import { ProfileOutlined as IconDetail } from "@ant-design/icons";
import { Button, Card } from "antd";
import Tooltip from "antd/es/tooltip";
import { t } from "i18next";
import moment from "moment-jalaali";
import React, { useContext, useEffect, useRef, useState } from "react";

import ImgHot from "@/assets/images/hot-sale.png";
import { WidgetContext } from "@/pages/link/detail/widgetBuilder";
import { getCountDownTimer } from "@/utils/helper";

import WidgetModalSpec from "./WidgetModalSpec";
import "./WidgetWrapperBuilder.less";

const ExtraMenu = (props) => {
  const [data, dispatch_] = useContext(WidgetContext);
  const [openModalSpec, setOpenModalSpec] = useState();
  const [time, setTime] = useState();
  const refTimer = useRef();

  const selectWidget = (wId) => {
    if (data.widget === wId) {
      dispatch_({ type: "set_selected_widget", value: null });
    } else {
      dispatch_({ type: "set_selected_widget", value: wId });
    }
  };

  const openDetail = () => {
    setOpenModalSpec(!openModalSpec);
  };

  useEffect(() => {
    const x = getCountDownTimer_();
    return () => {
      clearInterval(x);
    };
  }, []);

  const getCountDownTimer_ = () => {
    let endDate = props?.data?.campaign?.end_date || "2023-06-20T20:30:00Z";

    let startDate = moment().format();

    if (!refTimer.current) {
      return;
    }
    if (moment(endDate).isAfter(startDate))
      return setInterval(() => {
        startDate = moment().format();
        const diff = getCountDownTimer(startDate, endDate);
        refTimer.current.innerHTML = diff;
      }, 1000);
    else {
      refTimer.current.innerHTML = "00:00:00";
    }
  };

  return (
    <div className="w_wrapper__extra">
      {props?.data?.campaign_id ? (
        <div className="w_wrapper__campaign">
          <img src={ImgHot} />
          <b>{t("widget_builder.campaign_title")}: </b>{" "}
          <span className="camp_name">
            {props?.data?.campaign?.name || "-"}
          </span>{" "}
          |{" "}
          <span className="camp_count-down" ref={refTimer}>
            {time}
          </span>
        </div>
      ) : (
        false
      )}
      <Button
        icon={
          <Tooltip title={t("widget_builder.detail_widget")}>
            <IconDetail style={{ color: "orange" }} />
          </Tooltip>
        }
        onClick={openDetail}
      ></Button>
      <Button
        type="primary"
        // ghost={props.data?.Product_widget_id === data.widget ? false : true}
        onClick={() => selectWidget(props.data?.Product_widget_id)}
      >
        {t("widget_builder.selectWidgetButton")}
      </Button>
      {openModalSpec ? (
        <WidgetModalSpec widget={props.data} close={openDetail} />
      ) : (
        false
      )}
    </div>
  );
};

ExtraMenu.propTypes = {
  data: "",
};

const WidgetWrapperBuilder = (props) => {
  const { children, data } = props;

  const [dataContext, dispatch_] = useContext(WidgetContext);

  const selectWidget = (wId) => {
    if (dataContext.widget === wId) {
      dispatch_({ type: "set_selected_widget", value: null });
    } else {
      dispatch_({ type: "set_selected_widget", value: wId });
    }
  };

  return (
    <Card
      // title={data.name}
      title={
        <div onClick={() => selectWidget(data?.Product_widget_id)}>
          {data.name}
        </div>
      }
      extra={<ExtraMenu data={data} />}
      className={`widget-wrapper-builder wp_wrapper ${
        dataContext?.widget === data?.Product_widget_id
          ? "w_wrapper__selected"
          : ""
      }`}
    >
      {children}
    </Card>
  );
};

WidgetWrapperBuilder.propTypes = {
  children: "",
  data: "",
};

export default WidgetWrapperBuilder;
