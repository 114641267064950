import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import {
  newCustomer,
  newTicket,
  systemSettings,
} from "../app/services/ticketingService";
import {
  containedLargePrimaryBtn,
  notification,
  outlinedLargePrimaryBtn,
} from "./../app/helper";
import CustomizedButton from "../components/CustomizedButton";
import CustomTextField from "../components/CustomTextField";
import SimpleSelect from "../components/SimpleSelect";
import TextArea from "../components/TextArea";
import UploadFile from "../components/UploadFile";
import AttachmentIcon from "@material-ui/icons/Attachment";

const CreateTicket = (props) => {
  const { userProfile, currentDepartment } = props?.options;
  const [categoryId, setCategoryId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({});
  const [showUpload, setShowUpload] = useState(false);
  const [files, setFiles] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    systemSettings().then((res) => setSettings(res.data.data));
  }, []);

  const {
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm({});

  const { t } = useTranslation("support");

  const onCreate = (e) => {
    e.preventDefault();
    const { title, content } = getValues();
    const formData = new FormData();
    files.map((file, i) => {
      formData.append(`attachments[${i}]`, file);
    });
    formData.append("user_email", userProfile.email);
    formData.append("title", title);
    formData.append("content", content);
    formData.append("to_department", currentDepartment);
    formData.append(
      "ticket_category_id",
      categoryId === "other" ? null : categoryId
    );
    formData.append("importance", 2);

    setLoading(true);

    newTicket(formData)
      .then(() => {
        props?.router(-1);
        reset();
        // update();
      })
      .catch((error) => {
        if (error.response?.data?.message === "مشتری پیدا نشد") {
          addCustomer();
        } else if (error.response?.data?.message) {
          notification(
            `${error.response.data?.message} ${
              error.response.data?.errors
                ? `: ${error.response.data?.errors}`
                : ""
            }`,
            "error"
          );
        }
      })
      .finally(() => setLoading(false));
  };
  const addCustomer = () => {
    const data = {
      branch_id: 1, // default
      first_name: userProfile.firstName,
      last_name: userProfile.lastName,
      email: userProfile.email,
      mobile: userProfile.phone,
      username: userProfile.email,
      address: userProfile.platformName,
      password: `Adverge${userProfile.id}#`,
      password_confirmation: `Adverge${userProfile.id}#`,
      customer_fields: {
        customer_level_id: 1,
        organization_id: 1, // customers
        assigned_departments: [
          {
            department_id: currentDepartment,
          },
        ],
      },
    };
    newCustomer(data).then(() => onCreate());
  };

  return (
    <>
      <form>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <CustomTextField
              name="title"
              control={control}
              rules={{ required: true }}
              className={classes.textField}
              type="text"
              label={t("ticket_title")}
              error={errors?.ticket_title}
              color="secondary"
            />
          </Grid>
          <Grid
            container
            sm={6}
            justify={"flex-end"}
            style={{ marginBottom: 16 }}
          >
            <CustomizedButton
              icon={<AttachmentIcon />}
              onClick={() => setShowUpload(true)}
              isStartIcon
              buttonProperties={outlinedLargePrimaryBtn}
              hasType={true}
              extraStyle={classes.uploadBtn}
            >
              <label>{t("select_attach_file")}</label>
            </CustomizedButton>
          </Grid>
          <Grid item sm={6}>
            <SimpleSelect
              name="ticket_category"
              label={t("ticket_category")}
              value={categoryId}
              onSelect={(value) => setCategoryId(value)}
            >
              {settings?.ticket_categories
                ?.filter((item) => item.department_id === currentDepartment)
                .map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {t(item.name)}
                  </MenuItem>
                ))}
              {/* <MenuItem key={"other"} value={"other"}>
                {t("other")}
              </MenuItem> */}
            </SimpleSelect>
          </Grid>

          {/*<Grid item sm={6}>*/}
          {/*  <SimpleSelect name="importance" label={t("importance")} value={importance} onSelect={(value) => setImportance(value)}>*/}
          {/*    {importanc
         eLevels?.map((item) => (*/}

          {/*      <MenuItem key={item.value} value={item.value}>*/}
          {/*        {t(item.label)}*/}
          {/*      </MenuItem>*/}
          {/*    ))}*/}
          {/*  </SimpleSelect>*/}
          {/*</Grid>*/}
        </Grid>
        <Grid item sm={12}>
          <TextArea
            name="content"
            control={control}
            className={classes.textArea}
            label={t("request_text")}
            inputHeight="90px"
          />
        </Grid>
        <div>
          <CustomizedButton
            buttonProperties={containedLargePrimaryBtn}
            onClick={onCreate}
            loading={loading}
            disabled={loading}
            type="submit"
            hasType
          >
            {t("send_ticket")}
          </CustomizedButton>
        </div>
      </form>
      <UploadFile
        showUpload={showUpload}
        setShowUpload={setShowUpload}
        files={files}
        setFiles={setFiles}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: 640,
  },
  textArea: {
    margin: `${theme.spacing(3)}px 0px`,
  },
  saveBtn: {
    marginTop: theme.spacing(2),
  },
}));

CreateTicket.propTypes = {
  update: PropTypes.func,
  settings: PropTypes.object,
  options: PropTypes.any,
  router: PropTypes.func,
};

export default CreateTicket;
