import axios from "axios";

import { clearStorage } from "@/utils/utils";

// import { hashCode } from "@/utils/helper";
// import { QueryClient, QueryClientProvider } from "react-query";
import {
  ADV_LINK,
  DEFAULT_LOCALE,
} from "../../utils/constants/configConstants";

export const hashCode = (s) => {
  try {
    return s.split("").reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  } catch (e) {
    return s;
  }
};

// eslint-disable-next-line no-undef
const APP_VERSION = process.env.REACT_APP_VERSION;
// for every release it changed
const getUrlPostFix = () => {
  return hashCode(APP_VERSION);
};
export const LOCAL_STORAGE = {
  RETURN_URL: "return_url",
  USER_ID: "user_id",
};

export const logOut = () => {
  clearStorage();
  window.location.replace("/login");
};

export const cleanStorage = () => {
  window.localStorage.setItem("ACCESS_ERROR", "");
};

const errorCounter = (err) => {
  if (err?.response?.status === 401) {
    let count = window.localStorage.getItem("ACCESS_ERROR");
    count = Number(count) + 1;
    window.localStorage.setItem("ACCESS_ERROR", count);
  }
};

const retryLogin = () => {
  const d = getUrlPostFix();
  fetch("/api/v1/Account/Refresh?vr" + d, { method: "POST" });
};

// const isDevProduction = isDevPrd();
// if (isDevProduction) {
// axios.defaults.baseURL = "https://client-api.affilio.ir/api/v1";
// axios.defaults.baseURL = "https://client-api-stage.affilio.ir/api/v1";
// axios.defaults.baseURL = "http://157.90.231.113/api/v1";
// } else {
axios.defaults.baseURL = "/api/v1";
// }

const configLang = localStorage.getItem("locale") ?? DEFAULT_LOCALE;
axios.defaults.headers.common[
  "Culture"
] = `${configLang}-${configLang.toUpperCase()}`;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.interceptors.response.use(
  (res) => res,
  (err) => {
    // err?.response?.status === 401 && logOut();
    errorCounter(err);
    retryLogin();
    return Promise.reject(err);
  }
);

// A mock function to mimic making an async request for data
export function baseAPI(data) {
  const { method, headers, body, endPoint, isPublic, controller } = data;
  const headers_ = { ...axios.defaults.headers.common, ...(headers || {}) };
  return axios({
    url: endPoint, //`${endPoint}?v=8a2202f17`,
    // headers: isPublic ? headers_ : headers_,
    headers: headers_,
    method: method || "POST",
    data: body,
    params: { v: getUrlPostFix() },
    signal: controller?.signal,
  });
}

export function baseAdvAPI(data) {
  return fetch(`${ADV_LINK}${data.url}?${data.queryParams}`).then((response) =>
    response.json()
  );
  // .then((data) => data);
}

// const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       retry: Number.POSITIVE_INFINITY,
//       cacheTime: Number.POSITIVE_INFINITY,
//       staleTime: Number.POSITIVE_INFINITY,
//       keepPreviousData: true,
//       refetchOnMount: false,
//       refetchOnReconnect: false,
//       refetchOnWindowFocus: false,
//     },
//   },
// });

// export const FetcherProvider: FC = ({ children }) => {
//   return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
// };
