import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

export const minBalanceToStopCampaign = 9;
/** This custom hook is used to add STOPPED status for RUNNING campaigns that are stopped temporarily due to low budget
 * this status is not returned from backend because it's considered RUNNING in the backend but
 * in the frontend is considered as a new state(STOPPED) to notify users to charge their account.
 * some points to be considered in this scenario:
 * 1- for some campaign types there is no STOPPED status (typesWithoutStoppedStatus)
 * 2- when user have enough balance there is no campaign that is in STOPPED status
 * 3- when balance is not enough all DISPLAY campaigns that are RUNNING are automatically stopped but their status is
 * still RUNNING in backend
 */
const useListFiltersPrime = (initialState) => {
  const dispatch = useDispatch();
  // const { balance } = useSelector(userData, shallowEqual);
  const [filter, setFilter] = useState(initialState);
  //TODO: all this values are accepted values for ALL_ITEMS, they should be unified to one value
  const ALL_ITEMS = ["ALLITEMS", undefined, null];

  return { filter, setFilter };
};

export default useListFiltersPrime;
