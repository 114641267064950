import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Cancel from "@material-ui/icons/Cancel";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import AccordionExpand from "./AccordionExpand";

const useStyles = makeStyles((theme) => {
  return {
    selectOptions: {
      color: theme.palette.secondary.dark1,
      "& .MuiMenuItem-root": { fontSize: "14px" },
    },
    selectMenuForm: {
      backgroundColor: "transparent",
      width: "100%",
      "& .closeIcon": {
        cursor: "pointer",
        position: "absolute",
        fontSize: 20,
        color: theme.palette.grey.G400,
        right: theme.spacing(6),
        top: theme.spacing(2),
        zIndex: 1,
      },
      "& fieldset": {
        border: "none",
        ...theme.typography.body2.regular,
      },
      "& .MuiOutlinedInput-root": {
        height: "52px",
        "& svg": { width: "24px", height: "24px" },
        backgroundColor: "transparent",
        "&.Mui-focused": {
          border: `1px solid ${theme.palette.primary.main}`,
        },
        "&.optionFilled": {
          // border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: theme.spacing(1),
          "&.Mui-disabled": {
            border: `1px solid ${theme.palette.grey.G300}`,
            backgroundColor: "transparent",
            color: theme.palette.grey[600],

            "& svg": {
              display: "none",
            },
          },
        },
        border: `1px solid ${theme.palette.common.gray6}`,
        borderRadius: theme.spacing(1),
        "&:hover": {
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: theme.spacing(1),
          "&.Mui-disabled": {
            border: `1px solid ${theme.palette.grey.G300}`,
          },
        },
        "&.MuiSelect-select": {
          paddingLeft: theme.spacing(5),
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },

      "& .MuiSelect-outlined": {
        paddingRight: theme.spacing(6.25),
        backgroundColor: "transparent",
        "& div": {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
    selectMenuFormSmall: {
      "& .MuiOutlinedInput-root": {
        height: 37,
      },
      "& .MuiMenuItem-root": {
        fontSize: "12 !important",
      },
      "& .MuiOutlinedInput-root svg": {
        margin: "4px",
        width: 20,
        height: 20,
      },
      "& .MuiInputLabel-formControl.MuiInputLabel-animated": {
        marginTop: "-11px",
      },
      "& .closeIcon": {
        cursor: "pointer",
        position: "absolute",
        color: theme.palette.grey.G400,
        fontSize: 18,
        right: theme.spacing(4.5),
        top: theme.spacing(1.2),
        zIndex: 1,
      },
    },
    selectMenuNameLabel: {
      backgroundColor: "white",
      color: theme.palette.secondary.main,
      fontSize: "14px",
      zIndex: 100,
      padding: "0 8px 0 16px",
      margin: "-4px 0 8px 4px",
      // [theme.breakpoints.down("sm")]: {
      //   margin: "-8px 0 8px 1px",
      // },
      "&.disabledLabel": {
        color: theme.palette.text.hint,
        "& svg": {
          display: "none",
        },
      },
      "&.optionFilledLabel": {
        color: theme.palette.primary.main,
        "&.disabledLabel": {
          color: theme.palette.text.hint,
          "& svg": {
            display: "none",
          },
        },
      },
    },
    selectMenu: {
      borderRadius: theme.spacing(1),
      fontSize: "14px !important",
      color: theme.palette.secondary.dark1,
      "& .MuiMenuItem-root": { fontSize: "12px" },
      // margin: `0px ${theme.spacing(2)}px`,
      // [theme.breakpoints.down("sm")]: {
      //   width: "100%",
      //   margin: "0px",
      // },
      cursor: "pointer",
    },
    "& MenuItem": {
      fontSize: "12px !important",
    },
  };
});

const ModifiedAccordionExpand = withStyles((theme) => ({
  root: {
    width: "24px",
    height: "24px",
    margin: "2px 6px",
    "& path": {
      stroke: theme.palette.grey.G400,
    },
  },
}))(AccordionExpand);

const SimpleSelect = ({
  className,
  labelId,
  label,
  onChange,
  onSelect,
  value,
  selectLabel,
  variant = "outlined",
  disabled,
  selectId,
  renderValue,
  children,
  multiple,
  name = "",
  defaultValue = "",
  size,
  onClear,
}) => {
  const classes = useStyles();

  return (
    <>
      <FormControl
        className={clsx(
          classes.selectMenuForm,
          { [classes.selectMenuFormSmall]: size === "small" },
          className
        )}
      >
        <InputLabel
          className={`${classes.selectMenuNameLabel} ${
            value && "optionFilledLabel"
          } ${disabled && "disabledLabel"}`}
          id={labelId}
        >
          {label}
        </InputLabel>
        {value && onClear && (
          <Cancel className="closeIcon" onClick={() => onClear()} />
        )}
        <Select
          name={name}
          IconComponent={ModifiedAccordionExpand}
          onChange={(e) => {
            onChange && onChange(e);
            onSelect && onSelect(e.target.value);
          }}
          value={value ?? defaultValue}
          label={selectLabel}
          variant={variant}
          disabled={disabled}
          labelId={labelId}
          id={selectId}
          renderValue={renderValue}
          multiple={multiple}
          className={clsx(classes.selectMenu, { optionFilled: value })}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {children}
        </Select>
      </FormControl>
    </>
  );
};

SimpleSelect.propTypes = {
  labelId: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  value: PropTypes.any,
  selectLabel: PropTypes.string,
  multiple: PropTypes.bool,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  selectId: PropTypes.string,
  renderValue: PropTypes.func,
  children: PropTypes.node,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  onClear: PropTypes.func,
};

export default SimpleSelect;
