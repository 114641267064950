import { Form, Input } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export const InputPasswordConfirm = (props) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      // label="Password"
      name="passwordConfirm"
      className="auth__password"
      rules={[
        {
          required: true,
          message: props.requiredMessage
            ? props.requiredMessage
            : t("required"),
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            }

            return Promise.reject(new Error(t("auth.passwordNotMatch")));
          },
        }),
      ]}
      //   style={{ direction: "rtl" }}
      dependencies={["password"]}
      hasFeedback
    >
      <Input.Password
        size={props.size || "large"}
        // style={{ textAlign: "center" }}
        placeholder={t(props.placeholder || "auth.passwordConfirm")}
      />
    </Form.Item>
  );
};

InputPasswordConfirm.propTypes = {
  placeholder: PropTypes.string,
  size: PropTypes.size,
  requiredMessage: PropTypes.string,
};
export default InputPasswordConfirm;
