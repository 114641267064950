import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";

import AdPlaceLiveViewer from "../AdPlaceLiveViewer";
import CardSkeleton from "./CardSkeleton";
import FormMaster from "./FormMaster";
import "./InArticleConfig.less";

const InArticleConfig = (props) => {
  const { doFunction, form } = props;
  const [count, setCount] = useState(8);

  const onFinish = (event) => {
    doFunction(event);
  };

  const onValuesChange = (e) => {
    if (e?.max_item_count) {
      setCount(e?.max_item_count || 8);
    }
  };

  return (
    <Row gutter={[24, 24]} className="main-config in-article-config">
      <Col xs={10}>
        <FormMaster
          form={form}
          onValuesChange={onValuesChange}
          onFinish={onFinish}
          // config={{ content_display_type: "Bottom" }}
        />
      </Col>
      <Col xs={14}>
        <AdPlaceLiveViewer>
          <div className="box-preview">
            <div className="box-preview__widget">
              <div className="multiplex">
                {Array(count)
                  .fill(1)
                  .map((i, k) => (
                    <CardSkeleton
                      class={k === 2 ? "active" : ""}
                      key={"i" + i}
                    />
                  ))}
              </div>
            </div>
          </div>
        </AdPlaceLiveViewer>
      </Col>
    </Row>
  );
};

InArticleConfig.propTypes = {
  doFunction: PropTypes.any,
  form: PropTypes.any,
};
export default InArticleConfig;
