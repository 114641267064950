const enTransLation = {
  Dashboard: "Dashboard",
  required: "Required",
  loading: "Loading...",
  Action: "Detail",
  search: "Search",
  unknown: "Unknown",
  auth: {
    kyc: {
      PendingForApproval:
        "Your information is being reviewed, you will be notified after confirmation or any change of status.",
      IdentityCardNotConfirmed: "",
    },
    termConditionFirst: "I agree to the",
    termConditionSecond: "for registration in Affilio",
    pleaseEnterFirst:
      "Please enter the code that we sent to this mobile number",
    loginDescription: "Enter username",
    recoveryDescription: "Enter username",
    errorLoginMessage: "Username or password is wrong",
    selectTypeUser: "choose the type of cooperation",
    MERCHANT: "Webstore/Seller",
    PUBLISHER: "Publisher",
    LEGAL: "Legal entity",
    PRIVATE: "Natural person",
    phone: "Mobile number",
    usernameLogin: "Mobile number",
    password: "Password",
    passwordConfirm: "Confirm password",
    passwordNew: "New password",
    passwordConfirmNew: "Confirm new password",
    showAgreement: "Terms & conditions",
    passwordNotMatch: "Password doesn't match",
    helpPassword:
      "At least 8 characters(Must contain uppercase & lowecase letter, numbers & symbols",
    register: "Signup",
    forgot: "Forgot password",
    login: "Login",
    contractTitle: "Terms & conditions",
    contractText:
      'سامانههمکاریدرفروش(کهدراینقراردادبهاختصارسامانههمکارینامیدهمی‌شود.)،سامانهایاستکهبررویوبسایتدیجیکالابهآدرسwww.Digikala.comایجادمی‌شودوشاملنرمافزار،اپلیکیشن،سرویسها،دادههاوطراحیهایموجوددرآنمی‌باشد.عضوشدندرآنبهشماامکانمیدهدکهازوبسایت،شبکهاجتماعی،ونرمافزارهایآنلاینخود(کهدراینشرایطوقوانینبهاختصار"وبسایت"نامیدهمیشوند.(درآمدزاییبنمایید.اشخاصیکهدراینسامانهعضوشدهونامکاربریورمزعبوردریافتنمایند،همکار/همکاراننامیدهمیشوند.دیجیکالادرسامانههمکاریدرفروش،یکلینکاختصاصیکهبرایهرفردبهصورتمجزاتعریفمی‌شود،دراختیارتانقرارمیدهدولینکمذکوربایدبهطورکاملاصحیحوازفرمتلینکتگشدهیمخصوصیکهدیجیکالاتهیهنمودهومطابقاینشرایطوقوانینتنظیمشدهاست،باشد.اینلینکدرواقعکالاهایپیشنهادیدیجیکالارامعرفیمینمایدکهمطابقباموضوعفعالیت"وبسایت"شما،کالاهایمرتبطرادربرمیگیرد.شماباقراردادنلینکاختصاصیخوددر"وب سایت"تان،میتوانیددرآمدزاییکنید.',
    verify: "Confirm mobile number",
    successRegisterMessage: "Your account has been successfully created.",
    successLoginMessage: "Transfering...",
    rememberMe: "Rememeber me",
    loginText: "Affiliate Management Platform",
    registerText: "Affiliate Management Platform",
    agree: "Agree",
    accept: "Approve",
    changePassword: "Change password",
    enterNewPassword: "Enter new password",
    passUpdatedSuccessFully:
      "To proceed with your account, review & confirm the contract in profile section.",
    shouldAcceptAgreement: "Terms & conditions should be accepted",
    contract_alert:
      "To proceed with your account, review & confirm the contract in profile section",
    username: "Username",
    sendCode: "Send verification code",
    sendCodeDescription:
      "Please enter the verification code that was sent via email.",
    mobileVerify: "Verify mobile number",
    mobileDescription:
      "Please approve your mobile number. In case you want to edit mobile number, edit and then eapprove it.",
    sendmobileCodeDescription: " Please enter the code that was sent via SMS",
    changeMobileNumber: "Change mobile number",
    resetPassword: "Setup new password",
    textOldLogin: "If you are already Affilio user",
    clickHere: "Click here",
    back: "Back",
    yourAccountRecovered: "Your acount",
    recoverySuccessFullyDone:
      "Your account previously has been recovered,please enter your profile from login page.",
    emailError: "Email should be entered",
    phoneError: "Mobile number should be entered",
  },
  wizard: {
    legalPerson: "Legal entity",
    privatePerson: "Natural person",
    vatTitle: "VAT certificate",
    vat_included: "Is subject to vat tax",
    vat_not_included: "Is NOT subject to vat tax",
    stepPersonal: "Personal information",
    stepCompany: "Company information",
    stepContact: "Contact information",
    stepPayment: "Payment information",
    stepTax: "VAT",
    securityConfig: "Security setting",
    contract: "Contract",
    serviceSetting: "Service setting",
    publisherEntryDescription:
      "You entered as a publisher to the pannel, please choose your account type and fill the form",
    yourInfoSuccessfullySubmit:
      "Your information has been successfully registered and is under review. Please wait for further information. Thanks for your patient",
  },
  user: {
    changePassword: "Change password",
    legal: "User type",
    firstName: "Name",
    lastName: "Lastname",
    fatherName: "Father name",
    gender: "Gender",
    birthday: "Birth date",
    nationalCode: "National Id number",
    identityNumber: "Birth certificate number",
    picNationalCode: "National Id photo",
    picIdentityNumber: "Birth certificate photo",
    FEMALE: "Female",
    MALE: "Male",
    notValidNationalCode: "National Id number is not valid",
    notValidIdentNumber: "Birth certificate number is not valid",
    mobile: "Mobile number",
    phoneNumber: "Phone number",
    phoneNumberNotValid: "Phone number is not valid",
    email: "Email",
    province: "Province",
    city: "City",
    postalCode: "Postal code",
    address: "Address",
    addressPlaceHolder: "Unit, Building No., Street, Main Street, City ",
    notValidShebaCode: "Account IBAN number invalid",
    sheba: "Acount IBAN number",
    picVatDocument: "VAT certificate photo",
    picPaperDocument: "Official gazette image",
    taxOnVatText:
      "VAT certificate for natural persons should belong to the registered Affilio account",
    companyName: "Company name",
    registerNumber: "Registeration number",
    companyType: "Company type",
    nationalId: "Company national id number",
    economicalNumber: "Economical code",
    firstNameLegal: "Company legal representative name",
    lastNameLegal: "Company legal representative lastname",
    company: {
      PUBLIC: "Public company ",
      PRIVATE: "Private company",
    },
    plaque: "Plaque",
    MERCHANT: "Merchant",
    PUBLISHER: "Publisher",
    SUPER_ADMIN: "Super Admin",
    ADMIN: "Admin",
    SERVICE: "Service",
    errorAllKycMustBeApproved: "Error! User documents not approved.",
    roleAssigned: "Assigned roles",
    privileges: "Privileged access",
    addAdmin: "Add admin account",
    types: {
      PUBLISHER: "Publisher",
      MERCHANT: "Merchant",
      SUPER_ADMIN: "Super admin",
      ADMIN: "Admin",
      SERVICE: "Service",
    },
    oldUserId: "Old Affilio user id",
    oldUsername: "Old Affilio username",
    id: "User id",
  },
  input: {
    click_or_drag_file: "Upload file",
    support_single: "Approximately 2MB, format JPG or PNG",
    uploadError: "File upload error",
  },
  general: {
    saveSuccessFully: "Successfully submited",
    successFullyDone: "Successfully done",
    copySuccessFully: "Item copied!",
    unHandleErrorMessage: "Unexpected error, please try again",
    select: "Select",
    emailNotValid: "Not valid",
    notValidNumber: "Mobile number is not valid",
    submitContinue: "Submit & continue",
    back: "Back",
    submitEnd: "Submit",
    titleSite: "Affilio",
    logout: "Logout",
    academia: "Affilio Academy",
    submit: "Submit information",
    submit_: "Submit",
    cancel: "Cancel",
    SureCancel: "Cancel it?",
    sureDelete: "Are you sure you want to delete?",
    searchAdvanced: "Advanced search",
    save: "Submit",
    wizardMode: "Display in wizard form",
    wizardModeHelp: "In case the user is not Verified yet",
    ok: "Yes",
    ok2: "Updating",
    no: "No",
    active: "Active",
    deActive: "Deactive",
    delete: "Delete",
    UnexpectedErrorOccurred: "Error, please try again.",
    ValidationError: "Validation error, please check the entered values.",
    urlFormatNotValid: " url Address is not valid",
    saveAndContinue: "Save & continue",
    pleaseWait: "Loading...please wait.",
    pleaseWaitMessage: "Applying changes...",
    sureForDelete: "Are you sure you want to delete?",
    selectAll: "Select all ",
    currency: "Toman",
    from: "From",
    to: "To",
    Dollar: "Dollar",
    Euro: "Euro",
    Rial: "Rial",
    Toman: "Toman",
    undo: "Undo changes",
    accept: "Accept",
    reject: "Reject",
    edit: "Edit",
    view: "View",
    retry: "Retry",
    inputSearch: "Please search the keyword...",
    selectPublisher: "Select publisher",
    download: "Download",
    totalNumber: "Result count",
    noData: "No Data",
    new_release_message_title: "Affilio new service!",
    new_release_message:
      "A new version of Affilio is available, please update to the latest version.",
    networkError: "Network error, plaese try again.",
    networkErrorAccess: "Error! Your access to this page is restricted",
    networkErrorNeedAuth: "Error! please login again.",
    sureLeavePage: "Are you sure you want to leave this page? ",
    registerComplete: "Completion of registration",
    persian: "Persian",
    english: "English",
  },
  profile: {
    rankUser: " Score",
    userState: "Registration status",
    contractState: "Contract status",
    confirmed: "Confirmed",
    rejected: "Rejected",
    unknown: "",
    requiredUpdate: "Your document is not approved, please upload again.",
    showOldUser: "Old Affilio account information",
    user: {
      Registering: " Registration in progress",
      PendingForApproval: "Waiting to be reveiewed",
      Confirmed: "confirmed",
      InReview: "Under review",
      Rejected: "Rejected",
      Blocked: "Blocked",
      KysError: "Document error",
    },
    contract: {
      NEW: "Save draft",
      APPROVED: "Approved",
      REJECTED: "Rejected",
      EXPIRED: "Expired",
      BLOCKED: "Blocked",
    },
    verify: {
      Verified: "Verified",
      UnVerify: "Reject",
      Pending: "Waiting to be approved",
      Failed: "Something wrong!",
    },
    accessManagement: "Access management",
    saveUrl: "Submit",
    testApi: "API test",
    enterUrl: "www.api.exmaple.com",
    serviceSettingText: "Order Call Back API address",
    dataModel: "Data model",
    responseTitle: "Response",
  },
  page: {
    Profile: "User profile",
    Kits: "Kits",
    Websites: "Webstores",
    Dashboard: "Dashboard",
    Users: "User management",
    users: "Users",
    contracts: "Contracts",
    Publishers: "Publisher management",
    UserDetail: "User information",
    Merchants: "Merchant management",
    ContractTemplates: "Contract template management",
    ContractTempDetail: "Template",
    ContractsUserList: "Contract",
    ContractDetail: "Contract detail",
    ContractTempDetailAdd: "Add contract detail",
    MediaDetails: "Media details preview",
    WebsiteAdd: "Add Webstore",
    WebsiteEdit: "Edit Webstore",
    websites: "Webstores",
    WebsiteCommission: "Commission",
    WebsiteCommissionHistory: "Commission change history",
    Medias: "Medias",
    MediaEdit: "Edit media",
    Settings: "Setting",
    Reasons: "Response management",
    Subjects: "Topic management",
    Setting: "Setting",
    Links: "Links",
    EditLink: "Edit Links",
    Banners: "Merchants banner",
    BannersMerchant: "Banners",
    BannerNew: "Create banner",
    BannerEdit: "Edit banner",
    Widgets: "Widgets",
    websitesManage: "Webstore management",
    WidgetNew: "Create Widgets",
    WebsiteCommissionList: "Change commission",
    BannersWidget: "Publisher banner",
    BannersWidgetPublisher: "Banners",
    BannerWidget: "Banner detail",
    BannerWidgetNew: "Create widget banner",
    BannerWidgetEdit: "Edit widget banner",
    Orders: "Orders",
    Invoices: "Financials",
    InvoicesPage: "Invoices",
    InvoicesMerchant: "Merchants invoice",
    InvoicesPublisher: "Publisher's invoice",
    Roles: "Role management",
    InvoiceDetail: "Invoice detail",
    Projections: "Payment planning",
    FinanceMerchant: "Merchant Financials",
    FinancePublisher: "Publisher Financials",
    LinksManage: "Links management",
    Reports: "Reports",
    Support: "Support",
    PayoutsPage: "Payouts",
    Administrators: "Administrator management",
    Administrator: "Admin profile",
    SmartWidgets: "Smart Widgets",
    register: "Signup",
    forgot: "Forgot password",
    recovery: "Account recovery",
    Groups: "widget tags",
    SmartWidget: "Smart widget",
    SmartWidgetNew: "Create smart widget",
    SmartWidgetEdit: "Edit smart widget",
  },
  admins: {
    Id: "ID",
    undefined: "Unknown",
    Actions: "Detail",
    autoLogin: "Auto login",
    Role: "Role",
    Mobile: "Mobile number",
    Email: "Email",
    Status: "Status",
    Name: "Full name",
    sureToSetActive: "Are you sure to activate?",
    sureToUnsetActive: "Are you sure to deactivate?",
    gender: "Gender",
    successFullyDone: "Successfully done",
    UnexpectedErrorOccurred: "Error, please try again.",
    detail: "Detail",
    saveSuccessFully: "Saved successfully",
    unHandleErrorMessage: "Unexpected error, please try again",
    firstName: "Name",
    lastName: "Lastname",
    phone: "Mobile number",
    submit: "Submit",
    FEMALE: "Female",
    MALE: "Male",
    addAdmin: "Add account admin",
  },
  users: {
    Id: "ID",
    NameOrCompany: "Name/Company name",
    UserType: "User type",
    LEGAL: "Legal entity",
    PRIVATE: "Natural person",
    undefined: "Unknown",
    DateRegister: "Registration date",
    StatusKyc: "Document status",
    StatusContract: "Contract status",
    UserStatus: "Registration status",
    VerifyStatus: "Account status",
    actions: "Detail",
    detail: "Detail",
    history: "History",
    autoLogin: "Auto login",
    inputSearch: "Search the keyword...",
    role: "Role",
    StatusDocumentUser: "Evaluat document",
    StatusUserForm: "Change user status",
    statusDescription: "Description",
    approved: "Approved",
    error: "Not approved",
    sureForAutoLogin: "Are you sure you want to enter user acount?",
    status: {
      waiting: "Waiting to be reveiewed",
      approved: "Approved",
      rejected: "Rejected",
    },
    Mobile: "Mobile number",
    Email: "Email",
    Status: "Status",
    Name: "Name, Lastname",
    close: "Close",
  },
  kycDoc: {
    PersonalInfoConfirmed: "Personal information",
    CompanyInfoConfirmed: "Company information",
    BankAccountInfoConfirmed: "Bank account information",
    ContactInfoConfirmed: "Contact information",
    NationalCardConfirmed: "Id card",
    IdentityCardConfirmed: "Birth certificate",
    VatDocumentConfirmed: "VAT certificate",
    NewsPaperDocumentConfirmed: "Official gazette",
    kysDocApprove: "Please select approved items:",
    kysDescription: "Document error desc",
  },
  contract_tmp: {
    id: "Template Id",
    name: "Template title",
    type: "Template type",
    is_enabled: "Status",
    create_date: "Create date",
    start_date: "Start date",
    end_date: "End date",
    actions: "",
    PrivatePublisher: "Natural person publisher - (not legal)",
    LegalPublisher: "Legal entity publisher",
    Merchant: "Merchant",
    detail: "Detail",
    Waiting: "Waiting",
    Active: "Active",
    Expired: "Expired",
    Draft: "Draft",
    confirm: "Template edit type",
    create_new_templateYes: "Create new template.",
    create_new_templateNo: "Edit current template.",
    variables: "Available variables",
    user: "User",
  },
  contract: {
    number: "Contract number",
    status: "Contract status",
    create_date: "Contract create date",
    approve_date: "Contract approval date",
    reject_date: "Contract rejection date",
    download: "Download contract",
    approve: "I approve",
    reject: "I reject",
    history: "Contract Archive",
    id: "ID",
    name: "Contract title",
    is_enabled: "Status",
    is_enabled_: "General status",
    create_date_: "Create date",
    start_date: "Start date",
    end_date: "End date",
    last_modify_date: "Last update date",
    trueState: "Active",
    falseState: "Deactive",
    paper: "Contract file",
    savePaper: "Save file",
    numberRegister: "Register number",
    date: "Create date",
    smartech: "Atlas Ertebate Raman Co.",
    uploadFileLegal:
      "Please download & review the contract and upload the signed version.",
  },
  website: {
    id: "ID",
    merchant: "Merchant",
    name: "Merchant name",
    englishName: "English name",
    url: "Merchant URL",
    status: "State",
    create_date: "Create date",
    actions: "",
    add: "Add Webstore",
    subject: "Field of Activity",
    detail: "Webstore detail",
    levels: "Webstore levels",
    defaultLevel: "Default",
    levelName: "Level title",
    addLevel: "Add level",
    addSuccessfullySubmit: "Webstore info has been successfully submitted.",
    categories: "Categories",
    amount: "Amount",
    new_customer_price: "New customer order amount",
    new_customer_registration_price: "New customer signup amount",
    currency: "Currency",
    discount: "Discount",
    is_enabled: "Status",
    Dollar: "Dollar",
    Euro: "Euro",
    Rial: "Rial",
    Toman: "Toman",
    newCurrency: "New user commission",
    Edit: "Edit",
    active: "Active",
    noActive: "Deactive",
    sureToEnableWebsite: "Are you sure you want to active the Webstore?",
    sureToDisableWebsite: "Are you sure you want to deactivate the Webstore?",
    cookie_expire: "Cookie",
    cookie_time_type: "Cookie",
    cookie: {
      Day: "Day",
      Minute: "Minute",
      Hour: "Hour",
      Month: "Month",
      Year: "Year",
    },
    commissions: "Commissions",
    commissionLink: "Edit commissions",
    alertForEdit:
      "To edit categories commission, go to product commission page",
    save: "Save",
    delete: "Delete",
    errorSingleLevel: "You can select just 1 default level.",
    paymentConfig: "Commission formula setting",
    levels_: {
      Leaf: "Leaf",
      Closest: "Closest",
      Root: "Root",
      Custom: "Custom",
    },
    close: "Close",
  },
  commission: {
    CategoryName: "Category",
    Commission: "Basic commission%",
    Commission_: "Normal commission",
    PromotionCommission: "Promotional commission%",
    PromotionCommission_: "Promotional commission",
    Action: "Edit",
    importCsv: "Import excel",
    exportCsv: "Export excel",
    csvPlaceholder: "",
    download: "Download excel file",
    uploadFile: "Commissions",
    selectLevel: "Select level",
    selectWebsite: "Select website",
    commissionFormatError: "File format is not valid.",
    saveChanges: "Save changes",
    commissionsChanged: "Commission has been changed",
    importMessage:
      "First you should download products commission excel file, and upload it after changing it",
    status: {
      defaultMessage: "Implemented change status",
      defaultMessageAdmin: "Do you approve applied changes?",
      PendingForApprove: "Waiting to be reviewed",
      Active: "Active",
      Approved: "Approved",
      Rejected: "Rejected",
      Closed: "Closed",
      Ended: "Ended",
    },
    pleaseFill: "Please fill in the fields",
    categorySelected: "Category is selected",
    titleApprove: "Commission change date",
    selectApplyDate: "Please select commission change date",
    showAll: "Show all",
    currentCom: "Current commissions",
    newCom: "New commissions",
    titleReject: "Commission change reject reason",
    justEditedCommissions: "Just edited commissions",
    history: "Edit history",
    label: "#",
    dateFrom: "Date(from)",
    dateTo: "Date(to)",
    detail: "",
    id: "ID",
    name: "Title",
    status_: "Status",
    apply_date: "Update date",
    last_modify_date: "Update date",
    approve_date: "Approve date",
    reject_date: "Reject date",
    create_date: "Create date",
    show: "Display",
    view: "View",
    level: "Commission level",
    rejectTitle: "Request reject reason",
    moreInfo: "More info",
    dateReject: "Request reject date",
  },
  media: {
    id: "ID",
    name: "Media name",
    type: "Media type",
    selectMedia: "Select media",
    subject: "Subject",
    create_date: "Create date",
    test_mode: "Cooperation type",
    content_status: "Content approval",
    ownerShip_status: "Authentication",
    add: "Add media",
    Website: "Website",
    Instagram: "Instagram",
    Telegram: "Telegram",
    LinkedIn: "LinkedIn",
    WhatsApp: "WhatsApp",
    Facebook: "Facebook",
    YouTube: "YouTube",
    WeChat: "WeChat",
    Messenger: "Messenger",
    TikTok: "TikTok",
    Aparat: "Aparat",
    Pinterest: "Pinterest",
    url: "Media URL",
    quickEdit: "Quick edit",
    edit: "Edit",
    selectWebsite: "Select Webstore",
    test_mode_op: {
      PendingForApprove: "Waiting to be reviewed",
      InTestMode: "test",
      ActiveUser: "Official",
    },
    content_status_op: {
      New: "Waiting to be reviewed",
      Approved: "Approved",
      Rejected: "Rejected",
      Block: "Blocked",
    },
    ownerShip_status_op: {
      New: "Waiting to be reviewed",
      Approved: "Approved",
      Rejected: "Rejected",
    },
    info: "Media info",
    websites: "Webstores",
    last_modify_date: "Latest edit date",
    publisher_name: "Media owner",
    website: "Webstore",
    websiteLevel: "Webstore level",
    active: "Active",
    de_active: "Deactive",
    addWebsite: "Add Webstore",
    descriptionReject: "Detail",
    description: "Detail",
    editStatus: "Edit status",
    is_enabled: "Status",
    editTestMode: "Edit cooperation status",
    editOwnershipStatus: "Edit authentication status",
    editContentStatus: "Edit content approval status",
  },
  reason: {
    add: "Add response",
    reasonText: "Define response",
    reason: "Response",
    id: "ID",
    type: "",
    edit: "Edit",
    create_date: "Create date",
    last_modify_date: "Update date",
    is_enabled: "Status",
    select: "Reject reason",
    types: {
      Media: "Media",
      Contract: "Contract",
      UserDocument: "User document",
      Commission: "Commission",
      Payout: "Payout",
    },
    status: {
      true: "Active",
      false: "Deactive",
    },
  },
  subject: {
    id: "ID",
    name: "Title",
    namePlaceholder: "Topic title",
    create_date: "Create date",
    is_enabled: "Status",
    edit: "Edit topic",
    add: "Add topic",
  },
  setting: {
    account: "User acount",
    security: "Password change",
    mobile: "Mobile number",
    role: "User role",
    created_date: "Create date",
  },
  link: {
    id: "ID",
    name: "Title",
    website: "Webstore",
    stepInfo: "Link details",
    stepResult: "Links list",
    media: "Media",
    type: "Link type",
    clicks_daily: "Today clicks",
    clicks_total: "Total clicks",
    created_date: "Create date",
    is_enabled: "Status",
    address: "Main link",
    address_short: "Short link",
    add: "Create link",
    howCreateLink: "Please select link generation type:",
    linkType: {
      Private: "Exclusive link",
      Public: "General link",
      BannerWidget: "Widget banner",
      ProductWidget: "Product widget",
      Promotion: "Promotion",
    },
    selectWebsite: "Select Webstore",
    selectMedia: "Select media",
    title: "Link title",
    addressLink: "Page address",
    helperAddress: "Landing page address",
    createLink: "Create link",
    copyLink: "Copy link",
    mainLink: "Link",
    shortLink: "Short link",
    subjects: "Field of activity",
    edit: "Edit link",
    group_id: "Tag",
    status: "Status",
    selectType: "Select link type",
  },
  banner: {
    id: "ID",
    name: "Banner title",
    title: "Banner type",
    website: "Webstore",
    today_click_count: "Today clicks",
    total_click_count: "Total clicks",
    created_date: "Create date",
    is_enabled: "Status",
    generateCode: "Generate code",
    add: "Create banner",
    actions: "",
    subject: "Category",
    description: "Detail",
    address: "Banner address",
    addImage: "Add image",
    size: "Image size",
    image: "Banner image",
    width: "width",
    height: "Height",
    info: "Banner info",
    media: "Media",
    is_promotion: "Promotion",
    is_default: "Default",
    start_date: "Start date",
    end_date: "End date",
    images: "Banner image",
    tbl: {
      Remove: "Delete",
      Dimensions: "Create banner",
      LastUpdate: "Last update",
      ShowImage: "Display banner",
      Add: "Add",
    },
    sureToSetDefault:
      "Are you sure you want to add this banner as default banner?",
    create_date: "Create date",
    last_modify_date: "Update date",
    code: "Recieve code",
    detail: "Detail",
    create_widget: "Create widget",
    sureToActive: "Are you sure you want to activate banner?",
    sureToDeActive: "Are you sure you want to deactive banner? ",
    promotion_item: {
      yes: "Yes",
      no: "No",
    },
    status: "Status",
    error: {
      dimensionError: "Banner size & dimensions is not correct.",
    },
    conditionHelp:
      "Default banner will be available if you upload all banner size.",
  },
  bannerWidget: {
    add: "Create widget banner",
    selectSize: "Select size",
    subject: "Topic",
    end: "End",
    dimension: "Image dimension",
    default: "Default",
    previewImage: "Preview",
    sureToSetActive: "Are you sure you want to activate?",
    sureToUnsetActive: "Are you sure you want to deactivate?",
    footerBanner:
      "Default banner is a type of banner which will be activated when current banner is deactivated.",
    resultCreate:
      "The widget is successfully created, now you can copy codes from below:",
  },
  widget: {
    category: "Widget category",
    priceFilter: "Filter by price",
    filtered: "Activated filters",
    name: "Widget title",
    type: "Widget type",
    number: "Widget number",
    add: "Add widget",
    edit: "Edit widget",
    pleaseSelect: "Please select widget generation type:",
    generatedCode: "Generated code",
    copyLink: "Copy code",
    listMyWidget: "My widget list",
    preview: "Preview",
    pre: "Back",
    copied: "Copied!",
    selectWebsite: "Select Webstore",
    website: "Webstore",
    today_click_count: "Today clicks",
    total_click_count: "Total clicks",
    create_date: "Create date",
    generate_code: "Generate code",
    Prev: "Previous",
    Next: "Next",
    results: "Widgets",
    media: "Widget title",
    noData:
      "You haven't created a widget yet, to create a widget please click on the below button.",
    selectWidgetType: "Select widget type",
    customWidget: "Normal widget",
    customWidgetDescription:
      "With this type of widget, you can select a list of merchant's products.  شما در این نوع ویجت، لیستی از محصولات فروشنده ها را به دلخواه خود انتخاب می کنید.",
    smartWidget: "Smart widget",
    smartWidgetDescription:
      "In this type of widget you can select from default products like cheapest or highest price.  در این نوع ویجت، شما دسته ای از محصولات پیش فرض (مثلا گرانترین یا ارزانترین) را انتخاب می کنید.",
    stepInfo: "Widget info",
    resultWidgets: "Created widget",
    getCode: "Recieve code",
    alertSelectWebsite: "To create a widget, select a webstore.",
    selectGroup: "Tag",
    type_: {
      Smart: "Smart",
      Manual: "Normal",
    },
  },
  product: {
    category: "Categories",
    priceFilter: "Filter by price",
    filtered: "Activated filters",
    sort: {
      Ascending: "Oldest",
      Descending: "Newest",
      MinPrice: "Lowest price",
      MaxPrice: "Highest price",
      MinToMaxSale: "Minimum discount - Max discount",
      MaxToMinSale: "Maximum discount - Minimum discount",
    },
    previewSelectedItems: "Display selected",
    selectedItems: "Selected item",
    searchProduct: "Search product...",
    onlyExists: "Only available items",
    search: "Search...",
    cheapest: "Lowest price",
    expensive: "Highest price",
    showProduct: "Show product",
    selectedProduct: "Product is selected.",
    createWidget: "Create widget",
    editWidget: "Edit widget",
  },
  order: {
    id: "Order number",
    media: "Media",
    link: "Link",
    website: "Webstore",
    create_date: "Create date",
    status_: "Order status",
    type: "Order type",
    receive: "Get data",
    discount: "Discount",
    total_payment: "Order amount",
    new_customer: "New customer",
    new_customer_amount: "New customer amount",
    status_order: "Order status",
    payment_status: "Payment status",
    vat: "VAT",
    commission_amount: "Commission amount",
    mediaType: "Media type",
    pd: {
      id: "Product code",
      name: "Product name",
      count: "Count",
      category: "Category",
      commission_percent: "Commission %",
      commission_price: "Commission amount",
      price: "Product price",
    },
    status: {
      New: "New",
      MerchantApproved: "Merchant approval",
      Finalize: "Final approval",
      Canceled: "Cancel",
      WaitingForInvoice: "Waiting for invoice",
      Invoiced: "Invoice is issued",
      Paid: "Paid",
    },
    exportCsv: "Export excel",
    exportCsvForm: "Export type",
    pleaseSelectTypeExport: "Please select your export type:",
    exportCsvQuickText: `<b>Current page report</b>:
    Export this page data according to your selected filters`,
    exportCsvFullText: `<b>Complete report</b>:
    Export complete data   <br/>
    according to your selected filters and then you can download it from Reports section.
   `,
    orders: "Orders",
    ordersDetail: "Order detail",
  },
  invoice: {
    id: "ID",
    contract: "Contract",
    id_search: "Invoice ID",
    merchant_id: "Merchant ID",
    web_store: "Webstore",
    merchant_name: "Merchant name",
    publisher_id: "Publisher ID",
    publisher_name: "Publisher name",
    projection: "Projection ID",
    start_date: "Start date",
    end_date: "End date",
    create_date: "Create date",
    payment_date: "Payment date",
    payment_date_start: "Payment date from",
    payment_date_end: "Payment date to",
    create_date_start: "Create date from",
    create_date_end: "Create date to",
    status_invoice: "Invoice status",
    status_payment: "Payment status",
    amount: "Invoice amount",
    amount_vat: "Tax",
    commission: "Commission",
    total_amount: "Final amount",
    detail: "Invoice detail",
    export_excel: "Export excel",
    print: "Print invoice",
    eventId: "ID",
    event: "Event",
    count: "Count",
    commission_amount: "Commission amount",
    discount_amount: "Discount amount",
    actions: "",
    download: "Recieve invoice",
    website: "Webstore",
    status: "Status",
    projection_id: "Projection id",
    get_order_detail: "View order details",
    addBillLegal: "Legal bill upload",
    status_: {
      Initial: "Draft",
      Confirmed: "Approved",
      Rejected: "Rejected",
      Pending: "In review",
    },
    pay_status_: {
      WaitingToProcess: "Waiting to be paid",
      Confirmed: "Approved",
      ReadyForPayment: "Ready for payment",
      TreasuryAcceptance: "Approve remittance",
      TreasuryRejected: "Reject remittance",
      PaymentBlock: "Blocked payment",
      Paid: "Paid",
    },
    type: {
      CostPerOrder: "Cost per order",
      CostPerOrderByDeductingVoucher: "Order cost minus discount code amount",
      NewCustomerRegistration: "New user Signup",
      NewCustomerOrder: "New user order",
      Bounty: "Bonus",
    },
    cal_du_to: "Calculation till",
    setInvoiceStatus: "Change invoice status",
    setPaymentStatus: "Change invoice payment status",
    description: "Detail",
    addBill: "Add payment receipt",
    editBill: "Edit payment receipt",
    viewBill: "Details",
  },
  finance: {
    invoices: "Invoices",
    projections: "Payment projection",
    settings: "Setting",
    payouts: "Payouts",
    reportPublisherPayout: "Payout report",
  },
  role: {
    titlePrivilege: "Privilege title",
    tag: "Tag",
    name: "Role name",
    edit: "Edit role",
    add: "Add role",
    search: "Search role",
    privileges: "Privileges",
    table: {
      titleAccess: "privileges",
      view: "View",
      create: "Create",
      edit: "Edit",
      delete: "Delete",
    },
    userType: "Role type",
    userCount: "User",
    showUsers: "Show users",
    submitRole: "Create role",
    editRole: "Edit role",
    type: "Role type",
    allPrivileges: "All privileges",
    assignPrivileges: "Assigned privileges",
  },
  projection: {
    id: "ID",
    merchant: "Merchant",
    publisher: "Publisher",
    bucket: "User level",
    contract: "Contract",
    title: "Title",
    start_date: "Start date",
    end_date: "End date",
    payment_date: "Payment/Payout date",
    invoice_date: "Create date",
    status: "State",
    active_status: "Active status",
    invoice: "Invoice",
    syncMessage: "In case you get wrong result, click here!",
    sync: "Sync",
    status_: {
      Initial: "New",
      WaitingToCalculate: "Waiting to be calculated",
      WaitingToGenerate: "Waiting to be created",
      Calculated: "Calculated",
      CalculatedWithZeroResult: "Calculation result is 0",
    },
  },
  dashboard: {
    click: "Click",
    order: "Order",
    orderTotal: "Total order count",
    amountTotal: "Total order amount(Toman)",
    orderTotalCount: "Final order count",
    costAllOrder: "Final orders amount(Toman)",
    rateConvert: "Conversion rate(clicks to orders)",
    filter: {
      lastMonth: "Previous month",
      currentMonth: "Current month",
      month_3: "3 Months",
      month_6: "6 Months",
      year: "1 Year",
      favorite_date: "Custom date",
    },
    viewsAndClicks: "Views & clicks",
    ordersCount: "Orders count",
    doFilter: "Apply filters",
    paid_commission: "Paid commissions(Toman)",
    sure_commission: "Finalized commissions(Toman)",
    able_to_pay_commission: "Payable commissions(Toman)",
    note1: "Horizontal axis shows date, vertical axis shows count.",
    clicks: "Clicks",
    views: "Views",
    rate: "Conversion rate(CR)",
    commissionTitle: "Commission",
    commissions: "Commissions",
    topNotice: "Notice",
    myMedia: "My links & medias",
    lorem: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ",
    mediaName: "Media name",
    linkName: "Link name",
    mediaShare: "Conversion rate",
    impressionCount: "Impression count",
    clickCount: "Click count",
    totalOrderAmount: "Total order amount(Toman)",
    commission: "Commission",
    introAffilio: "Learn about Affilio",
    selectLinkType: "Link type",
    orderTypeCategoryChart: "Orders by category",
    orderTypeLinkChart: "Orders by link type",
    orderTypeMedia: "Orders by media type",
    y: "Orders by media",
    widget: "Widget",
    banner: "Banner",
    link: "Link",
    all: "All",
    views_: {
      click_count: "Click",
      conversion_rate: "Conversion rate(CR)",
      view_count: "View",
    },
    com: {
      paid: "Paid",
      payable: "Payable",
      finalized: "Finalized",
    },
  },
  factor: {
    preItem: "Sakoye Raman Affiliate Service",
    fromDate: "Date from",
    toDate: "Date till",
    excel: "Excel",
    title: "Product & service sales invoice",
    title1: "Factor agent",
    store: "Merchant",
    nationalCode: "National Id number",
    registerCode: "Register number",
    ecoCode: "Economical Code",
    address: "Company's official address",
    address2: "Address",
    postalCode: "Postal code",
    teleFax: "Phone & Fax number",
    invoiceId: "Invoice ID",
    date: "Date",
    follow: "Tracking",
    buyer: "Buyer",
    nationalCodeOrEco: "National ID",
    rowId: "Row",
    idKala: "Product or Service ID",
    descKala: "Product or Service description",
    amer: "Amer",
    count: "Count",
    price: "Price per item(Rial)",
    total: "Total Price(Rial)",
    discount: "Discount(Rial)",
    totalByDiscount: "Total amount after discount(Rial)",
    vat: "Total VAT amount(Rial)",
    totalByAll: "Total amount after discount, tax & VAT(Rial)",
    sum: "Total",
    sumNext:
      "Total amount after deduction of discount including taxes and duties",
    sumVat: "Tax credit usable by the buyer",
    signStore: "stamp and signature",
    signBuyer: "Buyer stamp and signature",
    methodPayment: "Payment method",
    print: "Print",
  },
  payout: {
    startDate: "Start date",
    endDate: "End date",
    invoiceInterval: "Invoice time period(day)",
    paymentInterval: "Invoice payment period(day)",
    statusPayment: "Status",
    isActive: "Active?",
    has_invoice: "Invoice",
    id: "ID",
    true: "Active",
    false: "Deactive",
    description: "Detail",
    history: "Payout setting change history",
    createPayout: "Add Payout setting",
    interval_5: "5 Days",
    interval_10: "10 Days",
    interval_15: "15 Days",
    interval_20: "20 Days",
    interval_25: "25 Days",
    interval_30: "30 Days",
    sync: "Sync",
    status: {
      Waiting: "Waiting",
      Approved: "Approved",
      Rejected: "Rejected",
    },
  },
  payment: {
    id: "ID",
    detail: "Detail",
    is_active: "Status",
    start_date: "Start date",
    end_date: "End date",
    orderIndex: "Acceptable order count",
    affilioCommissionPercent: "Affilio commission(%)",
    ceilingCommission: "Commission ceiling",
    defaultCommission: "Default commission",
    defaultPromotionCommission: "Promotion default commission",
    currency: "Calculation unit",
    calculationCategoryLevel: "Calculation level",
    calculationCategoryLevelCustom: "Customized level",
    costPerOrderByVoucher: "Order's commission minus discount",
    costPerOrder: "Commission per order",
    newCustomerRegistration: "New customer Signup",
    newCustomerOrder: "New customer Order",
    bounty: "Bonus",
    createPayment: "Create calculation setting",
    includeFollows: "Select calculation formula",
    paymentHistory: "Setting edit history",
  },
  payoutMerchant: {
    id: "ID",
    payoutId: "Payout/Payment number",
    merchantName: "Merchant",
    invoice: "Bill",
    tracking_code: "Tracking code",
    status: "Status",
    payment_date: "Payment date",
    reject_date: "Reject date",
    create_date: "Create date",
    bill: "Payout receipt file",
    actions: "",
    list: "Payment/Payout list",
    createBill: "Add Payment invoice",
    description: "Description",
    transaction_id: "Transaction ID",
    payment_date_from: "Payment/Payout date from",
    payment_date_end: "Payment/Payout date to",
    setPaymentStatus: " Edit payment status",
    nationalCode: "Merchant national id",
  },
  payoutPublisher: {
    id: "ID",
    payoutId: "Payment/Payout Id",
    sheba: "Sheba",
    publisherName: "Publisher",
    invoice: "Bill",
    tracking_code: "Tracking code",
    status: "Status",
    payment_date: "Payment/Payout date",
    reject_date: "Reject date",
    create_date: "Create date",
    bill: "Payment receipt file",
    actions: "",
    list: "Payment/Payout list",
    description: "Detail",
    transaction_id: "Transaction Id",
    setPaymentStatus: "Edit payment status",
    setStatus: "Edit status",
    contract_status: "Contract status",
    payment_status: "Payment status",
    total: "Total amount",
    last_modify_date: "Last update date",
    create_date_from: "Create date from",
    create_date_end: "Create date to",
    nationalCode: "Publisher/Company national code",
  },
  widgetSmart: {
    title: "Widget title",
    website: "Webstore",
    category: "Category",
    media: "Media",
    categoryType: "Select widget type",
    helpTextCategory: "Create widget based on:",
    titleWidget: "Widget",
    createWidget: " Generate code",
    editWidget: "Edit widget",
    sureForUseWidget: "Are you sure you want to use this widget?",
    messageSelectMedia: "Please select the media",
    helpText:
      "To use a widget first you should select the media. The created widget is dedicated to this media. برایاستفادهازاسمارتویجتهابایدرسانهموردنظرخودراانتخابکنید،ویجتساختهشدهبهصورتاختصاصیبرایآنرسانهساختهمیشود.",
    selectMedia: "Select media",
  },
  grouping: {
    id: "ID",
    name: "Tag title",
    create_date: "Create date",
    last_modify_date: "Update date",
    is_enabled: "Status",
    delete: "Delete",
    add: "Add Tag",
    edit: "Edit",
    editTitle: "Edit Tag",
    selectWebsite: "Select Webstore",
    website: "Webstore",
    status: {
      true: "Active",
      false: "Deactive",
    },
  },
  report: {
    report_id: "Report id",
    create_date: "Create date",
    last_modify_date: "Last modify date",
    user_id: "User id",
    report_type: "Report type",
    status: "Status",
    download: "Download",
    statusOptions: {
      QUEUED: "Waiting to be checked",
      FAILED: "Rejected",
      SUCCEEDED: "Successful",
      IN_PROGRESS: "Under review",
    },
    date_c_from: "Creation date from",
    date_c_end: "Creation date to",
    date_u_from: "Update date from",
    date_u_end: "Update date to",
    orderType: {
      ORDER: "Order",
    },
  },
  help: {
    tourTitleModal: "Step by step guide",
    back: "Previous",
    next: "Next",
    end: "End",
    description:
      "Each item, shows step by step tour guide for using this panel",
  },
};

export default enTransLation;
