import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  List,
  Modal,
  Pagination,
  Row,
  Select,
  Switch,
} from "antd";
import { useForm } from "antd/es/form/Form";
import Title from "antd/lib/typography/Title";
import _ from "lodash";
import PropsTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import { useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import {
  getProductSmartWidgetDetail,
  getProductWidgetDetail,
} from "@/app/local/productWidgetSlice";
import { getProductList } from "@/app/local/websiteSlice";
import LottieNodata from "@/components/general/LottieNodata";
import SliderMenu from "@/components/general/SliderMenu";
import { useDeviceDetector } from "@/utils/hooks/useDeviceDetector";
import { useWidgetType } from "@/utils/hooks/useWidgetType";

import BasketPreview from "./components/BasketPreview";
import CategoryFilterV from "./components/CategoryFilterVirtualized";
import ProductItem from "./components/ProductItem";
import RangePriceSlider from "./components/RangePriceSlider";
import TagFiltered from "./components/TagFiltered";
import "./styles.less";
import { WidgetContext } from "./widgetBuilder";

const filtersOptions = [
  {
    key: "MostDiscount",
    value: "MostDiscount",
  },
  {
    key: "MinPrice",
    value: "MinPrice",
  },
  {
    key: "MaxPrice",
    value: "MaxPrice",
  },
  {
    key: "Descending",
    value: "Descending",
  },
  {
    key: "Ascending",
    value: "Ascending",
  },
];

export const FilterContext = createContext([]);

const initialState = {};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_website_id":
      return { ...state, websites: action.value ? [action.value] : [] };
    case "set_category":
      return {
        ...state,
        category: !_.isEmpty(action.value) ? action.value : undefined,
      };
    case "set_price":
      return {
        ...state,
        from_price: action?.value[0],
        to_price: action?.value[1],
      };
    case "set_price_reset":
      return {
        ...state,
        reset_price: action?.value,
      };
    case "search_text":
      return {
        ...state,
        original_product_id: action?.value?.original_product_id
          ? [action?.value?.original_product_id]
          : undefined,
        search_text: action?.value?.search_text,
      };
    default:
      return { ...state, [action.type]: action.value };
    // throw new Error();
  }
};

const WidgetProductSelector = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm();
  const [context, dispatch_] = useReducer(reducer, initialState);
  const [data, setData] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [menuOpen, setOpenMenu] = useState();
  const isMobile = useDeviceDetector();
  const { confirm } = Modal;
  const [contextWidget, dispatchWidget_] = useContext(WidgetContext);

  const widgetPath = useMatch("/widgets/:id");
  const wId = widgetPath?.params?.id && Number(widgetPath?.params?.id);
  const { isCustom, isNew: isNewPage } = useWidgetType();

  const { products, productsLoading } = useSelector((state) => state.website);

  const totalPage = _.get(products, "data.total_items", 0);

  useEffect(() => {
    getWidgetDetail();
  }, [widgetPath]);

  const getWidgetDetail = async () => {
    if (!isNewPage) {
      if (isCustom) {
        const result = await dispatch(getProductWidgetDetail(wId));
        if (result?.payload?.success) {
          const r = _.get(result, "payload.data");
          const webId = _.get(r, "products[0].web_store_id");
          form.setFieldsValue({ website: webId });
          dispatch_({ type: "set_website_id", value: webId });
          setSelectedProducts(r.products);
        }
      } else if (!isCustom) {
        const result = await dispatch(getProductSmartWidgetDetail(wId));
        if (result?.payload?.success) {
          const r = _.get(result, "payload.data");
          const webId = _.get(r, "products[0].web_store_id");
          form.setFieldsValue({ website: webId });
          dispatch_({ type: "set_website_id", value: webId });
          setSelectedProducts(r.products);
        }
      }
    }
  };

  useEffect(() => {
    if (!_.isEmpty(context)) {
      loadData();
    }
  }, [context]);

  useEffect(() => {
    dispatchWidget_({ type: "set_product_context", value: selectedProducts });
  }, [selectedProducts]);

  const loadData = (page_, pageSize_) => {
    const context_ = { ...context };
    delete context_.search_text;
    delete context_.sort_orientation;
    delete context_.reset_price;

    let sortO = context?.sort_orientation;
    let sortF = "productId";

    if (context.sort_orientation === filtersOptions[0].key) {
      sortF = "price_Discount";
      sortO = 1;
    } else if (context.sort_orientation === filtersOptions[1].key) {
      sortF = "price_Amount_With_Discount";
      sortO = 2;
    } else if (context.sort_orientation === filtersOptions[2].key) {
      sortF = "price_Amount_With_Discount";
      sortO = 1;
    } else if (context.sort_orientation === filtersOptions[3].key) {
      sortF = "productId";
      sortO = 1;
    } else if (context.sort_orientation === filtersOptions[4].key) {
      sortF = "productId";
      sortO = 2;
    }

    const body = Object.assign(
      {},
      {
        page: page_ || 1,
        page_size: pageSize_ || 12,
        filter: context_,
        search_text: context?.search_text,
        sort_orientation: sortO,
        sort_field: sortF,
      }
    );
    const result = dispatch(getProductList(body));
    result.then((r) => {
      const new_ = _.get(r, "payload.data.result", []);
      setData(new_);
    });
  };

  const onFinishSearch = (e) => {
    dispatch_({
      type: "search_text",
      value: {
        search_text: e.searchText,
        original_product_id: e.originalProductId,
      },
    });
  };

  useEffect(() => {
    if (contextWidget?.web_store) {
      props.ref_?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [contextWidget?.web_store, props.ref_]);

  useEffect(() => {
    if (contextWidget?.web_store) {
      const websiteSelected = contextWidget?.web_store;
      if (selectedProducts.length > 0) {
        confirm({
          title: t("widget.sureChange"),
          async onOk() {
            form.setFieldsValue({ website: websiteSelected });
            setSelectedProducts([]);
            form.setFieldsValue({ searchText: "", originalProductId: "" });
            dispatch_({ type: "set_website_id", value: websiteSelected });
            dispatch_({ type: "set_category", value: [] });
          },
          onCancel() {
            form.setFieldsValue({ website: context.websites });
          },
        });
      } else {
        dispatch_({ type: "set_website_id", value: websiteSelected });
        dispatch_({ type: "set_category", value: [] });
      }
    }
  }, [contextWidget?.web_store]);

  const onValuesChange = (e) => {
    if (_.has(e, "website")) {
      if (selectedProducts.length > 0) {
        confirm({
          title: t("widget.sureChange"),
          async onOk() {
            form.setFieldsValue({ website: e.website });
            setSelectedProducts([]);
            form.setFieldsValue({ searchText: "", originalProductId: "" });
            dispatch_({ type: "set_website_id", value: e.website });
            dispatch_({ type: "set_category", value: [] });
          },
          onCancel() {
            form.setFieldsValue({ website: context.websites });
          },
        });
      } else {
        dispatch_({ type: "set_website_id", value: e.website });
        dispatch_({ type: "set_category", value: [] });
      }
    } else if (_.has(e, "onlyExists")) {
      dispatch_({
        type: "is_available",
        value: e.onlyExists ? true : undefined,
      });
    } else if (_.has(e, "sortResult")) {
      dispatch_({ type: "sort_orientation", value: e.sortResult });
    }
  };

  const loadMoreData = (page_, pageSize_) => {
    loadData(page_, pageSize_);
  };

  const handleSelectProduct = (e, prod) => {
    document.querySelector(".badge-update")?.classList?.add("active");
    setTimeout(() => {
      document.querySelector(".badge-update")?.classList?.remove("active");
    }, 1000);
    if (e?.target?.checked) {
      const items = [...selectedProducts, prod];
      setSelectedProducts(items);
    } else {
      const items = selectedProducts.filter(
        (item) => item.product_id !== prod.product_id
      );
      setSelectedProducts(items);
    }
  };

  useEffect(() => {
    if (props.showSelectedProducts) showSelectedItems();
  }, [props.showSelectedProducts]);

  const showSelectedItems = () => {
    setOpenMenu({
      type: "SHOW_BASKET",
      title: t("product.selectedItems"),
      callbackClose: () => {
        props.setShowSelectedProducts(false);
      },
    });
  };

  return (
    <FilterContext.Provider value={[context, dispatch_]}>
      <div className="products widget-builder__list">
        <div className="carousel-help">
          <Title level={5}>{t("widget_builder.select_products")}</Title>
          <p>{t("widget_builder.select_products_from_below")}:</p>
        </div>
        <Row gutter={24}>
          <Col md={24} xs={24}>
            <Card>
              <Form
                className="widget-search-bar"
                form={form}
                layout={isMobile ? "vertical" : "horizontal"}
                onFinish={onFinishSearch}
                labelCol={12}
                onValuesChange={onValuesChange}
              >
                <Row gutter={[12, 12]}>
                  <Col md={6} xs={24}>
                    <Form.Item name="searchText">
                      <Input
                        placeholder={t("product.searchProduct")}
                        size="middle"
                        allowClear
                        prefix={
                          <Button
                            type="text"
                            style={{ height: "20px", padding: "0" }}
                            htmlType="submit"
                          >
                            <SearchOutlined />
                          </Button>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item name="originalProductId">
                      <Input
                        placeholder={t("product.productIdPlaceHolder")}
                        size="middle"
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <Col md={2} xs={24}>
                    <div className="d-flex">
                      <Button
                        htmlType="submit"
                        className="widget_search_custom"
                      >
                        {t("search")}
                      </Button>
                    </div>
                  </Col>

                  <Col md={12} xs={24}>
                    <div className="widget__filter_bar--left">
                      <Form.Item
                        name="onlyExists"
                        label={t("product.onlyExists")}
                        className={"filter_switch"}
                      >
                        <Switch />
                      </Form.Item>

                      <Form.Item name="sortResult">
                        <Select
                          defaultValue={filtersOptions[3].key}
                          size="middle"
                          style={{ width: "140px" }}
                          allowClear
                        >
                          {filtersOptions.map((item) => {
                            return (
                              <Select.Option key={item.key} value={item.value}>
                                {t("product.sort." + item.key)}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
        <br />

        {!context.websites ? (
          <Row gutter={24}>
            <Col xs={24}>
              <Empty
                description={t("widget.alertSelectWebsite")}
                image={<LottieNodata />}
                imageStyle={{ height: !isMobile ? 363 : 250 }}
              ></Empty>
            </Col>
          </Row>
        ) : null}
        {context.websites ? (
          <Row gutter={24}>
            <Col md={6} xs={24}>
              <CategoryFilterV />
              <br />
              <Card title={t("product.priceFilter")}>
                <RangePriceSlider />
              </Card>
              <br />
              <TagFiltered />
            </Col>
            <Col md={18} xs={24}>
              <div className="products__body">
                <List
                  grid={{ gutter: 16, column: !isMobile ? 4 : 1 }}
                  dataSource={data}
                  loading={productsLoading}
                  renderItem={(item) => (
                    <List.Item
                      className="product_detail"
                      key={item?.product_id}
                    >
                      <ProductItem
                        product={item}
                        handleSelectProduct={handleSelectProduct}
                        selectedProducts={selectedProducts}
                        hideButton
                      />
                    </List.Item>
                  )}
                ></List>
              </div>

              {totalPage ? (
                <Pagination
                  className="pagination"
                  defaultCurrent={1}
                  current={products?.data?.page}
                  total={totalPage}
                  // pageSize={totalPage}
                  onChange={loadMoreData}
                  defaultPageSize={12}
                  pageSizeOptions={[12, 24, 60, 120]}
                />
              ) : (
                ""
              )}
            </Col>
          </Row>
        ) : null}
        {/* 
        {selectedProducts?.length ? (
          <FooterSticky
            showSelectedItems={showSelectedItems}
            data={selectedProducts}
            setCollapsed={setOpenMenu}
          />
        ) : (
          ""
        )} */}

        <SliderMenu
          title={menuOpen?.title}
          collapsed={menuOpen}
          setCollapsed={setOpenMenu}
          callbackCloseNoConfirm={menuOpen?.callbackClose}
        >
          {/* {menuOpen?.type === "ADD_WIDGET" && (
            <FormWidget
              close={() => setOpenMenu()}
              setCollapsed={setOpenMenu}
              options={menuOpen}
              data={selectedProducts}
            />
          )}
          {menuOpen?.type === "EDIT_WIDGET" && (
            <FormWidget
              options={menuOpen}
              close={() => setOpenMenu()}
              data={selectedProducts}
            />
          )} */}
          {menuOpen?.type === "SHOW_BASKET" && (
            <BasketPreview
              close={() => setOpenMenu()}
              data={selectedProducts}
              handleSelectProduct={handleSelectProduct}
            />
          )}
        </SliderMenu>
      </div>
    </FilterContext.Provider>
  );
};

WidgetProductSelector.propTypes = {
  ref_: PropsTypes.any,
  showSelectedProducts: PropsTypes.bool,
  setShowSelectedProducts: PropsTypes.func,
};

export default WidgetProductSelector;
