import { useEffect, useRef } from "react";
import { isMobile } from "react-device-detector";

export const useDeviceDetector = () => {
  const isMobileState = useRef(isMobile);
  useEffect(() => {
    isMobileState.current = isMobile;
  }, []);
  return isMobileState.current;
};
