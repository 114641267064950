import { Form, Select } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getGroups } from "@/app/local/adminMerchantSlice";

const SelectGroup = (props) => {
  const { name, label, rules, placeholder, size, initial, type, webStoreId } =
    props;
  const dispatch = useDispatch();
  const { groupList, groupListLoading } = useSelector(
    (state) => state.adminMerchant
  );

  useEffect(() => {
    webStoreId && dispatch(getGroups(webStoreId));
  }, [webStoreId]);

  const items = _.get(groupList, "data", []);

  return (
    <Form.Item name={name} rules={rules} label={label}>
      <Select
        loading={groupListLoading}
        allowClear
        mode={type}
        placeholder={placeholder}
        showSearch
        size={size || "large"}
        filterOption={(input, option) => {
          return (
            option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        defaultValue={initial}
        showArrow
      >
        {items?.map((item) => (
          <Select.Option
            key={item.merchant_widget_grouping_id}
            value={item.merchant_widget_grouping_id}
          >
            {item.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

SelectGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  rules: PropTypes.array,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  initial: PropTypes.string,
  type: PropTypes.number,
  webStoreId: PropTypes.number,
};

export default SelectGroup;
