import { Card, Tabs } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

import TopCategories from "./TopCategories";
import TopMedias from "./TopMedias";
import TopPublishers from "./TopPublishers";

const TopInAffilio = (props) => {
  const { title } = props;

  return (
    <>
      <Card
        title={<span className="title">{t(`dashboard.${title}`)}</span>}
        className="admin_commission_chart top_in_affilio"
      >
        {/* <Row>
          <Col md={24} xs={24}> */}
        <Tabs defaultActiveKey="topTenPublishers">
          <Tabs.TabPane
            tab={t("dashboard.topTenPublishers")}
            key="topTenPublishers"
          >
            <TopPublishers />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("dashboard.topCategories")} key="topCategories">
            <TopCategories />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("dashboard.topMedias")} key="topMedias">
            <TopMedias />
          </Tabs.TabPane>
        </Tabs>
        {/* </Col>
        </Row> */}
      </Card>
    </>
  );
};

TopInAffilio.propTypes = {
  title: PropTypes.string,
};
export default TopInAffilio;
