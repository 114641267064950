import { InputDatePicker as _InputDatePicker } from "jalaali-react-date-picker";

const InputDatePicker = (props) => {
  return (
    <_InputDatePicker
      customColors={{ text: "#7d8793", dayLabelBackground: "#2f415d" }}
      wrapperClassName="jalaali-date-input"
      {...props}
    />
  );
};

export default InputDatePicker;
