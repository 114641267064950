import { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  RAYCHAT_AFF_TOKEN,
  RAYCHAT_SSP_TOKEN,
} from "@/utils/constants/configConstants";
import useConfig from "@/utils/hooks/useConfig";

const RayChat = () => {
  const { profile } = useSelector((state) => state.account);
  const { isSSP } = useConfig();

  useEffect(() => {
    const host = document.location?.host;
    let mainToken = isSSP ? RAYCHAT_SSP_TOKEN : RAYCHAT_AFF_TOKEN;
    if (host.includes("affilio.ir") || host.includes("adverge.ir")) {
      // window.CRISP_WEBSITE_ID = "337291ef-33c1-441c-a256-26ff0152107b";
      if (document.location.host.includes("stage")) {
        window.RAYCHAT_TOKEN = mainToken; // stage
      } else {
        window.RAYCHAT_TOKEN = mainToken; // production
      }
      (function () {
        const d = document;
        const s = d.createElement("script");
        s.src = "https://widget-react.raychat.io/install/widget.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
      })();
    }
  }, [isSSP]);

  useEffect(() => {
    const userDetail = profile?.userDetail?.data;

    if (userDetail) {
      const userId = profile?.data?.user_id;
      const email = userDetail?.email;
      const mobile = userDetail?.user?.mobile;
      const name = `${userDetail?.first_name} ${userDetail?.last_name} ${userId}`;
      if (window.RocketChat) {
        window.RocketChat.livechat.setGuestEmail(email);
        window.RocketChat.livechat.setGuestName(name);
        window.RocketChat.livechat.setCustomField("Phone", mobile);

        window.Raychat.setUser({
          email: email,
          name: name,
          about: userId,
          phone: mobile,
          updateOnce: true,
        });
      }
    }
  }, [profile]);
  return null;
};

export default RayChat;
