import { Column, Line } from "@ant-design/plots";
import { Empty } from "antd";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import {
  reportGetClickRedirectSSP,
  reportGetClickSSP,
  reportGetImpSSP,
  reportGetViewSSP,
} from "@/app/local/reportSlice";
import { EmptyChart } from "@/assets";
import { DashboardContext } from "@/pages/dashboard/DashboardAdmin";
import { dateFormat, kFormatter } from "@/utils/helper";

import { COLORS } from "../../../components/dashboard/colors";
import ChartBarToolbar from "./ChartBarToolbar";
import "./LineChartWidget.less";

const sampleData = [
  {
    pivot: "2023-01-10",
    total_clicks: 1,
    banner_widget_count: 0,
    product_widget_count: 1,
  },
  {
    pivot: "2023-01-24",
    total_clicks: 6,
    banner_widget_count: 0,
    product_widget_count: 6,
  },
  {
    pivot: "2023-01-25",
    total_clicks: 48,
    banner_widget_count: 0,
    product_widget_count: 48,
  },
  {
    pivot: "2023-01-30",
    total_clicks: 62,
    banner_widget_count: 0,
    product_widget_count: 62,
  },
  {
    pivot: "2023-01-23",
    total_clicks: 1,
    banner_widget_count: 1,
    product_widget_count: 0,
  },
];

const EventType = {
  VIEW: "VIEW",
  IMPRESSION: "IMPRESSION",
  CLICK: "CLICK",
  CLICK_REDIRECT: "CLICK_REDIRECT",
};
const LineChartWidget = (props) => {
  const { title, type } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pth = useMatch("/places/:id/report");
  const invId = pth?.params?.id;

  const [data, setData] = useState(sampleData);
  const [mode, setMode] = useState("LINE");

  const { reportClickSSPLoading: ld } = useSelector((state) => state.report);
  const [context, dispatch_] = useContext(DashboardContext);

  useEffect(() => {
    getChartData();
  }, [context]);

  const getData = (d) => {
    const x = d?.payload?.data?.details || [];
    return x;
  };

  const chartData = (d) => {
    const temps = [];
    const data_ = getData(d);

    data_.map((item, i) => {
      const dt = dateFormat(item.pivot, "S2");
      temps.push({
        month: dt,
        value: item.count,
        type: type,
      });
    });
    setData(temps);
  };

  const lineConfig = useMemo(() => {
    return {
      data,
      xField: "month",
      yField: "value",
      smooth: true,
      padding: "30px",
      lineStyle: {
        lineWidth: 3,
      },
      seriesField: "type",
      limitInPlot: false,
      legend: false,
      tooltip: {
        formatter: (datum) => {
          return { name: t(datum.type), value: datum.value };
        },
      },
      xAxis: {
        // tickCount: 7,
        label: {
          autoHide: true,
          rotate: 100,
          offsetX: 0,
          offsetY: 10,
          style: {
            // fill: "#fff",
            fontSize: 12,
            opacity: 0.8,
          },
        },
        grid: {
          line: {
            style: {
              stroke: "#DDE1E6",
              lineWidth: 1,
              lineDash: [4, 5],
              // strokeOpacity: 0.7,
              // shadowColor: 'black',
              shadowBlur: 10,
              shadowOffsetX: 5,
              shadowOffsetY: 5,
              cursor: "pointer",
            },
          },
        },
      },
      yAxis: {
        label: {
          offsetX: -22,
          formatter: (v) => kFormatter(v, 1),
        },
        tickLine: {
          style: {
            // stroke: '2',
            // lineWidth: 2,
            // lineDash: [4, 5],
            // strokeOpacity: 0.7,
            // shadowColor: 'black',
            // shadowBlur: 10,
            // shadowOffsetX: 5,
            // shadowOffsetY: 5,
            // cursor: 'pointer'
          },
        },
        grid: {
          line: {
            style: {
              stroke: "#DDE1E6",
              lineWidth: 1,
              lineDash: [4, 5],
              strokeOpacity: 0.7,
              // shadowColor: 'black',
              shadowBlur: 10,
              shadowOffsetX: 5,
              shadowOffsetY: 5,
              cursor: "pointer",
            },
          },
        },
      },
      height: 300,
      color: ({ type, year }, ...x) => {
        switch (type) {
          case EventType.VIEW:
            return "#FF7B95";
          case EventType.IMPRESSION:
            return "#DF5FFF";
          case EventType.CLICK_REDIRECT:
            return "#468BFF";
          default:
            return COLORS.DASHBOARD_BLUE;
        }
      },
      theme: {
        styleSheet: {
          fontFamily: "IRANYekan",
        },
      },
    };
  }, [data]);

  const columnConfig = useMemo(() => {
    return {
      data,
      xField: "month",
      yField: "value",
      seriesField: "type",
      legend: false,
      height: 230,
      isGroup: true,
      limitInPlot: false,
      tooltip: {
        formatter: (datum) => {
          return { name: t(datum.type), value: datum.value };
        },
      },
      slider: data.length > 15 ? { start: 0.5, end: 1 } : "",
      padding: [30, 5, 70, 45],
      columnStyle: {
        radius: [10, 10, 0, 0],
      },
      yAxis: {
        label: {
          formatter: (v) => kFormatter(v, 1),
        },
      },
      color: ({ type, year }, ...x) => {
        switch (type) {
          case "dashboard.view":
            return COLORS.DASHBOARD_GREEN;
          case "dashboard.click":
            return COLORS.DASHBOARD_RED;
          default:
            return COLORS.DASHBOARD_BLUE;
        }
      },
      theme: {
        styleSheet: {
          fontFamily: "IRANYekan",
        },
      },
    };
  }, [data]);

  const getChartData = async (e) => {
    if (type === EventType.CLICK) {
      const d = await dispatch(
        reportGetClickSSP({
          media_id: context.webstore,
          from: context.period.fromDate,
          to: context.period.toDate,
          inventory_id: invId,
        })
      );
      chartData(d);
    }
    if (type === EventType.CLICK_REDIRECT) {
      const d = await dispatch(
        reportGetClickRedirectSSP({
          media_id: context.webstore,
          from: context.period.fromDate,
          to: context.period.toDate,
          inventory_id: invId,
        })
      );
      chartData(d);
    }
    if (type === EventType.VIEW) {
      const d = await dispatch(
        reportGetViewSSP({
          media_id: context.webstore,
          from: context.period.fromDate,
          to: context.period.toDate,
          inventory_id: invId,
        })
      );
      chartData(d);
    }
    if (type === EventType.IMPRESSION) {
      const d = await dispatch(
        reportGetImpSSP({
          media_id: context.webstore,
          from: context.period.fromDate,
          to: context.period.toDate,
          inventory_id: invId,
        })
      );
      chartData(d);
    }
  };

  return (
    <div className="line-chart-widget">
      <ChartBarToolbar />
      <ChartHolder loading={ld} len={data?.length} height="230px">
        {mode === "BAR" && <Column {...columnConfig} loading={ld} />}
        {mode === "LINE" && <Line {...lineConfig} loading={ld} />}
      </ChartHolder>
    </div>
  );
};

export const ChartHolder = (props) => {
  return (
    <>
      {!props.len && !props.loading ? (
        <div
          style={{
            height: props.height,
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Empty
            className="img-empty"
            image={EmptyChart}
            // style={{ height: props.height }}
          />
        </div>
      ) : (
        props.children
      )}
    </>
  );
};

ChartHolder.propTypes = {
  len: PropTypes.any,
  height: PropTypes.string,
  children: PropTypes.any,
  loading: PropTypes.bool,
};

LineChartWidget.propTypes = {
  isMerchant: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
};

export default LineChartWidget;
