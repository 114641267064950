import { DesktopOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, matchRoutes, useLocation } from "react-router-dom";

import { parentsIcon, getPrivateRoutes as x } from "@/app/routes";
import { getPrivateRoutes as y } from "@/app/routes-ssp";
// import LogoMin from "@/assets/images/logo-min.svg";
// import ArrowRight from "@/assets/images/arrow-right-sidebar.svg";
import Logo from "@/assets/images/logo-sidebar-off.svg";
import LogoSSP from "@/assets/images/logo-sidebar-ssp.svg";
// import LogoSSPMin from "@/assets/images/ssp/logo-min.svg";
import { USER_TYPE } from "@/utils/constants/apiConstants";
import { useAccount } from "@/utils/hooks/useAuth";
import useConfig from "@/utils/hooks/useConfig";
import { useDeviceDetector } from "@/utils/hooks/useDeviceDetector";

import ButtonCollapse from "../extra/ButtonCollapse";
import "./SidebarHelium.less";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SidebarHelium = (props) => {
  const { isSSP } = useConfig();
  let getPrivateRoutes = x;
  if (isSSP) {
    getPrivateRoutes = y;
  }
  const isWizard = props.mode === "wizard";
  // const [collapsed, setCollapsed] = useState(isWizard || false);
  const { collapsed, setCollapsed } = props;
  const { t } = useTranslation();
  const { user, role, roleId } = useAccount();
  const isMobile = useDeviceDetector();
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const locationPath = location?.pathname;
  const [selectedMenu, setSelectedMenu] = useState([]);

  const onCollapse = () => {
    if (isWizard) return;
    setCollapsed((old) => !old);
  };

  useEffect(() => {
    !collapsed && isMobile && setCollapsed(true);
  }, [isMobile]);

  const privateRoutes = useMemo(() => {
    // Get all that options must be shown in sidebar
    let routes = getPrivateRoutes(role).filter((item) => item.menu !== false);
    // Filter by privileges
    routes = routes.filter(
      (item) =>
        !item?.privileges ||
        item?.privileges?.find((i) =>
          user?.privileges?.find((j) => j.privilege_id === i)
        )
    );

    const refineRoute = [];

    routes.forEach((item) => {
      const isExist =
        refineRoute.findIndex((item1) => item1.parent === item.parent) >= 0;

      if (roleId !== USER_TYPE.SUPER_ADMIN && roleId !== USER_TYPE.ADMIN) {
        refineRoute.push({ ...item, parent: undefined });
        return;
      }
      if (item.parent && !isExist) {
        const children = routes.filter((item2) => item.parent === item2.parent);
        const entity = {
          parent: item.parent,
          title: t("page." + item.parent),
          key: item.parent,
          icon: parentsIcon[item.parent],
          children: children,
        };
        if (children.length > 1) {
          refineRoute.push(entity);
        } else {
          delete children[0].parent;
          refineRoute.push(children[0]);
        }
      } else if (!item.parent) {
        refineRoute.push(item);
      }
    });

    console.log("refineRoute", refineRoute);

    // grouping menu
    const groupMenus = {};

    const realMenues = {};

    const d = (sKey, item) => {
      if (groupMenus[sKey]) {
        groupMenus[sKey].push(item);
      } else {
        groupMenus[sKey] = [item];
      }
      return item;
    };
    refineRoute.forEach((item) => {
      const subsetKey = item?.options?.subset?.publisher;
      d(subsetKey || "other", item);
    });

    const other = groupMenus.other;
    if (other) {
      delete groupMenus.other;
      groupMenus.other = other;
    }

    return groupMenus;
  }, [role]);

  console.log("privateRoutes", privateRoutes);

  const getRouteKey = () => {
    const routes = getPrivateRoutes(role);
    const currentRouteObject = matchRoutes(routes, location);
    const cp = _.get(currentRouteObject, "[0].route.parent", "");

    const pRoutes = _.flatten(_.values(privateRoutes));

    try {
      let index;
      pRoutes.find((item, i) => {
        // if (!item.parent) {
        if (item.path === locationPath) {
          index = [i];
          return;
        } else if (cp === item.path.replace("/", "")) {
          index = [i];
          return;
        }
      });

      let routeKey;

      if (index?.length === 1) {
        routeKey = ["menu_" + index];
      } else if (index?.length === 2) {
        routeKey = ["sub_" + index[0] + "_" + index[1]];
        setOpenKeys(["sub_" + index[0]]);
      } else routeKey = selectedMenu;
      setSelectedMenu(routeKey);
    } catch (e) {
      const cashing = "";
    }
  };

  useEffect(() => {
    if (privateRoutes) {
      getRouteKey();
    }
  }, [locationPath]);

  let i = 0;
  return (
    <Sider
      width={isMobile ? 350 : 240}
      collapsed={collapsed}
      onCollapse={onCollapse}
      style={{ zIndex: 2000 }}
      className={
        collapsed
          ? "sidebar-helium sidebar-helium--collapsed"
          : "sidebar-helium"
      }
    >
      <div
        className={`menu_logo ${collapsed ? "menu_logo--collapsed" : ""}`}
        // onClick={onCollapse}
      >
        {!collapsed ? (
          <>
            <img src={isSSP ? LogoSSP : Logo} />
            <ButtonCollapse
              setCollapsed={props.setCollapsed}
              collapsed={props.collapsed}
            />
          </>
        ) : (
          // <img src={isSSP ? LogoSSPMin : LogoMin} className={"mini-logo"} />
          <ButtonCollapse
            setCollapsed={props.setCollapsed}
            collapsed={props.collapsed}
          />
        )}
      </div>

      <Menu
        disabled={isWizard}
        className={isSSP ? "main_menu main_menu__flat" : "main_menu"}
        defaultSelectedKeys={"menu_0"}
        defaultOpenKeys={selectedMenu}
        selectedKeys={selectedMenu}
        mode="inline"
        openKeys={openKeys}
      >
        {Object.keys(privateRoutes).map((key) => {
          return (
            <div key={key} className={"section__wrapper"}>
              {!isSSP ? (
                <div className="section__title">
                  {t(`sidebar.${key}`)}
                  {key === "aff-suggest" && (
                    <span className="section__title__news">جدید</span>
                  )}
                </div>
              ) : (
                false
              )}
              {privateRoutes[key].map((item) => {
                i++;
                const kName = `menu_${i - 1}`;
                return (
                  <div key={kName}>
                    <Menu.Item
                      key={kName}
                      className={
                        selectedMenu &&
                        selectedMenu.length > 0 &&
                        selectedMenu[0] === kName
                          ? "menu__active"
                          : null
                      }
                      icon={
                        <Link to={item?.path}>
                          {item.icon || <DesktopOutlined />}
                        </Link>
                      }
                      // onClick={() => handleOpen(item)}
                    >
                      <Link to={item?.path}>{t(`page.${item.title}`)}</Link>
                    </Menu.Item>
                  </div>
                );
              })}
            </div>
          );
        })}
      </Menu>
    </Sider>
  );
};

SidebarHelium.propTypes = {
  mode: PropTypes.string,
  setCollapsed: PropTypes.func,
  collapsed: PropTypes.bool,
};

export default SidebarHelium;
