import { Button, message } from "antd";
import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { CodeBlock } from "react-code-blocks";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getProductWidgetCode } from "@/app/local/productWidgetSlice";
import IconJS from "@/assets/images/Icon-JS.svg";
import IconReactJs from "@/assets/images/Icon-ReactJS.svg";
import IconPhp from "@/assets/images/Icon-php.svg";
import { WidgetContext } from "@/pages/link/detail/widgetBuilder";
import { CODE_LANGUAGE } from "@/utils/constants/apiConstants";
import { useWidgetType } from "@/utils/hooks/useWidgetType";
import { prettyCode } from "@/utils/utils";

import "./WidgetPreviewCode.less";

export const ToolbarSelectLanguage = () => {
  return (
    <div className="toolbar-lang">
      <ul>
        <li className="selected">
          <img src={IconJS} />
          <h4>JS</h4>
        </li>
        <li className="disabled">
          <img src={IconReactJs} />
          <h4>REACT JS</h4>
        </li>
        {/* <li className="disabled">
          <img src={IconAngular} />
          <h4>ANGULAR JS</h4>
        </li>
        <li className="disabled">
          <img src={IconVueJs} />
          <h4>VUE JS</h4>
        </li> */}
        <li className="disabled">
          <img src={IconPhp} />
          <h4>PHP</h4>
        </li>
        {/* <li className="disabled">
          <img src={IconDotNet} />
          <h4>.NET</h4>
        </li> */}
        {/* <li className="disabled">
          <img src={IconRails} />
          <h4>Rails</h4>
        </li> */}
        {/* <li className="disabled">
    <img src={IconJS} />
    <h4>NextJs</h4>
  </li> */}
      </ul>
    </div>
  );
};

const WidgetPreviewCode = (props) => {
  const { widgetCode, widgetCodeLoading, widgetAddCode } = useSelector(
    (state) => state.productWidget
  );
  const [dataContext, dispatch_] = useContext(WidgetContext);

  const [code, setCode] = useState();
  const dispatch = useDispatch();
  const { isCustom, encId } = useWidgetType();

  const params = useParams();
  // const widgetId = params?.id;

  useEffect(() => {
    let d;
    if (props.data) {
      setCode(props.data);
    } else if (isCustom || !widgetCode) {
      const wId = encId || _.get(widgetAddCode, "data[0].encrypted_widget_id");
      if (wId) {
        const result = dispatch(
          getProductWidgetCode({
            encrypted_widget_id: wId,
            code_language: CODE_LANGUAGE.JavaScript,
            config: dataContext.config,
          })
        );
        result.then((r) => {
          d = r.payload?.data?.value;
          d && setCode(prettyCode(d));
        });
      }
    } else {
      d = widgetCode?.data?.value;
      d && setCode(prettyCode(d));
    }
  }, []);

  const copyCode = (wId) => {
    navigator.clipboard.writeText(code);
    message.success({
      content: t("general.copySuccessFully"),
    });
  };

  return (
    <div className="widget-builder__code-area">
      <ToolbarSelectLanguage />
      <div className="code-area">
        {code ? (
          <CodeBlock
            text={code}
            language={"html"}
            showLineNumbers={18}
            // theme={dracula}
            CodeBlock
          />
        ) : (
          false
        )}
      </div>
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        <Button type="primary" onClick={copyCode}>
          {t("general.copy")}
        </Button>
      </div>
    </div>
  );
};

WidgetPreviewCode.propTypes = {
  data: "",
};
export default WidgetPreviewCode;
