import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useController } from "react-hook-form";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const CustomTextField = (props) => {
  const {
    control,
    rules,
    name,
    variant = "outlined",
    color = "primary",
    fullWidth,
    error,
    errorText,
    maxLength,
    inputHeight,
    className,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { field } = control
    ? useController({
        name,
        control,
        rules,
      })
    : {};

  const errorMassage = {
    maxLength: t("max_length"),
    minLength: t("min_length"),
    required: t("required_field"),
    pattern: t("non_match_pattern"),
  };

  return (
    <TextField
      {...props}
      variant={variant}
      color={color}
      className={clsx(classes.root, className)}
      fullWidth={fullWidth}
      value={props.value ?? ""}
      error={typeof error === "boolean" ? error : !!error}
      helperText={
        typeof errorText === "string" && error
          ? errorText
          : error?.message
          ? error?.message
          : errorMassage[error?.type] ?? ""
      }
      inputProps={{
        maxLength: maxLength,
        style: {
          height: inputHeight,
        },
      }}
      {...field}
    />
  );
};

CustomTextField.propTypes = {
  className: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  variant: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  inputRef: PropTypes.object,
  error: [PropTypes.bool, PropTypes.object],
  errorText: PropTypes.string,
  maxLength: PropTypes.number,
  InputProps: PropTypes.object,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  InputLabelProps: PropTypes.object,
  pattern: PropTypes.string,
  multiline: PropTypes.bool,
  inputHeight: PropTypes.string,
  ref: PropTypes.object,
};

export default CustomTextField;
const useStyles = makeStyles((theme) => {
  return {
    root: {
      /* Change the input's background color in autofill */
      "& :-webkit-autofill": {
        "&:hover": {
          "-webkit-box-shadow": "0 0 0 30px white inset !important",
        },
        "&:focus": {
          "-webkit-box-shadow": "0 0 0 30px white inset !important",
        },
        "&:active": {
          "-webkit-box-shadow": "0 0 0 30px white inset !important",
        },
        "-webkit-box-shadow": "0 0 0 30px white inset !important",
      },

      backgroundColor: "transparent",
      width: "100%",
      "& fieldset": {
        border: "none",
        ...theme.typography.body2.regular,
      },
      "& .MuiOutlinedInput-input": {
        direction: "ltr",
        textAlign: "start",
      },
      "& .MuiInputBase-root": {
        ...theme.typography.body2.regular,
        color: theme.palette.secondary.dark,
        borderRadius: theme.spacing(1),
        border: `1px solid ${theme.palette.common.gray6}`,
        "&.Mui-error": {
          borderRadius: theme.spacing(1),
          border: `1px solid ${theme.palette.error.main}`,
          "&.Mui-focused": {
            borderRadius: theme.spacing(1),
            border: `1px solid ${theme.palette.error.main}`,
          },
          "&:hover": {
            borderRadius: theme.spacing(1),
            border: `1px solid ${theme.palette.error.main}`,
          },
        },
        "&.Mui-focused": {
          border: `1px solid ${theme.palette.primary.main}`,
        },
        "&.Mui-disabled": {
          border: `1px solid ${theme.palette.grey.G300}`,
          backgroundColor: theme.palette.grey.G50,
        },
        "&:hover": {
          borderColor: "none",
          // borderColor: theme.palette.primary.main,
        },
      },

      "& .MuiFormLabel-root": {
        backgroundColor: "white",
        zIndex: 100,
        padding: "0 8px 0 8px",
        marginTop: "2px",
        [theme.breakpoints.down("md")]: {
          padding: "-8px 0 8px 0px",
        },
        fontSize: "14px",
        color: theme.palette.secondary.main,
        "&.Mui-error": {
          color: theme.palette.error.main,
          "&.Mui-focused": {
            color: theme.palette.error.main,
          },
        },
        "&.Mui-focused": {
          color: theme.palette.primary.main,
        },
      },
      "& .MuiFormHelperText-root": {
        ...theme.typography.body2.regular,
        color: theme.palette.error.main,
        fontSize: "11px",
      },
    },
  };
});

/**
 * The `TextField` is a convenience wrapper for the most common cases (80%).
 * It cannot be all things to all people, otherwise the API would grow out of control.
 *
 * ## Advanced Configuration
 *
 * It's important to understand that the text field is a simple abstraction
 * on top of the following components:
 *
 * -   [FormControl](https://material-ui.com/api/form-control/)
 * -   [InputLabel](https://material-ui.com/api/input-label/)
 * -   [FilledInput](https://material-ui.com/api/filled-input/)
 * -   [OutlinedInput](https://material-ui.com/api/outlined-input/)
 * -   [Input](https://material-ui.com/api/input/)
 * -   [FormHelperText](https://material-ui.com/api/form-helper-text/)
 *
 * If you wish to alter the props applied to the `input` element, you can do so as follows:
 *
 * ```jsx
 * const inputProps = {
 *   step: 300,
 * };
 *
 * return <TextField id="time" type="time" inputProps={inputProps} />;
 * ```
 *
 * For advanced cases, please look at the source of TextField by clicking on the
 * "Edit this page" button above. Consider either:
 *
 * -   using the upper case props for passing values directly to the components
 * -   using the underlying components directly as shown in the demos
 * Demos:
 *
 * - [Autocomplete](https://material-ui.com/components/autocomplete/)
 * - [Pickers](https://material-ui.com/components/pickers/)
 * - [Text Fields](https://material-ui.com/components/text-fields/)
 *
 * API:
 *
 * - [TextField API](https://material-ui.com/api/text-field/)
 * - inherits [FormControl API](https://material-ui.com/api/form-control/)
 */
