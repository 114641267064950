import { Button, Checkbox, Divider, Form, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
// import { t } from "i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import { getMerchantDetail } from "@/app/local/adminMerchantSlice";
import { getPublishDetail } from "@/app/local/adminPublisherSlice";
import { setKycStatus, setUserStatus } from "@/app/local/adminUserSlice";
import { USER_PAGE } from "@/pages/profile";
import { getProfileData } from "@/pages/profile";
import {
  KYC_STATUS,
  KYC_STATUS_LEGAL,
  KYC_STATUS_PRIVATE,
  USER_STATUS,
  USER_TYPE,
} from "@/utils/constants/apiConstants";
import { isLegalUser } from "@/utils/helper";

export const getKycOptions = (profile, t) => {
  const isLegal = isLegalUser(profile);
  const kysItems = isLegal ? KYC_STATUS_LEGAL : KYC_STATUS_PRIVATE;
  const kycFiltered = Object.keys(kysItems);

  const options_ = kycFiltered.map((key) => ({
    label: t("kycDoc." + key),
    value: kysItems[key],
  }));
  return options_;
};

const FormKyc = (props) => {
  const profile = getProfileData();
  const userInfo = props.userInfo?.user;
  const userType = _.get(props, "userInfo.user.user_type");
  const prefixUrl =
    userType === USER_TYPE.PUBLISHER || userType === USER_TYPE.ADVERTISER
      ? USER_PAGE.publishers
      : USER_PAGE.merchants;
  const user = useMatch(`/${prefixUrl}/` + ":id");

  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const [form] = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.adminUser);

  const options = useCallback(() => {
    return getKycOptions(profile, t);
  }, [userType]);
  const options_ = options();

  useEffect(() => {
    const kysStatus = userInfo?.kyc_status;
    const kycDesc = userInfo?.kyc_desc;
    if (kysStatus) {
      form.setFieldsValue({ kyc: kysStatus });
      form.setFieldsValue({ kycDesc: kycDesc });

      if (kysStatus?.length >= options?.length) {
        setCheckAll(true);
        setIndeterminate(false);
      }
    }
  }, []);

  const onFinish = async (e) => {
    const uId = user?.params?.id;
    const kysStatus = userInfo?.kyc_status;
    const kyc_ = e.kyc || [];

    const res = await dispatch(
      setKycStatus({
        user_id: uId,
        kyc_status: kyc_,
        description: e.kycDesc,
      })
    );

    if (res.payload?.success) {
      message.success({
        content: t("general.saveSuccessFully"),
        key: "kyc",
      });
      if (e.kycDesc) {
        await dispatch(
          setUserStatus({
            user_id: uId,
            status: USER_STATUS.KysError,
            description: e.userStatusDesc,
          })
        );
      }
      // else {
      //   await dispatch(
      //     setUserStatus({
      //       user_id: uId,
      //       status: USER_STATUS.Confirmed,
      //     })
      //   );
      // }
      if (userType === USER_TYPE.PUBLISHER) {
        dispatch(getPublishDetail(uId));
      } else {
        dispatch(getMerchantDetail(uId));
      }
      props.close();
    } else {
      message.error({
        content: res.payload?.Message || t("general.unHandleErrorMessage"),
        key: "kyc",
      });
    }
  };

  const onCheckAllChange = (e) => {
    if (e.target.checked) {
      form.setFieldsValue({
        kyc: Object.values(KYC_STATUS),
      });
      setCheckAll(true);
      setIndeterminate(false);
    } else {
      form.setFieldsValue({
        kyc: [],
      });
      setCheckAll(false);
    }
  };

  const onValuesChange = (e) => {
    if (e.kyc.length < options_?.length) {
      setIndeterminate(true);
    } else {
      setIndeterminate(false);
      setCheckAll(true);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      className="profile_form-kyc"
    >
      <div>{t("kycDoc.kysDocApprove")}</div>
      <br />
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        {t("general.selectAll")}
      </Checkbox>
      <Divider style={{ margin: "8px 0" }} />
      <Form.Item name={"kyc"}>
        <Checkbox.Group
          options={options_}
          defaultValue={[]}
          className="checkbox-group"
        />
      </Form.Item>

      <Form.Item shouldUpdate>
        {() => {
          let kycI = form.getFieldValue("kyc") || [];
          kycI = kycI.length > 7 ? kycI?.filter((item) => item !== 1) : kycI;
          const kycCount = kycI?.length || 0;
          const showDesc = kycCount < options_?.length;

          return showDesc ? (
            <Form.Item
              rules={[
                {
                  required: true,
                },
              ]}
              name={"kycDesc"}
              label={t("kycDoc.kysDescription")}
            >
              <TextArea rows={4} />
            </Form.Item>
          ) : (
            ""
          );
        }}
      </Form.Item>
      <Form.Item
        wrapperCol={{
          // offset: 8,
          span: 16,
        }}
        className="form_actions"
      >
        <Button loading={loading} size="large" type="primary" htmlType="submit">
          {t("general.submit")}
        </Button>
      </Form.Item>
    </Form>
  );
};

FormKyc.propTypes = {
  userInfo: PropTypes.object,
  close: PropTypes.func,
};

export default FormKyc;
