import { Card } from "antd";
import React, { useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

import {
  DashClickIcon,
  DashCtrIcon,
  DashImpIcon,
  DashViewIcon,
  MenuIcon,
  WinRateIcon,
} from "@/assets/images/ssp";
import { numberFormat } from "@/utils/helper";

import DonutChart from "../charts/DonutChart";
import "./TileCount.less";

export const TILE_TYPE = {
  VIEW: "view",
  IMP: "imp",
  CLICK: "click",
  CTR: "ctr",
  FILL_RATE: "fill_rate",
  WIN_RATE: "win_rate",
};

const TileCount = ({ type, inputData }) => {
  const { t } = useTranslation("ssp");
  const initData = {
    type: TILE_TYPE.CLICK,
    percentChange: "2.6",
    statusChange: "up", // "up" | "down" | "same"
    real: 218793,
    fraud: 302,
    total: 2132132,
    isPercent: false,
    icon: DashImpIcon,
  };
  const [detail, setDetail] = useState(initData);

  useMemo(() => {
    const data_ = {
      fraud: numberFormat(inputData?.fraud),
      real: numberFormat(inputData?.total - (inputData?.fraud || 0)),
      total: numberFormat(inputData?.total),
      totalRaw: inputData?.total,
      percent: inputData?.percent,
    };
    // if (inputData) {
    if (type === TILE_TYPE.VIEW) {
      setDetail({
        type: type,
        percent: 0,
        icon: DashViewIcon,
        ...data_,
      });
    }
    if (type === TILE_TYPE.IMP) {
      setDetail({
        type: type,
        percent: 0,
        icon: DashImpIcon,
        ...data_,
      });
    }
    if (type === TILE_TYPE.CLICK) {
      setDetail({
        type: type,
        percent: inputData?.percent,
        status: true,
        icon: DashClickIcon,
        ...data_,
      });
    }
    if (type === TILE_TYPE.CTR) {
      setDetail({
        type: type,
        percent: 0,
        icon: DashCtrIcon,
        isPercent: true,
        ...data_,
      });
    }
    if (type === TILE_TYPE.FILL_RATE) {
      setDetail({
        type: type,
        percent: 0,
        icon: MenuIcon,
        isPercent: true,
        ...data_,
      });
    }
    if (type === TILE_TYPE.WIN_RATE) {
      setDetail({
        type: type,
        percent: 0,
        icon: WinRateIcon,
        isPercent: true,
        ...data_,
      });
    }
    // }
  }, [type, inputData]);

  return (
    <Card className={`tile-count tile-count--${detail.type}`}>
      <div className="title-count__main">
        <div className="tile-count__title">
          <img src={detail.icon} />
          <p>{t(`dashboard.${detail.type}`)}</p>
        </div>
        <div className={`percent ${detail?.percent__status || ""}`}>
          <i>{detail?.percent || "0"}%</i>
        </div>
        <div className="tile-count__counter">
          {/* <CountUp delay={2} end={detail.real || 0} /> */}
          <CountUp delay={2} end={detail?.totalRaw || 0} />
          {detail.isPercent ? "%" : ""}
        </div>
        <div className="tile-count__overview">
          <div className="overview">
            <ul>
              <li>
                <span className="label-title">
                  {t(`dashboard.total_${detail.type}`)}:
                </span>
                <span>{detail?.total}</span>
              </li>
              <li>
                <span className="label-title">
                  {t(`dashboard.fraud_${detail.type}`)}:
                </span>
                <span>{detail?.fraud}</span>
              </li>
            </ul>
          </div>
          <div className="chart">
            <DonutChart type={detail.type} value={100} />
          </div>
        </div>
      </div>
    </Card>
  );
};

TileCount.propTypes = {
  type: "",
  inputData: "",
};
export default TileCount;
