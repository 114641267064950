import PropTypes from "prop-types";
import React from "react";
import Lottie from "react-lottie";

import * as SuccessData2 from "@/assets/images/lottie/success-anim-2.json";
import * as SuccessData from "@/assets/images/lottie/success-anim.json";

const LottieSuccess = (props) => {
  const animationData = props.type === "2" ? SuccessData2 : SuccessData;
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: props.success ? animationData : "",
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height={300}
      width={300}
      //   isStopped={isStopped}
      //   isPaused={isPaused}
    />
  );
};
LottieSuccess.propTypes = {
  success: PropTypes.bool,
  type: PropTypes.string,
};

export default LottieSuccess;
