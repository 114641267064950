import React from "react";
import ReactDOM from "react-dom";

import TestCom from "./TestCom/TestCom";
import TestCom2 from "./TestCom/TestCom2";
import TestCom3 from "./TestCom/TestCom2";
import TestComY from "./TestCom/TestComY";
import InTest from "./TestCom/InTest";
import MainTicket from "./tickets/TicketList";
import TicketCreate from "./ticket/TicketCreate";
import TicketReply from "./ticketReply/TicketReply";

setTimeout(() => {
  const d = document.getElementById("mainApp");
  if (d) ReactDOM.render(<InTest />, d);
});
setTimeout(() => {
  const d = document.getElementById("mainTicket");
  if (d) ReactDOM.render(<MainTicket />, d);
});
setTimeout(() => {
  const d = document.getElementById("createTicket");
  if (d) ReactDOM.render(<TicketCreate />, d);
});

// const TestComx = React.lazy(() => import("./TestCom/TestCom"));

// import Tickets from "./tickets/index";
// const TestCom = () => {
//   return (
//     <Suspense fallback={<>xxxx</>}>
//       <TestComx />
//     </Suspense>
//   );
// };

// export default InTest;
export {
  TicketReply,
  TicketCreate,
  MainTicket,
  InTest,
  TestCom,
  TestCom2,
  TestCom3,
  TestComY,
};
