import { Input } from "@material-ui/core";
import React from "react";

const TestComX = () => {
  return (
    <React.StrictMode>
      <div>
        <button>TestComX</button>
      </div>
    </React.StrictMode>
  );
};

export default TestComX;
