import { Form, Input, InputNumber, Switch } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import SelectBannerSize from "@/components/input/selectBannerSize";
import SelectContentDisplayType from "@/components/input/selectContentDisplayType";
import SelectPositionType from "@/components/input/selectPositionType";
import SelectTypeDevice from "@/components/input/selectTypeDevice";
import {
  CONTENT_DISPLAY_TYPE,
  DEVICE_TYPE,
  INVENTORY_TYPE,
} from "@/utils/constants/apiConstants";
import { defaultRules } from "@/utils/helper";

import { PlaceContext } from "../AdPlaceBuilder";
import ResetButton from "../components/place/ResetButton";
import "./FormMaster.less";

export const bannerSizeOptions = [
  {
    key: 1,
    w: 300,
    h: 250,
  },
  {
    key: 2,
    w: 300,
    h: 100,
  },
  {
    key: 3,
    w: 468,
    h: 60,
  },
  {
    key: 4,
    w: 728,
    h: 90,
  },
];

const FormMaster = (props) => {
  const { t } = useTranslation("ssp");
  const [context, dispatchContext] = useContext(PlaceContext);

  const { onFinish, form, config, onValuesChange } = props;
  const isNative = context?.type === INVENTORY_TYPE.native;

  const onFinish_ = (e) => {
    onFinish(e);
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish_}
        onValuesChange={onValuesChange}
        className="form-master"
        initialValues={{
          max_item_count: config?.max_item_count || 3,
          position_type:
            config?.position_type_vertical || config?.position_type,
          max_mobile_item_count: config?.max_mobile_item_count || 3,
          start_delay_second: config?.start_delay_second || 0,
          enable_to_close_delay_second:
            config?.enable_to_close_delay_second || 5,
          device_type: _.keys(DEVICE_TYPE),
          content_display_type:
            config?.content_display_type || CONTENT_DISPLAY_TYPE.ImageOnTop,
          responsive_type: "checked",
        }}
      >
        {isNative ? (
          <Form.Item
            name={"title"}
            label={t("place.placeTitle")}
            rules={defaultRules(t)}
          >
            <Input placeholder={t("place.placeTitle")} />
          </Form.Item>
        ) : (
          false
        )}

        <SelectTypeDevice
          name={"device_type"}
          label={t("place.deviceType")}
          rules={defaultRules(t)}
          size="large"
          placeholder={t("place.placeTitle")}
        />

        {config?.content_display_type ? (
          <SelectContentDisplayType
            name={"content_display_type"}
            label={t("display_type")}
            rules={defaultRules(t)}
            placeholder={t("display_type")}
            excludedOptions={config?.excluded_content}
          />
        ) : (
          false
        )}

        {config?.start_delay_second ? (
          <Form.Item
            name={"start_delay_second"}
            label={t("place.start_delay_second")}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder={t("place.start_delay_second")}
              min={0}
              max={20}
            />
          </Form.Item>
        ) : (
          false
        )}
        {config?.enable_to_close_delay_second ? (
          <Form.Item
            name={"enable_to_close_delay_second"}
            label={t("place.enable_to_close_delay_second")}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder={t("place.enable_to_close_delay_second")}
              min={5}
              max={20}
            />
          </Form.Item>
        ) : (
          false
        )}
        {config?.max_item_count ? (
          <Form.Item
            name={"max_item_count"}
            label={t("place.max_item_count")}
            help={<sub>{t("place.max_item_count_is_8")}</sub>}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder={t("place.max_item_count")}
              min={1}
              max={8}
            />
          </Form.Item>
        ) : (
          false
        )}
        {config?.max_mobile_item_count ? (
          <Form.Item
            name={"max_mobile_item_count"}
            label={t("place.max_mobile_item_count")}
            help={<sub>{t("place.max_item_count_is_2")}</sub>}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder={t("place.max_mobile_item_count")}
              min={1}
              max={4}
            />
          </Form.Item>
        ) : (
          false
        )}
        {config?.responsive_type ? (
          <Form.Item
            name={"responsive_type"}
            label={t("place.responsive_type")}
            valuePropName="checked"
          >
            <Switch
              checkedChildren={t("responsive_types.active")}
              unCheckedChildren={t("responsive_types.in_active")}
            />
          </Form.Item>
        ) : (
          false
        )}
        {config?.size ? (
          <SelectBannerSize defaultSizes={config?.defaultSize} />
        ) : (
          false
        )}
        {config?.position_type ? (
          <SelectPositionType
            name={"position_type"}
            label={t("place.position_type")}
          />
        ) : (
          false
        )}
        {config?.position_type_vertical ? (
          <SelectPositionType
            isVertical
            name={"position_type"}
            label={t("place.position_type")}
          />
        ) : (
          false
        )}
      </Form>
      <>
        <ResetButton />
      </>
    </>
  );
};

FormMaster.propTypes = {
  form: PropTypes.any,
  onFinish: PropTypes.any,
  config: PropTypes.any,
  onValuesChange: PropTypes.any,
};

export default FormMaster;
