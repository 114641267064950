import { useMatch, useNavigate } from "react-router-dom";
import { TicketReply as TicketReplyMain } from "smartech-support";

import MainContainer from "@/components/container/MainContainer";
import PageTitle from "@/components/general/PageTitle";
import { HELPICAL_DEPARTMENT_ID } from "@/utils/constants/configConstants";
import { useAccount } from "@/utils/hooks/useAuth";

const TicketReply = () => {
  const { userDetail } = useAccount();
  const nav = useNavigate();
  const replyId = useMatch("/tickets/:id")?.params?.id;
  const selectedTicket = replyId;

  const currentDepartment = HELPICAL_DEPARTMENT_ID;

  const userProfile = {
    email: userDetail?.email,
    firstName: userDetail?.first_name,
    lastName: userDetail?.last_name,
    id: userDetail?.mobile,
    phone: userDetail?.mobile,
  };

  return (
    <div>
      <PageTitle title={"TicketEdit"} />
      <MainContainer>
        <TicketReplyMain
          router={nav}
          selectedTicket={selectedTicket}
          options={{
            currentDepartment: currentDepartment,
            userProfile: userProfile,
          }}
        />
      </MainContainer>
    </div>
  );
};

export default TicketReply;
