import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";

const StatusBadge = ({ backgroundColor, fontColor, data }) => {
  const useStyles = makeStyles((theme) => {
    return {
      root: {
        color: fontColor,
        backgroundColor: backgroundColor,
        width: "max-content",
        "& > span": {
          fontSize: theme.typography.caption1.bold.fontSize,
          fontWeight: theme.typography.caption1.bold.fontWeight,
        },
      },
    };
  });

  const classes = useStyles();

  return (
    <>
      <Chip label={data} className={classes.root} />
    </>
  );
};

StatusBadge.propTypes = {
  backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  fontColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  data: PropTypes.string,
};

export default StatusBadge;
