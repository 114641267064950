import {
  AppstoreAddOutlined,
  AppstoreOutlined,
  BarChartOutlined,
  BookOutlined,
  MessageOutlined,
  QuestionCircleOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import React, { lazy } from "react";

import {
  IconBanner,
  IconCampaign,
  IconCommission,
  IconDashboard,
  IconFinance,
  IconInvoice,
  IconLink,
  IconMedia,
  IconOrders,
  IconProfile,
  IconReport,
  IconSetting,
  IconTag,
  IconWebsites,
  IconWidget,
} from "@/assets/icon-pack";
import SidebarIcon from "@/assets/images/sidebar";
import Dashboard from "@/pages/dashboard";
// import UiKit from "@/pages/features/kits";
import InvoiceView from "@/pages/invoice/InvoiceView";
// import UiKits from "@/pages/kits/kits";
import ForgotPassword from "@/pages/public/forgot-password";
import LoginPage from "@/pages/public/login";
import RegisterPage from "@/pages/public/register";
import ResetPassword from "@/pages/public/resetPassword";
import Ticket from "@/pages/ticket/ticket";
import TicketReply from "@/pages/ticketReply/TicketReply";
import Tickets from "@/pages/tickets/tickets";
import RegisterWizard from "@/pages/wizard";
import { Privileges } from "@/utils/constants/accessCenter";
import { USER_TYPE } from "@/utils/constants/apiConstants";
import { MANUAL_CONFIG } from "@/utils/constants/configConstants";

const PayoutMerchantList = React.lazy(() =>
  import("@/components/finance/PayoutMerchantList")
);
const PayoutPublisherList = React.lazy(() =>
  import("@/components/finance/PayoutPublisherList")
);
const ContractDetail = React.lazy(() =>
  import("@/pages/contract/contractDetail")
);
const ContractTemplate = React.lazy(() =>
  import("@/pages/contract/contractTemplate")
);
const ContractTemplates = React.lazy(() =>
  import("@/pages/contract/contractTemplates")
);
const ContractView = React.lazy(() => import("@/pages/contract/contractView"));
const ContractsUserList = React.lazy(() =>
  import("@/pages/contract/contractsUserList")
);
const FAQ = React.lazy(() => import("@/pages/faq/faq"));
const FinanceMerchant = React.lazy(() =>
  import("@/pages/finance/FinanceMerchant")
);
const FinancePrintView = React.lazy(() =>
  import("@/pages/finance/FinancePrintView")
);
const FinancePublisher = React.lazy(() =>
  import("@/pages/finance/FinancePublisher")
);
const Groups = React.lazy(() => import("@/pages/group/groups"));
const InvoiceDetail = React.lazy(() => import("@/pages/invoice/InvoiceDetail"));
// const InvoiceView = React.lazy(() => import("@/pages/invoice/InvoiceView"));
const InvoicesMerchant = React.lazy(() =>
  import("@/pages/invoice/InvoicesMerchant")
);
const InvoicesPublisher = React.lazy(() =>
  import("@/pages/invoice/InvoicesPublisher")
);
const BannersComponent = React.lazy(() => import("@/pages/link/banners"));
const BannersWidgetComponent = React.lazy(() =>
  import("@/pages/link/bannersWidget")
);
const Campaigns = React.lazy(() => import("@/pages/link/campaigns"));
const BannerPage = React.lazy(() => import("@/pages/link/detail/banner"));
const BannerWidget = React.lazy(() =>
  import("@/pages/link/detail/bannerWidget")
);
const CampaignPage = React.lazy(() => import("@/pages/link/detail/campaign"));
const LinkGroupDetail = React.lazy(() =>
  import("@/pages/link/detail/linkGroupDetail")
);
const WidgetBuilder = React.lazy(() =>
  import("@/pages/link/detail/widgetBuilder")
);
const LinksComponent = React.lazy(() => import("@/pages/link/links"));
const Widgets = React.lazy(() => import("@/pages/link/widgets"));
const Media = React.lazy(() => import("@/pages/media/media"));
const Medias = React.lazy(() => import("@/pages/media/medias"));
const Orders = React.lazy(() => import("@/pages/order/orders"));
const Profile = React.lazy(() => import("@/pages/profile"));
const AdminProfile = React.lazy(() => import("@/pages/profile/adminProfile"));
const ProjectionsMerchant = React.lazy(() =>
  import("@/pages/projection/ProjectionsMerchant")
);

const RecoveryAccount = React.lazy(() =>
  import("@/pages/public/recoveryAccount")
);
const Reasons = React.lazy(() => import("@/pages/reasons/reasons"));
const Reports = React.lazy(() => import("@/pages/report/reports"));
const ReportsMedia = React.lazy(() => import("@/pages/report/reportsMedia"));
const FlowDetail = React.lazy(() => import("@/pages/setting/flowDetail"));
const FlowsList = React.lazy(() => import("@/pages/setting/flowsList"));
const FormulaDetail = React.lazy(() => import("@/pages/setting/formulaDetail"));
const FormulasList = React.lazy(() => import("@/pages/setting/formulasList"));
const Roles = React.lazy(() => import("@/pages/setting/roles"));

const Subjects = lazy(() => import("@/pages/subject/subjects"));
const CommissionsPendingList = lazy(() =>
  import("@/pages/website/commissionsPendingList")
);
const Setting = lazy(() => import("@/pages/setting/setting"));
const Commission = lazy(() => import("@/pages/website/commissions"));
const Websites = lazy(() => import("@/pages/website/list/websites"));
const Website = lazy(() => import("@/pages/website/website"));
const Publishers = lazy(() => import("@/pages/user/list/publishers"));
const Merchants = lazy(() => import("@/pages/user/list/merchants"));
const Admins = lazy(() => import("@/pages/user/list/admins"));
const DashboardMd = lazy(() => import("@/pages/dashboard/DashboardMd"));
const AffilioProductSuggest = lazy(() =>
  import("@/pages/AffilioSuggest/products")
);
const AffilioCategoriesSuggest = lazy(() =>
  import("@/pages/AffilioSuggest/categories")
);
export const PRIVATE_BASE_PATH = "/dashboard";

export const PUBLIC_ROUTES = [
  {
    name: "HomePage",
    path: "/",
    exact: true,
    component: <LoginPage />,
  },
  {
    name: "Login",
    path: "/login",
    exact: true,
    component: <LoginPage />,
  },
  {
    name: "Register",
    path: "/register",
    exact: true,
    component: <RegisterPage />,
  },
  {
    name: "ForgotPassword",
    path: "/forgot",
    exact: true,
    component: <ForgotPassword />,
  },
  {
    name: "Recovery",
    path: "/recovery",
    exact: true,
    component: <RecoveryAccount />,
  },
  // {
  //   name: "Privacy",
  //   path: "/privacy",
  //   exact: true,
  //   component: <PrivacyPage />,
  // },
  {
    name: "ContractView",
    path: "/contract_builder",
    exact: true,
    secure: true,
    component: <ContractView />,
    restrict: [],
  },
  {
    name: "InvoiceView",
    path: "/invoice_view/:id/:type",
    // exact: true,
    secure: true,
    component: <InvoiceView />,
    restrict: [],
  },
  {
    name: "PaymentView",
    path: "/finance_report",
    // exact: true,
    secure: true,
    component: <FinancePrintView />,
    restrict: [],
  },
  {
    name: "ResetPassword",
    path: "/reset-password",
    exact: true,
    secure: true,
    component: <ResetPassword />,
    restrict: [],
  },
];

const A = USER_TYPE.SUPER_ADMIN;
const P = USER_TYPE.PUBLISHER;
const M = USER_TYPE.MERCHANT;
const isFaqActive = MANUAL_CONFIG.IS_FAQ_ACTIVE;

export const parentsIcon = {
  users: <IconProfile />,
  LinksManage: <IconLink />,
  websitesManage: <IconWebsites />,
  Settings: <IconSetting />,
  Invoices: <IconFinance />,
  Reports: <IconReport />,
  "aff-suggest": <IconLink />,
};

export const AUTHENTICATED_ROUTES = [
  {
    key: "dashboard",
    path: "/",
    exact: true,
    component: <Dashboard />,
    title: "Dashboard",
    icon: <IconDashboard />,
    options: {
      order: {
        admin: 1,
        merchant: 1,
        publisher: 1,
      },
      subset: {
        publisher: "home",
      },
    },
    privileges: [
      Privileges.Dashboard.DASHBOARD_ADMIN,
      Privileges.Dashboard.DASHBOARD_MERCHANT,
      Privileges.Dashboard.DASHBOARD_PUBLISHER,
    ],
  },
  {
    key: "analytics",
    path: "/analytics",
    exact: true,
    component: <Dashboard />,
    title: "Analytics",
    icon: <IconDashboard />,
    restrict: [A, M, P],
    options: {
      order: {
        admin: 1,
        merchant: 1,
        publisher: 1,
      },
      subset: {
        publisher: "home",
      },
    },
    privileges: [
      Privileges.Dashboard.DASHBOARD_ADMIN,
      Privileges.Dashboard.DASHBOARD_MERCHANT,
      Privileges.Dashboard.DASHBOARD_PUBLISHER,
    ],
  },
  {
    path: "/websites",
    component: <Websites />,
    title: "Websites",
    titlePage: "Websites",
    restrict: [],
    parent: "websitesManage",
    icon: <IconWebsites />,
    options: {
      order: {
        admin: 2,
        merchant: 2,
        publisher: 5,
      },
      subset: {
        publisher: "website-parties",
      },
    },
    privileges: [Privileges.WEBSTORE.WEBSTORE_LIST_VIEW],
  },
  {
    path: "/suggest/products",
    component: <AffilioProductSuggest />,
    title: "productSuggest",
    titlePage: "product-suggest",
    restrict: [M],
    parent: "aff-suggest",
    icon: <IconLink />,
    options: {
      order: {
        // admin: 2,
        // merchant: 2,
        publisher: 4,
      },
      subset: {
        publisher: "aff-suggest",
      },
    },
  },
  {
    path: "/suggest/categories",
    component: <AffilioCategoriesSuggest />,
    title: "categoriesSuggest",
    titlePage: "categories-suggest",
    restrict: [M],
    parent: "aff-suggest",
    icon: <IconBanner />,
    options: {
      order: {
        // admin: 2,
        // merchant: 2,
        publisher: 4,
      },
      subset: {
        publisher: "aff-suggest",
      },
    },
  },
  {
    path: "/websites/new",
    component: <Website />,
    title: "WebsiteAdd",
    titlePage: "WebsiteAdd",
    restrict: [P],
    parent: "/websites",
    menu: false,
  },
  {
    path: "/websites/:id",
    component: <Website />,
    title: "WebsiteEdit",
    titlePage: "WebsiteEdit",
    restrict: [P],
    parent: "/websites",
    menu: false,
    privileges: [Privileges.WEBSTORE.WEBSTORE_DETAIL_VIEW],
  },
  {
    path: "/commissions",
    component: <Commission />,
    title: "WebsiteCommission",
    titlePage: "WebsiteCommission",
    restrict: [],
    parent: "websitesManage",
    icon: <IconCommission />,
    options: {
      order: {
        admin: 2,
        merchant: 5,
        publisher: 5,
      },
      subset: {
        publisher: "website-parties",
      },
    },
    privileges: [Privileges.COMMISSION.CATEGORY_COMMISSION_VIEW],
  },
  {
    path: "/commissions-pending",
    component: <CommissionsPendingList />,
    title: "WebsiteCommissionList",
    titlePage: "WebsiteCommissionList",
    restrict: [M, P],
    parent: "websitesManage",
    icon: <BarChartOutlined />,
    options: {
      order: {
        admin: 2,
      },
    },
    privileges: [Privileges.COMMISSION.CHANGE_COMMISSION_REQUEST_VIEW],
  },
  {
    path: "/medias",
    component: <Medias />,
    title: "Medias",
    titlePage: "Medias",
    restrict: [M],
    parent: "medias",
    icon: <IconMedia />,
    options: {
      order: {
        publisher: 2,
        admin: 3,
      },
      subset: {
        publisher: "home",
      },
    },
    privileges: [Privileges.MEDIA.MEDIA_MGM_VIEW],
  },
  {
    path: "/media/:id",
    component: <Media />,
    title: "MediaEdit",
    titlePage: "MediaEdit",
    restrict: [M],
    parent: "/medias",
    menu: false,
  },
  {
    path: "/links",
    component: <LinksComponent />,
    title: "Links",
    titlePage: "Links",
    restrict: [M],
    parent: "LinksManage",
    icon: <IconLink />,
    options: {
      order: {
        publisher: 3,
        admin: 4,
      },
      subset: {
        publisher: "affiliate-tools",
      },
    },
    privileges: [Privileges.LINK.LINK_MGM_VIEW],
  },
  {
    path: "/links/:id",
    component: <LinkGroupDetail />,
    title: "EditLink",
    titlePage: "EditLink",
    restrict: [M],
    parent: "LinksManage",
    menu: false,
  },
  {
    path: "/banners",
    component: <BannersComponent />,
    title: "BannersMerchant",
    titlePage: "BannersMerchant",
    restrict: [P, A],
    parent: "LinksManage",
    icon: <IconBanner />,

    options: {
      order: {
        merchant: 3,
        // admin: 4,
      },
    },
    privileges: [Privileges.BANNER.BANNER_VIEW],
  },
  {
    path: "/banners",
    component: <BannersComponent />,
    title: "Banners",
    titlePage: "Banners",
    restrict: [P, M],
    parent: "LinksManage",
    icon: <IconBanner />,
    options: {
      order: {
        // merchant: 3,
        admin: 4,
      },
    },
    privileges: [Privileges.BANNER.BANNER_VIEW],
  },
  {
    path: "/banners/new",
    component: <BannerPage />,
    title: "BannerNew",
    titlePage: "BannerNew",
    restrict: [P],
    parent: "LinksManage",
    menu: false,
  },
  {
    path: "/banners/:id",
    component: <BannerPage />,
    title: "BannerEdit",
    titlePage: "BannerEdit",
    restrict: [P],
    parent: "/banners",
    menu: false,
  },
  {
    path: "/banners-widget",
    component: <BannersWidgetComponent />,
    title: "BannersWidget",
    titlePage: "BannersWidget",
    restrict: [M, P],
    parent: "LinksManage",
    icon: <IconBanner />,
    options: {
      order: {
        // publisher: 3,
        admin: 4,
      },
    },
    privileges: [Privileges.WIDGET_BANNER.WIDGET_VIEW],
  },
  {
    path: "/banners-widget",
    component: <BannersWidgetComponent />,
    title: "BannersWidgetPublisher",
    titlePage: "BannersWidgetPublisher",
    restrict: [M, A],
    parent: "LinksManage",
    icon: <IconBanner />,

    options: {
      order: {
        publisher: 3,
        // admin: 4,
      },
      subset: {
        publisher: "affiliate-tools",
      },
    },
    privileges: [Privileges.WIDGET_BANNER.WIDGET_VIEW],
  },
  {
    path: "/banners-widget/:id",
    component: <BannerWidget />,
    title: "BannerWidget",
    titlePage: "BannerWidget",
    restrict: [M],
    parent: "/banners-widget",
    menu: false,
  },
  {
    path: "/widgets",
    component: <Widgets />,
    title: "Widgets",
    titlePage: "Widgets",
    // TODO UNCOMMENT FOR MERCHANT
    restrict: [A],
    parent: "LinksManage",
    icon: <IconWidget />,
    options: {
      order: {
        publisher: 3,
        admin: 4,
        merchant: 4,
      },
      subset: {
        publisher: "affiliate-tools",
      },
    },
    // TODO UNCOMMENT FOR MERCHANT
    privileges: [Privileges.WIDGET.WIDGET_VIEW],
  },
  {
    path: "/widgets-publisher",
    component: <Widgets />,
    title: "WidgetsPublishers",
    titlePage: "WidgetsPublishers",
    // TODO UNCOMMENT FOR MERCHANT
    restrict: [M, P],
    parent: "LinksManage",
    icon: <SidebarIcon type="widgetsIcon" />,
    options: {
      order: {
        publisher: 3,
        admin: 4,
        merchant: 4,
      },
      subset: {
        publisher: "affiliate-tools",
      },
    },
    // TODO UNCOMMENT FOR MERCHANT
    privileges: [Privileges.WIDGET.WIDGET_VIEW],
  },
  {
    path: "/widgets-merchant",
    component: <Widgets mode="MERCHANT_WIDGETS" />,
    title: "WidgetsMerchants",
    titlePage: "WidgetsMerchants",
    restrict: [M, P],
    parent: "LinksManage",
    icon: <SidebarIcon type="widgetsIcon" />,
    options: {
      order: {
        publisher: 3,
        admin: 4,
        merchant: 4,
      },
      subset: {
        publisher: "affiliate-tools",
      },
    },
    // TODO UNCOMMENT FOR MERCHANT
    privileges: [Privileges.WIDGET.WIDGET_VIEW],
  },
  {
    path: "/widgets/new",
    component: <WidgetBuilder />,
    title: "WidgetNew",
    titlePage: "WidgetNew",
    restrict: [],
    parent: "/widgets",
    menu: false,
    options: {
      order: {
        publisher: 3,
      },
    },
  },
  {
    path: "/widgets/:id",
    component: <WidgetBuilder />,
    title: "WidgetEdit",
    titlePage: "WidgetEdit",
    restrict: [],
    parent: "/widgets",
    menu: false,
    options: {
      order: {
        publisher: 3,
      },
    },
  },
  {
    path: "/campaigns",
    component: <Campaigns />,
    title: "Campaigns",
    titlePage: "Campaigns",
    // TODO UNCOMMENT FOR MERCHANT
    restrict: [P],
    icon: <IconCampaign />,
    options: {
      order: {
        admin: 4,
        merchant: 4,
      },
    },
    // TODO UNCOMMENT FOR MERCHANT
    // menu: false,
    privileges: [Privileges.WIDGET.WIDGET_VIEW],
  },
  {
    path: "/campaign/:id",
    component: <CampaignPage />,
    title: "Campaign",
    titlePage: "Campaign",
    // TODO UNCOMMENT FOR MERCHANT
    restrict: [P],
    parent: "/campaigns",
    icon: <AppstoreAddOutlined />,
    // TODO UNCOMMENT FOR MERCHANT
    privileges: [Privileges.WIDGET.WIDGET_VIEW],
    menu: false,
  },
  {
    path: "/orders",
    component: <Orders />,
    title: "Orders",
    titlePage: "Orders",
    restrict: [],
    icon: <IconOrders />,
    options: {
      order: {
        admin: 5,
        merchant: 5,
        publisher: 6,
      },
      subset: {
        publisher: "finance",
      },
    },
    privileges: [Privileges.ORDER.ORDER_VIEW],
  },
  {
    key: "publishers",
    path: "/publishers",
    titlePage: "publishers",
    exact: true,
    component: <Publishers />,
    title: "Publishers",
    restrict: [P, M],
    parent: "users",
    options: {
      order: {
        admin: 7,
      },
    },
    privileges: [Privileges.USER.USER_MGM_LIST],
  },
  {
    path: "/publishers/:id",
    component: <Profile />,
    title: "UserDetail",
    titlePage: "UserDetail",
    restrict: [P, M],
    parent: "/publishers",
    menu: false,
  },
  {
    path: "/merchants",
    titlePage: "merchants",
    exact: true,
    component: <Merchants />,
    title: "Merchants",
    restrict: [P, M],
    parent: "users",
    icon: <UserOutlined />,
    options: {
      order: {
        admin: 7,
      },
    },
    privileges: [Privileges.USER.USER_MGM_LIST],
  },
  {
    path: "/merchants/:id",
    component: <Profile />,
    title: "UserDetail",
    titlePage: "UserDetail",
    restrict: [P, M],
    parent: "/merchants",
    menu: false,
  },
  {
    path: "/setting",
    exact: true,
    component: <Setting />,
    title: "SettingAccount",
    restrict: [M, P],
    parent: "Settings",
    options: {
      order: {
        admin: 8,
      },
    },
  },
  {
    path: "/reasons",
    component: <Reasons />,
    title: "Reasons",
    titlePage: "Reasons",
    restrict: [M, P],
    parent: "Settings",
    options: {
      order: {
        admin: 8,
      },
    },
    privileges: [Privileges.SETTING.SETTING_VIEW],
  },
  {
    path: "/subjects",
    component: <Subjects />,
    title: "Subjects",
    titlePage: "Subjects",
    restrict: [M, P],
    parent: "Settings",
    options: {
      order: {
        admin: 8,
      },
    },
    privileges: [Privileges.SETTING.SETTING_VIEW],
  },
  {
    path: "/contract-templates",
    titlePage: "ContractTemplates",
    exact: true,
    component: <ContractTemplates />,
    title: "ContractTemplates",
    restrict: [P, M],
    parent: "Settings",
    icon: <BookOutlined />,
    options: {
      order: {
        admin: 8,
      },
    },
    // privileges: [Privileges.CONTRACT.CONTRACT_TEMPLATE_MGM_READ],
  },
  {
    path: "/formulas",
    titlePage: "FormulasList",
    exact: true,
    component: <FormulasList />,
    title: "FormulasList",
    restrict: [P, M],
    parent: "Settings",
    icon: <BookOutlined />,
    options: {
      order: {
        admin: 8,
      },
    },
    // privileges: [Privileges.CONTRACT.CONTRACT_TEMPLATE_MGM_READ],
  },
  {
    path: "/formulas/add",
    titlePage: "FormulasAdd",
    exact: true,
    component: <FormulaDetail />,
    title: "FormulaAdd",
    restrict: [P, M],
    parent: "/formulas",
    icon: <BookOutlined />,
    options: {
      order: {
        admin: 8,
      },
    },
    menu: false,
    // privileges: [Privileges.CONTRACT.CONTRACT_TEMPLATE_MGM_READ],
  },
  {
    path: "/formulas/:id",
    titlePage: "FormulaEdit",
    exact: true,
    component: <FormulaDetail />,
    title: "FormulaEdit",
    restrict: [P, M],
    parent: "/formulas",
    icon: <BookOutlined />,
    options: {
      order: {
        admin: 8,
      },
    },
    menu: false,
    // privileges: [Privileges.CONTRACT.CONTRACT_TEMPLATE_MGM_READ],
  },
  {
    path: "/flows",
    titlePage: "FlowsList",
    exact: true,
    component: <FlowsList />,
    title: "FlowsList",
    restrict: [P, M],
    parent: "Settings",
    icon: <BookOutlined />,
    options: {
      order: {
        admin: 8,
      },
    },
    // privileges: [Privileges.CONTRACT.CONTRACT_TEMPLATE_MGM_READ],
  },
  {
    path: "/flows/add",
    titlePage: "FlowAdd",
    exact: true,
    component: <FlowDetail />,
    title: "FlowAdd",
    restrict: [P, M],
    parent: "Settings",
    icon: <BookOutlined />,
    menu: false,
    options: {
      order: {
        admin: 8,
      },
    },
    // privileges: [Privileges.CONTRACT.CONTRACT_TEMPLATE_MGM_READ],
  },
  {
    path: "/flows/:id",
    titlePage: "FlowEdit",
    exact: true,
    component: <FlowDetail />,
    title: "FlowEdit",
    restrict: [P, M],
    parent: "Settings",
    menu: false,
    icon: <BookOutlined />,
    options: {
      order: {
        admin: 8,
      },
    },
    // privileges: [Privileges.CONTRACT.CONTRACT_TEMPLATE_MGM_READ],
  },
  {
    path: "/reports",
    titlePage: "ReportsOrder",
    exact: true,
    component: <Reports />,
    title: "ReportsOrder",
    restrict: [],
    parent: "Reports",
    icon: <IconReport />,
    options: {
      order: {
        admin: 8,
        merchant: 8,
        publisher: 8,
      },
    },
    // privileges: [Privileges.CONTRACT.CONTRACT_TEMPLATE_MGM_READ],
  },
  {
    path: "/tickets",
    exact: true,
    component: <Tickets />,
    title: "Tickets",
    titlePage: "Tickets",
    restrict: [M, A],
    icon: <IconOrders />,
    options: {
      order: {
        publisher: 3,
        admin: 4,
      },
    },
    // privileges: [Privileges.LINK.LINK_MGM_VIEW],
  },
  {
    path: "/tickets/create",
    exact: true,
    component: <Ticket />,
    title: "Ticket",
    titlePage: "Ticket",
    restrict: [M, A],
    menu: false,
    icon: <SidebarIcon type="linkIcon" />,
    options: {
      order: {
        publisher: 3,
        admin: 4,
      },
    },
    // privileges: [Privileges.LINK.LINK_MGM_VIEW],
  },
  {
    path: "/tickets/:id",
    component: <TicketReply />,
    title: "Ticket",
    titlePage: "Ticket",
    restrict: [M, A],
    menu: false,
    icon: <SidebarIcon type="linkIcon" />,
    options: {
      order: {
        publisher: 3,
        admin: 4,
      },
    },
    // privileges: [Privileges.LINK.LINK_MGM_VIEW],
  },
  {
    path: "/report-performance-media",
    titlePage: "ReportsMedia",
    exact: true,
    component: <ReportsMedia />,
    title: "ReportsMedia",
    restrict: [P],
    parent: "Reports",
    icon: <BookOutlined />,
    options: {
      order: {
        admin: 9,
      },
    },
    privileges: [Privileges.REPORT.REPORT_MEDIA_PERFORMANCE],
  },
  {
    key: "report-insight",
    path: "/report-insight",
    exact: true,
    component: <DashboardMd />,
    title: "InsightReport",
    icon: <AppstoreOutlined />,
    options: {
      order: {
        admin: 10,
      },
    },
    parent: "Reports",
    privileges: [Privileges.Dashboard.DASHBOARD_ADMIN],
  },
  {
    path: "/contract-template/:id",
    component: <ContractTemplate />,
    title: "ContractTempDetail",
    titlePage: "ContractTempDetail",
    restrict: [P, M],
    parent: "/contract-templates",
    menu: false,
  },
  {
    path: "/contract-template/new",
    component: <ContractTemplate />,
    title: "ContractTempDetailAdd",
    titlePage: "ContractTempDetailAdd",
    restrict: [P, M],
    parent: "/contract-templates",
    menu: false,
  },
  {
    path: "/tags",
    component: <Groups />,
    title: "Groups",
    titlePage: "Groups",
    restrict: [P],
    parent: "Settings",
    icon: <IconTag />,
    options: {
      order: {
        admin: 8,
        merchant: 9,
      },
    },
    // privileges: [Privileges.SETTING.SETTING_VIEW],
  },
  {
    path: "/contracts/:id",
    component: <ContractsUserList />,
    title: "ContractsUserList",
    titlePage: "ContractsUserList",
    restrict: [],
    menu: false,
  },
  {
    path: "/contracts",
    component: <ContractsUserList />,
    title: "ContractsUserList",
    titlePage: "ContractsUserList",
    restrict: [P, M],
    parent: "Settings",
    menu: false,
  },
  {
    path: "/contract/:id",
    component: <ContractDetail />,
    title: "ContractDetail",
    titlePage: "ContractDetail",
    restrict: [],
    parent: "/contracts",
    menu: false,
  },
  {
    path: "/finance-merchant",
    component: <FinanceMerchant />,
    title: "FinanceMerchant",
    titlePage: "FinanceMerchant",
    restrict: [M, P],
    parent: "Invoices",
    options: {
      order: {
        admin: 7,
      },
    },
  },
  {
    path: "/finance-publisher",
    component: <FinancePublisher />,
    title: "FinancePublisher",
    titlePage: "FinancePublisher",
    restrict: [M, P],
    parent: "Invoices",
    options: {
      order: {
        admin: 7,
      },
    },
  },
  {
    path: "/invoices",
    component: <InvoicesMerchant />,
    title: "InvoicesPage",
    titlePage: "InvoicesPage",
    icon: <IconInvoice />,
    restrict: [A, P],
    parent: "Invoices",
    options: {
      order: {
        merchant: 7,
      },
    },
    privileges: [Privileges.FINANCE.FINANCE_MERCHANT_INVOICE],
  },
  {
    path: "/payouts",
    component: <PayoutMerchantList />,
    title: "PayoutsPage",
    titlePage: "PayoutsPage",
    icon: <IconFinance />,
    restrict: [A, P],
    parent: "Invoices",
    options: {
      order: {
        merchant: 7,
      },
    },
    privileges: [Privileges.FINANCE.FINANCE_MERCHANT_INVOICE],
  },
  {
    path: "/payouts",
    component: <PayoutPublisherList />,
    title: "PayoutsPage",
    titlePage: "PayoutsPage",
    icon: <IconFinance />,
    restrict: [A, M],
    parent: "Invoices",
    options: {
      order: {
        publisher: 7,
      },
      subset: {
        publisher: "finance",
      },
    },
  },
  {
    path: "/invoices",
    component: <InvoicesPublisher />,
    icon: <IconInvoice />,
    title: "InvoicesPage",
    titlePage: "InvoicesPage",
    restrict: [A, M],
    parent: "Invoices",
    options: {
      order: {
        merchant: 7,
      },
      subset: {
        publisher: "finance",
      },
    },
    privileges: [Privileges.FINANCE.FINANCE_PUBLISHER_INVOICE],
  },
  {
    path: "/invoices-publisher",
    component: <InvoicesPublisher />,
    title: "InvoicesPublisher",
    titlePage: "InvoicesPublisher",
    icon: <IconInvoice />,
    restrict: [P, M],
    parent: "Invoices",
    options: {
      order: {
        admin: 6,
      },
    },
    privileges: [Privileges.FINANCE.FINANCE_PUBLISHER_INVOICE],
  },
  {
    path: "/invoices-merchant",
    component: <InvoicesMerchant />,
    title: "InvoicesMerchant",
    titlePage: "InvoicesMerchant",
    icon: <IconInvoice />,
    restrict: [P, M],
    parent: "Invoices",
    options: {
      order: {
        admin: 6,
      },
    },
    privileges: [Privileges.FINANCE.FINANCE_MERCHANT_INVOICE],
  },
  {
    path: "/invoices/:id/:type",
    component: <InvoiceDetail />,
    title: "InvoiceDetail",
    titlePage: "InvoiceDetail",
    restrict: [],
    parent: "/invoices",
    menu: false,
  },
  {
    path: "/projections",
    component: <ProjectionsMerchant />,
    title: "Projections",
    titlePage: "Projections",
    restrict: [],
    parent: "Invoices",
    menu: false,
  },
  {
    path: "/administrators",
    component: <Admins />,
    title: "Administrators",
    titlePage: "Administrators",
    restrict: [P, M],
    exact: true,
    parent: "users",
    privileges: [Privileges.USER.USER_MGM_LIST],
  },
  {
    path: "/administrators/:id",
    component: <AdminProfile />,
    title: "Administrator",
    titlePage: "Administrator",
    restrict: [P, M],
    parent: "/administrators",
    menu: false,
    privileges: [Privileges.USER.USER_MGM_LIST],
  },
  {
    title: "Reports",
    icon: <UnorderedListOutlined />,
    path: "/",
    options: {
      order: {
        admin: 9,
        merchant: 7,
        publisher: 8,
      },
    },
    menu: false,
  },
  {
    title: "Support",
    icon: <MessageOutlined />,
    path: "/",
    options: {
      order: {
        admin: 10,
        merchant: 10,
        publisher: 10,
      },
    },
    menu: false,
  },
  // {
  //   path: "/kits",
  //   titlePage: "kits",
  //   exact: true,
  //   component: <UiKit />,
  //   title: "Kits",
  //   // menu: false,
  //   parent: "Settings",
  // },
  {
    path: "/roles",
    exact: true,
    component: <Roles />,
    title: "Roles",
    restrict: [M, P],
    parent: "Settings",
    // menu: false,
  },
  // {
  //   path: "/counter",
  //   exact: true,
  //   component: <Counter />,
  //   menu: false,
  // },
  {
    path: "/wizard",
    exact: true,
    component: <RegisterWizard />,
    restrict: [A],
    menu: false,
  },
  {
    path: "/profile",
    exact: true,
    component: <Profile />,
    title: "Profile",
    menu: false,
  },
  {
    path: "/faq",
    titlePage: "FAQ",
    exact: true,
    menu: isFaqActive,
    component: <FAQ />,
    title: "FAQ",
    restrict: [],
    icon: <QuestionCircleOutlined />,
    options: {
      order: {
        admin: 11,
        merchant: 11,
        publisher: 11,
      },
    },
  },
];

export const getPrivateRoutes = (userType) => {
  const uTID = USER_TYPE[userType];

  let routes = [];
  if (uTID === USER_TYPE.MERCHANT) {
    const merchantOrder = _.sortBy(
      AUTHENTICATED_ROUTES,
      "options.order.merchant"
    );
    routes = merchantOrder.filter(
      (item) => !item.restrict?.find((m) => m === uTID)
    );
  }
  if (uTID === USER_TYPE.PUBLISHER) {
    const publisherOrder = _.sortBy(
      AUTHENTICATED_ROUTES,
      "options.order.publisher"
    );
    routes = publisherOrder.filter(
      (item) => !item.restrict?.find((m) => m === uTID)
    );
  }
  if (uTID === USER_TYPE.SUPER_ADMIN || uTID === USER_TYPE.ADMIN) {
    const uTID_ = USER_TYPE.SUPER_ADMIN;
    const adminOrder = _.sortBy(AUTHENTICATED_ROUTES, "options.order.admin");
    routes = adminOrder.filter(
      (item) => !item.restrict?.find((m) => m === uTID_)
    );
  }

  return routes;
};
