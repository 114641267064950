import { Button, Col, Modal, Row } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useDispatch } from "react-redux";

import { clearTour, runTour } from "@/app/local/appSlice";
import {
  BasketSvg,
  BulletSvg,
  EditSvg,
  SquaresSvg,
  VolumeSvg,
} from "@/assets/pageIcons";
import { useAccount } from "@/utils/hooks/useAuth";

import tourData from "./mockData";
import "./styles.less";

const HelpTourModal = (props) => {
  const { open, setOpen } = props;
  const dispatch = useDispatch();
  const { roleId } = useAccount();

  const changeIcon = (key) => {
    if (key === "APPROVE_CONTRACT_TOUR") {
      return EditSvg;
    }
    if (key === "CREATE_MEDIA_TOUR") {
      return VolumeSvg;
    }
    if (key === "ADD_WEBSTORE_TO_MEDIA") {
      return BasketSvg;
    }
    if (key === "ADD_SMART_WIDGET") {
      return SquaresSvg;
    }
  };

  useEffect(() => {
    if (open) {
      dispatch(clearTour());
    }
  }, [open]);

  const dataItems = useMemo(() => {
    return tourData
      .filter((item) => item.watcher.includes(roleId))
      .map((item) => {
        return {
          title: item.title,
          key: item.key,
          enabled: item.enabled,
        };
      });
  }, [roleId]);

  const handleSelectTour = (tour) => {
    dispatch(runTour(tour.key));
    setOpen(false);
  };
  // xxx
  return (
    <Modal
      className="help_tour"
      visible={open}
      onOk={setOpen}
      onCancel={() => setOpen(false)}
      closable
      width={500}
      footer={false}
      centered
    >
      <p>{t("help.description")}</p>
      <Row gutter={[24, 24]}>
        {dataItems.map((item) => {
          return (
            <Col xs={12} key={item.key} onClick={() => handleSelectTour(item)}>
              <div
                className={
                  item.enabled ? "tour-grid" : "tour-grid tour-grid__disabled"
                }
              >
                <img src={changeIcon(item.key)} className="tour-grid__icon" />
                <div>{item.title}</div>
              </div>
            </Col>
          );
        })}
      </Row>
      <Row className="tour-grid__more" align="middle">
        <Col md={20}>
          <img src={BulletSvg} className="tour-grid__icon" />
          <span>{t("help.more")}</span>
        </Col>
        <Button type="primary">{t("help.help")}</Button>
      </Row>
    </Modal>
  );
};

HelpTourModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default HelpTourModal;
