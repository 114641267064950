import { Tag } from "antd";
import Modal from "antd/lib/modal/Modal";
import React from "react";
import { useTranslation } from "react-i18next";

const WidgetModalSpec = (props) => {
  const { t } = useTranslation();
  const { widget, close } = props;

  return (
    <Modal
      className={"modal-widget-spec"}
      title={widget?.name}
      visible={true}
      onCancel={close}
      width={"600px"}
      footer={false}
    >
      <div className="spec__body">
        {widget?.campaign ? (
          <div className="spec__campaign">
            <span className="spec__title">
              <b>{t("widget.campaign")}</b>:{" "}
              <span>{widget?.campaign?.title}</span>
            </span>
            <a>{t("widget.show_campaign_detail")}</a>
          </div>
        ) : (
          false
        )}
        <div>
          <table className="spec__table">
            <tr>
              <td>{t("widget.countProduct")}</td>
              <td>
                <b>{widget?.products?.length || "0"}</b>
              </td>
            </tr>

            <tr>
              <td>{t("widget.createdDate")}</td>
              <td>
                <b>{widget?.create_date}</b>
              </td>
            </tr>

            <tr>
              <td>{t("widget.tags")}</td>
              <td>{widget?.tags ? <Tag></Tag> : "-"}</td>
            </tr>
          </table>
        </div>
      </div>
    </Modal>
  );
};

WidgetModalSpec.propTypes = {
  widget: Object,
  close: Function,
};

export default WidgetModalSpec;
