import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Col, Radio, Row, Space, Steps } from "antd";
import { t } from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { accountUserDetail } from "@/app/local/accountSlice";
import { publisherSetLegal } from "@/app/local/publisherSlice";
import KYCAlert from "@/components/general/KYCAlert";
import StepCompanyInfo from "@/components/userInfo/StepCompanyInfo";
import StepContactInfo from "@/components/userInfo/StepContactInfo";
import StepPaymentInfo from "@/components/userInfo/StepPaymentInfo";
import StepPersonalInfo from "@/components/userInfo/StepPersonalInfo";
import StepSuccess from "@/components/userInfo/StepSuccess";
import StepTaxInfo from "@/components/userInfo/StepTaxInfo";
import {
  KYC_STATUS,
  LEGAL_STATUS,
  USER_STATUS,
  USER_TYPE,
} from "@/utils/constants/apiConstants";
import useConfig from "@/utils/hooks/useConfig";

import "./styles.less";

const { Step } = Steps;

export default function RegisterWizard() {
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [isLegal, setIsLegal] = useState(false);
  const [availableStep, setAvailableStep] = useState(0);
  const [showLegal, setShowLegal] = useState(false);
  const dispatch = useDispatch();
  const { isSSP } = useConfig();

  const defaultSteps = [
    {
      title: t("wizard.stepPersonal"),
      id: "personal",
      kyc: [
        KYC_STATUS.PersonalInfoConfirmed,
        KYC_STATUS.NationalCardConfirmed,
        KYC_STATUS.IdentityCardConfirmed,
      ],
    },
    {
      title: t("wizard.stepContact"),
      id: "contact",
      kyc: [KYC_STATUS.ContactInfoConfirmed],
    },
    {
      title: t("wizard.stepPayment"),
      id: "payment",
      kyc: [KYC_STATUS.BankAccountInfoConfirmed],
    },
    {
      title: t("wizard.stepTax"),
      id: "tax",
      kyc: [
        KYC_STATUS.VatDocumentConfirmed,
        KYC_STATUS.NewsPaperDocumentConfirmed,
      ],
    },
    {
      title: t("wizard.complete"),
      id: "finish",
      kyc: [],
    },
  ];
  const companyStep = {
    title: t("wizard.stepCompany"),
    id: "company",
    kyc: [KYC_STATUS.CompanyInfoConfirmed],
  };
  const [steps, setSteps] = useState(defaultSteps);

  const { profile } = useSelector((state) => state.account);
  const userType = isSSP ? USER_TYPE.PUBLISHER : profile?.data?.user_type;
  const userInfo = _.get(profile, "userDetail.data");
  const userStatus = userInfo?.user?.status;
  const kycStatus = userInfo?.user?.kyc_status;
  const max = steps.length - 1;
  const userHasVat = userInfo?.has_vat;

  const detectKycError = (item) => {
    const notConfirmed = item.kyc.filter((e) => !kycStatus?.includes(e));
    if (notConfirmed.length > 0) {
      if (notConfirmed.includes(8) && !userHasVat) {
        return false;
      }
      return true;
    } else return false;
  };
  const tabHasError = (step) => {
    let result = false;
    if (userStatus === USER_STATUS.KysError) {
      result = steps.find((item) => {
        if (item.id === step.id) {
          return detectKycError(item);
        }
      });
    }
    return !!result;
  };

  useEffect(() => {
    if (userType === USER_TYPE.PUBLISHER) {
      setShowLegal(true);
    }
    if (userType === USER_TYPE.ADVERTISER) {
      setShowLegal(true);
    }
    if (userType === USER_TYPE.MERCHANT) {
      setSteps((old) => {
        let old_ = [...old];
        old_[0] = companyStep;
        old_ = old_.filter((i) => i.id !== "payment");
        return old_;
      });
      setIsLegal(true);
    }

    setAvailableStep(getStepAvailable());

    setCurrent(getStepAvailable());
  }, [userType, profile]);

  useEffect(() => {
    if (userType === USER_TYPE.PUBLISHER) {
      if (userInfo?.legal_status === LEGAL_STATUS.LEGAL) {
        setIsLegal(true);
      } else if (userInfo?.legal_status === LEGAL_STATUS.PRIVATE) {
        setIsLegal(false);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (userType === USER_TYPE.PUBLISHER) {
      if (isLegal) {
        setSteps((old) => {
          const old_ = [...old];
          old_[0] = companyStep;
          return old_;
        });
      } else {
        setSteps(defaultSteps);
      }
    }
  }, [isLegal]);

  const getStepAvailable = () => {
    if (
      userStatus === USER_STATUS.PendingForApproval ||
      userStatus === USER_STATUS.InReview
    ) {
      if (userType === USER_TYPE.PUBLISHER) {
        return max;
      } else return max - 1;
    } else if (userType === USER_TYPE.PUBLISHER) {
      if (userInfo?.sheba) {
        return 3;
      } else if (userInfo?.email) {
        return 2;
      } else if (userInfo?.first_name || userInfo?.company_name) {
        return 1;
      } else {
        return 0;
      }
    } else {
      if (userInfo?.has_vat || userInfo?.news_paper_document_file_id) {
        return 2;
      } else if (userInfo?.company_name) {
        return 1;
      } else {
        return 0;
      }
    }
  };

  const onChangeLegal = (e) => {
    if (availableStep >= 1) {
      return;
    }
    setIsLegal(e.target.value);
    setLoading(true);
    setTimeout(() => {
      setIsLegal(e?.target?.value);
      setLoading(false);
      dispatch(
        publisherSetLegal({
          status: isLegal ? LEGAL_STATUS.PRIVATE : LEGAL_STATUS.LEGAL,
        })
      );
      dispatch(accountUserDetail());
    }, 500);
  };

  const onChange = (e) => {
    setCurrent(e);
  };

  const isDisabled = (stepId) => {
    return availableStep < stepId;
  };

  const getStatusStep = (i, step) => {
    if (tabHasError(step)) {
      return "error";
    } else return availableStep < i ? null : "finish";
  };

  const handleChangeStep = (i) => {
    if (i > current) {
      // refresh available step
      if (availableStep < max) {
        setAvailableStep(current + 1);
      }
    }
    if (availableStep < max) {
      setCurrent(i);
    } else {
      setCurrent(max);
    }
  };

  return (
    <div className="wizard">
      <br />
      <>
        <Row gutter={[16, 16]} align="middle" justify="">
          <Col md={2} xs={0}></Col>
          <Col md={20} xs={24}>
            <KYCAlert />
          </Col>
        </Row>
        <Row gutter={[16, 16]} align="middle" justify="">
          <Col md={2} xs={0}></Col>
          {showLegal ? (
            <Col md={20} xs={24}>
              <Card style={{ marginTop: 16 }}>
                <p>{t("wizard.publisherEntryDescription")}</p>
                <Radio.Group
                  // disabled={availableStep >= 1}
                  onChange={onChangeLegal}
                  value={isLegal}
                >
                  <Radio.Button value={true}>
                    {t("wizard.legalPerson")}
                  </Radio.Button>
                  <Radio.Button value={false}>
                    {t("wizard.privatePerson")}
                  </Radio.Button>
                </Radio.Group>
              </Card>
            </Col>
          ) : (
            <Col md={20} xs={24}>
              <Card style={{ marginTop: 16 }}>
                <div>
                  <Space>
                    <InfoCircleOutlined style={{ fontSize: 18 }} />
                    <span>{t("wizard.merchantEntryDescription")}</span>
                  </Space>
                </div>
              </Card>
            </Col>
          )}
        </Row>
      </>

      <Row gutter={[16, 16]} align="middle" justify="center">
        <Col md={20} xs={24}>
          <Card style={{ marginTop: 16 }} loading={loading}>
            <Steps
              type="navigation"
              current={current}
              onChange={onChange}
              className="site-navigation-steps"
            >
              {steps.map((step, i) => (
                <Step
                  disabled={isDisabled(i)}
                  key={step.id}
                  // status={""}
                  status={getStatusStep(i, step)}
                  title={step.title}
                />
              ))}
            </Steps>
            <div className="steps-content">
              <div className={`content_info ${current === 0 ? "active" : ""}`}>
                {!isLegal ? (
                  <StepPersonalInfo goNextStep={handleChangeStep} />
                ) : (
                  <StepCompanyInfo goNextStep={handleChangeStep} />
                )}
              </div>

              <div className={`content_info ${current === 1 ? "active" : ""}`}>
                <StepContactInfo goNextStep={handleChangeStep} />
              </div>
              <div className={`content_info ${current === 2 ? "active" : ""}`}>
                {userType === USER_TYPE.PUBLISHER ||
                userType === USER_TYPE.ADVERTISER ? (
                  <StepPaymentInfo goNextStep={handleChangeStep} />
                ) : (
                  ""
                )}
                {userType === USER_TYPE.MERCHANT ? (
                  <StepTaxInfo goNextStep={handleChangeStep} max={max} />
                ) : (
                  ""
                )}
              </div>
              {userType === USER_TYPE.PUBLISHER ||
              userType === USER_TYPE.ADVERTISER ? (
                <div
                  className={`content_info ${current === 3 ? "active" : ""}`}
                >
                  <StepTaxInfo goNextStep={handleChangeStep} max={max} />
                </div>
              ) : (
                ""
              )}
              <div
                className={`content_info ${current === max ? "active" : ""}`}
              >
                <StepSuccess />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
