import { Card, Col, Row, Tabs, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { MainTicket } from "smartech-support";

import EmailIcon from "@/assets/images/tickets/email.svg";
import InstaIcon from "@/assets/images/tickets/instagram.svg";
import TeleIcon from "@/assets/images/tickets/telegram.svg";
import MainContainer from "@/components/container/MainContainer";
import { HELPICAL_DEPARTMENT_ID } from "@/utils/constants/configConstants";
import { useAccount } from "@/utils/hooks/useAuth";

import "./tickets.less";

const Tickets = () => {
  const nav = useNavigate();
  const { userDetail } = useAccount();
  const { t } = useTranslation();

  const userEmail = userDetail?.email;
  const currentDepartment = HELPICAL_DEPARTMENT_ID;

  const infoContacts = [
    {
      title: t("ticket.email"),
      address: "support@affilio.ir",
      href: "mailto:support@affilio.ir",
      image: EmailIcon,
    },
    {
      title: t("ticket.telegram"),
      address: "www.t.me/AffilioChannel",
      href: "https://t.me/AffilioChannel",
      image: TeleIcon,
    },
    {
      title: t("ticket.instagram"),
      address: "www.instagram.com/affilio.io",
      href: "https://www.instagram.com/affilio.io",
      image: InstaIcon,
    },
  ];

  return (
    <div className="tickets">
      <MainContainer>
        <Tabs defaultActiveKey="topTenPublishers">
          <Tabs.TabPane tab={t("ticket.ticketTitle")} key="list">
            <MainTicket
              router={nav}
              options={{
                createRoute: "/tickets/create",
                editRoute: "/tickets/",
                userEmail: userEmail,
                currentDepartment: currentDepartment,
              }}
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab={t("ticket.ticketContact")}>
            <br />
            <Typography.Paragraph>{t("ticket.helpText")}</Typography.Paragraph>
            <br />
            <Row gutter={[16, 16]}>
              {infoContacts?.map((item, index) => (
                <Col key={index} lg={5} md={8} xs={24}>
                  <Card className="detail">
                    <img src={item.image} />
                    <div className="detail__title">{item.title}</div>
                    <div className="detail__link">
                      <Link to={item.href} target="_blank" rel="norefferer">
                        {item.address}
                      </Link>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
            <br />
            <br />
            <br />
          </Tabs.TabPane>
        </Tabs>
      </MainContainer>
    </div>
  );
};

export default Tickets;
