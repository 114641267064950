import React, { useState } from "react";

import "./CardSkeleton.less";

const CardSkeleton = (props) => {
  const [close, setClose] = useState(false);
  const isBanner = props.type === "BANNER";

  return !close ? (
    <div
      className={`card-skeleton ${props.class || ""} ${props.mode || ""} ${
        isBanner ? "banner" : ""
      }`}
    >
      <div className="img">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="vuesax/bold/gallery">
            <g id="gallery">
              <path
                id="Vector"
                d="M4.29996 31.6833L4.26663 31.7166C3.81663 30.7333 3.53329 29.6166 3.41663 28.3833C3.53329 29.6 3.84996 30.7 4.29996 31.6833Z"
                fill="white"
              />
              <path
                id="Vector_2"
                d="M15 17.3C17.1907 17.3 18.9667 15.5241 18.9667 13.3334C18.9667 11.1426 17.1907 9.3667 15 9.3667C12.8093 9.3667 11.0333 11.1426 11.0333 13.3334C11.0333 15.5241 12.8093 17.3 15 17.3Z"
                fill="white"
              />
              <path
                id="Vector_3"
                d="M26.9834 3.33325H13.0167C6.95004 3.33325 3.33337 6.94992 3.33337 13.0166V26.9833C3.33337 28.7999 3.65004 30.3833 4.26671 31.7166C5.70004 34.8833 8.76671 36.6666 13.0167 36.6666H26.9834C33.05 36.6666 36.6667 33.0499 36.6667 26.9833V23.1666V13.0166C36.6667 6.94992 33.05 3.33325 26.9834 3.33325ZM33.95 20.8333C32.65 19.7166 30.55 19.7166 29.25 20.8333L22.3167 26.7833C21.0167 27.8999 18.9167 27.8999 17.6167 26.7833L17.05 26.3166C15.8667 25.2833 13.9834 25.1833 12.65 26.0833L6.41671 30.2666C6.05004 29.3333 5.83337 28.2499 5.83337 26.9833V13.0166C5.83337 8.31659 8.31671 5.83325 13.0167 5.83325H26.9834C31.6834 5.83325 34.1667 8.31659 34.1667 13.0166V21.0166L33.95 20.8333Z"
                fill="white"
              />
            </g>
          </g>
        </svg>
      </div>

      {!isBanner ? (
        <div className="content">
          <h2 className="skeleton"></h2>
          <p className="skeleton"></p>
          <p className="skeleton"></p>
          <div
            className="close"
            onClick={() => {
              setClose(true);
            }}
          ></div>
        </div>
      ) : (
        false
      )}
    </div>
  ) : (
    false
  );
};

CardSkeleton.propTypes = {
  class: "",
  mode: "",
  type: "",
};

export default CardSkeleton;
