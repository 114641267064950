import SvgIcon from "@material-ui/core/SvgIcon";

export default function AccordionExpand(props) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fill="none"
        d="M19 8.5L12 15.5L5 8.5"
        stroke="#344952"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
