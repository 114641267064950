import { Tabs } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  ToolbarClickIcon,
  ToolbarCtrIcon,
  ToolbarImpIcon,
  ToolbarIncomeIcon,
  ToolbarOverviewIcon,
  ToolbarViewIcon,
} from "@/assets/images/ssp";
import LineChartWidget from "@/ssp/components/charts/LineChartWidget";

import "./ToolbarChart.less";

const TabTitle = (props) => {
  const { detail } = props;
  return (
    <div className="tab-title">
      <img src={detail.icon} />
      <div>{detail.title}</div>
    </div>
  );
};

TabTitle.propTypes = {
  detail: "",
};

const ToolbarChart = () => {
  const { t } = useTranslation("ssp");
  const tabItems = [
    {
      key: "overview",
      title: t("dashboard.overview"),
      icon: ToolbarOverviewIcon,
      disabled: true,
    },
    {
      key: "view",
      title: t("dashboard.view"),
      icon: ToolbarViewIcon,
    },
    {
      key: "imp",
      title: t("dashboard.imp"),
      icon: ToolbarImpIcon,
    },
    {
      key: "click",
      title: t("dashboard.click"),
      icon: ToolbarClickIcon,
    },
    {
      key: "ctr",
      title: t("dashboard.ctr"),
      disabled: true,
      icon: ToolbarCtrIcon,
    },
    {
      key: "income",
      title: t("dashboard.income"),
      disabled: true,
      icon: ToolbarIncomeIcon,
    },
  ];
  return (
    <Tabs className="toolbar-chart" defaultActiveKey={"view"}>
      {tabItems.map((item) => {
        return (
          <Tabs.TabPane
            tab={<TabTitle detail={item} />}
            key={item.key}
            disabled={item.disabled}
          >
            {item.key === "view" && (
              <LineChartWidget title="view" type="VIEW" />
            )}
            {item.key === "imp" && (
              <LineChartWidget title="impression" type="IMPRESSION" />
            )}
            {item.key === "click" && (
              <LineChartWidget title="click" type="CLICK_REDIRECT" />
            )}
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  );
};
export default ToolbarChart;
