import { MenuItem, withStyles } from "@material-ui/core";

const CustomMenuItem = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    fontSize: "10px",
    "&:hover": {
      backgroundColor: "rgb(239, 247, 230)",
    },

    "& .MuiListItemIcon-root": {
      minWidth: "30px",
    },
    "& .MuiTypography-root": {
      ...theme.typography.body2.regular,
    },
  },
}))(MenuItem);

export default CustomMenuItem;
