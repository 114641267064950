import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// import { baseAPI } from "@/app/services/baseApi";
import { ENDPOINTS } from "@/utils/constants/apiConstants";

import { baseAPI } from "../services/fetchers";

export const getMerchantPayoutListAsync = createAsyncThunk(
  "merchantPayout/getOrdersAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.MERCHANT_PAYOUT_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: data || {},
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const addMerchantPayoutAsync = createAsyncThunk(
  "merchantPayout/addMerchantPayoutAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.MERCHANT_PAYOUT_ADD;
      const response = await baseAPI({
        endPoint: end_,
        body: data || {},
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const editMerchantPayoutAsync = createAsyncThunk(
  "merchantPayout/editMerchantPayoutAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.MERCHANT_PAYOUT_EDIT;
      const response = await baseAPI({
        endPoint: end_,
        body: data || {},
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getPayoutDetailAsync = createAsyncThunk(
  "merchantPayout/getPayoutDetailAsync",
  async (payoutId) => {
    try {
      let end_ = ENDPOINTS.MERCHANT_PAYOUT_DETAIL.replace(
        "{payoutId}",
        payoutId
      );
      const response = await baseAPI({
        endPoint: end_,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const merchantPayoutApproveAsync = createAsyncThunk(
  "merchantPayout/merchantPayoutApproveAsync",
  async (data) => {
    try {
      let end_ =
        data.status === "APPROVED"
          ? ENDPOINTS.MERCHANT_PAYOUT_APPROVE
          : ENDPOINTS.MERCHANT_PAYOUT_REJECT;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

const slice = createSlice({
  name: "merchantPayout",
  initialState: {
    list: null,
    listLoading: false,
    listError: null,

    payoutDetail: null,
    payoutDetailLoading: false,
    payoutDetailError: null,
  },
  reducers: {
    clearState: (state) => {
      state.listLoading = false;
      state.listError = null;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(getMerchantPayoutListAsync.pending, (state) => {
      state.listLoading = true;
      state.ordersError = null;
    });
    builder.addCase(getMerchantPayoutListAsync.fulfilled, (state, action) => {
      state.listLoading = false;
      if (action.payload.success) {
        state.list = action.payload;
      } else {
        state.listError = action.payload;
      }
    });
    builder.addCase(getMerchantPayoutListAsync.rejected, (state, action) => {
      state.listLoading = false;
      state.listError = null;
    });

    builder.addCase(getPayoutDetailAsync.pending, (state) => {
      state.payoutDetailLoading = true;
      state.payoutDetailError = null;
    });
    builder.addCase(getPayoutDetailAsync.fulfilled, (state, action) => {
      state.payoutDetailLoading = false;
      if (action.payload.success) {
        state.payoutDetail = action.payload;
      } else {
        state.payoutDetailError = action.payload;
      }
    });
    builder.addCase(getPayoutDetailAsync.rejected, (state, action) => {
      state.payoutDetailLoading = false;
      state.payoutDetailError = null;
    });
  },
});

export const { clearState } = slice.actions;

export default slice.reducer;

export const getMerchantPayoutList = (data) => async (dispatch) => {
  return dispatch(getMerchantPayoutListAsync(data));
};

export const addMerchantPayout = (data) => async (dispatch) => {
  return dispatch(addMerchantPayoutAsync(data));
};

export const editMerchantPayout = (data) => async (dispatch) => {
  return dispatch(editMerchantPayoutAsync(data));
};

export const getPayoutDetail = (data) => async (dispatch) => {
  return dispatch(getPayoutDetailAsync(data));
};

export const merchantPayoutApprove = (data) => async (dispatch) => {
  return dispatch(merchantPayoutApproveAsync(data));
};
