import { Form, Select } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getAdminMediaActiveList,
  getAdminMediaActiveMiniList,
} from "@/app/local/mediaSlice";
import { Privileges } from "@/utils/constants/accessCenter";
import { hasAccess } from "@/utils/helper";
import { useAccount } from "@/utils/hooks/useAuth";

const SelectMedia = (props) => {
  const { name, label, rules, placeholder, size, mode, readonly, showID } =
    props;
  const dispatch = useDispatch();
  const { mediaActiveListLoading, mediaActiveList, mediaActiveListError } =
    useSelector((state) => state.media);

  const [searchTerm, setSearchTerm] = useState();

  const { roleId, isAdmin, user, isPublisher, isAdvertiser } = useAccount();
  const mediaList = useMemo(() => {
    let response = [];
    if (isPublisher || isAdvertiser) {
      response = _.get(mediaActiveList, "data", []).map((item) => {
        return {
          media_id: item.media_id,
          media_name: item.name,
        };
      });
    } else {
      response = _.get(mediaActiveList, "data.result", []).map((item) => {
        return {
          media_id: item.media_id,
          media_name: item.media_name,
        };
      });
    }

    return response;
  }, [isPublisher, mediaActiveList]);

  const mediaDetailAccess = hasAccess(user, [
    Privileges.publisher.VIEW_PUBLISHER_DETAIL,
  ]);

  useEffect(() => {
    if (roleId) {
      if (isAdmin || mediaDetailAccess) {
        // load mini api
        let data = {};

        if (searchTerm) {
          data = { search_text: searchTerm };
        }
        dispatch(getAdminMediaActiveMiniList(data));
        // dispatch(getMediaActiveList());
      } else {
        // load main api for publisher
        dispatch(getAdminMediaActiveList());
      }
    }
  }, [roleId, searchTerm]);

  return (
    <Form.Item name={name} rules={rules} label={label}>
      <Select
        maxTagCount="responsive"
        loading={mediaActiveListLoading}
        allowClear
        mode={mode}
        placeholder={placeholder}
        showSearch
        size={size || "large"}
        onSearch={_.debounce((input) => {
          setSearchTerm(input);
        }, 500)}
        filterOption={(input, option) => {
          const d = input.toLowerCase();
          return (
            option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        showArrow
        disabled={readonly}
      >
        {mediaList?.map((item) => (
          <Select.Option key={item.media_id} value={item.media_id}>
            {isAdmin || showID
              ? `${item.media_id}-${item.media_name}`
              : item.media_name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

SelectMedia.propTypes = {
  mode: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  rules: PropTypes.array,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  isAdmin: PropTypes.bool,
  readonly: PropTypes.bool,
  showID: PropTypes.bool,
};

export default SelectMedia;
