import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import { PlaceContext } from "../AdPlaceBuilder";
import AdPlaceLiveViewer from "../AdPlaceLiveViewer";
import CardSkeleton from "./CardSkeleton";
import FormMaster from "./FormMaster";
import "./MultiplexConfig.less";
import SkeltonGenerator from "./SkeltonGenerator";

const MultiplexConfig = (props) => {
  const { doFunction, form } = props;
  const [count, setCount] = useState(8);
  const [context, dispatchContext] = useContext(PlaceContext);

  const onFinish = (event) => {
    doFunction(event);
  };

  useEffect(() => {
    if (context?.active_live_tab === "MOBILE") {
      const v = form.getFieldValue("max_mobile_item_count") || 4;
      setCount(v);
    } else {
      const v = form.getFieldValue("max_item_count") || 8;
      setCount(v);
    }
  }, [context?.active_live_tab]);

  const onValuesChange = (e) => {
    if (context?.active_live_tab === "MOBILE") {
      setCount(e?.max_mobile_item_count || 4);
    } else {
      if (e?.max_item_count) {
        setCount(e?.max_item_count || 8);
      }
    }
  };

  return (
    <Row gutter={[24, 24]} className="main-config multiplex-config">
      <Col xs={10}>
        <div className="slider-config__main">
          <FormMaster
            config={{
              max_item_count: 8,
              max_mobile_item_count: 4,
              responsive_type: true,
            }}
            form={form}
            onValuesChange={onValuesChange}
            onFinish={onFinish}
          />
        </div>
      </Col>
      <Col xs={14}>
        <AdPlaceLiveViewer>
          <div className="box-preview">
            <div className="box-preview__content">
              <h2 className="skeleton"></h2>
              <SkeltonGenerator count={3} />
            </div>
            <div className="box-preview__widget">
              <div className="multiplex">
                {Array(count)
                  .fill(1)
                  .map((i) => (
                    <CardSkeleton key={"i" + i} />
                  ))}
              </div>
            </div>
          </div>
        </AdPlaceLiveViewer>
      </Col>
    </Row>
  );
};

MultiplexConfig.propTypes = {
  doFunction: PropTypes.any,
  form: PropTypes.any,
};
export default MultiplexConfig;
