import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React from "react";
import CustomizedButton from "./CustomizedButton";
// import CloseCircle from "./assets/icons/CloseCircle";
import BaseModal from "./BaseModal";
import { notification } from "../app/helper";
import { outlinedLargePrimaryBtn } from "../app/helper";

const UploadFile = ({ showUpload, setShowUpload, files, setFiles }) => {
  const classes = useStyles();
  const { t } = useTranslation("support");

  const handelDelete = (file) => {
    setFiles(files.filter((item) => item.name !== file.name));
  };

  const handleUpload = (e) => {
    if (e.target.files[0]?.size > 1100000) {
      notification(t("file_size_error"), "error");
    } else if (files.length > 4) {
      notification(t("file_number_error"), "error");
    } else if (
      files.filter((item) => item.name === [...e.target.files][0].name).length >
      0
    ) {
      notification(t("file_repetitive_error"), "error");
    } else {
      setFiles([...files, ...e.target.files]);
    }
  };

  return (
    <BaseModal
      isOpen={showUpload}
      titleText={t("upload_attach_file")}
      onClose={() => {
        setShowUpload(false);
      }}
      onMainBtnClick={() => {
        setShowUpload(false);
      }}
      mainBtnText={t("continue")}
      modalStyle={classes.root}
      isMainBtnDisabled={false}
    >
      <div className={classes.mainBox}>
        <label className={classes.filePropertyLabel}>
          {t("file_max_size")}
        </label>
        <label className={classes.filePropertyLabel}>
          {t("file_max_count")}
        </label>
        <CustomizedButton
          onClick={() => {}}
          isStartIcon
          buttonProperties={outlinedLargePrimaryBtn}
          hasType={true}
          extraStyle={classes.uploadBox}
        >
          <label className={classes.label}>{t("upload_attach_file")}</label>
          <label htmlFor="filePicker" className={classes.selectLayer} />
          <input
            type="file"
            accept="image/*"
            multiple
            value=""
            onChange={handleUpload}
            id="filePicker"
            className={classes.uploadInput}
          />
          <div className={classes.fileContainer}>
            {files?.map((file) => (
              <div key={file.name}>
                <span>{file.name}</span>
                <span onClick={() => handelDelete(file)}>
                  {/* <CloseCircle /> */}
                  NO
                </span>
              </div>
            ))}
          </div>
        </CustomizedButton>
      </div>
    </BaseModal>
  );
};

export default UploadFile;

UploadFile.propTypes = {
  showUpload: PropTypes.bool,
  setShowUpload: PropTypes.func,
  files: PropTypes.array,
  setFiles: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: 640,
  },
  mainBox: {
    border: "2px solid #eee",
    padding: theme.spacing(2),
    borderRadius: 16,
    marginBottom: theme.spacing(3),
  },
  uploadInput: {
    display: "none",
    cursor: "pointer",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  filePropertyLabel: {
    display: "block",
    marginBottom: theme.spacing(2),
  },
  uploadBox: {
    position: "relative",
    display: "block",
    width: "100% !important",
    borderColor: "#eee !important",
    minHeight: 200,
    border: "2px dashed",
    marginBottom: theme.spacing(3),
  },
  label: {
    width: "100%",
  },
  selectLayer: {
    position: "absolute",
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
  },
  fileContainer: {
    "& > div": {
      display: "flex",
      direction: "ltr",
      justifyContent: "flex-end",
      alignItems: "center",
      backgroundColor: "#7796A4",
      textAlign: "right",
      color: "#fff",
      padding: theme.spacing(1),
      paddingRight: theme.spacing(3),
      borderRadius: 16,
      marginTop: theme.spacing(1),
    },
    "& .MuiSvgIcon-root": {
      position: "relative",
      cursor: "pointer",
      display: "block",
      marginLeft: theme.spacing(2),
    },
  },
}));
