import { Bar } from "@ant-design/plots";
import { Col, Row } from "antd";
import { t } from "i18next";
import { truncate } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getTop10Links } from "@/app/local/reportSlice";
import { DashboardContext } from "@/pages/dashboard/DashboardAdmin";
import { kFormatter, numberFormat } from "@/utils/helper";

import NameTag from "../micro/NameTag";
import { ChartHolder } from "./AdminViewClickChart";
import { COLORS } from "./colors";
import { MOCK_TOP_10_PUBLISHERS } from "./mockData";

const sampleData = MOCK_TOP_10_PUBLISHERS;

const TopLinks = (props) => {
  const { title, pieChart } = props;
  const dispatch = useDispatch();
  const [context, dispatch_] = useContext(DashboardContext);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState({});

  const { top10Links, top10LinksLoading } = useSelector(
    (state) => state.report
  );

  useEffect(() => {
    dispatch(
      getTop10Links({
        web_store_ids: context.webstore,
        media_ids: context?.medias,
        from: context.period.fromDate,
        to: context.period.toDate,
        publisher_ids: context?.publisher_ids,
        filter_date_by: context?.filter_date_by,
      })
    );
  }, [context]);

  useEffect(() => {
    if (top10Links) {
      let temp = [...(top10Links?.data || [])];
      let dataItems = [];
      const tempTotal = { generatedOrder: 0, finalizedOrder: 0 };

      temp = temp.sort((a, b) => {
        const h =
          b.finialized_order_commission -
          a.finialized_order_commission +
          (b.generated_order_commission - a.generated_order_commission);
        return h;
      });

      temp.map((item) => {
        const name = truncate(item.name, { length: 20, omission: "..." });
        dataItems.push({
          name: name,
          value: item.finialized_order_commission,
          type: "dashboard.finalizedOrders",
        });
        tempTotal.finalizedOrder += item.finialized_order_commission;

        dataItems.push({
          name: name,
          value: item.generated_order_commission,
          type: "dashboard.generatedOrder",
        });
        tempTotal.generatedOrder += item.generated_order_commission;
      });

      setTotal(tempTotal);
      setData(dataItems);
    }
  }, [top10Links]);

  const chartConfig = {
    data: data,
    isStack: true,
    padding: [20, 20, 20, 125],
    appendPadding: 8,
    xField: "value",
    yField: "name",
    renderer: "svg",
    legend: false,
    maxBarWidth: 15,
    seriesField: "type",
    theme: {
      styleSheet: {
        fontFamily: "IRANYekan",
      },
    },
    xAxis: {
      label: {
        formatter: (v) => kFormatter(v, 1),
      },
    },
    tooltip: {
      formatter: (datum) => {
        return {
          name: `${t(datum.type)}(${t("general.currency")})`,
          value: numberFormat(datum.value),
        };
      },
    },
    color: ({ type, year }, ...x) => {
      switch (type) {
        case "dashboard.finalizedOrders":
          return COLORS.DASHBOARD_GREEN;
        case "dashboard.generatedOrder":
          return COLORS.DASHBOARD_ORANGE;
        default:
          return COLORS.DASHBOARD_BLUE;
      }
    },
  };

  return (
    <>
      <Row>
        <Col md={24} xs={24}>
          <div className={`tags-bar align__right`}>
            <NameTag title={t("dashboard.generatedOrder")} color="color_3" />
            <NameTag title={t("dashboard.finalizedOrders")} color="color_5" />
          </div>
          <div dir="ltr" style={{ direction: "ltr" }}>
            <ChartHolder
              loading={top10LinksLoading}
              len={data?.length}
              height="400px"
            >
              <Bar {...chartConfig} loading={top10LinksLoading} />
            </ChartHolder>
          </div>
        </Col>
      </Row>
    </>
  );
};

TopLinks.propTypes = {
  title: PropTypes.string,
  pieChart: PropTypes.bool,
};
export default TopLinks;
