import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Affix, Button, Dropdown, Menu } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const FabIcon = (props) => {
  const { t } = useTranslation();
  const menu = (
    <Menu>
      {props?.items
        ?.filter((item) => item?.title)
        .map((item, i) => (
          <Menu.Item key={i}>
            <a onClick={() => props.setCollapsed(item)}>{t(item.title)}</a>
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <Affix offsetBottom={0} style={{ position: "fixed", bottom: 50, left: 20 }}>
      <Dropdown overlay={menu} placement="topRight" arrow trigger={["click"]}>
        <Button
          type="primary"
          shape="circle"
          icon={<EditOutlined style={{ fontSize: 24 }} />}
          size={"large"}
        />
      </Dropdown>
    </Affix>
  );
};

FabIcon.propTypes = {
  items: PropTypes.array,
  setCollapsed: PropTypes.func,
};

export const FabIconAdd = (props) => {
  const { t } = useTranslation();

  return (
    <Affix offsetBottom={0} style={{ position: "fixed", bottom: 50, left: 20 }}>
      <Button
        onClick={() => props.setCollapsed(true)}
        type="primary"
        shape="circle"
        icon={<PlusOutlined style={{ fontSize: 24 }} />}
        size={"large"}
      />
    </Affix>
  );
};

FabIconAdd.propTypes = {
  setCollapsed: PropTypes.func,
};

export default FabIcon;
