import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { ENDPOINTS } from "@/utils/constants/apiConstants";
import { clearObject } from "@/utils/utils";

import { baseAPI } from "../services/fetchers";

const requestBodyListDefault = {
  page: 1,
  page_size: 10,
  sort_field: "",
  search_text: "",
  is_pagination: true,
  sort_orientation: "1",
};

export const getWebsiteListAsync = createAsyncThunk(
  "website/getWebsiteListAsync",
  async (data) => {
    try {
      const data_ = clearObject(data);
      const body_ = Object.assign({}, requestBodyListDefault, data_);
      const end_ = ENDPOINTS.WEBSITE_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getWebsiteActiveListAsync = createAsyncThunk(
  "website/getWebsiteActiveListAsync",
  async () => {
    try {
      const end_ = ENDPOINTS.WEBSITE_LIST_ACTIVE;
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getWebsitePublisherListAsync = createAsyncThunk(
  "website/getWebsitePublisherListAsync",
  async (pubId) => {
    try {
      const end_ = ENDPOINTS.WEBSITE_PUBLISHER_LIST.replace(
        "{publisherId}",
        pubId || 0
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getWebsiteMerchantListAsync = createAsyncThunk(
  "website/getWebsiteMerchantListAsync",
  async (mId) => {
    try {
      const end_ = ENDPOINTS.WEBSITE_MERCHANT_LIST.replace(
        "{merchant_id}",
        mId || 0
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getWebsiteDetailAsync = createAsyncThunk(
  "website/getWebsiteDetail",
  async (webId) => {
    try {
      const end_ = ENDPOINTS.WEBSITE_DETAIL.replace("{webStoreId}", webId);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const addWebsiteAsync = createAsyncThunk(
  "website/addWebsiteAsync",
  async (data) => {
    const defaultInput = {
      // merchant_id: 0,
      name: "string",
      url: "string",
      subjects: [
        {
          subject_id: 0,
          name: "string",
          create_date: "2022-04-19T06:47:31.793Z",
          last_modify_date: "2022-04-19T06:47:31.793Z",
          is_enabled: true,
        },
      ],
      // create_date: "2022-04-19T06:47:31.793Z",
      // last_modify_date: "2022-04-19T06:47:31.793Z",
      // is_enabled: true,
    };

    try {
      // const body_ = Object.assign({}, requestBodyListDefault, data);
      const end_ = ENDPOINTS.WEBSITE_ADD;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const editWebsiteAsync = createAsyncThunk(
  "website/editWebsiteAsync",
  async (data) => {
    const defaultInput = {
      website_id: 0,
      name: "string",
      url: "string",
      subjects: [
        {
          subject_id: 0,
          name: "string",
          create_date: "2022-04-19T06:47:31.793Z",
          last_modify_date: "2022-04-19T06:47:31.793Z",
          is_enabled: true,
        },
      ],
    };

    try {
      // const body_ = Object.assign({}, requestBodyListDefault, data);
      const end_ = ENDPOINTS.WEBSITE_EDIT;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const deleteWebsiteAsync = createAsyncThunk(
  "website/deleteWebsiteAsync",
  async (wId) => {
    try {
      // const body_ = Object.assign({}, requestBodyListDefault, data);
      const end_ = ENDPOINTS.WEBSITE_DELETE.replace("{webStoreId}", wId);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const addWebsiteLevelAsync = createAsyncThunk(
  "website/addWebsiteLevelAsync",
  async (data) => {
    const defaultInput = {
      // level_id: 0,
      website_id: 0,
      is_default: true,
      name: "string",
      // new_customer_price: {
      //   amount: 0,
      //   currency: "1 = Rial",
      //   discount: 0,
      // },
      // cookie_expire_days: 0,
      // create_date: "2022-04-19T09:35:30.628Z",
      // last_modify_date: "2022-04-19T09:35:30.628Z",
      // is_enabled: true,
    };

    try {
      // const body_ = Object.assign({}, requestBodyListDefault, data);
      const end_ = ENDPOINTS.WEBSITE_LEVEL_ADD.replace(
        "{webStoreId}",
        data.website_id
      );
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const updateWebsiteLevelAsync = createAsyncThunk(
  "website/updateWebsiteLevelAsync",
  async (data) => {
    const defaultInput = {
      level_id: 0,
      website_id: 0,
      is_default: true,
      name: "string",
      new_customer_price: {
        amount: 0,
        currency: "1 = Rial",
        discount: 0,
      },
      cookie_expire_days: 0,
      create_date: "2022-04-20T07:53:00.281Z",
      last_modify_date: "2022-04-20T07:53:00.281Z",
      is_enabled: true,
    };

    try {
      // const body_ = Object.assign({}, requestBodyListDefault, data);
      const end_ = ENDPOINTS.WEBSITE_LEVEL_EDIT;

      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const websiteUpdateStateAsync = createAsyncThunk(
  "website/updateWebsiteLevelAsync",
  async (data) => {
    try {
      // const body_ = Object.assign({}, requestBodyListDefault, data);
      let end_ = ENDPOINTS.WEBSITE_STATUS.replace("{webStoreId}", data.id);
      end_ = end_.replace("{status}", data.status);

      const response = await baseAPI({
        endPoint: end_,
        // body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const addWebsiteLevelBatchAsync = createAsyncThunk(
  "website/addWebsiteLevelBatchAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.WEBSITE_LEVEL_ADD_BATCH.replace(
        "{webStoreId}",
        data.websiteId
      );
      const response = await baseAPI({
        endPoint: end_,
        body: data.body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getWebsiteLevelsListAsync = createAsyncThunk(
  "website/getWebsiteLevelsListAsync",
  async (data) => {
    const defaultBody = {
      is_pagination: false,
      sort_orientation: "1",
    };
    const body_ = Object.assign({}, defaultBody, data);
    try {
      // const body_ = Object.assign({}, requestBodyListDefault, data);
      const end_ = ENDPOINTS.WEBSITE_LEVEL_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getWebsiteLevelsOfPublisherListAsync = createAsyncThunk(
  "website/getWebsiteLevelsOfPublisherListAsync",
  async (data) => {
    const defaultBody = {
      is_pagination: false,
      sort_orientation: "1",
    };
    const body_ = Object.assign({}, defaultBody, data);
    try {
      const endPointWebsiteLevelActive = ENDPOINTS.WEBSITE_LEVEL_ACTIVE_LIST;
      const responseActiveLevels = await baseAPI({
        endPoint: endPointWebsiteLevelActive,
      });
      const activeLevels = responseActiveLevels?.data?.data?.filter(
        (item) => item.web_store_id === body_.filter?.web_store_id
      );

      // OLD METHODS
      // const body_ = Object.assign({}, requestBodyListDefault, data);
      // const end_ = ENDPOINTS.WEBSITE_LEVEL_LIST;
      // const response = await baseAPI({
      //   endPoint: end_,
      //   body: body_,
      // });

      // const endPointMediaPublisher =
      //   ENDPOINTS.MEDIA_PUBLISHER_ACTIVE_LIST.replace(
      //     "{publisherId}",
      //     data.publisherId || 0
      //   );
      // const response_ = await baseAPI({
      //   endPoint: endPointMediaPublisher,
      //   body: {
      //     is_pagination: false,
      //   },
      // });

      // const activeLevelsSet = new Set();
      // response_.data?.data?.map((item) => {
      //   item.Web_Stores?.map((web_) => {
      //     web_.web_store_id === body_.filter?.web_store_id &&
      //       activeLevelsSet.add(web_.level_id);
      //   });
      // });
      // const filteredLevels = _.get(response, "data.data.result", [])?.filter(
      //   (item) => activeLevelsSet.has(item.level_id) || item.is_default
      // );

      const responseLast = { ...responseActiveLevels.data };
      _.set(responseLast, "data.result", activeLevels);

      // The value we return becomes the `fulfilled` action payload
      return { ...responseLast };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getLevelCommissionsAsync = createAsyncThunk(
  "website/getLevelCommissionsAsync",
  async (levelId) => {
    try {
      const end_ = ENDPOINTS.WEBSITE_COMMISSION_CURRENT.replace(
        "{level_id}",
        levelId
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const updateCommissionsAsync = createAsyncThunk(
  "website/updateCommissionsAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.WEBSITE_COMMISSION_ADD.replace(
        "{level_id}",
        data.level_id
      );
      const body = data.body;
      const response = await baseAPI({
        endPoint: end_,
        body: body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const commissionAddExcelAsync = createAsyncThunk(
  "website/commissionAddExcelAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.WEBSITE_COMMISSION_ADD_EXCEL.replace(
        "{level_id}",
        data.level_id
      );
      end_ = end_.replace("{fileId}", data.fileId);
      // const body = data.body;
      const response = await baseAPI({
        endPoint: end_,
        // body: body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const commissionProductAddExcelAsync = createAsyncThunk(
  "website/commissionProductAddExcelAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.WEBSITE_LEVEL_PRODUCT_COMMISSIONS_ADD_EXCEL.replace(
        "{levelId}",
        data.level_id
      );
      end_ = end_.replace("{fileId}", data.fileId);
      // const body = data.body;
      const response = await baseAPI({
        endPoint: end_,
        // body: body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const downloadCommissionExcelAsync = createAsyncThunk(
  "website/downloadCommissionExcelAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.WEBSITE_COMMISSION_DOWNLOAD_EXCEL.replace(
        "{level_id}",
        data.level_id
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getProductListAsync = createAsyncThunk(
  "website/getProductListAsync",
  async (data) => {
    try {
      const body_ = {
        page: 1,
        page_size: 12,
        sort_orientation: "1",
      };

      const body = Object.assign({}, body_, data);

      const end_ = ENDPOINTS.WEBSITE_PRODUCT_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getCategoryListAsync = createAsyncThunk(
  "shared/getCategoryListAsync",
  async (data) => {
    try {
      const body_ = {
        page: 1,
        page_size: 10,
        is_pagination: true,
        sort_orientation: "1",
      };

      const body = Object.assign({}, body_, data);
      delete body.website_id;

      const end_ = ENDPOINTS.WEBSITE_CATEGORY_LIST.replace(
        "{webStoreId}",
        data.website_id
      );
      const response = await baseAPI({
        endPoint: end_,
        body: body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const commissionApprovedAsync = createAsyncThunk(
  "shared/commissionApprovedAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.WEBSITE_LEVEL_COMMISSION_APPROVED.replace(
        "{commissionGroupId}",
        data.commissionGroupId
      );
      const response = await baseAPI({
        endPoint: end_,
        body: data.body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const commissionRejectAsync = createAsyncThunk(
  "shared/commissionRejectAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.WEBSITE_LEVEL_COMMISSION_REJECT.replace(
        "{commissionGroupId}",
        data.commissionGroupId
      );
      const response = await baseAPI({
        endPoint: end_,
        body: data.body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const commissionCloseAsync = createAsyncThunk(
  "shared/commissionCloseAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.WEBSITE_LEVEL_COMMISSION_CLOSE.replace(
        "{commissionGroupId}",
        data.commissionGroupId
      );
      const response = await baseAPI({
        endPoint: end_,
        body: data.body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getCommissionsPendingAsync = createAsyncThunk(
  "website/getCommissionsPendingAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.WEBSITE_LEVEL_COMMISSION_PENDING;
      const response = await baseAPI({
        endPoint: end_,
        // body: data.body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getCommissionsHistoryListAsync = createAsyncThunk(
  "website/getCommissionsHistoryListAsync",
  async (levelId) => {
    try {
      const end_ = ENDPOINTS.WEBSITE_LEVEL_COMMISSION_HISTORY.replace(
        "{levelId}",
        levelId
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getCommissionsWithGroupIdAsync = createAsyncThunk(
  "website/getCommissionsWithGroupIdAsync",
  async (cGid) => {
    try {
      const end_ = ENDPOINTS.WEBSITE_LEVEL_COMMISSION_GROUP_ID.replace(
        "{commissionGroupId}",
        cGid
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getPaymentPolicyHistoryAsync = createAsyncThunk(
  "website/getPaymentPolicyHistoryAsync",
  async (data) => {
    try {
      const urlParams = new URLSearchParams();
      urlParams.set("webStoreId", data.webStoreId);
      data.fromDate && urlParams.set("fromDate", data.fromDate);
      data.toDate && urlParams.set("toDate", data.toDate);
      const end_ = ENDPOINTS.WEBSITE_PAYMENT_POLICY_HISTORY.replace(
        "{queryParams}",
        urlParams.toString()
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setPaymentPolicyAsync = createAsyncThunk(
  "website/setPaymentPolicyAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.WEBSITE_PAYMENT_POLICY_SET;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getPaymentPolicyCurrentAsync = createAsyncThunk(
  "website/getPaymentPolicyCurrentAsync",
  async (webId) => {
    try {
      const end_ = ENDPOINTS.WEBSITE_PAYMENT_POLICY_CURRENT.replace(
        "{webStoreId}",
        webId
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getPaymentPolicyCurrentPublicAsync = createAsyncThunk(
  "website/getPaymentPolicyCurrentPublicAsync",
  async (webId) => {
    try {
      const end_ = ENDPOINTS.WEBSITE_PAYMENT_POLICY_CURRENT.replace(
        "{webStoreId}",
        webId
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const addProductCommissionsAsync = createAsyncThunk(
  "website/addProductCommissionsAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.WEBSITE_LEVEL_PRODUCT_COMMISSIONS_ADD.replace(
        "{levelId}",
        data.level
      );
      const response = await baseAPI({
        endPoint: end_,
        body: data.body,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getCurrentProductCommissionsAsync = createAsyncThunk(
  "website/getCurrentProductCommissionsAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.WEBSITE_LEVEL_PRODUCT_COMMISSIONS_CURRENT.replace(
        "{levelId}",
        data
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const commissionProductApprovedAsync = createAsyncThunk(
  "website/commissionProductApprovedAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.WEBSITE_LEVEL_PRODUCT_COMMISSIONS_APPROVED.replace(
        "{commissionGroupId}",
        data.commissionGroupId
      );
      const response = await baseAPI({
        endPoint: end_,
        body: data.body,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const commissionProductActiveAsync = createAsyncThunk(
  "website/commissionProductActiveAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.WEBSITE_LEVEL_PRODUCT_COMMISSIONS_ACTIVE.replace(
        "{commissionGroupId}",
        data.commissionGroupId
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const commissionProductRejectAsync = createAsyncThunk(
  "website/commissionProductRejectAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.WEBSITE_LEVEL_PRODUCT_COMMISSIONS_REJECT.replace(
        "{commissionGroupId}",
        data.commissionGroupId
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getCurrentProductExcelAsync = createAsyncThunk(
  "website/getCurrentProductExcelAsync",
  async (data) => {
    try {
      let end_ =
        ENDPOINTS.WEBSITE_LEVEL_PRODUCT_COMMISSIONS_GET_CURRENT_EXCEL.replace(
          "{levelId}",
          data
        );
      const response = await baseAPI({
        endPoint: end_,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getProductCommissionsHistoryListAsync = createAsyncThunk(
  "website/getProductCommissionsHistoryListAsync",
  async (data) => {
    try {
      let end_ =
        ENDPOINTS.WEBSITE_LEVEL_PRODUCT_COMMISSIONS_GET_HISTORY_LIST.replace(
          "{levelId}",
          data
        );

      const response = await baseAPI({
        endPoint: end_,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const websiteSlice = createSlice({
  name: "website",
  initialState: {
    loading: false,
    websites: null,
    website: null,
    error: null,

    websitesActive: null,
    websitesActiveLoading: false,
    websitesActiveError: null,

    websitesPublisher: null,
    websitesPublisherLoading: false,
    websitesPublisherError: null,

    websitesMerchant: null,
    websitesMerchantLoading: false,
    websitesMerchantError: null,

    addLoading: false,
    editLoading: false,

    websiteLevelBatch: null,
    websiteLevelBatchLoading: false,
    websiteLevelBatchError: false,

    webSitesLevels: null,
    webSitesLevelsLoading: false,

    websiteLoading: false,

    commissionsLoading: false,
    commissions: null,

    loadingAddExcel: false,

    products: null,
    productsLoading: false,
    productsError: null,

    categories: null,
    categoriesLoading: false,
    categoriesError: null,

    commissionsPending: null,
    commissionsPendingLoading: false,
    commissionsPendingError: null,

    commissionsHistory: null,
    commissionsHistoryLoading: false,
    commissionsHistoryError: null,

    commissionHistoryDetail: null,
    commissionHistoryDetailLoading: false,
    commissionHistoryDetailError: null,

    paymentPolicy: null,
    paymentPolicyLoading: false,
    paymentPolicyError: null,

    paymentPolicies: null,
    paymentPoliciesLoading: false,
    paymentPoliciesError: null,

    productCommissionsData: null,
    productCommissionsDataLoading: false,
    productCommissionsDataError: null,

    addProductCommissionLoading: false,
  },
  reducers: {
    clearState: (state) => {
      state.website = null;
      state.webSitesLevels = null;
      state.commissions = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWebsiteListAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
      state.webSitesLevels = null;
    });
    builder.addCase(getWebsiteListAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getWebsiteListAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.websites = action.payload;
      }
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getWebsiteMerchantListAsync.pending, (state, action) => {
      state.websitesMerchantLoading = true;
      state.error = null;
      state.websitesMerchant = null;
    });
    builder.addCase(getWebsiteMerchantListAsync.rejected, (state, action) => {
      state.websitesMerchantLoading = false;
      state.error = action.error;
    });
    builder.addCase(getWebsiteMerchantListAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.websitesMerchant = action.payload;
      }
      state.websitesMerchantLoading = false;
      state.error = null;
    });

    builder.addCase(addWebsiteAsync.pending, (state, action) => {
      state.addLoading = true;
      state.error = null;
    });
    builder.addCase(addWebsiteAsync.rejected, (state, action) => {
      state.addLoading = false;
      state.error = action.error;
    });
    builder.addCase(addWebsiteAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.website = action.payload;
      }
      state.addLoading = false;
      state.error = null;
    });

    builder.addCase(editWebsiteAsync.pending, (state, action) => {
      state.editLoading = true;
    });
    builder.addCase(editWebsiteAsync.rejected, (state, action) => {
      state.editLoading = false;
    });
    builder.addCase(editWebsiteAsync.fulfilled, (state, action) => {
      state.editLoading = false;
    });

    builder.addCase(addWebsiteLevelBatchAsync.pending, (state, action) => {
      state.websiteLevelBatchLoading = true;
      state.websiteLevelBatchError = null;
    });
    builder.addCase(addWebsiteLevelBatchAsync.rejected, (state, action) => {
      state.websiteLevelBatchLoading = false;
      state.websiteLevelBatchError = action.error;
    });
    builder.addCase(addWebsiteLevelBatchAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.websiteLevelBatch = action.payload;
      }
      state.websiteLevelBatchLoading = false;
      state.websiteLevelBatchError = null;
    });

    builder.addCase(getWebsiteLevelsListAsync.pending, (state, action) => {
      state.webSitesLevelsLoading = true;
      state.error = null;
    });
    builder.addCase(getWebsiteLevelsListAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.webSitesLevels = action.payload;
      }
      state.webSitesLevelsLoading = false;
      state.error = null;
    });
    builder.addCase(getWebsiteLevelsListAsync.rejected, (state, action) => {
      state.webSitesLevelsLoading = false;
      state.error = action.error;
    });

    builder.addCase(
      getWebsiteLevelsOfPublisherListAsync.pending,
      (state, action) => {
        state.webSitesLevels = null;
        state.webSitesLevelsLoading = true;
        state.error = null;
      }
    );
    builder.addCase(
      getWebsiteLevelsOfPublisherListAsync.fulfilled,
      (state, action) => {
        if (action.payload.success) {
          state.webSitesLevels = action.payload;
        }
        state.webSitesLevelsLoading = false;
        state.error = null;
      }
    );
    builder.addCase(
      getWebsiteLevelsOfPublisherListAsync.rejected,
      (state, action) => {
        state.webSitesLevelsLoading = false;
        state.error = action.error;
      }
    );

    builder.addCase(getWebsiteDetailAsync.pending, (state, action) => {
      state.websiteLoading = true;
      state.error = null;
    });
    builder.addCase(getWebsiteDetailAsync.rejected, (state, action) => {
      state.websiteLoading = false;
      state.error = action.error;
    });
    builder.addCase(getWebsiteDetailAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.website = action.payload;
      }
      state.websiteLoading = false;
      state.error = null;
    });

    builder.addCase(getLevelCommissionsAsync.pending, (state, action) => {
      state.commissionsLoading = true;
      state.error = null;
    });
    builder.addCase(getLevelCommissionsAsync.rejected, (state, action) => {
      state.commissionsLoading = false;
      state.error = action.error;
    });
    builder.addCase(getLevelCommissionsAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.commissions = action.payload;
      }
      state.commissionsLoading = false;
      state.error = null;
    });

    builder.addCase(updateCommissionsAsync.pending, (state, action) => {
      state.commissionsLoading = true;
      state.error = null;
    });
    builder.addCase(updateCommissionsAsync.rejected, (state, action) => {
      state.commissionsLoading = false;
      state.error = action.error;
    });
    builder.addCase(updateCommissionsAsync.fulfilled, (state, action) => {
      state.commissionsLoading = false;
      state.error = null;
    });

    builder.addCase(commissionAddExcelAsync.pending, (state, action) => {
      state.loadingAddExcel = true;
      state.error = null;
    });
    builder.addCase(commissionAddExcelAsync.rejected, (state, action) => {
      state.loadingAddExcel = false;
      state.error = action.error;
    });
    builder.addCase(commissionAddExcelAsync.fulfilled, (state, action) => {
      state.loadingAddExcel = false;
      state.error = null;
    });

    builder.addCase(getWebsiteActiveListAsync.pending, (state, action) => {
      state.websitesActiveLoading = true;
      state.websitesActive = null;
    });
    builder.addCase(getWebsiteActiveListAsync.rejected, (state, action) => {
      state.websitesActiveLoading = false;
      state.websitesActiveError = action.error;
    });
    builder.addCase(getWebsiteActiveListAsync.fulfilled, (state, action) => {
      state.websitesActiveLoading = false;
      state.websitesActiveError = null;
      if (action.payload.success) {
        state.websitesActive = action.payload;
      }
    });

    builder.addCase(getWebsitePublisherListAsync.pending, (state, action) => {
      state.websitesPublisherLoading = true;
      state.websitesPublisher = null;
    });
    builder.addCase(getWebsitePublisherListAsync.rejected, (state, action) => {
      state.websitesPublisherLoading = false;
      state.websitesPublisherError = action.error;
    });
    builder.addCase(getWebsitePublisherListAsync.fulfilled, (state, action) => {
      state.websitesPublisherLoading = false;
      state.websitesPublisherError = null;
      if (action.payload.success) {
        state.websitesPublisher = action.payload;
      }
    });

    builder.addCase(getProductListAsync.pending, (state, action) => {
      state.productsLoading = true;
      state.productsError = null;
    });
    builder.addCase(getProductListAsync.rejected, (state, action) => {
      state.productsLoading = false;
      state.productsError = action.error;
    });
    builder.addCase(getProductListAsync.fulfilled, (state, action) => {
      state.productsLoading = false;
      state.productsError = null;
      if (action.payload.success) {
        state.products = action.payload;
      }
    });

    builder.addCase(getCategoryListAsync.pending, (state, action) => {
      state.categoriesLoading = true;
      state.categoriesError = null;
    });
    builder.addCase(getCategoryListAsync.rejected, (state, action) => {
      state.categoriesLoading = false;
      state.categoriesError = action.error;
    });
    builder.addCase(getCategoryListAsync.fulfilled, (state, action) => {
      state.categoriesLoading = false;
      state.categoriesError = null;
      if (action.payload.success) {
        state.categories = action.payload;
      }
    });

    builder.addCase(getCommissionsPendingAsync.pending, (state, action) => {
      state.commissionsPendingLoading = true;
      state.commissionsPendingError = null;
    });
    builder.addCase(getCommissionsPendingAsync.rejected, (state, action) => {
      state.commissionsPendingLoading = false;
      state.commissionsPendingError = action.error;
    });
    builder.addCase(getCommissionsPendingAsync.fulfilled, (state, action) => {
      state.commissionsPendingLoading = false;
      state.commissionsPendingError = null;
      if (action.payload.success) {
        state.commissionsPending = action.payload;
      }
    });

    builder.addCase(getCommissionsHistoryListAsync.pending, (state, action) => {
      state.commissionsHistoryLoading = true;
      state.commissionsHistoryError = null;
    });
    builder.addCase(
      getCommissionsHistoryListAsync.rejected,
      (state, action) => {
        state.commissionsHistoryLoading = false;
        state.commissionsHistoryError = action.error;
      }
    );
    builder.addCase(
      getCommissionsHistoryListAsync.fulfilled,
      (state, action) => {
        state.commissionsHistoryLoading = false;
        state.commissionsHistoryError = null;
        if (action.payload.success) {
          state.commissionsHistory = action.payload;
        }
      }
    );

    builder.addCase(getCommissionsWithGroupIdAsync.pending, (state, action) => {
      state.commissionHistoryDetailLoading = true;
      state.commissionHistoryDetailError = null;
    });
    builder.addCase(
      getCommissionsWithGroupIdAsync.rejected,
      (state, action) => {
        state.commissionHistoryDetailLoading = false;
        state.commissionHistoryDetailError = action.error;
      }
    );
    builder.addCase(
      getCommissionsWithGroupIdAsync.fulfilled,
      (state, action) => {
        state.commissionHistoryDetailLoading = false;
        state.commissionHistoryDetailError = null;
        if (action.payload.success) {
          state.commissionHistoryDetail = action.payload;
        }
      }
    );

    builder.addCase(setPaymentPolicyAsync.pending, (state, action) => {
      state.paymentPolicyLoading = true;
      state.paymentPolicyError = null;
    });
    builder.addCase(setPaymentPolicyAsync.rejected, (state, action) => {
      state.paymentPolicyLoading = false;
      state.paymentPolicyError = action.error;
    });
    builder.addCase(setPaymentPolicyAsync.fulfilled, (state, action) => {
      state.paymentPolicyLoading = false;
      state.paymentPolicyError = null;
      if (action.payload.success) {
        state.paymentPolicy = action.payload;
      }
    });

    builder.addCase(getPaymentPolicyHistoryAsync.pending, (state, action) => {
      state.paymentPoliciesLoading = true;
      state.paymentPoliciesError = null;
    });
    builder.addCase(getPaymentPolicyHistoryAsync.rejected, (state, action) => {
      state.paymentPoliciesLoading = false;
      state.paymentPoliciesError = action.error;
    });
    builder.addCase(getPaymentPolicyHistoryAsync.fulfilled, (state, action) => {
      state.paymentPoliciesLoading = false;
      state.paymentPoliciesError = null;
      if (action.payload.success) {
        state.paymentPolicies = action.payload;
      }
    });

    builder.addCase(getPaymentPolicyCurrentAsync.pending, (state, action) => {
      state.paymentPolicyLoading = true;
      state.paymentPolicyError = null;
    });
    builder.addCase(getPaymentPolicyCurrentAsync.rejected, (state, action) => {
      state.paymentPolicyLoading = false;
      state.paymentPolicyError = action.error;
    });
    builder.addCase(getPaymentPolicyCurrentAsync.fulfilled, (state, action) => {
      state.paymentPolicyLoading = false;
      state.paymentPolicyError = null;
      if (action.payload.success) {
        state.paymentPolicy = action.payload;
      }
    });

    builder.addCase(addProductCommissionsAsync.pending, (state, action) => {
      state.addProductCommissionLoading = true;
    });
    builder.addCase(addProductCommissionsAsync.rejected, (state, action) => {
      state.addProductCommissionLoading = false;
    });
    builder.addCase(addProductCommissionsAsync.fulfilled, (state, action) => {
      state.addProductCommissionLoading = false;
    });

    builder.addCase(
      getCurrentProductCommissionsAsync.pending,
      (state, action) => {
        state.productCommissionsDataLoading = true;
      }
    );
    builder.addCase(
      getCurrentProductCommissionsAsync.rejected,
      (state, action) => {
        state.productCommissionsDataLoading = false;
        state.productCommissionsDataError = null;
      }
    );
    builder.addCase(
      getCurrentProductCommissionsAsync.fulfilled,
      (state, action) => {
        if (action.payload.success) {
          state.productCommissionsData = action.payload;
        }
        state.productCommissionsDataLoading = false;
        state.productCommissionsDataError = null;
      }
    );

    builder.addCase(
      getProductCommissionsHistoryListAsync.pending,
      (state, action) => {
        state.commissionsHistoryLoading = true;
      }
    );
    builder.addCase(
      getProductCommissionsHistoryListAsync.rejected,
      (state, action) => {
        state.commissionsHistoryLoading = false;
        state.commissionsHistoryError = null;
      }
    );
    builder.addCase(
      getProductCommissionsHistoryListAsync.fulfilled,
      (state, action) => {
        if (action.payload.success) {
          state.commissionsHistory = action.payload;
        }
        state.commissionsHistoryLoading = false;
        state.commissionsHistoryError = null;
      }
    );
  },
});

export const getWebsiteList = (data) => async (dispatch) => {
  return dispatch(getWebsiteListAsync(data));
};

export const getWebsiteActiveList = (data) => async (dispatch) => {
  return dispatch(getWebsiteActiveListAsync(data));
};

export const getWebsitePublisherList = (data) => async (dispatch) => {
  return dispatch(getWebsitePublisherListAsync(data));
};

export const getWebsiteMerchantList = (data) => async (dispatch) => {
  return dispatch(getWebsiteMerchantListAsync(data));
};

export const getWebsiteDetail = (data) => async (dispatch) => {
  return dispatch(getWebsiteDetailAsync(data));
};

export const addWebsite = (data) => async (dispatch) => {
  return dispatch(addWebsiteAsync(data));
};

export const editWebsite = (data) => async (dispatch) => {
  return dispatch(editWebsiteAsync(data));
};

export const deleteWebsite = (data) => async (dispatch) => {
  return dispatch(deleteWebsiteAsync(data));
};

export const addWebsiteLevel = (data) => async (dispatch) => {
  return dispatch(addWebsiteLevelAsync(data));
};

export const updateWebsiteLevel = (data) => async (dispatch) => {
  return dispatch(updateWebsiteLevelAsync(data));
};

export const getWebsiteLevelsList = (data) => async (dispatch) => {
  return dispatch(getWebsiteLevelsListAsync(data));
};

export const getWebsiteLevelsOfPublisherList = (data) => async (dispatch) => {
  return dispatch(getWebsiteLevelsOfPublisherListAsync(data));
};

export const addWebsiteLevelBatch = (data) => async (dispatch) => {
  return dispatch(addWebsiteLevelBatchAsync(data));
};

export const websiteUpdateState = (data) => async (dispatch) => {
  return dispatch(websiteUpdateStateAsync(data));
};

export const getLevelCommissions = (data) => async (dispatch) => {
  return dispatch(getLevelCommissionsAsync(data));
};

export const updateCommissions = (data) => async (dispatch) => {
  return dispatch(updateCommissionsAsync(data));
};

export const commissionAddExcel = (data) => async (dispatch) => {
  return dispatch(commissionAddExcelAsync(data));
};

export const commissionProductAddExcel = (data) => async (dispatch) => {
  return dispatch(commissionProductAddExcelAsync(data));
};

export const downloadCommissionExcel = (data) => async (dispatch) => {
  return dispatch(downloadCommissionExcelAsync(data));
};

export const getProductList = (data) => async (dispatch) => {
  return dispatch(getProductListAsync(data));
};

export const getCategoryList = (data) => async (dispatch) => {
  return dispatch(getCategoryListAsync(data));
};

export const commissionApproved = (data) => async (dispatch) => {
  return dispatch(commissionApprovedAsync(data));
};

export const commissionReject = (data) => async (dispatch) => {
  return dispatch(commissionRejectAsync(data));
};

export const commissionClose = (data) => async (dispatch) => {
  return dispatch(commissionCloseAsync(data));
};

export const getCommissionsPending = (data) => async (dispatch) => {
  return dispatch(getCommissionsPendingAsync(data));
};

export const getCommissionsHistoryList = (data) => async (dispatch) => {
  return dispatch(getCommissionsHistoryListAsync(data));
};

export const getCommissionsWithGroupId = (data) => async (dispatch) => {
  return dispatch(getCommissionsWithGroupIdAsync(data));
};

export const getPaymentPolicyHistory = (data) => async (dispatch) => {
  return dispatch(getPaymentPolicyHistoryAsync(data));
};

export const setPaymentPolicy = (data) => async (dispatch) => {
  return dispatch(setPaymentPolicyAsync(data));
};

export const getPaymentPolicyCurrent = (data) => async (dispatch) => {
  return dispatch(getPaymentPolicyCurrentAsync(data));
};

export const getPaymentPolicyCurrentPublic = (data) => async (dispatch) => {
  return dispatch(getPaymentPolicyCurrentPublicAsync(data));
};

export const addProductCommissions = (data) => async (dispatch) => {
  return dispatch(addProductCommissionsAsync(data));
};

export const getCurrentProductCommissions = (data) => async (dispatch) => {
  return dispatch(getCurrentProductCommissionsAsync(data));
};

export const commissionProductApproved = (data) => async (dispatch) => {
  return dispatch(commissionProductApprovedAsync(data));
};

export const commissionProductActive = (data) => async (dispatch) => {
  return dispatch(commissionProductActiveAsync(data));
};

export const commissionProductReject = (data) => async (dispatch) => {
  return dispatch(commissionProductRejectAsync(data));
};

export const getCurrentProductExcel = (data) => async (dispatch) => {
  return dispatch(getCurrentProductExcelAsync(data));
};

export const getProductCommissionsHistoryList = (data) => async (dispatch) => {
  return dispatch(getProductCommissionsHistoryListAsync(data));
};

export const { clearState } = websiteSlice.actions;

export default websiteSlice.reducer;
