import { Card } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";
import React from "react";
import CountUp from "react-countup";

import {
  AIV,
  Bonus,
  CPO,
  Event,
  KiseGreen,
  KiseGreen0,
  KiseGreen1,
  KiseGreen2,
  KiseOrange,
  OrangeAttach,
  PinkVol,
} from "@/assets";

const Images = {
  PinkVol: PinkVol,
  OrangeAttach: OrangeAttach,
  KiseGreen: KiseGreen,
  KiseGreen0: KiseGreen0,
  KiseGreen1: KiseGreen1,
  KiseGreen2: KiseGreen2,
  KiseOrange: KiseOrange,
  Bonus: Bonus,
  Event: Event,
  CPO: CPO,
  AIV: AIV,
};
const CardInfo = (props) => {
  const { title, img, value, disabled, isPadding } = props;

  return (
    <div
      className="card-info-dashboard"
      style={isPadding && { padding: "10px 0px " }}
    >
      <Card>
        <img src={Images[img]} />
        <p>{t(`dashboard.${title}`)}</p>
        {disabled ? (
          <i style={{ color: "#B0B6C1" }}> به زودی</i>
        ) : (
          <>
            <CountUp delay={2} end={value || 0} />
            <i>{t("general.Toman")}</i>
          </>
        )}

        {/* <span>{numberFormat(value)}</span> */}
      </Card>
    </div>
  );
};
CardInfo.propTypes = {
  title: PropTypes.string,
  img: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  isPadding: PropTypes.bool,
};

export default CardInfo;
