import { Radio } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  USER_TYPE_REGISTER,
  USER_TYPE_SSP,
} from "@/utils/constants/apiConstants";
import useConfig from "@/utils/hooks/useConfig";

import "./SelectUserType.less";

const SelectUserType = (props) => {
  const form = props.form;
  const { isSSP } = useConfig();
  const UT = isSSP ? USER_TYPE_SSP : USER_TYPE_REGISTER;
  const { t } = useTranslation(["translation", "ssp"]);

  let optionsDesc = {
    PUBLISHER: t("auth.pub_intro"),
    MERCHANT: t("auth.mer_intro"),
  };
  if (isSSP) {
    optionsDesc = {
      ADVERTISER: t("auth.adv_intro", { ns: "ssp" }),
    };
  }
  const [selectedItem, setSelectedItem] = useState(
    isSSP ? UT.ADVERTISER : UT.PUBLISHER
  );
  useEffect(() => {
    form.setFieldsValue({ userType: selectedItem });
  }, [selectedItem]);

  return (
    <div className="select-user-type">
      {Object.keys(UT).map((key) => {
        return (
          <div
            key={key}
            className={`item-option ${
              selectedItem === UT[key] ? "active" : ""
            }`}
            onClick={() => setSelectedItem(UT[key])}
          >
            <b>
              <Radio key={key} checked={selectedItem === UT[key]} />
              {t(`auth.${key}`)}
            </b>
            <div className="item-option__intro">{optionsDesc[key]}</div>
          </div>
        );
      })}
    </div>
  );
};

SelectUserType.propTypes = {
  form: PropTypes.any,
};

export default SelectUserType;
