import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { ENDPOINTS } from "@/utils/constants/apiConstants";

import { baseAPI, baseAdvAPI } from "../services/fetchers";

export const uploadFileAsync = createAsyncThunk("file/upload", async (data) => {
  try {
    const formData = new FormData();
    formData.append("ObjectType", data.objectType);
    formData.append("ObjectId", data.objectId);
    formData.append("FileType", data.fileType);
    formData.append("MediaType", data.mediaType);
    formData.append("Uploader", data.uploader);

    const response = await baseAPI({
      endPoint: ENDPOINTS.FILE_ADD,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "multipart/form-data",
      },
      body: formData,
    });
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  } catch (e) {
    return e.response?.data;
  }
});

export const locationGetProvinces = createAsyncThunk(
  "location/getProvinces",
  async () => {
    try {
      const response = await baseAPI({
        endPoint: ENDPOINTS.LOCATION_GET_PROVINCES,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const locationGetCities = createAsyncThunk(
  "location/getCities",
  async (data) => {
    try {
      const end_ = ENDPOINTS.LOCATION_GET_BY_PARENT_ID.replace(
        "{parent_id}",
        data.id
      );
      const response = await baseAPI({
        endPoint: end_,
        // body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getFileAsync = createAsyncThunk(
  "location/getFile",
  async (data) => {
    try {
      const end_ = ENDPOINTS.FILE_DETAIL.replace("{file_id}", data);
      const response = await baseAPI({
        endPoint: end_,
        // body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getFileQueryStringAsync = createAsyncThunk(
  "location/getFileQueryString",
  async (data) => {
    let fileId = data;
    if (_.isObject(data)) {
      fileId = data?.fileId;
    }
    try {
      const urlParams = new URLSearchParams();
      urlParams.set("fileId", fileId);
      const end_ = ENDPOINTS.FILE_DETAIL_QUERY_STRING.replace(
        "{queryParams}",
        urlParams.toString()
      );
      const response = await baseAPI({
        endPoint: end_,
        // body: data,
        controller: data?.controller,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getTourAsync = createAsyncThunk(
  "app/getTourAsync",
  async (data) => {
    try {
      // const response = await baseMockApi({
      //   type: "tour",
      // });
      // The value we return becomes the `fulfilled` action payload
      // return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getAdvertiseAsync = createAsyncThunk(
  "app/getAdvertiseAsync",
  async (data) => {
    try {
      const response = await baseAdvAPI(data);
      return response;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const appSlice = createSlice({
  name: "app",
  initialState: {
    loading: false,
    loadingBar: false,
    loadingFile: false,
    provinces: null,
    provincesLoading: false,
    cities: null,
    file: null,
    snack: {},
    selectedTour: null,

    fileLoading: false,

    defaultLang: null,

    wpLoading: false,
  },
  reducers: {
    appLoading: (state, { payload }) => {
      state.loading = payload;
    },
    appLoadingBar: (state, { payload }) => {
      state.loadingBar = payload;
    },
    showSnack: (state, { payload }) => {
      state.snack = payload;
    },
    runTour: (state, { payload }) => {
      state.selectedTour = payload;
    },
    clearTour: (state, { payload }) => {
      state.selectedTour = undefined;
    },
    setDefaultLang: (state, { payload }) => {
      state.defaultLang = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadFileAsync.pending, (state) => {
      state.loadingFile = true;
      state.error = null;
    });
    builder.addCase(uploadFileAsync.fulfilled, (state, action) => {
      state.loadingFile = false;
      if (action.payload.success) {
        state.file = action.payload;
      } else {
        state.error = action.payload;
      }
    });
    builder.addCase(uploadFileAsync.rejected, (state, action) => {
      state.loadingFile = false;
      state.error = action.error;
    });
    builder.addCase(locationGetProvinces.pending, (state, action) => {
      state.provincesLoading = true;
    });
    builder.addCase(locationGetProvinces.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.provinces = action.payload;
      }
      state.provincesLoading = false;
    });
    builder.addCase(locationGetProvinces.rejected, (state, action) => {
      state.provincesLoading = false;
    });
    builder.addCase(locationGetCities.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.cities = action.payload;
      }
    });
    builder.addCase(getFileAsync.pending, (state, action) => {
      state.loadingFile = true;
    });
    builder.addCase(getFileAsync.rejected, (state, action) => {
      state.loadingFile = false;
    });
    builder.addCase(getFileAsync.fulfilled, (state, action) => {
      state.loadingFile = false;
    });
    builder.addCase(getFileQueryStringAsync.pending, (state, action) => {
      state.loadingFile = true;
    });
    builder.addCase(getFileQueryStringAsync.rejected, (state, action) => {
      state.loadingFile = false;
    });
    builder.addCase(getFileQueryStringAsync.fulfilled, (state, action) => {
      state.loadingFile = false;
    });
    builder.addCase(getAdvertiseAsync.pending, (state, action) => {
      state.wpLoading = true;
    });
    builder.addCase(getAdvertiseAsync.rejected, (state, action) => {
      state.wpLoading = false;
    });
    builder.addCase(getAdvertiseAsync.fulfilled, (state, action) => {
      state.wpLoading = false;
    });
  },
});

export const {
  appLoading,
  appLoadingBar,
  showSnack,
  runTour,
  clearTour,
  setDefaultLang,
} = appSlice.actions;

export const info = (message, options) => async (dispatch) => {
  dispatch(
    showSnack({
      message,
      options: Object.assign({}, { variant: "info" }, options),
    })
  );
};

export const appUploadFile = (data) => async (dispatch) => {
  return dispatch(uploadFileAsync(data));
};

export const getFile = (data) => async (dispatch) => {
  // return dispatch(getFileAsync(data));
  return dispatch(getFileQueryStringAsync(data));
};

export const getFileQueryString = (data) => async (dispatch) => {
  return dispatch(getFileQueryStringAsync(data));
};

export const getProvinces = () => async (dispatch) => {
  return dispatch(locationGetProvinces());
};

export const getCities = (data) => async (dispatch) => {
  return dispatch(locationGetCities(data));
};

export const getTour = (data) => async (dispatch) => {
  return dispatch(getTourAsync(data));
};

export const getAdvertise = (data) => async (dispatch) => {
  return dispatch(getAdvertiseAsync(data));
};

export default appSlice.reducer;
