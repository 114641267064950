import { Button, Form, Input, Modal, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { setPublisherInvoicesStatus } from "@/app/local/invoiceSlice";
import { InvoiceStatus } from "@/utils/constants/apiConstants";
import { objectR } from "@/utils/helper";

const FormPayoutStatusPublisher = (props) => {
  const { data } = props;
  const [form] = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { payout } = data;
  const { confirm } = Modal;

  const calculateTitle = (status) => {
    let paymentStatus_;
    if (status === InvoiceStatus.Initial || status === InvoiceStatus.Pending) {
      paymentStatus_ = t("invoice.pay_status_.WaitingToProcess");
    } else if (status === InvoiceStatus.Confirmed) {
      paymentStatus_ = t("invoice.pay_status_.ReadyForPayment");
    } else if (status === InvoiceStatus.Rejected) {
      paymentStatus_ = t("invoice.pay_status_.TreasuryRejected");
    }
    return t("general.confirmSingleInvoice")
      .replace("status", t(`invoice.status_.${objectR(InvoiceStatus)[status]}`))
      .replace("payment", paymentStatus_);
  };

  const onFinish = async (e) => {
    const d = await confirm({
      title: parse(calculateTitle(e.status)),
      async onOk() {
        const res = await dispatch(
          setPublisherInvoicesStatus({
            invoice_id: payout?.publisher_invoice_id,
            status: e.status,
            description: e.description,
          })
        );
        if (res.payload?.success) {
          message.success({
            content: t("general.saveSuccessFully"),
          });
          d.destroy();
          props.close();
          props.onFinishSearch();
        } else {
          message.error({
            content: res.payload?.Message || t("general.unHandleErrorMessage"),
          });
        }
      },
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      status: payout.status,
      description: payout.description,
    });
  }, [payout]);

  return (
    <Form
      form={form}
      initialValues={{
        payoutId: payout?.publisher_invoice_id,
      }}
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item name="payoutId" label={t("payoutPublisher.invoiceId")}>
        <Input disabled />
      </Form.Item>

      <Form.Item
        name={"status"}
        label={t("invoice.status")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select size="large" placeholder={t("general.select")}>
          {Object.keys(InvoiceStatus).map((key) => {
            return (
              <Select.Option key={key} value={InvoiceStatus[key]}>
                {t(`invoice.status_.${key}`)}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(pre) => {
          const status_ = pre.getFieldValue("status");
          if (
            status_ === InvoiceStatus.Rejected ||
            status_ === InvoiceStatus.Pending
          )
            return (
              <Form.Item name={"description"} label={t("invoice.description")}>
                <TextArea rows={2} />
              </Form.Item>
            );
        }}
      </Form.Item>

      <Form.Item
        wrapperCol={{
          span: 16,
        }}
        className="form_actions"
      >
        <Button loading={false} size="large" type="primary" htmlType="submit">
          {t("general.save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

FormPayoutStatusPublisher.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func,
  onFinishSearch: PropTypes.func,
};

export default FormPayoutStatusPublisher;
