import { Pie } from "@ant-design/plots";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const DonutChart = (props) => {
  const data = [
    {
      type: `dashboard.${props.type || "view"}`,
      value: props.value,
    },
    {
      type: "dashboard.fraud",
      value: 0,
    },
  ];
  const { t } = useTranslation("ssp");

  const config = useMemo(() => {
    return {
      height: 75,
      width: 80,
      appendPadding: 0,
      legend: false,
      data,
      angleField: "value",
      colorField: "type",
      radius: 1,
      tooltip: {
        formatter: (datum) => {
          return { name: t(datum.type), value: datum.value + "%" };
        },
      },
      innerRadius: 0.55,
      theme: {
        styleSheet: {
          fontFamily: "IRANYekan",
        },
      },
      yAxis: {
        // label: {
        //   formatter: (v) => kFormatter(v, 1),
        // },
      },
      label: {
        autoRotate: false,
        type: "inner",
        offset: "-50%",
        style: {
          textAlign: "center",
          fontSize: 0,
        },
      },
      pieStyle: ({ type }) => {
        if (type === "dashboard.view") {
          return {
            fill: "#ff7b95",
          };
        } else if (type === "dashboard.imp") {
          return {
            fill: "#df5fff",
          };
        } else if (type === "dashboard.click") {
          return {
            fill: "#468bff",
          };
        } else if (type === "dashboard.ctr") {
          return {
            fill: "#ff7438",
          };
        } else if (type === "dashboard.fill_rate") {
          return {
            fill: "#FFA800",
          };
        } else if (type === "dashboard.win_rate") {
          return {
            fill: "#21B2C6",
          };
        } else {
          return {
            fill: "#F44336",
          };
        }
      },
      statistic: {
        title: false,
        content: {
          style: {
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          content: "",
        },
      },
    };
  }, [data]);

  return (
    <>
      <Pie {...config} loading={false} />
    </>
  );
};

DonutChart.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
};

export default DonutChart;
