import { Col } from "antd";
import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";

import { useAccount } from "@/utils/hooks/useAuth";
import { useWidgetType } from "@/utils/hooks/useWidgetType";

import PageTitle from "../general/PageTitle";

const WidgetBuilderHeader = () => {
  const { isCustom, isNew } = useWidgetType();
  const { isMerchant } = useAccount();

  const { widgetSmartDetail, widgetDetail } = useSelector(
    (state) => state.productWidget
  );

  const wd = widgetSmartDetail?.data || widgetDetail?.data;

  const widgetTitle = wd?.product_widget_config?.widget_title || wd?.name;

  return (
    <Col xs={24}>
      {isNew ? (
        <>
          {isCustom || isMerchant ? (
            <PageTitle
              title={"AddNewWidget"}
              helpText={t("widget_builder.addNewWidget")}
            />
          ) : (
            <PageTitle
              title={"SelectNewWidget"}
              helpText={t("widget_builder.selectNewWidget")}
            />
          )}
        </>
      ) : (
        <PageTitle
          title={`${widgetTitle}`}
          helpText={t("widget_builder.editWidget")}
          t={false}
        />
      )}
    </Col>
  );
};

export default WidgetBuilderHeader;
