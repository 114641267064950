import { Button, Checkbox } from "antd";
import { Card } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import defaultImage from "@/assets/images/default.png";
import { calcPercentDiscount, priceFormat } from "@/utils/helper";

import "./ProductItem.less";

const ProductItem = (props) => {
  const { product, handleSelectProduct, selectedProducts } = props;
  const { websitesPublisher } = useSelector((state) => state.website);
  const webStoreName = useMemo(() => {
    return websitesPublisher?.data?.find(
      (item) => item.web_store_id === product.web_store_id
    );
  }, [product, websitesPublisher]);

  return (
    <div className="product_item">
      <Card
        className="product_card"
        cover={
          <div>
            {handleSelectProduct ? (
              <div className="product__checkbox">
                <Checkbox
                  checked={selectedProducts?.find(
                    (p) => p.product_id === product.product_id
                  )}
                  onChange={(e) => handleSelectProduct(e, product)}
                />
              </div>
            ) : (
              ""
            )}
            <div className="product__thump">
              <img src={product?.profile_image_path || defaultImage} />
            </div>
          </div>
        }
      >
        <div className="product__box">
          <a href={product.main_url} target="_blank" rel="noreferrer">
            <div className="product__title">
              <Paragraph
                ellipsis={{ expandable: false, tooltip: true, rows: 2 }}
              >
                {product.name}
              </Paragraph>
            </div>
          </a>
          {!props.hidePrice ? (
            <div className="product__price">
              <div className="product__price__current">
                {product?.is_available ? (
                  priceFormat(product.price, "display_amount_with_discount")
                ) : (
                  <b className="unavailable">{t("general.unavailable")}</b>
                )}
              </div>
              {product.price.discount ? (
                <div className="product__price__old">
                  <b>{calcPercentDiscount(product.price)}</b>
                  <span>{priceFormat(product.price)}</span>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            false
          )}
          {!props.hideButton ? (
            <div className="product__button">
              <Button
                size="middle"
                type="primary"
                ghost
                href={product?.main_url}
                target="_blank"
              >
                {webStoreName?.name
                  ? `${t("product.buyFrom")} ${webStoreName.name}`
                  : t("product.show_product")}
              </Button>
            </div>
          ) : (
            false
          )}
        </div>
      </Card>
    </div>
  );
};

ProductItem.propTypes = {
  product: PropTypes.object,
  selectedProducts: PropTypes.array,
  handleSelectProduct: PropTypes.func,
  main_url: PropTypes.string,
  hideButton: PropTypes.bool,
  hidePrice: PropTypes.bool,
};
export default ProductItem;
