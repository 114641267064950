export const DISPLAY_JALALI_DATE_TIME_FORMAT = "jYYYY/jMM/jDD HH:mm";
export const DISPLAY_JALALI_TIME_DATE_FORMAT = "HH:mm jYYYY/jMM/jDD";

export const statuses = [
  { label: "pending", bgColor: "rgba(255, 152, 0, 0.1)", fontColor: "#F57C00" }, // open
  {
    label: "pending",
    bgColor: "rgba(255, 152, 0, 0.1)",
    fontColor: "#F57C00",
    hide: true,
  },
  {
    label: "answered",
    bgColor: "rgba(76, 175, 80, 0.1)",
    fontColor: "#388E3C",
  }, //employee
  {
    label: "pending",
    bgColor: "rgba(255, 152, 0, 0.1)",
    fontColor: "#F57C00",
    hide: true,
  }, //customer
  { label: "closed", bgColor: "rgba(33, 150, 243, 0.1)", fontColor: "#2196F3" },
];
