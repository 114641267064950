import { Steps } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { PlaceContext } from "../AdPlaceBuilder";
import "./PlaceStepper.less";

export const STEPS = [
  {
    key: "selectTypePlace",
  },
  {
    key: "selectFormatPlace",
  },
  {
    key: "configPlace",
  },
  {
    key: "generateCode",
  },
];

const PlaceStepper = () => {
  const [context, dispatchContext] = useContext(PlaceContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeSteps, setActiveSteps] = useState([0]);
  const [t] = useTranslation("ssp");

  const onChange = (v) => {
    const x = STEPS.find((k, j) => j === v);
    dispatchContext({ type: "currentStep", value: x.key });
    setSearchParams({ step: v + 1 });
  };

  useEffect(() => {
    if (context.config) {
      setActiveSteps([0, 1, 2]);
    } else if (context.format) {
      setActiveSteps([0, 1]);
    } else if (context.currentStep) {
      setActiveSteps([0]);
    }
  }, [context]);

  return (
    <div className="PlaceStepper">
      <Steps
        type="navigation"
        current={STEPS.findIndex((x) => x.key === context.currentStep)}
        onChange={onChange}
        className="place-stepper"
        status="process"
      >
        {STEPS.map((step, i) => (
          <Steps.Step
            key={step.key}
            disabled={activeSteps.length <= i}
            active={step.key === context.currentStep}
            className={context.currentStep === step.key ? "step__active" : ""}
            title={t(`place.steps.${step.key}`)}
          />
        ))}
      </Steps>
    </div>
  );
};

export default PlaceStepper;
