import PropTypes from "prop-types";

const Holder = (props) => {
  return props.if ? props.children : null;
};

Holder.propTypes = {
  if: PropTypes.bool,
  children: PropTypes.element,
};

export default Holder;
