import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";

import AdPlaceLiveViewer from "../AdPlaceLiveViewer";
import CardSkeleton from "./CardSkeleton";
import FormMaster from "./FormMaster";
import SkeltonGenerator from "./SkeltonGenerator";
import "./SliderConfig.less";

const SliderConfig = (props) => {
  const { doFunction, form } = props;

  const onFinish = (event) => {
    doFunction(event);
  };

  return (
    <Row gutter={[24, 24]} className="main-config slider-config">
      <Col xs={10}>
        <div className="slider-config__main">
          <FormMaster
            config={{
              max_item_count: 6,
              max_mobile_item_count: 4,
              responsive_type: true,
            }}
            form={form}
            onFinish={onFinish}
          />
        </div>
      </Col>
      <Col xs={14}>
        <AdPlaceLiveViewer>
          <div className="box-preview">
            <div className="box-preview__content">
              <h2 className="skeleton"></h2>
              <SkeltonGenerator count={5} />
            </div>
            <div className="box-preview__widget">
              <div className="slider">
                {Array(4)
                  .fill(1)
                  .map((i) => (
                    <CardSkeleton key={"i" + i} />
                  ))}
              </div>
            </div>
            <div className="box-preview__content">
              <SkeltonGenerator count={7} />
            </div>
          </div>
        </AdPlaceLiveViewer>
      </Col>
    </Row>
  );
};

SliderConfig.propTypes = {
  doFunction: PropTypes.any,
  form: PropTypes.any,
};
export default SliderConfig;
