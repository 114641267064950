import { Col, Form, Input, Row, Select, message } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { clarity } from "react-microsoft-clarity";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import { appLoadingBar, getCities, getProvinces } from "@/app/local/appSlice";
import { merchantSetContactInfo } from "@/app/local/merchantSlice";
import { publisherSetContactInfo } from "@/app/local/publisherSlice";
import { getProfileData } from "@/pages/profile";
import { USER_TYPE } from "@/utils/constants/apiConstants";
import {
  CITY_PHONE_NUMBER_REGEX_CODE,
  CITY_PHONE_NUMBER_REGEX_NUMBER,
  MASK_MOBILE_INPUT,
} from "@/utils/constants/configConstants";
import { findInfoError, getLastError } from "@/utils/helper";

import inTrackEvent from "../extra/Intrack/InTrackEvent";
import MaskInput from "../input/maskInput";
import FooterWizard from "./FooterWizard";

const StepContactInfo = (props) => {
  const isProfile = useMatch("/profile");

  // const isUser = useMatch("/publishers/:id");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  // const { profile } = useSelector((state) => state.account);
  const { provinces, cities, provincesLoading } = useSelector(
    (state) => state.app
  );
  const provincesOptions = provinces?.data;
  const cityOptions = cities?.data;

  const profile = getProfileData();
  const userType = _.get(profile, "data.user_type");
  const userInfo = _.get(profile, "userDetail");
  const [respError, setRespError] = useState(null);

  const { stepContact, loading, errorContact } = useSelector((state) =>
    userType === USER_TYPE.PUBLISHER ? state.publisher : state.merchant
  );

  const uId = profile?.data?.user_id;
  const mobile = profile?.data?.mobile ? "0" + profile?.data?.mobile : null;

  useEffect(() => {
    if (!_.isEmpty(userInfo) && userInfo.email) {
      let postCadePattern;
      if (userInfo?.postal_code) {
        postCadePattern =
          String(userInfo.postal_code).slice(0, 5) +
          " " +
          String(userInfo.postal_code).slice(5, 10);
      }

      let prePhone, phone;
      if (userInfo.phone_number) {
        prePhone = userInfo.phone_number
          ? "0" + String(userInfo.phone_number).slice(0, 2)
          : "";
        phone = String(userInfo.phone_number).slice(2) || "";
      }

      onValuesChange({ pvId: userInfo.province_id });

      form.setFieldsValue({
        user: {
          phoneNumber: phone,
          phoneNumberPrefix: prePhone,
          email: userInfo.email,
          postalCode: postCadePattern,
          address: userInfo.address,
          plaque: userInfo.plaque,
        },
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo && provinces?.data) {
      form.setFieldsValue({
        user: {
          province: userInfo.province_id && String(userInfo.province_id),
        },
      });
    }
  }, [userInfo, provinces]);

  useEffect(() => {
    dispatch(appLoadingBar(loading));
  }, [loading]);

  const successFullyDone = () => {
    message.success({
      content: t("general.saveSuccessFully"),
      key: "publisher",
    });
    if (_.isFunction(props?.goNextStep)) {
      props.goNextStep(2);
    }
  };

  useEffect(() => {
    if (!provincesOptions?.length) {
      dispatch(getProvinces());
    }
  }, [provincesOptions]);

  const validateMessages = {
    required: t("required"),
    types: {
      email: "${label} " + `${t("general.emailNotValid")}`,
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onFinish = async (values) => {
    const {
      mobile,
      phoneNumberPrefix,
      phoneNumber,
      email,
      province,
      city,
      postalCode,
      address,
      plaque,
    } = values.user;
    const ctName = cityOptions.find(
      (item) => item.location_id + "" === city
    )?.name;
    const pvName = provincesOptions.find(
      (item) => item.location_id + "" === province
    )?.name;
    const eventParam = {
      userId: uId,
      email,
      city: ctName,
      state: pvName,
      attributes: {
        kycStatus: 4,
        telephone_number_prefix: phoneNumberPrefix,
        telephone_number: phoneNumber,
        postalCode,
        address,
        plaque,
      },
    };
    inTrackEvent.updateProfile(eventParam);
    clarity.setTag("register", "step-2");

    const postalCode_ = postalCode.replaceAll(" ", "").trim();
    const mobile_ = mobile.replaceAll(" ", "").trim();

    const serverData = {
      user_id: uId,
      province_id: Number(province),
      city_id: Number(city),
      mobile: mobile_,
      email: email,
      phone_number: phoneNumberPrefix + phoneNumber,
      postal_code: postalCode_,
      address: address,
      plaque: plaque,
    };

    dispatch(appLoadingBar(true));

    let resp;
    if (userType === USER_TYPE.PUBLISHER || userType === USER_TYPE.ADVERTISER) {
      resp = await dispatch(publisherSetContactInfo(serverData));
    } else if (userType === USER_TYPE.MERCHANT) {
      resp = await dispatch(merchantSetContactInfo(serverData));
    }
    if (resp?.payload?.success) {
      // InTrack Event
      if (userType === USER_TYPE.PUBLISHER) {
        const pvName = provincesOptions.find(
          (item) => item.location_id + "" === province
        )?.name;
        const ctName = cityOptions.find(
          (item) => item.location_id + "" === city
        )?.name;

        inTrackEvent.contactInfoSubmitEvent({
          userId: uId,
          mobileNumber: mobile_,
          telephoneNumber: phoneNumberPrefix + phoneNumber,
          email: email,
          province: pvName,
          city: ctName,
          postalCode: postalCode_,
          address: address,
          pelak: plaque,
        });
      }

      successFullyDone();
    } else {
      const keyName = findInfoError(resp?.payload?.data);
      const errorMessage =
        resp?.payload?.Message || getLastError(resp?.payload);
      message.error({
        content: errorMessage,
        key: "publisher",
      });
      setRespError(keyName);
    }
  };

  const onChange = (e) => {
    // const reg = new RegExp("^d+$");
    const reg = /(\d)+/g;
    // form.setFieldsValue({ "user.phoneNumber": "222" });
  };

  const prefixSelector = (
    <Form.Item name={["user", "phoneNumberPrefix"]} noStyle>
      <Input />
    </Form.Item>
  );

  const onValuesChange = (e) => {
    if (e.user?.province || e.pvId) {
      form.setFieldsValue({
        user: {
          city: null,
        },
      });
      const result = dispatch(
        getCities({
          id: e.user?.province || e.pvId,
        })
      );
      if (e.pvId) {
        result.then(() => {
          form.setFieldsValue({
            user: {
              city: userInfo.city_id && String(userInfo.city_id),
            },
          });
        });
      }
    }
  };

  let phone = form.getFieldValue(["user", "phoneNumber"]);

  return (
    <>
      <Form
        form={form}
        name="formStepOne"
        onFinish={onFinish}
        validateMessages={validateMessages}
        // className="afl-form"
        layout="vertical"
        // labelCol={{
        //   span: 24,
        // }}
        // wrapperCol={{
        //   md: 24,
        // }}
        onValuesChange={onValuesChange}
        initialValues={{
          user: {
            mobile: mobile,
          },
        }}
      >
        <Row>
          <Col md={props.md}>
            <Row gutter={24}>
              <Col md={8} xs={24}>
                <Form.Item
                  name={["user", "mobile"]}
                  label={t("user.mobile")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  validateStatus="success"
                  hasFeedback
                >
                  <MaskInput
                    disabled
                    readOnly
                    mask={MASK_MOBILE_INPUT}
                    placeholder={t("auth.phone")}
                    // size="large"
                    style={{ textAlign: "right", direction: "ltr" }}
                    placeholderChar="-"
                  />
                </Form.Item>
              </Col>

              <Col md={8} xs={24}>
                <Form.Item label={t("user.phoneNumber")}>
                  <Input.Group size="default">
                    <Row gutter={6} style={{ direction: "ltr" }}>
                      <Col span={6}>
                        <Form.Item
                          noStyle
                          name={["user", "phoneNumberPrefix"]}
                          rules={[
                            {
                              pattern: new RegExp(CITY_PHONE_NUMBER_REGEX_CODE),
                              message: "",
                              required: phone,
                            },
                          ]}
                        >
                          <Input
                            style={{ direction: "ltr" }}
                            placeholder={t("users.phoneNumberPrefix")}
                            min={0}
                            max={100}
                            maxLength="3"
                            onChange={onChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={18}>
                        <Form.Item
                          noStyle
                          name={["user", "phoneNumber"]}
                          rules={[
                            {
                              pattern: new RegExp(
                                CITY_PHONE_NUMBER_REGEX_NUMBER
                              ),
                              message: t("user.phoneNumberNotValid"),
                            },
                          ]}
                          hasFeedback={
                            respError?.includes("phone_number") ? true : false
                          }
                          validateStatus={
                            respError?.includes("phone_number") && "error"
                          }
                        >
                          <Input style={{ direction: "ltr" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Input.Group>
                  {/* )} */}
                </Form.Item>
              </Col>

              <Col md={8} xs={24}>
                <Form.Item
                  name={["user", "email"]}
                  label={t("user.email")}
                  rules={[
                    {
                      required: true,
                      type: "email",
                    },
                  ]}
                  hasFeedback={respError?.includes("email") ? true : false}
                  validateStatus={respError?.includes("email") && "error"}
                >
                  <Input placeholder="example@mail.com" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col md={8} xs={12}>
                <Form.Item
                  name={["user", "province"]}
                  label={t("user.province")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={t("general.select")}
                    loading={provincesLoading}
                  >
                    {provincesOptions?.map((item) => (
                      <Select.Option
                        key={item.location_id}
                        // disabled={item.is_enabled}
                      >
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={8} xs={12}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const getPv = getFieldValue("user")?.province;
                    return (
                      <Form.Item
                        name={["user", "city"]}
                        label={t("user.city")}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder={t("general.select")}
                          showSearch
                          disabled={!getPv}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {cityOptions?.map((item) => (
                            <Select.Option
                              key={item.location_id}
                              // disabled={item.is_enabled}
                            >
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  name={["user", "postalCode"]}
                  label={t("user.postalCode")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  hasFeedback={
                    respError?.includes("postal_code") ? true : false
                  }
                  validateStatus={respError?.includes("postal_code") && "error"}
                >
                  <MaskInput
                    mask="00000 00000"
                    style={{ textAlign: "right", direction: "ltr" }}
                    placeholderChar="-"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col md={16} xs={24}>
                <Form.Item
                  name={["user", "address"]}
                  label={t("user.address")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder={t("user.addressPlaceHolder")} />
                </Form.Item>
              </Col>

              <Col md={8} xs={24}>
                <Form.Item
                  name={["user", "plaque"]}
                  label={t("user.plaque")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
            </Row>
            <br />
            <FooterWizard
              loading={loading}
              current={1}
              goNextStep={props.goNextStep}
            />
          </Col>
          <Col></Col>
        </Row>
      </Form>
    </>
  );
};

StepContactInfo.propTypes = {
  goNextStep: PropTypes.func,
  md: PropTypes.number,
};
StepContactInfo.defaultProps = {
  md: 18,
};

export default StepContactInfo;
