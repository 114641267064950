import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// import { baseAPI } from "@/app/services/baseApi";
import { ENDPOINTS } from "@/utils/constants/apiConstants";

import { baseAPI } from "../services/fetchers";

export const controlPanelGetBodyAsync = createAsyncThunk(
  "control_panel/controlPanelGetBodyAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.CONTROL_PANEL_GET_BODY_CALLBACK_META_DATA;
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

const slice = createSlice({
  name: "controlPanel",
  initialState: {
    reportPublisher: null,
    reportPublisherLoading: false,
    reportPublisherError: null,
  },
  reducers: {
    clearState: (state) => {
      state.reportPublisher = false;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(controlPanelGetBodyAsync.pending, (state) => {
      state.reportPublisherLoading = true;
      state.reportPublisherError = null;
    });
    builder.addCase(controlPanelGetBodyAsync.fulfilled, (state, action) => {
      state.reportPublisherLoading = false;
      if (action.payload.success) {
        state.reportPublisher = action.payload;
      }
    });
    builder.addCase(controlPanelGetBodyAsync.rejected, (state, action) => {
      state.reportPublisherLoading = false;
      state.reportPublisherError = null;
    });
  },
});

export const { clearState } = slice.actions;

export default slice.reducer;

export const controlPanelGetBody = (data) => async (dispatch) => {
  return dispatch(controlPanelGetBodyAsync(data));
};
