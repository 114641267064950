import { Spin } from "antd";
import PropTypes from "prop-types";

// import styles from "./Loading.module.css";

const AppLoading = ({ open, height }) => {
  return (
    <div className="app__loading" style={{ height: height }}>
      <Spin size="large" />
    </div>
  );
};

AppLoading.propTypes = {
  open: PropTypes.bool,
  height: PropTypes.string,
};

export default AppLoading;
