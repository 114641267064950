// import { updateUser } from "app/local/user";
// import { PRIVATE_BASE_PATH } from "app/routes";
// import { useLoginMutation } from "app/services/mock";
import { Button, ConfigProvider, Form, message } from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  accountForgot,
  accountSendCode,
  clearState,
} from "@/app/local/accountSlice";
import ForgetImage from "@/assets/images/forget.svg";
import LogoImage from "@/assets/images/logo-white.svg";
import InputPassword from "@/components/input/inputPassword";
import InputPasswordConfirm from "@/components/input/inputPasswordConfirm";
import MaskInput from "@/components/input/maskInput";
import { SEND_CODE_CONSUMER } from "@/utils/constants/apiConstants";
import { MASK_MOBILE_INPUT } from "@/utils/constants/configConstants";
import { getLastError } from "@/utils/helper";
import useConfig from "@/utils/hooks/useConfig";

import AuthLayout from "./authLayout";
import Register from "./register";

const Mode = {
  FORGOT: "FORGOT",
  VERIFY: "VERIFY",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
};

export const ForgotPassword = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { isSSP } = useConfig();

  const [mode, setMode] = useState(Mode.FORGOT);
  const [code, setCode] = useState();

  const { verify, error, loading } = useSelector((state) => state.account);

  useEffect(() => {
    if (error) {
      const errorMessage = error?.Message || getLastError(error);
      message.error({
        content: errorMessage,
        key: "forgot",
      });
    }
  }, [error]);

  const onFinish = async (e) => {
    try {
      const phone = e.username.replaceAll(" ", "").trim();

      const sendCode = await dispatch(
        accountSendCode({
          username: phone,
          consumer: SEND_CODE_CONSUMER.FORGOT_PASSWORD,
        })
      );
      if (sendCode.payload?.success) {
        setMode(Mode.VERIFY);
      }
    } catch (err) {
      const catching = "catch";
    }
  };

  const navigateTo = (name) => {
    dispatch(clearState());
    navigate(name);
  };

  return (
    <>
      {mode === Mode.FORGOT ? (
        <AuthLayout>
          <>
            <div className="auth__image">
              <div className="image__bg">
                <img src={ForgetImage} />
              </div>
              <div className="image__info">
                <div className="logo">
                  {isSSP ? (
                    <h2 style={{ fontWeight: "bold", color: "#fff" }}>
                      SSP Platform
                    </h2>
                  ) : (
                    <img src={LogoImage} />
                  )}
                </div>
                <p>{t("auth.registerText")}</p>
              </div>
            </div>
            <Form
              form={form}
              className="auth__form auth__form--forgot"
              name="basic"
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Title level={4} className={"auth__title"}>
                {t("auth.forgot")}
              </Title>
              <ConfigProvider direction="ltr">
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                  style={{ direction: "ltr" }}
                >
                  <MaskInput
                    mask={MASK_MOBILE_INPUT}
                    placeholder={t("auth.phone")}
                    size="large"
                    style={{ textAlign: "right" }}
                    placeholderChar="-"
                  />
                </Form.Item>
              </ConfigProvider>

              <Form.Item
                wrapperCol={{
                  // offset: 8,
                  span: 16,
                }}
                className="auth__actions"
              >
                <Button
                  loading={loading}
                  size="large"
                  type="primary"
                  htmlType="submit"
                >
                  {t("auth.accept")}
                </Button>
                <span className="space-or"></span>
                <Button
                  size="large"
                  type="default"
                  onClick={() => {
                    navigateTo("/login");
                  }}
                >
                  {t("general.back")}
                </Button>
              </Form.Item>
            </Form>
          </>
        </AuthLayout>
      ) : (
        ""
      )}
      {mode === Mode.VERIFY ? (
        <Register
          phone={form.getFieldValue("username")}
          mode={Mode.VERIFY}
          from={Mode.FORGOT}
          setMode={setMode}
          setCode={setCode}
        />
      ) : (
        ""
      )}
      {mode === Mode.CHANGE_PASSWORD ? (
        <ChangePassword phone={form.getFieldValue("username")} code={code} />
      ) : (
        ""
      )}
    </>
  );
};

const ChangePassword = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { verify, error, loading } = useSelector((state) => state.account);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = async (e) => {
    try {
      const passNew = e.password.trim();
      const passNewCon = e.password.trim();
      const phone_ = props.phone.replaceAll(" ", "").trim();
      const code_ = props.code;

      const updatePass = await dispatch(
        accountForgot({
          token: code_,
          mobile: phone_,
          new_password: passNew,
          confirm_new_password: passNewCon,
        })
      );
      if (updatePass.payload?.success) {
        // setMode(Mode.VERIFY);
        message.success({
          content: t("auth.passUpdatedSuccessFully"),
          key: "login",
          duration: 1,
        });
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }
    } catch (err) {
      const catching = "catch";
    }
  };

  const navigateTo = (name) => {
    dispatch(clearState());
    navigate(name);
  };

  return (
    <AuthLayout>
      <>
        <div className="auth__image">
          <div className="image__bg">
            <img src={ForgetImage} />
          </div>
          <div className="image__info">
            <img src={LogoImage} className="logo" />
            <p>{t("auth.registerText")}</p>
          </div>
        </div>
        <Form
          form={form}
          className="auth__form auth__form--forgot"
          name="basic"
          wrapperCol={{
            span: 24,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Title level={4} className={"auth__title"}>
            {t("auth.changePassword")}
          </Title>
          <Text>{t("auth.enterNewPassword")}</Text>
          <br />
          <br />
          <ConfigProvider direction="ltr">
            <InputPassword placeholder="auth.passwordNew" />

            <InputPasswordConfirm placeholder="auth.passwordConfirmNew" />
          </ConfigProvider>

          <Form.Item
            wrapperCol={{
              // offset: 8,
              span: 16,
            }}
            className="auth__actions"
          >
            <Button
              loading={loading}
              size="large"
              type="primary"
              htmlType="submit"
            >
              {t("auth.accept")}
            </Button>
            <span className="space-or"></span>
            <Button
              size="large"
              type="default"
              onClick={() => {
                navigateTo("/login");
              }}
            >
              {t("general.back")}
            </Button>
          </Form.Item>
        </Form>
      </>
    </AuthLayout>
  );
};

ChangePassword.propTypes = {
  code: PropTypes.string,
  phone: PropTypes.string,
};

export default ForgotPassword;
