import { Menu, withStyles } from "@material-ui/core";

const CustomMenu = withStyles({
  paper: {
    "& .MuiList-padding": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    disableScrollLock
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export default CustomMenu;
