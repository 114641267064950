import PropTypes from "prop-types";
import React from "react";

import "./styles.less";

const NameTag = (props) => {
  const { title, color, align } = props;

  return (
    <div>
      <p className={`tag bg ${color || ""}`}>{title}</p>
    </div>
  );
};

NameTag.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.align,
};

export default NameTag;
