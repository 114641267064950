// import { updateUser } from "app/local/user";
// import { PRIVATE_BASE_PATH } from "app/routes";
// import { useLoginMutation } from "app/services/mock";
import { Card, Col, Row } from "antd";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";

import MainTicketChat from "@/components/extra/MainTicketChat";
// import { LANGUAGE } from "@/utils/constants/apiConstants";
import { titleHandler } from "@/utils/helper";

import AuthFooter from "./authFooter";
import "./styles.less";

export const AuthLayout = (props) => {
  const ref = useRef(null);
  const { loading } = useSelector((state) => state.account);

  const location = useLocation();
  const isRegister = location.pathname === "/register";

  useEffect(() => {
    if (loading) ref.current.continuousStart();
    else ref.current.complete();
  }, [loading]);

  useEffect(() => {
    titleHandler();
  }, []);

  return (
    <div className="auth-wrap">
      <LoadingBar color="#fa8c15" ref={ref} />
      <Row justify="space-around" align="middle" className="auth__row">
        <Col md={16} xs={23}>
          <Card
            className={`auth__card ${isRegister ? "auth__card__register" : ""}`}
          >
            <div className="d-flex">{props.children}</div>
            <AuthFooter />
          </Card>
        </Col>
      </Row>
      <MainTicketChat />
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.element,
};

export default AuthLayout;
