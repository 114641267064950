import confirm from "antd/lib/modal/confirm";
import parse from "html-react-parser";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { MANUAL_CONFIG } from "@/utils/constants/configConstants";

import packageJson from "../../package.json";
import { UpgradeSvg } from "./../assets/pageIcons";
import { getAdvertise } from "./local/appSlice";

const openDialog = (data) => {
  const body = data ? data?.payload[0].content.rendered : "";

  setTimeout(() => {
    confirm({
      title: (
        <div className="upgradeTitle">
          <span className="upgrade">
            <img src={UpgradeSvg} />
          </span>
          {t("general.new_release_message_title")}
        </div>
      ),
      // icon: <ReloadOutlined />,
      content: (
        <div>
          {t("general.new_release_message")}
          <div className="change-logs">
            <div className="change-logs__head">
              <b>{t("general.changes_title")}</b>
            </div>
            <div className="change-logs__body">{parse(body)}</div>
          </div>
        </div>
      ),
      onOk() {
        clearFunction();
      },
      okText: t("general.ok2"),
      cancelButtonProps: {
        hidden: true,
      },
      className: "upgradeAlert",
    });
  }, 1000);
};

export const useHookCheckVersion = async () => {
  let version = localStorage.getItem("version");
  const dispatch = useDispatch();
  const [state, setState] = useState();

  useEffect(async () => {
    let response;
    if (!version) {
      localStorage.setItem("version", packageJson.version);
    } else if (version != packageJson.version) {
      if (MANUAL_CONFIG.IS_MAJOR_VERSION) {
        if (!state) {
          setState(true);
          response = await dispatch(
            getAdvertise({
              url: "pages",
              queryParams: "slug=affilio-panel-change-log",
            })
          );
          if (response) {
            openDialog(response);
          }
        }
      } else {
        clearFunction();
      }
    }
  });

  return {
    version,
  };
};

const clearFunction = () => {
  if ("caches" in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });

    // Makes sure the page reloads. Changes are only visible after you refresh.
    window.location.reload(true);
  }

  localStorage.clear();
  localStorage.setItem("version", packageJson.version);
};
