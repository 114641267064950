import { Button, Form, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  accountDetailSpecific,
  clearState,
  configAdd,
  resetPassword,
} from "@/app/local/accountSlice";
import { decryptPass } from "@/utils/helper";

import InputPassword from "../input/inputPassword";
import InputPasswordConfirm from "../input/inputPasswordConfirm";

const ResetPasswordComponent = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, dataAccountSpecific } = useSelector((state) => state.account);

  const navigateTo = (name) => {
    navigate(name);
  };

  useEffect(() => {
    if (!data) {
      dispatch(accountDetailSpecific());
    }
  }, [data]);

  const onFinish = async (e) => {
    setLoading(true);
    let mobile_;
    if (data?.data?.mobile) {
      mobile_ = decryptPass(data?.data?.mobile);
    }
    const mobile = mobile_ || dataAccountSpecific?.data?.mobile;
    const token = sessionStorage.getItem("TOKEN");
    const res = await dispatch(
      resetPassword({
        token: token,
        new_password: e.password,
        confirm_new_password: e.passwordConfirm,
        mobile: mobile,
      })
    );
    sessionStorage.removeItem("TOKEN");
    if (res.payload?.success) {
      message.success({
        content: t("general.saveSuccessFully"),
        key: "user",
      });
      await dispatch(configAdd({ type: "FirstLogin", value: "done" }));
      await dispatch(clearState());
      navigateTo("/");
    } else {
      message.error({
        content: res.payload?.Message || t("general.unHandleErrorMessage"),
        key: "user",
      });
    }
    setLoading(false);
  };

  return (
    <Form
      form={form}
      className="auth__form auth__form--login"
      name="basic"
      wrapperCol={{
        span: 24,
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Title level={4} className={"auth__title"}>
        {t("auth.resetPassword")}
      </Title>

      <InputPassword placeholder="auth.passwordNew" size="medium" />
      <InputPasswordConfirm
        placeholder="auth.passwordConfirmNew"
        size="medium"
      />
      <Form.Item
        wrapperCol={{
          // offset: 8,
          span: 16,
        }}
        className="form_actions"
      >
        <Button loading={loading} size="large" type="primary" htmlType="submit">
          {t("general.save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ResetPasswordComponent;
