import { Form, Select } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getWebsiteActiveList,
  getWebsiteMerchantList,
  getWebsitePublisherList,
} from "@/app/local/websiteSlice";
import { USER_TYPE } from "@/utils/constants/apiConstants";
import { useAccount } from "@/utils/hooks/useAuth";

const SelectWebsite = (props) => {
  // loadAllActive: sometimes publishers must see all active list (FormAddMedia)
  const {
    name,
    label,
    rules,
    placeholder,
    size,
    mode,
    loadAllActive,
    disabled,
    onChange,
  } = props;
  const dispatch = useDispatch();
  const { roleId, user, isAdmin } = useAccount();
  const {
    websitesActive,
    websitesActiveLoading,
    websitesActiveError,
    websitesPublisher,
    websitesMerchant,
  } = useSelector((state) => state.website);

  useEffect(async () => {
    if (roleId) {
      if (loadAllActive) {
        dispatch(getWebsiteActiveList());
      } else if (roleId === USER_TYPE.PUBLISHER) {
        const publisherId = 0; //user.user_id;
        dispatch(getWebsitePublisherList(publisherId));
      } else if (roleId === USER_TYPE.MERCHANT) {
        const merchantId = 0; //user.user_id;
        dispatch(getWebsiteMerchantList(merchantId));
      } else {
        dispatch(getWebsiteActiveList());
      }
    }
  }, [roleId]);

  const list = useMemo(() => {
    if (loadAllActive) {
      return _.get(websitesActive, "data", []);
    } else if (roleId === USER_TYPE.PUBLISHER) {
      const data = _.get(websitesPublisher, "data", []);
      if (props.data) {
        return data.filter((item) =>
          props.data.find((it) => it.web_store_id === item.web_store_id)
        );
      }
      return data;
    } else if (roleId === USER_TYPE.MERCHANT) {
      return _.get(websitesMerchant, "data", []);
    } else {
      return _.get(websitesActive, "data", []);
    }
  }, [websitesActive, websitesMerchant, websitesPublisher, props.data]);

  return (
    <Form.Item name={name} rules={rules} label={label}>
      <Select
        maxTagCount="responsive"
        loading={websitesActiveLoading}
        allowClear
        mode={mode}
        placeholder={placeholder}
        showSearch
        size={size || "large"}
        filterOption={(input, option) => {
          return (
            option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        showArrow
        disabled={disabled}
        onChange={onChange}
      >
        {list?.map((item) => (
          <Select.Option key={item?.web_store_id} value={item?.web_store_id}>
            {isAdmin ? `${item.web_store_id}-${item.name}` : item.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

SelectWebsite.propTypes = {
  mode: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  rules: PropTypes.array,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  loadAllActive: PropTypes.bool,
  disabled: PropTypes.bool,
  data: PropTypes.array,
  onChange: PropTypes.func,
};

export default SelectWebsite;
