import { Select } from "antd";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { setDefaultLang } from "@/app/local/appSlice";
import ModalContentTerms from "@/components/micro/ModalContentTerms";
import { LANGUAGE } from "@/utils/constants/apiConstants";
import { getLocalLang } from "@/utils/helper";

const AuthFooter = (props) => {
  const { Option } = Select;
  const [lang, setLang] = useState(LANGUAGE.PERSIAN);
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { t } = useTranslation();

  //!REMOVE is duplicated code
  const languages = [
    {
      label: t("general.persian"),
      value: LANGUAGE.PERSIAN,
    },
    {
      label: t("general.english"),
      value: LANGUAGE.ENGLISH,
    },
  ];

  const setLang_ = useCallback(
    (e) => {
      setLang(e);

      localStorage.setItem("locale", e);
      dispatch(setDefaultLang(e));
    },
    [lang]
  );

  useEffect(() => {
    getLocalLang() && setLang(getLocalLang());
  }, []);

  return (
    <div className="auth__lang">
      <div className="auth__academy">
        <a target="_blank" style={{ color: "#8c8c8c" }} rel="noreferrer" hidden>
          {t("auth.showAgreement")}
        </a>
      </div>
      {/* <div className="auth__langChanger">
        <Select
          value={lang}
          // defaultValue={LANGUAGE.PERSIAN}
          bordered={false}
          onChange={setLang_}
          dropdownMatchSelectWidth={false}
          style={{
            width: 95,
          }}
        >
          {languages.map((item, index) => {
            return (
              <Option value={item.value} key={index}>
                {item.label}
              </Option>
            );
          })}
        </Select>
      </div> */}
      {isModalVisible ? (
        <ModalContentTerms
          isModalVisible={isModalVisible}
          handleModal={() => setIsModalVisible(false)}
          userType={4}
        />
      ) : (
        ""
      )}
    </div>
  );
};

AuthFooter.propTypes = {
  lang: PropTypes.string,
  setLang: PropTypes.func,
};

export default AuthFooter;
