import { Button, Form } from "antd";
import { useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";

import { isUserPage } from "@/pages/profile";

import "./styles.less";

const layout = {
  labelCol: {
    span: 2,
  },
  wrapperCol: {
    span: 12,
  },
};

const FooterWizard = (props) => {
  const isProfile = useMatch("/profile");
  const isUser = isUserPage();
  const isWizard = useMatch("/wizard");

  const { t } = useTranslation();
  // eslint-disable-next-line react/prop-types
  const { loading, goNextStep, current, isLast } = props;
  // const isLast = current === 3;

  return (
    <div className="steps-action">
      <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
        <Button
          loading={loading}
          style={{ minWidth: "80px" }}
          size="large"
          type="primary"
          htmlType="submit"
          disabled={isUser}
        >
          {isProfile || isUser ? t("general.submit") : ""}
          {isWizard
            ? isLast
              ? t("general.submitEnd")
              : t("general.submitContinue")
            : ""}
        </Button>
        {isWizard && current ? (
          <Button
            style={{ minWidth: "80px", marginRight: "10px" }}
            className="backBtn"
            size="large"
            type="secondary"
            onClick={() => goNextStep(current - 1)}
          >
            {t("general.back")}
          </Button>
        ) : (
          ""
        )}
      </Form.Item>
    </div>
  );
};

export default FooterWizard;
