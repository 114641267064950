import { useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Pagination from "@material-ui/lab/Pagination";
import { MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const BasicPagination = ({
  count,
  page,
  handleChange,
  pageSize,
  onChangeSize,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const El = useRef(null);

  return (
    <div className={classes.root}>
      {count > 1 && (
        <Pagination
          ref={El}
          count={count}
          page={page}
          onChange={handleChange}
          shape="rounded"
          size="small"
          color="primary"
        />
      )}

      {count >= 1 && pageSize ? (
        <div>
          <span className={classes.label}>{t("rows_in_page")}</span>
          <Select
            value={pageSize}
            onChange={(event) => onChangeSize(event.target.value)}
          >
            {![10, 20, 50].includes(pageSize) ? (
              <MenuItem value={pageSize}>{pageSize}</MenuItem>
            ) : null}
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </div>
      ) : null}
    </div>
  );
};

BasicPagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  handleChange: PropTypes.func,
  onChangeSize: PropTypes.func,
  pageSize: PropTypes.number,
};
export default BasicPagination;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexFlow: "row-reverse",
    [theme.breakpoints.down("xs")]: {
      flexFlow: "column",
    },
    "& .MuiPagination-ul": {
      justifyContent: "end",
      "@media (max-width:815px)": {
        justifyContent: "center",
      },
    },
    "& .MuiPaginationItem-root": {
      color: theme.palette.secondary.main,
    },
    "& .MuiPaginationItem-rounded": {
      borderRadius: "50%",
      minWidth: theme.spacing(4),
      height: theme.spacing(4),
      [theme.breakpoints.down("xs")]: {
        minWidth: theme.spacing(3.5),
        marginBottom: theme.spacing(1),
      },
    },
    "& .MuiPaginationItem-textPrimary.Mui-selected": {
      color: theme.palette.primary.main,
      background: theme.palette.secondary.contrastText,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    "& .MuiSelect-selectMenu": {
      fontSize: 14,
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: 10,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: theme.spacing(0.5),
    },
    "& .MuiInput-underline::before": {
      border: "none !important",
    },
    "& .MuiInput-underline::hover": {
      border: "none !important",
    },
  },
  label: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
  },
}));
