import PropTypes from "prop-types";

import ArrowRight from "@/assets/images/arrow-right-sidebar.svg";

const ButtonCollapse = (props) => {
  const { collapsed, setCollapsed } = props;
  return (
    <a onClick={() => setCollapsed(!collapsed)}>
      {!collapsed ? (
        <img src={ArrowRight} />
      ) : (
        <img src={ArrowRight} style={{ rotate: "180deg" }} />
      )}
    </a>
  );
};
ButtonCollapse.propTypes = {
  collapsed: PropTypes.bool,
  setCollapsed: PropTypes.func,
};

export default ButtonCollapse;
