import { Form, Radio } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getStandardSizeList } from "@/app/local/standardSize";

const SelectBannerSize = (props) => {
  const { defaultSizes } = props;
  const { t } = useTranslation("ssp");
  const dispatch = useDispatch();
  const { sizes, sizesLoading } = useSelector((state) => state.standardSize);

  useEffect(() => {
    dispatch(getStandardSizeList({}));
  }, []);

  const defaultSize_ = [
    { w: 728, h: 90 },
    { w: 468, h: 60 },
    { w: 300, h: 250 },
    { w: 300, h: 100 },
  ];
  const items = useMemo(() => {
    let results = _.get(sizes, "data.result", []);
    // results = results.filter((item) =>
    //   (defaultSizes || defaultSize_).find(
    //     (x) => x.h === item.height && x.w === item.width
    //   )
    // );
    let data = [];
    if (sizes?.data) {
      data = results.map((item) => ({
        key: item.standard_size_id,
        w: item.width,
        h: item.height,
      }));
    }
    return data;
  }, [sizes]);

  return (
    <Form.Item name={"size"} label={t("place.size")}>
      <Radio.Group className="size__picker">
        {items.map((item) => (
          <Radio key={item.key} value={item.key}>
            <div>
              <b>{t("place.width")}: </b>
              {item.w}px&nbsp;
              <b>{t("place.height")}: </b>
              {item.h}px
            </div>
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

SelectBannerSize.propTypes = {
  defaultSizes: PropTypes.any,
};

export default SelectBannerSize;
