import { isShebaValid } from "@persian-tools/persian-tools";
import { Col, ConfigProvider, Form, Row, message } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { clarity } from "react-microsoft-clarity";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import { accountUserDetail } from "@/app/local/accountSlice";
import { appLoadingBar } from "@/app/local/appSlice";
import { publisherSetPaymentInfo } from "@/app/local/publisherSlice";
import { getProfileData } from "@/pages/profile";
import { USER_TYPE } from "@/utils/constants/apiConstants";
import { MASK_SEHBA } from "@/utils/constants/configConstants";
import { findInfoError, getLastError } from "@/utils/helper";

import inTrackEvent from "../extra/Intrack/InTrackEvent";
import MaskInput from "../input/maskInput";
import FooterWizard from "./FooterWizard";
import { KYC_CHECK } from "./StepPersonalInfo";

const layout = {
  labelCol: {
    span: 2,
  },
  wrapperCol: {
    span: 12,
  },
};

const StepPaymentInfo = (props) => {
  const isProfile = useMatch("/profile");

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  // const { profile } = useSelector((state) => state.account);
  const [checkKyc, setCheckKyc] = useState(undefined);
  const [respError, setRespError] = useState(null);

  const { stepPayment, loading, error } = useSelector(
    (state) => state.publisher
  );

  const profile = getProfileData();
  const uId = profile?.data?.user_id;
  const userInfo = _.get(profile, "userDetail");
  const userType = _.get(profile, "data.user_type");

  useEffect(() => {
    if (!_.isEmpty(userInfo)) {
      const IRNum = userInfo.sheba?.replaceAll("IR", "");

      form.setFieldsValue({
        user: {
          sheba: IRNum,
        },
      });
      setCheckKyc(KYC_CHECK(profile, ["sheba"]));
    }
    // if (isProfile) {
    //   setInputPrivileges(
    //     "user",
    //     Object.keys(Privileges.publisher.profile),
    //     form
    //   );
    // }
  }, [userInfo]);

  useEffect(() => {
    dispatch(appLoadingBar(loading));
  }, [loading]);

  const successFullyDone = () => {
    message.success({
      content: t("general.saveSuccessFully"),
      key: "publisher",
    });
    if (_.isFunction(props?.goNextStep)) {
      dispatch(accountUserDetail());
      props.goNextStep(3);
    }
  };

  const validateMessages = {
    required: t("required"),
  };

  const onFinish = async (values) => {
    const { sheba } = values.user;
    const eventParam = {
      userId: uId,
      attributes: {
        sheba,
        kycStatus: 5,
      },
    };
    inTrackEvent.updateProfile(eventParam);
    clarity.setTag("register", "step-3");

    const IRNum = ("IR" + sheba).replaceAll(" ", "");
    const serverData = {
      user_id: uId,
      sheba: IRNum,
    };
    dispatch(appLoadingBar(true));
    const resp = await dispatch(publisherSetPaymentInfo(serverData));
    if (resp?.payload?.success) {
      if (userType === USER_TYPE.PUBLISHER) {
        // InTrack Event
        inTrackEvent.paymentInfoSubmitEvent({
          userId: uId,
          shebaNumber: IRNum,
        });
      }

      successFullyDone();
    } else {
      const keyName = findInfoError(resp?.payload?.data);
      const errorMessage =
        resp?.payload?.Message || getLastError(resp?.payload);
      message.error({
        content: errorMessage,
        key: "publisher",
      });
      setRespError(keyName);
    }
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      IR
    </Form.Item>
  );

  // const checkKyc = userInfo?.sheba ? KYC_CHECK(profile, ["sheba"]) : undefined;

  return (
    <>
      <Form
        form={form}
        name="formStepThree"
        onFinish={onFinish}
        validateMessages={validateMessages}
        // className="afl-form"
        layout="vertical"
        // labelCol={{
        //   span: 24,
        // }}
        // wrapperCol={{
        //   md: 24,
        // }}
        // initialValues={{
        //   user: {},
        // }}
      >
        <Row>
          <Col md={props.md} xs={24}>
            <Row gutter={24}>
              <Col md={16} xs={24} className="sheba__wrap">
                <ConfigProvider direction="ltr">
                  <Form.Item
                    name={["user", "sheba"]}
                    label={t("user.sheba")}
                    rules={[
                      {
                        required: true,
                      },
                      () => ({
                        validator(_, value) {
                          const IRNum = ("IR" + value).replaceAll(" ", "");
                          if (isShebaValid(IRNum)) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error(t("user.notValidShebaCode"))
                          );
                        },
                      }),
                    ]}
                    hasFeedback={
                      checkKyc === undefined
                        ? true
                        : !!checkKyc || respError?.includes("sheba")
                        ? true
                        : false
                    }
                    validateStatus={
                      checkKyc || (respError?.includes("sheba") && "error")
                    }
                  >
                    <MaskInput
                      addonBefore={prefixSelector}
                      // readOnly
                      mask={MASK_SEHBA}
                      placeholder={t("user.sheba")}
                      // size="large"
                      style={{ textAlign: "left", direction: "ltr" }}
                      placeholderChar="-"
                    />
                  </Form.Item>
                </ConfigProvider>
              </Col>
            </Row>
            <br />
            <FooterWizard
              loading={loading}
              current={2}
              goNextStep={props.goNextStep}
            />
          </Col>
          <Col></Col>
        </Row>
      </Form>
    </>
  );
};

StepPaymentInfo.propTypes = {
  goNextStep: PropTypes.func,
  md: PropTypes.number,
};
StepPaymentInfo.defaultProps = {
  md: 18,
};

export default StepPaymentInfo;
