import pt from "prop-types";

const SkeltonGenerator = (props) => {
  return Array(props.count || 10)
    .fill(1)
    .map((i, index) => <p key={index} className="skeleton"></p>);
};

SkeltonGenerator.propsType = {
  count: pt.number,
};

export default SkeltonGenerator;
