import PropTypes from "prop-types";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const CrispChat = (props) => {
  const { setDisplay } = props;
  const { profile } = useSelector((state) => state.account);
  const [isReady, setIsReady] = useState();

  useEffect(() => {
    window.$crisp = [];
    // window.CRISP_WEBSITE_ID = "337291ef-33c1-441c-a256-26ff0152107b";
    window.CRISP_WEBSITE_ID = "979e0eca-1621-4eb4-8dd9-bd6695c3bc11";
    (function () {
      var d = document;
      var s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.id = "crsip-tag";
      s.async = 1;
      var h = document.getElementById("crsip-tag");
      if (!h) {
        d.getElementsByTagName("head")[0].appendChild(s);
      }
    })();
    checkCrsipFullyLoaded();
  }, []);

  const checkCrsipFullyLoaded = useCallback(() => {
    setTimeout(() => {
      const crp = document.querySelector(".crisp-client");
      if (!crp) {
        setDisplay("IMBER");
        crp?.remove();
      }
    }, 4000);
  }, [isReady]);

  window.CRISP_READY_TRIGGER = () => {
    setIsReady(true);
    window.$crisp?.push([
      "on",
      "chat:opened",
      () => {
        const userId = profile.data?.user_id;
        profile.data?.mobile &&
          window.$crisp?.push([
            "set",
            "user:phone",
            ["+98" + profile.data?.mobile],
          ]);

        // SET ADDITIONAL INFO
        const userDetail = profile?.userDetail?.data;
        if (userDetail) {
          // EMAIL
          userDetail?.email &&
            window.$crisp?.push(["set", "user:email", [userDetail?.email]]);

          // NAME
          userDetail?.first_name &&
            window.$crisp?.push([
              "set",
              "user:nickname",
              [`${userDetail?.first_name} ${userDetail?.last_name} ${userId}`],
            ]);

          // COMPANY
          userDetail?.company_name &&
            window.$crisp?.push([
              "set",
              "user:nickname",
              [`${userDetail?.company_name} ${userId}`],
            ]);
        }
      },
    ]);
    window.$crisp?.push(["on", "chat:closed", () => {}]);
  };

  return null;
};

CrispChat.propTypes = {
  setDisplay: PropTypes.func,
};

export default CrispChat;
