import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ErrorBoundaryMain from "./ErrorBoundary";

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

const myFallback = <FallbackComponent />;

const ErrorBoundaryPrime = (props) => {
  const [locationChanged, setLocationChanged] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.key) {
      setLocationChanged(location.pathname);
    }
  }, [location.key]);

  return (
    <ErrorBoundaryMain
      locationChanged={locationChanged}
      setLocationChanged={setLocationChanged}
      navigate={navigate}
    >
      {props.children}
    </ErrorBoundaryMain>
  );
};

ErrorBoundaryPrime.propTypes = {
  router: PropTypes.any,
  children: PropTypes.any,
};
export default ErrorBoundaryPrime;
