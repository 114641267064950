import { Area, Column } from "@ant-design/plots";
import { Col, Row } from "antd";
import { t } from "i18next";
import moment from "moment-jalaali";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getOrdersReport } from "@/app/local/reportSlice";
import { DashboardContext } from "@/pages/dashboard/DashboardAdmin";
import {
  exportCsvMain,
  handleDate,
  kFormatter,
  numberFormat,
} from "@/utils/helper";

import TileNumber from "../micro/TileNumber";
import { ChartHolder } from "./AdminViewClickChart";
import { COLORS } from "./colors";
import WidgetWrapper from "./micro/WidgetWrapper";
import "./styles.less";

const lineNames = [
  "dashboard.com.generated",
  "dashboard.com.net",
  "dashboard.com.finalized",
  "dashboard.com.canceled",
];
const OrderPricesChart = () => {
  const [data, setData] = useState([]);
  const [mode, setMode] = useState("LINE");
  const [totalData, setTotalData] = useState({
    amount: 0,
    finalized: 0,
    // net: 0,
  });
  const { adminOrdersReport, adminOrdersReportLoading } = useSelector(
    (state) => state.report
  );
  const dispatch = useDispatch();
  const [context, dispatch_] = useContext(DashboardContext);

  useEffect(() => {
    onSubmit();
  }, [context]);

  useEffect(() => {
    if (adminOrdersReport) {
      const data_ = [...(adminOrdersReport?.data || [])];
      const temps = [];

      let total = {
        amount: 0,
        finalized: 0,
        // net: 0,
        cancel: 0,
      };

      data_
        .sort((a, b) => {
          const b_ = moment(b.pivot);
          const a_ = moment(a.pivot);
          const isBefore = a_.isBefore(b_);
          if (a.pivot == b.pivot) {
            return 0;
          }
          if (isBefore) {
            return -1;
          }
          return 1;
        })
        .map((item, i) => {
          const dt = handleDate(item.pivot, data_[i - 1]?.pivot);

          temps.push({
            date: dt,
            value: item.generated_order_amount,
            type: lineNames[0],
          });
          total.amount = total.amount + item.generated_order_amount;

          // temps.push({
          //   date: dt,
          //   value: item.net_order_amount,
          //   type: lineNames[1],
          // });

          // total.net = total.net + item.net_order_amount;

          temps.push({
            date: dt,
            value: item.canceled_order_amount,
            type: lineNames[3],
          });

          total.cancel = total.cancel + item.canceled_order_amount;

          temps.push({
            date: dt,
            value: item.finalized_order_amount,
            type: lineNames[2],
          });

          total.finalized = total.finalized + item.finalized_order_amount;
        });

      setTotalData(total);

      setData(temps);
    }
  }, [adminOrdersReport]);

  const barConfig = useMemo(() => {
    return {
      data,
      isGroup: true,
      xField: "date",
      yField: "value",
      yAxis: {
        label: {
          formatter: (v) => kFormatter(v, 1),
        },
      },
      limitInPlot: false,
      slider: data.length > 15 ? { start: 0.5, end: 1 } : "",
      seriesField: "type",
      marginRatio: 0.1,
      height: 315,
      color: ({ type, year }, ...x) => {
        switch (type) {
          case lineNames[3]:
            return COLORS.DASHBOARD_CARMINE_PINK;
          case lineNames[2]:
            return COLORS.DASHBOARD_GREEN;
          case lineNames[1]:
            return COLORS.DASHBOARD_BLUE;
          case lineNames[0]:
            return COLORS.DASHBOARD_PURPLE;
          default:
            return COLORS.DASHBOARD_FRENCH_PINK;
        }
      },
      legend: false,
      maxColumnWidth: 40,
      theme: {
        styleSheet: {
          fontFamily: "IRANYekan",
        },
      },
      tooltip: {
        formatter: (datum) => {
          return { name: t(datum.type), value: numberFormat(datum.value) };
        },
      },
    };
  }, [data]);

  const areaConfig = useMemo(() => {
    return {
      data,
      legend: false,
      height: 315,
      xField: "date",
      yField: "value",
      seriesField: "type",
      slider: data.length > 15 ? { start: 0.5, end: 1 } : undefined,
      // padding: [40],
      limitInPlot: false,
      padding: [30, 30, 60, 40],
      isStack: false,
      tooltip: {
        formatter: (datum) => {
          return { name: t(datum.type), value: numberFormat(datum.value) };
        },
      },
      theme: {
        styleSheet: {
          fontFamily: "IRANYekan",
        },
      },
      yAxis: {
        label: {
          formatter: (v) => kFormatter(v, 1),
        },
      },
      color: ({ type, year }, ...x) => {
        switch (type) {
          case lineNames[3]:
            return COLORS.DASHBOARD_CARMINE_PINK;
          case lineNames[2]:
            return COLORS.DASHBOARD_GREEN;
          case lineNames[1]:
            return COLORS.DASHBOARD_BLUE;
          case lineNames[0]:
            return COLORS.DASHBOARD_PURPLE;
          default:
            return COLORS.DASHBOARD_RED;
        }
      },
    };
  }, [data]);

  const onSubmit = (e) => {
    dispatch(
      getOrdersReport({
        web_store_ids: context.webstore,
        media_ids: e?.medias || context?.medias,
        link_type_report_types: e?.link_type,
        from: context.period.fromDate,
        to: context.period.toDate,
        publisher_ids: context?.publisher_ids,
        filter_date_by: context?.filter_date_by,
      })
    );
  };

  const exportCsv = () => {
    try {
      const header = ["date", "value", "type"];
      const d = [];
      d.push(header);
      data.map((item) => {
        const tm = Object.values(item);
        tm[2] = tm[2].replace("dashboard.com.", "")?.toUpperCase();
        d.push(tm);
      });
      exportCsvMain(d);
    } catch (e) {
      const cashing = "";
    }
  };

  return (
    <WidgetWrapper
      setMode={setMode}
      title={"dashboard.orderPriceChartTitle"}
      onSubmit={onSubmit}
      exportCsv={exportCsv}
      className="admin_commission_chart"
    >
      <div>
        <Row gutter={[24, 24]}>
          <Col md={24} xs={24} className="tile-number-box">
            <div
              className="horizontal-chart-bar"
              dir="rtl"
              style={{ direction: "rtl" }}
            >
              <div className="tile-bar">
                <TileNumber
                  number={totalData.amount}
                  title={t("dashboard.amountTotal")}
                  color={"color_2"}
                  type="price"
                />
                {/* <TileNumber
              number={totalData.net}
              title={t("dashboard.pureOrderAmount")}
              color={"color_2"}
              type="price"
            /> */}
                <TileNumber
                  number={totalData.cancel}
                  title={t("dashboard.cancelOrderAmount")}
                  color={"color_10"}
                  type="price"
                />
                <TileNumber
                  number={totalData.finalized}
                  title={t("dashboard.costAllOrder")}
                  color={"color_5"}
                  type="price"
                />
              </div>
            </div>
          </Col>
          <Col
            md={24}
            xs={24}
            dir="ltr"
            style={{ direction: "ltr", minWidth: "320" }}
          >
            <ChartHolder
              loading={adminOrdersReportLoading}
              len={data?.length}
              height="440px"
            >
              {mode === "BAR" ? (
                <Column loading={adminOrdersReportLoading} {...barConfig} />
              ) : (
                ""
              )}
              {mode === "LINE" ? (
                <Area loading={adminOrdersReportLoading} {...areaConfig} />
              ) : (
                ""
              )}
            </ChartHolder>
          </Col>
        </Row>
      </div>
    </WidgetWrapper>
  );
};

OrderPricesChart.propTypes = {
  name: PropTypes.string,
};

export default OrderPricesChart;
