import { runTour } from "@/app/local/appSlice";
import { USER_TYPE } from "@/utils/constants/apiConstants";

const tourData = [
  {
    created_at: 1667300268,
    key: "APPROVE_CONTRACT_TOUR",
    title: "آموزش تایید قرارداد",
    lang: "IR_FA",
    page: "/profile",
    enabled: true,
    watcher: [USER_TYPE.PUBLISHER],
    steps: [
      {
        key: "step_1",
        disableBeacon: true,
        target: "[tour_id='profile-title']",
        content: "ابتدا وارد صفحه پروفایل خود شوید",
      },
      {
        key: "step_2",
        target: "[tour_id='contract-tab']",
        content: "بر روی تب قرارداد کلیک کنید.",
        action: (e) => {
          const { action, index, status, type, lifecycle } = e;
          if (
            // a.key === "step_2" &&
            action === "next" &&
            lifecycle === "ready"
          ) {
            document.querySelector("[tour_id='contract-tab']").click();
          }
        },
      },
      {
        key: "step_3",
        target: "[tour_id='accept-button']",
        content:
          "بعد از مطالعه قرارداد، در صورت تایید، بر روی گزینه قبول دارم کلیک کنید.",
      },
    ],
  },
  {
    created_at: 1667300268,
    key: "CREATE_MEDIA_TOUR",
    title: "آموزش ساخت رسانه",
    lang: "IR_FA",
    page: "/medias",
    enabled: false,
    watcher: [USER_TYPE.PUBLISHER],
    steps: [
      {
        key: "step_1",
        disableBeacon: true,
        target: "[tour_id='media-title']",
        content: "ابتدا وارد صفحه رسانه شوید",
      },
      {
        key: "step_2",
        disableBeacon: true,
        target: "[tour_id='add-button']",
        content: "بعد دکمه اضافه کردن رسانه را بزنید.",
        action: (e) => {
          const { action, index, status, type, lifecycle } = e;
          if (action === "next" && lifecycle === "complete") {
            document.querySelector("[tour_id='media-slider']").click();
          }
        },
      },
      {
        key: "step_3",
        disableBeacon: true,
        target: "[tour_id='submit-button']",
        content: "بعد از پر کردن فرم، این دکمه را بزنید.",
        action: (e) => {
          if (e.status === "running" && e.type === "error:target_not_found") {
            runTour("step_3");
          }
        },
      },
    ],
  },
  {
    created_at: 1667300268,
    key: "ADD_WEBSTORE_TO_MEDIA",
    title: "آموزش اضافه کردن فروشگاه به رسانه",
    watcher: [USER_TYPE.PUBLISHER],
    lang: "IR_FA",
    page: "/medias",
    enabled: false,
    steps: [
      {
        key: "step_1",
        disableBeacon: true,
        target: "[tour_id='media-title']",
        content: "ابتدا وارد صفحه رسانه شوید",
      },
      {
        key: "step_2",
        disableBeacon: true,
        target: "[tour_id='media_details']",
        content: "سپس روی جزییات یک رسانه کلیک کنید",
        action: (e) => {
          const { action, index, status, type, lifecycle } = e;
          if (action === "next" && lifecycle === "complete") {
            document.querySelector("[tour_id='media-details-page']").click();
          }
        },
      },
    ],
  },
  {
    created_at: 1667300268,
    key: "ADD_SMART_WIDGET",
    title: "آموزش ایجاد ویجت معمولی",
    watcher: [USER_TYPE.PUBLISHER],
    lang: "IR_FA",
    page: "/profile",
    enabled: false,
    steps: [],
  },
  // {
  //   created_at: 1667300268,
  //   key: "ADD_NORMAL_WIDGET",
  //   title: "آموزش ایجاد اسمارت ویجت",
  //   watcher: [USER_TYPE.PUBLISHER],
  //   lang: "IR_FA",
  //   page: "/profile",
  //   enabled: false,
  //   steps: [],
  // },
  {
    created_at: 1667300268,
    key: "ADD_NORMAL_WIDGET",
    title: "آموزش ایجاد اسمارت ویجت",
    watcher: [USER_TYPE.MERCHANT],
    lang: "IR_FA",
    page: "/profile",
    enabled: false,
    steps: [],
  },
  // {
  //   created_at: 1667300268,
  //   key: "ADD_SMART_WIDGET",
  //   watcher: [USER_TYPE.PUBLISHER],
  //   title: "آموزش نمایش کمیسیون های فروشگاه",
  //   lang: "IR_FA",
  //   page: "/profile",
  //   enabled: false,
  //   steps: [],
  // },
  // {
  //   created_at: 1667300268,
  //   key: "ADD_SMART_WIDGET",
  //   title: "آموزش ایجاد بنر ویجت",
  //   watcher: [USER_TYPE.PUBLISHER],
  //   lang: "IR_FA",
  //   page: "/profile",
  //   enabled: false,
  //   steps: [],
  // },
];

export default tourData;
