import axios from "axios";
// const baseURL = "https://smartech.helpical.ir/api/external/";
const baseURL = "https://ticket-api.adverge.ir/";

const headers = {
  "x-api-key": "EB06907E-9208-4102-A252-F141CBE42223",
};

export const ticketsList = (email, query) => {
  return axios({
    method: "GET",
    url: baseURL + "tickets" + `?user_email=${email}${query ? query : ""}`,
    // headers,
  });
};

export const ticketInformation = (id, email) => {
  return axios({
    method: "GET",
    url: baseURL + "tickets/" + id + `?user_email=${email}`,
    headers,
  });
};

export const newTicket = (data) => {
  return axios({
    method: "POST",
    url: baseURL + "tickets",
    // headers,
    headers: { ...headers, "content-type": "multipart/form-data" },
    data,
  });
};

export const updateTickets = (id, email, data) => {
  return axios({
    method: "POST",
    url: baseURL + "tickets/" + id + `?user_email=${email}`,
    headers,
    data,
  });
};

export const newCustomer = (data) => {
  return axios({
    method: "POST",
    url: baseURL + "customers",
    headers,
    data,
  });
};

export const systemSettings = (data) => {
  return axios({
    method: "GET",
    url: baseURL + "systemDefaults",
    headers,
    data,
  });
};
