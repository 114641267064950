import { Button, Card, Typography, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { addInventory, updateInventory } from "@/app/local/inventorySlice";
import {
  CONTENT_DISPLAY_TYPE,
  DEVICE_TYPE,
  INVENTORY_FORMATS,
  INVENTORY_TYPE,
} from "@/utils/constants/apiConstants";

import { PlaceContext, useHookPlace } from "../AdPlaceBuilder";
import CatfishConfig from "../placeConfig/CatfishConfig";
import FixedConfig from "../placeConfig/FixedConfig";
import FixedConfigBanner from "../placeConfig/FixedConfigBanner";
import FloatConfig from "../placeConfig/FloatConfig";
import { bannerSizeOptions } from "../placeConfig/FormMaster";
import InArticleConfig from "../placeConfig/InArticleConfig";
import InFeedConfig from "../placeConfig/InFeedConfig";
import MultiplexConfig from "../placeConfig/MultiplexConfig";
import PopupConfigBanner from "../placeConfig/PopupConfigBanner";
import SliderConfig from "../placeConfig/SliderConfig";
import "./ConfigPlace.less";
import { STEPS } from "./PlaceStepper";

const ConfigPlace = () => {
  const [context, dispatchContext] = useContext(PlaceContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [formComponent] = useForm();
  const { t } = useTranslation(["translation", "ssp"]);
  const isNative = context?.type === INVENTORY_TYPE.native;

  const { placeId, isNewPage, inventoryDetail } = useHookPlace();

  useEffect(() => {
    if (!isNewPage && inventoryDetail?.data) {
      const d = inventoryDetail?.data;
      dispatchContext({
        type: "config",
        value: d,
      });
    }
  }, [inventoryDetail]);

  useEffect(() => {
    if (context?.config) {
      const isEmpty = _.isEmpty(context?.config);
      if (isEmpty) {
        formComponent.resetFields();
      } else {
        const devTypes = [];
        context?.config?.device_type?.find((k) => {
          if (DEVICE_TYPE.Desktop === k) devTypes.push("Desktop");
          if (DEVICE_TYPE.Mobile === k) devTypes.push("Mobile");
          if (DEVICE_TYPE.Tablet === k) devTypes.push("Tablet");
        });

        formComponent.setFieldsValue({
          title: context?.config?.title || context?.config?.name,
          ...context?.config,
          device_type: devTypes,
        });
      }
    }
  }, [context?.config, placeId]);

  const syncData = async (data) => {
    const config = data || {};
    const media = context.media;
    const name = context.name;

    const responsive_type = 1;
    if (!_.isUndefined(config.responsive_type)) {
      config.responsive_type = config.responsive_type === "checked" ? 1 : 2;
    }

    const size = bannerSizeOptions.find((item) => item.key === config.size);

    const devTypes = [];
    config.device_type.map((item) => {
      if (Object.keys(DEVICE_TYPE).includes(item)) {
        devTypes.push(DEVICE_TYPE[item]);
      }
    });

    const body = {
      media_id: media,
      type: context.type,
      format_type: context.format,
      device_type: devTypes,
      name: name,
      title: config.title,
      max_item_count: config.max_item_count || 1,
      max_mobile_item_count: config.max_mobile_item_count || 1,
      position_type: config.position_type || 0,
      start_delay_second: config.start_delay_second,
      enable_to_close_delay_second: config.enable_to_close_delay_second,
      pop_up_type: config.pop_up_type || 1,
      // TODO MUST BE REMOVED
      bid_floor: config.bid_floor || { amount: 8000 },
      size_id: config?.size,
      content_display_type:
        config.content_display_type || CONTENT_DISPLAY_TYPE.ImageOnTop,

      // not used
      enable_to_close: context.enable_to_close,
      responsive_type: responsive_type || 1,
      row_count: context.row_count,
      column_count: context.column_count,
      // size: context.size,
      has_bid_floor: context.has_bid_floor || true,
      expire_date: context.expire_date,
    };

    if (isNewPage) {
      const r = await dispatch(addInventory(body));
      if (r?.payload?.success) {
        message.success({
          content: t("general.saveSuccessFully"),
        });
        handleNext();
      } else {
        message.error({
          content: r.payload?.Message || t("general.unHandleErrorMessage"),
        });
      }
    } else {
      body["inventory_id"] = placeId;
      const r = await dispatch(updateInventory(body));
      if (r?.payload?.success) {
        message.success({
          content: t("general.saveSuccessFully"),
        });
        handleNext();
        // nav(-1);
      } else {
        message.error({
          content: r.payload?.Message || t("general.unHandleErrorMessage"),
        });
      }
    }
  };

  const handleNext = () => {
    const cuIndex = STEPS.findIndex((item) => item.key === context.currentStep);
    const nextIndex = STEPS[cuIndex + 1];
    dispatchContext({ type: "currentStep", value: nextIndex.key });

    // CHANGE URL PARAMS
    setSearchParams({ step: cuIndex + 2 });
  };

  const onFinish = (e) => {
    formComponent.validateFields().then(() => {
      const data = formComponent.getFieldsValue();
      dispatchContext({
        type: "config",
        value: data,
      });
      syncData(data);
    });
  };

  const CUF = INVENTORY_FORMATS[context.format];

  return (
    <div className="config-place">
      <Card
        title={
          <div>
            <Typography.Title level={5}>
              {t("place.ConfigPlaceTitle", { ns: "ssp" })}
            </Typography.Title>
            <p className="subtitle">{t("formats.FixedDesc", { ns: "ssp" })}</p>
          </div>
        }
      >
        <div className="config-place__content">
          {/* // NATIVE */}
          {isNative && CUF === INVENTORY_FORMATS.Fixed ? (
            <FixedConfig form={formComponent} />
          ) : (
            false
          )}
          {CUF === INVENTORY_FORMATS.Slider ? (
            <SliderConfig form={formComponent} />
          ) : (
            false
          )}
          {CUF === INVENTORY_FORMATS.Multiplex ? (
            <MultiplexConfig form={formComponent} />
          ) : (
            false
          )}
          {CUF === INVENTORY_FORMATS.InArticle ? (
            <InArticleConfig form={formComponent} />
          ) : (
            false
          )}
          {CUF === INVENTORY_FORMATS.InFeed ? (
            <InFeedConfig form={formComponent} />
          ) : (
            false
          )}
          {CUF === INVENTORY_FORMATS.Float ? (
            <FloatConfig form={formComponent} />
          ) : (
            false
          )}
          {isNative && CUF === INVENTORY_FORMATS.Catfish ? (
            <CatfishConfig form={formComponent} />
          ) : (
            false
          )}
          {/* // BANNER */}
          {!isNative && CUF === INVENTORY_FORMATS.Fixed ? (
            <FixedConfigBanner form={formComponent} />
          ) : (
            false
          )}
          {!isNative && CUF === INVENTORY_FORMATS.Popup ? (
            <PopupConfigBanner form={formComponent} />
          ) : (
            false
          )}
          {!isNative && CUF === INVENTORY_FORMATS.Catfish ? (
            <CatfishConfig form={formComponent} isBanner />
          ) : (
            false
          )}
        </div>
        <Button htmlType="submit" hidden id="submitButton" onClick={onFinish}>
          OK
        </Button>
      </Card>
    </div>
  );
};

export default ConfigPlace;
