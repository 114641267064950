import {
  CheckOutlined as CheckIcon,
  CloseOutlined as CloseIcon,
  MoreOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Input,
  Menu,
  Popconfirm,
  Popover,
  Space,
  Table,
  Tooltip,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import confirm from "antd/lib/modal/confirm";
import Paragraph from "antd/lib/typography/Paragraph";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { accountAutoLogin } from "@/app/local/accountSlice";
import { appLoading } from "@/app/local/appSlice";
import { USER_TYPE } from "@/utils/constants/apiConstants";
import { dateFormat, numberFormat } from "@/utils/helper";
import { useDeviceDetector } from "@/utils/hooks/useDeviceDetector";

import MultiCheckBox from "../input/multiCheckBox";
import "./TableComponent.less";

const { Column } = Table;

const TableComponent = (props) => {
  const dispatch = useDispatch();
  const {
    name,
    heads,
    data,
    info,
    handlePagination,
    handleSort,
    selectedRows,
    setSelectedRows,
    form_,
    hideSelectedRows,
    callbackDelete,
    summaryCustom,
    onFinishSearch,
    actionTitle,
  } = props;
  const { t } = useTranslation();
  const [form] = useForm();
  const [openCustomTable, setOpenCustomTable] = useState();

  const isMobile = useDeviceDetector();

  const handleClick = async (item) => {
    if (item.type === "AUTO_LOGIN") {
      dispatch(appLoading(true));
      const res = await dispatch(
        accountAutoLogin({
          user_name: item.username,
        })
      );
      if (res?.payload?.success) {
        const uType = res?.payload?.data?.user_type;
        const isAdmin =
          uType === USER_TYPE.SUPER_ADMIN || uType === USER_TYPE.ADMIN;
        window.location.href = isAdmin ? "/" : "/";
      } else {
        dispatch(appLoading(false));
        message.error({
          content: res?.payload?.Message || t("general.unHandleErrorMessage"),
        });
      }
    }
  };

  const menu = (menuItem) => {
    return (
      <Menu>
        {menuItem?.map((item, i) => {
          return (
            <Menu.Item key={i} disabled={item.disabled}>
              {!item?.type ? <Link to={item.link}>{item.name}</Link> : ""}
              {item.type === "AUTO_LOGIN" ? (
                <Popconfirm
                  placement="topRight"
                  title={t("users.sureForAutoLogin")}
                  onConfirm={() => handleClick(item)}
                  okText={t("general.ok")}
                  cancelText={t("general.no")}
                >
                  <Link to="">{item.name}</Link>
                </Popconfirm>
              ) : item.type === "CUSTOM_NODE" ? (
                item.customNode
              ) : item.type ? (
                <a onClick={item.handleClick}>{item.name}</a>
              ) : (
                ""
              )}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const renderColumn = (head) => {
    if (head.type === "custom") {
      return (
        <Column
          title={head.title}
          dataIndex={head.key}
          key={head.key}
          render={(content) => {
            return <Space size="middle">{content}</Space>;
          }}
          width={head?.width}
        />
      );
    }
    if (head.type === undefined) {
      return (
        <Column
          // TODO: title custom sort
          // title={(titleProps) => {
          //   const sortedColumn = titleProps.sortColumns?.find(
          //     ({ column }) => column.key === head.key
          //   );

          //   return (
          //     <>
          //       {sortedColumn?.order === "ascend" ? "A" : "B"}
          //       {head.title}
          //     </>
          //   );
          // }}
          title={head.title}
          sorter={head.sort}
          dataIndex={head.key}
          key={head.key}
          fixed={head?.fixed}
          width={head?.width}
        />
      );
    }
    if (head.type === "boolean") {
      return (
        <Column
          title={head.title}
          key={head.key}
          dataIndex={head.key}
          render={(content) => {
            return (
              <div className="middle">
                <Tooltip>
                  {content === true || content[head.key] === true ? (
                    <CheckIcon style={{ color: "#159B73" }} />
                  ) : (
                    <CloseIcon style={{ color: "#EB4C4C" }} />
                  )}
                </Tooltip>
              </div>
            );
          }}
        />
      );
    }
    if (head.type === "actions") {
      let actions = (content) => {
        let result;
        if (content.actions?.length > 1) {
          result = (
            <Dropdown
              overlay={menu(content.actions)}
              placement="bottomCenter"
              arrow
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <MoreOutlined style={{ fontSize: 24, color: "#7D8793" }} />
              </a>
            </Dropdown>
          );
        } else if (content.actions.length === 1) {
          result = (
            <Dropdown
              overlay={menu([
                ...content.actions,
                { name: "حذف", disabled: true },
              ])}
              placement="bottomCenter"
              arrow
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <MoreOutlined style={{ fontSize: 24, color: "#7D8793" }} />
              </a>
            </Dropdown>
          );
          // const [act] = content.actions;
          // if (act?.handleClick) {
          //   result = (
          //     <Button onClick={act.handleClick} size="small">
          //       {act?.name}
          //     </Button>
          //   );
          // } else {
          //   result = (
          //     <Button href={act?.link} size="small">
          //       {act?.name}
          //     </Button>
          //   );
          // }
        } else {
          result = "-";
        }
        return result;
      };

      return (
        <Column
          fixed={head?.fixed}
          width={head?.width}
          sorter={false}
          title={actionTitle ? actionTitle : t("Action")}
          key="actions"
          render={(content) => <Space size="middle">{actions(content)}</Space>}
        />
      );
    }
    if (head.type === "showMore") {
      return (
        <Column
          title={head.title}
          dataIndex={head.key}
          key={head.key}
          render={(content) => {
            return (
              <div>
                <Paragraph
                  style={{ maxWidth: "120px" }}
                  ellipsis={{ expandable: false, tooltip: true, rows: 1 }}
                >
                  {content}
                </Paragraph>
              </div>
            );
          }}
          width={head?.width}
        />
      );
    }
    if (head.type === "dateTime") {
      return (
        <Column
          title={head.title}
          dataIndex={head.key}
          key={head.key}
          render={(content) => {
            return <div>{dateFormat(content, "ST")}</div>;
          }}
          width={head?.width}
        />
      );
    }
    if (head.type === "date") {
      return (
        <Column
          title={head.title}
          dataIndex={head.key}
          key={head.key}
          render={(content) => {
            return <div>{dateFormat(content, "SF")}</div>;
          }}
          width={head?.width}
        />
      );
    }
  };

  const TableSummary = props.rowSelection
    ? () => {
        let type = 0;
        if (hideSelectedRows) return;
        if (selectedRows?.length) {
          type = 1;
        }

        if (type === 2) {
          return (
            <Table.Summary fixed="top">
              {type === 1 ? (
                <div style={{ display: "flex" }}>
                  <Form
                    form={form_ || form}
                    layout={"inline"}
                    onFinish={props.handleBatchUpdate}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    <Form.Item
                      label={`${selectedRows?.length} ${t(
                        "general.rowSelected"
                      )}`}
                    ></Form.Item>
                    {props.rowSelectionFormItmes}
                    <Form.Item className="submit">
                      <Button size="medium" type="primary" htmlType="submit">
                        {t("general.submit")}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              ) : undefined}
            </Table.Summary>
          );
        }
        return (
          <Table.Summary fixed="top">
            {type === 1 ? (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={24}>
                  <Form
                    form={form_ || form}
                    layout={"inline"}
                    onFinish={props.handleBatchUpdate}
                  >
                    <Form.Item
                      label={`${selectedRows?.length} ${t(
                        "general.rowSelected"
                      )}`}
                    ></Form.Item>
                    {props.rowSelectionFormItmes}
                    <Form.Item className="submit">
                      <Button size="medium" type="primary" htmlType="submit">
                        {t("general.submit")}
                      </Button>
                    </Form.Item>
                  </Form>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            ) : undefined}
          </Table.Summary>
        );
      }
    : "";

  const handleSelect = (record, selected) => {
    if (selected) {
      setSelectedRows((keys) => [...keys, record[props.rowKeyName]]);
    } else {
      // deletedSelectedRow
      // setSelectedRemove();
      // const isBeforeSelected = false;
      if (record.active_in_current) {
        confirm({
          content: t("campaign.sure_for_remove_from_item"),
          onOk() {
            callbackDelete(record);
          },
          okText: t("general.ok"),
          cancelText: t("general.cancel"),
          cancelButtonProps: {
            // hidden: true,
          },
          // className: "upgradeAlert",
        });
      } else {
        setSelectedRows((keys) => {
          const index = keys.indexOf(record[props.rowKeyName]);
          return [...keys.slice(0, index), ...keys.slice(index + 1)];
        });
      }
    }
  };

  const toggleSelectAll = () => {
    setSelectedRows((keys) =>
      keys.length === data.length ? [] : data.map((r) => r[props.rowKeyName])
    );
  };

  const headerCheckbox = (
    <Checkbox
      checked={selectedRows?.length}
      indeterminate={
        selectedRows?.length > 0 && selectedRows?.length < data.length
      }
      onChange={toggleSelectAll}
    />
  );

  const rowSelection = {
    selectedRowKeys: selectedRows,
    type: "checkbox",
    fixed: true,
    onSelect: handleSelect,
    columnTitle: headerCheckbox,
  };

  const menuItems = heads
    .filter((item) => !item.key.includes("_id") && !item.key.includes("action"))
    .map((item) => ({
      value: item.key,
      label: item.title,
    }));

  const [types, setTypes] = useState(menuItems.map((item) => item.value));
  const [init, setInit] = useState(false);
  const [excludedTitles, setExcludedTitles] = useState();
  const storeData = (data) => {
    localStorage.setItem(
      data.name,
      JSON.stringify({ [data.tableName]: data.value })
    );
  };
  const getIgnoreColumns = () => {
    const dt = localStorage.getItem("tbl_custom");
    if (dt) {
      const x = JSON.parse(dt);
      const m = x[name];
      setExcludedTitles(m);
    }
  };

  useEffect(() => {
    getIgnoreColumns();
  }, []);

  useEffect(() => {
    if (types && init) {
      const g = [];
      menuItems.forEach((item) => {
        const d = types.find((h) => h === item.value);
        if (!d) {
          g.push(item.value);
        }
        return !d;
      });
      setExcludedTitles(g);

      storeData({
        name: "tbl_custom",
        tableName: name,
        value: g,
      });
    }
    if (!init) setInit(true);
  }, [types]);

  const defaultTypes =
    types.filter((item) => !excludedTitles?.includes(item)) || [];

  const contentCustomContent = (
    <div className="box-filter">
      <MultiCheckBox
        options={menuItems}
        checkedList={defaultTypes}
        setCheckedList={setTypes}
      />
    </div>
  );

  return (
    <div className="table-wrapper">
      {_.isFunction(onFinishSearch) ? (
        <div className="table-header">
          <div className="search-filter">
            <Input
              type="search"
              placeholder={t("general.inputSearch")}
              prefix={<i className="adt icon-search-normal-outline"></i>}
              bordered={false}
              allowClear
              onChange={_.debounce((e) => {
                form_.setFieldValue("search", e.target.value);
                const data = form_.getFieldsValue();
                onFinishSearch(data);
              }, 400)}
              // size="large"
            />
          </div>
          <div className="search-action">
            <div className="filter-table">
              <Popover
                content={contentCustomContent}
                trigger="click"
                placement="bottomRight"
                className="pop-over"
              >
                <Button
                  icon={
                    <i
                      className="adt icon-grid-outline"
                      onClick={setOpenCustomTable}
                    ></i>
                  }
                  type="text"
                ></Button>
              </Popover>
            </div>
          </div>
        </div>
      ) : (
        false
      )}
      <Table
        // totalBoundaryShowSizeChanger
        rowSelection={props.rowSelection ? rowSelection : ""}
        rowKey={(record) =>
          props.rowSelection ? record[props.rowKeyName] : ""
        }
        summary={() => {
          if (props.selectedRows?.length) return TableSummary();
          if (summaryCustom) return summaryCustom();
        }}
        loading={props.loading}
        dataSource={data}
        className="table-c"
        scroll={props.scroll}
        sticky={props.rowSelection}
        pagination={
          info.serverPagination
            ? {
                total: info.items,
                pageSize: info.pageSize || 10,
                current: info.page,
                showSizeChanger: true,
                hideOnSinglePage: true,
                showTotal: (t_, r) =>
                  !isMobile
                    ? `${t("general.totalNumber")}: ${numberFormat(t_)}`
                    : `${t("general.total")}: ${numberFormat(t_)}`,
              }
            : {
                total: data?.length,
                pageSize: info.pageSize || 10,
                showSizeChanger: true,
                hideOnSinglePage: true,
                showTotal: (t_, r) => `${t("general.totalNumber")}: ${t_}`,
              }
        }
        onChange={(page, filter, sort, extra) => {
          if (extra.action === "sort") {
            if (info.serverPagination) {
              handleSort(sort);
            } else {
              if (_.isFunction(handleSort)) {
                handleSort(sort);
              }
            }
          } else if (extra.action === "paginate") {
            if (info.serverPagination) {
              handlePagination(page);
            } else {
              handlePagination(page);
            }
          }
        }}
      >
        {heads
          .filter((item) => !excludedTitles?.includes(item.key))
          .map((head) => renderColumn(head))}
      </Table>
    </div>
  );
};

TableComponent.propTypes = {
  name: PropTypes.string,
  heads: PropTypes.array,
  data: PropTypes.array,
  info: PropTypes.object,
  loading: PropTypes.bool,
  hideSelectedRows: PropTypes.bool,
  handlePagination: PropTypes.func,
  handleSort: PropTypes.func,
  scroll: PropTypes.object,
  showSizeChanger: PropTypes.bool,
  rowSelection: PropTypes.bool,
  rowSelectionFormItmes: PropTypes.element,
  handleBatchUpdate: PropTypes.func,
  callbackDelete: PropTypes.func,
  selectedRows: PropTypes.array,
  setSelectedRows: PropTypes.func,
  rowKeyName: PropTypes.string,
  form_: PropTypes.any,
  summaryCustom: PropTypes.any,
  onFinishSearch: PropTypes.any,
  actionTitle: PropTypes.string,
};

export default TableComponent;
