import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ENDPOINTS } from "@/utils/constants/apiConstants";
import { clearObject } from "@/utils/utils";

import { baseAPI } from "../services/fetchers";

export const getBannersListAsync = createAsyncThunk(
  "banner/getBannersListAsync",
  async (data) => {
    try {
      const body = {
        page: 1,
        page_size: 10,
      };
      const data_ = clearObject(data);
      const body_ = Object.assign({}, body, data_);

      const end_ = ENDPOINTS.BANNER_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const addBannerAsync = createAsyncThunk(
  "banner/addBannerAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.BANNER_ADD;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const editBannerAsync = createAsyncThunk(
  "banner/editBanner",
  async (data) => {
    try {
      const end_ = ENDPOINTS.BANNER_EDIT;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getBannerSizeListAsync = createAsyncThunk(
  "banner/getBannerSizeListAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.BANNER_SIZE_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const bannerUpdateToDefaultAsync = createAsyncThunk(
  "banner/bannerUpdateToDefaultAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.BANNER_SET_STATUS_DEFAULT.replace(
        "{bannerId}",
        data.banner_id
      ).replace("{isDefault}", data.is_default);

      const response = await baseAPI({
        endPoint: end_,
        // body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getBannerDetailAsync = createAsyncThunk(
  "banner/getBannerDetailAsyncAsync",
  async (bId) => {
    try {
      const end_ = ENDPOINTS.BANNER_DETAIL.replace("{bannerId}", bId);

      const response = await baseAPI({
        endPoint: end_,
        body: {
          bannerId: bId,
        },
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setBannerStatusAsync = createAsyncThunk(
  "banner/setBannerStatusAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.BANNER_SET_STATUS.replace(
        "{bannerId}",
        data?.banner_id
      ).replace("{isEnabled}", data?.is_enabled);

      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    banner: null,
    bannerLoading: false,
    bannerError: null,

    addBannerLoading: false,
    addBannerError: null,
    addBannerData: null,

    loading: false,
    error: null,

    bannerSizes: null,
    bannerSizesLoading: null,

    banners: null,
    bannersLoading: false,
    bannersError: null,
  },
  reducers: {
    clearState: (state) => {
      state.banner = null;
      state.bannerLoading = false;
      state.bannerError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBannersListAsync.pending, (state, action) => {
      state.bannersLoading = true;
      state.bannersError = null;
    });
    builder.addCase(getBannersListAsync.rejected, (state, action) => {
      state.bannersLoading = false;
      state.bannersError = action.error;
    });
    builder.addCase(getBannersListAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.banners = action.payload;
      }
      state.bannersLoading = false;
      state.bannersError = null;
    });

    builder.addCase(addBannerAsync.pending, (state, action) => {
      state.addBannerLoading = true;
      state.addBannerError = null;
    });

    builder.addCase(addBannerAsync.rejected, (state, action) => {
      state.addBannerLoading = false;
      state.addBannerError = action.error;
    });
    builder.addCase(addBannerAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.addBannerData = action.payload;
      }
      state.addBannerLoading = false;
      state.addBannerError = null;
    });
    builder.addCase(editBannerAsync.pending, (state, action) => {
      state.addBannerLoading = true;
    });
    builder.addCase(editBannerAsync.fulfilled, (state, action) => {
      state.addBannerLoading = false;
    });
    builder.addCase(editBannerAsync.rejected, (state, action) => {
      state.addBannerLoading = false;
    });

    builder.addCase(getBannerDetailAsync.pending, (state, action) => {
      state.bannerLoading = true;
      state.bannerError = null;
    });
    builder.addCase(getBannerDetailAsync.rejected, (state, action) => {
      state.bannerLoading = false;
      state.bannerError = action.error;
    });
    builder.addCase(getBannerDetailAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.banner = action.payload;
      }
      state.bannerLoading = false;
      state.bannerError = null;
    });

    builder.addCase(getBannerSizeListAsync.pending, (state, action) => {
      state.bannerSizesLoading = true;
    });
    builder.addCase(getBannerSizeListAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.bannerSizes = action.payload;
      }
      state.bannerSizesLoading = false;
    });
    builder.addCase(getBannerSizeListAsync.rejected, (state, action) => {
      state.bannerSizesLoading = false;
    });
  },
});

export const { clearState } = bannerSlice.actions;

export const getBannersList = (data) => async (dispatch) => {
  return dispatch(getBannersListAsync(data));
};

export const addBanner = (data) => async (dispatch) => {
  return dispatch(addBannerAsync(data));
};

export const editBanner = (data) => async (dispatch) => {
  return dispatch(editBannerAsync(data));
};

export const setBannerStatus = (data) => async (dispatch) => {
  return dispatch(setBannerStatusAsync(data));
};

export const getBannerSizeList = (data) => async (dispatch) => {
  return dispatch(getBannerSizeListAsync(data));
};

export const bannerUpdateToDefault = (data) => async (dispatch) => {
  return dispatch(bannerUpdateToDefaultAsync(data));
};

export const getBannerDetail = (data) => async (dispatch) => {
  return dispatch(getBannerDetailAsync(data));
};

export default bannerSlice.reducer;
