import { Button, Form, Input, Space, message } from "antd";
import Title from "antd/lib/typography/Title";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  accountMobileChange,
  accountOldUser,
  accountOldVerifyEmail,
  clearState,
  sendEmailCode,
  sendSmsCode,
} from "@/app/local/accountSlice";
import LoginImage from "@/assets/images/login.svg";
import LogoImage from "@/assets/images/logo-white.svg";
import CodeInput from "@/components/input/codeInput";
import MaskInput from "@/components/input/maskInput";
import MobileConfirm from "@/components/recovery/MobileConfirm";
import ResetPasswordComponent from "@/components/recovery/ResetPasswordComponent";
import {
  SEND_CODE_CONSUMER,
  USER_CONFIG_TYPE,
} from "@/utils/constants/apiConstants";
import { MASK_MOBILE_INPUT } from "@/utils/constants/configConstants";

import AuthLayout from "./authLayout";

export const MODE = {
  USERNAME: "USERNAME",
  EMAIL: "EMAIL",
  MOBILE: "MOBILE",
  MOBILE_CONFIRM: "MOBILE_CONFIRM",
  RESET_PASSWORD: "RESET_PASSWORD",
};
export const RecoveryAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [mode, setMode] = useState(MODE.USERNAME);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [mobileNumber, setMobileNumber] = useState("");
  //   const [userEmail, setUserEmail] = useState("");

  const navigateTo = (name) => {
    dispatch(clearState());
    navigate(name);
  };

  const { data, error, loading, oldUserError } = useSelector(
    (state) => state.account
  );

  const sendCodeByEmail = async (e) => {
    const resultSendCodeEmail = await dispatch(
      sendEmailCode({
        username: e.username,
        consumer: SEND_CODE_CONSUMER.LOGIN,
      })
    );
    if (resultSendCodeEmail?.payload?.success) {
      setMode(MODE.EMAIL);
    } else {
      message.error({
        content:
          resultSendCodeEmail?.payload?.Message ||
          t("general.unHandleErrorMessage"),
        duration: 1,
      });
    }
  };

  const onFinish = async (e) => {
    const accountOldUserCall = dispatch(accountOldUser(e.username));
    try {
      const result = await accountOldUserCall;
      if (result?.payload?.success) {
        const firstLoginStatus = result?.payload?.data?.configs?.find(
          (item) => item.type === USER_CONFIG_TYPE.FirstLogin
        );
        if (firstLoginStatus?.value === "done") {
          message.info({
            content: t("auth.recoverySuccessFullyDone"),
            duration: 5,
          });
          setTimeout(() => {
            navigate("/login");
          }, 4000);
        } else {
          sendCodeByEmail(e);
        }
      } else {
        message.error({
          content:
            result?.payload?.Message || t("general.unHandleErrorMessage"),
          duration: 1,
        });
      }
    } catch (err) {
      const catching = "catch";
    }
  };

  const onSendCode = async (e) => {
    try {
      return await dispatch(
        sendEmailCode({
          username: data?.data?.old_username,
          consumer: SEND_CODE_CONSUMER.LOGIN,
        })
      );
    } catch (err) {
      const catching = "catch";
    }
  };

  const onFinishVerifyEmail = async (e) => {
    const result = await dispatch(
      accountOldVerifyEmail({
        token: e.code,
        username: form.getFieldValue("username"),
        consumer: SEND_CODE_CONSUMER.LOGIN,
      })
    );
    if (result?.payload?.success) {
      setMode(MODE.MOBILE);
      form3.setFieldsValue({
        email: data?.data?.email,
        mobile: "0" + result?.payload?.data?.username,
      });
    } else {
      message.error({
        content: result?.payload?.Message || t("general.unHandleErrorMessage"),
        duration: 1,
      });
    }
  };

  const onFinishVerifymobile = async (e) => {
    const result = await dispatch(
      accountMobileChange({
        user_id: data?.data?.user_id,
        mobile: e.mobile.replaceAll(" ", "").trim(),
      })
    );
    if (result?.payload?.success) {
      const verifyMobile = await dispatch(
        sendSmsCode({
          username: e.mobile.replaceAll(" ", "").trim(),
          consumer: SEND_CODE_CONSUMER.FORGOT_PASSWORD,
        })
      );
      if (verifyMobile.payload.success) {
        setMobileNumber(e.mobile.replaceAll(" ", "").trim());
        setMode(MODE.MOBILE_CONFIRM);
      } else {
        message.error({
          content:
            result?.payload?.Message || t("general.unHandleErrorMessage"),
          duration: 1,
        });
      }
    } else {
      message.error({
        content: result?.payload?.Message || t("general.unHandleErrorMessage"),
        duration: 1,
      });
    }
  };

  return (
    <AuthLayout>
      <>
        <div className="auth__image">
          <div className="image__bg">
            <img src={LoginImage} />
          </div>
          <div className="image__info">
            <img src={LogoImage} className="logo" />
          </div>
        </div>
        {mode === MODE.USERNAME && (
          <Form
            form={form}
            className="auth__form auth__form--login"
            name="basic"
            wrapperCol={{
              span: 24,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Title level={4} className={"auth__title"}>
              {t("auth.login")}
            </Title>
            <div className={"auth__description"}>
              {t("auth.loginDescription")}:
            </div>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: t("required"),
                },
              ]}
              style={{ direction: "ltr" }}
            >
              <Input
                placeholder={t("auth.username")}
                className={"auth__username"}
                size="large"
              />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                span: 16,
              }}
              className="auth__actions"
            >
              <Button
                size="large"
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                {t("general.submitContinue")}
              </Button>
            </Form.Item>
          </Form>
        )}
        {mode === MODE.EMAIL && (
          <Form
            form={form2}
            onFinish={onFinishVerifyEmail}
            className="auth__form"
            name="basic"
          >
            <Title level={4} className={"auth__title"}>
              {t("auth.sendCode")}
            </Title>
            <div className={"auth__description margin-0"}>
              {t("auth.sendCodeDescription")}:
            </div>
            <p>
              <b>{data?.data?.email || "exm**@gmail.com"}</b>
            </p>
            <br />
            <div style={{ direction: "ltr" }}>
              <Form.Item noStyle name="code" rules={[{ required: true }]}>
                <CodeInput form={form2} sendCode={onSendCode} />
              </Form.Item>
            </div>
            <br />
            <Form.Item
              wrapperCol={{
                // offset: 8,
                span: 16,
              }}
              className="auth__actions"
            >
              <Space>
                <Button
                  loading={loading}
                  size="large"
                  type="primary"
                  htmlType="submit"
                >
                  {t("auth.accept")}
                </Button>

                <Button
                  onClick={() => setMode(MODE.USERNAME)}
                  size="large"
                  type="default"
                >
                  {t("auth.back")}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        )}

        {mode === MODE.MOBILE && (
          <Form
            form={form3}
            onFinish={onFinishVerifymobile}
            className="auth__form"
            name="basic"
          >
            <Title level={4} className={"auth__title"}>
              {t("auth.mobileVerify")}
            </Title>
            <p className={"auth__description"}>{t("auth.mobileDescription")}</p>
            <br />
            <div>
              <Form.Item
                name="email"
                style={{ direction: "ltr" }}
                rules={[
                  {
                    required: true,
                    message: t("auth.emailError"),
                  },
                ]}
              >
                <Input
                  placeholder={t("user.email")}
                  disabled
                  style={{ direction: "rtl" }}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="mobile"
                style={{ direction: "rtl" }}
                rules={[
                  {
                    required: true,
                    message: t("auth.phoneError"),
                  },
                ]}
              >
                <MaskInput
                  mask={MASK_MOBILE_INPUT}
                  placeholder={t("auth.phone")}
                  style={{ direction: "ltr" }}
                  placeholderChar="-"
                  size="large"
                />
              </Form.Item>
            </div>
            <br />

            <Form.Item
              wrapperCol={{
                // offset: 8,
                span: 16,
              }}
              className="auth__actions"
            >
              <Button
                loading={loading}
                size="large"
                type="primary"
                htmlType="submit"
              >
                {t("auth.accept")}
              </Button>
            </Form.Item>
          </Form>
        )}

        {mode === MODE.MOBILE_CONFIRM && (
          <MobileConfirm mobileNumber={mobileNumber} setMode={setMode} />
        )}

        {mode === MODE.RESET_PASSWORD && (
          <ResetPasswordComponent
            mobileNumber={mobileNumber}
            setMode={setMode}
          />
        )}
      </>
    </AuthLayout>
  );
};

export default RecoveryAccount;
