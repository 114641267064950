import {
  FileDoneOutlined as IconAccess,
  KeyOutlined as IconPv,
} from "@ant-design/icons";
import { Button, Checkbox, Form, Spin, message } from "antd";
import { useForm } from "antd/es/form/Form";
import PropTypes from "prop-types";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { accountGetPrivileges } from "@/app/local/accountSlice";
import {
  assignRole,
  getPrivilegesUser,
  getRoles,
  getUserRole,
  unAssignRole,
  updatePrivilegesUser,
} from "@/app/local/adminUserSlice";
import { Privileges } from "@/utils/constants/accessCenter";
import { hasAccess } from "@/utils/helper";
import { useAccount } from "@/utils/hooks/useAuth";

import PrivilegesEdit from "../settings/PrivilegesEdit";
import "./styles.less";

const CheckBoxCell = (props) => {
  const { name, label } = props;
  return (
    <>
      <Form.Item name={name} valuePropName="checked" noStyle>
        <Checkbox disabled={props.disabled}>{label}</Checkbox>
      </Form.Item>
    </>
  );
};

CheckBoxCell.propTypes = {
  name: PropTypes.any,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

const AccessManagement = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const [form] = useForm();
  const [rolesItems, setRolesItems] = useState([]);
  const { privileges } = useSelector((state) => state.account);
  const { user } = useAccount();

  const accessEdit = hasAccess(user, [
    Privileges.SETTING.ACCESS_MGM_EDIT_USER_ACCESS,
  ]);

  const dispatch = useDispatch();
  const userId = data?.data?.user_id;
  const userType = data?.data?.user_type;

  const { roles, rolesLoading, privilegesLoading } = useSelector(
    (state) => state.adminUser
  );

  useEffect(() => {
    dispatch(getRoles());
    dispatch(accountGetPrivileges());

    const getUserRoleAsync = async () => {
      const result = await dispatch(getUserRole(userId));
      if (result?.payload?.success) {
        const assigned = {};
        result.payload.data?.forEach((role) => {
          assigned[role.role_id] = true;
        });
        form.setFieldsValue({
          role: assigned,
        });
      }
    };
    getUserRoleAsync();

    const getUserPrivilegesAsync = async () => {
      const result = await dispatch(getPrivilegesUser(userId));
      if (result?.payload?.success) {
        const assigned = result.payload.data?.map((role) => {
          return role.privilege_id;
        });
        form.setFieldsValue({
          privileges: assigned,
        });
      }
    };
    getUserPrivilegesAsync();
  }, []);

  useEffect(() => {
    if (roles?.data?.length && userType) {
      let items;
      items = roles?.data?.filter((item) => item.type === userType);
      setRolesItems(items);
    }
  }, [roles, userType]);

  const onValuesChange = async (e) => {
    let result;
    if (e.role) {
      message.loading({
        content: t("general.pleaseWaitMessage"),
        duration: 10,
        key: "assign",
      });
      const [roleId] = Object.keys(e.role);
      const status = e.role[roleId];
      if (status) {
        result = await dispatch(
          assignRole({
            userId: userId,
            roleId: roleId,
          })
        );
      } else {
        result = await dispatch(
          unAssignRole({
            userId: userId,
            roleId: roleId,
          })
        );
      }
      if (result?.payload?.success) {
        message.success({
          content: t("general.successFullyDone"),
          duration: 1,
          key: "assign",
        });
      } else {
        message.error({
          content:
            result?.payload?.Message || t("general.unHandleErrorMessage"),
          key: "assign",
          duration: 1,
        });
      }
    }
  };

  const onFinish = async (e) => {
    if (e.privileges) {
      const result = await dispatch(
        updatePrivilegesUser({
          userId: userId,
          privileges: e.privileges.map((id) => {
            return {
              privilege_id: id,
              has_access: true,
            };
          }),
        })
      );
      if (result?.payload?.success) {
        message.success({
          content: t("general.successFullyDone"),
          duration: 1,
          key: "assign",
        });
      } else {
        message.error({
          content:
            result?.payload?.Message || t("general.unHandleErrorMessage"),
          key: "assign",
          duration: 1,
        });
      }
    }
  };

  return (
    <div className="user-role">
      <Form form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
        <div>
          <div className="role_title">
            <IconAccess className="icon" /> {t("user.roleAssigned")}
          </div>
          <Spin spinning={rolesLoading}>
            <div className="user-role__list">
              {rolesItems?.map((role, i) => {
                return (
                  <div className="role_item" key={i}>
                    <CheckBoxCell
                      name={["role", role.role_id]}
                      label={role.role_name}
                      disabled={!accessEdit}
                    />
                  </div>
                );
              })}
            </div>
          </Spin>
        </div>
        <br />

        <Spin spinning={rolesLoading}>
          <div className="user-role__privileges">
            <div className="role_title">
              <IconPv className="icon" /> {t("user.privileges")}
            </div>
            <Form.Item name={"privileges"}>
              <PrivilegesEdit form={form} />
            </Form.Item>

            <Form.Item>
              <Button
                loading={privilegesLoading}
                size="large"
                type="primary"
                htmlType="submit"
              >
                {t("general.save")}
              </Button>
            </Form.Item>
          </div>
        </Spin>
      </Form>
    </div>
  );
};

AccessManagement.propTypes = {
  data: PropTypes.any,
};

export default AccessManagement;
