import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import Modal from "@material-ui/core/Modal";
import { PropTypes } from "prop-types";
import CustomizedButton from "./CustomizedButton";
import clsx from "clsx";
import {
  containedLargeDefaultBtn,
  containedLargePrimaryBtn,
} from "../app/helper";

const BaseModal = (props) => {
  const {
    modalStyle,
    titleIcon,
    titleIconLeft,
    titleText,
    headerStyle,
    titleStyle,
    closeBtnStyle,
    onBackdropClick,
    onClose,
    isOpen,
    children,
    onCancelBtnClick,
    onMainBtnClick,
    mainBtnText,
    cancelBtnText,
    mainBtnStyle = containedLargePrimaryBtn,
    modalBodyStyle,
    mainBtnProps,
    isMainBtnDisabled,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} onClose={onBackdropClick}>
      <div className={clsx(classes.root, modalStyle)}>
        <div className={clsx(classes.modalHeader, headerStyle)}>
          {titleIcon ?? null}
          <div className={clsx(classes.headerTitle, titleStyle)}>
            {t(titleText)}
          </div>
          {titleIconLeft ?? null}
          <IconButton
            className={clsx(classes.modalCloseBut, closeBtnStyle)}
            onClick={onClose}
          >
            <CloseOutlinedIcon fontSize="small" />
          </IconButton>
        </div>
        <div className={clsx(classes.modalBody, modalBodyStyle)}>
          {children}
          <div className={classes.actions}>
            {cancelBtnText && (
              <div>
                <CustomizedButton
                  buttonProperties={containedLargeDefaultBtn}
                  onClick={onCancelBtnClick}
                >
                  {t(cancelBtnText)}
                </CustomizedButton>
              </div>
            )}
            {mainBtnText && (
              <div>
                <CustomizedButton
                  buttonProperties={mainBtnStyle}
                  onClick={onMainBtnClick}
                  {...mainBtnProps}
                  disabled={isMainBtnDisabled}
                >
                  {t(mainBtnText)}
                </CustomizedButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BaseModal;

BaseModal.propTypes = {
  isOpen: PropTypes.bool,
  isMainBtnDisabled: PropTypes.bool,
  onClose: PropTypes.func,
  onBackdropClick: PropTypes.func,
  onCancelBtnClick: PropTypes.func,
  onMainBtnClick: PropTypes.func,
  children: PropTypes.node,
  headerBackground: PropTypes.string,
  titleIcon: PropTypes.node,
  titleIconLeft: PropTypes.node,
  titleText: PropTypes.string,
  mainBtnProps: PropTypes.object,
  closeBtnHoverColor: PropTypes.string,
  mainBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  modalStyle: PropTypes.string,
  closeBtnStyle: PropTypes.string,
  modalBodyStyle: PropTypes.string,
  headerStyle: PropTypes.string,
  titleStyle: PropTypes.string,
  mainBtnStyle: PropTypes.object,
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: theme.spacing(2),
    top: "50%",
    right: "50%",
    maxHeight: "90vh",
    transform: "translate(50%, -50%)",
    textAlign: "left",
    margin: "auto",
    outline: "none",
    minWidth: 340,
    width: "max-content",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    padding: `${theme.spacing(2.5)}px ${theme.spacing(5)}px`,
    height: theme.spacing(9),
    position: "relative",
    borderRadius: `${theme.spacing(2)}px ${theme.spacing(2)}px 0px 0px`,
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.12)",
    backgroundColor: theme.palette.common.white,
  },

  actions: {
    display: "flex",
    justifyContent: "end",
    gap: theme.spacing(2.5),
  },
  headerTitle: {
    color: theme.palette.secondary.dark1,
    margin: `${theme.spacing(0)}px ${theme.spacing(2.5)}px`,
    ...theme.typography.title1.bold,
  },
  modalCloseBut: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(3),
    color: theme.palette.grey[500],
    "&:hover": {
      cursor: "pointer",
      transition: "background 0.5s ease",
      background: theme.palette.grey[200],
    },
  },
  modalBody: {
    padding: `${theme.spacing(3.5)}px ${theme.spacing(5)}px`,
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
  },
  bodyTitle: {
    ...theme.typography.title2.bold,
    marginBottom: theme.spacing(3),
  },
  listTitle: { ...theme.typography.body2.bold, marginBottom: theme.spacing(1) },
  listItems: { ...theme.typography.body2.regular, paddingLeft: "20px" },
  actionBtn: {
    margin: `0px ${theme.spacing(0)}px 0px ${theme.spacing(2)}px`,
    textAlign: "end",
  },
}));
