import React from "react";
// import Button from "@material-ui/core/Button";
import { AccessAlarm } from "@material-ui/icons";
import ReactDOM from "react-dom";

function TestCom() {
  return (
    <>
      <AccessAlarm />
    </>
  );
}

// ReactDOM.render(
//   <React.StrictMode>
//     <Button variant="contained" color="primary">
//       Hello World
//     </Button>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

export default TestCom;
