import { t } from "i18next";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const RocketChat = () => {
  const { profile } = useSelector((state) => state.account);

  useEffect(() => {
    (function (w, d, s, u) {
      w.RocketChat = function (c) {
        w.RocketChat._.push(c);
      };
      w.RocketChat._ = [];
      w.RocketChat.url = u;
      const isExist = d.querySelector(".rocketchat-widget");
      var h = d.getElementsByTagName(s)[0],
        j = d.createElement(s);
      j.async = true;

      j.src =
        "https://chat.affilio.ir/livechat/rocketchat-livechat.min.js?_=201903270000";
      h.parentNode.insertBefore(j, h);
    })(window, document, "script", "https://chat.affilio.ir/livechat");
  }, []);

  // useEffect(() => {
  //   (function () {
  //     var d = document;
  //     var s = d.createElement("script");
  //     s.src = "https://sdk-ssp-stage.affilio.ir/samar-sdk.min.js";
  //     s.id = "samar-sdk";
  //     s.async = 1;
  //     var h = document.getElementById("crsip-tag");
  //     if (!h) {
  //       d.getElementsByTagName("head")[0].appendChild(s);
  //     }
  //   })();
  // }, []);

  setTimeout(() => {
    try {
      window.RocketChat.livechat.initialize({
        theme: {
          color: "#e46036",
          fontColor: "#FFFFFF",
          iconColor: "#1d74f5",
          title: t("general.chatTitle"),
          offlineTitle: t("general.chatTitleOffline"),
        },
        // department: "sales",
        // guestToken: "FHwaLnp8fzjMupSAj",
        language: "fa",
      });

      const userDetail = profile?.userDetail?.data;

      if (userDetail) {
        const userId = profile?.data?.user_id;
        const email = userDetail?.email;
        const mobile = userDetail?.user?.mobile;
        const name = `${userDetail?.first_name} ${userDetail?.last_name} ${userId}`;
        window.RocketChat.livechat.setGuestEmail(email);
        window.RocketChat.livechat.setGuestName(name);
        window.RocketChat.livechat.setCustomField("Phone", mobile);
      }
    } catch (e) {
      const cashing = "";
    }
  }, 1000);

  // return (
  //   <div>
  //     <div smr-id="83" smr-type="native_fixed"></div>
  //   </div>
  // );

  return null;
};

export default RocketChat;
