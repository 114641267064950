import { Form, Input } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./inputPassword.less";

const PASSWORD_PATTERN =
  '^(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!#$%&? "])[a-zA-Z0-9!#$@%&?]{8,20}$';

export const InputPassword = (props) => {
  const { t, name } = useTranslation();

  return (
    <Form.Item
      // label="Password"
      name={props.name || "password"}
      className="auth__password"
      rules={[
        {
          required: true,
          pattern: new RegExp(PASSWORD_PATTERN),
        },
      ]}
      help={t("auth.helpPassword")}
      hasFeedback
    >
      <Input.Password
        size={props.size || "large"}
        placeholder={t(props.placeholder || "auth.password")}
      />
    </Form.Item>
  );
};

InputPassword.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
};

export default InputPassword;
