import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import { clsx } from "clsx";

const CustomizedSkeleton = ({
  animation,
  variant,
  width,
  height,
  className,
  style,
}) => {
  const classes = useStyles();
  return (
    <Skeleton
      animation={animation}
      variant={variant}
      height={height}
      width={width}
      className={clsx(classes.skeleton, className)}
      style={style}
    />
  );
};

CustomizedSkeleton.propTypes = {
  animation: PropTypes.string,
  variant: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.object,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

export default CustomizedSkeleton;
const useStyles = makeStyles(() => {
  return {
    skeleton: {},
  };
});
