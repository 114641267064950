import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import PropTypes from "prop-types";

const CustomAlert = ({
  severity,
  message,
  fontClass = "caption1Reg",
  action,
  style,
  className,
}) => {
  const classes = useStyles();
  return (
    <Alert
      className={clsx(classes.root, classes[fontClass], className)}
      severity={severity}
      action={action}
      style={style}
    >
      {message}
    </Alert>
  );
};

CustomAlert.propTypes = {
  severity: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  fontClass: PropTypes.string,
  className: PropTypes.string,
  action: PropTypes.object,
  style: PropTypes.object,
};

export default CustomAlert;
const useStyles = makeStyles((theme) => {
  return {
    root: {
      border: "none",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    caption1Reg: {
      ...theme.typography.caption1.regular,
    },
    body2Reg: {
      ...theme.typography.body2.regular,
    },
  };
});
