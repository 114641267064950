import MaskedInput from "antd-mask-input";
import PropTypes from "prop-types";
import React from "react";

import { LANGUAGE } from "@/utils/constants/apiConstants";
import { getLocalLang } from "@/utils/helper";

const MaskInput = (props) => {
  const lang = getLocalLang();

  return (
    <MaskedInput
      size="large"
      style={
        lang === LANGUAGE.PERSIAN
          ? {
              textAlign: "right",
            }
          : {
              textAlign: "left",
            }
      }
      autoFocus
      placeholder="placeholder"
      mask="0000 000 0000"
      maskOptions={{
        placeholderChar: "-",
      }}
      {...props}
    />
  );
};

MaskInput.propTypes = {
  name: PropTypes.string,
  mask: PropTypes.string,
  rules: PropTypes.any,
};

export default MaskInput;
