import { Card } from "antd";
import { useTranslation } from "react-i18next";

import ToolbarChart from "./ToolbarChart";

const ChartPerformanceMedia = () => {
  const { t } = useTranslation("ssp");
  return (
    <div>
      <Card title={t("dashboard.performance_media")}>
        <ToolbarChart />
      </Card>
    </div>
  );
};

export default ChartPerformanceMedia;
