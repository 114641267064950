import PropTypes from "prop-types";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const ErrorLayout = ({ children }) => {
  const loc = useLocation();
  const nav = useNavigate();

  useEffect(() => {
    const isBeforeLogin = localStorage.getItem("lts-login");
    if (!isBeforeLogin) loc.pathname === "/" && nav("/login");
  }, []);

  return (
    <div className="public__page">
      <Outlet />
    </div>
  );
};

ErrorLayout.propTypes = {
  children: PropTypes.element,
};

export default ErrorLayout;
