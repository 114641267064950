import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ENDPOINTS } from "@/utils/constants/apiConstants";
import { clearObject } from "@/utils/utils";

import { baseAPI } from "../services/fetchers";

export const getCampaignListAsync = createAsyncThunk(
  "link/getCampaignListAsync",
  async (data) => {
    try {
      const body = {
        page: 1,
        page_size: 10,
        sort_orientation: "1",
      };
      const data_ = clearObject(data);
      const body_ = Object.assign({}, body, data_);
      const end_ = ENDPOINTS.CAMPAIGN_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getLinksGroupListAsync = createAsyncThunk(
  "link/getLinksGroupListAsync",
  async (data) => {
    try {
      const body = {
        page: 1,
        page_size: 10,
        // sort_field: "string",
        // search_text: "string",
        is_pagination: true,
        sort_orientation: 1,
      };
      const body_ = Object.assign({}, body, data?.body);
      const end_ = ENDPOINTS.LINK_LIST_GROUP;
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const addCampaignAsync = createAsyncThunk(
  "Campaign/addCampaignAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.CAMPAIGN_ADD;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const updateCampaignStatusAsync = createAsyncThunk(
  "Campaign/updateCampaignStatusAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.CAMPAIGN_UPDATE_STATUS.replace(
        "{campaignId}",
        data.campaignId
      ).replace("{status}", data.status);

      const response = await baseAPI({
        endPoint: end_,
        // body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const updateCampaignAsync = createAsyncThunk(
  "campaign/updateCampaignAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.CAMPAIGN_UPDATE;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const assignCampaignToWidgetAsync = createAsyncThunk(
  "campaign/assignCampaignAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.CAMPAIGN_ASSIGN_TO_SMART;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const assignCampaignToBannerAsync = createAsyncThunk(
  "campaign/assignCampaignToBannerAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.CAMPAIGN_ASSIGN_TO_BANNER;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getCampaignDetailAsync = createAsyncThunk(
  "Campaign/getCampaignDetailAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.CAMPAIGN_DETAIL.replace("{campaignId}", data);
      const response = await baseAPI({
        endPoint: end_,
        // body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getLinkDetailAsync = createAsyncThunk(
  "link/getLinkDetailAsync",
  async (id) => {
    try {
      const end_ = ENDPOINTS.LINK_DETAIL.replace("{linkId}", id);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const addMemberCampaignAsync = createAsyncThunk(
  "campaign/addMemberCampaignAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.CAMPAIGN_MEMBER_ADD.replace(
        "{campaignId}",
        data.campId
      );
      const response = await baseAPI({
        endPoint: end_,
        body: data.body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getCampaignSmartListAsync = createAsyncThunk(
  "campaign/getCampaignSmartListAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.CAMPAIGN_SMART_LIST.replace("{campaignId}", data);
      const response = await baseAPI({
        endPoint: end_,
        body: data.body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getCampaignBannerListAsync = createAsyncThunk(
  "campaign/getCampaignBannerListAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.CAMPAIGN_BANNER_LIST.replace("{campaignId}", data);
      const response = await baseAPI({
        endPoint: end_,
        body: data.body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const deleteCampaignWidgetAsync = createAsyncThunk(
  "campaign/deleteCampaignWidgetAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.CAMPAIGN_REMOVE_SMART;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const deleteCampaignBannerAsync = createAsyncThunk(
  "campaign/deleteCampaignBannerAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.CAMPAIGN_REMOVE_BANNER;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const campaignSlice = createSlice({
  name: "campaign",
  initialState: {
    campaigns: null,
    campaignsLoading: false,
    campaignsError: null,

    addCampaignLoading: false,
    addCampaignError: null,
    addCampaignData: null,

    campaignLoading: false,
    campaignError: null,
    campaign: null,

    campaignWidgetsLoading: false,
    campaignWidgetsError: null,
    campaignWidgets: null,

    campaignBannerLoading: false,
    campaignBannerError: null,
    campaignBanner: null,

    linkGroupListLoading: false,
    linkGroupError: null,
    linkGroupData: null,

    loading: false,
    error: null,
  },
  reducers: {
    clearState: (state) => {
      state.addCampaignLoading = false;
      state.addCampaignError = false;
      state.addCampaignData = null;

      state.campaignLoading = false;
      state.campaignError = null;
      state.campaign = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCampaignListAsync.pending, (state, action) => {
      state.campaignsLoading = true;
      state.campaignsError = null;
    });
    builder.addCase(getCampaignListAsync.rejected, (state, action) => {
      state.campaignsLoading = false;
      state.campaignsError = action.error;
    });
    builder.addCase(getCampaignListAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.campaigns = action.payload;
      }
      state.campaignsLoading = false;
      state.campaignsError = null;
    });

    builder.addCase(addCampaignAsync.pending, (state, action) => {
      state.addCampaignLoading = true;
      state.addCampaignError = null;
    });
    builder.addCase(addCampaignAsync.rejected, (state, action) => {
      state.addCampaignLoading = false;
      state.addCampaignError = action.error;
    });
    builder.addCase(addCampaignAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.addCampaignData = action.payload;
      }
      state.addCampaignLoading = false;
      state.addCampaignError = null;
    });

    builder.addCase(getCampaignDetailAsync.pending, (state, action) => {
      state.campaignLoading = true;
      state.campaignError = null;
    });
    builder.addCase(getCampaignDetailAsync.rejected, (state, action) => {
      state.campaignLoading = false;
      state.campaignError = action.error;
    });
    builder.addCase(getCampaignDetailAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.campaign = action.payload;
      }
      state.campaignLoading = false;
      state.campaignError = null;
    });

    builder.addCase(getLinksGroupListAsync.pending, (state, action) => {
      state.linkGroupListLoading = true;
      state.linkGroupError = null;
    });
    builder.addCase(getLinksGroupListAsync.rejected, (state, action) => {
      state.linkGroupListLoading = false;
      state.linkGroupError = action.error;
    });
    builder.addCase(getLinksGroupListAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.linkGroupData = action.payload;
      }
      state.linkGroupListLoading = false;
      state.linkGroupError = null;
    });

    builder.addCase(getCampaignSmartListAsync.pending, (state, action) => {
      state.campaignWidgetsLoading = true;
      state.campaignWidgetsError = null;
    });
    builder.addCase(getCampaignSmartListAsync.rejected, (state, action) => {
      state.campaignWidgetsLoading = false;
      state.campaignWidgetsError = action.error;
    });
    builder.addCase(getCampaignSmartListAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.campaignWidgets = action.payload;
      }
      state.campaignWidgetsLoading = false;
      state.campaignWidgetsError = null;
    });

    builder.addCase(getCampaignBannerListAsync.pending, (state, action) => {
      state.campaignBannerLoading = true;
      state.campaignBannerError = null;
    });
    builder.addCase(getCampaignBannerListAsync.rejected, (state, action) => {
      state.campaignBannerLoading = false;
      state.campaignBannerError = action.error;
    });
    builder.addCase(getCampaignBannerListAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.campaignBanner = action.payload;
      }
      state.campaignBannerLoading = false;
      state.campaignBannerError = null;
    });
  },
});

export const getCampaignList = (data) => async (dispatch) => {
  return dispatch(getCampaignListAsync(data));
};

export const getLinksGroupList = (data) => async (dispatch) => {
  return dispatch(getLinksGroupListAsync(data));
};

export const addCampaign = (data) => async (dispatch) => {
  return dispatch(addCampaignAsync(data));
};

export const updateCampaignStatus = (data) => async (dispatch) => {
  return dispatch(updateCampaignStatusAsync(data));
};

export const updateCampaign = (data) => async (dispatch) => {
  return dispatch(updateCampaignAsync(data));
};

export const assignCampaignToWidget = (data) => async (dispatch) => {
  return dispatch(assignCampaignToWidgetAsync(data));
};

export const assignCampaignToBanner = (data) => async (dispatch) => {
  return dispatch(assignCampaignToBannerAsync(data));
};

export const getCampaignDetail = (data) => async (dispatch) => {
  return dispatch(getCampaignDetailAsync(data));
};

export const addMemberCampaign = (data) => async (dispatch) => {
  return dispatch(addMemberCampaignAsync(data));
};

export const getCampaignSmartList = (data) => async (dispatch) => {
  return dispatch(getCampaignSmartListAsync(data));
};

export const getCampaignBannerList = (data) => async (dispatch) => {
  return dispatch(getCampaignBannerListAsync(data));
};

export const deleteCampaignWidget = (data) => async (dispatch) => {
  return dispatch(deleteCampaignWidgetAsync(data));
};

export const deleteCampaignBanner = (data) => async (dispatch) => {
  return dispatch(deleteCampaignBannerAsync(data));
};

export const { clearState } = campaignSlice.actions;

export default campaignSlice.reducer;
