export const COLORS = {
  DASHBOARD_RED: "#FF7295",
  DASHBOARD_GREEN: "#28B8B0",
  DASHBOARD_PURPLE: "#A673E9",
  DASHBOARD_PINK: "#F19CB9",
  DASHBOARD_BLUE: "#468BFF",
  DASHBOARD_ORANGE: "#FF8165",
  DASHBOARD_YELLOW: "#F4B334",
  DASHBOARD_ORANGE_LIGHTEN: "#FFE4DC",
  DASHBOARD_GREEN_LIGHTEN: "#D5F1F0",
  DASHBOARD_FRENCH_PINK: "#FF7295",
  DASHBOARD_CARMINE_PINK: "#EB4C4C",
};
