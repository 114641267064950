import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import "./FormGetCodePlace.less";
import CodePlace from "./placeSteps/CodePlace";

const FormGetCodePlace = (props) => {
  const { detail } = props;
  const { t } = useTranslation("ssp");

  const hideModal = () => {
    props.setOpen();
  };

  return (
    <Modal
      className="form-get-code"
      title={t("formGetCode")}
      open={true}
      footer={false}
      cancelButtonProps={{
        hidden: true,
      }}
      onCancel={hideModal}
      onOk={hideModal}
      key={"get-code"}
    >
      <CodePlace
        mode={"light"}
        id={detail?.inventory_id}
        type={detail?.type}
        format={detail?.format_type}
      />
    </Modal>
  );
};

FormGetCodePlace.propTypes = {
  setOpen: "",
  detail: "",
};
export default FormGetCodePlace;
