import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { accountDetailSpecific, sendSmsCode } from "@/app/local/accountSlice";
import LoginImage from "@/assets/images/login.svg";
import LogoImage from "@/assets/images/logo-white.svg";
import MobileConfirm from "@/components/recovery/MobileConfirm";
import ResetPasswordComponent from "@/components/recovery/ResetPasswordComponent";
import { SEND_CODE_CONSUMER } from "@/utils/constants/apiConstants";

import AuthLayout from "./authLayout";

const MODE = {
  MOBILE_CONFIRM: "MOBILE_CONFIRM",
  RESET_PASSWORD: "RESET_PASSWORD",
};
const ResetPassword = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(MODE.MOBILE_CONFIRM);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, dataAccountSpecific } = useSelector((state) => state.account);

  const navigateTo = (name) => {
    navigate(name);
  };

  useEffect(() => {
    if (!data) {
      dispatch(accountDetailSpecific());
    }
  }, [data]);

  useEffect(() => {
    if (dataAccountSpecific?.data) {
      const verifyMobile = dispatch(
        sendSmsCode({
          username: dataAccountSpecific?.data?.mobile
            .replaceAll(" ", "")
            .trim(),
          consumer: SEND_CODE_CONSUMER.FORGOT_PASSWORD,
        })
      );
    }
  }, []);

  const mobileNumber = _.get(dataAccountSpecific, "data.mobile");

  return (
    <AuthLayout>
      <>
        <div className="auth__image">
          <div className="image__bg">
            <img src={LoginImage} />
          </div>
          <div className="image__info">
            <img src={LogoImage} className="logo" />
          </div>
        </div>
        {mode === MODE.MOBILE_CONFIRM && (
          <MobileConfirm mobileNumber={mobileNumber} setMode={setMode} />
        )}
        {mode === MODE.RESET_PASSWORD && (
          <ResetPasswordComponent mobileNumber={mobileNumber} />
        )}
      </>
    </AuthLayout>
  );
};

export default ResetPassword;
