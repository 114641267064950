import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ENDPOINTS } from "@/utils/constants/apiConstants";

import { baseAPI } from "../services/fetchers";

export const getStandardSizeListAsync = createAsyncThunk(
  "link/getLinksListAsync",
  async (data) => {
    try {
      const body = {
        page: 1,
        page_size: 20,
        sort_orientation: "1",
      };
      const end_ = ENDPOINTS.SIZE_GET_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const standardSizeSlice = createSlice({
  name: "standardSize",
  initialState: {
    sizes: null,
    sizesLoading: false,
    sizesError: null,

    loading: false,
    error: null,
  },
  reducers: {
    clearState: (state) => {
      state.addLinkLoading = false;
      state.addLinkError = false;
      state.addLinkData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStandardSizeListAsync.pending, (state, action) => {
      state.sizesLoading = true;
      state.sizesError = null;
    });
    builder.addCase(getStandardSizeListAsync.rejected, (state, action) => {
      state.sizesLoading = false;
      state.sizesError = action.error;
    });
    builder.addCase(getStandardSizeListAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.sizes = action.payload;
      }
      state.sizesLoading = false;
      state.sizesError = null;
    });
  },
});

export const getStandardSizeList = (data) => async (dispatch) => {
  return dispatch(getStandardSizeListAsync(data));
};

export const { clearState } = standardSizeSlice.actions;

export default standardSizeSlice.reducer;
