import { InputRangePicker as IRP } from "jalaali-react-date-picker";
import { useTranslation } from "react-i18next";

const InputRangePicker = (props) => {
  const { t } = useTranslation();
  return (
    <IRP
      className="salam"
      customColors={{ text: "#7d8793", dayLabelBackground: "#2f415d" }}
      wrapperClassName="jalaali-date-range-input"
      {...props}
      placeholder={
        // eslint-disable-next-line react/prop-types
        props?.placeholder || [t("general.dateFrom"), t("general.dateTo")]
      }
    />
  );
};

export default InputRangePicker;
