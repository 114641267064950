import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// import { baseAPI } from "@/app/services/baseApi";
import { ENDPOINTS } from "@/utils/constants/apiConstants";

import { baseAPI } from "../services/fetchers";

export const setLegalAsync = createAsyncThunk(
  "publisher/setLegal",
  async (data) => {
    try {
      const { status } = data;
      let end_ = ENDPOINTS.PUBLISHER_SET_LEGAL_STATUS;
      end_ = end_.replace("{status}", status);

      const response = await baseAPI({
        endPoint: end_,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setPersonalInfoAsync = createAsyncThunk(
  "publisher/setPersonalInfo",
  async (data) => {
    try {
      let end_ = ENDPOINTS.PUBLISHER_SET_PERSONAL_INFO;

      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setContactInfoAsync = createAsyncThunk(
  "publisher/setContactInfo",
  async (data) => {
    try {
      let end_ = ENDPOINTS.PUBLISHER_SET_CONTACT_INFO;

      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setPaymentInfoAsync = createAsyncThunk(
  "publisher/setPaymentInfo",
  async (data) => {
    try {
      let end_ = ENDPOINTS.PUBLISHER_SET_BANK_ACCOUNT_INFO;

      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setVatInfoAsync = createAsyncThunk(
  "publisher/setVatInfoAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.PUBLISHER_SET_VAT_INFO;

      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setCompanyInfoAsync = createAsyncThunk(
  "publisher/setCompanyInfoAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.PUBLISHER_SET_COMPANY_INFO;

      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const callApiAsync = createAsyncThunk(
  "publisher/callApiAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.PUBLISHER_CALL_BACK_INFO;

      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const publisherHardDeleteAsync = createAsyncThunk(
  "publisher/publisherHardDeleteAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.PUBLISHER_HARD_DELETE;

      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

const slice = createSlice({
  name: "publisher",
  initialState: {
    legal: null,
    stepPersonal: null,
    stepContact: null,
    stepPayment: null,
    stepVat: null,
    detail: null,
    loading: false,
    error: null,
    errorContact: null,
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      state.error = null;
      state.errorContact = null;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(setLegalAsync.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.success) {
        state.legal = action.payload;
      } else {
        state.error = action.payload;
      }
    });

    builder.addCase(setPersonalInfoAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(setPersonalInfoAsync.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.success) {
        state.stepPersonal = action.payload;
      } else {
        state.error = action.payload;
      }
    });
    builder.addCase(setPersonalInfoAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(setContactInfoAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(setContactInfoAsync.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.success) {
        state.stepContact = action.payload;
      } else {
        state.errorContact = action.payload;
      }
    });
    builder.addCase(setContactInfoAsync.rejected, (state, action) => {
      state.loading = false;
      state.errorContact = action.error;
    });

    builder.addCase(setPaymentInfoAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(setPaymentInfoAsync.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.success) {
        state.stepPayment = action.payload;
      } else {
        state.error = action.payload;
      }
    });
    builder.addCase(setPaymentInfoAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(setVatInfoAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(setVatInfoAsync.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.success) {
        state.stepVat = action.payload;
      } else {
        state.error = action.payload;
      }
    });
    builder.addCase(setVatInfoAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(setCompanyInfoAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(setCompanyInfoAsync.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.success) {
        state.stepCompany = action.payload;
      } else {
        state.error = action.payload;
      }
    });
    builder.addCase(setCompanyInfoAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { updateUser, clearState } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state) => state.user.profile;

export const publisherSetLegal = (data) => async (dispatch) => {
  return dispatch(setLegalAsync(data));
};

export const publisherSetPersonalInfo = (data) => async (dispatch) => {
  return dispatch(setPersonalInfoAsync(data));
};

export const publisherSetContactInfo = (data) => async (dispatch) => {
  return dispatch(setContactInfoAsync(data));
};

export const publisherSetPaymentInfo = (data) => async (dispatch) => {
  return dispatch(setPaymentInfoAsync(data));
};

export const publisherVatInfo = (data) => async (dispatch) => {
  return dispatch(setVatInfoAsync(data));
};

export const publisherSetCompanyInfo = (data) => async (dispatch) => {
  return dispatch(setCompanyInfoAsync(data));
};

export const callApi = (data) => async (dispatch) => {
  return dispatch(callApiAsync(data));
};

export const publisherHardDelete = (data) => async (dispatch) => {
  return dispatch(publisherHardDeleteAsync(data));
};
