import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ENDPOINTS } from "@/utils/constants/apiConstants";
import { clearObject } from "@/utils/utils";

import { baseAPI } from "../services/fetchers";

export const getMediaListAsync = createAsyncThunk(
  "media/getMediaListAsync",
  async (data) => {
    try {
      const defaultBody = {
        page: 1,
        page_size: 10,
        is_pagination: true,
        sort_orientation: "1",
      };
      const data_ = clearObject(data);
      const body_ = Object.assign({}, defaultBody, data_);
      const end_ = ENDPOINTS.MEDIA_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const mediaAddAsync = createAsyncThunk(
  "media/mediaAddAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.MEDIA_ADD;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const updateOwnerShipStatusAsync = createAsyncThunk(
  "media/updateOwnerShipStatus",
  async (data) => {
    try {
      const end_ = ENDPOINTS.MEDIA_UPDATE_OWNERSHIP_STATUS;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const updateContentStatusAsync = createAsyncThunk(
  "media/updateContentStatus",
  async (data) => {
    try {
      const end_ = ENDPOINTS.MEDIA_UPDATE_CONTENT_STATUS;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getMediaDetailAsync = createAsyncThunk(
  "media/getMediaDetailAsync",
  async (mId) => {
    try {
      const end_ = ENDPOINTS.MEDIA_GET_DETAIL.replace("{id}", mId);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const updateMediaAsync = createAsyncThunk(
  "media/updateUpdateAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.MEDIA_UPDATE;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const updateTestModeStatusAsync = createAsyncThunk(
  "media/updateTestModeStatusAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.MEDIA_UPDATE_TEST_MODE_STATUS;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const addWebsiteToMediaAsync = createAsyncThunk(
  "media/addWebsiteToMediaAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.MEDIA_ADD_WEBSITE_TO_MEDIA.replace(
        "{mediaId}",
        data.mediaId
      );
      const response = await baseAPI({
        endPoint: end_,
        body: data.websites,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const removeWebSiteFromMediaAsync = createAsyncThunk(
  "media/removeWebSiteFromMediaAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.MEDIA_REMOVE_WEBSITE_FROM_MEDIA.replace(
        "{mediaId}",
        data.mediaId
      );
      const response = await baseAPI({
        endPoint: end_,
        body: [data.website],
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const deleteMediaAsync = createAsyncThunk(
  "media/deleteMediaAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.MEDIA_DELETE.replace("{id}", data);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getMediasByWebsiteIdAsync = createAsyncThunk(
  "media/getMediasByWebsiteIdAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.MEDIA_PUBLISHER_WEBSITES.replace(
        "{publisherId}",
        data.publisherId || 0
      ).replace("{webStoreId}", data.websiteId);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getMediaActiveListAsync = createAsyncThunk(
  "media/getMediaActiveListAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.MEDIA_ADMIN_ACTIVE_LIST;

      const response = await baseAPI({
        endPoint: end_,
        body: {},
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getAdminMediaActiveListAsync = createAsyncThunk(
  "media/getAdminMediaActiveListAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.MEDIA_PUBLISHER_ACTIVE_LIST.replace(
        "{publisherId}",
        data?.publisherId || 0
      );

      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getAdminMediaActiveMiniListAsync = createAsyncThunk(
  "media/getAdminMediaActiveMiniListAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.MEDIA_ADMIN_ACTIVE_LIST_MINI;

      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const updateOwnershipVerificationAsync = createAsyncThunk(
  "media/updateOwnershipVerificationAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.MEDIA_OWNERSHIP_EMAIL_VERIFY;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const updateOwnershipVerificationSendCodeAsync = createAsyncThunk(
  "media/updateOwnershipVerificationSendCodeAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.MEDIA_OWNERSHIP_EMAIL_SEND_CODE.replace(
        "{mediaId}",
        data?.mediaId || 0
      ).replace("{email}", data?.email);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const mediaSlice = createSlice({
  name: "media",
  initialState: {
    mediasLoading: false,
    medias: null,
    error: null,

    mediaLoading: false,

    mediaDetailLoading: false,
    mediaDetail: null,
    mediaDetailError: null,

    mediaActiveListLoading: false,
    mediaActiveList: null,
    mediaActiveListError: null,

    mediasWebsiteLoading: false,
    mediasWebsite: null,
    mediasWebsiteError: null,

    mediaWebsiteLoading: null,

    mediaChangeStatusLoading: false,

    sendCodeLoading: null,
  },
  reducers: {
    clearState: (state) => {
      state.mediaDetail = false;
      state.mediaDetailLoading = false;
      state.mediaDetailError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMediaListAsync.pending, (state, action) => {
      state.mediasLoading = true;
      state.error = null;
    });
    builder.addCase(getMediaListAsync.rejected, (state, action) => {
      state.mediasLoading = false;
      state.error = action.error;
    });
    builder.addCase(getMediaListAsync.fulfilled, (state, action) => {
      if (action.payload?.success) {
        state.medias = action.payload;
      }
      state.mediasLoading = false;
      state.error = null;
    });

    builder.addCase(mediaAddAsync.pending, (state, action) => {
      state.mediaLoading = true;
      state.error = null;
    });
    builder.addCase(mediaAddAsync.rejected, (state, action) => {
      state.mediaLoading = false;
      state.error = action.error;
    });
    builder.addCase(mediaAddAsync.fulfilled, (state, action) => {
      state.mediaLoading = false;
      state.error = null;
    });

    builder.addCase(getMediaDetailAsync.pending, (state, action) => {
      state.mediaDetailLoading = true;
      state.mediaDetailError = null;
    });
    builder.addCase(getMediaDetailAsync.rejected, (state, action) => {
      state.mediaDetailLoading = false;
      state.mediaDetailError = action.error;
    });
    builder.addCase(getMediaDetailAsync.fulfilled, (state, action) => {
      state.mediaDetailLoading = false;
      state.mediaDetailError = null;
      if (action.payload.success) {
        state.mediaDetail = action.payload;
      }
    });

    builder.addCase(getMediasByWebsiteIdAsync.pending, (state, action) => {
      state.mediasWebsiteLoading = true;
      state.mediasWebsiteError = null;
    });
    builder.addCase(getMediasByWebsiteIdAsync.rejected, (state, action) => {
      state.mediasWebsiteLoading = false;
      state.mediasWebsiteError = action.error;
    });
    builder.addCase(getMediasByWebsiteIdAsync.fulfilled, (state, action) => {
      state.mediasWebsiteLoading = false;
      state.mediasWebsiteError = null;
      if (action.payload.success) {
        state.mediasWebsite = action.payload;
      }
    });

    builder.addCase(updateMediaAsync.pending, (state, action) => {
      state.mediaLoading = true;
    });
    builder.addCase(updateMediaAsync.rejected, (state, action) => {
      state.mediaLoading = false;
    });
    builder.addCase(updateMediaAsync.fulfilled, (state, action) => {
      state.mediaLoading = false;
    });

    builder.addCase(removeWebSiteFromMediaAsync.pending, (state, action) => {
      state.mediaWebsiteLoading = true;
    });
    builder.addCase(removeWebSiteFromMediaAsync.rejected, (state, action) => {
      state.mediaWebsiteLoading = false;
    });
    builder.addCase(removeWebSiteFromMediaAsync.fulfilled, (state, action) => {
      state.mediaWebsiteLoading = false;
    });

    builder.addCase(getMediaActiveListAsync.pending, (state, action) => {
      state.mediaActiveListLoading = true;
    });
    builder.addCase(getMediaActiveListAsync.rejected, (state, action) => {
      state.mediaActiveListLoading = false;
    });
    builder.addCase(getMediaActiveListAsync.fulfilled, (state, action) => {
      state.mediaActiveListLoading = false;
      state.mediaActiveListError = null;
      if (action.payload.success) {
        state.mediaActiveList = action.payload;
      }
    });

    builder.addCase(getAdminMediaActiveListAsync.pending, (state, action) => {
      state.mediaActiveListLoading = true;
    });
    builder.addCase(getAdminMediaActiveListAsync.rejected, (state, action) => {
      state.mediaActiveListLoading = false;
    });
    builder.addCase(getAdminMediaActiveListAsync.fulfilled, (state, action) => {
      state.mediaActiveListLoading = false;
      state.mediaActiveListError = null;
      if (action.payload.success) {
        state.mediaActiveList = action.payload;
      }
    });

    builder.addCase(
      getAdminMediaActiveMiniListAsync.pending,
      (state, action) => {
        state.mediaActiveListLoading = true;
      }
    );
    builder.addCase(
      getAdminMediaActiveMiniListAsync.rejected,
      (state, action) => {
        state.mediaActiveListLoading = false;
      }
    );
    builder.addCase(
      getAdminMediaActiveMiniListAsync.fulfilled,
      (state, action) => {
        state.mediaActiveListLoading = false;
        state.mediaActiveListError = null;
        if (action.payload.success) {
          state.mediaActiveList = action.payload;
        }
      }
    );

    builder.addCase(updateOwnerShipStatusAsync.pending, (state, action) => {
      state.mediaChangeStatusLoading = true;
    });
    builder.addCase(updateTestModeStatusAsync.pending, (state, action) => {
      state.mediaChangeStatusLoading = true;
    });
    builder.addCase(updateContentStatusAsync.pending, (state, action) => {
      state.mediaChangeStatusLoading = true;
    });
    builder.addMatcher(
      (action) => action.type.includes("pending"),
      (state) => {
        state.sendCodeLoading = true;
      }
    );
    builder.addDefaultCase((state, action) => {
      state.mediaChangeStatusLoading = false;
    });
  },
});

export const { clearState } = mediaSlice.actions;

export const getMediaList = (data) => async (dispatch) => {
  return dispatch(getMediaListAsync(data));
};

export const mediaAdd = (data) => async (dispatch) => {
  return dispatch(mediaAddAsync(data));
};

export const updateOwnerShipStatus = (data) => async (dispatch) => {
  return dispatch(updateOwnerShipStatusAsync(data));
};

export const updateContentStatus = (data) => async (dispatch) => {
  return dispatch(updateContentStatusAsync(data));
};

export const updateTestModeStatus = (data) => async (dispatch) => {
  return dispatch(updateTestModeStatusAsync(data));
};

export const getMediaDetail = (data) => async (dispatch) => {
  return dispatch(getMediaDetailAsync(data));
};

export const updateMedia = (data) => async (dispatch) => {
  return dispatch(updateMediaAsync(data));
};

export const addWebsiteToMedia = (data) => async (dispatch) => {
  return dispatch(addWebsiteToMediaAsync(data));
};

export const removeWebSiteFromMedia = (data) => async (dispatch) => {
  return dispatch(removeWebSiteFromMediaAsync(data));
};

export const deleteMedia = (data) => async (dispatch) => {
  return dispatch(deleteMediaAsync(data));
};

export const getMediasByWebsiteId = (data) => async (dispatch) => {
  return dispatch(getMediasByWebsiteIdAsync(data));
};

export const getMediaActiveList = (data) => async (dispatch) => {
  return dispatch(getMediaActiveListAsync(data));
};

export const getAdminMediaActiveList = (data) => async (dispatch) => {
  return dispatch(getAdminMediaActiveListAsync(data));
};

export const getAdminMediaActiveMiniList = (data) => async (dispatch) => {
  return dispatch(getAdminMediaActiveMiniListAsync(data));
};

export const updateOwnershipVerification = (data) => async (dispatch) => {
  return dispatch(updateOwnershipVerificationAsync(data));
};

export const updateOwnershipVerificationSendCode =
  (data) => async (dispatch) => {
    return dispatch(updateOwnershipVerificationSendCodeAsync(data));
  };

export default mediaSlice.reducer;
