import { CloudUploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { Form, Image, Spin, Tooltip, Upload, message } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { appUploadFile, getFile } from "@/app/local/appSlice";
import {
  FILE_FILE_TYPE,
  FILE_MEDIA_TYPE,
  FILE_OBJECT_TYPE,
} from "@/utils/constants/apiConstants";
import { getUrlFile, saveFile } from "@/utils/helper";

import { KYC_CHECK } from "../userInfo/StepPersonalInfo";
import pdfImage from "./../../assets/images/default-pdf.svg";
import "./input.less";

const FormatsAccept = ".pdf,.zip,doc,docx,jpg,png,jpeg";

const InputUpload = (props) => {
  const {
    objectId,
    objectType,
    fileType,
    mediaType,
    required,
    name,
    label,
    form,
    accept,
    files,
    checkKYC,
    dimensions,
  } = props;

  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.account);

  const { t } = useTranslation();
  const [isUpdated, setIsUpdated] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileLen = !files?.includes(undefined) ? files?.length : 0;

  const [preview, setPreview] = useState();

  useEffect(() => {
    let fls = [];
    if (fileLen) {
      setLoading(true);
    }
    const abortController = new AbortController();
    const filePromise = new Promise((resolve) => {
      files?.map(async (fileId) => {
        if (fileId) {
          const file = await dispatch(
            getFile({ fileId, controller: abortController })
          );

          if (file.payload.success) {
            setError(false);
            const fileData = file?.payload?.data;
            const url_ = fileData?.use_public_domain
              ? fileData?.public_path
              : getUrlFile(fileData);

            const fileD = {
              uid: "-1",
              name: fileData.user_defined_name,
              status: "done",
              response: fileData,
              url: url_,
              thumbUrl: url_,
            };
            if (fileD.response.extension === ".pdf") {
              fileD.thumbUrl = pdfImage;
            }
            fls.push(fileD);
            resolve([fls]);
          } else {
            resolve("error");
            setError(true);
          }
        }
      });
    });
    filePromise
      .then((fsI) => {
        if (fsI?.length) {
          if (_.isArray(name)) {
            const [p, name_] = name;
            form.setFieldsValue({
              user: {
                [name_]: fls,
              },
            });
          } else {
            form.setFieldsValue({
              [name]: fls,
            });
          }
        } else setError(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    return () => {
      if (fileLen) {
        abortController.abort("cancel-upload");
      }
    };
  }, [fileLen]);

  const onChangeDragger = (info) => {
    const { status } = info.file;
    //   if (status !== "uploading") {
    //   }
    if (status === "done") {
      // setFileList(info.fileList);
      //     // message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      //     // message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onDrop = (e) => {
    e.isDefaultPrevented();
  };

  const customRequest = (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const objectId_ = objectId || profile.data?.user_id;
    const objectType_ = objectType || profile.data?.user_type;

    const file_ = file;
    const fileInfo = {
      mediaType: mediaType,
      fileType: fileType,
      uploader: file_,
      objectId: objectId_, //userId
      objectType: objectType_,
    };

    const response = dispatch(appUploadFile(fileInfo));

    response
      .then((r) => {
        if (r?.payload?.success) {
          onSuccess(r.payload.data);
          setIsUpdated(true);
          setError(false);
        } else {
          const error = new Error(t("input.uploadError"));
          message.error({
            content: r?.payload?.Message || t("input.uploadError"),
          });
          onError({ error });
        }
      })
      .catch(() => {
        const error = new Error(t("input.uploadError"));
        message.error({
          content: t("input.uploadError"),
        });
        onError({ error });
      });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = async (file) => {
    if (dimensions) {
      const [width_, height_] = dimensions;
      const promiseFunc = () => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.addEventListener("load", (event) => {
            const _loadedImageUrl = event.target.result;
            const image = document.createElement("img");
            image.src = _loadedImageUrl;
            image.addEventListener("load", () => {
              const { width, height } = image;
              if (width === width_ && height === height_) {
                resolve(true);
              } else {
                resolve("DimensionError");
              }
            });
          });
        });
      };

      const result = await promiseFunc();
      if (result === "DimensionError") {
        message.error({
          content: t("banner.error.dimensionError"),
        });
        return false;
      }
    }
  };

  const formatsAccept = () => {
    if (accept === "image") {
      return ".jpg,.png,.jpeg";
    }
    if (accept === "csv") {
      return ".csv,.xlsx";
    }
    if (accept === "pdf") {
      return ".pdf";
    }
    if (accept === "mix") {
      return ".jpg,.png,.jpeg,.pdf";
    }
    return FormatsAccept;
  };

  const onDownload = async (e) => {
    const contentType = e.response.mime_type;
    let sourceData = e.response.source;
    if (!sourceData) {
      const r1 = await dispatch(getFile(e.response.file_id));
      if (r1.payload.success) {
        sourceData = r1.payload.data?.source;
      }
    }
    saveFile({
      fileName: e.response.user_defined_name,
      b64: sourceData,
      mType: contentType,
    });
  };

  const checkKyc = checkKYC ? KYC_CHECK(profile, name) || undefined : undefined;

  return (
    <>
      <Spin spinning={loading}>
        <Form.Item
          name={name}
          label={label}
          rules={[
            {
              required: required,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (checkKyc === "error") {
                  if (value && isUpdated) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t("profile.requiredUpdate")));
                }

                if (value?.length > 0) {
                  setError(false);
                  return Promise.resolve();
                } else {
                  setError(true);
                  return Promise.reject(new Error(t("required")));
                }
              },
            }),
          ]}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          className="inputUpload"
          hasFeedback={checkKyc && !isUpdated ? true : false}
          validateStatus={!error && checkKyc}
        >
          {!files?.length && error ? (
            <Tooltip title={t("general.error_in_receive_file")} color="red">
              <Upload.Dragger
                accept={formatsAccept()}
                showUploadList={{
                  showDownloadIcon: true,
                  downloadIcon: <DownloadOutlined />,
                  showRemoveIcon: true,
                }}
                onChange={onChangeDragger}
                onDrop={onDrop}
                name={name + "File"}
                listType="picture"
                multiple={false}
                customRequest={customRequest}
                beforeUpload={beforeUpload}
                onDownload={onDownload}
                maxCount={1}
                className={error ? "uploaderError" : ""}
                // onRemove={() => setFileList((old) => (old || []).pop())}
              >
                <div>
                  <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined style={{ fontSize: "32px" }} />
                  </p>
                  <p className="ant-upload-text">
                    {t("input.click_or_drag_file")}
                    &nbsp;
                    {label}
                  </p>
                  <p className="ant-upload-hint">
                    {/* Support for a single or bulk upload. */}
                    {accept === "mix" ? t("input.support_single_mix") : ""}
                    {accept === "pdf" ? t("input.support_single_pdf") : ""}
                    {accept === "csv" ? t("input.support_single_csv") : ""}
                    {accept === "image" || !accept
                      ? t("input.support_single")
                      : ""}
                  </p>
                </div>
              </Upload.Dragger>
            </Tooltip>
          ) : (
            <Upload.Dragger
              accept={formatsAccept()}
              showUploadList={{
                showDownloadIcon: true,
                downloadIcon: <DownloadOutlined />,
                showRemoveIcon: true,
              }}
              onChange={onChangeDragger}
              onDrop={onDrop}
              name={name + "File"}
              multiple={false}
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              onDownload={onDownload}
              maxCount={1}
              onPreview={(e) => {
                if (accept === "image") setPreview(e.url);
              }}
              listType="picture"
              className={error ? "uploaderError" : ""}
              // onRemove={() => setFileList((old) => (old || []).pop())}
            >
              <div>
                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined style={{ fontSize: "32px" }} />
                </p>
                <p className="ant-upload-text">
                  {t("input.click_or_drag_file")}
                  &nbsp;
                  {label}
                </p>
                <p className="ant-upload-hint">
                  {/* Support for a single or bulk upload. */}
                  {accept === "mix" ? t("input.support_single_mix") : ""}
                  {accept === "pdf" ? t("input.support_single_pdf") : ""}
                  {accept === "csv" ? t("input.support_single_csv") : ""}
                  {accept === "image" || !accept
                    ? t("input.support_single")
                    : ""}
                </p>
              </div>
            </Upload.Dragger>
          )}
        </Form.Item>
        <Image
          className="img-preview"
          src={preview}
          style={{ display: "none" }}
          preview={{
            visible: preview ? true : false,
            src: preview,
            onVisibleChange: (value) => {
              setPreview(value);
            },
          }}
        />
      </Spin>
    </>
  );
};

InputUpload.propTypes = {
  name: PropTypes.any.isRequired,
  objectId: PropTypes.string,
  label: PropTypes.string,
  objectType: FILE_OBJECT_TYPE,
  fileType: FILE_FILE_TYPE,
  mediaType: FILE_MEDIA_TYPE,
  required: PropTypes.bool,
  form: PropTypes.element,
  accept: PropTypes.string,
  files: PropTypes.array,
  checkKYC: PropTypes.bool,
  dimensions: PropTypes.array,
};

export default InputUpload;
