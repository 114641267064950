import { Alert, Col, Divider, Form, Radio, Row, message } from "antd";
import Title from "antd/lib/typography/Title";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { clarity } from "react-microsoft-clarity";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import {
  accountFinalizeRegistering,
  accountUserDetail,
} from "@/app/local/accountSlice";
import { appLoadingBar } from "@/app/local/appSlice";
import { merchantVatInfo } from "@/app/local/merchantSlice";
import { publisherVatInfo } from "@/app/local/publisherSlice";
import { getProfileData } from "@/pages/profile";
import {
  FILE_FILE_TYPE,
  FILE_MEDIA_TYPE,
  LEGAL_STATUS,
  USER_TYPE,
} from "@/utils/constants/apiConstants";
import { getLastError } from "@/utils/helper";

import inTrackEvent from "../extra/Intrack/InTrackEvent";
import InputUpload from "../input/inputUpload";
import FooterWizard from "./FooterWizard";

const StepTaxInfo = (props) => {
  const isProfile = useMatch("/profile");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState();

  const profile = getProfileData();
  const userType = _.get(profile, "data.user_type");
  const userInfo = _.get(profile, "userDetail");

  const { stepVat, loading, error } = useSelector((state) =>
    userType === USER_TYPE.PUBLISHER || userType === USER_TYPE.ADVERTISER
      ? state.publisher
      : state.merchant
  );

  const uId = profile?.data?.user_id;
  const legal = userInfo?.legal_status;

  useEffect(() => {
    if (!_.isEmpty(userInfo)) {
      form.setFieldsValue({
        user: {
          hasVat: userInfo?.has_vat || false,
        },
      });
    }
  }, [userInfo]);

  useEffect(() => {
    dispatch(appLoadingBar(loading));
  }, [loading]);

  const successFullyDone = () => {
    if (isProfile) {
      message.success({
        content: t("general.saveSuccessFully"),
        key: "publisher",
      });
    } else {
      if (_.isFunction(props?.goNextStep)) {
        props.goNextStep(props.max);
      }
      // setModalVisible(true);
      dispatch(accountFinalizeRegistering());
    }
  };

  const validateMessages = {
    required: t("required"),
    types: {
      email: "${label} " + `${t("general.emailNotValid")}`,
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onFinish = async (values) => {
    const { picVatDocument, picPaperDocument, hasVat } = values.user;
    const eventParam = {
      userId: uId,
      attributes: {
        hasVat,
        kycStatus: 6,
        contractAccepted: false,
      },
    };
    inTrackEvent.updateProfile(eventParam);
    clarity.setTag("register", "step-4");

    let VFile = undefined;
    let PFile = undefined;
    if (picVatDocument) {
      VFile = picVatDocument[0].response?.file_id;
    }
    if (picPaperDocument) {
      PFile = picPaperDocument[0].response?.file_id;
    }
    const serverData = {
      user_id: uId,
      has_vat: hasVat,
      vat_file_id: VFile,
      news_paper_document_file_id: PFile,
    };

    dispatch(appLoadingBar(true));
    let resp;
    if (userType === USER_TYPE.PUBLISHER || userType === USER_TYPE.ADVERTISER) {
      resp = await dispatch(publisherVatInfo(serverData));
    } else if (userType === USER_TYPE.MERCHANT) {
      resp = await dispatch(merchantVatInfo(serverData));
    }
    if (resp?.payload?.success) {
      if (userType === USER_TYPE.PUBLISHER) {
        // InTrack Event

        inTrackEvent.updateProfile({
          userId: uId,
          attributes: {
            isTaxable: hasVat,
          },
        });

        inTrackEvent.taxInfoSubmitEvent({
          userId: uId,
          isTaxable: hasVat,
        });
        // InTrack Event
        inTrackEvent.finalSignUpEvent({
          userId: uId,
        });
      }

      successFullyDone();
    } else {
      const errorMessage =
        resp?.payload?.Message || getLastError(resp?.payload);
      message.error({
        content: errorMessage,
        key: "publisher",
      });
      dispatch(accountUserDetail());
    }
  };

  return (
    <>
      <Form
        form={form}
        name="formStepOne"
        onFinish={onFinish}
        validateMessages={validateMessages}
        className="afl-form"
        layout="vertical"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          md: 24,
        }}
        initialValues={{
          user: { hasVat: false },
        }}
      >
        <Row>
          <Col md={24}>
            <Row>{/* <Col md={24}>xx</Col> */}</Row>
            <br />
            <Row gutter={24}>
              <Col md={11} xs={24}>
                <Title strong level={5}>
                  {t("wizard.vatTitle")}
                </Title>
                <div>
                  <Form.Item name={["user", "hasVat"]}>
                    <Radio.Group>
                      <Radio value={false}>
                        {t("wizard.vat_not_included")}
                      </Radio>
                      <Radio value={true}>{t("wizard.vat_included")}</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const hasVat_ = getFieldValue(["user", "hasVat"]);
                      if (hasVat_) {
                        return (
                          <>
                            {legal === LEGAL_STATUS.PRIVATE ? (
                              <Alert
                                showIcon
                                // icon={<InfoCircleFilled />}
                                message={t("user.taxOnVatText")}
                              ></Alert>
                            ) : (
                              ""
                            )}
                            <br />
                            <InputUpload
                              label={t("user.picVatDocument")}
                              name={["user", "picVatDocument"]}
                              fileType={FILE_FILE_TYPE.VAT_DOCUMENT}
                              mediaType={FILE_MEDIA_TYPE.IMAGE}
                              required
                              form={form}
                              files={[userInfo?.vat_file_id]}
                              accept="image"
                              checkKYC
                            />
                          </>
                        );
                      } else return null;
                    }}
                  </Form.Item>
                </div>
              </Col>

              {legal === LEGAL_STATUS.LEGAL ||
              userType === USER_TYPE.MERCHANT ? (
                <>
                  <Col xs={0} md={2}>
                    <Divider
                      orientation={"center"}
                      dashed={true}
                      style={{
                        backgroundColor: "#ddd",
                        textAlign: "center",
                        height: "100%",
                      }}
                      type="vertical"
                    />
                  </Col>
                  <Col className={"form__split"} md={11} xs={24}>
                    <Title strong level={5} style={{ marginBottom: "16px" }}>
                      {t("user.picPaperDocument")}
                    </Title>

                    <InputUpload
                      // label={t("user.picPaperDocument")}
                      name={["user", "picPaperDocument"]}
                      fileType={FILE_FILE_TYPE.NEWSPAPER_DOCUMENT}
                      mediaType={FILE_MEDIA_TYPE.IMAGE}
                      required
                      form={form}
                      files={[userInfo?.news_paper_document_file_id]}
                      accept="image"
                      checkKYC
                    />
                  </Col>
                </>
              ) : (
                ""
              )}
            </Row>
            <br />
            <FooterWizard
              loading={loading}
              current={userType === USER_TYPE.PUBLISHER ? 3 : 2}
              isLast
              goNextStep={props.goNextStep}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

StepTaxInfo.propTypes = {
  goNextStep: PropTypes.func,
  md: PropTypes.number,
  max: PropTypes.number,
};
StepTaxInfo.defaultProps = {
  md: 18,
};

export default StepTaxInfo;
