import { getPanelMode } from "../helper";

const useConfig = () => {
  try {
    return {
      isSSP: getPanelMode() === "SSP",
    };
  } catch (e) {
    return {
      isSSP: true,
    };
  }
};

export default useConfig;
