import { Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useMemo } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";

import { getProductsWidgetSmart } from "@/app/local/productWidgetSlice";
import DefaultImage from "@/assets/images/default-product.svg";
import ImagePreview from "@/components/micro/ImagePreview";
import { useAccount } from "@/utils/hooks/useAuth";
import { useWidgetType } from "@/utils/hooks/useWidgetType";

import { WidgetContext } from "../widgetBuilder";
import "./CarouselWebstores.less";

// eslint-disable-next-line react/prop-types
const MyItem = ({ key, children }) => (
  <div style={{ width: 175, height: "auto" }} className="website_" key={key}>
    {children}
  </div>
);

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1600 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 1600, min: 1024 },
    items: 5,
    partialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const CarouselWebstores = () => {
  const {
    websitesActive,
    websitesActiveLoading,
    websitesActiveError,
    websitesPublisher,
    websitesMerchant,
    websitesPublisherLoading,
    websitesMerchantLoading,
  } = useSelector((state) => state.website);
  const { isPublisher, isMerchant, roleId } = useAccount();

  const { isCustom, isNew } = useWidgetType();

  const dispatch = useDispatch();

  const webStoreItems = useMemo(() => {
    if (isPublisher) {
      const data = _.get(websitesPublisher, "data", []);
      return data.map((item) => {
        return {
          image: item?.image,
          name: item?.name,
          id: item?.web_store_id,
          logo: item?.profile_file_id,
        };
      });
    } else if (isMerchant) {
      const data = _.get(websitesMerchant, "data", []);
      return data.map((item) => {
        return {
          image: item?.image,
          name: item?.name,
          id: item?.web_store_id,
          logo: item?.profile_file_id,
        };
      });
    }
  }, [websitesPublisher, websitesMerchant]);

  const [context, dispatch_] = useContext(WidgetContext);

  useEffect(() => {
    if (context.web_store) {
      dispatch(
        getProductsWidgetSmart({
          filter: {
            webstore_id: [context.web_store],
          },
        })
      );
    }
  }, [context.web_store]);

  return (
    <>
      <div className="carousel-help">
        <Title level={5}>{t("widget_builder.select_store")}</Title>
        {isCustom || isMerchant ? (
          <p>{t("widget_builder.select_store_between_merchant")}:</p>
        ) : (
          <p>{t("widget_builder.select_store_between")}</p>
        )}
      </div>

      <div className="carousel-webstores">
        <Spin
          spinning={
            websitesActiveLoading ||
            websitesMerchantLoading ||
            websitesPublisherLoading
          }
        >
          {webStoreItems?.length ? (
            <Carousel
              rtl
              partialVisible
              containerClass="carousel-container"
              responsive={responsive}
              itemClass="carousel-item-padding-40-px"
              // arrows={false}
            >
              {webStoreItems?.map((item, k) => {
                return (
                  <MyItem key={item.id}>
                    <div
                      className={`website_item${
                        context.web_store === item.id ? " active" : ""
                      }`}
                      onClick={() => {
                        dispatch_({
                          type: "set_web_store",
                          value: item.id,
                        });
                      }}
                    >
                      <div className="w_img">
                        <ImagePreview
                          fileId={item.logo}
                          defaultImage={DefaultImage}
                          preview={false}
                          height={"auto"}
                          width={"auto"}
                        />
                      </div>
                      <div className="w_button">{item.name}</div>
                    </div>
                  </MyItem>
                );
              })}
            </Carousel>
          ) : (
            false
          )}
        </Spin>
      </div>
    </>
  );
};

export default CarouselWebstores;
