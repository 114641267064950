import { Column, Line } from "@ant-design/plots";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getConversionRate } from "@/app/local/reportSlice";
import { DashboardContext } from "@/pages/dashboard/DashboardAdmin";
import { dateFormat } from "@/utils/helper";

import TileNumber from "../micro/TileNumber";
import { ChartHolder } from "./AdminViewClickChart";
import { COLORS } from "./colors";
import WidgetWrapper from "./micro/WidgetWrapper";
import "./styles.less";

const A_DATA = [
  {
    pivot: "2022-11-16",
    cr_click_to_finalized_order: 0,
    finalized_order_over_total_order_rate: 0,
    crnc_over_total_order: 1,
    crnc_final_over_total_order: 0,
    click_to_total_generated_order_rate: 0,
    commission_over_nmv: 0,
  },
  {
    pivot: "2022-11-22",
    cr_click_to_finalized_order: 2,
    finalized_order_over_total_order_rate: 0,
    crnc_over_total_order: 0,
    crnc_final_over_total_order: 0,
    click_to_total_generated_order_rate: 4,
    commission_over_nmv: 0,
  },
];

const AdminConversionRateChart = (props) => {
  const { title } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { adminConversionRate, adminConversionRateLoading } = useSelector(
    (state) => state.report
  );
  // const [totalData, setTotalData] = useState({ totalItems: 1 });

  const [data, setData] = useState([]);
  const [mode, setMode] = useState("LINE");

  const [context, dispatch_] = useContext(DashboardContext);

  useEffect(() => {
    onSubmit();
  }, [context]);

  const getPercentValue = (v) => {
    if (v !== null) {
      return Number((v * 100).toFixed(2));
    }
  };

  useEffect(() => {
    if (adminConversionRate) {
      const data_ = [...(adminConversionRate?.data?.data || [])];
      const temps = [];
      const total = {
        // clickOnGOrder: 0,
        finalOrderOnGOrder: 0,
        // ncOnGOrders: 0,
        // ncOnFinalOrders: 0,
        commissionOverNmv: 0,
      };
      data_
        .sort((a, b) => {
          const b_ = moment(b.pivot);
          const a_ = moment(a.pivot);
          const isBefore = a_.isBefore(b_);
          if (a.pivot == b.pivot) {
            return 0;
          }
          if (isBefore) {
            return -1;
          }
          return 1;
        })
        .map((item, i) => {
          // const dt = formatDate(moment(item.pivot));
          const dt = dateFormat(item.pivot, "S2");

          // const x1 = getPercentValue(item.click_to_total_generated_order_rate);
          // temps.push({
          //   month: dt,
          //   value: x1,
          //   type: "dashboard.clickToSubmittedOrder",
          // });
          // total.clickOnGOrder += x1;

          const x2 = getPercentValue(
            item.finalized_order_over_total_order_rate
          );
          temps.push({
            month: dt,
            value: x2,
            type: "dashboard.finalOrderToSubmittedOrder",
          });
          total.finalOrderOnGOrder += x2;

          // const x3 = getPercentValue(item.crnc_final_over_total_order);
          // temps.push({
          //   month: dt,
          //   value: x3,
          //   type: "dashboard.newCustomerToFinalOrder",
          // });
          // total.ncOnFinalOrders += x3;

          // const x4 = getPercentValue(item.crnc_over_total_order);
          // temps.push({
          //   month: dt,
          //   value: x4,
          //   type: "dashboard.newCustomerToSubmittedOrder",
          // });
          // total.ncOnGOrders += x4;

          const x5 = getPercentValue(item.commission_over_nmv);
          temps.push({
            month: dt,
            value: x5,
            type: "dashboard.commissionOverNmv",
          });
          total.commissionOverNmv += x5;
        });
      total.totalItems = data_.length || 1;

      // setTotalData(total);
      setData(temps);
    }
  }, [adminConversionRate]);

  const lineConfig = useMemo(() => {
    return {
      data,
      padding: [30, 30, 70, 55],
      xField: "month",
      yField: "value",
      height: 320,
      seriesField: "type",
      limitInPlot: false,
      slider: data.length > 15 ? { start: 0.5, end: 1 } : "",
      legend: false,
      theme: {
        styleSheet: {
          fontFamily: "IRANYekan",
        },
      },
      tooltip: {
        formatter: (datum) => {
          return { name: t(datum.type), value: datum.value };
        },
      },
      color: ({ type, year }, ...x) => {
        switch (type) {
          case "dashboard.newCustomerToFinalOrder":
            return COLORS.DASHBOARD_PURPLE;
          case "dashboard.newCustomerToSubmittedOrder":
            return COLORS.DASHBOARD_ORANGE;
          case "dashboard.finalOrderToSubmittedOrder":
            return COLORS.DASHBOARD_RED;
          case "dashboard.clickToSubmittedOrder":
            return COLORS.DASHBOARD_BLUE;
          case "dashboard.commissionOverNmv":
            return COLORS.DASHBOARD_GREEN;
          default:
            return COLORS.DASHBOARD_BLUE;
        }
      },
    };
  }, [data]);

  const columnConfig = useMemo(() => {
    return {
      data,
      xField: "month",
      yField: "value",
      seriesField: "type",
      legend: false,
      maxBarWidth: 30,
      height: 320,
      limitInPlot: false,
      slider: data.length > 15 ? { start: 0.5, end: 1 } : "",
      isGroup: true,
      padding: [30, 30, 70, 55],
      columnStyle: {
        radius: [20, 20, 0, 0],
      },
      color: ({ type, year }, ...x) => {
        switch (type) {
          case "dashboard.newCustomerToFinalOrder":
            return COLORS.DASHBOARD_GREEN;
          case "dashboard.newCustomerToSubmittedOrder":
            return COLORS.DASHBOARD_RED;
          case "dashboard.finalOrderToSubmittedOrder":
            return COLORS.DASHBOARD_PURPLE;
          case "dashboard.clickToSubmittedOrder":
            return COLORS.DASHBOARD_ORANGE;
          default:
            return COLORS.DASHBOARD_BLUE;
        }
      },
      theme: {
        styleSheet: {
          fontFamily: "IRANYekan",
        },
      },
      tooltip: {
        formatter: (datum) => {
          return { name: t(datum.type), value: datum.value };
        },
      },
    };
  }, [data]);

  const onSubmit = (e) => {
    dispatch(
      getConversionRate({
        web_store_ids: context.webstore,
        media_ids: e?.medias || context?.medias,
        from: context.period.fromDate,
        to: context.period.toDate,
        publisher_ids: context?.publisher_ids,
        filter_date_by: context?.filter_date_by,
      })
    );
  };

  const totalData = adminConversionRate?.data;

  return (
    <WidgetWrapper
      setMode={setMode}
      title={`dashboard.${title}`}
      onSubmit={onSubmit}
      className="admin_commission_chart"
    >
      <div
        className="horizontal-chart-bar"
        dir="ltr"
        style={{ direction: "ltr" }}
      >
        <div className="tile-bar no-flex">
          {/* <Row justify={"space-between"}>
            <Col md={4} xs={24}> */}
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            {/* <TileNumber
              title={t("dashboard.clickToSubmittedOrder")}
              number={totalData?.click_to_total_generated_order_rate * 100}
              align="center"
              color={"color_6"}
              percent
              toolTip="Clicks / Generated Orders"
            /> */}
            {/* </Col>
            <Col md={4} xs={24}> */}
            <TileNumber
              title={t("dashboard.finalOrderToSubmittedOrder")}
              number={totalData?.finalized_order_over_total_order_rate * 100}
              align="center"
              color={"color_1"}
              toolTip="Generated Orders / Total Order"
              percent
            />
            {/* </Col>
            <Col md={4} xs={24}> */}
            {/* <TileNumber
              title={t("dashboard.newCustomerToFinalOrder")}
              number={totalData?.crnc_over_total_order * 100}
              align="center"
              color={"color_2"}
              toolTip="NC / Finals Order"
              percent
            />
            <TileNumber
              title={t("dashboard.newCustomerToSubmittedOrder")}
              number={totalData?.crnc_final_over_total_order * 100}
              align="center"
              color={"color_3"}
              toolTip="NC / Total Order"
              percent
            /> */}
            {/* </Col>
            <Col md={4} xs={24}> */}

            {/* </Col>
            <Col md={4} xs={24}> */}
            <TileNumber
              title={t("dashboard.commissionOverNmv")}
              number={totalData?.commission_over_nmv * 100}
              align="center"
              color={"color_5"}
              toolTip="Commission / NMV"
              percent
            />
            {/* </Col> */}
            {/* </Row> */}
          </div>
        </div>
        <ChartHolder
          loading={adminConversionRateLoading}
          len={data?.length}
          height="320px"
        >
          {mode === "BAR" && (
            <Column {...columnConfig} loading={adminConversionRateLoading} />
          )}
          {mode === "LINE" && (
            <Line {...lineConfig} loading={adminConversionRateLoading} />
          )}
        </ChartHolder>
      </div>
    </WidgetWrapper>
  );
};

AdminConversionRateChart.propTypes = {
  title: PropTypes.string,
};

export default AdminConversionRateChart;
