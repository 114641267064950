import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import { t } from "i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";

import useConfig from "@/utils/hooks/useConfig";

import "./FormSearchBar.less";

const FormSearchBar = (props) => {
  const {
    loading,
    AdvanceFields,
    search,
    more,
    form_,
    onValuesChange,
    name,
    idSearch,
    ignoreFilled,
    responsive,
    searchWidth,
  } = props;
  const [form] = useForm();
  const [openAdvanced, setOpenAdvanced] = useState();
  const [filled, setFilled] = useState(null);
  const { isSSP } = useConfig();

  const onFinishSearch = (e) => {
    props.onFinish(e);
  };

  const onValuesChange_ = (e) => {
    !!onValuesChange && onValuesChange(e);

    if (props?.onClearFilters) {
      const dataForm = _.isEmpty(form.getFieldsValue())
        ? form_.getFieldsValue()
        : form.getFieldsValue();

      const isFilled = Object.keys(dataForm).find(
        (key) => !_.isUndefined(dataForm[key])
      );
      if (isFilled !== filled) {
        if (ignoreFilled === isFilled) {
          setFilled(false);
        } else {
          setFilled(!!isFilled);
        }
      }
    }
  };

  const onClearFilters = () => {
    props?.onClearFilters();
    setFilled(false);
  };

  return (
    <Card className="form-search-bar">
      <Row gutter={24}>
        <Col md={24} xs={24}>
          <Form
            form={form_ || form}
            name="formSearch"
            onFinish={onFinishSearch}
            className="adv-search"
            onValuesChange={onValuesChange_}
          >
            <Row gutter={8}>
              <Col md={more ? 20 : 22}>
                <Row gutter={[12, 12]}>
                  <Col
                    md={
                      more && props.children && props.children.length === 3
                        ? 9
                        : searchWidth
                        ? searchWidth
                        : 8
                    }
                    xs={24}
                    style={search ? undefined : { display: "none" }}
                  >
                    <Form.Item name={"search"}>
                      <Input
                        type="search"
                        placeholder={
                          idSearch
                            ? t("general.IdAndinputSearch")
                            : t("general.inputSearch")
                        }
                        prefix={
                          <SearchOutlined
                            style={{ fontSize: 20, color: "#468BFF" }}
                          />
                        }
                        allowClear
                      />
                    </Form.Item>
                  </Col>

                  {props?.children}

                  {!more && (
                    <Col md={2}>
                      <div className="d-flex submit-row">
                        <Form.Item name={"submit"}>
                          <Button
                            htmlType="submit"
                            type="ghost"
                            loading={loading}
                            size={"middle"}
                            className="search_btn"
                          >
                            {t("search")}
                          </Button>
                        </Form.Item>
                        {filled && props.onClearFilters && (
                          <Button
                            type="text"
                            onClick={onClearFilters}
                            className="delete_filters"
                          >
                            حذف فیلترها
                          </Button>
                        )}
                      </div>
                    </Col>
                  )}
                  {responsive && (
                    <Col md={more ? 4 : 2} className={"submit-row"}>
                      <div className="action-button">
                        {more && (
                          <Form.Item name={"submit"}>
                            <Button
                              htmlType="submit"
                              // block
                              type="ghost"
                              // loading={loading}
                              size={"middle"}
                              className="search_btn"
                            >
                              {t("search")}
                            </Button>
                          </Form.Item>
                        )}
                        {more && (
                          <>
                            <div className="search-more">
                              {openAdvanced ? (
                                <Button
                                  onClick={() => setOpenAdvanced((old) => !old)}
                                  size={isSSP ? "large" : "middle"}
                                  icon={
                                    <i class="adt icon-arrow-doable-up"></i>
                                  }
                                />
                              ) : (
                                <Button
                                  onClick={() => setOpenAdvanced((old) => !old)}
                                  size={isSSP ? "large" : "middle"}
                                  icon={
                                    <i class="adt icon-arrow-doable-up close"></i>
                                  }
                                />
                              )}
                            </div>
                            {filled && props.onClearFilters && (
                              <Button
                                type="text"
                                onClick={onClearFilters}
                                className="delete_filters"
                              >
                                حذف فیلترها
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </Col>
                  )}
                  {openAdvanced ? AdvanceFields : ""}
                </Row>
              </Col>
              {!responsive && (
                <Col md={more ? 4 : 2} className={"submit-row"}>
                  <div className="action-button">
                    {more && (
                      <Form.Item name={"submit"}>
                        <Button
                          htmlType="submit"
                          // block
                          type="ghost"
                          loading={loading}
                          size={"middle"}
                          className="search_btn"
                        >
                          {t("search")}
                        </Button>
                      </Form.Item>
                    )}
                    {more && (
                      <>
                        <div className="search-more">
                          {openAdvanced ? (
                            <Button
                              onClick={() => setOpenAdvanced((old) => !old)}
                              size={isSSP ? "large" : "middle"}
                              icon={<i class="adt icon-arrow-doable-up"></i>}
                            />
                          ) : (
                            <Button
                              onClick={() => setOpenAdvanced((old) => !old)}
                              size={isSSP ? "large" : "middle"}
                              icon={
                                <i class="adt icon-arrow-doable-up close"></i>
                              }
                            />
                          )}
                        </div>
                        {filled && props.onClearFilters && (
                          <Button
                            type="text"
                            onClick={onClearFilters}
                            className="delete_filters"
                          >
                            حذف فیلترها
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </Col>
              )}
            </Row>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};

FormSearchBar.propTypes = {
  search: PropTypes.bool,
  children: PropTypes.any,
  onFinish: PropTypes.func,
  loading: PropTypes.bool,
  more: PropTypes.bool,
  AdvanceFields: PropTypes.any,
  form_: PropTypes.any,
  onValuesChange: PropTypes.func,
  onClearFilters: PropTypes.func,
  name: PropTypes.string,
  idSearch: PropTypes.bool,
  ignoreFilled: PropTypes.string,
  responsive: PropTypes.bool,
  searchWidth: PropTypes.number,
};

export default FormSearchBar;
