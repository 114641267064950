import { ConfigProvider, Tabs } from "antd";
import React, { useMemo } from "react";
import { CopyBlock } from "react-code-blocks";

const CODE_ASYNC_TEMPLATE = `<!-- Affilio Widget BEGIN -->
<div class="affilio-widget-container"><div id="affilio_XXXXXXXXXXXXXXXX"></div></div>
<script type="text/javascript">!function(i,t,o,d,f,n,r){function a(){Affilio&&Affilio.widget(r)}i[n]?a():(s=t.createElement(o),s.id="AFF",s.async=!0,s.src="https://widget.affilio.ir/lib.js?XXXXXXXXXXXXXXXX",s.onload=function(){i[n]=Affilio,a()},e=t.getElementsByTagName(o)[0],e.parentNode.insertBefore(s,void 0))}(window,document,"script",0,0,"$affilio",{autosize:!0,container_id:"affilio_XXXXXXXXXXXXXXXX",widget_type:"ProductWidget",widget_id:"YYYYYYYYYYYYYYY",redirect_type:"ProductPage",url:"",is_smart:!1})</script>
<!-- Affilio Widget END -->`;

const CodeSelector = (props) => {
  const { code } = props;

  const codeAsync = useMemo(() => {
    var numberRegex = /\d+/g;
    const x = code?.match(numberRegex);
    if (x?.length) {
      var containerId = x[0];
      var widgetId = x[3];

      let str = CODE_ASYNC_TEMPLATE.replace("XXXXXXXXXXXXXXXX", containerId);

      str = str.replace("XXXXXXXXXXXXXXXX", containerId);
      str = str.replace("XXXXXXXXXXXXXXXX", containerId);
      str = str.replace("YYYYYYYYYYYYYYY", widgetId);

      const type = code?.includes("BannerWidget")
        ? "BannerWidget"
        : "ProductWidget";
      str = str.replace("ProductWidget", type);

      return str;
    }
    return "";
    // var widgetIdRegex = /(?<!affilio_)\d+/g;
  }, [code]);

  return (
    <ConfigProvider direction="ltr">
      <Tabs defaultActiveKey="2" dir="ltr">
        <Tabs.TabPane tab="ASYNC" key="2">
          <CopyBlock
            text={codeAsync}
            language={"html"}
            wrapLongLines
            CodeBlock
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="SYNC" key="1">
          {code ? (
            <CopyBlock
              text={code}
              language={"html"}
              // wrapLines
              wrapLongLines
              CodeBlock
            />
          ) : (
            false
          )}
        </Tabs.TabPane>
      </Tabs>
    </ConfigProvider>
  );
};

CodeSelector.propTypes = {
  code: "",
};

export default CodeSelector;
