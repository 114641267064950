import { Button, Modal, Skeleton } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getBannerWidgetCode } from "@/app/local/bannerWidgetSlice";
import { getProductWidgetCode } from "@/app/local/productWidgetSlice";
import { getPreviewCode } from "@/utils/helper";
import { prettyCode } from "@/utils/utils";

import AffilioWidgetPreview from "./AffilioWidgetPreview";
import CodeSelector from "./CodeSelector";
import ConfirmSelectMedia from "./ConfirmSelectMedia";

const AREA_TYPES = {
  Banner: "banner",
  Product: "product",
};

const STEPS = { CODE: "CODE", MEDIA: "MEDIA", PREVIEW: "PREVIEW" };

const CodeAreaModal = (props) => {
  const { open, close, confirmMedia, categoryFilter } = props;
  const [isCopy, setIsCopy] = useState();
  const [preview, setPreview] = useState();
  const [data, setData] = useState();
  const [media, setMedia] = useState();
  const [step, setStep] = useState("CODE");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isBanner = open?.type === AREA_TYPES.Banner;
  const widgetId =
    open.encrypted_widget_id || open?.Product_widget_id || open?.widgetId;
  const wId = `${isBanner ? "ban_" : "pr_"}${widgetId}`;
  const { widgetCodeLoading } = useSelector((state) => state.productWidget);

  useEffect(() => {
    if (!isBanner) {
      const result = dispatch(
        getProductWidgetCode({
          // widget_id: widgetId, last version
          encrypted_widget_id: widgetId,
        })
      );
      result.then((r) => {
        const d = r.payload?.data?.value;
        d && setData(prettyCode(d));
      });
    } else {
      const result = dispatch(getBannerWidgetCode({ widget_id: widgetId }));
      result.then((r) => {
        setData(r.payload?.data?.value);
        const d = r.payload?.data?.value;
        d && setData(prettyCode(d));
      });
    }
  }, [media]);

  useEffect(() => {
    if (confirmMedia && !media) {
      setStep(STEPS.MEDIA);
    } else {
      setStep(STEPS.CODE);
    }
  }, [confirmMedia, media]);

  const handleCopy = () => {
    data && navigator.clipboard.writeText(data);
    setIsCopy(true);
  };

  const onPreview = () => {
    if (preview) {
      setStep(STEPS.CODE);
      setPreview();
    } else {
      setStep(STEPS.PREVIEW);
      setPreview(getPreviewCode(wId, categoryFilter));
    }
  };

  return (
    <Modal
      className={"modal-code"}
      title={
        step !== STEPS.MEDIA
          ? t("widget.generatedCode")
          : t("widgetSmart.selectMedia")
      }
      visible={open}
      onCancel={close}
      width={768}
      footer={
        step !== STEPS.MEDIA
          ? [
              <Button
                key="submit"
                type="primary"
                //   loading={loading}
                onClick={handleCopy}
                style={{ float: "right" }}
              >
                {!isCopy ? t("widget.copyLink") : t("widget.copied")}
              </Button>,
              <Button key="preview" type="link" onClick={onPreview}>
                {preview ? t("widget.pre") : t("widget.preview")}
              </Button>,
            ]
          : []
      }
    >
      {step === STEPS.MEDIA && <ConfirmSelectMedia setMedia={setMedia} />}
      {step === STEPS.CODE && (
        <div>
          {widgetCodeLoading ? (
            <Skeleton active />
          ) : (
            <CodeSelector code={data} />
          )}
        </div>
      )}
      {step === STEPS.PREVIEW && (
        <div className={"preview-area"}>
          <AffilioWidgetPreview data={data} />
        </div>
      )}
    </Modal>
  );
};

CodeAreaModal.propTypes = {
  open: PropTypes.any,
  close: PropTypes.func,
  confirmMedia: PropTypes.bool,
  categoryFilter: PropTypes.array,
};

export default CodeAreaModal;
