import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useAccount } from "@/utils/hooks/useAuth";

const ImberChat = () => {
  const { profile } = useSelector((state) => state.account);
  const { lang } = useAccount();

  useEffect(() => {
    !(function () {
      window.IMBER_LANG = lang;
      var a = window,
        d = document;
      function im() {
        window.IMBER_ID = "u3v741n9bul8pr630h";
        window.IMBER_TOKEN = localStorage.getItem("imber_token");
        var i = document.createElement("div");
        i.id = "imber-top-parent";
        var x = document;
        var s = x.createElement("script");
        s.src =
          "https://widget.imber.live/imber?id=" +
          window.IMBER_ID +
          "&token=" +
          (window.IMBER_AUTH
            ? "null&auth=" + JSON.stringify(window.IMBER_AUTH)
            : window.IMBER_TOKEN);
        s.async = 1;
        x.getElementsByTagName("head")[0].appendChild(s);
        x.getElementsByTagName("body")[0].appendChild(i);
        window.$imber = {};
      }
      const isExist = d.querySelector("#imber-top-parent");
      if (!isExist) {
        "complete" === d.readyState
          ? im()
          : a.attachEvent
          ? a.attachEvent("onload", im)
          : a.addEventListener("load", im, !1);
      }
    })();
    document.addEventListener("IMBER_READY", () => {
      // Your event or function
      const userId = profile?.data?.user_id;
      const userDetail = profile?.userDetail?.data;

      if (userDetail) {
        window.$imber.setVisitor({
          email: userDetail?.email,
          name: `${userDetail?.first_name} ${userDetail?.last_name} ${userId}`,
          description: `userId ${userId}`,
          phone: "98" + profile?.data?.mobile,
        });
      }
    });
  }, []);

  return null;
};

export default ImberChat;
