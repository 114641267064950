import { makeStyles } from "@material-ui/styles";
import { PropTypes } from "prop-types";
import { clsx } from "clsx";
import CustomTextField from "./CustomTextField";

const TextArea = ({
  className,
  name,
  control,
  autoFocus,
  inputRef,
  maxRows = 5,
  label,
  inputHeight,
  value,
  onChange,
  maxLength,
  disabled,
  placeholder,
  hasError,
  errorText,
}) => {
  const classes = useStyles();

  return (
    <div>
      <CustomTextField
        variant="outlined"
        className={clsx(classes.textFieldStyle, className)}
        maxRows={maxRows}
        label={label}
        placeholder={placeholder}
        multiline={true}
        inputHeight={inputHeight}
        value={value}
        name={name}
        control={control}
        onChange={onChange}
        maxLength={maxLength}
        inputRef={inputRef}
        autoFocus={autoFocus}
        disabled={disabled}
        InputLabelProps={{ shrink: true }}
        error={hasError}
        errorText={errorText}
      />
      {maxLength ? (
        <div style={{ textAlign: "left" }}>
          <p className={classes.counter}>{`${
            value?.length ?? 0
          }/${maxLength}`}</p>
        </div>
      ) : null}
    </div>
  );
};

export default TextArea;

TextArea.propTypes = {
  control: PropTypes.any,
  className: PropTypes.object,
  maxRows: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  inputHeight: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  inputRef: PropTypes.object,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  hasCounter: PropTypes.bool,
  errorText: PropTypes.string,
};

const useStyles = makeStyles((theme) => {
  return {
    counter: {
      margin: "4px 8px",
      ...theme.typography.caption1.regular,
      color: theme.palette.secondary.main,
    },
    textFieldStyle: { width: "100%" },
  };
});
