import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment-jalaali";
import faTranslations from "./locales/fa";
moment.loadPersian({ usePersianDigits: false, dialect: "persian-modern" });

const resources = {
  fa: faTranslations,
};
i18n.use(initReactI18next).init({
  resources,
  lng: "fa",
  defaultNS: "common",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
