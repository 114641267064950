export const VERIFICATION_CODE_COUNT = 4;
export const VERIFICATION_RESEND_SECONDS = 120;
export const DEFAULT_LOCALE = "fa";
export const MOBILE_FIXED_PREFIX = "09";
export const PHONE_FIXED_PREFIX = "0";
export const FIRST_NAME_MIN_LENGTH = 3;
export const FIRST_NAME_MAX_LENGTH = 20;
export const LAST_NAME_MIN_LENGTH = 3;
export const LAST_NAME_MAX_LENGTH = 30;
export const PASSWORD_MIN_LENGTH = 8;
export const BIRTH_DATE_MIN_AGE = 18;
export const BIRTH_DATE_MAX_AGE = 70;
export const ADDRESS_MIN_LENGTH = 15;
export const ADDRESS_MAX_LENGTH = 100;
export const IMAGE_MAX_SIZE_MB = 2;
export const TABLE_PAGES_SIZES = [10, 25, 100];
export const IMAGE_MIME_TYPES = ["image/jpg", "image/jpeg", "image/png"];
export const MASK_MOBILE_INPUT = "0000 000 0000";
export const MASK_SEHBA = "00 0000 0000 0000 0000 0000 00";
export const MIG_MIG_API = "https://migmig-stage.affilio.ir/api/v1/Click";
export const SHORTNER_BASE_LINK = "https://link.affilo/";
export const CITY_PHONE_NUMBER_REGEX_CODE = "^0\\d{1,3}$";
export const CITY_PHONE_NUMBER_REGEX_NUMBER = "^\\d{3,8}$";
export const CSV_MIME_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const ACADEMIA_LINK = "https://affilio.ir?refer=panel";
export const ACADEMIA_LINK_SSP = "https://docs.adverge.ir";
// export const ADV_LINK = "http://localhost:8888/wordpress/wp-json/wp/v2/";
export const ADV_LINK = "https://wp.affilio.ir/wp-json/wp/v2/";
export const PLP_ADDRESS = "plp.affilio.ir";
export const PLP_STAGE_ADDRESS = "plp-stage.affilio.ir";
export const REFRESH_TOKEN_TIME = 1000 * 60 * 30;
export const RAYCHAT_AFF_TOKEN = "be011cd2-f24b-4f8c-afb6-4ad6c99b30fe";
export const RAYCHAT_SSP_TOKEN = "492edccb-11c3-4c27-8535-fe0c228155cd";
// export const ADV_LINK = "https://wp.webutils.ir/wp-json/wp/v2/";
export const HELPICAL_DEPARTMENT_ID = 4; // AFFILIO DEPRATMENT HELIPICAL
export const INTRACK_CONFIG = {
  APP_KEY: "AAAAxg",
  AUTH_KEY: "ea4da4c6-2d04-4eee-84ea-0eec4d49918c",
  PUBLIC_KEY:
    "BJNoUN3MEEix2zkwLxVWagOfcsf3Wob8kHZNgbZaEyaQAfitQrx2kdKykY4zfitHc-G2QptlBvE88O7bJGxpwMI=",
};

export const MANUAL_CONFIG = {
  SHOW_PAYMENT_DATE: false,
  IS_MAJOR_VERSION: true,
  IS_TOUR_ACTIVE: false,
  IS_HOTJAR_ACTIVE: true,
  IS_FAQ_ACTIVE: false,
};
