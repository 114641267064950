import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { PositionTypeOptions } from "@/components/input/selectPositionType";

import AdPlaceLiveViewer from "../AdPlaceLiveViewer";
import CardSkeleton from "./CardSkeleton";
import "./FloatConfig.less";
import FormMaster from "./FormMaster";
import SkeltonGenerator from "./SkeltonGenerator";

const FloatConfig = (props) => {
  const { doFunction, form } = props;
  const [pos, setPos] = useState();

  const onFinish = (event) => {
    doFunction(event);
  };

  const onValuesChange = (e) => {
    if (e?.position_type) {
      const key = Object.keys(PositionTypeOptions).find((key) => {
        return PositionTypeOptions[key] === e?.position_type;
      });
      setPos(key);
    }
  };

  return (
    <Row gutter={[24, 24]} className="main-config float-config">
      <Col xs={10}>
        <div className="slider-config__main">
          <FormMaster
            config={{
              start_delay_second: 0,
              enable_to_close_delay_second: 5,
              position_type: 3,
            }}
            form={form}
            onValuesChange={onValuesChange}
            onFinish={onFinish}
          />
        </div>
      </Col>
      <Col xs={14}>
        <AdPlaceLiveViewer>
          <div className="box-preview">
            <div className="box-preview__content">
              <h2 className="skeleton"></h2>
              <SkeltonGenerator count={22} />
            </div>
            <div className={`box-preview__widget type_${pos}`}>
              <CardSkeleton />
            </div>
          </div>
        </AdPlaceLiveViewer>
      </Col>
    </Row>
  );
};

FloatConfig.propTypes = {
  doFunction: PropTypes.any,
  form: PropTypes.any,
};
export default FloatConfig;
