import { PlusCircleFilled } from "@ant-design/icons";
import {
  DesktopOutlined as IconDesktop,
  MobileOutlined as IconPhone,
  TabletOutlined as IconTablet,
} from "@ant-design/icons";
import { Button, Col, Form, Popconfirm, Select, Switch, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  cloneInventory,
  deleteInventory,
  getInventoryList,
  updateInventoryStatus,
} from "@/app/local/inventorySlice";
import MainContainer from "@/components/container/MainContainer";
import PageTitle from "@/components/general/PageTitle";
import TableComponent from "@/components/general/TableComponent";
import SelectMedia from "@/components/input/selectMedia";
import FormSearchBar from "@/components/micro/FormSearchBar";
import InfoAdPlace from "@/components/micro/InfoAdplace";
import FormGetCodePlace from "@/ssp/FormGetCodePlace";
import {
  DEVICE_TYPE,
  INVENTORY_FORMATS,
  INVENTORY_STATUS,
  INVENTORY_TYPE,
} from "@/utils/constants/apiConstants";
import { objectR, priceFormat } from "@/utils/helper";
import { getFinalFilters, getLastParams } from "@/utils/utils";

import IconCode1 from "./../../ssp/assets/IconCode.svg";
import "./places.less";

const Places = () => {
  const { inventories, inventoriesLoading } = useSelector(
    (state) => state.inventory
  );
  const [selectedInv, setSelectedInv] = useState();
  const [form] = useForm();
  const nav = useNavigate();
  const [extraData, setExtraData] = useState(null);
  const { t } = useTranslation("ssp");

  const dispatch = useDispatch();
  useEffect(() => {
    onFinishSearch({
      sort_field: "inventory_id",
      sort_orientation: 1,
      page_size: 10,
    });
  }, []);

  let heads = useMemo(() => {
    return [
      {
        title: t("place.inventory_id"),
        key: "inventory_id",
        sort: true,
      },
      {
        title: t("place.name"),
        key: "name",
        sort: true,
      },
      {
        title: t("media.name"),
        key: "media",
        sort: true,
      },
      {
        title: t("place.type"),
        key: "type",
        sort: true,
      },
      // {
      //   title: t("place.device_type"),
      //   key: "device_type",
      // },
      // {
      //   title: t("place.bid_floor"),
      //   key: "bid_floor",
      // },
      {
        title: t("place.format"),
        key: "format_type",
      },
      {
        title: t("place.create_date"),
        key: "create_date",
        type: "date",
        sort: true,
      },
      {
        title: t("status_"),
        key: "status",
        sort: true,
      },
      {
        title: t("get_code"),
        key: "get_code",
      },
      {
        title: t("media.actions"),
        key: "actions",
        type: "actions",
        fixed: "right",
        width: "80px",
      },
    ];
  }, []);

  const getDeviceIcons = (d_) => {
    let d = d_;
    if (!_.isArray(d_)) {
      d = [d];
    }
    const result = [];
    if (d.includes(DEVICE_TYPE.Mobile)) {
      result.push(<IconPhone />);
    }
    if (d.includes(DEVICE_TYPE.Tablet)) {
      result.push(<IconTablet />);
    }
    if (d.includes(DEVICE_TYPE.Desktop)) {
      result.push(<IconDesktop />);
    }
    return (
      <ul className="devices-cell">
        {result.map((item) => {
          return <li key={item.id}>{item}</li>;
        })}
      </ul>
    );
  };

  const onFinishSearch = async (ev) => {
    const e = getFinalFilters(extraData, ev, {});

    const filter = {
      type: e?.type ? [e?.type] : undefined,
      status: e?.status ? [e?.status] : undefined,
      format_type: e?.format ? [e?.format] : undefined,
      media_ids: e?.website ? [e?.website] : undefined,
    };

    const params = getLastParams(filter, e);

    const result = await dispatch(getInventoryList(params));
    setExtraData(e);
    if (!result.payload.success) {
      message.error({
        key: "ERROR-SEARCH-BAR",
        content: result?.payload?.Message || t("general.unHandleErrorMessage"),
      });
    }
  };

  const onGetCode = (item) => {
    setSelectedInv(item);
  };

  const handlePagination = (e) => {
    onFinishSearch({
      ...e,
    });
  };

  const getStatus = (v) => {
    return (
      <Popconfirm
        disabled
        placement="top"
        title={
          v !== INVENTORY_STATUS.active
            ? t("general.sureToSetActive")
            : t("general.sureToUnsetActive")
        }
        onConfirm={async () => {
          // removeItem(docFileId);
          dispatch(
            updateInventoryStatus({
              inventory_id: 1,
            })
          );
        }}
        okText={t("general.ok")}
        cancelText={t("general.no")}
      >
        <Switch
          disabled
          checked={
            v === INVENTORY_STATUS.active || v === INVENTORY_STATUS.unknown
          }
        />
      </Popconfirm>
    );
  };
  const itemsList = useMemo(() => {
    let items_ = _.get(inventories, "data.result", []);
    items_ = items_.map((item) => {
      // const dType = t(`device.${objectR(DEVICE_TYPE)[item.device_type]}`);
      const dType = getDeviceIcons(item.device_type);
      const getCode = () => {
        return (
          <div>
            <Button type="text" onClick={() => onGetCode(item)} size="small">
              <img src={IconCode1} style={{ width: "24px" }} />
            </Button>
          </div>
        );
      };

      const actions = [];
      actions.push({
        name: t("actions.analytics"),
        link: `/places/${item.inventory_id}/report`,
        // disabled: true,
      });
      actions.push({
        name: t("actions.edit"),
        link: `/places/${item.inventory_id}`,
        // disabled: true,
      });
      actions.push({
        name: t("actions.clone"),
        type: "CUSTOM_NODE",
        customNode: (
          <Popconfirm
            placement="top"
            title={t("general.sureForClone")}
            onConfirm={async () => {
              const respDelete = await dispatch(
                cloneInventory(item?.inventory_id)
              );
              if (respDelete?.payload.success) {
                message.success({
                  content: t("general.successFullyDone"),
                  key: "inv",
                  duration: 1,
                });
                onFinishSearch(extraData);
              } else {
                message.error({
                  content:
                    respDelete?.payload?.Message ||
                    t("general.UnexpectedErrorOccurred"),
                  key: "inv",
                  duration: 1,
                });
              }
            }}
            okText={t("general.ok")}
            cancelText={t("general.no")}
          >
            <a>{t("actions.clone")}</a>
          </Popconfirm>
        ),
        disabled: true,
      });
      actions.push({
        name: t("actions.delete"),
        type: "CUSTOM_NODE",
        customNode: (
          <Popconfirm
            placement="top"
            title={t("general.sureForDelete")}
            onConfirm={async () => {
              const respDelete = await dispatch(
                deleteInventory(item?.inventory_id)
              );
              if (respDelete?.payload.success) {
                message.success({
                  content: t("general.successFullyDone"),
                  key: "inv",
                  duration: 1,
                });
                onFinishSearch(extraData);
              } else {
                message.error({
                  content:
                    respDelete?.payload?.Message ||
                    t("general.UnexpectedErrorOccurred"),
                  key: "inv",
                  duration: 1,
                });
              }
            }}
            okText={t("general.ok")}
            cancelText={t("general.no")}
          >
            <a>{t("general.delete")}</a>
          </Popconfirm>
        ),
      });

      return {
        ...item,
        type: t(`type.${objectR(INVENTORY_TYPE)[item.type]}`),
        media: item?.media?.name || "-",
        device_type: dType,
        bid_floor: priceFormat(item.bid_floor),
        format_type: t(
          `formats.${objectR(INVENTORY_FORMATS)[item.format_type]}`
        ),
        // status: t(`status.${objectR(INVENTORY_STATUS)[item.status]}`),
        status: getStatus(item.status),
        get_code: getCode(),
        actions: actions,
      };
    });
    return items_;
  }, [inventories?.data]);

  const items = _.get(inventories, "data.total_items", 10);
  const pages = _.get(inventories, "data.total_pages", 1);
  const pageSize = _.get(inventories, "data.page_size", 10);
  const page = _.get(inventories, "data.page", 1);
  const tableInfo = { items, pages, pageSize, serverPagination: true, page };

  return (
    <div className="places">
      <PageTitle title={"AdPlaces"} tour_id={"place-title"}>
        <a onClick={() => nav("/places/new")} className="plus">
          <PlusCircleFilled className="page__plus" />
        </a>
      </PageTitle>

      <MainContainer>
        <InfoAdPlace />
        <FormSearchBar
          name="formSearch"
          form_={form}
          onFinish={onFinishSearch}
          // onClearFilters={onClearFilters}
        >
          <Col md={4} xs={24}>
            <Form.Item name={"type"}>
              <Select placeholder={t("place.type")} allowClear>
                {_.keys(INVENTORY_TYPE).map((key) => {
                  return (
                    <Select.Option key={key} value={INVENTORY_TYPE[key]}>
                      {t(`type.${key}`)}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col md={4} xs={24}>
            <Form.Item name={"format"}>
              <Select placeholder={t("place.format")} allowClear>
                {_.keys(INVENTORY_FORMATS).map((key) => {
                  return (
                    <Select.Option key={key} value={INVENTORY_FORMATS[key]}>
                      {t(`formats.${key}`)}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col md={4} xs={24}>
            <SelectMedia
              name="website"
              size="default"
              placeholder={t("media.name")}
            />
          </Col>

          <Col md={3} xs={24}>
            <Form.Item name={"status"}>
              <Select placeholder={t("status_")} allowClear>
                {_.keys(INVENTORY_STATUS).map((key) => {
                  return (
                    <Select.Option key={key} value={INVENTORY_STATUS[key]}>
                      {t(`status.${key}`)}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </FormSearchBar>

        <TableComponent
          loading={inventoriesLoading}
          heads={heads}
          data={itemsList}
          info={tableInfo}
          // handleSort={handleSort}
          handlePagination={handlePagination}
          form_={form}
          onFinishSearch={onFinishSearch}
          scroll={{
            x: 1200,
          }}
        />
      </MainContainer>
      {selectedInv ? (
        <FormGetCodePlace detail={selectedInv} setOpen={setSelectedInv} />
      ) : (
        false
      )}
    </div>
  );
};

export default Places;
