import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ENDPOINTS } from "@/utils/constants/apiConstants";

import { baseAPI } from "../services/fetchers";

export const getContractTemplatesListAsync = createAsyncThunk(
  "contracts/getContractTemplatesListAsync",
  async (data) => {
    try {
      const body_ = Object.assign({}, data);
      const end_ = ENDPOINTS.ADMIN_CONTRACT_TEMPLATE_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getContractsListAsync = createAsyncThunk(
  "contracts/getContractsListAsync",
  async (data) => {
    try {
      const body_ = Object.assign({}, data);
      const end_ = ENDPOINTS.CONTRACT_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getAdminContractsListAsync = createAsyncThunk(
  "contracts/getAdminContractsListAsync",
  async (data) => {
    try {
      const data_ = {
        filter: {},
        page: 1,
        page_size: 10,
      };
      const body_ = Object.assign({}, data_, data);
      const end_ = ENDPOINTS.ADMIN_CONTRACT_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getDefaultContractUserAsync = createAsyncThunk(
  "contract/getDefaultContractUserAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.CONTRACT_GET_USER_DEFAULT.replace(
        "{user_id}",
        data.uId
      ).replace("{userType}", data?.userType);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const contractApproveAsync = createAsyncThunk(
  "contract/contractApproveAsync",
  async (cId) => {
    try {
      const end_ = ENDPOINTS.CONTRACT_APPROVE.replace("{contract_id}", cId);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const contractRejectAsync = createAsyncThunk(
  "contract/contractRejectAsync",
  async (cId) => {
    try {
      const end_ = ENDPOINTS.CONTRACT_REJECT.replace("{contract_id}", cId);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getContractDetailAsync = createAsyncThunk(
  "contract/getContractDetailAsync",
  async (cId) => {
    try {
      const end_ = ENDPOINTS.CONTRACT_GET.replace("{contract_id}", cId);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const uploadPaperContractAsync = createAsyncThunk(
  "contract/uploadPaperContractAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.CONTRACT_UPLOAD_PAPER.replace(
        "{contract_id}",
        data.contract_id
      );

      end_ = end_.replace("{file_id}", data.file_id);

      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const contractSlice = createSlice({
  name: "contract",
  initialState: {
    loading: false,
    loadingAction: false,
    contracts: null,
    contractsLoading: null,
    contract: null,
    contractDetail: null,
    contractLoading: null,
    error: null,
    loadingApprove: false,
    loadingReject: false,
  },
  reducers: {
    clearState: (state) => {
      state.contracts = [];
      state.contract = false;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDefaultContractUserAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getDefaultContractUserAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getDefaultContractUserAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.contract = action.payload;
      }
      state.loading = false;
      state.error = null;
    });
    builder.addCase(contractApproveAsync.pending, (state, action) => {
      state.loadingApprove = true;
    });
    builder.addCase(contractApproveAsync.rejected, (state, action) => {
      state.loadingApprove = false;
    });
    builder.addCase(contractApproveAsync.fulfilled, (state, action) => {
      state.loadingApprove = false;
    });
    builder.addCase(contractRejectAsync.pending, (state, action) => {
      state.loadingReject = true;
    });
    builder.addCase(contractRejectAsync.rejected, (state, action) => {
      state.loadingReject = false;
    });
    builder.addCase(contractRejectAsync.fulfilled, (state, action) => {
      state.loadingReject = false;
    });
    builder.addCase(getContractsListAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getContractsListAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getContractsListAsync.fulfilled, (state, action) => {
      state.contractsLoading = false;
      state.contracts = action.payload;
      state.error = null;
    });
    builder.addCase(getAdminContractsListAsync.pending, (state, action) => {
      state.contractsLoading = true;
      state.error = null;
    });
    builder.addCase(getAdminContractsListAsync.fulfilled, (state, action) => {
      state.contractsLoading = false;
      state.contracts = action.payload;
      state.error = null;
    });
    builder.addCase(getAdminContractsListAsync.rejected, (state, action) => {
      state.contractsLoading = false;
      state.error = action.error;
    });
    builder.addCase(getContractDetailAsync.pending, (state, action) => {
      state.contractLoading = true;
      state.error = null;
    });
    builder.addCase(getContractDetailAsync.fulfilled, (state, action) => {
      state.contractLoading = false;
      state.contractDetail = action.payload;
      state.error = null;
    });
    builder.addCase(getContractDetailAsync.rejected, (state, action) => {
      state.contractLoading = false;
      state.error = action.error;
    });
  },
});

export const { clearState } = contractSlice.actions;

export const getContractTemplatesList = (data) => async (dispatch) => {
  return dispatch(getContractTemplatesListAsync(data));
};

export const getDefaultContractUser = (data) => async (dispatch) => {
  return dispatch(getDefaultContractUserAsync(data));
};

export const contractApprove = (cId) => async (dispatch) => {
  return dispatch(contractApproveAsync(cId));
};
export const contractReject = (cId) => async (dispatch) => {
  return dispatch(contractRejectAsync(cId));
};

export const getContractsList = (data) => async (dispatch) => {
  return dispatch(getContractsListAsync(data));
};

export const getAdminContractsList = (data) => async (dispatch) => {
  return dispatch(getAdminContractsListAsync(data));
};

export const getContractDetail = (data) => async (dispatch) => {
  return dispatch(getContractDetailAsync(data));
};

export const uploadPaperContract = (data) => async (dispatch) => {
  return dispatch(uploadPaperContractAsync(data));
};

export default contractSlice.reducer;
