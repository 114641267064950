import { Alert } from "antd";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { KYC_STATUS, USER_STATUS } from "@/utils/constants/apiConstants";

export const KYCAlert = () => {
  const { t } = useTranslation();

  const { profile } = useSelector((state) => state.account);
  const userInfo = _.get(profile, "userDetail.data");
  const userStatus = userInfo?.user?.status;
  const kycStatus = userInfo?.user?.kyc_status;
  const kycDesc = userInfo?.user?.kyc_desc;

  if (userStatus === USER_STATUS.KysError) {
    if (KYC_STATUS.PendingForApproval === kycStatus) {
      return (
        <Alert
          className="kyc_alert"
          showIcon
          message={<b>{t("auth.kyc.PendingForApproval")}</b>}
        ></Alert>
      );
    } else if (kycDesc) {
      return (
        <Alert
          className="kyc_alert"
          showIcon
          type="error"
          message={`${kycDesc}`}
        ></Alert>
      );
    }
  }
  return null;
};
export default KYCAlert;
