import { Button, Form, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import { getMerchantDetail } from "@/app/local/adminMerchantSlice";
import {
  getPublishDetail,
  setLegalStatusPublisherAdmin,
} from "@/app/local/adminPublisherSlice";
import { LEGAL_STATUS, USER_TYPE } from "@/utils/constants/apiConstants";

const FormUpdateLegalUser = (props) => {
  const userType = _.get(props, "userInfo.user.user_type");
  const userDetail = _.get(props, "userInfo");

  const path_ = useMatch("/publishers/:id");
  const uId = path_?.params?.id;

  const [form] = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userDetail) {
      form.setFieldsValue({ status: userDetail?.legal_status });
    }
  }, [userDetail]);

  const { loading } = useSelector((state) => state.adminUser);

  const onFinish = async (e) => {
    const res = await dispatch(
      setLegalStatusPublisherAdmin({
        userId: uId,
        status: e.status,
      })
    );

    if (res.payload?.success) {
      message.success({
        content: t("general.saveSuccessFully"),
        key: "user",
      });
      if (userType === USER_TYPE.PUBLISHER) {
        dispatch(getPublishDetail(uId));
      } else {
        dispatch(getMerchantDetail(uId));
      }
      props.close();
    } else {
      message.error({
        content: res.payload?.Message || t("general.unHandleErrorMessage"),
        key: "user",
      });
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name={"status"}
        label={t("users.UserType")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select allowClear placeholder={t("users.UserType")}>
          {Object.keys(LEGAL_STATUS).map((key) => (
            <Select.Option key={key} value={LEGAL_STATUS[key]}>
              {t("users." + key)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          // offset: 8,
          span: 16,
        }}
        className="form_actions"
      >
        <Button loading={loading} size="large" type="primary" htmlType="submit">
          {t("general.save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

FormUpdateLegalUser.propTypes = {
  userInfo: PropTypes.object,
  close: PropTypes.func,
};

export default FormUpdateLegalUser;
