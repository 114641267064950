import { Layout, Menu } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import LoadingBar from "react-top-loading-bar";

import AppLoading from "@/components/main/Loading";
import { MANUAL_CONFIG } from "@/utils/constants/configConstants";
import { useAccount } from "@/utils/hooks/useAuth";
import useConfig from "@/utils/hooks/useConfig";

import Hotjar from "../extra/Hotjar";
import InTrackSDK from "../extra/Intrack/InTrackSDK";
import MainTicketChat from "../extra/MainTicketChat";
// import OnboardingTour from "../onboarding/OnboardingTour";
import AppBar from "./Appbar";
import ErrorBoundaryPrime from "./ErrorBoundaryPrime";
import Sidebar from "./Sidebar";
import SidebarHelium from "./SidebarHelium";
import "./layout.less";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const MainLayout = (props) => {
  const { children, mode } = props;
  const isWizard = mode === "wizard";
  const { isPublisher, role, isAdvertiser, isAdmin } = useAccount();
  const { isSSP } = useConfig();

  const [collapsed, setCollapsed] = useState();

  const ref = useRef(null);
  const { loadingBar } = useSelector((state) => state.app);
  useEffect(() => {
    if (loadingBar) ref.current.continuousStart();
    else ref.current.complete();
  }, [loadingBar]);

  return (
    <Layout style={{ minHeight: "100vh" }} className={`${role}-LAYOUT`}>
      <LoadingBar color="#fa8c15" ref={ref} />
      {!isWizard ? (
        <>
          {isPublisher || isAdvertiser || (isSSP && isAdmin) ? (
            <SidebarHelium
              mode={mode}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
          ) : (
            <Sidebar
              mode={mode}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
          )}
        </>
      ) : null}
      <Layout className={`site-layout ${isWizard ? "is-wizard" : ""}`}>
        {MANUAL_CONFIG.IS_HOTJAR_ACTIVE ? <Hotjar /> : ""}
        {/* <OnboardingTour /> */}
        <Header className="site-layout-header" style={{ padding: 0 }}>
          <AppBar
            isWizard={isWizard}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
        </Header>
        <Content className="site-layout-content">
          <Suspense fallback={<AppLoading open={true} height="80vh" />}>
            <ErrorBoundaryPrime>
              <div>{children}</div>
            </ErrorBoundaryPrime>
          </Suspense>
        </Content>
        <Footer className="site-layout-footer">
          {isSSP ? "ADVERGE-SSP" : "AFFILIO"} 2017 - {moment().format("YYYY")}
        </Footer>
      </Layout>
      <MainTicketChat />
      <InTrackSDK />
      {/* <SentryComponent /> */}
      {/* <RayChat /> */}
    </Layout>
  );
};

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
  mode: PropTypes.string,
};

export default MainLayout;
