import { Button, Form, Input, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { setPublisherInvoicesPayoutStatus } from "@/app/local/invoiceSlice";
import { PaymentStatus } from "@/utils/constants/apiConstants";

const FormPayoutStatusPublisher = (props) => {
  const { data } = props;
  const [form] = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { payout } = data;
  useEffect(() => {
    form.setFieldsValue({
      status: payout.payment_status,
      description: payout.description,
    });
  }, [payout]);

  const onFinish = async (e) => {
    const res = await dispatch(
      setPublisherInvoicesPayoutStatus({
        invoice_id: payout?.publisher_invoice_id,
        status: e.status,
        description: e.description,
      })
    );
    if (res.payload?.success) {
      message.success({
        content: t("general.saveSuccessFully"),
      });
      props.close();
      props.onFinishSearch();
    } else {
      message.error({
        content: res.payload?.Message || t("general.unHandleErrorMessage"),
      });
    }
  };

  return (
    <Form
      form={form}
      initialValues={{
        payoutId: payout?.publisher_invoice_id,
      }}
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item name="payoutId" label={t("payoutPublisher.invoiceId")}>
        <Input disabled />
      </Form.Item>

      <Form.Item
        name={"status"}
        label={t("invoice.status_payment")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select size="large" placeholder={t("general.select")}>
          {Object.keys(PaymentStatus).map((key) => {
            return (
              <Select.Option key={key} value={PaymentStatus[key]}>
                {t(`invoice.pay_status_.${key}`)}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {(pre) => {
          const status_ = pre.getFieldValue("status");
          if (
            status_ === PaymentStatus.TreasuryRejected ||
            status_ === PaymentStatus.PaymentBlock
          )
            return (
              <Form.Item
                name={"description"}
                label={t("invoice.description")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TextArea rows={2} />
              </Form.Item>
            );
        }}
      </Form.Item>

      <Form.Item
        wrapperCol={{
          span: 16,
        }}
        className="form_actions"
      >
        <Button loading={false} size="large" type="primary" htmlType="submit">
          {t("general.save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

FormPayoutStatusPublisher.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func,
  onFinishSearch: PropTypes.func,
};

export default FormPayoutStatusPublisher;
