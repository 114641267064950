import { Button, Typography } from "antd";
import Form from "antd/lib/form/Form";
import { t } from "i18next";
import PropTypes from "prop-types";
import React from "react";

import SelectMedia from "../input/selectMedia";

const ConfirmSelectMedia = (props) => {
  const onFinish = (e) => {
    props.setMedia(e.media);
  };

  return (
    <div>
      <p>
        {t("widgetSmart.messageSelectMedia")}:
        {/* <TooltipQuestion title={t("widgetSmart.helpText")} /> */}
      </p>
      <Typography.Paragraph>
        <blockquote>{t("widgetSmart.helpText")}</blockquote>
      </Typography.Paragraph>
      <br />
      {/* {roleId === USER_TYPE.PUBLISHER && ( */}
      <Form onFinish={onFinish}>
        <SelectMedia
          name={"media"}
          // label={t("widgetSmart.media")}
          placeholder={t("widgetSmart.media")}
          rules={[
            {
              required: true,
              message: t("required"),
            },
          ]}
          size="default"
        />

        <Button type={"primary"} htmlType="submit">
          {t("general.submitContinue")}
        </Button>
      </Form>
    </div>
  );
};

ConfirmSelectMedia.propTypes = {
  setMedia: PropTypes.func,
};

export default ConfirmSelectMedia;
