import { Pie } from "@ant-design/plots";
import { Col, Row } from "antd";
import { t } from "i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPublisherOverview } from "@/app/local/reportSlice";
import { DashboardContext } from "@/pages/dashboard/DashboardAdmin";

import NameTag from "../micro/NameTag";
import TileNumber from "../micro/TileNumber";
import { COLORS } from "./colors";
import WidgetWrapper from "./micro/WidgetWrapper";

const dataSample = [
  {
    type: "dashboard.privatePublisher",
    value: 0,
  },
  {
    type: "dashboard.legalPublisher",
    value: 0,
  },
];

const PublishersAndLinksAndActiveMedias = (props) => {
  const { title } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [dataPie, setDataPie] = useState(dataSample);
  const [context, dispatch_] = useContext(DashboardContext);
  const { publisherOverview, publisherOverviewLoading } = useSelector(
    (state) => state.report
  );

  useEffect(() => {
    onSubmit();
  }, [context]);

  useEffect(() => {
    if (publisherOverview) {
      const responseData = _.get(publisherOverview, "data.data", []);
      const response = _.get(publisherOverview, "data", {});
      const result = {
        totalPublishers: response?.total_publishers || 0,
        activePublishers: response?.total_active_publisher || 0,
        activeMedia: response?.total_active_media || 0,
        activeLinks: response?.total_active_link || 0,
        privatePublishers: response?.total_real_publisher || 0,
        publicPublishers: response?.total_legal_publisher || 0,
      };

      setData(result);
      setDataPie([
        {
          type: "dashboard.privatePublisher",
          value: result.privatePublishers,
        },
        {
          type: "dashboard.legalPublisher",
          value: result.publicPublishers,
        },
      ]);
    }
  }, [publisherOverview]);

  const Pieconfig = useMemo(() => {
    return {
      height: 220,
      appendPadding: 10,
      legend: false,
      data: dataPie,
      angleField: "value",
      colorField: "type",
      radius: 0.9,
      label: {
        type: "inner",
        offset: "-30%",
        autoRotate: false,
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
          fontSize: 12,
          textAlign: "center",
        },
      },
      tooltip: {
        formatter: (datum) => {
          return { name: t(datum.type), value: datum.value };
        },
      },
      theme: {
        styleSheet: {
          fontFamily: "IRANYekan",
        },
      },
      pieStyle: ({ type }) => {
        if (type === "dashboard.privatePublisher") {
          return {
            fill: COLORS.DASHBOARD_BLUE,
          };
        }
        if (type === "dashboard.legalPublisher") {
          return {
            fill: COLORS.DASHBOARD_ORANGE,
          };
        }
      },
      interactions: [
        {
          type: "element-active",
        },
      ],
    };
  }, [dataPie]);

  const onSubmit = () => {
    dispatch(
      getPublisherOverview({
        web_store_ids: context.webstore,
        media_ids: context?.medias,
        from: context.period.fromDate,
        to: context.period.toDate,
        publisher_ids: context?.publisher_ids,
        filter_date_by: context?.filter_date_by,
      })
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        gap: "10px",
      }}
    >
      {/* <Row gutter={[12]} className="">
        <Col md={12} xs={24}>
          <CardInfo
            title="activeMediasCount"
            img="PinkVol"
            value={data?.activeMedia}
          />
        </Col>
        <Col md={12} xs={24}>
          <CardInfo
            title="activeLinksCount"
            img="OrangeAttach"
            value={data?.activeLinks}
          />
        </Col>
      </Row> */}

      <WidgetWrapper
        hideSwitch
        title={t(`dashboard.${title}`)}
        onSubmit={onSubmit}
        className="admin_commission_chart publisher-chart"
        hideFilter
      >
        <Row>
          <Col md={24} xs={24}>
            <div className="tile-bar no-flex">
              <Row>
                <Col md={12} xs={24}>
                  <TileNumber
                    title={t("dashboard.confirmedPublishers")}
                    number={data?.totalPublishers}
                    align="center"
                    color={"color_5"}
                  />
                </Col>
                <Col md={12} xs={24}>
                  <TileNumber
                    title={t("dashboard.activePublishers")}
                    number={data?.activePublishers}
                    align="center"
                    color={"color_2"}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            md={24}
            xs={24}
            className="pie-border"
            dir="ltr"
            style={{ direction: "ltr" }}
          >
            <Pie {...Pieconfig} loading={publisherOverviewLoading} />
            <p className="pie-description">{t("dashboard.pieDescription")}</p>
            <div className={`tags-bar align__center`}>
              <NameTag
                title={t("dashboard.privatePublisher")}
                color="color_6"
              />
              <NameTag title={t("dashboard.legalPublisher")} color="color_3" />
            </div>
          </Col>
        </Row>
      </WidgetWrapper>
    </div>
  );
};

PublishersAndLinksAndActiveMedias.propTypes = {
  title: PropTypes.string,
};
export default PublishersAndLinksAndActiveMedias;
