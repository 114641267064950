import { Form, Select } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCategoryList } from "@/app/local/websiteSlice";

const SelectCategory = (props) => {
  const { name, label, rules, placeholder, size, mode, all, websiteId } = props;
  const dispatch = useDispatch();
  const [treeData_, setTreeData_] = useState();

  const { categories, categoriesLoading } = useSelector(
    (state) => state.website
  );

  const data_ = useMemo(() => {
    const rtn =
      categories?.data?.filter((item) => item.is_root && item.is_display) || [];
    setTreeData_(rtn);
    return rtn;
  }, [categories]);

  useEffect(() => {
    websiteId &&
      dispatch(
        getCategoryList({ website_id: websiteId, is_pagination: false })
      );
  }, [websiteId]);

  return (
    <Form.Item name={name} label={label} rules={rules}>
      <Select
        loading={categoriesLoading}
        allowClear
        mode={mode}
        size={size || "medium"}
        placeholder={t("widgetSmart.category")}
        showSearch
        showArrow
        filterOption={(input, option) => {
          return (
            option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
      >
        {treeData_?.map((item) => (
          <Select.Option key={item.Category_id} value={item.Category_id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

SelectCategory.propTypes = {
  mode: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  rules: PropTypes.array,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  all: PropTypes.bool,
  websiteId: PropTypes.number,
};

export default SelectCategory;
