const ACCESS_TYPE = {
  WRITE: "WRITE",
  READ: "READ",
  HIDE: "HIDE",
};

export const Privileges = {
  publisher: {
    profile: {
      sheba: ACCESS_TYPE.READ,
      mobile: ACCESS_TYPE.READ,
      // nationalCode: ACCESS_TYPE.READ,
      // identityNumber: ACCESS_TYPE.READ,
      // registerNumber: ACCESS_TYPE.READ,
      // nationalId: ACCESS_TYPE.READ,
    },
    VIEW_PUBLISHER_DETAIL: 255,
  },
  merchant: {
    profile: { sheba: ACCESS_TYPE.READ, mobile: ACCESS_TYPE.READ },
  },
  Dashboard: {
    DASHBOARD_ADMIN: 101,
    DASHBOARD_PUBLISHER: 102,
    DASHBOARD_MERCHANT: 103,
    DASHBOARD_ADMIN_SSP: 104,
  },
  MEDIA: {
    MEDIA_MGM_VIEW: 161,
    MEDIA_MGM_CREATE: 162,
    MEDIA_MGM_REMOVE: 163,
    MEDIA_MGM_EDIT: 164,
    MEDIA_MGM_SET_STATUS: 165,
  },
  LINK: {
    LINK_MGM_VIEW: 171,
    LINK_MGM_CREATE: 172,
    LINK_MGM_SET_STATUS: 173,
    LINK_MGM_EDIT: 174,
  },
  WIDGET_BANNER: {
    WIDGET_VIEW: 191,
    WIDGET_CREATE: 192,
    WIDGET_EDIT: 193,
  },
  WIDGET: {
    WIDGET_VIEW: 191,
    WIDGET_CREATE: 192,
    WIDGET_EDIT: 193,
    WIDGET_SMART_VIEW: 194,
    WIDGET_SMART_EDIT: 195,
    WIDGET_SMART_CREATE: 196,
    PRODUCT_LIST_VIEW: 181,
  },
  INVENTORY: {
    WIDGET_VIEW: 260,
    WIDGET_CREATE: 261,
    WIDGET_EDIT: 262,
    WIDGET_LIST: 263,
  },
  WEBSTORE: {
    WEBSTORE_LIST_VIEW: 131,
    WEBSTORE_CREATE: 132,
    WEBSTORE_DETAIL_VIEW: 133,
    WEBSTORE_DETAIL_EDIT: 134,
    WEBSTORE_LEVEL_CREATE: 135,
    WEBSTORE_LEVEL_EDIT: 136,
    WEBSTORE_CATEGORY_TAB_VIEW: 137,
  },
  COMMISSION: {
    CATEGORY_COMMISSION_VIEW: 141,
    CATEGORY_COMMISSION_EDIT: 142,
    CHANGE_COMMISSION_REQUEST_VIEW: 151,
    CHANGE_COMMISSION_REQUEST_SET_STATUS: 152,
    CHANGE_COMMISSION_REQUEST_EDIT: 153,
    // CHANGE_COMMISSION_REQUEST_CREATE: 154,
    // CHANGE_COMMISSION_REQUEST_SET_APPLY_DATE: 155,
  },
  ORDER: {
    ORDER_VIEW: 211,
    EXCEL_VIEW: 212,
  },
  FINANCE: {
    FINANCE_MERCHANT_INVOICE: 241,
    FINANCE_MERCHANT_INVOICE_SET_STATUS: 242,
    FINANCE_MERCHANT_PAYMENT_CONFIG: 243,
    FINANCE_MERCHANT_PAYMENT_SET_STATUS: 244,
    FINANCE_PUBLISHER_INVOICE: 231,
    FINANCE_PUBLISHER_INVOICE_SET_STATUS: 232,
    FINANCE_PUBLISHER_PAYOUT_LIST: 234,
    FINANCE_PUBLISHER_PAYMENT_CONFIG: 235,
  },
  BANNER: {
    BANNER_CREATE: 201,
    BANNER_VIEW: 202,
    BANNER_CREATE_: 203,
  },
  USER: {
    USER_MGM_AUTOLOGIN: 113,
    USER_MGM_DETAIL: 112,
    USER_MGM_LIST: 111,
    USER_MGM_SET_USER_PROFILE_STATUS: 115,
    USER_MGM_SHOW_HISTORY: 114,
  },
  CONTRACT: {
    CONTRACT_TEMPLATE_MGM_CREATE: 122,
    CONTRACT_TEMPLATE_MGM_EDIT: 123,
    CONTRACT_TEMPLATE_MGM_EDIT_ACTIVE_CONTRACT: 124,
    CONTRACT_TEMPLATE_MGM_READ: 121,
  },
  SETTING: {
    SETTING_CREATE: 222,
    SETTING_EDIT: 223,
    SETTING_VIEW: 221,
    ACCESS_MGM_CREATE_ADMIN_USER: 253,
    ACCESS_MGM_CREATE_NEW_ROLE: 252,
    ACCESS_MGM_EDIT_USER_ACCESS: 254,
    ACCESS_MGM_VIEW: 251,
  },
  REPORT: {
    REPORT_MEDIA_PERFORMANCE: 256,
  },
};

const data = {
  data: [
    {
      application_privilege_id: 181,
      name: "PRODUCT_LIST_VIEW",
      type: [3, 4, 1, 2],
      group: "Product List",
    },
    {
      application_privilege_id: 222,
      name: "SETTING_CREATE",
      type: [3, 4],
      group: "Setting",
    },
    {
      application_privilege_id: 223,
      name: "SETTING_EDIT",
      type: [3, 4],
      group: "Setting",
    },
    {
      application_privilege_id: 221,
      name: "SETTING_VIEW",
      type: [3, 4],
      group: "Setting",
    },
    {
      application_privilege_id: 253,
      name: "ACCESS_MGM_CREATE_ADMIN_USER",
      type: [3, 4],
      group: "Access Management",
    },
    {
      application_privilege_id: 252,
      name: "ACCESS_MGM_CREATE_NEW_ROLE",
      type: [3],
      group: "Access Management",
    },
    {
      application_privilege_id: 254,
      name: "ACCESS_MGM_EDIT_USER_ACCESS",
      type: [3, 4],
      group: "Access Management",
    },
    {
      application_privilege_id: 251,
      name: "ACCESS_MGM_VIEW",
      type: [3, 4],
      group: "Access Management",
    },
  ],
  error_code: 200,
  message: "OK",
  success: true,
};
