import React, { createContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import { getInventory } from "@/app/local/inventorySlice";
import MainContainer from "@/components/container/MainContainer";
import PageTitle from "@/components/general/PageTitle";
import { INVENTORY_TYPE } from "@/utils/constants/apiConstants";

import "./AdPlaceBuilder.less";
import ActionFooter from "./placeSteps/ActionFooter";
import CodePlace from "./placeSteps/CodePlace";
import ConfigPlace from "./placeSteps/ConfigPlace";
import PlaceStepper, { STEPS } from "./placeSteps/PlaceStepper";
import SelectFormatPlace from "./placeSteps/SelectFormatPlace";
import SelectTypePlace from "./placeSteps/SelectTypePlace";

export const PlaceContext = createContext([]);

const reducer = (state, action) => {
  switch (action.type) {
    case "set_title":
      return { ...state, title: action.value };
    case "set_media":
      return { ...state, media: action.value };
    case "set_type":
      return { ...state, type: action.value };
    case "set_format":
      return {
        ...state,
        format: action.value,
      };
    case "set_active_live_tab":
      return {
        ...state,
        active_live_tab: action.value,
      };
    case "reset":
      return { ...state, config: {} };
    default:
      return { ...state, [action.type]: action.value };
  }
};
const initialState = {
  currentStep: STEPS[0].key,
  type: INVENTORY_TYPE.native,
};

export const useHookPlace = () => {
  const placeId = useMatch("places/:id")?.params?.id;
  const isNewPage = placeId === "new";
  const { inventoryDetail } = useSelector((state) => state.inventory);
  return { placeId, isNewPage, inventoryDetail };
};

const AdPlaceBuilder = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [context, dispatch_] = useReducer(reducer, initialState);
  const { placeId, isNewPage, inventoryDetail } = useHookPlace();

  useEffect(() => {
    if (!isNewPage) {
      dispatch(getInventory(placeId));
    }
  }, [placeId]);

  return (
    <PlaceContext.Provider value={[context, dispatch_]}>
      <div className="place-builder">
        <PageTitle title={isNewPage ? t("PlaceNew") : t("PlaceEdit")} />
        <PlaceStepper />
        <MainContainer wrapDisabled>
          <div className="place-builder__body">
            {context.currentStep === STEPS[0].key ? <SelectTypePlace /> : ""}
            {context.currentStep === STEPS[1].key ? <SelectFormatPlace /> : ""}
            {context.currentStep === STEPS[2].key ? <ConfigPlace /> : ""}
            {context.currentStep === STEPS[3].key ? <CodePlace /> : ""}
          </div>
        </MainContainer>
        <ActionFooter />
      </div>
    </PlaceContext.Provider>
  );
};

export default AdPlaceBuilder;
