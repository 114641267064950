import { EditOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useMemo } from "react";

import {
  CONTENT_STATUS,
  ERROR_STATUS,
  InvoiceStatus,
  OWNERSHIP_STATUS,
  OrderPaymentStatus,
  OrderStatusEnum,
  PaymentStatus,
  PayoutStatus,
  REPORT_QUEUE_STATUS,
  TEST_MODE,
} from "@/utils/constants/apiConstants";

import "./styles.less";

const _getKeys = (obj) => {
  return _.keys(obj).map((k) => _.toLower(k));
};

const TagCustom = (props) => {
  const { status, icon, onClick, disabled, id } = props;
  const InvoiceStatus_ = _getKeys(InvoiceStatus);
  const PaymentStatus_ = _getKeys(PaymentStatus);
  const PayoutStatus_ = _getKeys(PayoutStatus);
  const OWNERSHIP_STATUS_ = _getKeys(OWNERSHIP_STATUS);
  const TEST_MODE_ = _getKeys(TEST_MODE);
  const CONTENT_STATUS_ = _getKeys(CONTENT_STATUS);
  const OrderStatusEnum_ = _getKeys(OrderStatusEnum);
  const REPORT_QUEUE_STATUS_ = _getKeys(REPORT_QUEUE_STATUS);
  const OrderPaymentStatus_ = _getKeys(OrderPaymentStatus);

  const color = useMemo(() => {
    if (disabled) {
      return ERROR_STATUS.pending;
    }

    switch (_.toLower(status)) {
      case InvoiceStatus_[3]:
      case PayoutStatus_[0]:
      case InvoiceStatus_[0]:
      case PaymentStatus_[0]:
      case PaymentStatus_[1]:
      case OWNERSHIP_STATUS_[0]:
      case TEST_MODE_[0]:
      case CONTENT_STATUS_[0]:
      case REPORT_QUEUE_STATUS_[0]:
      case REPORT_QUEUE_STATUS_[3]:
      case OrderStatusEnum_[1]:
      case OrderPaymentStatus_[1]:
      case "processing":
        return ERROR_STATUS.processing;
      case PayoutStatus_[1]:
      case InvoiceStatus_[1]:
      case PaymentStatus_[2]:
      case PaymentStatus_[5]:
      case OWNERSHIP_STATUS_[1]:
      case TEST_MODE_[2]:
      case CONTENT_STATUS_[1]:
      case OrderStatusEnum_[2]:
      case REPORT_QUEUE_STATUS_[2]:
      case OrderPaymentStatus_[2]:
      case "success":
      case "active":
      case "true":
      case true:
        return ERROR_STATUS.success;
      case PayoutStatus_[2]:
      case PaymentStatus_[3]:
      case PaymentStatus_[4]:
      case OWNERSHIP_STATUS_[2]:
      case CONTENT_STATUS_[2]:
      case CONTENT_STATUS_[3]:
      case OrderStatusEnum_[3]:
      case OrderStatusEnum_[4]:
      case REPORT_QUEUE_STATUS_[1]:
      case "error":
      case "noActive":
      case "false":
      case false:
        return ERROR_STATUS.error;
      case TEST_MODE_[1]:
      case OrderStatusEnum_[0]:
      case "warning":
        return ERROR_STATUS.warning;
      default:
        return ERROR_STATUS.default;
    }
  }, [status, disabled]);

  return (
    <div className="tag-custom">
      <Tag
        onClick={!disabled && onClick}
        icon={_.isFunction(onClick) ? icon || <EditOutlined /> : ""}
        className={`${
          icon ? `tag-custom__link tag-custom__link__${status}` : ""
        } ${disabled ? "tag-custom__disabled" : ""}`}
        color={color}
      >
        {props.children}
      </Tag>
    </div>
  );
};

TagCustom.propTypes = {
  status: PropTypes.any,
  children: PropTypes.any,
  icon: PropTypes.any,
  onClick: PropTypes.any,
  disabled: PropTypes.any,
  id: PropTypes.any,
};

export default TagCustom;
