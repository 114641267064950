import { Card, Col, Row, Typography } from "antd";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  INVENTORY_FORMATS,
  INVENTORY_TYPE,
} from "@/utils/constants/apiConstants";

import { PlaceContext, useHookPlace } from "../AdPlaceBuilder";
import BannerFixedSvg from "./../assets/BannerFixed.svg";
import CatfishSvg from "./../assets/Catfish.svg";
import CatfishBannerSvg from "./../assets/CatfishBanner.svg";
import InArticleSvg from "./../assets/InArticle.svg";
import InFeedSvg from "./../assets/InFeed.svg";
import InFloatSvg from "./../assets/InFloat.svg";
import MultiplexSvg from "./../assets/Multiplex.svg";
// import PopUpSvg from "./../assets/PopUp.svg";
import SliderSvg from "./../assets/Slider.svg";
import StaticSvg from "./../assets/Static.svg";
import "./SelectFormatPlace.less";

const NativeOptions = [
  {
    title: "Fixed",
    description: "FixedDesc",
    image: StaticSvg,
  },
  {
    title: "Slider",
    description: "SliderDesc",
    image: SliderSvg,
  },
  {
    title: "Multiplex",
    description: "MultiplexDesc",
    image: MultiplexSvg,
  },
  {
    title: "InFeed",
    description: "InFeedDesc",
    image: InFeedSvg,
  },
  {
    title: "InArticle",
    description: "InArticleDesc",
    image: InArticleSvg,
    // disabled: true,
  },
  {
    title: "Float",
    description: "FloatDesc",
    image: InFloatSvg,
  },
  {
    title: "Catfish",
    description: "CatfishDesc",
    image: CatfishSvg,
  },
  // {
  //   title: "Popup",
  //   description: "PopupDesc",
  //   image: PopUpSvg,
  //   disabled: true,
  // },
  // {
  //   title: "PushDown",
  //   description: "PushDownDesc",
  //   image: PopUpSvg,
  //   disabled: true,
  // },
];

const BannerOptions = [
  {
    title: "Fixed",
    description: "FixedDescBanner",
    image: BannerFixedSvg,
  },
  {
    title: "Catfish",
    description: "CatfishDesc",
    image: CatfishBannerSvg,
  },
  // {
  //   title: "Popup",
  //   description: "PopupDesc",
  //   image: PopUpSvg,
  //   disabled: true,
  // },
];

const SelectFormatPlace = () => {
  const [context, dispatchContext] = useContext(PlaceContext);
  const { t } = useTranslation("ssp");
  const { placeId, isNewPage, inventoryDetail } = useHookPlace();

  const Options =
    context.type === INVENTORY_TYPE.native ? NativeOptions : BannerOptions;

  useEffect(() => {
    if (!isNewPage && inventoryDetail?.data) {
      const d = inventoryDetail?.data;
      const formatType = Object.keys(INVENTORY_FORMATS).find(
        (key) => INVENTORY_FORMATS[key] === d?.format_type
      );
      dispatchContext({ type: "format", value: formatType });
    }
  }, [inventoryDetail]);

  const handleSelect = (v) => {
    if (isNewPage) {
      dispatchContext({ type: "format", value: v.title });
    }
  };

  return (
    <div className="select-format-place">
      <Card
        title={
          <>
            <Typography.Title level={5}>
              {context.type === INVENTORY_TYPE.native
                ? t("place.FormatPlaceTitleNative")
                : t("place.FormatPlaceTitleBanner")}
            </Typography.Title>
            <p>{t("place.FormatPlaceTitleDesc")}</p>
          </>
        }
      >
        <Row gutter={[24, 24]}>
          {Options.map((item) => {
            return (
              <Col xs={6} key={item.title}>
                <div
                  key={item.title}
                  className={`block ${
                    context.format === item.title ? "active" : ""
                  } ${item.disabled ? "block--disabled" : ""} ${
                    !isNewPage ? "block--readonly" : ""
                  }`}
                  onClick={() => handleSelect(item)}
                >
                  <div className="block__image">
                    <img src={item.image} />
                  </div>
                  <div className="block__footer">
                    <div className="block__title">
                      {t(`formats.${item.title}`)}
                    </div>
                    <div className="block__content">
                      {t(`formats.${item.description}`)}
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Card>
    </div>
  );
};

export default SelectFormatPlace;
