import { Image } from "antd";
import React from "react";
import { memo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getFile } from "@/app/local/appSlice";
import { getUrlFile } from "@/utils/helper";

const ImagePreview = (props) => {
  const { height, width, preview, defaultImage } = props;
  const dispatch = useDispatch();
  const [img, setImg] = useState();

  useEffect(() => {
    if (props?.fileId) {
      const result = dispatch(getFile(props?.fileId));
      result.then((r) => {
        if (r?.payload?.success) {
          const { data } = r.payload;
          if (data) {
            setImg(getUrlFile(data));
          }
        }
      });
    }
  }, [props.fileId]);

  return (
    <div>
      <Image
        className="img-preview"
        width={width || 150}
        height={height || 50}
        preview={preview}
        src={img || defaultImage}
      />
    </div>
  );
};

ImagePreview.propTypes = {
  fileId: String,
  preview: Boolean,
  width: String,
  height: String,
  defaultImage: String,
};

export default memo(ImagePreview);
