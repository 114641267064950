import { EditOutlined } from "@ant-design/icons";
import { Col, Form, Input, Modal, Popconfirm, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import parse from "html-react-parser";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  getPublisherInvoices,
  regeneratePublisherInvoice,
  setPublisherBatchPayment,
  setPublisherBatchStatus,
} from "@/app/local/invoiceSlice";
import MainContainer from "@/components/container/MainContainer";
import BillUploadPublisherForm from "@/components/finance/BillUploadPublisherForm";
import FormPayoutStatusPublisher from "@/components/finance/FormPayoutStatusPublisher";
import FormStatusPublisher from "@/components/finance/FormStatusPublisher";
import InvoiceDocuments from "@/components/finance/InvoiceDocuments";
import PageTitle from "@/components/general/PageTitle";
import SliderMenu from "@/components/general/SliderMenu";
import TableComponent from "@/components/general/TableComponent";
import InputRangePicker from "@/components/input/inputRangePicker";
import SelectPublisher from "@/components/input/selectPublisher";
import FormSearchBar from "@/components/micro/FormSearchBar";
import TagCustom from "@/components/micro/TagCustom";
import {
  InvoiceStatus,
  LEGAL_STATUS,
  PaymentStatus,
  USER_TYPE,
} from "@/utils/constants/apiConstants";
// import { MANUAL_CONFIG } from "@/utils/constants/configConstants";
import {
  dateFormat,
  objectR,
  priceFormat,
  serverAcceptableDate,
} from "@/utils/helper";
import { useAccount } from "@/utils/hooks/useAuth";
import { useDeviceDetector } from "@/utils/hooks/useDeviceDetector";
import { getFinalFilters, getLastParams } from "@/utils/utils";

import "./styles.less";

const filteredOptions = Object.keys(PaymentStatus).filter(
  (index) =>
    index === "WaitingToProcess" ||
    index === "ReadyForPayment" ||
    index === "TreasuryAcceptance" ||
    index === "Paid"
);

const InvoicesPublisher = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { roleId, isAdmin, userDetail } = useAccount();
  const [menuOpen, setOpenMenu] = useState();
  const {
    invoicesPublisher,
    invoicesPublisherLoading,
    invoicesPublisherError,
  } = useSelector((state) => state.invoice);
  const [selectedRows, setSelectedRows] = useState([]);
  const isMobile = useDeviceDetector();
  const { confirm } = Modal;

  const [extraData, setExtraData] = useState(null);
  const [openDialogDocument, setOpenDialogDocument] = useState(false);
  const navigate = useNavigate();

  const [form] = useForm();
  const [formSummery] = useForm();

  let heads = [
    {
      title: t("invoice.id"),
      key: "publisher_invoice_id",
      fixed: !isMobile && "left",
      width: !isMobile && 100,
      sort: true,
    },
    // {
    //   title: t("invoice.publisher_id"),
    //   key: "publisher_id",
    //   width: 100,
    //   sort: true,
    // },
    // {
    //   title: t("invoice.publisher_name"),
    //   key: "publisher_name",
    //   width: 150,
    //   sort: true,
    // },
    {
      title: t("invoice.status_invoice"),
      key: "status",
      width: 150,
      sort: true,
    },
    {
      title: t("invoice.status_payment"),
      key: "payment_status",
      width: 150,
      sort: true,
    },
    {
      title: t("invoice.projection_id"),
      key: "projection_id",
      hover: t("invoice.projection_id_title"),
      width: 150,
      sort: true,
    },
    {
      title: t("invoice.commission"),
      key: "amount_commission",
      width: 150,
      sort: true,
    },
    {
      title: t("invoice.amount_vat"),
      key: "amount_vat",
      width: 150,
      sort: true,
    },
    {
      title: t("invoice.total"),
      key: "total_amount",
      width: 150,
      sort: true,
    },
    {
      title: t("invoice.create_date"),
      key: "create_date",
      width: !isMobile && 150,
      sort: true,
    },
    // {
    //   title: t("invoice.payment_date"),
    //   key: "payment_date",
    //   width: !isMobile && 150,
    //   sort: true,
    // },
    {
      title: t("invoice.cal_du_to"),
      key: "cal_du_to",
      width: !isMobile && 150,
      sort: true,
    },
    {
      title: t("invoice.documents_count"),
      key: "documents_count",
      width: !isMobile && 100,
      // sort: true,
    },
    {
      title: t("invoice.actions"),
      key: "actions",
      type: "actions",
      fixed: !isMobile && "right",
      width: !isMobile && 70,
    },
  ];

  if (roleId === USER_TYPE.PUBLISHER) {
    heads = heads.filter(
      (item) => item.key !== "publisher_id" && item.key !== "projection_id"
      // item.key !== "documents_count"
    );
  }

  const itemsList = useMemo(() => {
    let items_ = _.get(invoicesPublisher, "data.result", []);
    items_ = items_.map((item) => {
      const actions = [];

      let bill = {
        name: t("invoice.addBillLegal"),
        type: "ADD_BILL",
        handleClick: () =>
          setOpenMenu({
            title: t("invoice.addBillLegal"),
            type: "ADD_BILL",
            invoice: item,
          }),
      };

      if (item?.legal_publiser_bill_file_id) {
        bill = {
          name: t("invoice.editBillLegal"),
          type: "ADD_BILL",
          handleClick: () =>
            setOpenMenu({
              title: t("invoice.editBillLegal"),
              type: "ADD_BILL",
              invoice: item,
            }),
        };
      }

      if (isAdmin && item.payment_status !== PaymentStatus.Paid) {
        let reqController = new AbortController();
        actions.push({
          name: t("invoice_document.re_generate_invoice"),
          type: "CUSTOM_NODE",
          customNode: (
            <Popconfirm
              placement="top"
              title={t("general.sureForRegenerate")}
              onConfirm={async () => {
                const response = await dispatch(
                  regeneratePublisherInvoice({
                    invoiceId: item.publisher_invoice_id,
                    includedNewEvents: true,
                    controller: reqController,
                  })
                );
                if (response?.payload.success) {
                  message.success({
                    content: t("general.successFullyDone"),
                    key: "delete",
                    duration: 1,
                  });
                } else {
                  message.error({
                    content:
                      response?.payload?.Message ||
                      t("general.UnexpectedErrorOccurred"),
                    key: "delete",
                    duration: 1,
                  });
                }
              }}
              okText={t("general.ok")}
              cancelText={t("general.no")}
            >
              <a>{t("invoice_document.re_generate_invoice")}</a>
            </Popconfirm>
          ),
        });
      }

      if (item.status === InvoiceStatus.Pending) {
        actions.push({
          name: !isAdmin
            ? t("invoice_document.title_button")
            : t("invoice_document.title_button_admin"),
          type: "INVOICE_DOCUMENT",
          handleClick: () => {
            setOpenDialogDocument(item.publisher_invoice_id);
          },
        });
      }

      actions.push(
        {
          name: t("invoice.print"),
          type: "PRINT",
          handleClick: () => {
            window.open(
              `/invoice_view/${item.publisher_invoice_id}/p`,
              "_blank"
            );
          },
        },
        {
          name: t("invoice.detail"),
          type: "DETAIL",
          handleClick: () =>
            navigate(`/invoices/${item.publisher_invoice_id}/p`),
        }
      );

      if (
        userDetail?.legal_status === LEGAL_STATUS.LEGAL ||
        item?.publisher?.legal_status === LEGAL_STATUS.LEGAL
      ) {
        actions.push(bill);
      }

      let onEditStatusInvoice = null;
      if (isAdmin) {
        onEditStatusInvoice = () =>
          setOpenMenu({
            title: t("invoice.setInvoiceStatus"),
            type: "SET_STATUS",
            payout: item,
          });
      }

      let Status = "-";
      if (item.status) {
        Status = (
          <TagCustom
            icon={_.isFunction(onEditStatusInvoice) && <EditOutlined />}
            status={objectR(InvoiceStatus)[item?.status]}
            onClick={onEditStatusInvoice}
          >
            {t(`invoice.status_.${objectR(InvoiceStatus)[item.status]}`)}
          </TagCustom>
        );
      }

      let onEditPaymentStatusInvoice = null;
      if (isAdmin) {
        onEditPaymentStatusInvoice = () =>
          setOpenMenu({
            title: t("invoice.setPaymentStatus"),
            type: "SET_PAYMENT_STATUS",
            payout: item,
          });
      }

      let StatusInvoice = "-";
      if (item.payment_status) {
        StatusInvoice = (
          <TagCustom
            icon={_.isFunction(onEditPaymentStatusInvoice) && <EditOutlined />}
            status={objectR(PaymentStatus)[item?.payment_status]}
            onClick={onEditPaymentStatusInvoice}
          >
            {t(
              `invoice.pay_status_.${
                objectR(PaymentStatus)[item.payment_status]
              }`
            )}
          </TagCustom>
        );
      }

      // let publisher_name = `${item.publisher?.first_name} ${item.publisher?.last_name}`;
      // if (item.publisher?.legal_status === LEGAL_STATUS.LEGAL) {
      //   publisher_name = item.publisher?.company_name;
      // }

      const cal_du_to = dateFormat(item.projection?.end_date, "ST");

      return {
        ...item,
        // publisher_id: (
        //   <Link to={`/publishers/${item?.publisher?.user?.user_id}`}>
        //     {item.publisher_id}
        //   </Link>
        // ),
        // publisher_name: (
        //   <Link
        //     to={`/publishers/${item?.publisher?.user?.user_id}`}
        //     target="_blank"
        //   >
        //     {publisher_name}
        //   </Link>
        // ),
        create_date: dateFormat(item.create_date, "ST"),
        projection_id: item?.projection?.publisher_projection_id,
        // payment_date:
        //   MANUAL_CONFIG.SHOW_PAYMENT_DATE || roleId !== USER_TYPE.PUBLISHER
        //     ? dateFormat(item?.projection?.payment_date, "ST")
        //     : "",
        amount_commission: priceFormat(item.commission),
        amount_vat: priceFormat(item.vat),
        total_amount: priceFormat(item.total),
        status: Status,
        cal_du_to,
        documents_count: item.documents_count,
        payment_status: StatusInvoice,
        actions: actions,
      };
    });
    return items_;
  }, [invoicesPublisher]);

  const items = _.get(invoicesPublisher, "data.total_items", 10);
  const pages = _.get(invoicesPublisher, "data.total_pages", 1);
  const pageSize = _.get(invoicesPublisher, "data.page_size", 10);
  const page = _.get(invoicesPublisher, "data.page", 1);
  const tableInfo = { items, pages, pageSize, serverPagination: true, page };

  useEffect(() => {
    dispatch(getPublisherInvoices({}));
  }, []);

  const onFinishSearch = async (ev) => {
    const filters = {};
    const e = getFinalFilters(extraData, ev, {});

    if (e.payment_date) {
      const fromDate = serverAcceptableDate(e.payment_date[0]);
      const toDate = serverAcceptableDate(e.payment_date[1]);
      filters["from_payment_date"] = fromDate;
      filters["to_payment_date"] = toDate;
    }
    if (e.create_date) {
      const fromDate = serverAcceptableDate(e.create_date[0]);
      const toDate = serverAcceptableDate(e.create_date[1]);
      filters["from_create_date"] = fromDate;
      filters["to_create_date"] = toDate;
    }

    const filter = {
      publisher_invoice_id: e.id,
      status: e.status_invoice,
      payment_status: e.status_payment,
      publisher_id: e.publisher,
      ...filters,
    };

    const params = getLastParams(filter, e);

    const resp = await dispatch(getPublisherInvoices(params));
    setExtraData(e);
    if (!resp?.payload?.success) {
      message.error({
        key: "ERROR-SEARCH-BAR",
        content: resp?.payload?.Message || t("general.unHandleErrorMessage"),
      });
    }
  };
  const onClearFilters = () => {
    setExtraData(null);
    dispatch(getPublisherInvoices({}));
    form.resetFields();
  };

  const handlePagination = (e) => {
    onFinishSearch({
      ...e,
    });
  };

  const handleSort = (e) => {
    onFinishSearch({
      page: 1,
      sort_field: e.order ? e.columnKey : undefined,
      sort_orientation: e.order ? (e.order === "ascend" ? 2 : 1) : undefined,
    });
  };

  const doUpdate = async (value) => {
    message.loading({
      key: "invoice",
      content: t("general.pleaseWaitMessage"),
      // duration: 10,
    });
    const invoiceResult = await dispatch(
      setPublisherBatchStatus({
        invoice_ids: selectedRows,
        status: value?.statusInvoice,
      })
    );
    if (invoiceResult?.payload?.success) {
      if (value.statusPayment) {
        const result = await dispatch(
          setPublisherBatchPayment({
            invoice_ids: selectedRows,
            status: value?.statusPayment,
          })
        );
        if (result?.payload?.success) {
          message.success({
            content: t("general.successFullyDone"),
            key: "invoice",
          });
          onFinishSearch(extraData);
          setSelectedRows([]);
        } else {
          message.error({
            content:
              result?.payload?.Message || t("general.unHandleErrorMessage"),
            key: "invoice",
          });
        }
      } else
        message.success({
          content: t("general.successFullyDone"),
          key: "invoice",
        });
      onFinishSearch(extraData);
      setSelectedRows([]);
    } else {
      message.error({
        content:
          invoiceResult?.payload?.Message || t("general.unHandleErrorMessage"),
        key: "invoice",
      });
    }
  };

  const handleBatchUpdate = async (value) => {
    if (!value.statusPayment) {
      await confirm({
        title: parse(t("general.confirmBatchInvoice")),
        async onOk() {
          doUpdate(value);
        },
      });
    } else if (value.statusInvoice) {
      await doUpdate(value);
    }
    if (value.statusPayment && !value.statusInvoice) {
      message.loading({
        key: "invoice",
        content: t("general.pleaseWaitMessage"),
        // duration: 10,
      });
      const paymentResult = await dispatch(
        setPublisherBatchPayment({
          invoice_ids: selectedRows,
          status: value?.statusPayment,
        })
      );

      if (paymentResult?.payload?.success) {
        message.success({
          content: t("general.successFullyDone"),
          key: "invoice",
        });
        onFinishSearch(extraData);
        setSelectedRows([]);
      } else {
        message.error({
          content:
            paymentResult?.payload?.Message ||
            t("general.unHandleErrorMessage"),
          key: "invoice",
        });
      }
    }
  };
  const paymentOptions = filteredOptions.map((key) => {
    return (
      <Select.Option key={key} value={PaymentStatus[key]}>
        {t(`invoice.pay_status_.${key}`)}
      </Select.Option>
    );
  });

  return (
    <div className="invoices invoices__publisher">
      <PageTitle
        title={
          roleId === USER_TYPE.SUPER_ADMIN
            ? "InvoicesPublisher"
            : "InvoicesPage"
        }
      />

      <MainContainer>
        <>
          <FormSearchBar
            name="formSearch"
            form_={form}
            // search
            // more
            onClearFilters={onClearFilters}
            onFinish={onFinishSearch}
          >
            <Col md={5} xs={24}>
              <Form.Item name={"id"}>
                <Input allowClear placeholder={t("invoice.id_search")} />
              </Form.Item>
            </Col>

            <Col md={5} xs={24}>
              <Form.Item name={"status_invoice"}>
                <Select allowClear placeholder={t("invoice.status_invoice")}>
                  {_.keys(InvoiceStatus).map((key) => (
                    <Select.Option key={key} value={InvoiceStatus[key]}>
                      {t(`invoice.status_.${key}`)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name={"status_payment"}>
                <Select allowClear placeholder={t("invoice.status_payment")}>
                  {_.keys(PaymentStatus).map((key) => (
                    <Select.Option key={key} value={PaymentStatus[key]}>
                      {t(`invoice.pay_status_.${key}`)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {isAdmin ? (
              <Col md={5} xs={24}>
                <SelectPublisher name="publisher" />
              </Col>
            ) : (
              ""
            )}

            <Col md={6} xs={24}>
              <Form.Item name={"create_date"}>
                <InputRangePicker
                  placeholder={[
                    t("invoice.create_date_start"),
                    t("invoice.create_date_end"),
                  ]}
                />
              </Form.Item>
            </Col>
            {/* <Col md={9} xs={24}>
              <Form.Item name={"payment_date"}>
                <InputRangePicker
                  placeholder={[
                    t("invoice.payment_date_start"),
                    t("invoice.payment_date_end"),
                  ]}
                />
              </Form.Item>
            </Col> */}
          </FormSearchBar>

          <TableComponent
            loading={invoicesPublisherLoading}
            heads={heads}
            data={itemsList}
            form_={formSummery}
            info={tableInfo}
            scroll={{
              x: 1600,
            }}
            handlePagination={handlePagination}
            handleSort={handleSort}
            rowSelection={isAdmin}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            handleBatchUpdate={handleBatchUpdate}
            rowKeyName="publisher_invoice_id"
            rowSelectionFormItmes={
              <>
                <Form.Item
                  name={"statusInvoice"}
                  label={t("invoice.status_invoice")}
                >
                  <Select
                    placeholder={t("general.select")}
                    dropdownMatchSelectWidth={false}
                    allowClear
                  >
                    {Object.keys(InvoiceStatus)
                      .filter((v) => v === "Initial" || v === "Confirmed")
                      .map((key) => {
                        return (
                          <Select.Option key={key} value={InvoiceStatus[key]}>
                            {t(`invoice.status_.${key}`)}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                  {(formSummery) => {
                    let showWaitingOptionOnly;
                    let showAllExceptWaitingOption;
                    const invoice = formSummery.getFieldValue("statusInvoice");
                    if (invoice === InvoiceStatus.Initial) {
                      showWaitingOptionOnly = true;
                    }
                    if (invoice === InvoiceStatus.Confirmed) {
                      showAllExceptWaitingOption = true;
                    }
                    return (
                      <Form.Item
                        name={"statusPayment"}
                        label={t("invoice.status_payment")}
                      >
                        <Select
                          placeholder={t("general.select")}
                          dropdownMatchSelectWidth={false}
                          allowClear
                        >
                          <>
                            {showWaitingOptionOnly && (
                              <Select.Option
                                key={1}
                                value={PaymentStatus.WaitingToProcess}
                              >
                                {t(`invoice.pay_status_.WaitingToProcess`)}
                              </Select.Option>
                            )}
                            {showAllExceptWaitingOption &&
                              filteredOptions.slice(1).map((key) => {
                                return (
                                  <Select.Option
                                    key={key}
                                    value={PaymentStatus[key]}
                                  >
                                    {t(`invoice.pay_status_.${key}`)}
                                  </Select.Option>
                                );
                              })}
                            {!invoice && paymentOptions}
                          </>
                        </Select>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </>
            }
          />
        </>
      </MainContainer>

      <SliderMenu
        title={menuOpen?.title}
        collapsed={menuOpen}
        setCollapsed={setOpenMenu}
      >
        {menuOpen?.type === "SET_PAYMENT_STATUS" && (
          <FormPayoutStatusPublisher
            data={menuOpen}
            close={() => setOpenMenu()}
            onFinishSearch={() => onFinishSearch(extraData)}
          />
        )}
        {menuOpen?.type === "SET_STATUS" && (
          <FormStatusPublisher
            data={menuOpen}
            close={() => setOpenMenu()}
            onFinishSearch={() => onFinishSearch(extraData)}
          />
        )}
        {menuOpen?.type === "ADD_BILL" && (
          <BillUploadPublisherForm
            close={() => setOpenMenu()}
            data={menuOpen}
            onFinishSearch={() => onFinishSearch(extraData)}
          />
        )}
      </SliderMenu>

      {openDialogDocument ? (
        <InvoiceDocuments
          isModal
          open={openDialogDocument}
          setOpen={setOpenDialogDocument}
        />
      ) : null}
    </div>
  );
};

export default InvoicesPublisher;
