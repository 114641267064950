import { Button, Popconfirm } from "antd";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { PlaceContext } from "@/ssp/AdPlaceBuilder";

const ResetButton = () => {
  const { t } = useTranslation("ssp");
  const [context, dispatchContext] = useContext(PlaceContext);

  const resetContext = () => {
    dispatchContext({ type: "reset" });
  };
  return (
    <div className="form_footer">
      <Popconfirm
        placement="top"
        title={t("general.sureForClear")}
        onConfirm={async () => {
          resetContext();
        }}
        okText={t("general.ok")}
        cancelText={t("general.no")}
      >
        <Button size="small" type="link">
          {t("place.backToDefaultSetting")}
        </Button>
      </Popconfirm>
    </div>
  );
};

export default ResetButton;
