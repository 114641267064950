import { Button, Card, Col, Form, Input, Row, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import BannerImg from "@/assets/images/ssp/banners_ads.svg";
import NativeImg from "@/assets/images/ssp/native_ads.svg";
import SelectMedia from "@/components/input/selectMedia";
import { INVENTORY_TYPE } from "@/utils/constants/apiConstants";

import { PlaceContext, useHookPlace } from "../AdPlaceBuilder";
import "./SelectTypePlace.less";

const SelectTypePlace = () => {
  const [context, dispatchContext] = useContext(PlaceContext);
  const [formM] = useForm();
  const { t } = useTranslation("ssp");
  const { placeId, isNewPage, inventoryDetail } = useHookPlace();

  useEffect(() => {
    if (inventoryDetail?.data) {
      const d = inventoryDetail?.data;
      dispatchContext({ type: "name", value: d?.name });
      dispatchContext({ type: "media", value: d?.media_id });
    }
  }, [inventoryDetail]);

  useEffect(() => {
    if (context) {
      formM.setFieldsValue({ name: context.name, media: context.media });
    }
  }, [context]);

  const options = [
    {
      key: INVENTORY_TYPE.native,
      title: "NativeAds",
      description: "NativeAdsDesc",
      img: NativeImg,
    },
    {
      key: INVENTORY_TYPE.banner,
      title: "BannerAds",
      description: "BannerAdsDesc",
      img: BannerImg,
    },
  ];
  const [placeType, setPlaceType] = useState(INVENTORY_TYPE.native);

  const handleSetType = (id) => {
    if (isNewPage) {
      setPlaceType(id);
      dispatchContext({ type: "type", value: id });
    }
  };

  const onFinish = () => {
    formM.validateFields();
  };

  const onValuesChange = (v) => {
    const [key] = Object.keys(v);
    dispatchContext({ type: key, value: v[key] });
  };

  return (
    <div className="select-type-place">
      <Card>
        <Typography.Title level={5}>{t("place.infoPlace")}</Typography.Title>
        <p className="text-body">{t("place.infoPlaceContent")}</p>

        <Form form={formM} onValuesChange={onValuesChange} layout="vertical">
          <Row gutter={[24, 24]}>
            <Col md={8} xs={24}>
              <Form.Item
                name={"name"}
                label={t("place.placeName")}
                rules={[
                  {
                    required: true,
                  },
                ]}
                help={<div className="help_input">{t("place.placeHelp")}</div>}
              >
                <Input
                  disabled={!isNewPage}
                  size="large"
                  placeholder={t("place.placeNameHolder")}
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <SelectMedia
                name="media"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                readonly={!isNewPage}
                size="large"
                label={t("place.WhichMediaShown")}
              />
            </Col>
          </Row>
        </Form>
      </Card>
      <br />
      <Card hidden={!isNewPage}>
        <Typography.Title level={5}>
          {t("place.typePlaceHeader")}
        </Typography.Title>
        <p className="text-body">{t("place.typePlaceHeaderContent")}</p>

        <Row gutter={[24, 24]} className="block-container">
          {options.map((item, i) => {
            return (
              <Col key={i} xs={12}>
                <div
                  className={`block ${
                    item.key === context.type ? "block__active" : ""
                  }`}
                  onClick={() => handleSetType(item.key)}
                >
                  <div className="image">
                    <img src={item.img} />
                  </div>
                  <div className="block__info">
                    <div className="title">
                      <b>{t(`place.${item.title}`)}</b>
                    </div>
                    <div className="description">
                      {t(`place.${item.description}`)}
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Card>
      <Button htmlType="submit" hidden id="submitButton" onClick={onFinish}>
        OK
      </Button>
    </div>
  );
};

export default SelectTypePlace;
