import { Form, Select } from "antd";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { DEVICE_TYPE } from "@/utils/constants/apiConstants";

const SelectTypeDevice = (props) => {
  const { t } = useTranslation("ssp");

  const devicesOptions = _.keys(DEVICE_TYPE).map((k) => ({
    key: k,
    name: k,
  }));
  const { name, label, rules, placeholder } = props;

  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      placeholder={placeholder}
    >
      <Select mode="multiple" showArrow size="large">
        {devicesOptions.map((item) => (
          <Select.Option key={item.key} value={item.key}>
            {t(`device.${item.name}`)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

SelectTypeDevice.propTypes = {
  name: "",
  label: "",
  rules: "",
  placeholder: "",
};

export default SelectTypeDevice;
