import { Pie } from "@ant-design/plots";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useContext, useEffect, useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getLinkConversion,
  getOrderOverviewByCount,
} from "@/app/local/reportSlice";
import { DashboardContext } from "@/pages/dashboard/DashboardAdmin";
import { kFormatter } from "@/utils/helper";

import TileNumber from "../micro/TileNumber";
import { COLORS } from "./colors";
import WidgetWrapper from "./micro/WidgetWrapper";

const initData = [
  {
    type: "dashboard.link",
    value: 0,
  },
  {
    type: "dashboard.widget",
    value: 0,
  },
  {
    type: "dashboard.banner",
    value: 0,
  },
];

const AdminFinalOrdersChart = (props) => {
  const { title } = props;
  const [data, setData] = useState(initData);
  const dispatch = useDispatch();
  const {
    // adminOrderOverviewByCount,
    // adminOrderOverviewByCountLoading,
    adminLinkConversion,
    adminLinkConversionLoading,
  } = useSelector((state) => state.report);
  const [context, dispatch_] = useContext(DashboardContext);
  const [mode, setMode] = useState("LINE");

  useEffect(() => {
    onSubmit();
  }, [context]);

  useEffect(() => {
    if (adminLinkConversion) {
      const result = adminLinkConversion?.data || [];
      // const d_ = [...data];
      let r = { link: 0, banner: 0, widget: 0 };
      result?.map((item, i) => {
        if (item.general_type === 0) {
          r["link"] += item.finalized_order_count || 0;
        }
        if (item.general_type === 1) {
          r["banner"] += item.finalized_order_count || 0;
        }
        if (item.general_type === 2) {
          r["widget"] += item.finalized_order_count || 0;
        }
      });
      // d_[0].value = result.finalized_count || 0;
      // d_[1].value = result.total_clicks || 0;
      // d_[2].value = result.total_orders || 0;
      setData([
        {
          type: "dashboard.link",
          value: r.link,
        },
        {
          type: "dashboard.banner",
          value: r.banner,
        },
        {
          type: "dashboard.widget",
          value: r.widget,
        },
      ]);
    }
  }, [adminLinkConversion]);

  const config = useMemo(() => {
    return {
      height: 170,
      appendPadding: 10,
      legend: false,
      data,
      angleField: "value",
      colorField: "type",
      radius: 1,
      tooltip: {
        formatter: (datum) => {
          return { name: t(datum.type), value: datum.value };
        },
      },
      innerRadius: 0.6,
      theme: {
        styleSheet: {
          fontFamily: "IRANYekan",
        },
      },
      yAxis: {
        label: {
          formatter: (v) => kFormatter(v, 1),
        },
      },
      label: {
        autoRotate: false,
        type: "inner",
        offset: "-50%",
        style: {
          textAlign: "center",
          fontSize: 14,
        },
      },
      pieStyle: ({ type }) => {
        if (type === "dashboard.banner") {
          return {
            fill: COLORS.DASHBOARD_PINK,
          };
        }
        if (type === "dashboard.link") {
          return {
            fill: COLORS.DASHBOARD_YELLOW,
          };
        }
        if (type === "dashboard.widget") {
          return {
            fill: COLORS.DASHBOARD_PURPLE,
          };
        }
      },
      statistic: {
        title: false,
        content: {
          style: {
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          content: "",
        },
      },
    };
  }, [data]);

  const onSubmit = (e) => {
    dispatch(
      getOrderOverviewByCount({
        web_store_ids: context.webstore,
        media_ids: e?.medias || context?.medias,
        link_type_report_types: e?.link_type,
        from: context.period.fromDate,
        to: context.period.toDate,
        publisher_ids: context?.publisher_ids,
        filter_date_by: context?.filter_date_by,
      })
    );
    dispatch(
      getLinkConversion({
        web_store_ids: context.webstore,
        media_ids: e?.medias || context?.medias,
        link_type_report_types: e?.link_type,
        from: context.period.fromDate,
        to: context.period.toDate,
        publisher_ids: context?.publisher_ids,
        filter_date_by: context?.filter_date_by,
      })
    );
  };

  // const dataFinalOrder = adminOrderOverviewByCount?.data;

  return (
    <>
      <WidgetWrapper
        setMode={setMode}
        title={`dashboard.${title}`}
        onSubmit={onSubmit}
        hideSwitch
        className="admin_commission_chart chart_final_order"
      >
        <div dir="ltr" style={{ direction: "ltr" }}>
          <Pie {...config} loading={adminLinkConversionLoading} />
        </div>
        <p className="pie-description">{t("dashboard.donutDescription")}</p>
        <TileNumber
          title={t("dashboard.link")}
          number={data[0]?.value}
          align="right"
          color={"color_8"}
          row
        />
        <TileNumber
          title={t("dashboard.widget")}
          number={data[2]?.value}
          align="right"
          color={"color_2"}
          row
        />
        <TileNumber
          title={t("dashboard.banner")}
          number={data[1]?.value}
          align="right"
          color={"color_9"}
          row
        />
      </WidgetWrapper>
    </>
  );
};

AdminFinalOrdersChart.propTypes = {
  title: PropTypes.string,
};

export default AdminFinalOrdersChart;
