/* eslint-disable react/prop-types */
import { Table, Tag, Transfer } from "antd";
import _ from "lodash";
import difference from "lodash/difference";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Privileges } from "@/utils/constants/accessCenter";
import { hasAccess } from "@/utils/helper";
import { useAccount } from "@/utils/hooks/useAuth";

import "./styles.less";

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <Transfer {...restProps}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === "left" ? leftColumns : rightColumns;
      const rowSelection = {
        getCheckboxProps: (item) => ({
          disabled: listDisabled || item.disabled,
        }),

        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },

        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },

        selectedRowKeys: listSelectedKeys,
      };
      return (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          style={{
            pointerEvents: listDisabled ? "none" : undefined,
          }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);

const PrivilegesEdit = (props) => {
  const { form } = props;
  const { t } = useTranslation();
  const { user } = useAccount();

  const accessEdit = hasAccess(user, [
    Privileges.SETTING.ACCESS_MGM_EDIT_USER_ACCESS,
  ]);
  const leftTableColumns = [
    {
      dataIndex: "title",
      title: t("role.titlePrivilege"),
    },
    {
      dataIndex: "tag",
      title: t("role.tag"),
      render: (tag) => <Tag>{tag}</Tag>,
    },
  ];
  const rightTableColumns = [
    {
      dataIndex: "title",
      title: t("role.titlePrivilege"),
    },
    {
      dataIndex: "tag",
      title: t("role.tag"),
      render: (tag) => <Tag>{tag}</Tag>,
    },
  ];

  const [targetKeys, setTargetKeys] = useState([]);
  const { privileges } = useSelector((state) => state.account);
  const [dataSource, setDataSource] = useState();

  useEffect(() => {
    const data_ = _.get(privileges, "data", []);
    if (data_.length) {
      const tmpData = data_.map((item, i) => ({
        key: item.application_privilege_id,
        title: item.name,
        disabled: false,
        tag: item.group,
      }));
      setDataSource(tmpData);
    }
  }, [privileges]);

  const onChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
    form.setFieldsValue({
      privileges: nextTargetKeys,
    });
  };

  useEffect(() => {
    setTargetKeys(form.getFieldValue("privileges"));
  }, [form.getFieldValue("privileges")]);

  return (
    <div className="privileges-edit">
      <TableTransfer
        dataSource={dataSource}
        targetKeys={targetKeys}
        disabled={!accessEdit}
        showSearch={true}
        onChange={onChange}
        filterOption={(inputValue, item) => {
          let response =
            item.title?.toLowerCase()?.indexOf(inputValue) !== -1 ||
            item.tag?.toLowerCase()?.indexOf(inputValue) !== -1;

          return response;
        }}
        leftColumns={leftTableColumns}
        rightColumns={rightTableColumns}
        titles={[t("role.allPrivileges"), t("role.assignPrivileges")]}
      />
    </div>
  );
};

export default PrivilegesEdit;
