import { Form, Select } from "antd";
import { t } from "i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { FILTER_DATE_BY } from "@/utils/constants/apiConstants";

const SelectFilterTypeDate = (props) => {
  const { name, label, size, mode, placeholder } = props;

  return (
    <Form.Item name={name} label={label}>
      <Select
        allowClear
        mode={mode}
        size={size || "medium"}
        placeholder={placeholder}
        showArrow
      >
        {_.keys(FILTER_DATE_BY).map((key) => (
          <Select.Option key={key} value={FILTER_DATE_BY[key]}>
            {t(`order.filter_by_date.${key}`)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

SelectFilterTypeDate.propTypes = {
  mode: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
};

export default SelectFilterTypeDate;
