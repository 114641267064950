import { CloseOutlined } from "@ant-design/icons";
import { Avatar, List } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import "./styles.less";

const BasketPreview = (props) => {
  const { data, handleSelectProduct } = props;

  const { websitesActive } = useSelector((state) => state.website);
  const websitesList = _.get(websitesActive, "data", []);

  const deleteItem = (pdk) => {
    handleSelectProduct(undefined, pdk);
  };

  return (
    <div className="basket-preview">
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => {
          const website = websitesList?.find(
            (web) => web.web_store_id === item.category?.web_store_id
          );
          return (
            <List.Item
              actions={[
                <CloseOutlined
                  key={"i"}
                  className="delete-icon"
                  onClick={() => deleteItem(item)}
                />,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    className={"avatar"}
                    size="large"
                    src={item.profile_image_path}
                    shape="square"
                  />
                }
                title={
                  <Paragraph
                    ellipsis={{ expandable: false, tooltip: true, rows: 2 }}
                  >
                    {item.name}
                  </Paragraph>
                }
                // title={item.name}
                description={website?.name}
              />
            </List.Item>
          );
        }}
      />
    </div>
  );
};

BasketPreview.propTypes = {
  data: PropTypes.array,
  handleSelectProduct: PropTypes.func,
};

export default BasketPreview;
