import PropTypes from "prop-types";
import { memo } from "react";
import { ReactSVG } from "react-svg";

import commissionIcon from "./commission.svg";
import homeIcon from "./home.svg";
import "./index.less";
import invoiceIcon from "./invoice.svg";
import LinkIconImg from "./link.svg";
import mediaIcon from "./media.svg";
import orderIcon from "./orderIcon.svg";
import payoutIcon from "./payouts.svg";
import reportIcon from "./report-icon.svg";
import websiteIcon from "./website-icon.svg";
import widgetBannerIcon from "./widget-banner.svg";
import widgetsIcon from "./widget-product.svg";

const SidebarIcon = (props) => {
  const { type } = props;
  return (
    <div className="SidebarIcon">
      {type === "linkIcon" ? <ReactSVG src={LinkIconImg} /> : false}
      {type === "homeIcon" ? <ReactSVG src={homeIcon} /> : false}
      {type === "mediaIcon" ? <ReactSVG src={mediaIcon} /> : false}
      {type === "widgetBannerIcon" ? (
        <ReactSVG src={widgetBannerIcon} />
      ) : (
        false
      )}
      {type === "widgetsIcon" ? <ReactSVG src={widgetsIcon} /> : false}
      {type === "commissionIcon" ? <ReactSVG src={commissionIcon} /> : false}
      {type === "orderIcon" ? <ReactSVG src={orderIcon} /> : false}
      {type === "payoutIcon" ? <ReactSVG src={payoutIcon} /> : false}
      {type === "invoiceIcon" ? <ReactSVG src={invoiceIcon} /> : false}
      {type === "reportIcon" ? <ReactSVG src={reportIcon} /> : false}
      {type === "websiteIcon" ? <ReactSVG src={websiteIcon} /> : false}
    </div>
  );
};
SidebarIcon.propTypes = {
  type: PropTypes.string,
};

export default memo(SidebarIcon);
