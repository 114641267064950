import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// import { baseAPI } from "@/app/services/baseApi";
import { ENDPOINTS } from "@/utils/constants/apiConstants";

import { baseAPI } from "../services/fetchers";

export const setContactInfoAsync = createAsyncThunk(
  "merchant/setContactInfo",
  async (data) => {
    try {
      let end_ = ENDPOINTS.MERCHANT_SET_CONTACT_INFO;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setVatInfoAsync = createAsyncThunk(
  "merchant/setVatInfoAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.MERCHANT_SET_VAT_INFO;

      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setCompanyInfoAsync = createAsyncThunk(
  "merchant/setCompanyInfoAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.MERCHANT_SET_COMPANY_INFO;

      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const merchantHardDeleteAsync = createAsyncThunk(
  "merchant/merchantHardDeleteAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.PUBLISHER_HARD_DELETE;

      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

const slice = createSlice({
  name: "merchant",
  initialState: {
    legal: null,
    stepPersonal: null,
    stepContact: null,
    stepPayment: null,
    stepVat: null,
    detail: null,
    loading: false,
    error: null,
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      state.error = null;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(setContactInfoAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(setContactInfoAsync.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.success) {
        state.stepContact = action.payload;
      } else {
        state.error = action.payload;
      }
    });
    builder.addCase(setContactInfoAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(setVatInfoAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(setVatInfoAsync.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.success) {
        state.stepVat = action.payload;
      } else {
        state.error = action.payload;
      }
    });
    builder.addCase(setVatInfoAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(setCompanyInfoAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(setCompanyInfoAsync.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.success) {
        state.stepCompany = action.payload;
      } else {
        state.error = action.payload;
      }
    });
    builder.addCase(setCompanyInfoAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { updateUser, clearState } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state) => state.user.profile;

export const merchantSetContactInfo = (data) => async (dispatch) => {
  return dispatch(setContactInfoAsync(data));
};

export const merchantVatInfo = (data) => async (dispatch) => {
  return dispatch(setVatInfoAsync(data));
};

export const merchantSetCompanyInfo = (data) => async (dispatch) => {
  return dispatch(setCompanyInfoAsync(data));
};

export const merchantHardDelete = (data) => async (dispatch) => {
  return dispatch(merchantHardDeleteAsync(data));
};
