import { Modal } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import FormTag from "../forms/FormTag";

const ModalAddTag = (props) => {
  const { selectedWebId, setModalOpen } = props;
  const { t } = useTranslation();

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Modal
      title={t("grouping.add")}
      visible={true}
      onCancel={handleClose}
      footer={false}
    >
      <FormTag defaultWebId={selectedWebId} close={handleClose} />
    </Modal>
  );
};

ModalAddTag.propTypes = {
  selectedWebId: PropTypes.string,
  setModalOpen: PropTypes.func,
};

export default ModalAddTag;
