import React from "react";
import Lottie from "react-lottie";

import * as Nodata from "@/assets/images/lottie/no-data.json";
import { useDeviceDetector } from "@/utils/hooks/useDeviceDetector";

const LottieNodata = (props) => {
  const isMobile = useDeviceDetector();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: Nodata,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height={isMobile ? 250 : 300}
      width={isMobile ? 250 : 300}
      //   isStopped={isStopped}
      //   isPaused={isPaused}
    />
  );
};
export default LottieNodata;
