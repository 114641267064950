import { FrownOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navi = useNavigate();

  return (
    <div style={{ margin: "auto" }}>
      <Result
        icon={<FrownOutlined className="icon-error" />}
        // status="404"
        title="404"
        subTitle={t("general.error400")}
        extra={
          <Button type="primary" onClick={() => navi("/")}>
            {t("general.back")}
          </Button>
        }
      />
    </div>
  );
}
