export default {
  support_ticket: "تیکت پشتیبانی",
  contact_with_support: "تماس با پشتیبانی",
  ticketId_title: "شناسه تیکت",
  title: "عنوان",
  create_date: "تاریخ ایجاد",
  status: "وضعیت",
  search_placeholder: "جستجو شناسه و یا عنوان تیکت",
  create_new_ticket: "تیکت جدید",
  request_text: "متن درخواست",
  send_ticket: "ارسال تیکت",
  department: "دپارتمان",
  importance: "درجه اهمیت",
  ticket_title: "عنوان تیکت",
  ticket_category: "موضوع تیکت",
  other: "سایر",
  pending: "در انتظار بررسی",
  answered: "پاسخ داده شده",
  closed: "بسته شده",
  no_ticket: "هیچ درخواستی وجود ندارد.",
  low: "کم",
  normal: "متوسط",
  high: "بالا",
  critical: "فوری",
  file: "فایل",
  continue: "ادامه",
  select_attach_file: "انتخاب فایل ضمیمه",
  upload_attach_file: "آپلود فایل ضمیمه",
  file_size_error: "حجم فایل بیش از حد مجاز است",
  file_number_error: "تعداد فایل بیش از حد مجاز است",
  file_max_size: "حداکثر حجم فایل های پیوست: ۱ مگابایت",
  file_max_count: "حداکثر تعداد فایل پیوست: ۵ فایل",
  file_repetitive_error: "این فایل قبلا انتخاب شده است",
};
