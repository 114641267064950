import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ENDPOINTS } from "@/utils/constants/apiConstants";
import { clearObject } from "@/utils/utils";

import { baseAPI } from "../services/fetchers";

const requestBodyListDefault = {
  page: 1,
  page_size: 10,
  sort_field: undefined,
  search_text: undefined,
  is_pagination: true,
  sort_orientation: "1", // 1 = Descending,
};

export const getPublisherListAsync = createAsyncThunk(
  "publishers/getPublisherListAsync",
  async (data) => {
    try {
      const data_ = clearObject(data);
      const body_ = Object.assign({}, requestBodyListDefault, data_);
      const end_ = ENDPOINTS.PUBLISHER_ADMIN_GET_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getPublisherActiveListSync = createAsyncThunk(
  "publishers/getPublisherActiveListSync",
  async (data) => {
    try {
      const isGetAll = data?.all === true;
      const urlParams = new URLSearchParams();
      isGetAll && urlParams.set("all", isGetAll);
      data.searchTerm && urlParams.set("searchTerm", data.searchTerm);

      const end_ = ENDPOINTS.PUBLISHER_ADMIN_GET_LIST_ACTIVE.replace(
        "{queryParams}",
        urlParams.toString()
      );

      // const end_ = ENDPOINTS.PUBLISHER_ADMIN_GET_LIST_ACTIVE;
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getPublishDetailAsync = createAsyncThunk(
  "publishers/getPublishDetailAsync",
  async (uId) => {
    try {
      const end_ = ENDPOINTS.PUBLISHER_ADMIN_GET_DETAIL.replace(
        "{userId}",
        uId
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getProjectionsByPublisherIdAsync = createAsyncThunk(
  "publishers/getProjectionsByPublisherIdAsync",
  async (pId) => {
    try {
      const end_ = ENDPOINTS.PUBLISHER_ADMIN_PROJECTION_CURRENT.replace(
        "{publisherId}",
        pId
      );

      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const syncProjectionsByPublisherIdSync = createAsyncThunk(
  "publishers/syncProjectionsByPublisherIdSync",
  async (pId) => {
    try {
      const end_ = ENDPOINTS.PUBLISHER_ADMIN_PROJECTION_GENERATE.replace(
        "{publisherId}",
        pId
      );

      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setLegalStatusPublisherAdminSync = createAsyncThunk(
  "publishers/setLegalStatusPublisherAdminSync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.PUBLISHER_ADMIN_SET_LEGAL_STATUS.replace(
        "{userId}",
        data?.userId
      ).replace("{status}", data?.status);

      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const adminPublisherSlice = createSlice({
  name: "adminPublisher",
  initialState: {
    loading: false,
    users: null,
    user: null,
    error: null,

    projectionsLoading: false,
    projectionsList: null,
    projectionsError: null,

    publisherProjectionSyncLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPublisherListAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getPublisherListAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getPublisherListAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.users = action.payload;
      }
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getPublisherActiveListSync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getPublisherActiveListSync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getPublisherActiveListSync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.users = action.payload;
      }
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getPublishDetailAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getPublishDetailAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getPublishDetailAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.user = action.payload;
      }
      state.loading = false;
      state.error = null;
    });

    builder.addCase(
      getProjectionsByPublisherIdAsync.fulfilled,
      (state, action) => {
        if (action.payload.success) {
          state.projectionsList = action.payload;
        }
        state.loading = false;
        state.error = null;
      }
    );

    builder.addCase(
      syncProjectionsByPublisherIdSync.pending,
      (state, action) => {
        state.publisherProjectionSyncLoading = true;
      }
    );
    builder.addCase(
      syncProjectionsByPublisherIdSync.fulfilled,
      (state, action) => {
        state.publisherProjectionSyncLoading = false;
      }
    );
    builder.addCase(
      syncProjectionsByPublisherIdSync.rejected,
      (state, action) => {
        state.publisherProjectionSyncLoading = false;
      }
    );
  },
});

export const getPublisherList = (data) => async (dispatch) => {
  return dispatch(getPublisherListAsync(data));
};

export const getPublishDetail = (data) => async (dispatch) => {
  return dispatch(getPublishDetailAsync(data));
};

export const getProjectionsByPublisherId = (data) => async (dispatch) => {
  return dispatch(getProjectionsByPublisherIdAsync(data));
};

export const syncProjectionsByPublisherId = (data) => async (dispatch) => {
  return dispatch(syncProjectionsByPublisherIdSync(data));
};

export const getPublisherActiveList = (data) => async (dispatch) => {
  return dispatch(getPublisherActiveListSync(data));
};

export const setLegalStatusPublisherAdmin = (data) => async (dispatch) => {
  return dispatch(setLegalStatusPublisherAdminSync(data));
};

export default adminPublisherSlice.reducer;
