import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ENDPOINTS } from "@/utils/constants/apiConstants";
import { clearObject } from "@/utils/utils";

import { baseAPI } from "../services/fetchers";

export const getContractTemplatesListAsync = createAsyncThunk(
  "contracts/getContractTemplatesListAsync",
  async (data) => {
    try {
      const body = {
        page: 1,
        page_size: 10,
        sort_orientation: "1",
      };
      const data_ = clearObject(data);
      const body_ = Object.assign({}, body, data_);

      const end_ = ENDPOINTS.ADMIN_CONTRACT_TEMPLATE_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getContractTemplateDetailAsync = createAsyncThunk(
  "contract/getContractTemplateDetailAsync",
  async (cId) => {
    try {
      const end_ = ENDPOINTS.ADMIN_CONTRACT_TEMPLATE_GET.replace(
        "{contract_id}",
        cId
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const updateContractTemplateAsync = createAsyncThunk(
  "contract/updateContractTemplateAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.ADMIN_CONTRACT_TEMPLATE_UPDATE;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const createContractTemplateAsync = createAsyncThunk(
  "contract/createContractTemplateAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.ADMIN_CONTRACT_TEMPLATE_ADD;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const contractUpdateStatusAsync = createAsyncThunk(
  "contract/contractUpdateStatusAsync",
  async (data) => {
    try {
      // const end_ = ENDPOINTS.ADMIN_CONTRACT_UPDATE_STATUS;
      let end_ = ENDPOINTS.ADMIN_CONTRACT_UPDATE_STATUS.replace(
        "{contract_id}",
        data.contract_id
      );
      end_ = end_.replace("{status}", data.status);

      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const contractUserAddAsync = createAsyncThunk(
  "contract/contractUserAddAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.ADMIN_CONTRACT_ADD;

      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const contractUserNewContractAsync = createAsyncThunk(
  "contract/contractUserNewContractAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.ADMIN_CONTRACT_NEW_CONTRACT.replace(
        "{userId}",
        data?.user_id
      ).replace("{type}", data?.type);

      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const adminContractSlice = createSlice({
  name: "adminContract",
  initialState: {
    loading: false,
    loadingAction: false,
    templates: null,
    templatesLoading: null,
    template: null,
    error: null,
  },
  reducers: {
    clearState: (state) => {
      state.template = false;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getContractTemplatesListAsync.pending, (state, action) => {
      state.templatesLoading = true;
      state.error = null;
    });
    builder.addCase(getContractTemplatesListAsync.rejected, (state, action) => {
      state.templatesLoading = false;
      state.error = action.error;
    });
    builder.addCase(
      getContractTemplatesListAsync.fulfilled,
      (state, action) => {
        if (action.payload.success) {
          state.templates = action.payload;
        }
        state.templatesLoading = false;
        state.error = null;
      }
    );
    builder.addCase(getContractTemplateDetailAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getContractTemplateDetailAsync.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error;
      }
    );
    builder.addCase(
      getContractTemplateDetailAsync.fulfilled,
      (state, action) => {
        if (action.payload.success) {
          state.template = action.payload;
        }
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(updateContractTemplateAsync.pending, (state, action) => {
      state.loadingAction = true;
      state.error = null;
    });
    builder.addCase(updateContractTemplateAsync.rejected, (state, action) => {
      state.loadingAction = false;
      state.error = action.error;
    });
    builder.addCase(updateContractTemplateAsync.fulfilled, (state, action) => {
      // if (action.payload.success) {
      //   state.template = action.payload;
      // }
      state.loadingAction = false;
      state.error = null;
    });
    builder.addCase(createContractTemplateAsync.pending, (state, action) => {
      state.loadingAction = true;
      state.error = null;
    });
    builder.addCase(createContractTemplateAsync.rejected, (state, action) => {
      state.loadingAction = false;
      state.error = action.error;
    });
    builder.addCase(createContractTemplateAsync.fulfilled, (state, action) => {
      state.loadingAction = false;
      state.error = null;
    });
  },
});

export const { clearState } = adminContractSlice.actions;

export const getContractTemplatesList = (data) => async (dispatch) => {
  return dispatch(getContractTemplatesListAsync(data));
};

export const getContractTemplateDetail = (data) => async (dispatch) => {
  return dispatch(getContractTemplateDetailAsync(data));
};

export const updateContractTemplate = (data) => async (dispatch) => {
  return dispatch(updateContractTemplateAsync(data));
};

export const createContractTemplate = (data) => async (dispatch) => {
  return dispatch(createContractTemplateAsync(data));
};

export const contractUpdateStatus = (data) => async (dispatch) => {
  return dispatch(contractUpdateStatusAsync(data));
};

export const contractUserAdd = (data) => async (dispatch) => {
  return dispatch(contractUserAddAsync(data));
};

export const contractUserNewContract = (data) => async (dispatch) => {
  return dispatch(contractUserNewContractAsync(data));
};

export default adminContractSlice.reducer;
