import { Drawer } from "antd";
import confirm from "antd/lib/modal/confirm";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { LANGUAGE } from "@/utils/constants/apiConstants";
import { useAccount } from "@/utils/hooks/useAuth";

import "./styles.less";

const SliderMenu = (props) => {
  const { t } = useTranslation();
  const { profile } = useSelector((state) => state.account);
  const { lang } = useAccount();

  const onClose = (e) => {
    if (props?.callbackClose) {
      confirm({
        title: t("general.sureLeavePage"),
        async onOk() {
          props.setCollapsed(false);
          if (_.isFunction(props.callbackClose)) {
            props.callbackClose();
          }
        },
      });
    } else if (props?.callbackCloseNoConfirm) {
      props.callbackCloseNoConfirm();
      props.setCollapsed(false);
    } else {
      props.setCollapsed(false);
    }
  };
  return (
    <Drawer
      title={t(props.title)}
      placement={lang === LANGUAGE.PERSIAN ? "left" : "right"}
      size={props?.size || "default"}
      width={props?.width}
      onClose={onClose}
      visible={props.collapsed}
      // extra={
      //   <Space>
      //     <Button onClick={onClose}>Cancel</Button>
      //     <Button type="primary">OK</Button>
      //   </Space>
      // }
    >
      <div className="menu-content">{props.children}</div>
    </Drawer>
  );
};

SliderMenu.propTypes = {
  collapsed: PropTypes.bool,
  setCollapsed: PropTypes.func,
  children: PropTypes.any,
  title: PropTypes.string,
  width: PropTypes.number,
  size: PropTypes.string,
  callbackClose: PropTypes.func,
  callbackCloseNoConfirm: PropTypes.func,
};

export default SliderMenu;
