import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";

import AdPlaceLiveViewer from "../AdPlaceLiveViewer";
import CardSkeleton from "./CardSkeleton";
import FormMaster from "./FormMaster";
import "./PopupConfigBanner.less";

const PopupConfigBanner = (props) => {
  const { doFunction, form } = props;
  const [position, setPosition] = useState();

  const onFinish = (event) => {
    // Maybe need change box-widget
    doFunction(event);
  };

  const onValuesChange = (e) => {
    if (e?.position_type) setPosition(e?.position_type);
  };

  return (
    <Row gutter={[24, 24]} className="main-config pop-banner-config">
      <Col xs={10}>
        <FormMaster
          config={{ position_type: true }}
          onValuesChange={onValuesChange}
          form={form}
          onFinish={onFinish}
        />
      </Col>
      <Col xs={14}>
        <AdPlaceLiveViewer>
          <div className="box-preview">
            <div className="box-preview__content">
              <h2 className="skeleton"></h2>
              {Array(5)
                .fill(1)
                .map((i) => (
                  <p key={i} className="skeleton"></p>
                ))}
            </div>
            <div className={`box-preview__widget ${position}`}>
              <CardSkeleton type={"BANNER"} />
            </div>
          </div>
        </AdPlaceLiveViewer>
      </Col>
    </Row>
  );
};

PopupConfigBanner.propTypes = {
  doFunction: PropTypes.any,
  form: PropTypes.any,
};
export default PopupConfigBanner;
