import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";

import AdPlaceLiveViewer from "../AdPlaceLiveViewer";
import CardSkeleton from "./CardSkeleton";
import FormMaster from "./FormMaster";
import "./InFeedConfig.less";
import SkeltonGenerator from "./SkeltonGenerator";

const InFeedConfig = (props) => {
  const { doFunction, form } = props;

  const onFinish = (event) => {
    doFunction(event);
  };

  const onValuesChange = (e) => {};

  return (
    <Row gutter={[24, 24]} className="main-config in-feed-config">
      <Col xs={10}>
        <FormMaster
          form={form}
          onValuesChange={onValuesChange}
          onFinish={onFinish}
          config={{ content_display_type: "ImageOnRight" }}
        />
      </Col>
      <Col xs={14}>
        <AdPlaceLiveViewer>
          <div className="box-preview">
            <div className="box-preview__content">
              <h2 className="skeleton"></h2>
              <SkeltonGenerator count={6} />
            </div>
            <div className="box-preview__widget">
              <CardSkeleton mode={"horizontal"} />
            </div>
            <div>
              <SkeltonGenerator count={8} />
            </div>
          </div>
        </AdPlaceLiveViewer>
      </Col>
    </Row>
  );
};

InFeedConfig.propTypes = {
  doFunction: PropTypes.any,
  form: PropTypes.any,
};
export default InFeedConfig;
