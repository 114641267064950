import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import inTrackEvent from "@/components/extra/Intrack/InTrackEvent";
// import { baseAPI } from "@/app/services/baseApi";
import { ENDPOINTS, USER_TYPE } from "@/utils/constants/apiConstants";
import { clearStorage } from "@/utils/utils";

import { baseAPI, cleanStorage } from "../services/fetchers";

export const loginAsync = createAsyncThunk("account/login", async (data) => {
  try {
    const response = await baseAPI({
      endPoint: ENDPOINTS.ACCOUNT_LOGIN,
      body: data,
      isPublic: true,
    });
    if (response?.data?.success) {
      cleanStorage();
      const d = new Date();
      localStorage.setItem("lts-login", d.getTime());
      const r = await getUserDetail();
      // inTrackEvent.loginEvent(r);
      return r;
    }
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  } catch (e) {
    return e.response?.data;
  }
});

export const autoLoginAsync = createAsyncThunk(
  "account/autoLogin",
  async (data) => {
    try {
      const response = await baseAPI({
        endPoint: ENDPOINTS.USER_ADMIN_ACCOUNT_AUTO_LOGIN,
        body: data,
      });
      if (response?.payload?.success) {
        const r = await getUserDetail();
        return r;
      }
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const resetPasswordAsync = createAsyncThunk(
  "account/resetPasswordAsync",
  async (data) => {
    try {
      const response = await baseAPI({
        endPoint: ENDPOINTS.ACCOUNT_RESET_PASSWORD,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const accountLogoutAsync = createAsyncThunk(
  "account/accountLogoutAsync",
  async () => {
    try {
      const response = await baseAPI({
        endPoint: ENDPOINTS.ACCOUNT_LOGOUT,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const accountForgotAsync = createAsyncThunk(
  "account/accountForgotAsync",
  async (data) => {
    try {
      const response = await baseAPI({
        endPoint: ENDPOINTS.ACCOUNT_FORGOT_PASSWORD,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const registerAsync = createAsyncThunk(
  "account/register",
  async (data) => {
    try {
      const response = await baseAPI({
        endPoint: ENDPOINTS.ACCOUNT_REGISTER_AND_VERIFY,
        body: data,
        // isPublic: true,
      });
      // The value we return becomes the `fulfilled` action payload
      return { ...response.data, role_type: data.role_type };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const refreshTokenAsync = createAsyncThunk(
  "account/refresh",
  async (data) => {
    try {
      const response = await baseAPI({
        endPoint: ENDPOINTS.ACCOUNT_REFRESH,
        body: data,
        // method: "GET",
        // isPublic: true,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const sendCodeUnRegisteredAsync = createAsyncThunk(
  "account/sendCodeUnRegistered",
  async (data) => {
    try {
      const response = await baseAPI({
        endPoint: ENDPOINTS.ACCOUNT_SEND_CODE_UNREGISTERED,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const sendCodeAsync = createAsyncThunk(
  "account/sendCode",
  async (data) => {
    try {
      const response = await baseAPI({
        endPoint: ENDPOINTS.ACCOUNT_SEND_CODE,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const sendEmailCodeAsync = createAsyncThunk(
  "account/send/email/code",
  async (data) => {
    try {
      const response = await baseAPI({
        endPoint: ENDPOINTS.ACCOUNT_SEND_EMAIL_CODE,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const sendSmsCodeAsync = createAsyncThunk(
  "account/send/sms/code",
  async (data) => {
    try {
      const response = await baseAPI({
        endPoint: ENDPOINTS.ACCOUNT_SEND_SMS_CODE,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const configAddAsync = createAsyncThunk(
  "account/config/add",
  async (data) => {
    try {
      const response = await baseAPI({
        endPoint: ENDPOINTS.ACCOUNT_CONFIG_ADD,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const verifyAsync = createAsyncThunk("account/verify", async (data) => {
  try {
    const response = await baseAPI({
      endPoint: ENDPOINTS.ACCOUNT_VERIFY,
      body: data,
    });
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  } catch (e) {
    return e.response?.data;
  }
});

export const accountOldVerifyEmailAsync = createAsyncThunk(
  "account/old/verify/email",
  async (data) => {
    try {
      const response = await baseAPI({
        endPoint: ENDPOINTS.ACCOUNT_OLD_VERIFY_EMAIL,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const accountVerifyMobileAsync = createAsyncThunk(
  "account/verify/mobile",
  async (data) => {
    try {
      const response = await baseAPI({
        endPoint: ENDPOINTS.ACCOUNT_VERIFY_MOBILE,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

const getUserDetail = async () => {
  try {
    const end_ = ENDPOINTS.ACCOUNT_GET_USER_DETAIL;
    const response = await baseAPI({
      endPoint: end_,
    });
    let userDetail = {};
    let privileges = {};

    const userType = _.get(response.data, "data.user_type");
    let error = false;

    if (process.env.REACT_APP_MODE === "SSP") {
      if (userType === USER_TYPE.ADVERTISER) {
        const endDetail = ENDPOINTS.PUBLISHER_GET_DETAIL;
        userDetail = await baseAPI({
          endPoint: endDetail,
        });
        userDetail = userDetail?.data;
        // privileges = Privileges.publisher;
        privileges = userDetail?.privileges;
      } else {
        error = "NOT_ALLOWED";
      }
    } else {
      if (userType === USER_TYPE.PUBLISHER) {
        const endDetail = ENDPOINTS.PUBLISHER_GET_DETAIL;
        userDetail = await baseAPI({
          endPoint: endDetail,
        });
        userDetail = userDetail?.data;
        // privileges = Privileges.publisher;
        privileges = userDetail?.privileges;
      } else if (userType === USER_TYPE.MERCHANT) {
        const endDetail = ENDPOINTS.MERCHANT_GET_DETAIL;
        userDetail = await baseAPI({
          endPoint: endDetail,
        });
        userDetail = userDetail?.data;
        // privileges = Privileges.merchant;
        privileges = userDetail?.privileges;
      }
    }

    const endPointRole = ENDPOINTS.ACCOUNT_GET_USER_ROLE;
    const responseRole = await baseAPI({
      endPoint: endPointRole,
    });
    const roles = responseRole.data?.data || [];

    // The value we return becomes the `fulfilled` action payload
    if (userDetail) {
      return { ...response.data, userDetail, privileges, userRoles: roles };
    } else {
      return { error: error };
    }
  } catch (e) {
    return e.response?.data;
  }
};
export const accountDetailAsync = createAsyncThunk(
  "account/useDetail",
  async () => {
    const r = await getUserDetail();
    return r;
  }
);

export const accountDetailSpecificAsync = createAsyncThunk(
  "account/useDetailSpecific",
  async (data) => {
    try {
      const end_ = ENDPOINTS.ACCOUNT_GET_USER_DETAIL;
      const response = await baseAPI({
        endPoint: end_,
      });
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const accountFinalizeRegisteringAsync = createAsyncThunk(
  "account/accountFinalizeRegistering",
  async (data) => {
    try {
      const end_ = ENDPOINTS.ACCOUNT_FINALIZE_REGISTERING;
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const accountChangePasswordAsync = createAsyncThunk(
  "account/accountChangePasswordAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.ACCOUNT_CHANGE_PASSWORD;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const accountGetRolesAsync = createAsyncThunk(
  "account/accountGetRolesAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.ACCOUNT_GET_ROLES;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const accountGetPrivilegesAsync = createAsyncThunk(
  "account/accountGetPrivilegesAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.ACCOUNT_GET_PRIVILEGES;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const accountProfilePhotoAsync = createAsyncThunk(
  "account/accountProfilePhotoAsync",
  async (fileId) => {
    try {
      const end_ = ENDPOINTS.ACCOUNT_PROFILE_PHOTO_SET.replace(
        "{fileId}",
        fileId
      );
      const response = await baseAPI({
        endPoint: end_,
        // body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const accountOldUserAsync = createAsyncThunk(
  "account/accountOldUserAsync",
  async (user_name) => {
    try {
      const end_ = ENDPOINTS.ACCOUNT_OLD_USERNAME.replace(
        "{username}",
        user_name
      );
      const response = await baseAPI({
        endPoint: end_,
        // body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const accountMobileChangeAsync = createAsyncThunk(
  "account/accountMobileChangeAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.ACCOUNT_MOBILE_CHANGE;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

const slice = createSlice({
  name: "account",
  initialState: {
    profile: null,
    verify: null,
    register: null,
    loading: false,
    oldUserError: null,
    error: null,
    roles: null,
    privileges: null,
    // token: window.localStorage.getItem("token"),
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      state.error = null;
      state.profile = null;
      state.register = null;

      state.dataAccountSpecific = null;
    },
    updateUser: (state, { payload: { profile, token, fetched = false } }) => {
      state.profile = profile;
      state.fetched = fetched;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(loginAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(loginAsync.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.success) {
        // InTrack Event
        inTrackEvent.loginEvent(action.payload);
        state.profile = action.payload;
      } else {
        state.error = action.payload;
      }
    });
    builder.addCase(loginAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(autoLoginAsync.fulfilled, (state, action) => {
      // state.loading = false;
      // state.register = null;
      // state.verify = null;
      // if (action.payload.success) {
      //   state.profile = action.payload;
      // } else {
      //   state.error = action.payload;
      // }
    });
    builder.addCase(registerAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(registerAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.register = action.payload;
      if (action.payload.success) {
        if (action?.payload?.role_type === 1) {
          inTrackEvent.newUserRegisterEvent({
            userId: action?.payload?.data?.user_id,
            phone_number: action?.payload?.data?.username,
          });
        }
      } else {
        state.error = action.payload;
      }
    });
    builder.addCase(registerAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(sendCodeUnRegisteredAsync.pending, (state) => {
      state.loading = true;
      state.verify = null;
      state.error = null;
    });
    builder.addCase(sendCodeUnRegisteredAsync.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.success) {
        state.verify = action.payload;
      } else {
        state.error = action.payload;
      }
    });
    builder.addCase(sendCodeUnRegisteredAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(sendCodeAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(sendCodeAsync.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.success) {
        state.verify = action.payload;
      } else {
        state.error = action.payload;
      }
    });
    builder.addCase(sendCodeAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(sendEmailCodeAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(sendEmailCodeAsync.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.success) {
        state.verify = action.payload;
      } else {
        state.error = action.payload;
      }
    });
    builder.addCase(sendEmailCodeAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(sendSmsCodeAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(sendSmsCodeAsync.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.success) {
        state.verify = action.payload;
      } else {
        state.error = action.payload;
      }
    });
    builder.addCase(sendSmsCodeAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(accountDetailAsync.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.success) {
        state.profile = action.payload;
      }
    });
    builder.addCase(accountDetailAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(accountLogoutAsync.fulfilled, (state, action) => {
      // state.profile = null;
      state.loading = false;
    });
    builder.addCase(verifyAsync.fulfilled, (state, action) => {
      if (!action.payload.success) {
        state.error = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(verifyAsync.rejected, (state, action) => {
      state.error = action.error;
      state.loading = false;
    });

    builder.addCase(accountOldVerifyEmailAsync.fulfilled, (state, action) => {
      if (!action.payload.success) {
        state.error = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(accountOldVerifyEmailAsync.rejected, (state, action) => {
      state.error = action.error;
      state.loading = false;
    });

    builder.addCase(accountVerifyMobileAsync.fulfilled, (state, action) => {
      if (!action.payload.success) {
        state.error = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(accountVerifyMobileAsync.rejected, (state, action) => {
      state.error = action.error;
      state.loading = false;
    });

    builder.addCase(accountGetRolesAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.roles = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(accountGetPrivilegesAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.privileges = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(accountOldUserAsync.pending, (state) => {
      state.loading = true;
      state.oldUserError = null;
    });
    builder.addCase(accountOldUserAsync.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.success) {
        state.data = action.payload;
      } else {
        state.oldUserError = action.payload;
      }
    });
    builder.addCase(accountOldUserAsync.rejected, (state, action) => {
      state.loading = false;
      state.oldUserError = action.error;
    });

    builder.addCase(accountDetailSpecificAsync.fulfilled, (state, action) => {
      state.dataAccountSpecific = action.payload;
    });
  },
});

export const { updateUser, clearState } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state) => state.user.profile;

export const logout = () => async (dispatch) => {
  clearStorage();
  dispatch(
    updateUser({
      profile: null,
      // token: null,
      fetched: false,
    })
  );
};

export const accountLogin = (data) => async (dispatch) => {
  return dispatch(loginAsync(data));
};

// could RTKQuery
export const accountRegister = (data) => async (dispatch) => {
  return dispatch(registerAsync(data));
};

export const accountVerify = (data) => async (dispatch) => {
  return dispatch(verifyAsync(data));
};

export const accountOldVerifyEmail = (data) => async (dispatch) => {
  return dispatch(accountOldVerifyEmailAsync(data));
};

export const accountVerifyMobile = (data) => async (dispatch) => {
  return dispatch(accountVerifyMobileAsync(data));
};

export const accountSendCodeUnRegistered = (data) => async (dispatch) => {
  return dispatch(sendCodeUnRegisteredAsync(data));
};

export const accountSendCode = (data) => async (dispatch) => {
  return dispatch(sendCodeAsync(data));
};

export const sendEmailCode = (data) => async (dispatch) => {
  return dispatch(sendEmailCodeAsync(data));
};

export const sendSmsCode = (data) => async (dispatch) => {
  return dispatch(sendSmsCodeAsync(data));
};

export const accountAutoLogin = (data) => async (dispatch) => {
  return dispatch(autoLoginAsync(data));
};

export const accountUserDetail = (data) => async (dispatch) => {
  return dispatch(accountDetailAsync(data));
};

export const accountDetailSpecific = (data) => async (dispatch) => {
  return dispatch(accountDetailSpecificAsync(data));
};

export const accountForgot = (data) => async (dispatch) => {
  return dispatch(accountForgotAsync(data));
};

export const accountLogout = () => async (dispatch) => {
  return dispatch(accountLogoutAsync());
};

export const refreshToken = () => async (dispatch) => {
  return dispatch(refreshTokenAsync());
};

export const accountFinalizeRegistering = () => async (dispatch) => {
  return dispatch(accountFinalizeRegisteringAsync());
};

export const accountChangePassword = (data) => async (dispatch) => {
  return dispatch(accountChangePasswordAsync(data));
};

export const accountGetRoles = (data) => async (dispatch) => {
  return dispatch(accountGetRolesAsync(data));
};

export const accountGetPrivileges = (data) => async (dispatch) => {
  return dispatch(accountGetPrivilegesAsync(data));
};

export const accountProfilePhoto = (data) => async (dispatch) => {
  return dispatch(accountProfilePhotoAsync(data));
};

export const accountOldUser = (data) => async (dispatch) => {
  return dispatch(accountOldUserAsync(data));
};

export const accountMobileChange = (data) => async (dispatch) => {
  return dispatch(accountMobileChangeAsync(data));
};

export const resetPassword = (data) => async (dispatch) => {
  return dispatch(resetPasswordAsync(data));
};

export const configAdd = (data) => async (dispatch) => {
  return dispatch(configAddAsync(data));
};
