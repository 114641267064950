import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ENDPOINTS } from "@/utils/constants/apiConstants";
import { clearObject } from "@/utils/utils";

import { baseAPI } from "../services/fetchers";

export const addWidgetAsync = createAsyncThunk(
  "productWidget/addWidgetAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.PRODUCT_WIDGET_ADD;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const editWidgetAsync = createAsyncThunk(
  "productWidget/editWidgetAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.PRODUCT_WIDGET_EDIT;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const addWidgetSmartAsync = createAsyncThunk(
  "productWidget/addWidgetSmartAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.PRODUCT_WIDGET_SMART_ADD;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const editWidgetSmartAsync = createAsyncThunk(
  "productWidget/editWidgetSmartAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.PRODUCT_WIDGET_SMART_EDIT;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getProductsWidgetAsync = createAsyncThunk(
  "productWidget/getProductsWidgetAsync",
  async (data) => {
    const requestBodyListDefault = {
      page: 1,
      page_size: 10,
      sort_field: undefined,
      search_text: undefined,
      is_pagination: true,
      sort_orientation: "1", // 1 = Descending,
    };

    try {
      const end_ = ENDPOINTS.PRODUCT_WIDGET_LIST;
      const data_ = clearObject(data);
      const body_ = Object.assign({}, requestBodyListDefault, data_);
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getProductsWidgetSmartAsync = createAsyncThunk(
  "productWidget/getProductsWidgetSmartAsync",
  async (data) => {
    const requestBodyListDefault = {
      page: 1,
      page_size: 10,
      sort_field: undefined,
      search_text: undefined,
      is_pagination: true,
      sort_orientation: "1", // 1 = Descending,
    };

    try {
      const end_ = ENDPOINTS.PRODUCT_WIDGET_LIST_SMART;
      const data_ = clearObject(data);
      const body_ = Object.assign({}, requestBodyListDefault, data_);
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getProductWidgetCodeAsync = createAsyncThunk(
  "productWidget/getProductWidgetCodeAsync",
  async (data) => {
    try {
      // const body = {
      //   widget_id: 0,
      //   is_public: true,
      //   redirect_type: "0 = ProductPage",
      //   url: "string",
      // };
      const end_ = ENDPOINTS.PRODUCT_WIDGET_GET_CODE;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getProductSmartWidgetCodeAsync = createAsyncThunk(
  "productWidget/getProductSmartWidgetCodeAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.PRODUCT_WIDGET_SMART_GET_CODE;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getProductSmartWidgetDetailAsync = createAsyncThunk(
  "productWidget/getProductSmartWidgetDetail",
  async (wId, data) => {
    try {
      const end_ = ENDPOINTS.PRODUCT_WIDGET_SMART_GET_DETAIL.replace(
        "{widgetId}",
        wId
      );
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getProductWidgetDetailAsync = createAsyncThunk(
  "productWidget/getProductWidgetDetailAsync",
  async (wId, data) => {
    try {
      const end_ = ENDPOINTS.PRODUCT_WIDGET_GET_DETAIL.replace(
        "{widgetId}",
        wId
      );
      const response = await baseAPI({
        endPoint: end_,
        // body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getProductWidgetDetailEncryptedAsync = createAsyncThunk(
  "productWidget/getProductWidgetDetailEncryptedAsync",
  async (wId, data) => {
    try {
      const end_ = ENDPOINTS.PRODUCT_WIDGET_GET_DETAIL_ENCRYPTED.replace(
        "{widgetId}",
        wId
      );
      const response = await baseAPI({
        endPoint: end_,
        // body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const productWidgetSlice = createSlice({
  name: "productWidget",
  initialState: {
    widgets: null,
    widgetsLoading: false,
    widgetsError: null,

    smartWidgets: null,
    smartWidgetsLoading: false,
    smartWidgetsError: null,

    addProductWidgetLoading: false,
    addProductWidgetError: null,
    addProductWidgetData: null,

    widgetCode: null,
    widgetCodeLoading: false,
    widgetCodeError: null,

    widgetAdd: null,
    widgetAddLoading: false,
    widgetAddError: null,
    widgetAddCode: null,

    widgetSmartDetail: null,
    widgetSmartDetailLoading: false,
    widgetSmartDetailError: null,

    widgetDetail: null,
    widgetDetailLoading: false,
    widgetDetailError: null,

    loading: false,
    error: null,
  },
  reducers: {
    clearWidgetState: (state) => {
      state.widgets = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductsWidgetAsync.pending, (state, action) => {
      state.widgetsLoading = true;
      state.widgetsError = null;
      state.widgetDetail = null;
      state.widgetSmartDetail = null;
      state.widgetCode = null;
    });
    builder.addCase(getProductsWidgetAsync.rejected, (state, action) => {
      state.widgetsLoading = false;
      state.widgetsError = action.error;
    });
    builder.addCase(getProductsWidgetAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.widgets = action.payload;
      }
      state.widgetsLoading = false;
      state.widgetsError = null;
    });

    builder.addCase(getProductsWidgetSmartAsync.pending, (state, action) => {
      state.smartWidgetsLoading = true;
      state.smartWidgetsError = null;
    });
    builder.addCase(getProductsWidgetSmartAsync.rejected, (state, action) => {
      state.smartWidgetsLoading = false;
      state.smartWidgetsError = action.error;
    });
    builder.addCase(getProductsWidgetSmartAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.smartWidgets = action.payload;
      }
      state.smartWidgetsLoading = false;
      state.smartWidgetsError = null;
    });

    builder.addCase(getProductWidgetCodeAsync.pending, (state, action) => {
      state.widgetCodeLoading = true;
      state.widgetCodeError = null;
    });
    builder.addCase(getProductWidgetCodeAsync.rejected, (state, action) => {
      state.widgetCodeLoading = false;
      state.widgetCodeError = action.error;
    });
    builder.addCase(getProductWidgetCodeAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.widgetCode = action.payload;
      }
      state.widgetCodeLoading = false;
      state.widgetCodeError = null;
    });

    builder.addCase(getProductSmartWidgetCodeAsync.pending, (state, action) => {
      state.widgetCodeLoading = true;
      state.widgetCodeError = null;
    });
    builder.addCase(
      getProductSmartWidgetCodeAsync.rejected,
      (state, action) => {
        state.widgetCodeLoading = false;
        state.widgetCodeError = action.error;
      }
    );
    builder.addCase(
      getProductSmartWidgetCodeAsync.fulfilled,
      (state, action) => {
        if (action.payload.success) {
          state.widgetCode = action.payload;
        }
        state.widgetCodeLoading = false;
        state.widgetCodeError = null;
      }
    );

    builder.addCase(addWidgetAsync.pending, (state, action) => {
      state.widgetAddLoading = true;
      state.widgetAddError = null;
      state.widgetAddCode = null;
    });
    builder.addCase(addWidgetAsync.rejected, (state, action) => {
      state.widgetAddLoading = false;
      state.widgetAddError = action.error;
    });
    builder.addCase(addWidgetAsync.fulfilled, (state, action) => {
      state.widgetAddLoading = false;
      state.widgetAddError = null;
      if (action.payload.success) {
        state.widgetAddCode = action.payload;
      }
    });

    builder.addCase(
      getProductSmartWidgetDetailAsync.pending,
      (state, action) => {
        state.widgetSmartDetailLoading = true;
        state.widgetSmartDetailError = null;
      }
    );
    builder.addCase(
      getProductSmartWidgetDetailAsync.fulfilled,
      (state, action) => {
        if (action.payload.success) {
          state.widgetSmartDetail = action.payload;
        }
        state.widgetSmartDetailLoading = false;
        state.widgetSmartDetailError = null;
      }
    );
    builder.addCase(
      getProductSmartWidgetDetailAsync.rejected,
      (state, action) => {
        state.widgetSmartDetailLoading = false;
        state.widgetSmartDetailError = null;
      }
    );

    builder.addCase(getProductWidgetDetailAsync.pending, (state, action) => {
      state.widgetDetailLoading = true;
      state.widgetDetailError = null;
    });
    builder.addCase(getProductWidgetDetailAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.widgetDetail = action.payload;
      }
      state.widgetDetailLoading = false;
      state.widgetDetailError = null;
    });
    builder.addCase(getProductWidgetDetailAsync.rejected, (state, action) => {
      state.widgetDetailLoading = false;
      state.widgetDetailError = null;
    });

    builder.addCase(
      getProductWidgetDetailEncryptedAsync.pending,
      (state, action) => {
        state.widgetDetailLoading = true;
        state.widgetDetailError = null;
      }
    );
    builder.addCase(
      getProductWidgetDetailEncryptedAsync.fulfilled,
      (state, action) => {
        if (action.payload.success) {
          state.widgetDetail = action.payload;
        }
        state.widgetDetailLoading = false;
        state.widgetDetailError = null;
      }
    );
    builder.addCase(
      getProductWidgetDetailEncryptedAsync.rejected,
      (state, action) => {
        state.widgetDetailLoading = false;
        state.widgetDetailError = null;
      }
    );

    builder.addCase(editWidgetAsync.pending, (state, action) => {
      state.widgetEditLoading = true;
    });
    builder.addCase(editWidgetAsync.fulfilled, (state, action) => {
      state.widgetEditLoading = false;
    });
    builder.addCase(editWidgetAsync.rejected, (state, action) => {
      state.widgetEditLoading = false;
    });
  },
});

export const { clearWidgetState } = productWidgetSlice.actions;

export const addWidget = (data) => async (dispatch) => {
  return dispatch(addWidgetAsync(data));
};

export const editWidget = (data) => async (dispatch) => {
  return dispatch(editWidgetAsync(data));
};

export const getProductsWidget = (data) => async (dispatch) => {
  return dispatch(getProductsWidgetAsync(data));
};

export const getProductsWidgetSmart = (data) => async (dispatch) => {
  return dispatch(getProductsWidgetSmartAsync(data));
};

export const getProductWidgetCode = (data) => async (dispatch) => {
  return dispatch(getProductWidgetCodeAsync(data));
};

export const addWidgetSmart = (data) => async (dispatch) => {
  return dispatch(addWidgetSmartAsync(data));
};

export const editWidgetSmart = (data) => async (dispatch) => {
  return dispatch(editWidgetSmartAsync(data));
};

export const getProductSmartWidgetCode = (data) => async (dispatch) => {
  return dispatch(getProductSmartWidgetCodeAsync(data));
};

export const getProductSmartWidgetDetail = (data) => async (dispatch) => {
  return dispatch(getProductSmartWidgetDetailAsync(data));
};

export const getProductWidgetDetail = (data) => async (dispatch) => {
  return dispatch(getProductWidgetDetailAsync(data));
};

export const getProductWidgetDetailEncrypted = (data) => async (dispatch) => {
  return dispatch(getProductWidgetDetailEncryptedAsync(data));
};

export default productWidgetSlice.reducer;
