import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";

import AdPlaceLiveViewer from "../AdPlaceLiveViewer";
import CardSkeleton from "./CardSkeleton";
import "./FixedConfigBanner.less";
import FormMaster, { bannerSizeOptions } from "./FormMaster";
import SkeltonGenerator from "./SkeltonGenerator";

const FixedConfigBanner = (props) => {
  const { doFunction, form } = props;
  const [styles, setStyles] = useState();

  const onFinish = (event) => {
    // Maybe need change box-widget
    doFunction(event);
  };

  const onValuesChange = (e) => {
    const s = bannerSizeOptions.find((item) => item.key === e.size);
    if (s) {
      const styles_ = {
        width: s.w / 2 + "px",
        height: s.h / 2 + "px",
      };
      setStyles(styles_);
    }
  };

  return (
    <Row gutter={[24, 24]} className="main-config fixed-banner-config">
      <Col xs={10}>
        <FormMaster
          config={{ size: true }}
          onValuesChange={onValuesChange}
          form={form}
          onFinish={onFinish}
        />
      </Col>
      <Col xs={14}>
        <AdPlaceLiveViewer>
          <div className="box-preview">
            <div className="box-preview__content">
              <h2 className="skeleton"></h2>
              <SkeltonGenerator count={8} />
            </div>
            <div className="box-preview__widget" style={styles}>
              <CardSkeleton type={"BANNER"} />
            </div>
            <SkeltonGenerator count={8} />
          </div>
        </AdPlaceLiveViewer>
      </Col>
    </Row>
  );
};

FixedConfigBanner.propTypes = {
  doFunction: PropTypes.any,
  form: PropTypes.any,
};
export default FixedConfigBanner;
