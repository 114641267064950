import { Column } from "@ant-design/plots";
import moment from "moment-jalaali";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const WeekChart = () => {
  const { t } = useTranslation("ssp");
  const [dataChart, setDataChart] = useState([]);
  const currentDate = moment();

  useEffect(() => {
    let data = [
      {
        date: "monday",
        value: 52,
      },
    ];

    data = Array(7)
      .fill(1)
      .map((item, index) => {
        return {
          date: moment()
            .add(-1 * index, "day")
            .format("ddd"),
          value: Math.floor(Math.random() * 100),
        };
      });

    setDataChart(data);
  }, []);

  const config = {
    data: dataChart,
    xField: "date",
    yField: "value",
    height: 126,
    marginRatio: 0.1,
    columnWidthRatio: 0.2,
    xAxis: {
      label: {
        autoHide: true,
        rotate: 100,
        offsetX: 0,
        offsetY: 10,
        style: {
          fill: "#fff",
          fontSize: 12,
          opacity: 0.8,
        },
      },
      grid: { line: { style: { lineWidth: 0 } } },
      tickLine: { style: { lineWidth: 0 } },
    },
    yAxis: {
      label: {
        style: {
          opacity: 0,
        },
      },
      grid: { line: { style: { lineWidth: 0 } } },
      tickLine: { style: { lineWidth: 0 } },
    },
    columnStyle: {
      radius: [20, 20, 20, 20],
      // fill: "#FFF072",
    },
    meta: {
      date: {
        alias: "date",
      },
      value: {
        alias: t("dashboard.income"),
      },
    },
    color: "#FFF072",
    minColumnWidth: 10,
    maxColumnWidth: 10,
    theme: {
      styleSheet: {
        fontFamily: "IRANYekan",
      },
    },
  };
  return <Column {...config} />;
};

export default WeekChart;
