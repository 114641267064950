import React from "react";
import Button from "@material-ui/core/Button";

const TestCom = () => {
  return (
    <>
      <Button variant="contained" color="primary">
        Hello World
      </Button>
    </>
  );
};

// ReactDOM.render(
//   <React.StrictMode>
//     <Button variant="contained" color="primary">
//       Hello World
//     </Button>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

export default TestCom;
