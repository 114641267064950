import {
  BarChartOutlined as IconBar,
  CloseOutlined as IconClose,
  FilterFilled as IconFilter,
  LineChartOutlined as IconLine,
} from "@ant-design/icons";
import { Button, Card, Form, Radio, Select } from "antd";
import { t } from "i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useState } from "react";

import SelectMedia from "@/components/input/selectMedia";
import { LINK_REPORT_TYPE, USER_TYPE } from "@/utils/constants/apiConstants";
import { useAccount } from "@/utils/hooks/useAuth";

import "./WidgetWrapper.less";

const USER_TYPE_OPTIONS = [
  { key: USER_TYPE.PUBLISHER, label: "PUBLISHER" },
  { key: USER_TYPE.MERCHANT, label: "MERCHANT" },
  { key: "AFFILIO", label: "AFFILIO" },
];
const FilterMenu = (props) => {
  const { setOpen, onSubmit, exportCsv, hasUserType } = props;

  const onFinish = (e) => {
    onSubmit(e);
    setOpen(false);
  };

  return (
    <div className="widget-wrapper__filter">
      <Form onFinish={onFinish}>
        {open ? (
          <div className="backdrop" onClick={() => setOpen(false)}></div>
        ) : (
          false
        )}
        <Card
          title={t("dashboard.filters")}
          style={{ minWidth: "250px" }}
          extra={
            <>
              <IconClose
                className="close-icon"
                onClick={() => {
                  setOpen(false);
                }}
              />
            </>
          }
        >
          <div className="filter__body">
            <SelectMedia
              name={"medias"}
              size="middle"
              mode="multiple"
              placeholder={t("media.selectMedia")}
            />
            <Form.Item name={"link_type"} size="middle">
              <Select
                placeholder={t("dashboard.select_link_type")}
                mode="multiple"
              >
                {_.keys(LINK_REPORT_TYPE).map((key, i) => (
                  <Select.Option key={i} value={i}>
                    {t(`dashboard.${key.toLowerCase()}`)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {hasUserType ? (
              <Form.Item name={"user_type"} size="middle">
                <Select placeholder={t("dashboard.user_type")}>
                  {USER_TYPE_OPTIONS.map((item, i) => (
                    <Select.Option key={i} value={item.key}>
                      {t(`user.${item.label}`)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              false
            )}
            <Button htmlType="submit" type="primary" block size="middle">
              {t("general.submitFilter")}
            </Button>
          </div>
          {exportCsv ? (
            <div className="filter__footer">
              <Button type="text" block size="small" onClick={exportCsv}>
                {t("general.exportExcel")}
              </Button>
            </div>
          ) : (
            false
          )}
        </Card>
      </Form>
    </div>
  );
};

FilterMenu.propTypes = {
  exportCsv: PropTypes.func,
  setOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  hasUserType: PropTypes.bool,
};

const WidgetWrapper = (props) => {
  const {
    mode,
    setMode,
    title,
    onSubmit,
    hideSwitch,
    className,
    exportCsv,
    hideFilter,
    hasUserType,
  } = props;
  const [openFilterMenu, setOpenFilterMenu] = useState();

  const { isMerchant } = useAccount();

  return (
    <Card
      className={`${className || "commission-chart"} widget-wrapper`}
      title={<span className="title">{t(title)}</span>}
      extra={
        <div className="widget-wrapper__extra">
          {!isMerchant && !hideFilter ? (
            <Button
              type="text"
              onClick={() => setOpenFilterMenu(true)}
              icon={
                <IconFilter
                  style={{
                    fontSize: "1.1em",
                    color: "#89929E",
                  }}
                />
              }
            ></Button>
          ) : null}
          {openFilterMenu ? (
            <FilterMenu
              setOpen={setOpenFilterMenu}
              onSubmit={onSubmit}
              exportCsv={exportCsv}
              hasUserType={hasUserType}
            />
          ) : (
            false
          )}

          {!hideSwitch ? (
            <Radio.Group
              defaultValue="LINE"
              buttonStyle="solid"
              onChange={(v) => {
                setMode(v.target.value);
              }}
            >
              <Radio.Button value="LINE">
                <IconLine style={{ fontSize: "16px" }} />
              </Radio.Button>
              <Radio.Button value="BAR">
                <IconBar style={{ fontSize: "16px" }} />
              </Radio.Button>
            </Radio.Group>
          ) : (
            false
          )}
        </div>
      }
    >
      {props.children}
    </Card>
  );
};

WidgetWrapper.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.element,
  setMode: PropTypes.func,
  mode: PropTypes.ant,
  exportCsv: PropTypes.func,
  onSubmit: PropTypes.func,
  hideSwitch: PropTypes.bool,
  hideFilter: PropTypes.bool,
  hasUserType: PropTypes.bool,
  className: PropTypes.string,
};

export default WidgetWrapper;
