import PropTypes from "prop-types";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import { PUBLIC_ROUTES } from "@/app/routes";

import ErrorBoundaryPrime from "./ErrorBoundaryPrime";

const PublicLayout = ({ children }) => {
  const navigate = useNavigate();
  const { profile, loading } = useSelector((state) => state.account);

  useEffect(() => {
    try {
      if (profile) {
        const sub_ = "/";
        const to = location?.state?.from || sub_;
        const isSecurePath = PUBLIC_ROUTES.find((item) => {
          const prePath = item.path.match(/\/\w+/gm);
          if (prePath) {
            return item.secure && location.pathname.includes(prePath[0]);
          }
        });
        if (!isSecurePath) navigate(to, { replace: true });
        return;
      }
    } catch (e) {
      const cashing = "";
    }
  }, [profile]);

  return (
    <ErrorBoundaryPrime>
      <div className="public__page">
        <Outlet />
      </div>
    </ErrorBoundaryPrime>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.element,
};

export default PublicLayout;
