import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import { INVENTORY_POSITION_TYPE } from "@/utils/constants/apiConstants";
import { objectR } from "@/utils/helper";

import { PlaceContext } from "../AdPlaceBuilder";
import AdPlaceLiveViewer from "../AdPlaceLiveViewer";
import CardSkeleton from "./CardSkeleton";
import "./CatfishConfig.less";
import FormMaster from "./FormMaster";
import SkeltonGenerator from "./SkeltonGenerator";

const CatfishConfig = (props) => {
  const { doFunction, form, isBanner } = props;
  const [position, setPosition] = useState("Bottom");
  const [context, dispatchContext] = useContext(PlaceContext);

  const onFinish = (event) => {
    doFunction(event);
  };

  useEffect(() => {
    onValuesChange({ position_type: context?.config?.position_type });
  }, [context?.config]);

  const onValuesChange = (e) => {
    if (e?.position_type) {
      const pType = objectR(INVENTORY_POSITION_TYPE)[e?.position_type];
      setPosition(pType);
    }
  };

  return (
    <Row gutter={[24, 24]} className="main-config cat-fish-config">
      <Col xs={10}>
        <div className="slider-config__main">
          <FormMaster
            config={{
              start_delay_second: "0",
              enable_to_close_delay_second: 5,
              position_type_vertical: INVENTORY_POSITION_TYPE.Bottom,
              defaultSize: [
                { w: 728, h: 90 },
                { w: 468, h: 60 },
              ],
              // content_display_type: "ImageOnRight",
              excluded_content: [
                "Any",
                "ImageOnTop",
                "TitleOnTop",
                "TitleOnBottom",
                "JustText",
              ],
            }}
            form={form}
            onValuesChange={onValuesChange}
            onFinish={onFinish}
          />
        </div>
      </Col>
      <Col xs={14}>
        <AdPlaceLiveViewer>
          <div className={isBanner ? "box-banner" : ""}>
            <div className="box-preview">
              <div className="box-preview__content">
                <h2 className="skeleton"></h2>
                <SkeltonGenerator count={20} />
              </div>
            </div>
            <div
              className="box-preview__widget"
              style={{
                bottom: position === "Bottom" ? "0px" : "unset",
                top: position === "Top" ? "0px" : "unset",
              }}
            >
              <CardSkeleton
                mode={"horizontal"}
                type={isBanner ? "BANNER" : undefined}
              />
            </div>
          </div>
        </AdPlaceLiveViewer>
      </Col>
    </Row>
  );
};

CatfishConfig.propTypes = {
  doFunction: PropTypes.any,
  form: PropTypes.any,
  isBanner: PropTypes.bool,
};
export default CatfishConfig;
