import { Divider } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { clarity } from "react-microsoft-clarity";
import { useMatch } from "react-router-dom";

import LottieSuccess from "../general/LottieSuccess";

const StepSuccess = (props) => {
  const isProfile = useMatch("/profile");
  const { t } = useTranslation();
  clarity.setTag("register", "step-5");

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <LottieSuccess success />
        <Divider />
        <p>{t("wizard.yourInfoSuccessfullySubmit")}</p>
      </div>
    </>
  );
};

StepSuccess.propTypes = {
  md: PropTypes.number,
};
StepSuccess.defaultProps = {
  md: 18,
};

export default StepSuccess;
