import Modal from "antd/lib/modal/Modal";
import parse from "html-react-parser";
import _ from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { getAdvertise } from "@/app/local/appSlice";
import useConfig from "@/utils/hooks/useConfig";
import { isStage } from "@/utils/utils";

import { useAccount } from "../../utils/hooks/useAuth";

const PopupAdvertise = () => {
  const dispatch = useDispatch();
  const [detail, setDetail] = useState();
  const [visible, setVisible] = useState();
  const { isPublisher, isMerchant, isAdmin } = useAccount();
  const { isSSP } = useConfig();

  const noteId = localStorage.getItem("notification_panel");

  useEffect(async () => {
    try {
      if (!isSSP) {
        const response = await dispatch(
          getAdvertise({
            url: "pages",
            queryParams: "slug=page_panel_notification_affilio",
          })
        );

        if (response?.payload?.length) {
          const item = _.get(response, "payload[0]");
          const responseImage = await dispatch(
            getAdvertise({
              url: `media/${item.featured_media}`,
              queryParams: "",
            })
          );

          if (responseImage?.payload) {
            const item_ = _.get(responseImage, "payload");
            if (String(noteId) === String(item?.featured_media)) return;

            let link = item?.acf?.url;
            if (item?.acf?.inner_link) {
              link = link.replace("https://panel.affilio.ir", "");
            }
            const envMode = item?.acf?.production;
            let show = false;
            if (isStage()) {
              // is stage mode
              if (envMode === "STAGE" || envMode === "BOTH") {
                show = true;
              }
            } else {
              // is production mode
              if (envMode === "PRODUCTION" || envMode === "BOTH") {
                show = true;
              }
            }

            if (show) {
              setDetail({
                image: item_?.source_url || "",
                text: item?.excerpt?.rendered,
                link: link || "/banners-widget/add",
                noteId: item?.featured_media,
                isInner: item?.acf?.inner_link,
              });
              const ut = item.acf?.user_type;
              if (ut === "PUBLISHER" && isPublisher) {
                setVisible(true);
              }
              if (ut === "MERCHANT" && isMerchant) {
                setVisible(true);
              }
              if (ut === "ADMIN" && isAdmin) {
                setVisible(true);
              }
              if (ut === "ALL") {
                setVisible(true);
              }
            }
          }
        }
      }
    } catch (e) {
      const cashing = "";
    }
  }, [isSSP]);

  const onClose = () => {
    setVisible(false);
    localStorage.setItem("notification_panel", detail?.noteId);
  };

  return (
    <Modal
      visible={visible}
      className={"popup__modal"}
      cancelText={false}
      footer={false}
      onCancel={() => onClose()}
      centered
    >
      <div className="popup__modal__content">
        {/* <Spin spinning={visible}> */}
        <div>
          {!detail?.isInner ? (
            <a target="_blank" href={detail?.link} rel="noreferrer">
              <img className="image_preview" src={detail?.image} />
            </a>
          ) : (
            <Link to={detail?.link} onClick={() => onClose()}>
              <img className="image_preview" src={detail?.image} />
            </Link>
          )}
          {detail?.text ? (
            <div className="popup__text">{parse(detail?.text || "")}</div>
          ) : (
            ""
          )}
        </div>
        {/* </Spin> */}
      </div>
    </Modal>
  );
};

export default PopupAdvertise;
