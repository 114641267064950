import { Button, Form, Switch } from "antd";
import { useTranslation } from "react-i18next";

import { ChartBarIcon, ChartLineIcon } from "@/assets/images/ssp";
import SelectMedia from "@/components/input/selectMedia";

import "./ChartBarToolbar.less";

const ChartBarToolbar = () => {
  const { t } = useTranslation();
  const onValuesChange = () => {};

  return (
    <div className="chart-bar-toolbar">
      <Form onValuesChange={onValuesChange} className="chart_form">
        <div className="chart_form__filter">
          <SelectMedia
            name={"medias"}
            size="middle"
            mode="single"
            placeholder={t("media.selectMedia")}
          />
          <div>
            <Form.Item name={"aggregate"} label={"نمودار تجمیعی"}>
              <Switch disabled checked />
            </Form.Item>
          </div>
        </div>
        <div className="chart_form__type">
          <Button type="text" icon={<img src={ChartBarIcon} />} />
          <Button type="text" icon={<img src={ChartLineIcon} />} />
        </div>
      </Form>
    </div>
  );
};

export default ChartBarToolbar;
