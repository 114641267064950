import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles((theme) => {
  return {
    backdrop: {
      background: "rgba(229,228,228,0.2)",
      backdropFilter: "blur(2px)",
      zIndex: 9999,
    },
    inside: {
      position: "absolute",
      zIndex: 199,
      borderRadius: theme.spacing(2.5),
    },
    insideSpinner: {
      top: 100,
    },
  };
});

const Loading = ({ open, isInside, customClasses }) => {
  const classes = useStyles();

  return (
    <Backdrop
      className={clsx(
        classes.backdrop,
        { [classes.inside]: isInside },
        customClasses
      )}
      open={open}
    >
      <CircularProgress
        color="primary"
        size={70}
        className={classes.insideSpinner}
      />
    </Backdrop>
  );
};

Loading.propTypes = {
  open: PropTypes.bool,
  isInside: PropTypes.bool,
  customClasses: PropTypes.object,
};

export default Loading;
