import { ArrowRightOutlined as ArrowBack } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Row,
  Tabs,
  Typography,
  message,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import _ from "lodash";
import React, {
  createContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { clarity } from "react-microsoft-clarity";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  addWidget,
  addWidgetSmart,
  editWidget,
  editWidgetSmart,
  getProductSmartWidgetCode,
  getProductSmartWidgetDetail,
  getProductWidgetDetail,
  getProductWidgetDetailEncrypted,
} from "@/app/local/productWidgetSlice";
import {
  getWebsiteMerchantList,
  getWebsitePublisherList,
} from "@/app/local/websiteSlice";
import MainContainer from "@/components/container/MainContainer";
import inTrackEvent from "@/components/extra/Intrack/InTrackEvent";
import LottieSuccess from "@/components/general/LottieSuccess";
import SelectCategory from "@/components/input/selectCategory";
import SelectGroup from "@/components/input/selectGroup";
import SelectMedia from "@/components/input/selectMedia";
import ModalAddTag from "@/components/micro/ModalAddTag";
import WidgetBuilderHeader from "@/components/widget/WidgetBuilderHeader";
import WidgetListSelector from "@/components/widget/WidgetListSelector";
import WidgetPreviewCode from "@/components/widget/WidgetPreviewCode";
import WidgetUiConfig from "@/components/widget/WidgetUiConfig";
import { CODE_LANGUAGE } from "@/utils/constants/apiConstants";
// import { getMediaName } from "@/utils/helper";
import { useAccount } from "@/utils/hooks/useAuth";
import { useWidgetType } from "@/utils/hooks/useWidgetType";

import CarouselWebstores from "./components/CarouselWebstores";
import "./widgetBuilder.less";
import WidgetProductSelector from "./widgetProductsSelector";

const { TabPane } = Tabs;
export const WidgetContext = createContext([]);

const MAIN_TAB = {
  SELECT_WIDGET: "SELECT_WIDGET",
  MY_WIDGET: "MY_WIDGET",
};

const SELECT_WIDGET_STEPS = {
  STEP_INFO: "STEP_INFO",
  STEP_CONFIG: "STEP_CONFIG",
  STEP_GENERATED: "STEP_GENERATED",
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_web_store":
      return { ...state, web_store: action.value };
    case "set_selected_widget":
      return { ...state, widget: action.value };
    case "set_config_widget":
      return { ...state, config: action.value };
    case "set_media":
      return { ...state, media: action.value };
    case "set_tag":
      return { ...state, tag: action.value };
    case "set_product_context":
      return { ...state, product_context: action.value };
    case "set_category":
      return { ...state, category: action.value };
    default:
      return { ...state, [action.type]: action.value };
    // throw new Error();
  }
};
const initialState = {};

const WidgetMaker = (props) => {
  const { widgetCodeLoading, widgetDetail, widgetEditLoading } = useSelector(
    (state) => state.productWidget
  );
  message.config({
    top: "50px",
  });
  const params = useParams();
  const [form2] = useForm();
  const dispatch = useDispatch();
  const [context, dispatch_] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const [mainTab, setMainTab] = useState(MAIN_TAB.SELECT_WIDGET);
  const [tabCurrent, setTabCurrent] = useState(SELECT_WIDGET_STEPS.STEP_INFO);
  const { isPublisher, isMerchant, user, isAdvertiser } = useAccount();
  const widgetId = params?.id;
  const { isCustom, isNew: isNewPage, isSmart, encId } = useWidgetType();
  const [openAddTag, setOpenAddTag] = useState();
  const [showSelectedProducts, setShowSelectedProducts] = useState(false);
  const { mediaActiveList } = useSelector((state) => state.media);
  const mediaList = useMemo(() => {
    let response = [];
    if (isPublisher || isAdvertiser) {
      response = _.get(mediaActiveList, "data", []).map((item) => {
        return {
          media_id: item.media_id,
          media_name: item.name,
        };
      });
    } else {
      response = _.get(mediaActiveList, "data.result", []).map((item) => {
        return {
          media_id: item.media_id,
          media_name: item.media_name,
        };
      });
    }

    return response;
  }, [isPublisher, mediaActiveList]);

  const getWidgetDetail = async () => {
    if (!isNewPage) {
      if (!isCustom && !isPublisher) {
        const result = await dispatch(getProductSmartWidgetDetail(widgetId));
        if (result?.payload?.success) {
          const r = _.get(result, "payload.data");
          const webId = _.get(r, "products[0].web_store_id");
          dispatch_({ type: "set_web_store", value: webId });
          if (r.groups?.length) {
            const grps = r.groups.map(
              (item) => item.merchant_widget_grouping_id
            );
            form2.setFieldsValue({
              group: grps,
            });
            dispatch_({ type: "set_tag", value: grps });
          }
        }
      } else {
        let wId = widgetId;
        let result;
        if (isSmart) {
          result = await dispatch(getProductWidgetDetailEncrypted(encId));
        } else {
          result = await dispatch(getProductWidgetDetail(wId));
        }

        if (result?.payload?.success) {
          const r = _.get(result, "payload.data");
          const webId = _.get(r, "products[0].web_store_id");
          dispatch_({ type: "set_web_store", value: webId });
          dispatch_({ type: "widget", value: r?.Product_widget_id });
          form2.setFieldsValue({
            media: r?.media_id,
          });
          dispatch_({ type: "set_media", value: r?.media_id });
        }
      }
    }
  };

  useEffect(async () => {
    getWidgetDetail();
    if (isPublisher) {
      dispatch(getWebsitePublisherList());
    } else if (isMerchant) {
      dispatch(getWebsiteMerchantList());
    }
  }, []);

  const changeTab = (e) => {
    setMainTab(e);
    if (e === MAIN_TAB.SELECT_WIDGET) {
      setTabCurrent(SELECT_WIDGET_STEPS.STEP_INFO);
    }
  };

  const submitContinue = () => {
    const keys = Object.keys(SELECT_WIDGET_STEPS);
    const cuIndex = keys.findIndex((tab) => tab === tabCurrent);
    if (cuIndex === 0) {
      setTabCurrent(keys[cuIndex + 1]);
      clarity.setTag("add-widget", isSmart ? "step-2-smart" : "step-2-normal");
    }
    if (tabCurrent === SELECT_WIDGET_STEPS.STEP_CONFIG) {
      // document.querySelector("#submitId").click();
      onFinishMain();
      clarity.setTag("add-widget", isSmart ? "step-3-smart" : "step-3-normal");
    }
  };

  const onValuesChangeForm2 = (e) => {
    if (e?.media) {
      dispatch_({ type: "set_media", value: e?.media });
    }
    if (e?.group) {
      dispatch_({ type: "set_tag", value: e?.group });
    }
    if (e?.category) {
      dispatch_({ type: "set_category", value: e?.category });
    }
  };

  const onFinishMain = async () => {
    let result;
    if (!context.config?.name) {
      message.error({
        key: "widget",
        content: t("widget_builder.error_select_title"),
      });
      return;
    }
    if (isMerchant) {
      if (!context?.tag) {
        message.error({
          key: "widget",
          content: t("widget_builder.error_select_tag"),
        });
        return;
      }
    }

    let config = {};
    const cg = context.config;
    let styles = {};
    if (cg?.color_button) {
      styles["color_button"] = cg?.color_button;
    }
    if (cg?.color_title_button) {
      styles["color_title_button"] = cg?.color_title_button;
    }

    config = {
      widget_view_type: cg?.viewTypeDesktop,
      widget_title: cg?.name,
      show_title: cg?.showTitle,
      show_product_title: cg?.showProductTitle,
      show_product_button: cg?.showProductButton,
      show_price: cg?.showPriceButton,
      style: styles,
      theme: cg?.theme,
    };

    if (isPublisher) {
      const body = {
        name: context.config.name,
        media_list: [context.media],
        products: context?.product_context?.map((item) => ({
          product_id: item.product_id,
          main_url: item.main_url,
        })),
        config: config,
      };

      // send event
      const inTrackFunction = () => {
        // InTrack Event
        // const mediaName = getMediaName(mediaItem, mediaWebsitesList);
        const findMedia = mediaList.find(
          (item) => item.media_id === context.media
        );
        let displayType = "horizontal";
        if (cg.viewTypeDesktop === 2) {
          displayType = "vertical";
        } else if (cg.viewTypeDesktop === 3) {
          displayType = "grid";
        }
        const dataEvent = {
          userId: user?.user_id,
          widgetTitle: config.widget_title,
          media: findMedia?.media_name,
          isActive: true,
          displayType: displayType,
          showTitle: config.show_title,
          showProductTitle: config.show_product_title,
          showProductCta: config.show_product_button,
          showProductPrice: config.show_price,
        };
        if (isCustom) inTrackEvent.generateNormalWidget(dataEvent);
        else inTrackEvent.generateSmartWidget(dataEvent);
      };
      inTrackFunction();

      if (isNewPage) {
        // --->>> for custom widget
        if (isCustom) {
          result = dispatch(addWidget(body));
        } else if (!isCustom) {
          // --->>> for smart widget
          result = dispatch(
            getProductSmartWidgetCode({
              widget_id: context.widget,
              media_id: context.media,
              config: config,
              category: context?.category,
              encrypted_widget_id: widgetDetail?.data?.encrypted_widget_id,
              code_language: CODE_LANGUAGE.JavaScript,
            })
          );
        }
      } else {
        // for update smart or custom
        if (isSmart) {
          delete body.name;
        }
        body["Product_widget_id"] = widgetId;
        body["encrypted_widget_id"] = widgetDetail?.data?.encrypted_widget_id;

        result = dispatch(editWidget(body));
      }
    } else if (isMerchant) {
      const body = {
        name: context?.config?.name,
        web_store_id: context?.web_store,
        group_ids: context.tag,
        products: context?.product_context?.map((item) => ({
          product_id: item.product_id,
          main_url: item.main_url,
        })),
        config: config,
      };
      if (isNewPage) {
        result = dispatch(addWidgetSmart(body));
      } else {
        body.Product_widget_id = widgetId;
        result = dispatch(editWidgetSmart(body));
      }
    }
    result.then((r) => {
      if (r?.payload?.success) {
        message.success({
          key: "widget",
          content: t("general.successFullyDone"),
          duration: 3,
        });
        setTabCurrent(SELECT_WIDGET_STEPS.STEP_GENERATED);

        // if (!isNewPage) {
        //   setTabCurrent(SELECT_WIDGET_STEPS.STEP_GENERATED);
        // } else {
        //   message.success({
        //     key: "widget",
        //     content: t("general.successFullyDone"),
        //     duration: 3,
        //   });
        //   setTimeout(() => navigate(-1), 2000);
        // }
      } else {
        message.error({
          key: "widget",
          content: r.payload?.Message || t("general.UnexpectedErrorOccurred"),
          duration: 3,
        });
      }
    });
  };

  const cardWrapperRef = useRef();

  return (
    <WidgetContext.Provider value={[context, dispatch_]}>
      <Row gutter={24}>
        <WidgetBuilderHeader />
      </Row>

      <MainContainer wrapDisabled pTop={0}>
        <div className="widget-builder">
          <div className="widget-builder__content">
            {tabCurrent === SELECT_WIDGET_STEPS.STEP_INFO ? (
              <>
                <Card>
                  <CarouselWebstores />
                </Card>
                <br />

                {context.web_store ? (
                  <Card ref={cardWrapperRef}>
                    {isCustom || isMerchant ? (
                      <WidgetProductSelector
                        isInner
                        ref_={cardWrapperRef.current}
                        showSelectedProducts={showSelectedProducts}
                        setShowSelectedProducts={() => {
                          setShowSelectedProducts();
                        }}
                      />
                    ) : (
                      <WidgetListSelector ref_={cardWrapperRef.current} />
                    )}
                  </Card>
                ) : (
                  false
                )}
              </>
            ) : (
              false
            )}

            {tabCurrent === SELECT_WIDGET_STEPS.STEP_CONFIG ? (
              <Card
                title={
                  <div className="widget-builder__header">
                    <b>2</b>
                    {t("widget_builder.widgetSetting")}
                  </div>
                }
              >
                {isPublisher ? (
                  <>
                    <Form form={form2} onValuesChange={onValuesChangeForm2}>
                      <Row gutter={[24, 24]}>
                        <Col xs={24} md={8}>
                          <p>
                            {t("widget_builder.showInWhichMediaText")}
                            <span style={{ color: "red" }}>*</span>
                          </p>
                          <SelectMedia
                            name={"media"}
                            size="large"
                            readonly={!isNewPage}
                            placeholder={t("link.selectMedia")}
                          />
                        </Col>

                        <Col xs={24} md={8} hidden>
                          <p>{t("widget_builder.selectCategory")}</p>

                          <SelectCategory
                            name="category"
                            mode="multiple"
                            websiteId={context?.web_store}
                            size="large"
                          />
                        </Col>
                      </Row>
                    </Form>
                  </>
                ) : (
                  false
                )}
                {isMerchant ? (
                  <Form form={form2} onValuesChange={onValuesChangeForm2}>
                    <Row>
                      <Col xs={24} md={12}>
                        <div className="inline-tag">
                          <SelectGroup
                            name={"group"}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                            label={t("widget.selectGroup")}
                            placeholder={t("general.select")}
                            webStoreId={context?.web_store}
                            type="multiple"
                          />
                          <Button
                            size="large"
                            onClick={() => setOpenAddTag(true)}
                          >
                            {t("widget.createTag")}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                ) : (
                  false
                )}

                <WidgetUiConfig onFinishMain={onFinishMain} />
              </Card>
            ) : (
              false
            )}

            {tabCurrent === SELECT_WIDGET_STEPS.STEP_GENERATED ? (
              <>
                {isPublisher ? (
                  <Card
                    title={
                      <div className="widget-builder__header">
                        <b>2</b>
                        {t("widget_builder.generated_code")}
                      </div>
                    }
                  >
                    <WidgetPreviewCode />
                  </Card>
                ) : (
                  false
                )}
                {isMerchant ? (
                  <Card className="widget-builder__step-success">
                    <LottieSuccess success />
                    <Typography.Title level={4}>
                      {isNewPage
                        ? t("widget_builder.successfullyCreated")
                        : t("widget_builder.successfullyUpdated")}
                    </Typography.Title>
                    <Divider />
                    <Button type="default" onClick={() => navigate("/widgets")}>
                      {t("widget_builder.showCreatedWidgets")}
                    </Button>
                  </Card>
                ) : (
                  false
                )}
              </>
            ) : (
              false
            )}

            <div className="widget-builder__footer">
              {context?.product_context?.length ? (
                <div
                  className="selected_area"
                  hidden={tabCurrent !== SELECT_WIDGET_STEPS.STEP_INFO}
                >
                  <Button
                    type="dashed"
                    size="large"
                    onClick={() => setShowSelectedProducts(true)}
                  >
                    <b className="badge-update">
                      {context?.product_context?.length}
                    </b>
                    <span>{t("product.selectedProduct")}</span>
                  </Button>
                </div>
              ) : (
                false
              )}
              <div className="button_area">
                <Button
                  size="large"
                  type="ghost"
                  hidden={
                    tabCurrent === SELECT_WIDGET_STEPS.STEP_INFO ||
                    tabCurrent === SELECT_WIDGET_STEPS.STEP_GENERATED
                  }
                  onClick={() => setTabCurrent(SELECT_WIDGET_STEPS.STEP_INFO)}
                  icon={<ArrowBack />}
                ></Button>

                <Button
                  size="large"
                  type="primary"
                  onClick={submitContinue}
                  disabled={!context.widget && !context.product_context?.length}
                  loading={widgetCodeLoading || widgetEditLoading}
                  hidden={tabCurrent === SELECT_WIDGET_STEPS.STEP_GENERATED}
                >
                  {isMerchant ? (
                    <>
                      {tabCurrent === SELECT_WIDGET_STEPS.STEP_CONFIG
                        ? isNewPage
                          ? t("widget.createWidget")
                          : t("general.saveChanges")
                        : t("general.submitContinue")}
                    </>
                  ) : (
                    <>
                      {tabCurrent === SELECT_WIDGET_STEPS.STEP_CONFIG
                        ? t("general.submitAndGetCode")
                        : t("general.submitContinue")}
                    </>
                  )}
                </Button>

                <Button
                  hidden={tabCurrent !== SELECT_WIDGET_STEPS.STEP_GENERATED}
                  size="large"
                  type="primary"
                  onClick={() => {
                    navigate("/widgets");
                  }}
                >
                  {t("general.return")}
                </Button>
              </div>
            </div>
          </div>
          {openAddTag ? (
            <ModalAddTag
              selectedWebId={context?.web_store}
              setModalOpen={() => setOpenAddTag(false)}
            />
          ) : (
            false
          )}
        </div>
      </MainContainer>
    </WidgetContext.Provider>
  );
};

export default WidgetMaker;
