import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Table from "../components/Table";
import React, { useEffect, useState } from "react";
// import TicketFilters from "./TicketFilters";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";

import {
  systemSettings,
  ticketsList,
} from "./../app/services/ticketingService";
import moment from "moment-jalaali";
import { SEVERITY, containedLargePrimaryBtn, isNumeric } from "./../app/helper";
import StatusBadge from "../components/StatusBadge";
import CustomizedButton from "../components/CustomizedButton";
import CustomAlert from "../components/CustomAlert";
import BasicPagination from "../components/Pagination";
import useListFiltersPrime from "../app/hooks/useListFiltersPrime";
import { DISPLAY_JALALI_DATE_TIME_FORMAT, statuses } from "../utils/constants";

const handleKeywordQuery = (keyword) =>
  !keyword
    ? ""
    : `${keyword && !isNumeric(keyword) ? `&keyword=${keyword}` : ""}${
        keyword && isNumeric(keyword) ? `&from_id=${keyword}` : ""
      }${keyword && isNumeric(keyword) ? `&to_id=${keyword}` : ""}`;

const handleStatusesQuery = (ticket_statuses) => {
  return !ticket_statuses
    ? ""
    : ticket_statuses === "0"
    ? `&ticket_statuses[]=0&ticket_statuses[]=1&ticket_statuses[]=3`
    : `&ticket_statuses[]=${ticket_statuses}`;
};

export default function Tickets(props) {
  const email = props?.options?.userEmail;
  const currentDepartment = props?.options?.currentDepartment;

  const { t } = useTranslation("support");
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { filter, setFilter } = useListFiltersPrime({ perPage: 10, page: 1 });
  const [update, setUpdate] = useState(false);
  const [tickets, setTickets] = useState({});
  const [settings, setSettings] = useState({});
  const listLength = tickets?.data?.length;
  const columns = [
    { value: t("ticketId_title") },
    { value: t("title") },
    { value: t("ticket_category") },
    { value: t("create_date") },
    { value: t("status") },
  ];

  useEffect(() => {
    const query = `${handleKeywordQuery(filter.keyword)}${handleStatusesQuery(
      filter.ticket_statuses
    )}&to_departments[]=${currentDepartment}${
      filter.from_date ? `&from_date=${filter.from_date}` : ""
    }${filter.to_date ? `&to_date=${filter.to_date}` : ""}${
      filter.page ? `&page=${filter.page}` : ""
    }${filter.perPage ? `&perPage=${filter.perPage}` : ""}`;
    setLoading(true);
    ticketsList(email, query.toString())
      .then((res) => setTickets(res.data))
      .finally(() => setLoading(false));
    systemSettings().then((res) => setSettings(res.data.data));
  }, [filter, update]);

  const onCellClick = (id) => {
    // setSelectedTicket(id);
    props?.router && props?.router(props?.options?.editRoute + id);
  };

  const doUpdate = () => setUpdate(!update);

  const handleClickNewTicket = () => {
    props?.router && props?.router(props?.options?.createRoute);
  };

  const handlePages = (e, value) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, page: value };
    });
  };

  const handlePageSize = (value) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, perPage: value, page: 1 };
    });
  };

  const rows = tickets.data?.length
    ? tickets.data?.map((item) => [
        {
          value: item.identifier,
          style: { width: "10%" },
          action: () => onCellClick(item.id),
        },
        {
          value: item.title,
          style: { width: "30%" },
          action: () => onCellClick(item.id),
        },
        {
          value: item.ticket_category?.name ?? "_",
          style: { width: "20%" },
          action: () => onCellClick(item.id),
        },
        {
          value: moment(item.created_at).format(
            DISPLAY_JALALI_DATE_TIME_FORMAT
          ),
          style: { width: "20%" },
          action: () => onCellClick(item.id),
        },
        // {
        //   value: t(importanceLevels.filter((n) => n.value === item.importance)[0]?.label),
        //   style: { width: "40%" },
        //   action: () => onCellClick(item.id),
        // },
        {
          value: (
            <StatusBadge
              backgroundColor={statuses[item.ticket_status].bgColor}
              fontColor={statuses[item.ticket_status].fontColor}
              data={t(statuses[item.ticket_status].label)}
            />
          ),
          style: { width: "20%" },
          action: () => onCellClick(item.id),
        },
      ])
    : [];

  return (
    <div>
      <div className={classes.head}>
        <CustomizedButton
          buttonProperties={containedLargePrimaryBtn}
          icon={<AddIcon />}
          isStartIcon={true}
          onClick={handleClickNewTicket}
        >
          {t("create_new_ticket")}
        </CustomizedButton>
      </div>
      {/* <TicketFilters filter={filter} setFilter={setFilter} /> */}
      <section>
        <Table columns={columns} rows={rows} loading={loading} />
        {!listLength && !loading ? (
          <div className={classes.alertContainer}>
            <CustomAlert
              severity={SEVERITY.INFO}
              message={t("no_ticket")}
              fontClass="caption1Reg"
            />
          </div>
        ) : null}
        <div className={classes.pagination}>
          <BasicPagination
            count={tickets?.totalPages}
            page={tickets?.currentPage}
            pageSize={filter.perPage}
            onChangeSize={handlePageSize}
            handleChange={handlePages}
          />
        </div>
      </section>
    </div>
  );
}

Tickets.propTypes = {
  router: PropTypes.any,
  options: PropTypes.any,
};

const useStyles = makeStyles((theme) => {
  return {
    head: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(4),
    },
    alertContainer: {
      padding: theme.spacing(4),
    },
    pagination: {
      marginTop: theme.spacing(4),
    },
  };
});
