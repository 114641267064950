import React from "react";
import MainWrap from "../components/MainWrap";
import Ticket from "./index";

const TicketCreate = (props) => {
  return (
    <React.StrictMode>
      <MainWrap>
        <Ticket {...props} />
      </MainWrap>
    </React.StrictMode>
  );
};

export default TicketCreate;
