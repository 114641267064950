import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

import { numberFormat } from "@/utils/helper";

import "./styles.less";

const TileNumber = (props) => {
  const { t } = useTranslation();
  const { title, number, color, align, type, row, toolTip, percent } = props;

  return (
    <Tooltip title={toolTip}>
      <div
        className={`tile-number align__${align || "right"} ${row ? "row" : ""}`}
      >
        <div className="total">
          <h3 className={`cp ${color || ""}`}>
            {!percent ? (
              <CountUp delay={2} end={number || 0} />
            ) : (
              numberFormat(number, null, percent ? "percent" : null)
            )}
          </h3>{" "}
          {type === "price" ? <b>{t("general.Toman")}</b> : ""}
        </div>
        <p className="title">{t(title)}</p>
      </div>
    </Tooltip>
  );
};

TileNumber.propTypes = {
  title: PropTypes.string,
  number: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.string,
  type: PropTypes.string,
  toolTip: PropTypes.string,
  row: PropTypes.bool,
  percent: PropTypes.bool,
};

export default TileNumber;
