import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

// import { baseAPI } from "@/app/services/baseApi";
import { ENDPOINTS } from "@/utils/constants/apiConstants";
import { clearObject } from "@/utils/utils";

import { baseAPI } from "../services/fetchers";

export const getPublisherReportAsync = createAsyncThunk(
  "order/getPublisherReportAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_PUBLISHER;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // let end2_ = ENDPOINTS.REPORT_GET_USER_NOTIFICATION;
      // const responseUserNotification = await baseAPI({
      //   endPoint: end2_,
      //   // body: data,
      // });
      let end3_ = ENDPOINTS.SHARED_PANEL_NOTIFICATION;
      const responsePanelNotification = await baseAPI({
        endPoint: end3_,
      });

      // RANDOM API FOR TEST
      // end_ = ENDPOINTS.REPORT_GET_PUBLISHER_RANDOM;
      // const response_ = await baseAPI({
      //   endPoint: end_,
      //   body: data,
      // });
      // The value we return becomes the `fulfilled` action payload
      return {
        ...response.data,
        dataRand: {},
        // notifications: responseUserNotification.data,
        notifications: responsePanelNotification.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getOrdersReportAsync = createAsyncThunk(
  "report/getOrdersReport",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_ORDERS_COUNT_AND_AMOUNT;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getCommisionOverviewAsync = createAsyncThunk(
  "report/getCommisionOverview",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_COMMISSION_OVERVIEW;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getClickImpressionAsync = createAsyncThunk(
  "report/getClickImpression",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_CLICK_IMPRESSION;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getCostOverviewAsync = createAsyncThunk(
  "report/getCostOverview",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_COST_OVERVIEW;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getConversionRateAsync = createAsyncThunk(
  "report/getConversionRateAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_CONVERSION_RATE;
      if (!_.isEmpty(data.publisher_ids) || !_.isEmpty(data.media_ids)) {
        end_ = ENDPOINTS.REPORT_GET_CONVERSION_RATE_BY_PUBLISHER;
      }

      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getOrderOverviewByCountAsync = createAsyncThunk(
  "report/getOrderOverviewByCountAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_ORDER_OVERVIEW_BY_COUNT;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getLinkConversionAsync = createAsyncThunk(
  "report/getLinkConversionAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_LINK_CONVERSION;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getTop10PublishersAsync = createAsyncThunk(
  "report/getTop10PublishersAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_TOP_10_PUBLISHERS;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getMerchantReportAsync = createAsyncThunk(
  "order/getMerchantReportAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_MERCHANT;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      let end3_ = ENDPOINTS.SHARED_PANEL_NOTIFICATION;
      const responsePanelNotification = await baseAPI({
        endPoint: end3_,
      });

      // RANDOM API FOR TEST
      // end_ = ENDPOINTS.REPORT_GET_PUBLISHER_RANDOM;
      // const response_ = await baseAPI({
      //   endPoint: end_,
      //   body: data,
      // });
      // The value we return becomes the `fulfilled` action payload
      return {
        ...response.data,
        dataRand: {},
        // notifications: responseUserNotification.data,
        notifications: responsePanelNotification.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getAsyncReportsAsync = createAsyncThunk(
  "order/getReportsAsync",
  async (data) => {
    try {
      const defaultBody = {
        page: 1,
        page_size: 10,
        is_pagination: true,
        sort_orientation: "1",
      };
      const data_ = clearObject(data);
      const body_ = Object.assign({}, defaultBody, data_);

      let end_ = ENDPOINTS.REPORT_GET_ASYNC_REPORTS;
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getAsyncReportsBackgroundAsync = createAsyncThunk(
  "order/getAsyncReportsBackgroundAsync",
  async (data) => {
    try {
      const defaultBody = {
        page: 1,
        page_size: 10,
        is_pagination: true,
        sort_orientation: "1",
      };
      const data_ = clearObject(data);
      const body_ = Object.assign({}, defaultBody, data_);

      let end_ = ENDPOINTS.REPORT_GET_ASYNC_REPORTS;
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const retryReportAsync = createAsyncThunk(
  "order/retryReportAsync",
  async (rId) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_ASYNC_RETRY.replace("{reportId}", rId);
      const response = await baseAPI({
        endPoint: end_,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getReportsMediaAsync = createAsyncThunk(
  "order/getReportsMediaAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_MEDIA_REPORTS;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getReportPublisherMediaExcelFastAsync = createAsyncThunk(
  "order/getReportPublisherMediaExcelFastAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_MEDIA_REPORTS_EXCEL;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getReportPublisherMediaExcelFullAsync = createAsyncThunk(
  "order/getReportPublisherMediaExcelFullAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_MEDIA_REPORTS_EXCEL_ASYNC;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getPublisherOverviewAsync = createAsyncThunk(
  "order/getPublisherOverviewAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_PUBLISHER_OVERVIEW;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getTop10CategoriesAsync = createAsyncThunk(
  "order/getTop10CategoriesAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_TOP_10_CATEGORIES;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getTop10MediasAsync = createAsyncThunk(
  "order/getTop10MediasAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_TOP_10_MEDIAS;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getTop10CategoriesByCountAsync = createAsyncThunk(
  "order/getTop10CategoriesByCountAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_TOP_10_CATEGORIES_BY_COUNT;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getTop10LinksAsync = createAsyncThunk(
  "order/getTop10LinksAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_TOP_10_LINKS;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getManagementReportAsync = createAsyncThunk(
  "order/getManagementReportAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_MANAGEMENT_REPORT;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getMerchantOverviewAsync = createAsyncThunk(
  "order/getMerchantOverviewAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_MERCHANT_OVERVIEW;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const reportGetClickSSPAsync = createAsyncThunk(
  "report/reportGetClickSSPAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_CLICK_SSP;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const reportGetClickRedirectSSPAsync = createAsyncThunk(
  "report/reportGetClickRedirectSSPAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_CLICK_REDIRECT_SSP;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const reportGetViewSSPAsync = createAsyncThunk(
  "report/reportGetViewSSPAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_VIEW_SSP;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const reportGetImpSSPAsync = createAsyncThunk(
  "report/reportGetImpSSPAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.REPORT_GET_IMPRESSION_SSP;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      return {
        ...response.data,
      };
    } catch (e) {
      return e.response?.data;
    }
  }
);

const slice = createSlice({
  name: "report",
  initialState: {
    reportPublisher: null,
    reportPublisherLoading: false,
    reportPublisherError: null,

    reportMerchant: null,
    reportMerchantLoading: false,
    reportMerchantError: null,

    reportsData: null,
    reportsDataLoading: false,
    reportsDataError: null,

    reportsMediaData: null,
    reportsMediaDataLoading: false,
    reportsMediaDataError: null,
    adminOrdersReport: null,
    adminOrdersReportLoading: false,
    adminOrdersReportError: null,

    adminCommisionOverview: null,
    adminCommisionOverviewLoading: false,
    adminCommisionOverviewError: null,

    adminClickImpression: null,
    adminClickImpressionLoading: false,
    adminClickImpressionError: null,

    adminCostOverview: null,
    adminCostOverviewLoading: false,
    adminCostOverviewError: null,

    adminConversionRate: null,
    adminConversionRateLoading: false,
    adminConversionRateError: null,

    adminOrderOverviewByCount: null,
    adminOrderOverviewByCountLoading: false,
    adminOrderOverviewByCountError: null,

    adminLinkConversion: null,
    adminLinkConversionLoading: false,
    adminLinkConversionError: null,

    adminTopPublishers: null,
    adminTopPublishersLoading: false,
    adminTopPublishersError: null,

    publisherOverview: null,
    publisherOverviewLoading: false,
    publisherOverviewError: null,

    top10Categories: null,
    top10CategoriesLoading: false,
    top10CategoriesError: null,

    top10CategoriesCount: null,
    top10CategoriesCountLoading: false,
    top10CategoriesCountError: null,

    top10Medias: null,
    top10MediasLoading: false,
    top10MediasError: null,

    top10Links: null,
    top10LinksLoading: false,
    top10LinksError: null,

    reportManagement: null,
    reportManagementLoading: false,
    reportManagementError: null,

    reportMerchantOverview: null,
    reportMerchantOverviewLoading: false,
    reportMerchantOverviewError: null,

    reportClickSSP: null,
    reportClickSSPLoading: false,
    reportClickSSPError: null,

    reportClickRedirectSSP: null,
    reportClickSSPRedirectLoading: false,
    reportClickSSPRedirectError: null,

    reportViewSSP: null,
    reportViewSSPLoading: false,
    reportViewSSPError: null,

    reportImpSSP: null,
    reportImpSSPLoading: false,
    reportImpSSPError: null,
  },
  reducers: {
    clearState: (state) => {
      state.reportPublisher = false;
      state.reportPublisherError = null;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(getPublisherReportAsync.pending, (state) => {
      state.reportPublisherLoading = true;
      state.reportPublisherError = null;
    });
    builder.addCase(getPublisherReportAsync.fulfilled, (state, action) => {
      state.reportPublisherLoading = false;
      if (action.payload.success) {
        state.reportPublisher = action.payload;
      }
    });
    builder.addCase(getPublisherReportAsync.rejected, (state, action) => {
      state.reportPublisherLoading = false;
      state.reportPublisherError = null;
    });

    builder.addCase(getMerchantReportAsync.pending, (state) => {
      state.reportMerchantLoading = true;
      state.reportMerchantError = null;
    });
    builder.addCase(getMerchantReportAsync.fulfilled, (state, action) => {
      state.reportMerchantLoading = false;
      if (action.payload.success) {
        state.reportMerchant = action.payload;
      }
    });
    builder.addCase(getMerchantReportAsync.rejected, (state, action) => {
      state.reportMerchantLoading = false;
      state.reportMerchantError = null;
    });

    builder.addCase(getAsyncReportsAsync.pending, (state) => {
      state.reportsDataLoading = true;
      state.reportsDataError = null;
    });
    builder.addCase(getAsyncReportsAsync.fulfilled, (state, action) => {
      state.reportsDataLoading = false;
      if (action.payload.success) {
        state.reportsData = action.payload;
      }
    });
    builder.addCase(getAsyncReportsAsync.rejected, (state, action) => {
      state.reportsDataLoading = false;
      state.reportsDataError = null;
    });
    builder.addCase(
      getAsyncReportsBackgroundAsync.fulfilled,
      (state, action) => {
        if (action.payload.success) {
          state.reportsData = action.payload;
        }
      }
    );
    builder.addCase(getReportsMediaAsync.pending, (state, action) => {
      state.reportsMediaDataLoading = true;
    });
    builder.addCase(getReportsMediaAsync.rejected, (state, action) => {
      state.reportsMediaDataLoading = false;
      state.reportsMediaDataError = null;
    });
    builder.addCase(getReportsMediaAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.reportsMediaData = action.payload;
      }
      state.reportsMediaDataLoading = false;
      state.reportsMediaDataError = null;
    });
    builder.addCase(getOrdersReportAsync.pending, (state) => {
      state.adminOrdersReportLoading = true;
      state.adminOrdersReportError = null;
    });
    builder.addCase(getOrdersReportAsync.fulfilled, (state, action) => {
      state.adminOrdersReportLoading = false;
      if (action.payload.success) {
        state.adminOrdersReport = action.payload;
      }
    });
    builder.addCase(getOrdersReportAsync.rejected, (state, action) => {
      state.adminOrdersReportLoading = false;
      state.adminOrdersReportError = null;
    });

    builder.addCase(getCommisionOverviewAsync.pending, (state) => {
      state.adminCommisionOverviewLoading = true;
      state.adminCommisionOverviewError = null;
    });
    builder.addCase(getCommisionOverviewAsync.fulfilled, (state, action) => {
      state.adminCommisionOverviewLoading = false;
      if (action.payload.success) {
        state.adminCommisionOverview = action.payload;
      }
    });
    builder.addCase(getCommisionOverviewAsync.rejected, (state, action) => {
      state.adminCommisionOverviewLoading = false;
      state.adminCommisionOverviewError = null;
    });

    builder.addCase(getClickImpressionAsync.pending, (state) => {
      state.adminClickImpressionLoading = true;
      state.adminClickImpressionError = null;
    });
    builder.addCase(getClickImpressionAsync.fulfilled, (state, action) => {
      state.adminClickImpressionLoading = false;
      if (action.payload.success) {
        state.adminClickImpression = action.payload;
      }
    });
    builder.addCase(getClickImpressionAsync.rejected, (state, action) => {
      state.adminClickImpressionLoading = false;
      state.adminClickImpressionError = null;
    });

    builder.addCase(getCostOverviewAsync.pending, (state) => {
      state.adminCostOverviewLoading = true;
      state.adminCostOverviewError = null;
    });
    builder.addCase(getCostOverviewAsync.fulfilled, (state, action) => {
      state.adminCostOverviewLoading = false;
      if (action.payload.success) {
        state.adminCostOverview = action.payload;
      }
    });
    builder.addCase(getCostOverviewAsync.rejected, (state, action) => {
      state.adminClickImpressionLoading = false;
      state.adminCommisionOverviewError = null;
    });

    builder.addCase(getConversionRateAsync.pending, (state) => {
      state.adminConversionRateLoading = true;
      state.adminConversionRateError = null;
    });
    builder.addCase(getConversionRateAsync.fulfilled, (state, action) => {
      state.adminConversionRateLoading = false;
      if (action.payload.success) {
        state.adminConversionRate = action.payload;
      }
    });
    builder.addCase(getConversionRateAsync.rejected, (state, action) => {
      state.adminConversionRateLoading = false;
      state.adminConversionRateError = null;
    });

    builder.addCase(getOrderOverviewByCountAsync.pending, (state) => {
      state.adminOrderOverviewByCountLoading = true;
      state.adminOrderOverviewByCountError = null;
    });
    builder.addCase(getOrderOverviewByCountAsync.fulfilled, (state, action) => {
      state.adminOrderOverviewByCountLoading = false;
      if (action.payload.success) {
        state.adminOrderOverviewByCount = action.payload;
      }
    });
    builder.addCase(getOrderOverviewByCountAsync.rejected, (state, action) => {
      state.adminOrderOverviewByCountLoading = false;
      state.adminConversionRateError = null;
    });

    builder.addCase(getLinkConversionAsync.pending, (state) => {
      state.adminLinkConversion = true;
      state.adminLinkConversionError = null;
    });
    builder.addCase(getLinkConversionAsync.fulfilled, (state, action) => {
      state.adminLinkConversionLoading = false;
      if (action.payload.success) {
        state.adminLinkConversion = action.payload;
      }
    });
    builder.addCase(getLinkConversionAsync.rejected, (state, action) => {
      state.adminLinkConversionLoading = false;
      state.adminLinkConversionError = null;
    });

    builder.addCase(getTop10PublishersAsync.pending, (state) => {
      state.adminTopPublishersLoading = true;
      state.adminTopPublishersError = null;
    });
    builder.addCase(getTop10PublishersAsync.fulfilled, (state, action) => {
      state.adminTopPublishersLoading = false;
      if (action.payload.success) {
        state.adminTopPublishers = action.payload;
      }
    });
    builder.addCase(getTop10PublishersAsync.rejected, (state, action) => {
      state.adminTopPublishersLoading = false;
      state.adminTopPublishersError = null;
    });

    builder.addCase(getPublisherOverviewAsync.pending, (state) => {
      state.publisherOverviewLoading = true;
      state.publisherOverviewError = null;
    });
    builder.addCase(getPublisherOverviewAsync.fulfilled, (state, action) => {
      state.publisherOverviewLoading = false;
      if (action.payload.success) {
        state.publisherOverview = action.payload;
      }
    });
    builder.addCase(getPublisherOverviewAsync.rejected, (state, action) => {
      state.publisherOverviewLoading = false;
      state.publisherOverviewError = null;
    });

    builder.addCase(getTop10CategoriesAsync.pending, (state) => {
      state.top10CategoriesLoading = true;
      state.top10CategoriesError = null;
    });
    builder.addCase(getTop10CategoriesAsync.fulfilled, (state, action) => {
      state.top10CategoriesLoading = false;
      if (action.payload.success) {
        state.top10Categories = action.payload;
      }
    });
    builder.addCase(getTop10CategoriesAsync.rejected, (state, action) => {
      state.top10CategoriesLoading = false;
      state.top10CategoriesError = null;
    });

    builder.addCase(getTop10CategoriesByCountAsync.pending, (state) => {
      state.top10CategoriesCountLoading = true;
      state.top10CategoriesCountError = null;
    });
    builder.addCase(
      getTop10CategoriesByCountAsync.fulfilled,
      (state, action) => {
        state.top10CategoriesCountLoading = false;
        if (action.payload.success) {
          state.top10CategoriesCount = action.payload;
        }
      }
    );
    builder.addCase(
      getTop10CategoriesByCountAsync.rejected,
      (state, action) => {
        state.top10CategoriesCountLoading = false;
        state.top10CategoriesCountError = null;
      }
    );

    builder.addCase(getTop10MediasAsync.pending, (state) => {
      state.top10MediasLoading = true;
      state.top10MediasError = null;
    });
    builder.addCase(getTop10MediasAsync.fulfilled, (state, action) => {
      state.top10MediasLoading = false;
      if (action.payload.success) {
        state.top10Medias = action.payload;
      }
    });
    builder.addCase(getTop10MediasAsync.rejected, (state, action) => {
      state.top10MediasLoading = false;
      state.top10MediasError = null;
    });

    builder.addCase(getManagementReportAsync.pending, (state) => {
      state.reportManagementLoading = true;
      state.reportManagementError = null;
    });
    builder.addCase(getManagementReportAsync.fulfilled, (state, action) => {
      state.reportManagementLoading = false;
      if (action.payload.success) {
        state.reportManagement = action.payload;
      }
    });
    builder.addCase(getManagementReportAsync.rejected, (state, action) => {
      state.reportManagementLoading = false;
      state.reportManagementError = null;
    });

    builder.addCase(getTop10LinksAsync.pending, (state) => {
      state.top10LinksLoading = true;
      state.top10LinksError = null;
    });
    builder.addCase(getTop10LinksAsync.fulfilled, (state, action) => {
      state.top10LinksLoading = false;
      if (action.payload.success) {
        state.top10Links = action.payload;
      }
    });
    builder.addCase(getTop10LinksAsync.rejected, (state, action) => {
      state.top10LinksLoading = false;
      state.top10LinksError = null;
    });

    builder.addCase(getMerchantOverviewAsync.pending, (state) => {
      state.reportMerchantOverviewLoading = true;
      state.reportMerchantOverviewError = null;
    });
    builder.addCase(getMerchantOverviewAsync.fulfilled, (state, action) => {
      state.reportMerchantOverviewLoading = false;
      if (action.payload.success) {
        state.reportMerchantOverview = action.payload;
      }
    });
    builder.addCase(getMerchantOverviewAsync.rejected, (state, action) => {
      state.reportMerchantOverviewLoading = false;
      state.reportMerchantOverviewError = null;
    });

    builder.addCase(reportGetClickSSPAsync.pending, (state) => {
      state.reportClickSSPLoading = true;
      state.reportClickSSPError = null;
    });
    builder.addCase(reportGetClickSSPAsync.fulfilled, (state, action) => {
      state.reportClickSSPLoading = false;
      if (action.payload.success) {
        state.reportClickSSP = action.payload;
      }
    });
    builder.addCase(reportGetClickSSPAsync.rejected, (state, action) => {
      state.reportClickSSPLoading = false;
      state.reportClickSSPError = null;
    });

    // redirect
    builder.addCase(reportGetClickRedirectSSPAsync.pending, (state) => {
      state.reportClickSSPRedirectLoading = true;
      state.reportClickSSPRedirectError = null;
    });
    builder.addCase(
      reportGetClickRedirectSSPAsync.fulfilled,
      (state, action) => {
        state.reportClickSSPRedirectLoading = false;
        if (action.payload.success) {
          state.reportClickRedirectSSP = action.payload;
        }
      }
    );
    builder.addCase(
      reportGetClickRedirectSSPAsync.rejected,
      (state, action) => {
        state.reportClickSSPRedirectLoading = false;
        state.reportClickSSPRedirectError = null;
      }
    );

    builder.addCase(reportGetViewSSPAsync.pending, (state) => {
      state.reportViewSSP = true;
      state.reportViewSSPError = null;
    });
    builder.addCase(reportGetViewSSPAsync.fulfilled, (state, action) => {
      state.reportViewSSPLoading = false;
      if (action.payload.success) {
        state.reportViewSSP = action.payload;
      }
    });
    builder.addCase(reportGetViewSSPAsync.rejected, (state, action) => {
      state.reportViewSSPLoading = false;
      state.reportViewSSPError = null;
    });

    builder.addCase(reportGetImpSSPAsync.pending, (state) => {
      state.reportImpSSPLoading = true;
      state.reportImpSSPError = null;
    });
    builder.addCase(reportGetImpSSPAsync.fulfilled, (state, action) => {
      state.reportImpSSPLoading = false;
      if (action.payload.success) {
        state.reportImpSSP = action.payload;
      }
    });
    builder.addCase(reportGetImpSSPAsync.rejected, (state, action) => {
      state.reportImpSSPLoading = false;
      state.reportImpSSP = null;
    });
  },
});

export const { clearState } = slice.actions;

export default slice.reducer;

export const getPublisherReport = (data) => async (dispatch) => {
  return dispatch(getPublisherReportAsync(data));
};

export const getMerchantReport = (data) => async (dispatch) => {
  return dispatch(getMerchantReportAsync(data));
};

export const getAsyncReports = (data) => async (dispatch) => {
  return dispatch(getAsyncReportsAsync(data));
};

export const getAsyncReportsBackground = (data) => async (dispatch) => {
  return dispatch(getAsyncReportsBackgroundAsync(data));
};

export const retryReport = (data) => async (dispatch) => {
  return dispatch(retryReportAsync(data));
};

export const getReportsMedia = (data) => async (dispatch) => {
  return dispatch(getReportsMediaAsync(data));
};

export const getReportPublisherMediaExcelFast = (data) => async (dispatch) => {
  return dispatch(getReportPublisherMediaExcelFastAsync(data));
};

export const getReportPublisherMediaExcelFull = (data) => async (dispatch) => {
  return dispatch(getReportPublisherMediaExcelFullAsync(data));
};
export const getOrdersReport = (data) => async (dispatch) => {
  return dispatch(getOrdersReportAsync(data));
};

export const getCommisionOverview = (data) => async (dispatch) => {
  return dispatch(getCommisionOverviewAsync(data));
};

export const getClickImpression = (data) => async (dispatch) => {
  return dispatch(getClickImpressionAsync(data));
};

export const getCostOverview = (data) => async (dispatch) => {
  return dispatch(getCostOverviewAsync(data));
};

export const getConversionRate = (data) => async (dispatch) => {
  return dispatch(getConversionRateAsync(data));
};

export const getOrderOverviewByCount = (data) => async (dispatch) => {
  return dispatch(getOrderOverviewByCountAsync(data));
};

export const getLinkConversion = (data) => async (dispatch) => {
  return dispatch(getLinkConversionAsync(data));
};

export const getTop10Publishers = (data) => async (dispatch) => {
  return dispatch(getTop10PublishersAsync(data));
};

export const getPublisherOverview = (data) => async (dispatch) => {
  return dispatch(getPublisherOverviewAsync(data));
};

export const getTop10Categories = (data) => async (dispatch) => {
  return dispatch(getTop10CategoriesAsync(data));
};

export const getTop10CategoriesByCount = (data) => async (dispatch) => {
  return dispatch(getTop10CategoriesByCountAsync(data));
};

export const getTop10Medias = (data) => async (dispatch) => {
  return dispatch(getTop10MediasAsync(data));
};

export const getTop10Links = (data) => async (dispatch) => {
  return dispatch(getTop10LinksAsync(data));
};

export const getManagementReport = (data) => async (dispatch) => {
  return dispatch(getManagementReportAsync(data));
};

export const getMerchantOverview = (data) => async (dispatch) => {
  return dispatch(getMerchantOverviewAsync(data));
};

export const reportGetClickSSP = (data) => async (dispatch) => {
  return dispatch(reportGetClickSSPAsync(data));
};

export const reportGetClickRedirectSSP = (data) => async (dispatch) => {
  return dispatch(reportGetClickRedirectSSPAsync(data));
};

export const reportGetViewSSP = (data) => async (dispatch) => {
  return dispatch(reportGetViewSSPAsync(data));
};

export const reportGetImpSSP = (data) => async (dispatch) => {
  return dispatch(reportGetImpSSPAsync(data));
};
