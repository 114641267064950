import {
  DesktopOutlined, // MenuFoldOutlined,
  // MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, matchRoutes, useLocation, useNavigate } from "react-router-dom";

import { getPrivateRoutes, parentsIcon } from "@/app/routes";
import { getPrivateRoutes as getPrivateRoutesSSP } from "@/app/routes-ssp";
import LogoSSP from "@/assets/images/logo-sidebar-ssp.svg";
import Logo from "@/assets/images/logo-white.svg";
import { USER_TYPE } from "@/utils/constants/apiConstants";
import { useAccount } from "@/utils/hooks/useAuth";
import useConfig from "@/utils/hooks/useConfig";
import { useDeviceDetector } from "@/utils/hooks/useDeviceDetector";

import ButtonCollapse from "../extra/ButtonCollapse";
import "./Sidebar.less";

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = (props) => {
  const { collapsed, setCollapsed } = props;
  const isWizard = props.mode === "wizard";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, role, roleId } = useAccount();
  const isMobile = useDeviceDetector();
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const locationPath = location?.pathname;
  const [selectedMenu, setSelectedMenu] = useState([]);
  const { isSSP } = useConfig();

  const onOpenChange = (e) => {
    if (openKeys.find((i) => i === e.key)) setOpenKeys([]);
    else setOpenKeys([e.key]);
  };

  const onCollapse = () => {
    if (isWizard) return;
    props.setCollapsed((old) => !old);
  };

  useEffect(() => {
    !collapsed && isMobile && setCollapsed(true);
  }, [isMobile]);

  const privateRoutes = useMemo(() => {
    let routes = getPrivateRoutes(role).filter((item) => item.menu !== false);
    if (isSSP) {
      routes = getPrivateRoutesSSP(role).filter((item) => item.menu !== false);
    } else {
      routes = getPrivateRoutes(role).filter((item) => item.menu !== false);
    }
    // filter with privileges
    routes = routes.filter(
      (item) =>
        !item?.privileges ||
        item?.privileges?.find((i) =>
          user?.privileges?.find((j) => j.privilege_id === i)
        )
    );

    const refineRoute = [];

    routes.forEach((item) => {
      const isExist =
        refineRoute.findIndex((item1) => item1.parent === item.parent) >= 0;

      if (roleId !== USER_TYPE.SUPER_ADMIN && roleId !== USER_TYPE.ADMIN) {
        refineRoute.push({ ...item, parent: undefined });
        return;
      }
      if (item.parent && !isExist) {
        const children = routes.filter((item2) => item.parent === item2.parent);
        const entity = {
          parent: item.parent,
          title: t("page." + item.parent),
          key: item.parent,
          icon: parentsIcon[item.parent],
          children: children,
        };
        if (children.length > 1) {
          refineRoute.push(entity);
        } else {
          delete children[0].parent;
          refineRoute.push(children[0]);
        }
      } else if (!item.parent) {
        refineRoute.push(item);
      }
    });

    return refineRoute;
  }, [role]);

  const getRouteKey = () => {
    const routes = getPrivateRoutes(role);

    const currentRouteObject = matchRoutes(routes, location);
    const cp = _.get(currentRouteObject, "[0].route.parent", "");

    try {
      let index;
      privateRoutes.find((item, i) => {
        if (!item.parent) {
          if (item.path === locationPath) {
            index = [i];
            return;
          } else if (cp === item.path.replace("/", "")) {
            index = [i];
            return;
          }
        } else if (item.parent) {
          item?.children?.find((sub, k) => {
            if (sub.path === locationPath) {
              index = [i, k];
              return;
            }
          });
        }
      });

      let routeKey;
      if (index?.length === 1) {
        routeKey = ["menu_" + index];
      } else if (index?.length === 2) {
        routeKey = ["sub_" + index[0] + "_" + index[1]];
        setOpenKeys(["sub_" + index[0]]);
      } else routeKey = selectedMenu;
      setSelectedMenu(routeKey);
    } catch (e) {
      const cashing = "";
    }
  };

  useEffect(() => {
    if (privateRoutes) {
      getRouteKey();
    }
  }, [locationPath]);

  const handleCollapseMenu = () => {
    !collapsed && setCollapsed(true);

    let key;
    if (openKeys[0]?.includes("sub")) {
      key = openKeys[0].replace("sub", "menu");
      setOpenKeys([key]);
    }
  };

  console.log("privateRoutes", privateRoutes);

  return (
    <Sider
      width={isMobile ? 350 : 280}
      collapsed={collapsed}
      onCollapse={onCollapse}
      className="sidebar"
      style={{ zIndex: "2000" }}
    >
      {/* <div
        className={`menu_logo ${collapsed ? "menu_logo--collapsed" : ""}`}
        onClick={onCollapse}
      >
        {collapsed ? (
          <MenuFoldOutlined className={"collapse_icon"} />
        ) : (
          <MenuUnfoldOutlined className={"collapse_icon"} />
        )}
        {!collapsed ? <img src={Logo} className={"mini-logo"} /> : ""}
      </div> */}
      <div
        className={`menu_logo ${collapsed ? "menu_logo--collapsed" : ""}`}
        // onClick={onCollapse}
      >
        {!collapsed ? (
          <>
            <img src={isSSP ? LogoSSP : Logo} />
            <ButtonCollapse
              setCollapsed={props.setCollapsed}
              collapsed={props.collapsed}
            />
          </>
        ) : (
          // <img src={isSSP ? LogoSSPMin : LogoMin} className={"mini-logo"} />
          <ButtonCollapse
            setCollapsed={props.setCollapsed}
            collapsed={props.collapsed}
          />
        )}
      </div>
      <Menu
        disabled={isWizard}
        className="sidebar_menu"
        // defaultSelectedKeys={"menu_0"}
        defaultOpenKeys={selectedMenu}
        selectedKeys={selectedMenu}
        mode="inline"
        openKeys={openKeys}
      >
        {privateRoutes.map((item, i) => {
          return !item.parent ? (
            <Menu.Item
              key={"menu_" + i}
              icon={item.icon || <DesktopOutlined />}
              onClick={isMobile ? handleCollapseMenu : () => {}}
            >
              <Link to={item?.path}>{t(`page.${item.title}`)}</Link>
            </Menu.Item>
          ) : (
            <SubMenu
              onTitleClick={onOpenChange}
              key={"sub_" + i}
              icon={item.icon}
              title={t("page." + item.key)}
              onClick={isMobile ? handleCollapseMenu : () => {}}
            >
              {item.children.map((itemChild, k) => (
                <Menu.Item
                  key={"sub_" + i + "_" + k}
                  onClick={() => navigate(itemChild.path)}
                  icon={<span className={"icon-elipse"} />}
                  className="submenu-item"
                >
                  <Link to={itemChild?.path}>
                    {t(`page.${itemChild.title}`)}
                  </Link>
                </Menu.Item>
              ))}
            </SubMenu>
          );
        })}

        {/* <Menu.Item
          key="15"
          onClick={() => navigate("kits")}
          icon={<FileOutlined />}
        >
          کیت
        </Menu.Item> */}
        {/* <Menu.Item
          key="16"
          onClick={() => navigate("404")}
          icon={<FileOutlined />}
        >
          سایر
        </Menu.Item> */}
      </Menu>
    </Sider>
  );
};

Sidebar.propTypes = {
  mode: PropTypes.string,
  setCollapsed: PropTypes.func,
  collapsed: PropTypes.bool,
};

export default Sidebar;
