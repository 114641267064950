import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ENDPOINTS } from "@/utils/constants/apiConstants";
import { converterFlow } from "@/utils/utils";

import { baseAPI } from "../services/fetchers";

const formulasGetListAsync = createAsyncThunk(
  "calculation/getListsFormulas",
  async (data) => {
    try {
      const end_ = ENDPOINTS.CALCULATION_LIST_FORMULAS;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

const addFormulaAsync = createAsyncThunk(
  "calculation/addFormulaAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.CALCULATION_ADD_FORMULA;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

const editFormulaAsync = createAsyncThunk(
  "calculation/editFormulaAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.CALCULATION_EDIT_FORMULA;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

const getFormulaAsync = createAsyncThunk(
  "calculation/getFormulaAsync",
  async (id) => {
    try {
      const end_ = ENDPOINTS.CALCULATION_GET_FORMULA.replace("{formulaId}", id);
      const response = await baseAPI({
        endPoint: end_,
      });
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

const getFlowsAsync = createAsyncThunk(
  "calculation/getFlowsAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.CALCULATION_GET_FLOWS;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

const getFormulaVariablesAsync = createAsyncThunk(
  "calculation/getFormulaVariablesAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.CALCULATION_GET_VARIABLES_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const calculationSlice = createSlice({
  name: "calculation",
  initialState: {
    formulas: [],
    formulasError: null,
    formulasLoading: false,

    formula: {},
    formulaLoading: false,
    formulaError: null,

    flows: {},
    flowsLoading: false,
    flowsError: null,

    flow: {},
    flowLoading: false,
    flowError: null,

    variables: {},

    flowEntity: {},
  },
  reducers: {
    updateFlow: (state, { payload }) => {
      state.flowEntity = converterFlow(payload, true);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(formulasGetListAsync.pending, (state) => {
      state.formulasLoading = true;
      state.formulas = null;
      state.formulasError = null;
    });
    builder.addCase(formulasGetListAsync.fulfilled, (state, action) => {
      state.formulasLoading = false;
      if (action.payload.success) {
        state.formulas = action.payload;
      } else {
        state.formulasError = action.payload;
      }
    });
    builder.addCase(formulasGetListAsync.rejected, (state, action) => {
      state.formulasLoading = false;
      state.formulasError = action.payload;
    });

    builder.addCase(getFormulaAsync.pending, (state) => {
      state.formulaLoading = true;
      state.formula = null;
      state.formulaError = null;
    });
    builder.addCase(getFormulaAsync.fulfilled, (state, action) => {
      state.formulaLoading = false;
      if (action.payload.success) {
        state.formula = action.payload;
      } else {
        state.formulaError = action.payload;
      }
    });
    builder.addCase(getFormulaAsync.rejected, (state) => {
      state.formulaLoading = false;
      state.formulaError = null;
    });

    builder.addCase(getFlowsAsync.pending, (state) => {
      state.flowsLoading = true;
      state.flows = null;
      state.flowsError = null;
    });
    builder.addCase(getFlowsAsync.fulfilled, (state, action) => {
      state.flowsLoading = false;
      if (action.payload.success) {
        state.flows = action.payload;
      } else {
        state.flowsError = action.payload;
      }
    });
    builder.addCase(getFlowsAsync.rejected, (state) => {
      state.flowsLoading = false;
      state.flowsError = null;
    });
    builder.addCase(getFormulaVariablesAsync.fulfilled, (state, action) => {
      state.flowsLoading = false;
      if (action.payload.success) {
        state.variables = action.payload;
      } else {
        state.flowsError = action.payload;
      }
    });
  },
});

export const { updateFlow } = calculationSlice.actions;

export const formulasGetList = (data) => async (dispatch) => {
  return dispatch(formulasGetListAsync(data));
};

export const addFormula = (data) => async (dispatch) => {
  return dispatch(addFormulaAsync(data));
};

export const editFormula = (data) => async (dispatch) => {
  return dispatch(editFormulaAsync(data));
};

export const getFormula = (data) => async (dispatch) => {
  return dispatch(getFormulaAsync(data));
};

export const getFlows = (data) => async (dispatch) => {
  return dispatch(getFlowsAsync(data));
};

export const addFlow = (data) => async (dispatch) => {
  return dispatch(getFlowsAsync(data));
};

export const editFlow = (data) => async (dispatch) => {
  return dispatch(getFlowsAsync(data));
};

export const getFormulaVariables = (data) => async (dispatch) => {
  return dispatch(getFormulaVariablesAsync(data));
};

export default calculationSlice.reducer;
