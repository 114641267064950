import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  StylesProvider,
  ThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";
import { ApiProvider } from "@reduxjs/toolkit/dist/query/react";
import { SnackbarProvider } from "notistack";
import "./../app/i18n";
import { theme } from "./../app/theme";
import { create } from "jss";
import { rootApiSlice } from "./../app/rootApiSlice";
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
import rtl from "jss-rtl";
import PropTypes from "prop-types";

const MainWrap = (props) => {
  return (
    <React.StrictMode>
      <ApiProvider api={rootApiSlice}>
        <ThemeProvider theme={theme}>
          <StylesProvider jss={jss}>
            <CssBaseline />
            <SnackbarProvider maxSnack={2}>{props.children}</SnackbarProvider>
          </StylesProvider>
        </ThemeProvider>
      </ApiProvider>
    </React.StrictMode>
  );
};

MainWrap.propTypes = {
  children: PropTypes.any,
};

export default MainWrap;
