import { Card } from "antd";
import { useTranslation } from "react-i18next";

import { AuctionImage, WalletImage } from "@/assets/images/ssp";

import WeekChart from "../charts/WeekChart";
import "./WidgetIncome.less";

const WidgetIncome = () => {
  const { t } = useTranslation("ssp");

  return (
    <Card className="widget-income">
      <div className="widget_title">
        <div className="percent">0%</div>
        <div className="wallet-image">
          <img src={WalletImage} />
        </div>
      </div>
      <h4 className="income-title">{t("dashboard.income")}</h4>
      <div className="income-cost">
        0 <i>{t("dashboard.toman")}</i>
      </div>
      <div className="divider"></div>
      <div className="chart-compare">
        <div className="title">{t("dashboard.compare_last_week")}</div>
        <WeekChart />
      </div>
      <div className="average-in-come">
        <div>
          <div className="title">{t("dashboard.average_income")}</div>
          <div className="price">0 {t("dashboard.toman")}</div>
        </div>

        <div>
          <img src={AuctionImage} />
        </div>
      </div>
    </Card>
  );
};

export default WidgetIncome;
