import { createMuiTheme } from "@material-ui/core/styles";
// import YekanWoff from "assets/fonts/woff/iranyekanwebregularfanum.woff";
import { darken, lighten } from "@material-ui/core/styles";

// const yekan = {
//   fontFamily: "iranyekan",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 400,
//   src: `
//     local('iranyekan'),
//     url(${YekanWoff}) format('woff')
//   `,
//   unicodeRange:
//     "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
// };
const breakpointValues = {
  sm: 1024,
  md: 1440,
  lg: 1920,
};
const defaultTheme = createMuiTheme();
export const theme = createMuiTheme({
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      ...breakpointValues,
    },
  },

  palette: {
    type: "light",

    text: {
      hint: "rgba(52, 73, 82, 0.5)",
      disabled: "rgba(52, 73, 82, 0.5)",
      primary: lighten("#000000", 0.13),
      secondary: lighten("#000000", 0.46),
      // disabled: lighten("#000000", 0.62),
      // hint: lighten("#000000", 0.62),
    },
    common: {
      white: "#FFFFFF",
      gray1: "#878787",
      gray2: "#C4C4C4",
      gray3: "#EAEAEA",
      gray4: "#F9F9F9",
      gray5: "#BDBDBD",
      gray6: "#E0E0E0",
      black: "#000000",
    },
    primary: {
      main: "#79797c",
      light: "#BDE78F",
      dark: "#346309",
      //contrastText: "rgba(0, 0, 0, 0.87)",
      contrastText: "#FFFFFF",
      deepLight: lighten("#64B20D", 0.98),
      text: darken("#64B20D", 0.6),
      background: lighten("#64B20D", 0.9),
      hover: "#71C710",
    },

    secondary: {
      main: "#5C717A",
      light: "#7796A4",
      lighter: "#B5E0FF",
      light3: lighten("#5C717A", 0.98),
      dark0: "#486275",
      dark1: "#344952",
      contrastText: "#FFFFFF",
      text: darken("#5C717A", 0.6),
      background: lighten("#5C717A", 0.9),
    },

    warning: {
      main: "#FF9800",
      light: "#FFB74D",
      dark: "#F57C00",
      contrastText: "rgba(0, 0, 0, 0.87)",
      text: darken("#FF9800", 0.6),
      background: lighten("#FF9800", 0.9),
    },

    info: {
      main: "#2196F3",
      light: "#64B5F6",
      dark: "#1976D2",
      contrastText: "#FFFFFF",
      text: darken("#2196F3", 0.6),
      background: lighten("#2196F3", 0.9),
    },

    success: {
      main: "#4CAF50",
      light: "#81C784",
      dark: "#388E3C",
      contrastText: "rgba(0, 0, 0, 0.87)",
      text: darken("#4CAF50", 0.6),
      background: lighten("#4CAF50", 0.9),
    },

    error: {
      main: "#F44336",
      light: "#E57373",
      dark: "#D32F2F",
      contrastText: "#FFFFFF",
      text: darken("#F44336", 0.6),
      background: lighten("#F44336", 0.9),
    },
    badge: {
      red: "#EF4056",
    },
    logoColor: {
      primary: "#79D70F",
      secondary: "#486275",
    },

    background: {
      default: "#f5f5f5",
      paper: "#FFFFFF",
      card: "#FEFEFE",
    },

    grey: {
      G50: "#fafafa",
      G100: "#F5F5F5",
      G200: "#EEEEEE",
      G300: "#E0E0E0",
      G400: "#BDBDBD",
      G500: "#9E9E9E",
      G600: "#757575",
      G700: "#616161",
      G800: "#424242",
      G900: "#212121",
      A100: "#D5D5D5",
      A200: "#AAAAAA",
      A400: "#303030",
      A700: "#616161",
    },

    action: {
      active: lighten("#000000", 0.46),
      hover: lighten("#000000", 0.96),
      selected: lighten("#000000", 0.92),
      disabled: lighten("#000000", 0.74),
      disabledBackground: lighten("#000000", 0.88),
      focus: lighten("#000000", 0.88),
    },

    divider: lighten("#000000", 0.88),

    other: {
      border: lighten("#000000", 0.77),
      snackbar: "#323232",
      backdrop: lighten("#000000", 0.5),
      tooltip: "#616161",
    },
  },

  typography: {
    fontFamily: "iranyekan",
    fontSize: 14,
    htmlFontSize: 14,
    common: {
      fontFamily: "iranyekan",
      fontSize: 14,
      htmlFontSize: 14,
    },
    headline: {
      regular: {
        fontFamily: "iranyekan",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "50px",
        letterSpacing: "-0.16px",
      },
      bold: {
        fontFamily: "iranyekan",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "50px",
        letterSpacing: "-0.16px",
      },
    },
    title1: {
      regular: {
        fontFamily: "iranyekan",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "34px",
        letterSpacing: "-0.16px",
      },
      bold: {
        fontFamily: "iranyekan",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "34px",
        letterSpacing: "-0.16px",
      },
    },
    title2: {
      light: {
        fontFamily: "iranyekan",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "30px",
        letterSpacing: "-0.16px",
      },
      regular: {
        fontFamily: "iranyekan",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "30px",
        letterSpacing: "-0.16px",
      },
      bold: {
        fontFamily: "iranyekan",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "30px",
        letterSpacing: "-0.16px",
      },
    },
    body1: {
      regular: {
        fontFamily: "iranyekan",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "27px",
        letterSpacing: "-0.16px",
      },
      bold: {
        fontFamily: "iranyekan",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "27px",
        letterSpacing: "-0.16px",
      },
    },
    body2: {
      light: {
        fontFamily: "iranyekan",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
      },
      regular: {
        fontFamily: "iranyekan",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
      },
      bold: {
        fontFamily: "iranyekan",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
      },
    },
    body3: {
      bold: {
        fontFamily: "iranyekan",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "34px",
        letterSpacing: "-0.16px",
      },
    },
    caption1: {
      light: {
        fontFamily: "iranyekan",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "22px",
        letterSpacing: "-0.16px",
      },
      regular: {
        fontFamily: "iranyekan",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "22px",
        letterSpacing: "-0.16px",
      },
      bold: {
        fontFamily: "iranyekan",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "22px",
        letterSpacing: "-0.16px",
      },
    },
    caption2: {
      regular: {
        fontFamily: "iranyekan",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "27px",
        letterSpacing: "-0.16px",
      },
      bold: {
        fontFamily: "iranyekan",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "27px",
        letterSpacing: "-0.16px",
      },
    },
    button: {
      large: {
        fontFamily: "iranyekan",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "26px",
        letterSpacing: "-0.16px",
      },
      medium: {
        fontFamily: "iranyekan",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "25px",
        letterSpacing: "-0.16px",
      },
      small: {
        fontFamily: "iranyekan",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
      },
    },
    invoice: {
      regular: {
        fontFamily: "iranyekan",
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "16px",
      },
      bold: {
        fontFamily: "iranyekan",
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "16px",
      },
    },
  },

  styles: {
    dropDownMenu: {
      boxShadow: `0px 3px 14px ${lighten("##000000", 0.88)}`,
    },
    detailsCard: {
      boxShadow: `0px 4px 20px rgba(0, 0, 0, 0.04)}`,
      border: "1px solid #F5F5F5",
      background: "#FFFFFF",
    },
    buttonGlow: {
      boxShadow: `0px 5px 12px ${lighten("#79D70F4D", 0.7)}`,
    },
    buttonGlowSecondary: {
      boxShadow: `0px 5px 12px ${lighten("#5C717A", 0.7)}`,
    },
    divider12: {
      boxShadow: `0px 1px 1px ${lighten("#000000", 0.88)}`,
    },
    divider4: {
      boxShadow: `0px 1px 1px ${lighten("#000000", 0.92)}`,
    },
  },

  direction: "rtl",
  overrides: {
    // MuiCssBaseline: {
    //   "@global": {
    //     "@font-face": [yekan],
    //   },
    // },
    MuiButton: {
      root: {
        fontFamily: "iranyekan!important",
      },
    },
    MuiButtonBase: {
      root: {
        fontFamily: "iranyekan!important",
      },
    },
  },
});
