/* eslint-disable no-undef */
import PropTypes from "prop-types";
import { useEffect } from "react";

const AffilioWidgetPreview = (props) => {
  const { data } = props;
  useEffect(() => {
    if (data) {
      try {
        const REGEX_AFF_ID = new RegExp(/affilio_\d+/gm);
        const REGEX_W_ID = new RegExp(/'\d+'/gm);
        const CHECK_PRODUCT = new RegExp(/ProductWidget/gm);
        const REGEX_GET_URL = new RegExp(
          // eslint-disable-next-line no-useless-escape
          /(https)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}?/gm
        );

        const cId = REGEX_AFF_ID.exec(data)[0]?.replace("affilio_", "");
        const wId = REGEX_W_ID.exec(data)[0]?.replaceAll("'", "");
        const isProductWidget = CHECK_PRODUCT.exec(data)?.length;
        const category = data?.category;

        const CONTAINER_ID = cId || "4833368978685012479";

        const WIDGET_ID = wId || "5261190933352317863";
        const WIDGET_TYPE = isProductWidget ? "ProductWidget" : "BannerWidget";
        const REDIRECT_TYPE = "ProductPage";
        const IS_SMART = false;
        const WIDGET_URL = REGEX_GET_URL.exec(data)[0];
        // const WIDGET_URL = "http://localhost:8080"; // For Client Test

        (function () {
          var d = document;
          const isExist = d.querySelector("#widget-wrapper")?.children?.length;
          if (isExist) return;
          var a = d.createElement("div");
          a.id = `affilio_${CONTAINER_ID}`;
          d.getElementById("widget-wrapper").appendChild(a);

          var s = d.createElement("script");
          s.src = `${WIDGET_URL}/lib.js?${CONTAINER_ID}`;
          s.async = false;
          d.getElementById("widget-wrapper").appendChild(s);
          s.addEventListener("load", () => {
            var c = d.createElement("script");
            c.append(
              `Affilio.widget({
                autosize: true,
                container_id: "affilio_${CONTAINER_ID}",
                widget_type: "${WIDGET_TYPE}",
                widget_id: "${WIDGET_ID}",
                redirect_type: "${REDIRECT_TYPE}",
                url: "",
                is_smart: ${IS_SMART},
                category: ${category},
              });`
            );
            d.getElementById("widget-wrapper").appendChild(c);
          });
        })();
      } catch (e) {
        const cashing = "";
      }
    }
  }, [data]);

  return <div id="widget-wrapper" className="affilio-widget-container"></div>;
};

AffilioWidgetPreview.propTypes = {
  data: PropTypes.any,
};

export default AffilioWidgetPreview;
