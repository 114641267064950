import { Alert, Card } from "antd";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { CopyBlock, a11yLight as theme } from "react-code-blocks";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ToolbarSelectLanguage } from "@/components/widget/WidgetPreviewCode";
import {
  INVENTORY_FORMATS,
  INVENTORY_TYPE,
} from "@/utils/constants/apiConstants";
import { codeBase, objectR } from "@/utils/helper";

import { PlaceContext } from "../AdPlaceBuilder";
import "./CodePlace.less";

const CodePlace = (props) => {
  const { t } = useTranslation("ssp");
  const { inventoryAdd } = useSelector((state) => state.inventory);
  const [context] = useContext(PlaceContext);
  const { mode, id, type, format } = props;
  const [blockCode, setBlockCode] = useState();
  const isLight = mode === "light";

  useEffect(() => {
    let smrType;
    if (!_.isUndefined(type)) {
      smrType = _.toLower(
        `${objectR(INVENTORY_TYPE)[type]}_${objectR(INVENTORY_FORMATS)[format]}`
      );
    } else {
      smrType = _.toLower(
        `${objectR(INVENTORY_TYPE)[context.type]}_${context.format}`
      );
    }

    let blockCode_ = `<div smr-id="${id}" smr-type="${smrType}"></div>`;
    if (!id) {
      blockCode_ = `<div smr-id="${inventoryAdd?.data?.inventory_id}" smr-type="${smrType}"></div>`;
    }

    setBlockCode(blockCode_);
  }, [inventoryAdd?.data]);

  return (
    <Card
      className={`code-place code-place__${mode || ""}`}
      title={
        !isLight ? (
          <div>
            <b>{t("place.steps.generateCode")}</b>
          </div>
        ) : (
          false
        )
      }
    >
      <div className="code-place__wrapper">
        {!isLight ? (
          <div className="alert">
            <Alert
              showIcon
              type="warning"
              message={
                <div>
                  <h3>لطفا توجه کنید!</h3>
                  <p>
                    کدها را فقط در ‌سایتی که هنگام ایجاد جایگاه تبلیغاتی انتخاب
                    کرده‌اید قرار دهید. استفاده از آن در سایر سایت ها، کارایی
                    نداشته و نمایش داده نمی‌شود.
                  </p>
                </div>
              }
            ></Alert>
          </div>
        ) : (
          false
        )}

        <div className="code-place__main">
          <ToolbarSelectLanguage />
          <div className="code-area__container">
            <div className="code__head" hidden>
              <p>{t("codeHeadText")}</p>
              <div className="code-area">
                {codeBase ? (
                  <CopyBlock
                    text={codeBase}
                    language={"html"}
                    showLineNumbers={true}
                    // theme={dracula}
                    theme={theme}
                    codeBlock
                    wrapLongLines
                  />
                ) : (
                  false
                )}
              </div>
              <br />
            </div>

            <p>{t("blockCodeText")}</p>
            <div className="code-area">
              {blockCode ? (
                <CopyBlock
                  text={blockCode}
                  language={"html"}
                  showLineNumbers={true}
                  theme={theme}
                  codeBlock
                />
              ) : (
                false
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

CodePlace.propTypes = {
  mode: "",
  id: "",
  type: "",
  format: "",
};
export default CodePlace;
