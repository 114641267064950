import { configureStore } from "@reduxjs/toolkit";

// import appReducer from "app/local/app";
// import userReducer from "app/local/user";
import counterReducer from "../pages/features/counter/counterSlice";
import accountReducer from "./local/accountSlice";
import adminContractReducer from "./local/adminContractSlice";
import adminMerchantReducer from "./local/adminMerchantSlice";
import adminPublisherReducer from "./local/adminPublisherSlice";
import adminUserReducer from "./local/adminUserSlice";
import appReducer from "./local/appSlice";
import bannerReducer from "./local/bannerSlice";
import bannerWidgetReducer from "./local/bannerWidgetSlice";
import calculationSliceReducer from "./local/calculationSlice";
import campaignReducer from "./local/campaignSlice";
import contractReducer from "./local/contractSlice";
import controlPanelReducer from "./local/controlPanelSlice";
import inventoryReducer from "./local/inventorySlice";
import invoiceReducer from "./local/invoiceSlice";
import linkReducer from "./local/linkSlice";
import mediaReducer from "./local/mediaSlice";
import merchantPayoutReducer from "./local/merchantPayoutSlice";
import merchantReducer from "./local/merchantSlice";
import orderReducer from "./local/orderSlice";
import productWidgetReducer from "./local/productWidgetSlice";
import publisherReducer from "./local/publisherSlice";
import reportReducer from "./local/reportSlice";
import sharedReducer from "./local/sharedSlice";
import standardSizeReducer from "./local/standardSize";
import websiteReducer from "./local/websiteSlice";

// import userReducer from "./local/userSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    account: accountReducer,
    counter: counterReducer,
    publisher: publisherReducer,
    merchant: merchantReducer,
    adminPublisher: adminPublisherReducer,
    adminUser: adminUserReducer,
    adminMerchant: adminMerchantReducer,
    adminContract: adminContractReducer,
    contract: contractReducer,
    website: websiteReducer,
    shared: sharedReducer,
    media: mediaReducer,
    link: linkReducer,
    banner: bannerReducer,
    bannerWidget: bannerWidgetReducer,
    productWidget: productWidgetReducer,
    order: orderReducer,
    invoice: invoiceReducer,
    report: reportReducer,
    merchantPayout: merchantPayoutReducer,
    controlPanel: controlPanelReducer,
    campaign: campaignReducer,
    calculation: calculationSliceReducer,
    inventory: inventoryReducer,
    standardSize: standardSizeReducer,
  },
  // eslint-disable-next-line no-undef
  devTools: process.env.NODE_ENV !== "production",
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  // middleware: (getDefaultMiddleware) => {
  // },
  //   getDefaultMiddleware().concat(mockApi.middleware),
});
