import { Form, Select } from "antd";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

export const PositionTypeOptions = {
  TopRight: 1,
  TopLeft: 2,
  BottomRight: 3,
  BottomLeft: 4,
};

const OptionsFloatVertical = { Top: 5, Bottom: 6 };

const SelectPositionType = (props) => {
  const { name, label, rules, placeholder, isVertical } = props;
  const { t } = useTranslation("ssp");

  const opt = isVertical ? OptionsFloatVertical : PositionTypeOptions;
  let devicesOptions = _.keys(opt).map((k) => ({
    key: opt[k],
    name: k,
  }));

  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      placeholder={placeholder}
    >
      <Select showArrow>
        {devicesOptions.map((item) => (
          <Select.Option key={item.key} value={item.key}>
            {t(`position_inventory.${item.name}`)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

SelectPositionType.propTypes = {
  name: "",
  label: "",
  rules: "",
  isVertical: false,
  placeholder: "",
  excludedOptions: [],
};

export default SelectPositionType;
