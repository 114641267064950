import React, { useEffect, useState } from "react";
import { Avatar, Button, Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import TextArea from "../components/TextArea";
import Divider from "@material-ui/core/Divider";
// import avatarPlaceholder from "assets/avatars/avatarPlaceholder.svg";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import clsx from "clsx";
import moment from "moment-jalaali";
import {
  ticketInformation,
  updateTickets,
} from "../app/services/ticketingService";
import { DISPLAY_JALALI_TIME_DATE_FORMAT } from "../utils/constants";
import CustomMenu from "../components/CustomMenu";
import CustomMenuItem from "../components/CustomMenuItem";
import GetAppIcon from "@material-ui/icons/GetApp";
import { handleDownload, outlinedLargePrimaryBtn } from "../app/helper";
import ListItemText from "@material-ui/core/ListItemText";
import CustomizedButton from "../components/CustomizedButton";
import AttachmentIcon from "@material-ui/icons/Attachment";
import UploadFile from "../components/UploadFile";
import Loading from "../components/Loading";

const Reply = ({ selectedTicket, update, options }) => {
  const [replyText, setReplyText] = useState("");
  const [ticket, setTicket] = useState({});
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [msgIndex, setMsgIndex] = useState(null);
  const [replyLoading, setReplyLoading] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [files, setFiles] = useState([]);
  // const { userProfile } = useSelector(userData);
  const { userProfile } = options;
  const classes = useStyles();
  const { t } = useTranslation("support");

  useEffect(() => {
    if (selectedTicket && !replyLoading) {
      setLoading(true);
      ticketInformation(selectedTicket, userProfile.email)
        .then((res) => setTicket(res.data.data))
        .finally(() => setLoading(false));
    }
  }, [selectedTicket, replyLoading]);

  const onReply = () => {
    const formData = new FormData();
    files.map((file, i) => {
      formData.append(`attachments[${i}]`, file);
    });
    formData.append("_method", "PUT");
    formData.append("content", replyText);

    setReplyLoading(true);
    updateTickets(selectedTicket, userProfile.email, formData)
      .then(() => {
        setReplyText("");
        setFiles([]);
      })
      .finally(() => setReplyLoading(false));
  };

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMsgIndex(index);
  };

  return (
    <div className="list-tickets">
      {/* <Loading open={loading} isInside /> */}
      <div className={classes.massagesContainer}>
        <Loading open={loading} isInside />
        {ticket?.contents
          ?.map((item, index) => (
            <div
              key={item.id}
              className={clsx(classes.message, {
                [classes.answerMessage]: !item?.owner,
              })}
            >
              <Avatar>{item.user?.first_name?.[0]}</Avatar>
              <div className={classes.body}>
                <div className={classes.userName}>{item.user?.name}</div>
                <div className={classes.text}>
                  <div dangerouslySetInnerHTML={{ __html: item.body }} />
                </div>
                <>
                  {item?.attachments[0]?.name && (
                    <GetAppIcon
                      onClick={(event) => handleClick(event, index)}
                      className={"downloadIcon"}
                    />
                  )}
                  <CustomMenu
                    id={`customized-menu-${index}`}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl) && msgIndex === index}
                    onClose={() => setAnchorEl(null)}
                  >
                    {item.attachments?.map((file, i) => {
                      return (
                        <CustomMenuItem
                          key={item.presentation}
                          onClick={() =>
                            handleDownload(file.name, t("file") + " " + (i + 1))
                          }
                        >
                          <ListItemText
                            className={classes.itemText}
                            primary={t("file") + " " + (i + 1)}
                          />
                        </CustomMenuItem>
                      );
                    })}
                  </CustomMenu>
                </>
              </div>
              <div className={classes.time}>
                <AccessTimeIcon fontSize="small" />
                <span>
                  {moment(item.updated_at).format(
                    DISPLAY_JALALI_TIME_DATE_FORMAT
                  )}
                </span>
              </div>
            </div>
          ))
          .reverse()}
      </div>
      <Divider className={classes.divider} />
      <TextArea
        className={classes.textArea}
        label={t("request_text")}
        inputHeight="90px"
        value={replyText}
        onChange={(e) => setReplyText(e.target.value)}
      />
      <Grid container justify="space-between" className="">
        <Grid>
          <Button onClick={onReply}>{t("send_ticket")}</Button>
        </Grid>
        {/* <Grid container justify={"flex-end"} style={{ marginBottom: 16 }}> */}
        <Grid>
          <CustomizedButton
            icon={<AttachmentIcon />}
            onClick={() => setShowUpload(true)}
            isStartIcon
            buttonProperties={outlinedLargePrimaryBtn}
            hasType={true}
            extraStyle={classes.uploadBtn}
          >
            <label>{t("select_attach_file")}</label>
          </CustomizedButton>
        </Grid>

        {/* </Grid> */}
      </Grid>
      <UploadFile
        showUpload={showUpload}
        setShowUpload={setShowUpload}
        files={files}
        setFiles={setFiles}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: 640,
  },
  message: {
    position: "relative",
    display: "flex",
    width: "60%",
    marginBottom: theme.spacing(5),
    borderRadius: `0px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px ${theme.spacing(3)}px`,
    padding: theme.spacing(2),
    backgroundColor: "#EFF7E7",
    "& .downloadIcon": {
      color: theme.palette.grey[400],
      cursor: "pointer",
      position: "absolute",
      fontSize: 24,
      right: 25,
      bottom: -26,
    },
  },
  answerMessage: {
    left: "40%",
    flexFlow: "row-reverse",
    backgroundColor: "#EFF1F2",
    borderRadius: `${theme.spacing(3)}px 0px ${theme.spacing(
      3
    )}px ${theme.spacing(3)}px`,
  },
  body: {
    width: "calc(100% - 32px)",
    fontSize: 12,
    marginLeft: theme.spacing(1.5),
  },
  userName: {
    marginBottom: 4,
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "100%",
    border: "2px solid #fff",
    "& img": {
      width: "100%",
    },
  },
  time: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    fontSize: 10,
    position: "absolute",
    bottom: -theme.spacing(3),
    "& .MuiSvgIcon-root": {
      color: "#757575",
      fontSize: 17,
    },
  },
  divider: {
    width: "calc(100% + 80px)",
    borderColor: theme.palette.grey[300],
    position: "relative",
    left: -40,
    margin: `${theme.spacing(4)}px 0`,
  },
  textArea: {
    marginBottom: theme.spacing(3),
  },
  saveBtn: {
    marginTop: theme.spacing(2),
  },
  massagesContainer: {
    position: "relative",
    height: "calc(90vh - 460px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
  uploadInput: {
    display: "none",
    cursor: "pointer",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  itemText: {
    fontSize: 8,
  },
}));

Reply.propTypes = {
  selectedTicket: PropTypes.any,
  setSelectedTicket: PropTypes.func,
  update: PropTypes.bool,
  options: PropTypes.any,
};

export default Reply;
