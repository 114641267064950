import { Button, Form, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import { getMerchantDetail } from "@/app/local/adminMerchantSlice";
import { getPublishDetail } from "@/app/local/adminPublisherSlice";
import { setAdminUserConfig, setUserStatus } from "@/app/local/adminUserSlice";
import { USER_PAGE } from "@/pages/profile";
import { getProfileData } from "@/pages/profile";
import {
  FIRST_APPROVE_ACCEPTED,
  USER_STATUS,
  USER_TYPE,
} from "@/utils/constants/apiConstants";

import { getKycOptions } from "./FormKyc";

const FormStatusKyc = (props) => {
  const profile = getProfileData();

  const userInfo = props.userInfo?.user;
  const userType = _.get(props, "userInfo.user.user_type");
  const prefixUrl =
    userType === USER_TYPE.PUBLISHER || userType === USER_TYPE.ADVERTISER
      ? USER_PAGE.publishers
      : USER_PAGE.merchants;
  const user = useMatch(`/${prefixUrl}/` + ":id");

  const [form] = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.adminUser);

  useEffect(() => {
    const userStatus = userInfo?.status;
    const userStatusDesc = userInfo?.status_description;
    if (userStatus) {
      form.setFieldsValue({ userStatus, userStatusDesc });
    }
  }, []);

  const checkTerms = () => {
    // const KYC_STATUS_ = { ...KYC_STATUS };
    // delete KYC_STATUS_.PendingForFirstApproval;

    const { kyc_status } = userInfo;
    // const kycUser = kyc_status.filter(
    // (item) => item !== KYC_STATUS.PendingForFirstApproval
    // );

    const kycItems = getKycOptions(profile, t);
    const len = kycItems?.length;
    if (kyc_status?.length >= len) return true;
    return false;
  };

  const onFinish = async (e) => {
    const uId = user.params?.id;

    if (e.userStatus === USER_STATUS.Confirmed) {
      const isKycOk = checkTerms(userInfo?.kyc_status);
      if (!isKycOk) {
        message.error({
          content: t("user.errorAllKycMustBeApproved"),
          key: "user",
        });
        return;
      }
      const respSet = await dispatch(
        setAdminUserConfig({
          user_id: uId,
          type: "FirstApprove",
          value: FIRST_APPROVE_ACCEPTED,
        })
      );
      if (!respSet?.payload?.success) {
        message.error({
          content: respSet?.payload?.Message || t("user.unHandleErrorMessage"),
          key: "user",
        });
        return;
      }
    }

    const res = await dispatch(
      setUserStatus({
        user_id: uId,
        status: e.userStatus,
        description: e.userStatusDesc,
      })
    );

    if (res.payload?.success) {
      message.success({
        content: t("general.saveSuccessFully"),
        key: "user",
      });

      //!REMOVE
      // if (e.userStatus === USER_STATUS.Confirmed) {
      //   const kysStatus = userInfo?.kyc_status;
      //   const kycS = kysStatus.filter(
      //     (i) => i !== KYC_STATUS.PendingForFirstApproval
      //   );
      //   await dispatch(
      //     setKycStatus({
      //       user_id: uId,
      //       kyc_status: kycS,
      //       description: "",
      //     })
      //   );
      // }
      if (userType === USER_TYPE.PUBLISHER) {
        dispatch(getPublishDetail(uId));
      } else {
        dispatch(getMerchantDetail(uId));
      }
      props.close();
    } else {
      message.error({
        content: res.payload?.Message || t("general.unHandleErrorMessage"),
        key: "user",
      });
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name={"userStatus"}
        label={t("general.select")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          size="large"
          style={{ flexGrow: 1, display: "flex", marginLeft: "10px" }}
          placeholder={t("auth.selectTypeUser")}
        >
          {Object.keys(USER_STATUS).map((key) => {
            return (
              <Select.Option key={key} value={USER_STATUS[key]}>
                {t(`profile.user.${key}`)}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(pre) => {
          const isError =
            pre.getFieldValue("userStatus") === USER_STATUS.KysError;

          return (
            <Form.Item
              name={"userStatusDesc"}
              rules={[
                {
                  required: isError,
                },
              ]}
              label={t("users.statusDescription")}
            >
              <TextArea rows={4} />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item
        wrapperCol={{
          // offset: 8,
          span: 16,
        }}
        className="form_actions"
      >
        <Button loading={loading} size="large" type="primary" htmlType="submit">
          {t("general.save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

FormStatusKyc.propTypes = {
  userInfo: PropTypes.object,
  close: PropTypes.func,
};

export default FormStatusKyc;
