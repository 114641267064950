import { Bar } from "@ant-design/plots";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// import NameTag from "../micro/NameTag";
import { getCommisionOverview } from "@/app/local/reportSlice";
import { DashboardContext } from "@/pages/dashboard/DashboardAdmin";
import { kFormatter } from "@/utils/helper";

import NameTag from "../micro/NameTag";
import TileNumber from "../micro/TileNumber";
import { COLORS } from "./colors";
import WidgetWrapper from "./micro/WidgetWrapper";
import "./styles.less";

const mockData = [
  {
    type: 0,
    finalized_commission: 34000,
    issued_commission: 15000,
  },
  {
    type: 0,
    finalized_commission: 293485,
    issued_commission: 23333,
  },
];

const AdminCommissionChart = (props) => {
  const { isMerchant, title } = props;
  const [totalData, setTotalData] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { adminCommisionOverview, adminCommisionOverviewLoading } = useSelector(
    (state) => state.report
  );
  const [context, dispatch_] = useContext(DashboardContext);
  const [mode, setMode] = useState("LINE");

  useEffect(() => {
    onSubmit();
  }, [context]);

  useEffect(() => {
    if (adminCommisionOverview) {
      const data_ = [...(adminCommisionOverview?.data || mockData)];
      const temps = [];
      let total = { sure: 0, not_sure: 0 };
      data_.map((item, i) => {
        temps.push({
          name: t("dashboard.finalized"),
          value: item.finalized_commission || 0,
          type: item.type,
        });
        total.sure += item.finalized_commission || 0;

        // temps.push({
        //   name: t("dashboard.invoiced"),
        //   value: item.invoiced_commission || 0,
        //   type: item.type,
        // });
        // total.invoiced_commission += item.invoiced_commission || 0;

        temps.push({
          name: t("dashboard.issued"),
          value: item.issued_commission || 0,
          type: item.type,
        });
        total.not_sure += item.issued_commission || 0;
      });
      setTotalData(total);
      setData(temps);
    }
  }, [adminCommisionOverview]);

  const [data, setData] = useState([]);

  const config = useMemo(() => {
    return {
      data,
      xField: "value",
      yField: "name",
      isStack: true,
      seriesField: "type",
      legend: false,
      maxBarWidth: 40,
      // width: 260,
      height: 180,
      padding: [10, 30, 40, 100],
      tooltip: {
        formatter: (datum) => {
          return { name: t("dashboard.value"), value: datum.value };
        },
      },
      xAxis: {
        label: {
          formatter: (v) => kFormatter(v, 1),
        },
      },
      // yAxis: {
      //   // position: "right",
      //   reverse: true,
      //   label: {
      //     offset: 12,
      //     style: { textAlign: "right", rotate: 45 },
      //   },
      // },

      // xAxis: {
      //   //   reverse: true,
      //   label: {
      //     offset: 12,
      //     style: { textAlign: "right", rotate: 45 },
      //   },
      // },
      // xAxis: {
      //   type: "category",
      //   tickLine: null,
      //   position: "top",
      //   label: {
      //     offset: 12,
      //     style: { textAlign: "bottom" },
      //   },
      // },
      intervalPadding: 0,
      barBackground: {
        style: {
          fill: "rgba(0,0,0,0.1)",
        },
      },
      color: ({ type, name }, ...x) => {
        switch (type) {
          case 0:
            return COLORS.DASHBOARD_PURPLE;
          case 1:
            return COLORS.DASHBOARD_ORANGE;
          default:
            return COLORS.DASHBOARD_BLUE;
        }
      },
      theme: {
        styleSheet: {
          fontFamily: "IRANYekan",
        },
      },
    };
  }, [data]);

  const onSubmit = (e) => {
    dispatch(
      getCommisionOverview({
        web_store_ids: context.webstore,
        media_ids: e?.medias || context?.medias,
        link_type_report_types: e?.link_type,
        from: context.period.fromDate,
        to: context.period.toDate,
        publisher_ids: context?.publisher_ids,
        filter_date_by: context?.filter_date_by,
        user_type: e?.user_type,
      })
    );
  };

  return (
    <WidgetWrapper
      setMode={setMode}
      title={`dashboard.${title}`}
      onSubmit={onSubmit}
      hideSwitch
      hasUserType
      className="admin_commission_chart"
    >
      <div
        className="horizontal-chart-bar"
        dir="rtl"
        style={{ direction: "rtl" }}
      >
        <div className="tile-bar">
          <TileNumber
            title={t("dashboard.not_sure_commission")}
            // number={data.finalized_commission}
            number={totalData?.not_sure}
            align="center"
          />
          {/* <TileNumber
            title={t("dashboard.invoiced_commission")}
            number={totalData?.invoiced_commission}
            align="center"
          /> */}
          <TileNumber
            title={t("dashboard.sure_commission")}
            // number={data.issued_commission}
            number={totalData?.sure}
            align="center"
          />
        </div>
        <div className={`tags-bar align__right`}>
          <NameTag title={"کمیسیون ناشران"} color="color_1" />
          {/* <NameTag title={"کمیسیون افیلیو"} color="color_2" /> */}
        </div>
      </div>
      <div style={{ direction: "ltr" }}>
        <Bar {...config} loading={adminCommisionOverviewLoading} />
      </div>
    </WidgetWrapper>
  );
};

AdminCommissionChart.propTypes = {
  isMerchant: PropTypes.bool,
  title: PropTypes.string,
};

export default AdminCommissionChart;
