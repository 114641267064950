import { Form, Input } from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { ChromePicker } from "react-color";

const InputColor = (props) => {
  const { label, name, form, defaultColor } = props;
  const [openCp, setOpenCp] = useState();
  const [selectedColor, setSelectedColor] = useState(defaultColor);

  useEffect(() => {
    if (defaultColor) onChangeComplete({ hex: defaultColor });
  }, [defaultColor]);

  const onChangeComplete = (e) => {
    form.setFieldsValue({ [name]: e.hex });
    setSelectedColor(e.hex);
    setTimeout(() => form.submit(), 100);

    setTimeout(() => emitColorsOnWidgetPreview(e), 500);
  };

  const emitColorsOnWidgetPreview = (e) => {
    if (name === "color_button") {
      const d = document.querySelectorAll(
        ".widget__box .product__item .ant-btn"
      );
      d.forEach((item) => {
        item.style.backgroundColor = e.hex;
        item.style.borderColor = e.hex;
      });
    }
    if (name === "color_title_button") {
      const d = document.querySelectorAll(
        ".widget__box .product__item .ant-btn"
      );
      d.forEach((item) => {
        item.style.color = e.hex;
        item.style.borderColor = e.hex;
      });
    }
  };

  return (
    <div className="input-color">
      <Form.Item name={name} label={t(label)}>
        <Input
          addonBefore={
            <div
              className={`iconColor`}
              style={{ backgroundColor: selectedColor }}
              onClick={() => {
                setOpenCp(!openCp);
              }}
            ></div>
          }
          defaultValue={defaultColor}
          size="large"
          onClick={() => {
            setOpenCp(!openCp);
          }}
        />
      </Form.Item>
      {openCp ? (
        <div className="input-color__popover">
          <div
            className="input-color__cover"
            onClick={() => setOpenCp(false)}
          />
          <ChromePicker
            color={selectedColor}
            onChangeComplete={onChangeComplete}
          />
        </div>
      ) : null}
    </div>
  );
};

InputColor.propTypes = {
  name: "",
  label: "",
  form: "",
  defaultColor: "",
};

export default InputColor;
