import { Modal, Spin } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";
import React from "react";

import "./styles.less";

const ModalContentTerms = (props) => {
  return (
    <Modal
      title={t("auth.contractTitle")}
      visible={props.isModalVisible}
      onOk={() => props.handleModal(true)}
      onCancel={() => props.handleModal(false)}
      className={"terms__modal"}
      okText={t("auth.agree")}
      cancelText={false}
    >
      <div className="contract-text">
        <p className="modal-title">
          ضوابط و شرایط استفاده از سامانه همکاری در فروش افیلیو
        </p>

        <p className="modal-main-text">
          به سامانه همکاری در فروش «افیلیو» ("AFFILIO")، به نشانی www.affilio.ir
          (از این پس اختصارا «سامانه») خوش آمدید.
        </p>
        <p className="modal-main-text">
          این سامانه متعلق به شرکت اطلس ارتباط رامان است و امکانات وب‌سایت،
          برنامههای کاربردی (اپلیکیشن)، استفاده از نرم‌افزارها و سایر سرویس و
          خدمات آنلاین با موضوع همکاری در فروش محصولات را در اختیار شما قرار
          می‌دهد
        </p>
        <p className="modal-main-text">
          شما با ثبت نام، عضویت و استفاده از این سامانه ، از طرف خود و هر یک از
          افرادی که از این سامانه تحت حساب کاربری شما استفاده می‌کنند، با شرایط
          زیر موافقت مینمایید.:
        </p>
        <p className="modal-title">ایجاد حساب کاربری و ارائه اطلاعات</p>
        <p>
          شما برای استفاده از خدمات سامانه نیاز به ثبت‌نام و ایجاد حساب کاربری
          در وب‌سایت افیلیو دارید. نام کاربری و رمز عبور حساب کاربری شما توسط
          خودتان تعریف و تعیین میشود. لازم است بدانید که با توجه به نوع شخص
          (حقیقی یا حقوقی) از شما اطلاعاتی دریافت می‌شود؛ این اطلاعات برای شخص
          حقیقی شامل نام و نام خانوادگی، کد ملی، نشانی، کدپستی، شماره تلفن ثابت،
          همراه و در خصوص شخص حقوقی شامل نام، شناسه ملی، شماره ثبت، نشانی،
          کدپستی شرکت و شماره تلفن ثابت است. شما مسئول حفظ محرمانگی اطلاعات، نام
          و رمز عبور حساب کاربری خود هستید و موافقت می‌نمایید مسئولیت کلیه
          فعالیت‌هایی که از طریق حساب کاربری‌تان انجام میشود برعهده شما است.
        </p>
        <p>
          شما از طریق موافقت با سند حاضر تعهد و تصدیق می‌کنید که کلیه اطلاعاتی
          که در سامانه وارد کردید صحیح و مطابق با واقعیت است و مسئولیت عدم صحت
          یا جعلی بودن اطلاعات بر عهده شما است. افیلیو می‌تواند با کشف عدم صحت و
          خلاف واقع بودن اطلاعات ارائه‌شده در هر زمان و بدون اطلاع‌رسانی و اخطار
          قبلی حساب کاربری شما را به طور کامل مسدود کند.
        </p>
        <p>
          حداقل سن برای ایجاد حساب کاربری و استفاده از آن در هنگام ثبت نام 18
          سال تمام شمسی است. اگر کمتر از 18 سال سن دارید تنها میتوانید از طریق
          حسابکاربری ایجاد شده توسط والدین خود فعالیت کنید. والدین شما در این
          خصوص تایید و تصدیق میکنند که کلیه فعالیتهای حساب فوق تحت نظارت و
          مسئولیت کامل آن‌‌ها است.
        </p>
        <p>
          شما همچنین تعهد می‌کنید که با نام‌های متعدد نسبت به تشکیل حساب‌های
          کاربری متفاوت به صورت همزمان اقدام نکنید.
        </p>
        <p className="modal-title">شرایط همکاری با ناشر و فروشنده</p>
        <p>
          عضویت در سامانه و همکاری شما با افیلیو به یکی از دو صورت ناشر و یا
          فروشنده است. لازم به ذکر است برای فعالیت به عنوان فروشنده، کاربر
          می‌بایست شخص حقوقی باشد و امکان فعالیت همزمان به صورت ناشر و فروشنده
          در سامانه افیلیو وجود ندارد. لازم به ذکر است اطلاعات هویتی و ثبتی
          ارائه شده در سامانه توسط شما، باید مطابق با نام مالک دامنه و وب‌سایت
          رسانه ناشر و یا فروشگاه فروشنده (بسته به نوع همکاری) باشد. مغایرت در
          این امر موجب انسداد حساب کاربری شما از سوی افیلیو می‌شود. در هر صورت،
          همکاری شما با افیلیو تنها پس از تایید حساب کاربری، صحت‌سنجی اطلاعات
          ارائه شده از جانب شما، تایید رسانه یا فروشگاه معرفی شده برای فعالیت در
          سامانه افیلیو (با توجه به نوع همکاری) و در نهایت انعقاد قرارداد
          (الکترونیکی) امکانپذیر است. صرف ثبت‌نام در سامانه، تکمیل اطلاعات آن یا
          دارا بودن شرایط پذیرش در سامانه افیلیو، به منزله قبول همکاری افیلیو با
          اشخاص نیست و افیلیو هیچ تعهد و الزامی در خصوص تایید حساب کاربری شما و
          قبول همکاری پس از ثبت ‌نام در سامانه ندارد. برخی از شرایط رسانه یا
          فروشگاه کاربر برای پذیرش در سامانه افیلیو به شرح زیر است:
        </p>
        <ul>
          <li>از نظر طراحی و گرافیک، در سطح مطلوب و مورد قبول افیلیو باشد؛</li>
          <li>دارای پاپ آپ یا نمایش بیش از حد تبلیغات نباشد؛</li>
          <li>دارای مطالب صحیح باشد؛</li>
          <li>در زمان همکاری و استفاده از سامانه افیلیو در دسترس باشد؛</li>
          <li>
            جزو چت روم‌ها، باکس‌ها و سایت‌های افزایش رتبه(Rank) و موارد مشابه
            نباشد؛
          </li>
          <li>
            دارای محتوایی مطابق با قوانین و مقررات و عرف جاری کشور باشد و حاوی
            محتوا و مطالب غیر اخلاقی، کاذب، خلاف نظم عمومی و یا نقض کننده‌ی حقوق
            مادی و معنوی اشخاص و یا موهن (از نظر مذهبی، سیاسی، قومی یا نژادی و
            جنسیتی) نباشد؛
          </li>
          <li>
            حاوی صفحات و لینک‌های جعلی برای ورود به سایت‌های جعلی و مشابه سایت
            افیلیو، که باعث گمراهی کاربران میگردد، نباشد. (به عنوان مثال حاوی
            لینکی با عنوان "برای ورود به سایت اصلی افیلیو اینجا کلیک کنید"
            نباشد).
          </li>
        </ul>
        <p>
          لازم به ذکر است با توجه به ویژگی هر رسانه یا فروشگاه ممکن است شرایط
          دیگری نیز برای پذیرش در سامانه افیلیو وجود داشته باشد که شروع و ادامه
          همکاری با سامانه افیلیو صرفا پس از احراز وجود و رعایت این شرایط
          امکان‌پذیر است.
        </p>
        <ul>
          <li>
            مجوز دسترسی و استفاده از اطلاعات و سیاست‏‌های رعایت حریم خصوصی
          </li>
        </ul>
        <p>
          لطفا جهت آگاهی از نحوه جمعآوری، حفظ محرمانگی و امنیت و استفاده از
          دادههای کاربران که از سامانه همکاری در فروش افیلیو استفاده می‌کنند،
          سند حفظ حریم خصوصی سامانه افیلیو را مطالعه نمایید.
        </p>
        <p className="modal-title">رعایت حقوق مادی و معنوی سامانه افیلیو</p>
        <p>
          کلیه حقوق (مادی و معنوی) سامانه افیلیو (مانند و نه محدود به حقوق مرتبط
          با علائم تجاری (برند) اعم از ثبت شده و یا ثبتنشده، حقوق مالکیت فکری
          مربوط به هر نوع محتوای مندرج در وبسایت و سامانه به هر شکل، مانند
          متن‌ها، طرح‌های گرافیکی، لوگوها، آیکون‌ها، تصاویر، فیلم‌ها، صداها، نام
          دامنه و سایر موارد) متعلق به افیلو است. بدیهی است در صورت تخلف و نقض
          هر یک از این حقوق توسط کاربر، افیلیو علاوه بر حق مسدود نمودن حساب
          کاربری شخص متخلف (بدون اعلام قبلی) حق پیگیری امر و مطالبه جبران خسارات
          وارده در مراجع رسیدگی ذیصلاح را دارد. درج نام و علامت تجاری افیلیو
          درURL ایمیلها، Source Code نامهها، رسانه یا فروشگاه شما و هرگونه
          استفاده دیگری منوط به تایید افیلیو در قرارداد کتبی مابین و یا اعلام
          رضایت کتبی خارج از قرارداد است.
        </p>
        <p className="modal-title">ارتباطات الکترونیکی</p>
        <p>
          کلیه ارتباطات و مکاتبات افیلیو با شما به صورت الکترونیکی و از طریق
          حساب کاربری اختصاص یافته به شما در سامانه یا آدرس ایمیل و یا شماره
          تماس ارائه‌شده توسط شما انجام می‌شود. لازم به ذکر است که این مکاتبات
          در حکم مکاتبه کتبی تلقی شده و قابل استناد است.
        </p>
        <p className="modal-title">بررسی و تغییر سند حاضر</p>
        <p>
          ضوابط و شرایط استفاده از سامانه افیلیو به طور مستمر توسط افیلیو مورد
          بررسی قرار می‌گیرد و ممکن است در هر زمانی تغییراتی در آن ایجاد شود.
          نسخه‌های به ‌روز شده این سند جهت مشاهده و اطلاع شما در سامانه افیلیو
          قرار می‌گیرد و تاریخ آخرین تغییرات آن در ابتدای سند تصریح می‌گردد. اگر
          پس از اعمال تغییر در شرایط به هر نحوی از سامانه و خدمات ما استفاده
          کنید این به معنی موافقت شما با تغییرات است. افیلیو خود را متعهد به
          رعایت مفاد این سند می‌داند و همواره با مکانیزم‌های نظارتی درونی، حسن
          اجرای مفاد سند حاضر را مورد بررسی و بازرسی قرار می‌دهد.
        </p>
        <p>
          لطفا در صورت وجود هرگونه ابهام، سوال و اشکال از طریق ایمیل
          legalnotices@smartech.ir با ما تماس حاصل نموده و در ارتباط باشید.
        </p>
      </div>
    </Modal>
  );
};

ModalContentTerms.propTypes = {
  isModalVisible: PropTypes.bool,
  handleModal: PropTypes.func,
  userType: PropTypes.string,
};

export default ModalContentTerms;
