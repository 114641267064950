import { Card } from "antd";
import PropTypes from "prop-types";
import React from "react";

import "./MainContainer.less";

const MainContainer = (props) => {
  const { children, wrapDisabled, pTop, mBottom } = props;
  return (
    <div
      className="main-container"
      style={{ paddingTop: pTop, paddingBottom: mBottom }}
    >
      {wrapDisabled ? (
        <div className="simple-wrap">{children}</div>
      ) : (
        <Card>{children}</Card>
      )}
    </div>
  );
};
MainContainer.propTypes = {
  children: PropTypes.any,
  wrapDisabled: PropTypes.bool,
  pTop: PropTypes.any,
  mBottom: PropTypes.any,
};

export default MainContainer;
