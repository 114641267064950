import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ENDPOINTS } from "@/utils/constants/apiConstants";

import { baseAPI } from "../services/fetchers";

export const getSubjectsListAsync = createAsyncThunk(
  "shared/getSubjectsListAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.SHARED_SUBJECT_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getReasonsAsync = createAsyncThunk(
  "shared/getReasonsAsync",
  async (data) => {
    try {
      const type = data?.type;
      const end_ = ENDPOINTS.SHARED_REASON_LIST + (type ? "?type=" + type : "");
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const addReasonsAsync = createAsyncThunk(
  "shared/addReasonsAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.SHARED_REASON_ADD;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const editReasonsAsync = createAsyncThunk(
  "shared/editReasonsAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.SHARED_REASON_EDIT;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const deleteReasonAsync = createAsyncThunk(
  "shared/deleteReasonAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.SHARED_REASON_DELETE.replace(
        "{reasonId}",
        data?.reason_id
      );
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const addSubjectAsync = createAsyncThunk(
  "shared/addSubjectAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.SHARED_SUBJECT_ADD;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const editSubjectAsync = createAsyncThunk(
  "shared/editSubjectAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.SHARED_SUBJECT_EDIT;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const deleteSubjectAsync = createAsyncThunk(
  "shared/deleteSubjectAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.SHARED_SUBJECT_DELETE.replace(
        "{subject_id}",
        data?.subject_id
      );
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getTermsContentAsync = createAsyncThunk(
  "shared/getTermsContentAsync",
  async (type) => {
    try {
      const end_ = ENDPOINTS.SHARED_CONTENT_TYPE.replace("{type}", type);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const sharedSlice = createSlice({
  name: "shared",
  initialState: {
    loading: false,
    error: null,

    subjects: null,
    subjectsLoading: false,
    subjectsError: null,

    reasons: null,
    reasonsLoading: false,
    reasonsError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSubjectsListAsync.pending, (state, action) => {
      state.subjectsLoading = true;
      state.subjectsError = null;
    });
    builder.addCase(getSubjectsListAsync.rejected, (state, action) => {
      state.subjectsLoading = false;
      state.subjectsError = action.error;
    });
    builder.addCase(getSubjectsListAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.subjects = action.payload;
      }
      state.subjectsLoading = false;
      state.subjectsError = null;
    });

    builder.addCase(getReasonsAsync.pending, (state, action) => {
      state.reasonsLoading = true;
      state.reasonsError = null;
    });
    builder.addCase(getReasonsAsync.rejected, (state, action) => {
      state.reasonsLoading = false;
      state.reasonsError = action.error;
    });
    builder.addCase(getReasonsAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.reasons = action.payload;
      }
      state.reasonsLoading = false;
      state.reasonsError = null;
    });
  },
});

export const getSubjectsList = (data) => async (dispatch) => {
  return dispatch(getSubjectsListAsync(data));
};

export const getReasons = (data) => async (dispatch) => {
  return dispatch(getReasonsAsync(data));
};

export const addReason = (data) => async (dispatch) => {
  return dispatch(addReasonsAsync(data));
};

export const editReason = (data) => async (dispatch) => {
  return dispatch(editReasonsAsync(data));
};

export const deleteReason = (data) => async (dispatch) => {
  return dispatch(deleteReasonAsync(data));
};

export const addSubject = (data) => async (dispatch) => {
  return dispatch(addSubjectAsync(data));
};

export const editSubject = (data) => async (dispatch) => {
  return dispatch(editSubjectAsync(data));
};

export const deleteSubject = (data) => async (dispatch) => {
  return dispatch(deleteSubjectAsync(data));
};

export const getTermsContent = (data) => async (dispatch) => {
  return dispatch(getTermsContentAsync(data));
};

export default sharedSlice.reducer;
