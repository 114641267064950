import { Button, Form, Input, Switch, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { addGroup, editGroup, getGroups } from "@/app/local/adminMerchantSlice";

import SelectWebsite from "../input/selectWebsite";

const FormTag = (props) => {
  const { options, defaultWebId } = props;
  const [form] = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mode_ = options?.type;

  const { groupEditLoading } = useSelector((state) => state.adminMerchant);

  useEffect(() => {
    if (mode_ === "EDIT") {
      form.setFieldsValue({
        name: options.item.name,
        website: options.item.web_store_id,
        is_enabled: options.item.is_enabled,
      });
    }

    if (defaultWebId) {
      form.setFieldsValue({ website: defaultWebId });
    }
  }, [options]);

  const onFinish = async (e) => {
    let result = {};
    if (mode_ === "EDIT") {
      const body = {
        merchant_widget_grouping_id: options.item.merchant_widget_grouping_id,
        name: e.name,
        web_store_id: e.website,
        is_enabled: e.is_enabled,
      };
      result = await dispatch(editGroup(body));
    } else {
      const body = {
        name: e.name,
        web_store_id: e.website,
      };
      result = await dispatch(addGroup(body));
    }

    if (result?.payload?.success) {
      await dispatch(getGroups(form.getFieldValue("website")));
      message.success({
        content: t("general.saveSuccessFully"),
      });
      form.resetFields();
      props.close();
    } else {
      message.error({
        content: result?.payload?.Message || t("general.unHandleErrorMessage"),
      });
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name={"name"}
        rules={[
          {
            required: true,
            message: " نام تگ اجباری است",
          },
        ]}
        label={t("grouping.name")}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={"website"}
        rules={[
          {
            required: true,
            message: " فروشگاه اجباری است",
          },
        ]}
        label={t("grouping.website")}
      >
        <SelectWebsite size="default" name={"website"} />
      </Form.Item>

      {mode_ === "EDIT" ? (
        <Form.Item name={"is_enabled"} valuePropName="checked">
          <Switch
            checkedChildren={t("grouping.status.true")}
            unCheckedChildren={t("grouping.status.false")}
          />
        </Form.Item>
      ) : (
        ""
      )}

      <Form.Item
        wrapperCol={{
          // offset: 8,
          span: 16,
        }}
        className="form_actions"
      >
        <Button
          loading={groupEditLoading}
          size="large"
          type="primary"
          htmlType="submit"
          disabled={props.disabled}
        >
          {t("general.submit")}
        </Button>
      </Form.Item>
    </Form>
  );
};

FormTag.propTypes = {
  close: PropTypes.func,
  options: PropTypes.object,
  disabled: PropTypes.bool,
  defaultWebId: PropTypes.string,
};

export default FormTag;
