import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// import { baseAPI } from "@/app/services/baseApi";
import { ENDPOINTS } from "@/utils/constants/apiConstants";
import { clearObject } from "@/utils/utils";

import { baseAPI } from "../services/fetchers";

export const getOrdersAsync = createAsyncThunk(
  "order/getOrdersAsync",
  async (data) => {
    const defaultBody = {
      page: 1,
      page_size: 10,
      // sort_orientation: "1 = Descending",
    };

    try {
      let end_ = ENDPOINTS.ORDER_LIST;
      const data_ = clearObject(data);
      const body_ = Object.assign({}, defaultBody, data_);
      const response = await baseAPI({
        endPoint: end_,
        body: body_ || {},
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getOrdersAggregateAsync = createAsyncThunk(
  "order/getOrdersAggregateAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.ORDER_AGGREGATE_LIST;
      const data_ = clearObject(data);
      let body_ = Object.assign({}, data_);
      body_ = body_?.filter;
      // delete body_.page;
      // delete body_.page_size;
      const response = await baseAPI({
        endPoint: end_,
        body: body_ || {},
        controller: data?.controller,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const exportOrdersCsvAsync = createAsyncThunk(
  "order/exportOrdersCsvAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.ORDER_LIST_EXCEL;
      const response = await baseAPI({
        endPoint: end_,
        body: data || {},
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const exportOrdersCsvAsyncTypeAsync = createAsyncThunk(
  "order/exportOrdersCsvAsyncTypeAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.ORDER_LIST_EXCEL_ASYNC;
      const response = await baseAPI({
        endPoint: end_,
        body: data || {},
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

const slice = createSlice({
  name: "order",
  initialState: {
    orders: null,
    ordersLoading: false,
    ordersError: null,

    ordersAggregate: null,
    ordersAggregateLoading: false,
    ordersAggregatesError: null,
    error: null,
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      state.error = null;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(getOrdersAsync.pending, (state) => {
      state.ordersLoading = true;
      state.ordersError = null;
    });
    builder.addCase(getOrdersAsync.fulfilled, (state, action) => {
      state.ordersLoading = false;
      if (action.payload.success) {
        state.orders = action.payload;
      } else {
        state.ordersError = action.payload;
      }
    });
    builder.addCase(getOrdersAsync.rejected, (state, action) => {
      state.ordersLoading = false;
      state.ordersError = null;
    });

    builder.addCase(getOrdersAggregateAsync.pending, (state) => {
      state.ordersAggregateLoading = true;
      state.ordersAggregatesError = null;
    });
    builder.addCase(getOrdersAggregateAsync.fulfilled, (state, action) => {
      state.ordersAggregateLoading = false;
      if (action.payload.success) {
        state.ordersAggregate = action.payload;
      } else {
        state.ordersAggregatesError = action.payload;
      }
    });
    builder.addCase(getOrdersAggregateAsync.rejected, (state, action) => {
      state.ordersAggregateLoading = false;
      state.ordersAggregatesError = null;
    });
  },
});

export const { updateUser, clearState } = slice.actions;

export default slice.reducer;

export const getOrders = (data) => async (dispatch) => {
  if (!data?.sort_field && (!data?.page || data?.page === 1)) {
    dispatch(getOrdersAggregateAsync(data));
  }
  return dispatch(getOrdersAsync(data));
};

export const getOrdersAggregate = (data) => async (dispatch) => {
  return dispatch(getOrdersAggregateAsync(data));
};

export const exportOrdersCsv = (data) => async (dispatch) => {
  return dispatch(exportOrdersCsvAsync(data));
};

export const exportOrdersCsvAsyncType = (data) => async (dispatch) => {
  return dispatch(exportOrdersCsvAsyncTypeAsync(data));
};
