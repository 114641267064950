import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ENDPOINTS } from "@/utils/constants/apiConstants";

import { baseAPI } from "../services/fetchers";

export const addInventoryAsync = createAsyncThunk(
  "inventory/addInventoryAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.INVENTORY_WIDGET_ADD;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getInventoryAsync = createAsyncThunk(
  "inventory/getInventoryAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.INVENTORY_WIDGET_GET.replace("{id}", data);
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getInventoryListAsync = createAsyncThunk(
  "inventory/getInventoryListAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.INVENTORY_WIDGET_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const deleteInventoryAsync = createAsyncThunk(
  "inventory/deleteInventoryAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.INVENTORY_WIDGET_DELETE.replace("{id}", data);
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const cloneInventoryAsync = createAsyncThunk(
  "inventory/cloneInventoryAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.INVENTORY_WIDGET_CLONE.replace("{id}", data);
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const updateInventoryAsync = createAsyncThunk(
  "inventory/updateInventoryAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.INVENTORY_WIDGET_UPDATE;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const inventorySlice = createSlice({
  name: "inventory",
  initialState: {
    inventories: null,
    inventoriesLoading: false,
    inventoriesError: null,

    inventoryAdd: null,
    inventoryAddLoading: false,
    inventoryAddError: null,

    inventoryDetail: null,
    inventoryDetailLoading: false,
    inventoryDetailError: null,

    inventoryUpdate: null,
    inventoryUpdateLoading: false,
    inventoryUpdateError: null,

    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInventoryListAsync.pending, (state, action) => {
      state.inventories = null;
      state.inventoriesLoading = true;
    });
    builder.addCase(getInventoryListAsync.rejected, (state, action) => {
      state.inventoriesLoading = false;
      state.inventoriesError = action.error;
    });
    builder.addCase(getInventoryListAsync.fulfilled, (state, action) => {
      state.inventoryDetail = null;
      if (action.payload.success) {
        state.inventories = action.payload;
      }
      state.inventoriesLoading = false;
      state.inventoriesError = null;
    });

    builder.addCase(addInventoryAsync.pending, (state, action) => {
      state.inventoryAddLoading = true;
      state.inventoryAddError = null;
      state.inventoryAdd = null;
    });
    builder.addCase(addInventoryAsync.rejected, (state, action) => {
      state.inventoryAddLoading = false;
      state.inventoryAddError = action.error;
    });
    builder.addCase(addInventoryAsync.fulfilled, (state, action) => {
      state.inventoryAddLoading = false;
      state.inventoryAddError = null;
      if (action.payload.success) {
        state.inventoryAdd = action.payload;
      }
    });

    builder.addCase(getInventoryAsync.pending, (state, action) => {
      state.inventoryDetailLoading = true;
      state.inventoryDetailError = null;
      state.inventoryDetail = null;
    });
    builder.addCase(getInventoryAsync.rejected, (state, action) => {
      state.inventoryDetailLoading = false;
      state.inventoryDetailError = action.error;
    });
    builder.addCase(getInventoryAsync.fulfilled, (state, action) => {
      state.inventoryDetailLoading = false;
      state.inventoryDetailError = null;
      if (action.payload.success) {
        state.inventoryDetail = action.payload;
      }
    });

    builder.addCase(updateInventoryAsync.pending, (state, action) => {
      state.inventoryUpdateLoading = true;
      state.inventoryUpdateError = null;
      state.inventoryUpdate = null;
    });
    builder.addCase(updateInventoryAsync.rejected, (state, action) => {
      state.inventoryUpdateLoading = false;
      state.inventoryUpdateError = action.error;
    });
    builder.addCase(updateInventoryAsync.fulfilled, (state, action) => {
      state.inventoryUpdateLoading = false;
      state.inventoryUpdateError = null;
      if (action.payload.success) {
        state.inventoryUpdate = action.payload;
      }
    });
  },
});

export const addInventory = (data) => async (dispatch) => {
  return dispatch(addInventoryAsync(data));
};

export const getInventory = (data) => async (dispatch) => {
  return dispatch(getInventoryAsync(data));
};

export const getInventoryList = (data) => async (dispatch) => {
  return dispatch(getInventoryListAsync(data));
};

export const deleteInventory = (data) => async (dispatch) => {
  return dispatch(deleteInventoryAsync(data));
};

export const cloneInventory = (data) => async (dispatch) => {
  return dispatch(cloneInventoryAsync(data));
};

export const updateInventory = (data) => async (dispatch) => {
  return dispatch(updateInventoryAsync(data));
};

export const updateInventoryStatus = (data) => async (dispatch) => {
  return dispatch(updateInventoryAsync(data));
};

export default inventorySlice.reducer;
