import { Card, Tooltip } from "antd";
import PropTypes from "prop-types";
import React from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

import "./styles.less";

const TileAnalyticCard = (props) => {
  const { t } = useTranslation();
  const {
    name,
    price,
    color,
    toolTip,
    extra,
    count,
    isCount,
    isDecimal,
    isPercent,
    value,
    icon,
    backgroundColor,
    disabled,
  } = props;
  return (
    <Card className={`tile-analytic-card ${color}`}>
      <div className="tile-analytic-card-container">
        <div className="tile-analytic-card-right">
          <div
            className=" tile-analytic-card-image"
            style={
              disabled
                ? { backgroundColor: "#EDF0F5" }
                : { backgroundColor: backgroundColor }
            }
          >
            <img src={icon} alt="icon" />
          </div>
        </div>
        <div className="tile-analytic-card-left">
          <h3 className={`tac__text`}>
            <Tooltip title={toolTip} placement="left">
              {name}
            </Tooltip>
          </h3>
          <div className="tac__price">
            {extra ? (
              <b
                style={{ fontSize: "1.7em", color: "#7a7a7a" }}
              >{`${extra}: `}</b>
            ) : (
              ""
            )}
            {disabled ? (
              <i style={{ color: "#B0B6C1" }}>به زودی</i>
            ) : (
              <>
                <CountUp
                  decimal="."
                  decimals={isDecimal ? 2 : undefined}
                  delay={2}
                  end={price || count || value}
                />
                {!isCount && !isPercent ? <i>{t("general.Toman")}</i> : ""}
                {isPercent ? <i>{t("general.percent")}</i> : ""}
              </>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

TileAnalyticCard.propTypes = {
  name: PropTypes.string,
  price: PropTypes.any,
  count: PropTypes.any,
  isCount: PropTypes.bool,
  color: PropTypes.string,
  toolTip: PropTypes.string,
  extra: PropTypes.string,
  value: PropTypes.string,
  isDecimal: PropTypes.bool,
  isPercent: PropTypes.bool,
  icon: PropTypes.string,
  backgroundColor: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TileAnalyticCard;
