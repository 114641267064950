import { ConfigProvider } from "antd";
import "antd/dist/antd.less";
import localeEn from "antd/lib/locale/en_US";
import localeFa from "antd/lib/locale/fa_IR";
// import "antd/dist/antd.css";
import moment from "moment-jalaali";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { clarity } from "react-microsoft-clarity";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";

import { PUBLIC_ROUTES, getPrivateRoutes } from "@/app/routes";

import "./App.less";
import { useHookCheckVersion } from "./app/updateHandler";
import NotFound from "./components/main/404";
import ErrorLayout from "./components/main/ErrorLayout";
import AppLoading from "./components/main/Loading";
import PrivateLayout from "./components/main/PrivateLayout";
import PublicLayout from "./components/main/PublicLayout";
import { LANGUAGE, USER_TYPE } from "./utils/constants/apiConstants";
import { INTRACK_CONFIG } from "./utils/constants/configConstants";
import { objectR } from "./utils/helper";
import { useAccount, useAuth } from "./utils/hooks/useAuth";
import useConfig from "./utils/hooks/useConfig";

moment.locale("fa");

const App = () => {
  const { loading, defaultLang } = useSelector((state) => state.app);
  const { profile } = useSelector((state) => state.account);
  const { t, i18n } = useTranslation();
  const { isSSP } = useConfig();
  const navigate = useNavigate();

  useAuth();
  useHookCheckVersion();

  if (process.env.NODE_ENV === "production") {
    clarity.init("n4mktgpypz");
  }

  const { roleId, lang } = useAccount();

  useEffect(() => {
    document.title = "افـیلیو | پلتفرم همکاری در فروش";
  }, []);

  const privateRoutes = useMemo(() => {
    if (profile && profile.data && profile.data.user_type) {
      const uType = profile?.data?.user_type;
      const typeName = uType && objectR(USER_TYPE)[uType];
      return getPrivateRoutes(typeName);
    } else {
      navigate("/login");
    }
  }, [profile]);

  // useEffect(() => {
  //   if (!privateRoutes) {
  //     navigate("/login");
  //   }
  // }, [privateRoutes]);

  useEffect(() => {
    const lg = !roleId ? defaultLang || lang : lang;
    i18n.changeLanguage(lg, () => {
      if (lg === LANGUAGE.ENGLISH) {
        document.body.classList.add("ltr_cls");
      } else {
        document.body.classList.remove("ltr_cls");
      }
    });
  }, [defaultLang, lang]);

  useEffect(() => {
    if (!isSSP) {
      var inTrack_config = {
        app_key: INTRACK_CONFIG.APP_KEY,
        auth_key: INTRACK_CONFIG.AUTH_KEY,
        public_key: INTRACK_CONFIG.PUBLIC_KEY,
        debug: true,
      };
      (function (i, n, t, r, a, c, k) {
        const o = (i["InTrack"] = i["InTrack"] || {});
        i[a] =
          i[a] ||
          function () {
            (o.q = o.q || []).push(arguments);
          };
        const s = n.createElement(t);
        s.async = true;
        s.src = r;
        s.onload = function () {
          o.init(c);
        };
        const e = n.getElementsByTagName(t)[0];
        e.parentNode.insertBefore(s, k);
      })(
        window,
        document,
        "script",
        "//static1.intrack.ir/api/web/download/sdk/v1/inTrack.min.js?v=00",
        "Intk",
        inTrack_config
      );
    }
  }, [isSSP]);

  return loading ? (
    <AppLoading height="100vh" />
  ) : (
    <ConfigProvider
      locale={lang === LANGUAGE.PERSIAN ? localeFa : localeEn}
      direction={lang === LANGUAGE.PERSIAN ? "rtl" : "ltr"}
    >
      <Routes>
        <Route element={<PrivateLayout />}>
          {privateRoutes?.map((route, idx) => (
            <Route key={idx} path={`${route.path}`} element={route.component} />
          ))}
        </Route>

        <Route element={<PublicLayout />}>
          {PUBLIC_ROUTES.map((route, idx) => (
            <Route key={idx} path={route.path} element={route.component} />
          ))}
        </Route>

        <Route element={<ErrorLayout />}>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </ConfigProvider>
  );
};

export default App;
