import {
  MobileOutlined as IconMobile,
  DesktopOutlined as IconMonitor,
} from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Form, Input, Radio, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import _ from "lodash";
import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { useSelector } from "react-redux";

import { WidgetContext } from "@/pages/link/detail/widgetBuilder";
import { useAccount } from "@/utils/hooks/useAuth";
import { useWidgetType } from "@/utils/hooks/useWidgetType";

import InputColor from "../input/InputColor";
import SliderCarouselV2 from "./SliderCarouselV2";
import "./WidgetUiConfig.less";

const WidgetUiConfig = (props) => {
  const { onFinishMain } = props;
  const [form] = useForm();
  const [data, dispatch_] = useContext(WidgetContext);
  const { isCustom, isNew } = useWidgetType();

  const { isPublisher, isMerchant } = useAccount();

  const ViewTypeOptions = [
    {
      label: t("widget_builder.Row"),
      value: 1,
    },
    {
      label: t("widget_builder.Column"),
      value: 2,
      disabled: true,
    },
    {
      label: t("widget_builder.Grid"),
      value: 3,
      disabled: true,
    },
  ];

  const { smartWidgets, widgetSmartDetail, widgetDetail } = useSelector(
    (state) => state.productWidget
  );

  const styleConfig = _.get(
    widgetSmartDetail || widgetDetail,
    "data.product_widget_config.style",
    {}
  );

  useEffect(() => {
    const data_ = widgetDetail?.data || widgetSmartDetail?.data;

    if (data_) {
      const d = data_;
      const c = d?.product_widget_config;
      form.setFieldsValue({ name: c?.widget_title || d?.name });
      form.setFieldsValue({ color_button: c?.style?.color_button });
      form.setFieldsValue({ color_title_button: c?.style?.color_title_button });
      form.setFieldsValue({ showTitle: c?.show_title });
      form.setFieldsValue({ showPriceButton: c?.show_price });
      form.setFieldsValue({ showProductTitle: c?.show_product_title });
      form.setFieldsValue({ showProductButton: c?.show_product_button });
      setTimeout(() => onValuesChange(), 600);
    }
  }, [widgetSmartDetail, widgetDetail]);

  const widgetDemo = useMemo(() => {
    if (isCustom || isMerchant) {
      return {
        name: "",
        products: data?.product_context || [],
      };
    } else if (!isCustom) {
      let items_ = _.get(smartWidgets, "data.result", []);
      return items_.find((item) => item.Product_widget_id === data?.widget);
    }
  }, [smartWidgets]);

  const [x, forceUpdate] = useReducer((x) => x + 1, 0);

  const onFinish = (e) => {
    const formData = form.getFieldsValue();
    dispatch_({ type: "set_config_widget", value: formData });
  };

  const onValuesChange = (e) => {
    const formData = form.getFieldsValue();
    if (!_.isUndefined(formData.showTitle)) {
      const d = document.querySelector(".widget__box .inner_body h3");
      if (!formData.showTitle) {
        d.style.display = "none";
      }
      if (formData.showTitle) {
        d.style.display = "block";
      }
    }
    if (!_.isUndefined(formData.showProductTitle)) {
      const d = document.querySelectorAll(".widget__box .product__title");
      if (!formData.showProductTitle) {
        d.forEach((item) => {
          item.style.display = "none";
        });
      }
      if (formData.showProductTitle) {
        d.forEach((item) => {
          item.style.display = "block";
        });
      }
    }

    if (!_.isUndefined(formData.showProductButton)) {
      const d = document.querySelectorAll(
        ".widget__box .product__box a.ant-btn"
      );
      if (!formData.showProductButton) {
        d.forEach((item) => {
          item.style.display = "none";
        });
      }
      if (formData.showProductButton) {
        d.forEach((item) => {
          item.style.display = "block";
        });
      }
    }

    if (!_.isUndefined(formData.showPriceButton)) {
      const d = document.querySelectorAll(
        ".widget__box .product__box .product__price"
      );
      if (!formData.showPriceButton) {
        d.forEach((item) => {
          item.style.display = "none";
        });
      }
      if (formData.showPriceButton) {
        d.forEach((item) => {
          item.style.display = "block";
        });
      }
    }

    if (!_.isUndefined(formData.name)) {
      const d = document.querySelector(".widget__box .inner_body h3");
      d.innerHTML = formData.name;
    }
    dispatch_({ type: "set_config_widget", value: formData });
  };

  const widgetBoxRef = useRef();
  const changeView = (type) => {
    const d = widgetBoxRef?.current?.classList;
    if (d) {
      if (type === "MOBILE") {
        d.add("mobile_view");
      } else {
        d.remove("mobile_view");
      }
      forceUpdate();
    }
  };

  const extraMenu = (
    <div className="widget__box__switch">
      <Button
        icon={<IconMobile />}
        type={
          widgetBoxRef?.current?.classList?.contains("mobile_view")
            ? "primary"
            : "default"
        }
        onClick={() => changeView("MOBILE")}
      />
      <Button
        icon={<IconMonitor />}
        type={
          widgetBoxRef?.current?.classList?.contains("mobile_view")
            ? "default"
            : "primary"
        }
        onClick={() => changeView("DESKTOP")}
      />
    </div>
  );

  return (
    <Card
      className="widget-ui-config"
      title={t("widget_builder.displayConfig")}
      extra={extraMenu}
    >
      <div>
        <Row gutter={[24, 24]}>
          <Col xs={7}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onValuesChange={onValuesChange}
              initialValues={{
                viewTypeDesktop: 1,
                showTitle: true,
                showProductTitle: true,
                showProductButton: true,
                showPriceButton: true,
                name: widgetDemo?.name,
              }}
            >
              <div className="widget__box__toolbox">
                <div>
                  <h2>{t("widget_builder.view_in_desktop")}</h2>
                  <Form.Item name="viewTypeDesktop" noStyle>
                    <Radio.Group options={ViewTypeOptions} />
                  </Form.Item>
                </div>
                <div>
                  <label className="label-custom" htmlFor="w-name">
                    {t("widget_builder.change_name")}
                  </label>
                  <Form.Item name="name" noStyle>
                    <Input
                      id="w-name"
                      size="large"
                      placeholder={t("widget_builder.nameWidget")}
                      className="box-widget__name"
                      // style={{ width: 300 }}
                    />
                  </Form.Item>

                  <Form.Item name="showTitle" noStyle valuePropName="checked">
                    <Checkbox>{t("widget_builder.show_name")}</Checkbox>
                  </Form.Item>
                </div>
                <div className="box-widget__color">
                  <div>
                    <InputColor
                      name={"color_button"}
                      label={"widget_builder.color_button"}
                      form={form}
                      defaultColor={styleConfig.color_button || "#EE394D"}
                    />
                  </div>
                  <div>
                    <InputColor
                      name={"color_title_button"}
                      label={"widget_builder.color_title_button"}
                      form={form}
                      defaultColor={styleConfig.color_title_button || "#fff"}
                    />
                  </div>
                </div>

                <div className="config__list">
                  <Form.Item
                    name={"showProductTitle"}
                    noStyle
                    valuePropName="checked"
                  >
                    <Checkbox>
                      {t("widget_builder.show_product_title")}
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name={"showProductButton"}
                    noStyle
                    valuePropName="checked"
                  >
                    <Checkbox>
                      {t("widget_builder.show_product_button")}
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name={"showPriceButton"}
                    noStyle
                    valuePropName="checked"
                  >
                    <Checkbox>{t("widget_builder.show_price_button")}</Checkbox>
                  </Form.Item>
                </div>
              </div>
              <Button htmlType="submit" hidden id="submitId"></Button>
            </Form>
          </Col>
          <Col xs={17}>
            <div className="widget__box" ref={widgetBoxRef}>
              <div className="widget__box__toolbar"></div>
              <div className="widget__box__body">
                <div className="inner_body">
                  <h3>{widgetDemo?.name}</h3>
                  {widgetDemo?.products ? (
                    <SliderCarouselV2 data={widgetDemo?.products} />
                  ) : null}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

WidgetUiConfig.propTypes = {
  onFinishMain: "",
};

export default WidgetUiConfig;
