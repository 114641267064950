import { CircularProgress, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { containedLargePrimaryBtn } from "../app/helper";
// import { useTranslation } from "react-i18next";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const CustomizedButton = ({
  borderRadius = "8px",
  buttonProperties = containedLargePrimaryBtn,
  icon,
  isStartIcon,
  isEndIcon,
  disabled,
  onClick,
  children,
  autoFocus,
  width,
  hasType,
  type,
  loading,
  extraStyle,
}) => {
  //ToDO//
  const useStyles = makeStyles(() => ({
    root: {
      borderRadius: borderRadius,
      backgroundColor: buttonProperties.backgroundColor,
      borderColor: buttonProperties.borderColor,
      color: buttonProperties.textColor,
      fontWeight: buttonProperties.fontWeight,
      fontSize: buttonProperties.fontSize,
      padding: buttonProperties.padding,
      width: width ?? "max-content",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: buttonProperties.hoverColor,
        boxShadow: "none",
      },
    },
  }));
  const classes = useStyles();
  return (
    <Button
      className={clsx(classes.root, extraStyle)}
      disabled={disabled || loading}
      variant={buttonProperties.variant}
      onClick={onClick}
      startIcon={isStartIcon ? icon : null}
      endIcon={isEndIcon ? icon : null}
      type={hasType ? type : null}
      autoFocus={autoFocus}
    >
      {loading && (
        <CircularProgress color="#eee" size={16} style={{ marginLeft: 16 }} />
      )}
      {children}
    </Button>
  );
};

export default CustomizedButton;

CustomizedButton.propTypes = {
  buttonProperties: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  icon: PropTypes.element,
  isStartIcon: PropTypes.bool,
  isEndIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  hasType: PropTypes.bool,
  type: PropTypes.string,
  width: PropTypes.string,
  loading: PropTypes.bool,
  borderRadius: PropTypes.string,
  onClick: PropTypes.func,
  extraStyle: PropTypes.string,
};
