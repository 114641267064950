import React from "react";
import MainWrap from "../components/MainWrap";
import Reply from "./index";

const TicketReply = (props) => {
  return (
    <React.StrictMode>
      <MainWrap>
        <Reply {...props} />
      </MainWrap>
    </React.StrictMode>
  );
};

export default TicketReply;
