import { FrownOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";
import React from "react";

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

const myFallback = <FallbackComponent />;

class ErrorBoundaryMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidUpdate(preProps, preState) {
    // clear hasError of route changed
    if (this.state.hasError) {
      if (this.props.locationChanged !== preProps.locationChanged) {
        if (this.state.hasError === preState.hasError) {
          this.setState({ hasError: false });
        }
      }
    }
  }

  logErrorToMyService() {}

  componentDidCatch(error, errorInfo) {
    if (error) {
      this.setState({ hasError: true });
    }
    // You can also log the error to an error reporting service
    this.logErrorToMyService(error, errorInfo);
  }

  FallbackComponent() {
    return <div>An error has occurred</div>;
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      //   return <ErrorBoundary>xxx</ErrorBoundary>;
      //   return <Alert type="error" message={"Something went wrong."}></Alert>;
      return (
        <>
          {/* <Sentry.ErrorBoundary fallback={myFallback} showDialog> */}
          {/* <img src={ErrorDefault} style={{ width: "250px" }} /> */}
          <Result
            icon={<FrownOutlined className="icon-error" />}
            // title="500"
            subTitle={t("general.unHandleErrorMessage")}
            extra={
              <Button
                type="default"
                onClick={() => {
                  window.history.back();
                  this.setState({ hasError: false });
                  window.history.back();
                  return false;
                }}
              >
                {t("general.goBack")}
              </Button>
            }
          />
          {/* </Sentry.ErrorBoundary> */}
        </>
      );
    }

    // eslint-disable-next-line react/prop-types
    return <div>{this.props?.children}</div>;
  }
}

ErrorBoundaryMain.propTypes = {
  locationChanged: PropTypes.any,
  router: PropTypes.any,
  hasError: PropTypes.any,
  navigate: PropTypes.any,
};
export default ErrorBoundaryMain;
