// import { useLazyGetCurrentUserQuery } from "app/services/mock";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { accountUserDetail, refreshToken } from "@/app/local/accountSlice";
import { appLoading } from "@/app/local/appSlice";

import {
  LANGUAGE,
  USER_CONFIG_TYPE,
  USER_TYPE,
} from "../constants/apiConstants";

export const objectR = (obj) =>
  obj && Object.fromEntries(Object.entries(obj).map((a) => a.reverse()));

export const useAuth = () => {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const account = useSelector((state) => state.account);
  const user = account.profile?.data;
  const role = _.isFunction(objectR) && objectR(USER_TYPE)[user?.user_type];

  let tryState = 0;
  const getUserInfo = async () => {
    const resultProfile = await dispatch(accountUserDetail());
    if (resultProfile?.payload?.success) {
      return true;
    } else {
      if (tryState === 0) {
        const resultRefreshToken = await dispatch(refreshToken());
        if (resultRefreshToken?.payload?.success) {
          tryState = 1;
          return getUserInfo();
        }
      } else {
        // window.location.href = "/login";
      }
    }
  };

  useEffect(async () => {
    if (!role && !app.loading) {
      dispatch(appLoading(true));
      await getUserInfo();
      dispatch(appLoading(false));
    }
  }, [role]);

  return { role };
};

export const useAccount = () => {
  const account = useSelector((state) => state.account);
  const user = account.profile?.data;
  const userDetail = account.profile?.userDetail?.data;
  const role = objectR(USER_TYPE)[user?.user_type];
  let roleId = user?.user_type;

  if (user?.user_types) {
    // roleId = Math.max(...(user?.user_types || []));
  }
  const isAdmin =
    roleId === USER_TYPE.SUPER_ADMIN || roleId === USER_TYPE.ADMIN;
  const getConfig = user?.config;
  const findConfig = getConfig?.find(
    (item) => item.type === USER_CONFIG_TYPE?.Culture
  );
  const lang =
    findConfig?.value || localStorage.getItem("locale") || LANGUAGE.PERSIAN;
  const isMerchant = roleId === USER_TYPE.MERCHANT;
  const isPublisher = roleId === USER_TYPE.PUBLISHER;
  const isAdvertiser = roleId === USER_TYPE.ADVERTISER;

  return useMemo(
    () => ({
      user,
      role,
      roleId,
      isAdmin,
      userDetail,
      lang,
      isMerchant,
      isPublisher,
      isAdvertiser,
    }),
    [
      user,
      role,
      roleId,
      isAdmin,
      userDetail,
      lang,
      isMerchant,
      isPublisher,
      isAdvertiser,
    ]
  );
};
