import {
  DeleteOutlined as IconDelete,
  DownloadOutlined as IconDownload,
} from "@ant-design/icons";
import { Button, Card, Form, Modal, Popconfirm, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { t } from "i18next";
import _ from "lodash";
import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import { getFile } from "@/app/local/appSlice";
import {
  addInvoiceDocument,
  getInvoiceDocuments,
  removeInvoiceDocument,
} from "@/app/local/invoiceSlice";
import {
  FILE_FILE_TYPE,
  FILE_MEDIA_TYPE,
  FILE_OBJECT_TYPE,
} from "@/utils/constants/apiConstants";
import { dateFormat, getTimeStamp, saveFile } from "@/utils/helper";
import { useAccount } from "@/utils/hooks/useAuth";

import TableComponent from "../general/TableComponent";
import InputUpload from "../input/inputUpload";

const InvoiceDocuments = (props) => {
  const { isModal, setOpen, open } = props;
  const invoicePath = useMatch("/invoices/:id/:type");
  const params = invoicePath?.params;
  const dispatch = useDispatch();
  const [form] = useForm();
  const { isAdmin } = useAccount();
  const [pageSizeState, setPageSizeState] = useState(10);

  const { invoiceDocuments, invoiceDocumentsLoading, invoiceDocumentsError } =
    useSelector((state) => state.invoice);

  const invId = params?.id || open;

  const heads = [
    {
      title: t("invoice_document.id"),
      key: "doc_id",
    },
    {
      title: t("invoice_document.create_date"),
      key: "create_date",
    },
    {
      title: t("invoice_document.download"),
      key: "download",
    },
    {
      title: t("invoice_document.remove"),
      key: "remove",
    },
  ];

  const handlePagination = (e) => {
    setPageSizeState(e.pageSize);
  };

  useEffect(() => {
    dispatch(getInvoiceDocuments(invId));
  }, []);

  const downloadFile = async (fileId) => {
    const file = await dispatch(getFile(fileId));
    if (file.payload.success) {
      saveFile({
        fileName: `receipt-${getTimeStamp()}`,
        b64: file.payload?.data?.source,
        mType: file.payload?.data?.mime_type,
      });
    }
  };

  const removeItem = async (docFileId) => {
    const result = await dispatch(
      removeInvoiceDocument({
        invoiceId: invId,
        fileId: docFileId,
      })
    );
    if (result?.payload?.success) {
      message.success(t("invoice_document.deleted"));
      dispatch(getInvoiceDocuments(invId));
    }
  };

  const itemsList = useMemo(() => {
    const results = invoiceDocuments?.data?.map((item) => {
      const docFileId = item.file_id;
      return {
        doc_id: item.publisher_invoice_document_id,
        create_date: dateFormat(item.create_date, "ST"),
        download: (
          <a onClick={() => downloadFile(docFileId)}>
            <IconDownload />
          </a>
        ),
        remove: (
          <Popconfirm
            placement="top"
            title={t("general.sureForDelete")}
            onConfirm={async () => {
              removeItem(docFileId);
            }}
            okText={t("general.ok")}
            cancelText={t("general.no")}
          >
            <a>
              <IconDelete />
            </a>
          </Popconfirm>
        ),
      };
    });
    return results;
  }, [invoiceDocuments]);

  const items = _.get(invoiceDocuments, "data.total_items", 100);
  const pages = _.get(invoiceDocuments, "data.total_pages", 1);
  const pageSize = pageSizeState || 10;

  const tableInfo = {
    items,
    pages,
    pageSize,
    serverPagination: false,
  };

  const onFinish = async () => {
    const paymentDoc = form.getFieldValue("payment_doc");
    if (!paymentDoc) {
      return;
    }
    const [file] = paymentDoc;

    const response = await dispatch(
      addInvoiceDocument({
        invoiceId: invId,
        fileId: file?.response?.file_id,
      })
    );
    if (response?.payload?.success) {
      message.success({
        content: t("general.saveSuccessFully"),
      });
      dispatch(getInvoiceDocuments(invId));
    } else {
      message.error({
        content:
          response?.payload?.Message || t("general.unHandleErrorMessage"),
      });
    }
  };

  const mainContent = (
    <div>
      <div>
        <p>{t("invoice_document.please_upload_documents_invoice")}</p>
        <Form onFinish={onFinish} form={form} layout="vertical">
          <InputUpload
            label={t("invoice_document.payment_doc")}
            name={"payment_doc"}
            objectId={invId}
            objectType={FILE_OBJECT_TYPE.INVOICE}
            mediaType={FILE_MEDIA_TYPE.DOCUMENT}
            fileType={FILE_FILE_TYPE.PublisherInvoiceDocument}
            required
            form={form}
            // files={[tmpData?.paper_contract_fileId]}
            accept="mix"
          />
          <Button type="primary" htmlType="submit">
            {t("invoice_document.submit")}
          </Button>
        </Form>
      </div>

      {itemsList?.length || invoiceDocumentsLoading ? (
        <>
          <TableComponent
            loading={invoiceDocumentsLoading}
            heads={heads}
            data={itemsList}
            info={tableInfo}
            handlePagination={handlePagination}
          />
        </>
      ) : null}
    </div>
  );

  if (isModal) {
    return (
      <Modal
        visible={open}
        onCancel={() => {
          setOpen();
        }}
        title={
          isAdmin
            ? t("invoice_document.title_doc_admin")
            : t("invoice_document.title_doc")
        }
        width="40vw"
        style={{ minWidth: "300px" }}
        footer={null}
        centered
      >
        {mainContent}
      </Modal>
    );
  }
  return <Card title={t("invoice_document.title_doc")}>{mainContent}</Card>;
};

InvoiceDocuments.propTypes = {
  isModal: "",
  open: "",
  setOpen: "",
};

export default InvoiceDocuments;
