export default {
  selected_item: "مورد انتخاب شده",
  an_error_occurred: "خطایی پیش آمد!",
  "1ord": "اول",
  "2ord": "دوم",
  filling_this_field_is_required: "‌پر کردن این فیلد ضروری است",
  print_or_download: "پرینت / دانلود",
  add_DKP: "افزودن کالا",
  update: "به روز رسانی",
  update_user_data: "به روز رسانی اطلاعات کاربر",
  plp_code: "کد لیست کالا (PLP)",
  new: "جدید",
  npsTitle: "چقدر از تعییرات جدید در صفحه داشبورد رضایت دارید؟  ",
  satisfying_message:
    "چه کاری می‌توانیم انجام دهیم تا استفاده از پنل ادورج را برای شما آسان‌تر کنیم؟  ",
  unsatisfying_message:
    "از اینکه تجربه خوشایندی نداشتید متاسفیم. لطفا دلیل خود را با ما به اشتراک بگذارید.",
  extremely_low: "خیلی کم",
  extremely_high: "خیلی زیاد",
  submit_comment: "ثبت دیدگاه",
  please_select_an_option: "لطفا یک گزینه را انتخاب کنید",
  retry: "تلاش مجدد",
  expired: "منقضی",
  active: "فعال",
};
