import {
  CopyOutlined as IconCopy,
  InfoCircleFilled as IconInfo,
} from "@ant-design/icons";
import { Alert, Button } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { codeBase } from "@/utils/helper";

import "./InfoAdPlace.less";

const InfoAdPlace = () => {
  const { t } = useTranslation("ssp");
  const [copied, setCopied] = useState(false);
  const copyHeadTag = () => {
    navigator.clipboard.writeText(codeBase);
    setCopied(true);
  };

  return (
    <Alert
      className="info-ad-place"
      message={
        <div>
          <div className="title">
            <IconInfo className="icon-info" />
            <b>راهنمای جایگاه</b>
          </div>
          <p>
            برای استفاده از جایگاه، ابتدا کد اسکریپت را کپی کرده و در {"<head>"}{" "}
            سایت خود قرار دهید.
          </p>
        </div>
      }
      action={
        <Button
          icon={<IconCopy />}
          onClick={copyHeadTag}
          type={copied ? "ghost" : "primary"}
        >
          {copied ? t("copied") : t("copyCode")}
        </Button>
      }
    />
  );
};

export default InfoAdPlace;
