import { Col, Form, Input, InputNumber, Row, Select, message } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { clarity } from "react-microsoft-clarity";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import { appLoadingBar, getCities } from "@/app/local/appSlice";
import { merchantSetCompanyInfo } from "@/app/local/merchantSlice";
import { publisherSetCompanyInfo } from "@/app/local/publisherSlice";
import { getProfileData } from "@/pages/profile";
import { Privileges } from "@/utils/constants/accessCenter";
import { COMPANY_TYPE, USER_TYPE } from "@/utils/constants/apiConstants";
import {
  findInfoError,
  getLastError,
  objectR,
  setInputPrivileges,
  validation,
} from "@/utils/helper";

import FooterWizard from "./FooterWizard";

const StepCompanyInfo = (props) => {
  const isProfile = useMatch("/profile");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [respError, setRespError] = useState(null);

  const profile = getProfileData();

  const userType = _.get(profile, "data.user_type");
  const userInfo = _.get(profile, "userDetail");

  const { stepCompany, loading, error } = useSelector((state) =>
    userType === USER_TYPE.PUBLISHER || userType === USER_TYPE.ADVERTISER
      ? state.publisher
      : state.merchant
  );

  useEffect(() => {
    if (!_.isEmpty(userInfo)) {
      form.setFieldsValue({
        user: {
          companyName: userInfo.company_name,
          companyType: objectR(COMPANY_TYPE)[userInfo.company_type],
          registerNumber: userInfo.register_number,
          nationalId: String(userInfo?.national_id || ""),
          economicalNumber: userInfo.economical_number,
          firstNameLegal: userInfo.legal_representative_first_name,
          lastNameLegal: userInfo.legal_representative_last_name,
        },
      });

      if (!isProfile) {
        setInputPrivileges(
          "user",
          Object.keys(Privileges.publisher.profile),
          form
        );
      }
    }
  }, [userInfo]);

  useEffect(() => {
    dispatch(appLoadingBar(loading));
  }, [loading]);

  const successFullyDone = () => {
    message.success({
      content: t("general.saveSuccessFully"),
      key: "publisher",
    });
    if (_.isFunction(props?.goNextStep)) {
      props.goNextStep(1);
    }
  };

  const validateMessages = {
    required: t("required"),
    types: {
      email: "${label} " + `${t("general.emailNotValid")}`,
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onFinish = async (values) => {
    const {
      companyName,
      registerNumber,
      companyType,
      nationalId,
      economicalNumber,
      firstNameLegal,
      lastNameLegal,
    } = values.user;

    clarity.setTag("register", "step-1");

    const serverData = {
      //   user_id: uId,
      company_name: companyName,
      register_number: registerNumber,
      company_type: companyType,
      national_id: nationalId,
      economical_number: economicalNumber,
      legal_representative_first_name: firstNameLegal,
      legal_representative_last_name: lastNameLegal,
    };
    dispatch(appLoadingBar(true));
    let resp;
    if (userType === USER_TYPE.PUBLISHER || userType === USER_TYPE.ADVERTISER) {
      resp = await dispatch(publisherSetCompanyInfo(serverData));
    } else if (userType === USER_TYPE.MERCHANT) {
      resp = await dispatch(merchantSetCompanyInfo(serverData));
    }
    if (resp?.payload?.success) {
      successFullyDone();
    } else {
      const keyName = findInfoError(resp?.payload?.data);
      const errorMessage =
        resp?.payload?.Message || getLastError(resp?.payload);
      message.error({
        content: errorMessage,
        key: "publisher",
      });
      setRespError(keyName);
    }
  };

  const onValuesChange = (e) => {
    if (e.user?.province || e.pvId) {
      dispatch(
        getCities({
          id: e.user?.province || e.pvId,
        })
      );
    }
  };

  return (
    <>
      <Form
        form={form}
        name="formStepOne"
        onFinish={onFinish}
        validateMessages={validateMessages}
        // className="afl-form"
        layout="vertical"
        // labelCol={{
        //   span: 24,
        // }}
        // wrapperCol={{
        //   md: 24,
        // }}
        onValuesChange={onValuesChange}
        initialValues={{
          user: {},
        }}
      >
        <Row>
          <Col md={props.md}>
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <Form.Item
                  name={["user", "companyName"]}
                  label={t("user.companyName")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name={["user", "registerNumber"]}
                  label={t("user.registerNumber")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name={["user", "companyType"]}
                  label={t("user.companyType")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select placeholder={t("general.select")}>
                    {Object.keys(COMPANY_TYPE).map((key) => (
                      <Select.Option key={key}>
                        {t("user.company." + key)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name={["user", "nationalId"]}
                  label={t("user.nationalId")}
                  rules={[
                    {
                      required: true,
                      len: 11,
                      max: 11,
                      // message: "xxxx",
                    },
                    () => ({
                      validator(_a, value) {
                        if (validation(value, "VALID_NATIONAL_ID")) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(t("general.notValidNumber"))
                        );
                      },
                    }),
                  ]}
                  hasFeedback={
                    respError?.includes("national_id") ? true : false
                  }
                  validateStatus={respError?.includes("national_id") && "error"}
                >
                  <Input maxLength="11" />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name={["user", "economicalNumber"]}
                  label={t("user.economicalNumber")}
                  rules={[
                    {
                      // required: true,
                      len: 12,
                      max: 12,
                    },
                    () => ({
                      validator(_a, value) {
                        if (
                          !value ||
                          validation(value, "VALID_ECONOMICAL_ID")
                        ) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(t("general.notValidNumber"))
                        );
                      },
                    }),
                  ]}
                  hasFeedback={
                    respError?.includes("economical_number") ? true : false
                  }
                  validateStatus={
                    respError?.includes("economical_number") && "error"
                  }
                >
                  <Input maxLength="12" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <Form.Item
                  name={["user", "firstNameLegal"]}
                  label={t("user.firstNameLegal")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name={["user", "lastNameLegal"]}
                  label={t("user.lastNameLegal")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <br />
            <FooterWizard
              loading={loading}
              current={0}
              goNextStep={props.goNextStep}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

StepCompanyInfo.propTypes = {
  goNextStep: PropTypes.func,
  md: PropTypes.number,
};
StepCompanyInfo.defaultProps = {
  md: 18,
};

export default StepCompanyInfo;
