import { PrinterOutlined as IconPrint } from "@ant-design/icons";
import { Button } from "antd";
import { t } from "i18next";

const ButtonPrint = () => {
  return (
    <Button
      icon={<IconPrint />}
      type="primary"
      ghost
      style={{ background: "#fff" }}
      className="print-button"
      onClick={() => window.print()}
    >
      {t("general.print")}
    </Button>
  );
};

export default ButtonPrint;
