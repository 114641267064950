import { Form, Select } from "antd";
import { t } from "i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPublisherActiveList } from "@/app/local/adminPublisherSlice";
import { LEGAL_STATUS } from "@/utils/constants/apiConstants";
import { useAccount } from "@/utils/hooks/useAuth";

const SelectPublisher = (props) => {
  const { name, label, rules, placeholder, size, mode, legal, all, showID } =
    props;
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState();
  const { isAdmin } = useAccount();

  const { users, loading, error } = useSelector(
    (state) => state.adminPublisher
  );

  useEffect(() => {
    //  v4.9.71 and before
    // dispatch(getPublisherActiveList({ all: !!all }));
    //  v4.9.72 and after
    dispatch(getPublisherActiveList({ searchTerm: searchTerm }));
  }, [searchTerm]);

  const publishersList = useMemo(() => {
    let users_ = _.get(users, "data", []);
    if (_.isArray(users_)) {
      users_ = users_.filter((user) => {
        if (user?.first_name || user?.company_name) return true;
      });
      if (legal) {
        users_ = users_.filter((item) => item.legal_status === legal);
      }
      return users_;
    }
    return [];
  }, [users]);

  const getOptionText = (item) => {
    const p = isAdmin || showID ? `${item.publisher_id}` : null;
    const m =
      item.legal_status === LEGAL_STATUS.PRIVATE
        ? `${item?.first_name || t("unknown")} ${item?.last_name || ""}`
        : item.company_name;

    if (p) {
      return `${p}- ${m}`;
    }
    return m;
  };

  return (
    <Form.Item name={name}>
      <Select
        maxTagCount="responsive"
        loading={loading}
        allowClear
        placeholder={t("general.selectPublisher")}
        showSearch
        // disabled={loading}
        mode={mode}
        showArrow
        // size="large"
        onSearch={_.debounce((input) => {
          setSearchTerm(input);
        }, 500)}
        filterOption={(input, option) => {
          try {
            const d =
              option.key?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0 ||
              option.children?.indexOf(input) >= 0;
            if (d) return d;
          } catch (e) {
            const cashing = "";
          }
        }}
      >
        {publishersList?.map((item) => (
          <Select.Option key={item.publisher_id} value={item.publisher_id}>
            {getOptionText(item)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

SelectPublisher.propTypes = {
  mode: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  rules: PropTypes.array,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  legal: PropTypes.string,
  all: PropTypes.bool,
  showID: PropTypes.bool,
};

export default SelectPublisher;
