// import { updateUser } from "app/local/user";
// import { PRIVATE_BASE_PATH } from "app/routes";
import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  Form,
  Input,
  Row,
  message,
} from "antd";
import Title from "antd/lib/typography/Title";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  accountLogin,
  accountOldUser,
  clearState,
} from "@/app/local/accountSlice";
import LoginImage from "@/assets/images/login.svg";
import LogoImage from "@/assets/images/logo-white.svg";
import MaskInput from "@/components/input/maskInput";
import { USER_CONFIG_TYPE } from "@/utils/constants/apiConstants";
import { MASK_MOBILE_INPUT } from "@/utils/constants/configConstants";
import { getLocalLang, validation } from "@/utils/helper";

import AuthLayout from "./authLayout";

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { error, loading } = useSelector((state) => state.account);

  const checkIsOldUser = async (username) => {
    if (!validation(username, "MOBILE")) {
      const accountOldUserCall = await dispatch(accountOldUser(username));
      if (accountOldUserCall?.payload?.success) {
        const data = accountOldUserCall?.payload?.data;
        const isNeedResetPassword = data?.config?.find(
          (item) => item.type === USER_CONFIG_TYPE.FirstLogin
        );
        if (isNeedResetPassword?.value !== "done") {
          navigate("/recovery");
          return;
        }
        // username = "0" + data?.mobile;
      }
    }
  };

  const onFinish = async (e) => {
    try {
      // first check old login
      let username = e.username.replaceAll(" ", "").trim();
      // const isOldUser = await checkIsOldUser(username);
      // const phone = e.phone.replaceAll(" ", "").trim();
      const result = await dispatch(
        accountLogin({
          user_name: username,
          password: e.password,
          remember_me: e.remember,
        })
      );
      if (!result?.payload?.success) {
        message.error({
          content: result?.payload?.Message || t("auth.errorInLogin"),
          key: "login",
        });
      }
    } catch (err) {
      const catching = "catch";
    }
  };

  const navigateTo = (name) => {
    dispatch(clearState());
    navigate(name);
  };

  const lang = getLocalLang();

  return (
    <AuthLayout>
      <>
        <div className="auth__image">
          <div className="image__bg">
            <img src={LoginImage} />
          </div>
          <div className="image__info">
            <img src={LogoImage} className="logo" />
            <p>{t("auth.registerText")}</p>
          </div>
        </div>
        <Form
          className="auth__form auth__form--login"
          name="basic"
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Title level={4} className={"auth__title"}>
            {t("auth.login")}
          </Title>
          <br />

          <ConfigProvider direction="ltr">
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: t("required"),
                },
              ]}
              style={{ direction: "ltr" }}
            >
              <MaskInput
                mask={MASK_MOBILE_INPUT}
                placeholder={t("auth.phone")}
                size="large"
                autoFocus
              />
            </Form.Item>

            <Form.Item
              // label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: t("required"),
                },
              ]}
              className="auth__password"
              // style={{ direction: "ltr" }}
            >
              <Input.Password
                size="large"
                style={{ textAlign: "center" }}
                placeholder={t("auth.password")}
              />
            </Form.Item>
          </ConfigProvider>

          <Row>
            <Col xs={12}>
              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                  span: 24,
                }}
              >
                <Checkbox>{t("auth.rememberMe")}</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                style={{ margin: 0, textAlign: "left" }}
                wrapperCol={{
                  span: 24,
                }}
                className={"auth__forgot"}
              >
                <a onClick={() => navigateTo("/forgot")}>
                  <b>{t("auth.forgot")}</b>
                </a>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            wrapperCol={{
              // offset: 8,
              span: 16,
            }}
            className="auth__actions"
          >
            <Button
              size="large"
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              {t("auth.login")}
            </Button>
            <span className="space-or" />
            <Button size="large" onClick={() => navigateTo("/register")}>
              {t("auth.register")}
            </Button>
          </Form.Item>
        </Form>
      </>
    </AuthLayout>
  );
};

export default Login;
