import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { isRejectedWithValue } from "@reduxjs/toolkit";
// import { SIGN_IN_ROUTE } from "../staticRoutes";
import i18next from "i18next";
// import { notification } from "../helpers";

export const rootApiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/web/",
    prepareHeaders: (headers, api) => {
      const state = api.getState();
      const projectId = state.user.profile?.projectId;
      headers.set("Content-Type", "application/json");
      if (projectId) {
        headers.set("project-id", projectId);
      }
      return headers;
    },
  }),
  tagTypes: [""],
  endpoints: () => ({}),
});

export const rtkQueryErrorHandler = () => (next) => (action) => {
  const UNAUTHORIZED = "UNAUTHORIZED";
  if (isRejectedWithValue(action)) {
    const error = action.payload;
    if (
      error?.statusText?.toUpperCase() === UNAUTHORIZED ||
      error?.data?.status === UNAUTHORIZED
    ) {
      // window.location = SIGN_IN_ROUTE;
    }
    error.errMsg =
      error?.data?.errorMessage ||
      error?.errorMessage ||
      error?.data?.message ||
      error?.data?.error ||
      (!error?.data?.errors?.[0]?.key &&
        error?.response?.data?.errors?.[0]?.message) ||
      i18next.t("an_error_occurred");

    if (error?.response?.config?.hasError) {
      // notification(error.errMsg, "error");
    }
  }
  return next(action);
};
