import { Button, Form, InputNumber, Slider } from "antd";
import { useForm } from "antd/es/form/Form";
import _ from "lodash";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FilterContext } from "../widgetProductsSelector";

const RangePriceSlider = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [context, dispatch_] = useContext(FilterContext);
  const [options, setOptions] = useState();
  // const [price, setPrice] = useState();

  const { products, productsLoading, productsError } = useSelector(
    (state) => state.website
  );

  const rangePrice = _.get(products, "data.additional_data", {});
  const { min_price, max_price } = rangePrice;

  useEffect(() => {
    if (!options && !_.isEmpty(rangePrice)) {
      const min_ = min_price / 10;
      const max_ = max_price / 10;

      const marks = {
        mark_: {
          [min_]: t("product.cheapest"),
          [max_]: t("product.expensive"),
        },
        min_price: min_,
        max_price: max_,
      };
      setOptions(marks);
      form.setFieldsValue({ slider: [min_, max_] });
      form.setFieldsValue({ priceTo: max_price, priceFrom: min_price });
    }
  }, [rangePrice]);

  useEffect(() => {
    if (context?.reset_price) {
      form.setFieldsValue({ slider: [options?.min_price, options?.max_price] });
    }
  }, [context?.reset_price]);

  const onValuesChange = (e) => {
    if (e.slider) {
      const [fm, to] = e.slider;
      form.setFieldsValue({ priceFrom: fm, priceTo: to });
      // dispatch_({ type: "set_price", value: [fm * 10, to * 10] });
    }
    const rPrice = form.getFieldValue("slider");

    let range_;
    if (e.priceTo) {
      range_ = [rPrice[0], e.priceTo];
      form.setFieldsValue({ slider: range_ });
    }
    if (e.priceFrom) {
      range_ = [e.priceFrom, rPrice[1]];
      form.setFieldsValue({ slider: range_ });
    }
  };

  const onFinish = (e) => {
    dispatch_({ type: "set_price", value: [e.priceFrom, e.priceTo] });
  };

  return (
    <div className={"rangePrice"}>
      <Form form={form} onValuesChange={onValuesChange} onFinish={onFinish}>
        <div className="sliderMarksWrapper">
          <span>{t("product.cheapest")}</span>
          <span>{t("product.expensive")}</span>
        </div>
        <Form.Item name="slider">
          <Slider
            step={100}
            range
            min={options?.min_price}
            max={options?.max_price}
            // marks={options?.mark_}
          />
        </Form.Item>
        <Form.Item name="priceFrom">
          <InputNumber
            placeholder={t("general.currency")}
            // formatter={(value) => formatterNumber(value)}
            // formatter={(value) =>
            //   value ? `${value}`.replace(CURRENCY_FORMAT_REGEX, ",") : ""
            // }
            prefix={t("general.from")}
            addonAfter={t("general.currency")}
            min={0}
            type="number"
          />
        </Form.Item>
        <Form.Item name="priceTo">
          <InputNumber
            placeholder={t("general.currency")}
            // formatter={(value) => formatterNumber(value)}
            // formatter={(value) =>
            //   value ? `${value}`.replace(CURRENCY_FORMAT_REGEX, ",") : ""
            // }
            prefix={t("general.to")}
            addonAfter={t("general.currency")}
            min={0}
            type="number"
          />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          {t("dashboard.doFilter")}
        </Button>
      </Form>
    </div>
  );
};

export default RangePriceSlider;
