import { ArrowRightOutlined as IconBack } from "@ant-design/icons";
import { Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";

import useQuery from "@/utils/hooks/useQuery";

import { PlaceContext } from "../AdPlaceBuilder";
import "./ActionFooter.less";
import { STEPS } from "./PlaceStepper";

const ActionFooter = () => {
  const [context, dispatchContext] = useContext(PlaceContext);
  const { t } = useTranslation("ssp");
  const placeId = useMatch("places/:id").params?.id;
  let query = useQuery();
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [nextDisabled, setNextDisabled] = useState(true);
  const cuIndex = STEPS.findIndex((item) => item.key === context.currentStep);

  const clickSubmitButton = () => {
    const btn = document.querySelector("#submitButton");
    btn.click();
  };
  const handleNext = () => {
    if (context.currentStep === STEPS[0].key) {
      if (!context.name || !context.media) {
        clickSubmitButton();
        return;
      }
    }
    // Check is last step
    if (context.currentStep === STEPS[3].key) {
      nav("/places");
      return;
    }
    const nextStep = cuIndex + 1;
    if (nextStep <= STEPS.length) {
      if (context.currentStep === STEPS[2].key) {
        // AD PLACE : CALL API
        clickSubmitButton();
        return;
      }

      // only next step
      const nextIndex = STEPS[nextStep];
      dispatchContext({ type: "currentStep", value: nextIndex.key });
      setSearchParams({ step: cuIndex + 2 });

      goNextStep(nextStep);
    }
  };

  const goNextStep = (nextStep) => {
    const nextIndex = STEPS[nextStep];
    dispatchContext({ type: "currentStep", value: nextIndex.key });
    setSearchParams({ step: cuIndex + 2 });
  };

  const goPreStep = () => {
    if (cuIndex - 1 >= 0) {
      const step = STEPS[cuIndex - 1];
      dispatchContext({ type: "currentStep", value: step.key });
      setSearchParams({ step: cuIndex });
    }
  };

  useEffect(() => {
    setNextDisabled(true);
    // in step 1 and currentStep must selected
    if (context.currentStep === STEPS[0].key) {
      setNextDisabled(false);
    }

    // in step 2 and format must selected
    if (context.currentStep === STEPS[1].key) {
      if (context.format) {
        setNextDisabled(false);
      }
    }

    // in step 3,4 validation is done with form
    if (
      context.currentStep === STEPS[2].key ||
      context.currentStep === STEPS[3].key
    ) {
      setNextDisabled(false);
    }
  }, [context, placeId]);

  const backState =
    !query.get("step") ||
    query.get("step") === "1" ||
    context.currentStep === STEPS[3].key;

  return (
    <div className="ActionFooter">
      <Button
        size="large"
        // disabled={backState}
        hidden={backState}
        onClick={goPreStep}
        icon={<IconBack />}
      ></Button>
      <Button
        size="large"
        type="primary"
        disabled={nextDisabled}
        onClick={handleNext}
      >
        {context.currentStep === STEPS[3].key
          ? t("place.Finish")
          : t("place.NextStep")}
      </Button>
    </div>
  );
};

export default ActionFooter;
