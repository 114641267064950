const faIRSSPTranslation = {
  general: {
    sureForClear: "آیا مطمئن هستید؟",
    no: "خیر",
    ok: "بله",
    delete: "حذف",
    sureForDelete: "این ایتم حذف شود؟",
    sureToSetActive: "برای فعال سازی، مطمئن هستید؟",
    sureToUnsetActive: "برای غیرفعال سازی، مطمئن هستید؟",
  },
  dashboard: {
    ctr: "CTR",
    click: "کلیک",
    view: "نمایش",
    imp: "بازدید",
    fraud: "تقلب",
    total_view: "کل نمایش ها",
    fraud_view: "نمایش تقلبی",
    total_imp: "کل بازدیدها",
    fraud_imp: "بازدید تقلبی",
    total_click: "کل کلیک ها",
    fraud_click: "کلیک تقلبی",
    total_ctr: "CTR کل",
    fraud_ctr: "CTR تقلبی",
    income: "درآمد",
    toman: "تومان",
    compare_last_week: "مقایسه درآمد هفته اخیر",
    average_income: "میانگین قیمت مزایده",
    performance_media: "روند عملکرد رسانه",
    overview: "آمار کلی",
    fill_rate: "نرخ پر شدن",
    win_rate: "نرخ برنده شدن",
    total_fill_rate: "کل نمایش‌ها ",
    fraud_fill_rate: "نمایش تقلبی",
    total_win_rate: "کل نرخ برنده شدن",
    fraud_win_rate: "کل برنده شدن تقلبی",
  },
  formats: {
    InFeed: "میان محتوا",
    InFeedDesc:
      "شامل تصویر و متن است و مابین پاراگراف های متنی وبسایت قرار میگیرد. عمودی یا افقی بودن کارت ها به صورت ریسپاسیو تنظیم میشود.",
    InArticle: "میان محصولی",
    InArticleDesc:
      "شامل تصویر و متن است و مابین پاراگراف های متنی وبسایت قرار میگیرد. عمودی یا افقی بودن کارت ها به صورت ریسپاسیو تنظیم میشود.",
    Multiplex: "چندتایی",
    MultiplexDesc:
      "شامل تصویر و متن است که میتواند در انتهای مطلب، ابتدای مطلب یا سایدبار قرار بگیرد و تعداد سطر و ستون های آن قابل تنظیم است.",
    Float: "شناور",
    FloatDesc:
      "به صورت نوتیفیکیشن از سمت پایین صفحه وارد می شود. این تبلیغ شناور است و با اسکرول کاربر در جایگاه خود ثابت می ماند. ",
    Catfish: "چسبان افقی",
    CatfishDesc:
      "این نوع تبلیغ در قالب نوار باریک افقی در پایین یا بالای صفحه قرار میگیرد و همیشه در دید مخاطب است.",
    Fixed: "ثابت",
    FixedDesc:
      "این تبلیغ به صورت ثابت در سایدبار سمت چپ یا راست صفحه قرار میگیرد.",
    FixedDescBanner:
      "در این نوع تبلیغ بنری، سایزهای مختلف بنر را میتوانید بر اساس نیاز خود انتخاب کنید.",
    PushDown: "پاپ آپ PUSHDOWN",
    PushDownDesc:
      "بعد از اسکرول کاربر به پایین صفحه این پاپ آپ نمایش داده خوهد دش.",
    Popup: "پاپ آپ ساده",
    PopupDesc: "بعد از چند ثانیه از لود شدن سایت به نمایش در می آید.",
    Slider: "اسلایدر",
    SliderDesc:
      "شامل چند تبلیغ متوالی (حداکثر ۴ عدد) که همواره در حال عوض شدن است. کاربر هم میتواند با فلش های چپ و راست، تبلیغ های بعدی و قبلی را ببیند.",
  },
  auth: {
    registerText: "پلتفرم مدیریت فضای نمایش",
    adv_intro: `
      با وب سایت خود کسب درآمد کنید. با ایجاد حساب کاربری
      میتوانید مدیریت فضای تبلیغاتی وب سایت خود را با ما بسپارید و کسب درآمد کنید.`,
  },
  place: {
    NextStep: "مرحله بعدی",
    Finish: "پایان",
    PlaceNew: "افزودن جایگاه جدید",
    PlaceEdit: "ویرایش جایگاه",
    typePlaceHeader: "انتخاب نوع تبلیغات",
    typePlaceHeaderContent: "نوع تبلیغات دلخواه خود را مشخص کنید:",
    FormatPlaceTitleNative: "مدل جایگاه - همسان",
    FormatPlaceTitleBanner: "مدل جایگاه - بنری",
    infoPlace: "اطلاعات جایگاه",
    infoPlaceContent:
      "جهت ایجاد جایگاه تبلیغاتی، ابتدا اطلاعات زیر را تکمیل کنید:",
    FormatPlaceTitleDesc:
      "نوع جایگاه تبلیغاتی خود را از بین گزینه های زیر انتخاب نمایید:",
    NativeAds: "تبلیغات همسـان",
    NativeAdsDesc:
      "به تبلیغاتی گفته می شوند که از نظر ظاهری (رنگ، فونت و ...) براساس دیزاین پترن وب سایت شما نمایش داده میشود",
    BannerAds: "تبلیغات بنــری",
    BannerAdsDesc:
      "یک تصویر گرافیگی جذاب در ابعاد مختلف وب و موبایل است که در در دو مدل ثابت و متحرک قابل اجرا است",
    steps: {
      selectTypePlace: "نوع تبلیغات",
      selectFormatPlace: "مدل جایگاه",
      configPlace: "تنظیمات جایگاه",
      generateCode: "تولید کد",
    },
    ConfigPlaceTitle: "تنظیمات جایگاه",
    ConfigPlaceTitleIntro: "تنظیمات جایگاه",
    WhichMediaShown: "رسانه نمایش دهنده تبلیغ",
    placeName: "نام جایگاه تبلیغاتی",
    placeHelp: "برای اشاره به جایگاه در آمار و گزارشات. مثلا : سینگل بلاگ",
    placeNameHolder: "نام جایگاه ...",
    placeTitle: "عنوان جایگاه",
    deviceType: "دستگاه نمایش دهنده",
    inventory_id: "شناسه",
    name: "نام جایگاه",
    type: "نوع جایگاه",
    format: "نوع نمایش",
    device_type: "دستگاه/ها",
    bid_floor: "مبلغ مناقصه",
    max_item_count: "حداکثر تعداد نمایش در دسکتاپ",
    max_item_count_is_8: "حداکثر تعداد مجاز: 8",
    max_item_count_is_2: "حداکثر تعداد مجاز: 4",
    max_mobile_item_count: "حداکثر تعداد نمایش در موبایل",
    responsive_type: "رسپانسیو",
    start_delay_second: "مدت زمان تا شروع نمایش (ثانیه)",
    enable_to_close_delay_second: "مدت زمان نمایش تا امکان بستن (ثانیه)",
    size: "ابعاد بنر",
    position_type: "موقعیت بنر",
    backToDefaultSetting: "بازگشت به تنظیمات پیش فرض",
    create_date: "تاریخ ایجاد",
    width: "عرض",
    height: "ارتفاع",
  },
  responsive_types: {
    active: "فـعال",
    in_active: "غیرفعال",
  },
  display_type: "نحوه نمایش محتوا",
  display_type_options: {
    Any: "پیش فرض",
    ImageOnTop: "تصویر بالای متن",
    ImageOnLeft: "تصویر سمت چپ",
    ImageOnRight: "تصویر سمت راست",
    TitleOnTop: "عنوان بالا",
    TitleOnBottom: "عنوان پایین",
    JustText: "فقط متنی",
  },
  position_inventory: {
    Top: "بالا",
    Bottom: "پایین",
    BottomRight: "پایین راست",
    BottomLeft: "پایین چپ",
    TopRight: "بالا راست",
    TopLeft: "بالا چپ",
  },
  copyCode: "کپی کد",
  copied: "کپی شد",
  place_analytics: "گزارش عملکرد جایگاه",
  formGetCode: "کد جایگاه",
  expire_date: "تاریخ پایان نمایش",
  expire_date_help:
    "در صورت انتخاب تاریخ پایان، جایگاه به صورت اتوماتیک در این تاریخ غیرفعال می شود.",
  actions: {
    analytics: "گزارش عملکرد",
    edit: "ویرایش",
    clone: "کپی",
    delete: "حذف",
  },
  // media: "وب سایت",
  get_code: "کد جایگاه",
  type: {
    native: "همسـان",
    banner: "بنـــر",
  },
  device: {
    Mobile: "موبایل",
    Tablet: "تبـلت",
    Desktop: "دسکتـاپ",
  },
  status_: "وضعیت",
  status: {
    unknown: "نامشخص",
    active: "فعال",
    inActive: "غیرفعال",
    expired: "منقضی شده",
  },
  media: {
    name: "وبسایت",
    add: "افزودن وبسایت",
    edit: "ویرایش وبسایت",
    url: "آدرس وبسایت",
  },
  required: "الزامی",
  Dashboard: "آمار و گزارشات",
  codeHeadText: "کد زیر را کپی کرده و آن را در head وبسایت خود قرار دهید:",
  blockCodeText: "کد زیر را کپی کرده و آن را در محل مناسب جهت نمایش قرار دهید:",
};

export default faIRSSPTranslation;
