import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import {
  accountLogout,
  configAdd,
  refreshToken,
} from "@/app/local/accountSlice";
import { USER_CONFIG_TYPE } from "@/utils/constants/apiConstants";
import { REFRESH_TOKEN_TIME } from "@/utils/constants/configConstants";
import { getLocalLang, routeMiddleware } from "@/utils/helper";
import { useAccount } from "@/utils/hooks/useAuth";

import MainLayout from "./Layout";

const PrivateLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const router = useLocation();
  const { lang } = useAccount();
  const location = useLocation();
  const { profile, error } = useSelector((state) => state.account);
  let intervalRefresh;

  useEffect(() => {
    // TODO HANDLING ERROR CODE
    if (profile?.errorCode > 400 || error) {
      const result = dispatch(accountLogout());
      result.then((r) => {
        if (r?.payload?.success) {
          navigate("/login");
        }
      });
      return;
    }
    const usr_ = profile?.data;

    if (usr_?.user_type) routeMiddleware(usr_, navigate);
    refreshAuthToken();
    return () => {
      intervalRefresh && clearInterval(intervalRefresh);
    };
  }, []);

  useEffect(() => {
    setLanguageOnFirstLogin();
  }, [lang]);

  const setLanguageOnFirstLogin = async () => {
    const language = getLocalLang();
    const userId = profile?.data?.user_id;

    if (lang !== language) {
      const res = await dispatch(
        configAdd({
          id: userId,
          type: USER_CONFIG_TYPE.Culture,
          value: language,
        })
      );
      if (res?.payload?.success) {
        //TODO FIX RELOAD
        // await dispatch(accountUserDetail());
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    const usr_ = profile?.data;
    if (usr_?.user_type) {
      routeMiddleware(usr_, navigate, location);
    }
  }, [profile, location?.pathname]);

  const refreshAuthToken = () => {
    dispatch(refreshToken());

    if (!intervalRefresh) {
      intervalRefresh = setInterval(() => {
        dispatch(refreshToken());
      }, REFRESH_TOKEN_TIME);
    }
  };

  let mode;
  if (location?.pathname === "/wizard") mode = "wizard";

  return (
    <MainLayout mode={mode}>
      <Outlet />
    </MainLayout>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.element,
};

export default PrivateLayout;
