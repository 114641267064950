import {
  DownloadOutlined,
  DatabaseOutlined as IconHistory,
  ReloadOutlined as IconUpdate,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
  Space,
  Tag,
  Tooltip,
  message,
} from "antd";
import Text from "antd/lib/typography/Text";
import parse from "html-react-parser";
import _ from "lodash";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useMatch } from "react-router-dom";

import { accountUserDetail } from "@/app/local/accountSlice";
import { contractUserNewContract } from "@/app/local/adminContractSlice";
import {
  contractApprove,
  contractReject,
  getDefaultContractUser,
  uploadPaperContract,
} from "@/app/local/contractSlice";
import { getProfileData } from "@/pages/profile";
import {
  CONTRACT_STATUS,
  CONTRACT_STATUS_ALERT,
  FILE_FILE_TYPE,
  FILE_MEDIA_TYPE,
} from "@/utils/constants/apiConstants";
import { dateFormat, isLegalUser, objectR } from "@/utils/helper";
import { useAccount } from "@/utils/hooks/useAuth";

import Holder from "../general/Holder";
import InputUpload from "../input/inputUpload";
import inTrackEvent from "./Intrack/InTrackEvent";
import "./styles.less";

const ContractUser = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const templateRoute =
    useMatch("/publishers/:id") || useMatch("/merchants/:id");
  const isProfile = useMatch("/profile");
  const tmpId = templateRoute?.params?.id;

  const profile = getProfileData();
  const isLegal = isLegalUser(profile);
  const { isAdmin } = useAccount();

  const {
    contract,
    error,
    loading,
    loadingAction,
    loadingReject,
    loadingApprove,
  } = useSelector((state) => state.contract);
  const tmpData = _.get(contract, "data", {});

  const onFinish = async (e) => {
    const fileId = _.get(e, "contractPaper[0].response.file_id");

    if (fileId) {
      const result = await dispatch(
        uploadPaperContract({
          contract_id: tmpData.contract_id,
          file_id: fileId,
        })
      );
      if (result.payload?.success) {
        message.success({
          content: t("general.saveSuccessFully"),
        });
      } else {
        message.error({
          content: result.payload?.Message || t("general.unHandleErrorMessage"),
        });
      }
    }
  };

  const uId = _.get(profile, "data.user_id");
  const getContract = async () => {
    await dispatch(
      getDefaultContractUser({ uId, userType: profile?.data?.user_type })
    );
  };

  useEffect(() => {
    if (tmpId)
      dispatch(
        getDefaultContractUser({ uId, userType: profile?.data?.user_type })
      );
    if (isProfile) {
      getContract();
    }
  }, [tmpId]);

  const handleConfirm = async (type) => {
    let result;
    if (type) {
      result = await dispatch(contractApprove(tmpData.contract_id));
      // InTrack Event
      inTrackEvent.updateProfile({
        userId: uId,
        attributes: {
          contractAccepted: true,
        },
      });
      inTrackEvent.contractAcceptedEvent({
        userId: uId,
      });
    } else {
      result = await dispatch(contractReject(tmpData.contract_id));
    }
    if (result.payload.success) {
      message.loading({
        content: t("general.pleaseWaitMessage"),
        duration: 10,
        key: "contract",
      });
      getContract();
      const resultDetail = await dispatch(accountUserDetail());
      if (resultDetail.payload.success) {
        message.success({
          content: t("general.successFullyDone"),
          key: "contract",
        });
      }
    } else {
      message.error({
        content: result.payload?.Message || t("general.unHandleErrorMessage"),
      });
    }
  };

  const updateUserContract = async () => {
    message.loading({
      content: t("general.pleaseWaitMessage"),
      duration: 10,
      key: "contract",
    });

    const type_ = _.get(profile, "data.user_type");

    let result;
    result = await dispatch(
      contractUserNewContract({
        user_id: uId,
        type: type_,
      })
    );
    if (result.payload.success) {
      message.success({
        content: t("general.successFullyDone"),
        key: "contract",
      });
      getContract();
    } else {
      message.error({
        content: result.payload?.Message || t("general.unHandleErrorMessage"),
      });
    }
  };

  const contractUserStatus = profile?.data?.contract_status;
  const status = objectR(CONTRACT_STATUS)[contractUserStatus];

  return (
    <div className="contract-user">
      <Form name="formInfo" layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[24, 24]}>
          <Col md={24} xs={24}>
            <Card loading={loading} className="contract-user__info">
              <div className="contract-user__tools">
                <ul>
                  <li>
                    <Tooltip title={t("contract.download")}>
                      <Link target="_blank" to={`/contract_builder?ci=${uId}`}>
                        <Button icon={<DownloadOutlined />} type="default" />
                      </Link>
                    </Tooltip>
                  </li>
                  <li>
                    <Tooltip title={t("contract.history")}>
                      <Link target="_blank" to={`/contracts/${uId}`}>
                        <Button icon={<IconHistory />} type="default" />
                      </Link>
                    </Tooltip>
                  </li>
                  {isAdmin ? (
                    <li>
                      <Tooltip title={t("contract.update")}>
                        <Button
                          icon={<IconUpdate />}
                          onClick={updateUserContract}
                          type="default"
                        />
                      </Tooltip>
                    </li>
                  ) : (
                    false
                  )}
                </ul>
              </div>
              <ul className="contract-user__ul">
                <li>
                  <Text>{t("contract.status")}:</Text>
                  {status ? (
                    <Tag
                      style={{ margin: 0 }}
                      color={CONTRACT_STATUS_ALERT[status]}
                    >
                      {t(`profile.contract.${status}`)}
                    </Tag>
                  ) : (
                    "-"
                  )}
                </li>
                <li hidden>
                  <Text>{t("contract.create_date")}:</Text>
                  {dateFormat(tmpData.create_date, "S2")}
                </li>
                {tmpData.status === CONTRACT_STATUS.APPROVED ? (
                  <li>
                    <Text>{t("contract.start_date")}:</Text>
                    {dateFormat(tmpData.start_date, "S2")}
                  </li>
                ) : (
                  ""
                )}
                {tmpData.status === CONTRACT_STATUS.APPROVED ? (
                  <li>
                    <Text>{t("contract.end_date")}:</Text>
                    {dateFormat(tmpData.end_date, "S2")}
                  </li>
                ) : (
                  ""
                )}
                {tmpData.status === CONTRACT_STATUS.REJECTED ? (
                  <li>
                    <Text>{t("contract.reject_date")}:</Text>
                    {dateFormat(tmpData.reject_date, "S2")}
                  </li>
                ) : (
                  ""
                )}
                {tmpData.status === CONTRACT_STATUS.APPROVED ? (
                  <li hidden>
                    <Text>{t("contract.approve_date")}:</Text>
                    {dateFormat(tmpData.approve_date, "S2")}
                  </li>
                ) : (
                  ""
                )}
                {tmpData.paper_contract_file ? (
                  <li>
                    <Text>{t("contract.download")}:</Text>
                    <a href="#">
                      <DownloadOutlined />
                    </a>
                  </li>
                ) : (
                  ""
                )}
              </ul>
              <Space />
              {/* <Title level={4}>{tmpData.name}</Title> */}
              <div className="contract__text">
                {parse(tmpData?.content || "")}
              </div>

              {isLegal ? (
                <div className="contract__file-upload">
                  <Alert
                    message={
                      <div>
                        {t("contract.uploadFileLegal")}(
                        <a
                          href={`/contract_builder?ci=${uId}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t("contract.download")}
                        </a>
                        )
                      </div>
                    }
                  ></Alert>
                  <br />
                  <InputUpload
                    label={t("contract.paper")}
                    name={"contractPaper"}
                    mediaType={FILE_MEDIA_TYPE.DOCUMENT}
                    fileType={FILE_FILE_TYPE.PAPER_CONTRACT}
                    required
                    form={form}
                    files={[tmpData?.paper_contract_file_id]}
                    accept="mix"
                  />
                  <Button
                    type="ghost"
                    loading={loadingAction}
                    htmlType="submit"
                  >
                    {t("contract.savePaper")}
                  </Button>
                </div>
              ) : (
                ""
              )}
            </Card>
          </Col>
          <Holder
            if={isProfile && contractUserStatus !== CONTRACT_STATUS.APPROVED}
          >
            <Col md={24} xs={24}>
              <Space>
                <Button
                  tour_id="accept-button"
                  type="primary"
                  onClick={() => handleConfirm(true)}
                  loading={loadingApprove}
                  // htmlType="submit"
                >
                  {t("contract.approve")}
                </Button>
                <Button
                  type="primary"
                  onClick={() => handleConfirm(false)}
                  danger
                  // htmlType="submit"
                  loading={loadingReject}
                >
                  {t("contract.reject")}
                </Button>
              </Space>
            </Col>
          </Holder>
        </Row>
      </Form>
    </div>
  );
};

export default ContractUser;
