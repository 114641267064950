import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ENDPOINTS } from "@/utils/constants/apiConstants";

import { baseAPI } from "../services/fetchers";

export const getBannersWidgetListAsync = createAsyncThunk(
  "bannerWidget/getBannersWidgetListAsync",
  async (data) => {
    try {
      const body = {
        page: 1,
        page_size: 10,
        // filter: {},
        // is_pagination: true,
        // sort_orientation: "1",
      };
      const body_ = Object.assign({}, body, data);
      const end_ = ENDPOINTS.BANNER_WIDGETS_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const addWidgetBannerAsync = createAsyncThunk(
  "bannerWidget/addWidgetBannerAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.BANNER_WIDGET_ADD;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const deleteWidgetBannerAsync = createAsyncThunk(
  "bannerWidget/deleteWidgetBannerAsync",
  async (id) => {
    try {
      const end_ = ENDPOINTS.BANNER_WIDGET_DELETE.replace("{widgetId}", id);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const bannerChangeStatusAsync = createAsyncThunk(
  "bannerWidget/bannerChangeStatusAsync",
  async (body) => {
    try {
      const end_ = ENDPOINTS.BANNER_WIDGET_ACTIVE_STATUS.replace(
        "{widgetId}",
        body.id
      ).replace("{enabled}", body.status);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getBannerWidgetCodeAsync = createAsyncThunk(
  "bannerWidget/getBannerWidgetCodeAsync",
  async (body) => {
    try {
      const end_ = ENDPOINTS.BANNER_WIDGET_GET_CODE;
      const response = await baseAPI({
        endPoint: end_,
        body: body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const bannerSlice = createSlice({
  name: "bannerWidget",
  initialState: {
    bannersWidget: null,
    bannersWidgetLoading: false,
    bannersWidgetError: null,

    addBannersWidget: null,
    addBannersWidgetLoading: false,
    addBannersWidgetError: null,
  },
  reducers: {
    clearState: (state) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getBannersWidgetListAsync.pending, (state, action) => {
      state.bannersWidgetLoading = true;
      state.bannersWidgetError = null;
    });
    builder.addCase(getBannersWidgetListAsync.rejected, (state, action) => {
      state.bannersWidgetLoading = false;
      state.bannersWidgetError = action.error;
    });
    builder.addCase(getBannersWidgetListAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.bannersWidget = action.payload;
      }
      state.bannersWidgetLoading = false;
      state.bannersWidgetError = null;
    });

    builder.addCase(addWidgetBannerAsync.pending, (state, action) => {
      state.addBannersWidgetLoading = true;
      state.addBannersWidgetError = null;
    });
    builder.addCase(addWidgetBannerAsync.rejected, (state, action) => {
      state.addBannersWidgetLoading = false;
      state.addBannersWidgetError = action.error;
    });
    builder.addCase(addWidgetBannerAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.addBannersWidget = action.payload;
      }
      state.addBannersWidgetLoading = false;
      state.addBannersWidgetError = null;
    });
  },
});

export const { clearState } = bannerSlice.actions;

export const getBannersWidgetList = (data) => async (dispatch) => {
  return dispatch(getBannersWidgetListAsync(data));
};

export const addWidgetBanner = (data) => async (dispatch) => {
  return dispatch(addWidgetBannerAsync(data));
};

export const deleteWidgetBanner = (data) => async (dispatch) => {
  return dispatch(deleteWidgetBannerAsync(data));
};

export const bannerChangeStatus = (data) => async (dispatch) => {
  return dispatch(bannerChangeStatusAsync(data));
};

export const getBannerWidgetCode = (data) => async (dispatch) => {
  return dispatch(getBannerWidgetCodeAsync(data));
};

export default bannerSlice.reducer;
