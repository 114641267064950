import { Card, Tag } from "antd";
import { t } from "i18next";
import _ from "lodash";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { priceFormat } from "@/utils/helper";

import { FilterContext } from "../widget";

const TagFiltered = () => {
  const [tags, setTags] = useState([]);
  const [context, dispatch_] = useContext(FilterContext);

  const { categories } = useSelector((state) => state.website);
  const data_ = categories?.data || [];

  const { websitesActive } = useSelector((state) => state.website);
  const websitesList = _.get(websitesActive, "data", []);

  useEffect(() => {
    let tags_ = [];
    _.keys(context).map((key) => {
      if (key === "category") {
        if (context[key]) {
          const cats = context[key].map((c) => {
            return data_.find((i) => i.Category_id === c);
          });
          cats
            .filter((i) => i?.is_leaf)
            .map((i) => {
              tags_.push({
                type: key,
                value: i?.name,
                detail: i,
              });
            });
        }
      } else if (key === "website_id") {
        const [web_] = context[key];
        const website_ = websitesList.find(
          (item) => item.web_store_id === web_
        );
        tags_.push({
          type: "website_id",
          value: website_.name,
        });
      } else if (key === "search_text") {
        const search_ = context[key];
        // tags_.push(search_);
        if (search_) {
          tags_.push({
            type: key,
            value: search_,
          });
        }
      } else if (key === "from_price") {
        const from_ = context[key];
        const to_ = context["to_price"];

        if (from_) {
          tags_.push({
            type: "range",
            key: from_,
            value: `${t("general.from")} ${priceFormat(from_)} ${t(
              "general.Toman"
            )} ${t("general.to")} ${priceFormat(to_)} ${t("general.Toman")}`,
          });
        }
      }
    });
    setTags(tags_);
  }, [context]);

  const removeTag = (tag_) => {
    if (tag_.type === "category") {
      const tagsV = context.category.filter(
        (i) => tag_.detail.Category_id !== i
      );
      dispatch_({ type: "set_category", value: tagsV });
    } else if (tag_.type === "range") {
      dispatch_({ type: "set_price_reset", value: [] });
    }
  };

  return tags.length ? (
    <Card title={t("product.filtered")}>
      <div className="filterTagsWrapper">
        {tags.map((tag, i) => (
          <Tag closable key={tag?.key || i} onClose={() => removeTag(tag, i)}>
            {tag?.value}
          </Tag>
        ))}
      </div>
    </Card>
  ) : (
    ""
  );
};

export default TagFiltered;
