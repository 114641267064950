import moment from "moment-jalaali";

import { GENDER, USER_TYPE } from "@/utils/constants/apiConstants";

// const Intk = window?.Intk;

class InTrackEvent {
  loginEvent(params) {
    const uData = params?.userDetail?.data;
    const uId = params?.data?.user_id;
    const uType = params?.data?.user_type;
    if (uData && uType === USER_TYPE.PUBLISHER) {
      window.Intk("login", {
        firstName: uData?.first_name,
        lastName: uData?.last_name,
        email: uData?.email,
        phone: "+98" + uData?.mobile,
        gender: uData?.gender === GENDER.MALE ? "male" : "female",
        birthday: uData?.date_of_birth
          ? moment(uData?.date_of_birth).toISOString()
          : undefined, //ISO-8601
        userId: uId,
        attributes: {},
      });
    }
  }
  updateProfile(params) {
    window.Intk("updateProfile", params);
  }

  newUserRegisterEvent(params) {
    IntkMain("sendEvent", {
      eventName: "register_steps",
      eventData: params,
    });
  }
  personalInfoEvent(params) {
    if (params.first_name)
      IntkMain("sendEvent", {
        eventName: "personal_info_submit",
        eventData: {
          userId: params.userId,
          firstName: params.first_name,
          lastName: params.last_name,
          fatherName: params.father_name,
          gender: params?.gender === GENDER.MALE ? "male" : "female",
          birthDate: params?.date_of_birth
            ? moment(params?.date_of_birth).toISOString()
            : undefined, //ISO-8601
          nationalId: params.national_code,
        },
      });
  }
  contactInfoSubmitEvent(params) {
    IntkMain("sendEvent", {
      eventName: "contact_info_submit",
      eventData: {
        userId: params.userId,
        mobileNumber: params.mobileNumber,
        telephoneNumber: params.telephoneNumber,
        email: params.email,
        province: params.province,
        city: params.city,
        postalCode: params.postalCode,
        address: params.address,
        pelak: params.pelak,
      },
    });
  }
  paymentInfoSubmitEvent(params) {
    IntkMain("sendEvent", {
      eventName: "payment_info_submit",
      eventData: {
        userId: params.userId,
        shebaNumber: params.shebaNumber,
      },
    });
  }
  taxInfoSubmitEvent(params) {
    IntkMain("sendEvent", {
      eventName: "tax_info_submit",
      eventData: {
        userId: params.userId,
        isTaxable: params.isTaxable,
      },
    });
  }
  finalSignUpEvent(params) {
    IntkMain("sendEvent", {
      eventName: "final_sign_up",
      eventData: {
        userId: params.userId,
      },
    });
  }
  contractAcceptedEvent(params) {
    IntkMain("sendEvent", {
      eventName: "contract_accepted",
      eventData: {
        userId: params.userId,
      },
    });
  }
  newMediaOtpCodeEvent(params) {
    IntkMain("sendEvent", {
      eventName: "new_media_otp_submit",
      eventData: {
        email: params.email,
      },
    });
  }
  newMediaInfoSubmitEvent(params) {
    if (!window.Intk) return;

    IntkMain("sendEvent", {
      eventName: "new_media_submit",
      eventData: {
        userId: params.userId,
        mediaName: params.mediaName,
        mediaType: params.mediaType,
        mediaUrl: params.mediaUrl,
        fieldOfActivity: params?.fieldOfActivity,
        merchantName: params?.merchantName,
      },
    });
  }
  newMediaIdentityConfirmedEvent(params) {
    IntkMain("sendEvent", {
      eventName: "new_media_identity_confirmed",
      eventData: {
        userId: params.userId,
        mediaName: params.mediaName,
      },
    });
  }
  // not needed
  newMediaContentStatusChangeEvent(params) {
    IntkMain("sendEvent", {
      eventName: "new_media_content_status_changed",
      eventData: {
        userId: params.userId,
        description: params.description,
        reason: params.reason,
        status: params.status,
      },
    });
  }
  generateLinkEvent(params) {
    IntkMain("sendEvent", {
      eventName: "generate_link",
      eventData: {
        userId: params.userId,
        linkTitle: params.linkTitle,
        merchant: params.merchant,
        media: params.media,
        url: params.url,
        isPrivate: params.isPrivate,
      },
    });
  }
  generateBannerWidget(params) {
    IntkMain("sendEvent", {
      eventName: "generate_banner_widget",
      eventData: {
        userId: params.userId,
        bannerTitle: params.bannerTitle,
        media: params.media,
        bannerDimension: params.bannerDimension,
        isActive: params.isActive,
      },
    });
  }
  generateSmartWidget(params) {
    IntkMain("sendEvent", {
      eventName: "generate_smart_widget",
      eventData: {
        userId: params.userId,
        widgetTitle: params.widgetTitle,
        media: params.media,
        isActive: params.isActive,
        displayType: params.displayType,
        showTitle: params.showTitle,
        showProductTitle: params.showProductTitle,
        showProductCta: params.showProductCta,
        showProductPrice: params.showProductPrice,
      },
    });
  }
  generateNormalWidget(params) {
    IntkMain("sendEvent", {
      eventName: "generate_normal_widget",
      eventData: {
        userId: params.userId,
        widgetTitle: params.widgetTitle,
        media: params.media,
        showTitle: params.showTitle,
        showProductTitle: params.showProductTitle,
        showProductCta: params.showProductCta,
        showProductPrice: params.showProductPrice,
      },
    });
  }
}
const IntkMain = (eventName, data) => {
  window.Intk && window.Intk(eventName, data);
};

const inTrackEvent = new InTrackEvent();
export default inTrackEvent;
