import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ENDPOINTS } from "@/utils/constants/apiConstants";
import { clearObject } from "@/utils/utils";

import { baseAPI } from "../services/fetchers";

const requestBodyListDefault = {
  page: 1,
  page_size: 10,
  sort_field: undefined,
  search_text: undefined,
  is_pagination: true,
  sort_orientation: "1", // 1 = Descending,
};

export const getMerchantListAsync = createAsyncThunk(
  "merchants/getMerchantListAsync",
  async (data) => {
    try {
      const data_ = clearObject(data);
      const body_ = Object.assign({}, requestBodyListDefault, data_);
      const end_ = ENDPOINTS.MERCHANT_ADMIN_GET_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getMerchantDetailAsync = createAsyncThunk(
  "merchants/getMerchantDetailAsync",
  async (uId) => {
    try {
      const end_ = ENDPOINTS.MERCHANT_ADMIN_GET_DETAIL.replace("{userId}", uId);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getProjectionCurrentAsync = createAsyncThunk(
  "merchants/getProjectionCurrentAsync",
  async (mId) => {
    try {
      const end_ = ENDPOINTS.MERCHANT_ADMIN_GET_DETAIL.replace(
        "{merchantId}",
        mId || 0
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getProjectionsByContractIdAsync = createAsyncThunk(
  "merchants/getProjectionsByContractIdAsync",
  async (cId) => {
    try {
      const end_ = ENDPOINTS.MERCHANT_ADMIN_CONTRACT_PROJECTION_LIST.replace(
        "{contractId}",
        cId
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getProjectionsByMerchantIdAsync = createAsyncThunk(
  "merchants/getProjectionsByMerchantIdAsync",
  async (mId) => {
    try {
      // const end_ = ENDPOINTS.MERCHANT_ADMIN_PROJECTION_LIST.replace(
      //   "{merchantId}",
      //   mId
      // );
      const end_ = ENDPOINTS.MERCHANT_ADMIN_PROJECTION_CURRENT.replace(
        "{merchantId}",
        mId
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getPayoutConfigMerchantHistoryAsync = createAsyncThunk(
  "merchants/getPayoutConfigMerchantHistoryAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.MERCHANT_ADMIN_PAYOUT_CONFIG_HISTORY;
      if (data.merchantId) {
        end_ = end_.replace("{merchantId}", `merchantId=${data.merchantId}`);
      }
      if (data.fromDate) {
        end_ = end_.replace("{fromDate}", `&fromDate=${data.fromDate}`);
      } else {
        end_ = end_.replace("{fromDate}", "");
      }

      if (data.toDate) {
        end_ = end_.replace("{toDate}", `&toDate=${data.toDate}`);
      } else {
        end_ = end_.replace("{toDate}", "");
      }

      const response = await baseAPI({
        endPoint: end_,
        // body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setPayoutConfigAsync = createAsyncThunk(
  "merchants/setPayoutConfigAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.MERCHANT_ADMIN_PAYOUT_SET_PAYOUT_CONFIG;

      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const syncProjectionsByMerchantIdSync = createAsyncThunk(
  "merchants/syncProjectionsByMerchantIdSync",
  async (mId) => {
    try {
      const end_ = ENDPOINTS.MERCHANT_ADMIN_PROJECTION_GENERATE.replace(
        "{merchantId}",
        mId
      );

      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getGroupsByWebStoreIdSync = createAsyncThunk(
  "merchants/getGroupsByWebStoreIdSync",
  async (wId) => {
    try {
      const end_ = ENDPOINTS.MERCHANT_ADMIN_GROUP_BY_WEB_STORE_ID.replace(
        "{webstoreId}",
        wId
      );

      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getGroupsSync = createAsyncThunk(
  "merchants/getGroupsSync",
  async (wId) => {
    try {
      const end_ = ENDPOINTS.MERCHANT_ADMIN_GROUP_BY_WEB_STORE_ID.replace(
        "{webstoreId}",
        wId
      );

      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const editGroupSync = createAsyncThunk(
  "merchants/editGroupSync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.MERCHANT_ADMIN_GROUP_EDIT;

      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const addGroupSync = createAsyncThunk(
  "merchants/addGroupSync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.MERCHANT_ADMIN_GROUP_ADD;

      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const deleteGroupSync = createAsyncThunk(
  "merchants/deleteGroupSync",
  async (id) => {
    try {
      const end_ = ENDPOINTS.MERCHANT_ADMIN_GROUP_DELETE.replace("{id}", id);

      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getSmartWidgetListSync = createAsyncThunk(
  "merchants/getSmartWidgetListSync",
  async (data) => {
    const defaultBody = {
      page: 1,
      page_size: 10,
      // sort_orientation: "1 = Descending",
    };
    try {
      const end_ = ENDPOINTS.PRODUCT_WIDGET_SMART_GET_LIST;
      const body = Object.assign({}, defaultBody, data);
      const response = await baseAPI({
        endPoint: end_,
        body: body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getMerchantActiveListSync = createAsyncThunk(
  "merchants/getMerchantActiveListSync",
  async (data) => {
    try {
      const isGetAll = data?.all === true;
      const urlParams = new URLSearchParams();
      isGetAll && urlParams.set("all", isGetAll);

      const end_ = ENDPOINTS.MERCHANT_ADMIN_ACTIVE_LIST.replace(
        "{queryParams}",
        urlParams.toString()
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const adminMerchantSlice = createSlice({
  name: "adminMerchant",
  initialState: {
    loading: false,
    users: null,
    user: null,
    error: null,

    contractProjections: null,
    merchantProjections: null,

    merchantPayoutHistory: null,

    merchantProjectionSyncLoading: false,

    groupListLoading: false,
    groupList: null,
    groupListError: null,

    groupEditLoading: false,

    smartWidgets: null,
    smartWidgetsLoading: false,
    smartWidgetsError: null,

    setPayoutConfigLoading: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMerchantListAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getMerchantListAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getMerchantListAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.users = action.payload;
      }
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getMerchantActiveListSync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getMerchantActiveListSync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getMerchantActiveListSync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.users = action.payload;
      }
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getMerchantDetailAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getMerchantDetailAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getMerchantDetailAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.user = action.payload;
      }
      state.loading = false;
      state.error = null;
    });

    builder.addCase(
      getProjectionsByContractIdAsync.fulfilled,
      (state, action) => {
        if (action.payload.success) {
          state.contractProjections = action.payload;
        }
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(
      getProjectionsByMerchantIdAsync.fulfilled,
      (state, action) => {
        if (action.payload.success) {
          state.merchantProjections = action.payload;
        }
        state.loading = false;
        state.error = null;
      }
    );

    builder.addCase(
      getPayoutConfigMerchantHistoryAsync.fulfilled,
      (state, action) => {
        if (action.payload.success) {
          state.merchantPayoutHistory = action.payload;
        }
        state.loading = false;
        state.error = null;
      }
    );

    builder.addCase(
      syncProjectionsByMerchantIdSync.pending,
      (state, action) => {
        state.merchantProjectionSyncLoading = true;
      }
    );
    builder.addCase(
      syncProjectionsByMerchantIdSync.fulfilled,
      (state, action) => {
        state.merchantProjectionSyncLoading = false;
      }
    );
    builder.addCase(
      syncProjectionsByMerchantIdSync.rejected,
      (state, action) => {
        state.merchantProjectionSyncLoading = false;
      }
    );

    builder.addCase(addGroupSync.pending, (state, action) => {
      state.groupEditLoading = true;
    });
    builder.addCase(addGroupSync.fulfilled, (state, action) => {
      state.groupEditLoading = false;
    });
    builder.addCase(addGroupSync.rejected, (state, action) => {
      state.groupEditLoading = false;
    });

    builder.addCase(editGroupSync.pending, (state, action) => {
      state.groupEditLoading = true;
    });
    builder.addCase(editGroupSync.fulfilled, (state, action) => {
      state.groupEditLoading = false;
    });
    builder.addCase(editGroupSync.rejected, (state, action) => {
      state.groupEditLoading = false;
    });

    builder.addCase(deleteGroupSync.pending, (state, action) => {
      state.groupEditLoading = true;
    });
    builder.addCase(deleteGroupSync.fulfilled, (state, action) => {
      state.groupEditLoading = false;
    });
    builder.addCase(deleteGroupSync.rejected, (state, action) => {
      state.groupEditLoading = false;
    });

    builder.addCase(getGroupsSync.pending, (state, action) => {
      state.groupListLoading = true;
    });
    builder.addCase(getGroupsSync.fulfilled, (state, action) => {
      state.groupListLoading = false;
      if (action.payload.success) {
        state.groupList = action.payload;
      }
      state.groupListError = null;
    });
    builder.addCase(getGroupsSync.rejected, (state, action) => {
      state.groupListLoading = false;
    });

    builder.addCase(getSmartWidgetListSync.pending, (state, action) => {
      state.smartWidgetsLoading = true;
    });
    builder.addCase(getSmartWidgetListSync.fulfilled, (state, action) => {
      state.smartWidgetsLoading = false;
      if (action.payload.success) {
        state.smartWidgets = action.payload;
      }
      state.smartWidgetsError = null;
    });
    builder.addCase(getSmartWidgetListSync.rejected, (state, action) => {
      state.smartWidgetsLoading = false;
      state.smartWidgetsError = null;
    });
    builder.addMatcher(
      (action) => action.type.includes("pending"),
      (state) => {
        state.setPayoutConfigLoading = true;
      }
    );
    builder.addMatcher(
      (action) => action.type.includes("fulfilled"),
      (state) => {
        state.setPayoutConfigLoading = false;
      }
    );
    builder.addMatcher(
      (action) => action.type.includes("rejected"),
      (state) => {
        state.setPayoutConfigLoading = false;
      }
    );
  },
});

export const getMerchantList = (data) => async (dispatch) => {
  return dispatch(getMerchantListAsync(data));
};

export const getMerchantDetail = (data) => async (dispatch) => {
  return dispatch(getMerchantDetailAsync(data));
};

export const getProjectionCurrent = (data) => async (dispatch) => {
  return dispatch(getProjectionCurrentAsync(data));
};

export const getProjectionActiveDetail = (data) => async (dispatch) => {
  return dispatch(getProjectionCurrentAsync(data));
};

export const getProjectionsByContractId = (data) => async (dispatch) => {
  return dispatch(getProjectionsByContractIdAsync(data));
};

export const getProjectionsByMerchantId = (data) => async (dispatch) => {
  return dispatch(getProjectionsByMerchantIdAsync(data));
};

export const getPayoutConfigMerchantHistory = (data) => async (dispatch) => {
  return dispatch(getPayoutConfigMerchantHistoryAsync(data));
};

export const setPayoutConfig = (data) => async (dispatch) => {
  return dispatch(setPayoutConfigAsync(data));
};

export const syncProjectionsByMerchantId = (data) => async (dispatch) => {
  return dispatch(syncProjectionsByMerchantIdSync(data));
};

export const getGroups = (data) => async (dispatch) => {
  return dispatch(getGroupsSync(data));
};

export const editGroup = (data) => async (dispatch) => {
  return dispatch(editGroupSync(data));
};

export const addGroup = (data) => async (dispatch) => {
  return dispatch(addGroupSync(data));
};

export const deleteGroup = (data) => async (dispatch) => {
  return dispatch(deleteGroupSync(data));
};

export const getSmartWidgetList = (data) => async (dispatch) => {
  return dispatch(getSmartWidgetListSync(data));
};

export const getMerchantActiveList = (data) => async (dispatch) => {
  return dispatch(getMerchantActiveListSync(data));
};

export default adminMerchantSlice.reducer;
