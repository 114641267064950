import { enqueueSnackbar } from "notistack";
import Slide from "@material-ui/core/Slide";
import { theme } from "./theme";

export const notification = (message, status) => {
  enqueueSnackbar(message, {
    variant: status,

    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    TransitionComponent: Slide,
  });
};

export const handleDownload = (_url) => {
  let link = document.createElement("a");
  link.download = name;
  link.target = "_blank";
  link.href = _url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function isNumeric(str) {
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
}

export const SEVERITY = {
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
};

export const containedLargePrimaryBtn = {
  variant: "contained",
  textColor: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  hoverColor: theme.palette.primary.dark,
  fontSize: theme.typography.button.large.fontSize,
  fontWeight: theme.typography.button.large.fontWeight,
  lineHeight: theme.typography.button.large.lineHeight,
  padding: "8px 22px",
};

export const outlinedLargePrimaryBtn = {
  variant: "outlined",
  textColor: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  hoverColor: theme.palette.primary.background,
  borderColor: theme.palette.primary.main,
  fontSize: theme.typography.button.large.fontSize,
  fontWeight: theme.typography.button.large.fontWeight,
  lineHeight: theme.typography.button.large.lineHeight,
  padding: "8px 22px",
};

export const containedLargeDefaultBtn = {
  variant: "contained",
  textColor: theme.palette.common.black,
  backgroundColor: theme.palette.grey.G300,
  hoverColor: theme.palette.grey.A100,
  fontSize: theme.typography.button.large.fontSize,
  fontWeight: theme.typography.button.large.fontWeight,
  lineHeight: theme.typography.button.large.lineHeight,
  padding: "8px 22px",
};
