import { Column, Line } from "@ant-design/plots";
import { Col, Row } from "antd";
import { t } from "i18next";
import moment from "moment-jalaali";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getOrdersReport } from "@/app/local/reportSlice";
import { DashboardContext } from "@/pages/dashboard/DashboardAdmin";
import {
  exportCsvMain,
  handleDate,
  kFormatter,
  priceFormat,
} from "@/utils/helper";

import TileNumber from "../micro/TileNumber";
import { ChartHolder } from "./AdminViewClickChart";
import { COLORS } from "./colors";
import WidgetWrapper from "./micro/WidgetWrapper";

const lineNames = [
  "dashboard.com.generated_count",
  "dashboard.com.net_count",
  "dashboard.orderTotalCount",
  "dashboard.com.ncFinalized",
  "dashboard.com.ncGenerated",
  "dashboard.cancelOrderCount",
];
const AmountOrdersChart = (props) => {
  const [totalData, setTotalData] = useState({});
  const [data, setData] = useState([]);
  const [mode, setMode] = useState("LINE");
  const { adminOrdersReport, adminOrdersReportLoading } = useSelector(
    (state) => state.report
  );

  const dispatch = useDispatch();
  const [context, dispatch_] = useContext(DashboardContext);

  useEffect(() => {
    if (adminOrdersReport) {
      const data_ = [...(adminOrdersReport?.data || [])];
      const temps = [];
      const total = {
        generated: 0,
        // net: 0,
        finalized: 0,
        // ncFinal: 0,
        // ncGenerated: 0,
        canceled: 0,
      };
      data_
        .sort((a, b) => {
          const b_ = moment(b.pivot);
          const a_ = moment(a.pivot);
          const isBefore = moment(a_).isBefore(b_);
          if (isBefore) {
            return -1;
          }
          return 1;
        })
        .map((item, i) => {
          const date_ = handleDate(item.pivot, data_[i - 1]?.pivot);
          temps.push({
            date: date_,
            value: item.generated_order_count,
            type: lineNames[0],
          });
          total.generated += item.generated_order_count;

          // temps.push({
          //   date: date_,
          //   value: item.net_order_count,
          //   type: lineNames[1],
          // });
          // total.net += item.net_order_count;

          temps.push({
            date: date_,
            value: item.canceled_order_count,
            type: lineNames[5],
          });
          total.canceled += item.canceled_order_count;

          temps.push({
            date: date_,
            value: item.finalize_order_count,
            type: lineNames[2],
          });
          total.finalized += item.finalize_order_count;

          // temps.push({
          //   date: date_,
          //   value: item.nc_count_of_finalized_order_count,
          //   type: lineNames[3],
          // });
          // total.ncFinal += item.nc_count_of_finalized_order_count;

          // temps.push({
          //   date: date_,
          //   value: item.nc_count_of_generated_order_count,
          //   type: lineNames[4],
          // });
          // total.ncGenerated += item.nc_count_of_generated_order_count;
        });
      setTotalData(total);

      setData(temps);
    }
  }, [adminOrdersReport]);

  const lineConfig = useMemo(() => {
    console.log("data", data);
    return {
      data,
      xField: "date",
      yField: "value",
      yAxis: {
        label: {
          formatter: (v) => kFormatter(v, 1),
        },
      },
      limitInPlot: false,
      slider: data.length > 15 ? { start: 0.5, end: 1 } : "",
      seriesField: "type",
      height: 315,
      legend: false,
      color: ({ type, date }, ...x) => {
        console.log("type", type);
        switch (type) {
          case lineNames[5]:
            return COLORS.DASHBOARD_CARMINE_PINK;
          case lineNames[4]:
            return COLORS.DASHBOARD_ORANGE;
          case lineNames[3]:
            return COLORS.DASHBOARD_BLUE;
          case lineNames[2]:
            return COLORS.DASHBOARD_GREEN;
          case lineNames[0]:
            return COLORS.DASHBOARD_PURPLE;
          case lineNames[1]:
            return COLORS.DASHBOARD_RED;
          default:
            return COLORS.DASHBOARD_BLUE;
        }
      },
      theme: {
        styleSheet: {
          fontFamily: "IRANYekan",
        },
      },
      tooltip: {
        formatter: (datum) => {
          console.log("dataum", datum);
          return { name: t(datum.type), value: datum.value };
        },
      },
    };
  }, [data]);

  const barConfig = useMemo(() => {
    return {
      data,
      height: 315,
      isGroup: true,
      xField: "date",
      yField: "value",
      limitInPlot: false,
      slider: data.length > 15 ? { start: 0.5, end: 1 } : "",
      yAxis: {
        label: {
          formatter: (v) => kFormatter(v, 1),
        },
      },
      seriesField: "type",
      theme: {
        styleSheet: {
          fontFamily: "IRANYekan",
        },
      },
      legend: false,
      tooltip: {
        formatter: (datum) => {
          return { name: t(datum.type), value: priceFormat(datum.value) };
        },
      },
      maxColumnWidth: 40,
      color: ({ type, date }, ...x) => {
        switch (type) {
          case lineNames[5]:
            return COLORS.DASHBOARD_CARMINE_PINK;
          case lineNames[4]:
            return COLORS.DASHBOARD_ORANGE;
          case lineNames[3]:
            return COLORS.DASHBOARD_BLUE;
          case lineNames[2]:
            return COLORS.DASHBOARD_GREEN;
          case lineNames[0]:
            return COLORS.DASHBOARD_PURPLE;
          case lineNames[1]:
            return COLORS.DASHBOARD_RED;
          default:
            return COLORS.DASHBOARD_BLUE;
        }
      },
    };
  }, [data]);

  const onSubmit = (e) => {
    dispatch(
      getOrdersReport({
        web_store_ids: context.webstore,
        media_ids: e?.medias || context?.medias,
        link_type_report_types: e?.link_type,
        from: context.period.fromDate,
        to: context.period.toDate,
        publisher_ids: context?.publisher_ids,
        filter_date_by: context?.filter_date_by,
      })
    );
  };

  const exportCsv = () => {
    try {
      const header = ["date", "value", "type"];
      const d = [];
      d.push(header);
      data.map((item) => {
        const tm = Object.values(item);
        tm[2] = tm[2].replace("dashboard.com.", "")?.toUpperCase();
        d.push(tm);
      });
      exportCsvMain(d);
    } catch (e) {
      const cashing = "";
    }
  };

  return (
    <WidgetWrapper
      setMode={setMode}
      title={"dashboard.ordersCount"}
      onSubmit={onSubmit}
      exportCsv={exportCsv}
    >
      <div>
        <Row gutter={[24, 24]}>
          <Col md={18} xs={24} dir="ltr" style={{ direction: "ltr" }}>
            <ChartHolder
              loading={adminOrdersReportLoading}
              len={data?.length}
              height="315px"
            >
              {mode === "LINE" ? (
                <Line loading={adminOrdersReportLoading} {...lineConfig} />
              ) : (
                ""
              )}
              {mode === "BAR" ? (
                <Column {...barConfig} loading={adminOrdersReportLoading} />
              ) : (
                ""
              )}
            </ChartHolder>
          </Col>

          <Col md={6} xs={24} className="tile-number-box">
            <TileNumber
              number={totalData.generated}
              title={t("dashboard.submittedOrderCount")}
              color={"color_2"}
            />

            <TileNumber
              number={totalData.canceled}
              title={t("dashboard.cancelOrderCount")}
              color={"color_10"}
            />
            <TileNumber
              number={totalData.finalized}
              title={t("dashboard.orderTotalCount")}
              color={"color_5"}
            />
          </Col>
        </Row>
      </div>
    </WidgetWrapper>
  );
};

AmountOrdersChart.propTypes = {
  name: PropTypes.string,
};

export default AmountOrdersChart;
