import { useEffect } from "react";
import { useSelector } from "react-redux";

import { INTRACK_CONFIG } from "@/utils/constants/configConstants";
import useConfig from "@/utils/hooks/useConfig";

const InTrackSDK = () => {
  const { profile } = useSelector((state) => state.account);
  const { isSSP } = useConfig();

  useEffect(() => {
    if (!isSSP) {
      var inTrack_config = {
        app_key: INTRACK_CONFIG.APP_KEY,
        auth_key: INTRACK_CONFIG.AUTH_KEY,
        public_key: INTRACK_CONFIG.PUBLIC_KEY,
        debug: true,
      };
      (function (i, n, t, r, a, c, k) {
        const o = (i["InTrack"] = i["InTrack"] || {});
        i[a] =
          i[a] ||
          function () {
            (o.q = o.q || []).push(arguments);
          };
        const s = n.createElement(t);
        s.async = true;
        s.src = r;
        s.onload = function () {
          o.init(c);
        };
        const e = n.getElementsByTagName(t)[0];
        e.parentNode.insertBefore(s, k);
      })(
        window,
        document,
        "script",
        "//static1.intrack.ir/api/web/download/sdk/v1/inTrack.min.js?v=00",
        "Intk",
        inTrack_config
      );
    }
  }, [isSSP]);

  return null;
};

export default InTrackSDK;
