import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import ArrowDownwardSharp from "@material-ui/icons/ArrowDownwardSharp";
import ArrowUpwardSharp from "@material-ui/icons/ArrowUpwardSharp";
import clsx from "clsx";
import CustomizedSkeleton from "../components/CustomizedSkeleton";
import PropTypes from "prop-types";

const isFunction = (functionToCheck) => {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
};

const BasicTable = ({
  rows,
  columns,
  footerData,
  hasBorder = true,
  tableHeadClassName,
  loading,
}) => {
  const classes = useStyles();

  //const [currentSortColumn, setCurrentSortColumn] = useState("");

  const columnSorter = (cb) => {
    //setCurrentSortColumn(par);
    cb();
  };

  const SortIcon = ({ cb, par, dir, active }) => {
    ArrowUpwardSharp;
    return (
      <span className={`${classes.sortIcon} ${active && classes.activeSort}`}>
        {dir === "DESC" ? (
          <ArrowDownwardSharp
            className={classes.iconSize}
            onClick={() => columnSorter(cb, par, dir)}
          />
        ) : (
          <ArrowUpwardSharp
            className={classes.iconSize}
            onClick={() => columnSorter(cb, par, dir)}
          />
        )}
      </span>
    );
  };

  SortIcon.propTypes = {
    cb: PropTypes.func,
    par: PropTypes.string,
    dir: PropTypes.string,
    active: PropTypes.bool,
  };

  return (
    <TableContainer className={hasBorder ? classes.root : classes.noBorder}>
      <Table aria-label="simple table">
        <TableHead className={clsx(classes.head, tableHeadClassName)}>
          <TableRow>
            {columns.map((item, idx) => {
              return (
                <TableCell
                  key={idx}
                  width={item.width ?? null}
                  className={classes.headCells}
                >
                  {item.value}
                  {item?.action?.sort && (
                    <SortIcon
                      active={item?.action?.sort?.active}
                      cb={item?.action?.sort?.func}
                      par={item?.action?.sort?.par}
                      dir={item?.action?.sort?.dir}
                    />
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {getRows(rows, columns, loading).map((row, ridx) => (
            <TableRow key={ridx} className={classes.row}>
              {row.map((Value, cidx) => (
                <TableCell
                  key={cidx}
                  component={cidx === 0 ? "th" : "td"}
                  scope="row"
                  onClick={Value.action}
                  className={clsx(classes.tableCell, {
                    [classes.cellHasAction]: Value.action,
                  })}
                  style={{ ...Value.style }}
                >
                  {loading ? (
                    <CustomizedSkeleton height={40} />
                  ) : isFunction(Value.value) ? (
                    <Value />
                  ) : (
                    Value.value
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        {footerData && (
          <TableFooter className={classes.head}>
            <TableRow>
              {footerData.map((item, idx) => {
                return (
                  <TableCell key={idx} className={classes.columnCell}>
                    {item.value}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};
export const getRows = (rows, columns, loading) => {
  if (!rows?.length && loading) {
    const _columns = new Array(columns.length).fill({});
    const _rows = new Array(10).fill(_columns);
    return _rows;
  }
  return rows;
};

BasicTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  footerData: PropTypes.array,
  hasBorder: PropTypes.bool,
  tableHeadClassName: PropTypes.string,
  loading: PropTypes.bool,
};

export default BasicTable;

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px  solid ${theme.palette.grey.G100}`,
    borderRadius: theme.spacing(1),
    "& .MuiTableCell-head": {
      fontWeight: theme.typography.body2.bold.fontWeight,
      fontSize: theme.typography.body2.bold.fontSize,
      backgroundColor: "#F4F4F4",
      border: "none",
      whiteSpace: "noWrap",
    },
    "& .MuiTableCell-body": {
      fontWeight: theme.typography.body2.regular.fontWeight,
      fontSize: theme.typography.body2.regular.fontSize,
      color: theme.palette.secondary.dark1,
      whiteSpace: "noWrap",
    },
  },
  skeleton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  noBorder: {
    borderTop: `0.9px  solid ${theme.palette.grey.G300}`,
    borderBottom: `0.9px  solid ${theme.palette.grey.G300}`,
    "& .MuiTable-root": {
      whiteSpace: "noWrap",
    },
    "& .MuiTableCell-head": {
      fontWeight: theme.typography.body2.bold.fontWeight,
      fontSize: theme.typography.body2.bold.fontSize,
    },
    "& .MuiTableCell-body": {
      fontWeight: theme.typography.body2.regular.fontWeight,
      fontSize: theme.typography.body2.regular.fontSize,
      color: theme.palette.secondary.dark1,
    },
  },
  cellHasAction: { cursor: "pointer" },
  tableCell: {
    "&:has(a)": { padding: 0, height: "content-fit" },
    "& a": {
      color: `${theme.palette.secondary.dark1}!important`,
      cursor: "pointer !important",
      height: "72px",
      display: "flex",
      alignItems: "center",
    },
  },
  sortIcon: {
    width: "25px",
    height: "25px",
    "&:hover": {
      backgroundColor: theme.palette.common.gray6,
    },
    borderRadius: theme.spacing(2),
    margin: "auto",
    padding: "1px",
    cursor: "pointer",
    // position: "absolute",
    opacity: "0.2",
  },
  activeSort: { opacity: "1" },
  iconSize: { fontSize: "1.2rem" },
  head: {
    boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.12)",
    ...theme.typography.body2.bold,
  },
  headCells: {
    color: theme.palette.secondary.dark1,
  },

  row: {
    "& td, & th": {
      border: "none",
    },
    "& th": {
      paddingLeft: "16px !important",
    },
    justifyContent: "center",
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
      "& button": {
        padding: 0,
      },
    },
    "&:nth-child(even) td, &:nth-child(even) th": {
      backgroundColor: theme.palette.grey.G50,
    },
    "&:hover td, &:hover th": {
      backgroundColor: fade(theme.palette.primary.main, 0.1),
    },
  },
}));
