import { Button, Form, message } from "antd";
import Title from "antd/lib/typography/Title";
import { t } from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  accountVerifyMobile,
  configAdd,
  sendSmsCode,
} from "@/app/local/accountSlice";
import { MODE } from "@/pages/public/recoveryAccount";
import { SEND_CODE_CONSUMER } from "@/utils/constants/apiConstants";

import CodeInput from "../input/codeInput";

const MobileConfirm = (props) => {
  const { setMode, mobileNumber } = props;
  const [form4] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, error, loading, oldUserError } = useSelector(
    (state) => state.account
  );

  const onFinishVerify = async (e) => {
    const result = await dispatch(
      accountVerifyMobile({
        token: e.code,
        username: mobileNumber,
        consumer: 4,
      })
    );
    if (result?.payload?.success) {
      sessionStorage.setItem("TOKEN", e.code);
      setNeedResetPassword();
    } else {
      message.error({
        content: result?.payload?.Message || t("general.unHandleErrorMessage"),
        duration: 1,
      });
    }
  };

  const setNeedResetPassword = async () => {
    await dispatch(
      configAdd({ type: "FirstLogin", value: "need-reset-password" })
    );
    setMode(MODE.RESET_PASSWORD);
    // if (result?.payload?.success) {
    // navigate("/reset-password", {
    //   state: {
    //     // hasToken,
    //   },
    // });
    // }
  };

  const onSendMobileCode = async (e) => {
    try {
      return await dispatch(
        sendSmsCode({
          username: e.mobile.replaceAll(" ", "").trim(),
          consumer: SEND_CODE_CONSUMER.LOGIN,
        })
      );
    } catch (err) {
      const cashing = "";
    }
  };

  return (
    <Form
      form={form4}
      onFinish={onFinishVerify}
      className="auth__form"
      name="basic"
    >
      <Title level={4} className={"auth__title"}>
        {t("auth.sendCode")}
      </Title>
      <div className={"auth__description"}>
        {t("auth.sendmobileCodeDescription")}
      </div>
      <p>
        <div>
          <b>{mobileNumber}</b>
        </div>
        <a onClick={() => setMode("mobile")}>{t("auth.changeMobileNumber")}</a>
      </p>

      <div style={{ direction: "ltr" }}>
        <Form.Item noStyle name="code" rules={[{ required: true }]}>
          <CodeInput form={form4} sendCode={onSendMobileCode} />
        </Form.Item>
      </div>
      <br />
      <Form.Item
        wrapperCol={{
          // offset: 8,
          span: 16,
        }}
        className="auth__actions"
      >
        <Button loading={loading} size="large" type="primary" htmlType="submit">
          {t("auth.accept")}
        </Button>
      </Form.Item>
    </Form>
  );
};

MobileConfirm.propTypes = {
  setMode: PropTypes.func,
  mobileNumber: PropTypes.string,
};
export default MobileConfirm;
