import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Pagination,
  Select,
  Spin,
  Switch,
} from "antd";
import Title from "antd/lib/typography/Title";
import { t } from "i18next";
import _ from "lodash";
import PropsTypes from "prop-types";
import React, { useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { getProductsWidgetSmart } from "@/app/local/productWidgetSlice";
import { WidgetContext } from "@/pages/link/detail/widgetBuilder";
import { dateFormat } from "@/utils/helper";
import { getFinalFilters } from "@/utils/utils";

import FormSearchBar from "../micro/FormSearchBar";
import SliderCarouselV2 from "./SliderCarouselV2";
import WidgetWrapperBuilder from "./WidgetWrapperBuilder";

const WidgetListSelector = (props) => {
  const {
    smartWidgets,
    smartWidgetsLoading,
    smartWidgetsError,
    widgetCodeLoading,
    widgetSmartDetail,
  } = useSelector((state) => state.productWidget);
  const [formSearch] = Form.useForm();
  const navigate = useNavigate();
  const [extraData, setExtraData] = useState(null);
  const dispatch = useDispatch();
  const [context, dispatch_] = useContext(WidgetContext);

  const itemsList = useMemo(() => {
    let items_ = _.get(smartWidgets, "data.result", []);
    items_ = items_.map((item) => {
      return {
        ...item,
        create_date: dateFormat(item.create_date, "ST"),
      };
    });
    if (items_.length) {
      setTimeout(() => {
        props.ref_.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }, 100);
    }
    return items_;
  }, [smartWidgets]);

  const onFinishSearch = (ev) => {
    const e = getFinalFilters(extraData, ev, {});
    dispatch(
      getProductsWidgetSmart({
        filter: {
          webstore_id: [context.web_store],
          product_id: e.product_id,
          has_campaign: e.has_campaign,
        },
        page: e?.current || 1,
        page_size: e?.pageSize || 10,
        search_text: e?.search,
      })
    );
    setExtraData(e);
  };

  const handlePagination = (current, pageSize) => {
    onFinishSearch({ current, pageSize });
  };

  const dataItems = _.get(smartWidgets, "data", {});

  return (
    <Spin spinning={smartWidgetsLoading}>
      {/* {itemsList.length ? ( */}
      <>
        <div className="carousel-help">
          <Title level={5}>{t("widget_builder.select_widget")}</Title>
          <p>{t("widget_builder.select_widget_from_below")}:</p>
        </div>

        <FormSearchBar
          name="formSearch"
          form_={formSearch}
          onFinish={onFinishSearch}
          search
        >
          <Col md={4} xs={24}>
            <Form.Item name={"product_id"}>
              <Input placeholder={t("widget_builder.product_id")} />
            </Form.Item>
          </Col>
          <Col md={4} xs={24} hidden>
            <Form.Item name={"category"}>
              <Select placeholder={t("widget_builder.select_category")} />
            </Form.Item>
          </Col>
          <Col md={5} xs={24} className="switch-inline">
            <label htmlFor="switch_camp">
              {t("widget_builder.show_campaign_widget")}
            </label>
            <Form.Item name={"has_campaign"}>
              <Switch
                id="switch_camp"
                title={t("widget_builder.show_campaign_widget")}
              />
            </Form.Item>
          </Col>
        </FormSearchBar>
      </>
      {/* ) : null} */}

      {itemsList.length ? (
        <div className="widget-builder__list">
          <div>
            {itemsList.map((widget, k) => {
              return (
                <WidgetWrapperBuilder key={k} data={widget}>
                  <SliderCarouselV2
                    data={widget.products}
                    hideButton
                    // hidePrice
                  />
                </WidgetWrapperBuilder>
              );
            })}
          </div>
          <Card>
            <div style={{ textAlign: "center" }}>
              {t("widget_builder.no_widget_message.message")}
              <Link to={"/widgets/new?type=custom"} style={{ margin: "0 2px" }}>
                {t("widget_builder.no_widget_message.message2")}
              </Link>
              {t("widget_builder.no_widget_message.message3")}
            </div>
          </Card>

          <div>
            <Pagination
              className="pagination"
              defaultCurrent={1}
              total={dataItems?.total_items}
              current={dataItems?.page}
              onChange={handlePagination}
              showSizeChanger
              defaultPageSize={dataItems?.page_size}
              hideOnSinglePage
            />
          </div>
        </div>
      ) : !smartWidgetsLoading ? (
        <>
          <Alert
            message={t("widget_builder.empty_widget")}
            type="info"
            showIcon
            action={
              <Button
                size="small"
                type="link"
                onClick={() => navigate("/widgets/new?type=custom")}
              >
                {t("widget_builder.create_widget")}
              </Button>
            }
          />
        </>
      ) : null}
    </Spin>
  );
};

WidgetListSelector.propTypes = {
  ref_: PropsTypes.any,
};
export default WidgetListSelector;
