import _ from "lodash";

export function clearObject(data) {
  try {
    return JSON.parse(JSON.stringify(data));
  } catch (e) {
    return data;
  }
}

export const clearStorage = () => {
  localStorage.removeItem("lst-login");
};

export const getFinalFilters = (extraData, newEvents, options) => {
  let result = Object.assign({}, extraData, newEvents);
  if (newEvents?.current) {
    return result;
  }
  return Object.assign({}, result, { current: 1 });
};

export const getLastParams = (filter, e, options) => {
  return {
    search_text: e?.search || undefined,
    filter: filter,
    page: e?.current,
    page_size: e?.pageSize || 10,
    sort_field: e?.sort_field,
    sort_orientation: e?.sort_orientation,
  };
};

export const isStage = () => {
  if (
    window.location.host?.includes("stage") ||
    window.location.host?.includes("localhost")
  ) {
    return true;
  }
  return;
};

export const converterFlow = (data, sendToServer) => {
  try {
    const result = { variables: {}, Formulas: [], Steps: [], Currency: 1 };
    const variables = {};
    _.keys(data?.variables).map((k) => {
      const v = data?.variables[k];
      variables[`$${v.key}`] = v.value;
    });
    const Formulas = {};
    _.keys(data?.formulas).map((k) => {
      const v = data?.variables[k];
      if (v) {
        Formulas[`@${v.key}`] = v.value;
      }
    });
    const Steps = [];
    _.keys(data?.formulas).map((k) => {
      const v = data?.variables[k];
      const Predicates = [];
      _.keys(data?.formulas).map((k) => {
        const ac = data?.formulas[k];
        // const actions = _.keys(ac.actions);
        Predicates.push({
          Instruction: k.instruction,
          Actions: [],
        });
      });
      Steps.push({
        StepName: v.name,
        // IsFirstStep: ,
        Instruction: v.instruction,
        Predicates: v.instruction,
      });
    });

    result.variables = variables;
    result.Formulas = Formulas;
    result.Steps = Steps;

    return data;
  } catch (e) {
    return [];
  }
};

export const prettyCode = (code) => {
  let d = code;
  d = d.replaceAll("<div ", "\n<div ");
  d = d.replaceAll("<script ", "\n<script ");
  d = d.replaceAll(",", ",\n");
  d = d.replaceAll("Affilio.widget", "\nAffilio.widget");

  return d;
};
