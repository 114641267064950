import { Button, Form, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import { contractUpdateStatus } from "@/app/local/adminContractSlice";
import { getMerchantDetail } from "@/app/local/adminMerchantSlice";
import { getPublishDetail } from "@/app/local/adminPublisherSlice";
import { getDefaultContractUser } from "@/app/local/contractSlice";
import { CONTRACT_STATUS, USER_TYPE } from "@/utils/constants/apiConstants";

const FormStatusContract = (props) => {
  const userInfo = props.userInfo?.user;
  const userType = _.get(props, "userInfo.user.user_type");

  const path_ = useMatch("/publishers/:id") || useMatch("/merchants/:id");
  const uId = path_?.params?.id;
  const { contract } = useSelector((state) => state.contract);

  useEffect(() => {
    if (path_) dispatch(getDefaultContractUser(uId));
  }, [path_]);

  const [form] = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.adminUser);

  const onFinish = async (e) => {
    const cId = contract.data?.contract_id;
    const res = await dispatch(
      contractUpdateStatus({
        contract_id: cId,
        status: CONTRACT_STATUS.BLOCKED,
      })
    );

    if (res.payload?.success) {
      message.success({
        content: t("general.saveSuccessFully"),
        key: "user",
      });
      if (userType === USER_TYPE.PUBLISHER) {
        dispatch(getPublishDetail(uId));
      } else {
        dispatch(getMerchantDetail(uId));
      }
      props.close();
    } else {
      message.error({
        content: res.payload?.Message || t("general.unHandleErrorMessage"),
        key: "user",
      });
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name={"contractStatus"}
        label={t("contract.status")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          size="large"
          style={{ flexGrow: 1, display: "flex", marginLeft: "10px" }}
          placeholder={t("contract.status")}
        >
          {Object.keys(CONTRACT_STATUS).map((key) => {
            return (
              <Select.Option
                key={key}
                value={CONTRACT_STATUS[key]}
                disabled={key !== "BLOCKED"}
              >
                {t(`profile.contract.${key}`)}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          // offset: 8,
          span: 16,
        }}
        className="form_actions"
      >
        <Button loading={loading} size="large" type="primary" htmlType="submit">
          {t("general.save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

FormStatusContract.propTypes = {
  userInfo: PropTypes.object,
  close: PropTypes.func,
};

export default FormStatusContract;
