import PropTypes from "prop-types";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
// import { SnapItem, SnapList } from "react-snaplist-carousel";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// ES6
import ProductItem from "@/pages/link/detail/components/ProductItem";
import { useDeviceDetector } from "@/utils/hooks/useDeviceDetector";

import "./SliderCarouselv2.less";

// eslint-disable-next-line react/prop-types
const MyItem = ({ children }) => (
  <div style={{ margin: "0px" }} className="product__item">
    {children}
  </div>
);

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const SliderCarouselV2 = (props) => {
  const { data, hideButton, hidePrice } = props;
  const [slides, setSlides] = useState([]);
  const LENGTH = data.length;
  const [current, setCurrent] = useState(0);
  const isMobile = useDeviceDetector();
  const GAP = !isMobile ? 5 : 1;

  useEffect(() => {
    setSlides(data.slice(0, GAP));
  }, [data]);

  return (
    <div className="slider-carousel-v2">
      <Carousel
        rtl
        // infinite
        partialVisible
        containerClass="carousel-container"
        responsive={responsive}
        itemClass="carousel-item-padding-40-px"
      >
        {data.map((item, k) => (
          <MyItem key={k}>
            <ProductItem
              product={item}
              hidePrice={hidePrice}
              hideButton={hideButton}
            />
          </MyItem>
        ))}
      </Carousel>
    </div>
  );
};

SliderCarouselV2.propTypes = {
  data: PropTypes.array,
  hidePrice: PropTypes.bool,
  hideButton: PropTypes.bool,
};
export default SliderCarouselV2;
