import PropTypes from "prop-types";
import React from "react";

function DoubleUpIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
    >
      <rect
        width="33"
        height="33"
        x="0.5"
        y="-0.5"
        fill="#fff"
        rx="5.5"
        transform="matrix(1 0 0 -1 0 33)"
      ></rect>
      <path
        fill={"#468BFF" || color}
        d="M21.937 22.523a.796.796 0 00.375-1.335l-4.73-4.732a.798.798 0 00-.872-.19c-.152.056-.27.172-.38.286l-4.62 4.622a.796.796 0 00.146 1.265c.3.18.715.124.957-.129 1.396-1.397 2.79-2.797 4.187-4.194 1.397 1.398 2.792 2.797 4.188 4.196.19.194.485.277.749.21z"
      ></path>
      <path
        fill={"#468BFF" || color}
        d="M22.44 17.247a.792.792 0 00-.044-.668c-.063-.118-.167-.206-.26-.3-1.496-1.499-2.994-2.995-4.49-4.493-.111-.116-.236-.227-.392-.276a.797.797 0 00-.83.199c-1.572 1.57-3.143 3.143-4.715 4.715a.792.792 0 101.12 1.121l4.17-4.179a8573.06 8573.06 0 014.154 4.161.783.783 0 00.71.21.794.794 0 00.578-.49z"
      ></path>
      <rect
        width="33"
        height="33"
        x="0.5"
        y="-0.5"
        stroke={"#468BFF" || color}
        rx="5.5"
        transform="matrix(1 0 0 -1 0 33)"
      ></rect>
    </svg>
  );
}

DoubleUpIcon.propTypes = {
  color: PropTypes.string,
};

export default DoubleUpIcon;
