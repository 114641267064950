import { Button, Skeleton } from "antd";
import { t } from "i18next";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import {
  getMerchantInvoiceDetail,
  getPublisherInvoiceDetail,
} from "@/app/local/invoiceSlice";
import { LEGAL_STATUS } from "@/utils/constants/apiConstants";
import { dateFormat, priceFormat } from "@/utils/helper";
import { useAccount } from "@/utils/hooks/useAuth";

import "./styles.less";

const InvoiceView = (props) => {
  const cuPath = useMatch("/invoice_view/:id/:type");
  const dispatch = useDispatch();
  const initData = {
    store: "",
    codeNational: "",
    registerCode: "",
    ecoCode: "",
    address: "",
    postalCode: "",
    teleFax: "",
    invoiceId: "",
    date: "1400-01-01",
    followCode: "",
    products: [
      {
        id: "1",
        codeKala: "124234234",
        descKala: "توضیحات محصول یا خدمات انجام شده در این محل قرار بگیرد",
        amer: "ورزش 3",
        count: 4,
        price: "23432.234234",
        total: "23432.234234",
        discount: "23432.234234",
        totalByDiscount: "23432.234234",
        vat: "23432.234234",
        totalByAll: "23432.234234",
      },
    ],
    buyer: "",
    buyerNationalCodeOrEco: "",
    buyerNationalCode: "",
    buyerRegisterCode: "",
  };
  const { roleId } = useAccount();
  const [content, setContent] = useState(initData);

  const { invoice, invoiceLoading } = useSelector((state) => state.invoice);
  // TODO CHECK INVOICE ID

  const isPublisher = cuPath?.params?.type === "p";
  useEffect(() => {
    const { params } = cuPath;
    const fetchInvoice = async () => {
      let result;
      if (params?.id) {
        if (params.type === "p")
          result = await dispatch(getPublisherInvoiceDetail(params.id));
        else {
          result = await dispatch(getMerchantInvoiceDetail(params.id));
        }
        if (result?.payload?.error_code === 401) {
          window.location.href = "/";
        }
      }
    };
    fetchInvoice();
  }, []);

  const invoiceData = invoice?.data;
  useEffect(() => {
    if (invoice) {
      const tmpData = { ...initData };

      tmpData.products = invoiceData?.invoice_items
        ?.filter((item) => item.total?.amount)
        .map((item, i) => {
          const index = i + 1;
          const deskKala = !isPublisher
            ? `${t(`factor.preItem`)} ${t("factor.fromDate")} ${dateFormat(
                invoiceData?.projection?.start_date,
                "S2"
              )} ${t("factor.toDate")} ${dateFormat(
                invoiceData?.projection?.end_date,
                "S2"
              )} - ${item.web_store?.name}`
            : `${t("factor.preItemPublisher")} - ${item.web_store?.name}`;
          return {
            id: index,
            item: item.total,
            codeKala:
              item.publisher_invoice_item_id || item.merchant_invoice_item_id,
            // descKala: `${t(
            //   `invoice.type.${objectR(InvoiceEventType)[item.event_type]}`
            // )} - ${t("invoice.website")} ${item.web_store?.name}`,
            descKala: deskKala,
            count: 1, //item.quantity,
            // price: priceFormat(item.total),
            total: priceFormat(
              item.commission?.amount +
                item.bonus_commission?.amount +
                item.total?.discount,
              "N"
            ),
            discount: priceFormat(item.total?.discount, "N"),
            totalByDiscount: priceFormat(
              item.commission?.display_amount_with_discount,
              "C"
            ),
            vat: priceFormat(item.vat, "N"),
            totalByAll: priceFormat(item.total, "N"),
          };
        });

      if (!isPublisher) {
        // init seller
        const company = invoiceData?.company_legal_info;
        tmpData.store = company?.company_name;
        tmpData.codeNational = company?.national_id;
        tmpData.registerCode = company?.register_number;
        tmpData.ecoCode = company?.economical_number;
        tmpData.address = company?.address;
        tmpData.postalCode = company?.postal_code;
        tmpData.teleFax = company?.phone_number;

        // init buyer
        const buyer = invoiceData?.merchant;
        tmpData.buyerName = buyer?.company_name;
        tmpData.buyerNationalCodeOrEco = buyer?.economical_number;
        tmpData.buyerNationalCode = buyer?.national_id;
        tmpData.buyerRegisterCode = buyer?.register_number;
        tmpData.address2 = buyer?.address;
        tmpData.buyerPostalCode = buyer?.postal_code;
        tmpData.buyerTele = buyer?.phone_number;
      }

      if (isPublisher) {
        // init seller
        const seller = invoiceData?.publisher;
        tmpData.store =
          seller.legal_status === LEGAL_STATUS.PRIVATE
            ? `${seller?.first_name} ${seller?.last_name}`
            : seller?.company_name;
        tmpData.codeNational =
          seller.legal_status === LEGAL_STATUS.LEGAL
            ? seller?.national_id
            : seller?.national_code;
        tmpData.registerCode = seller?.register_number || "-";
        tmpData.ecoCode = seller?.economical_number || "-";
        tmpData.address = seller?.address;
        tmpData.postalCode = seller?.postal_code;
        tmpData.teleFax = seller?.phone_number;

        // init buyer
        const company = invoiceData?.company_legal_info;
        tmpData.buyerName = company?.company_name;
        tmpData.buyerNationalCode = company?.national_id;
        tmpData.buyerNationalCodeOrEco =
          company?.economical_number || company?.national_id;
        tmpData.buyerRegisterCode = company?.register_number;
        tmpData.buyerPostalCode = company?.postal_code;
        tmpData.address2 = company?.address;
        tmpData.buyerTele = company?.phone_number;
      }

      tmpData.sum1 = {
        count: invoiceData?.vat?.quantity,
        // price: priceFormat(invoiceData?.total),
        total: priceFormat(invoiceData?.commission, "N"),
        discount: priceFormat(invoiceData?.total?.discount, "N"),
        totalByDiscount: priceFormat(
          invoiceData?.commission?.display_amount_with_discount,
          "C"
        ),
        vat: priceFormat(invoiceData?.vat, "N"),
        totalByAll: priceFormat(invoiceData?.total, "N"),
      };
      (tmpData.sumAll = priceFormat(invoiceData?.total, "N")),
        (tmpData.invoiceId =
          invoiceData?.publisher_invoice_id ||
          invoiceData?.merchant_invoice_id);
      tmpData.date = dateFormat(invoiceData?.projection?.end_date);
      setContent(tmpData);
    }
  }, [invoice]);

  const print = () => {
    window.print();
  };

  return (
    <div className="invoicePrint">
      <Skeleton loading={invoiceLoading}>
        <h2 className="title-invoice">{t("factor.title")}</h2>
        <div className="print-btn">
          <Button onClick={print} type="primary">
            {t("factor.print")}
          </Button>
        </div>
        <div>
          <div className="row-head">
            <div className="row-right">
              <div className="row-1">
                <div>
                  <b className="right-title">{t("factor.title1")}</b>
                </div>
                <div>
                  <table>
                    <tr>
                      <td>
                        <b>{t("factor.store")}</b>
                        {content.store}
                      </td>
                      <td>
                        <b>{t("factor.nationalCode")}</b>
                        {content.codeNational}
                      </td>
                      <td>
                        <b>{t("factor.registerCode")}</b>
                        {content.registerCode}
                      </td>
                      <td>
                        <b>{t("factor.ecoCode")}</b>
                        {content.ecoCode}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <b>{t("factor.address")}</b>
                        {content.address}
                      </td>
                      <td>
                        <b>{t("factor.postalCode")}</b>
                        {content.postalCode}
                      </td>
                      <td>
                        <b>{t("factor.teleFax")}</b>
                        {content.teleFax}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div className="row-1">
                <div>
                  <b className="right-title">{t("factor.buyer")}</b>
                </div>
                <div>
                  <table>
                    <tr>
                      <td>
                        <b>{t("factor.buyer")}</b>
                        {content.buyerName}
                      </td>
                      <td>
                        <b>{t("factor.nationalCodeOrEco")}</b>
                        {content.buyerNationalCodeOrEco}
                      </td>
                      <td>
                        <b>{t("factor.nationalCode")}</b>
                        {content.buyerNationalCode}
                      </td>
                      <td>
                        <b>{t("factor.registerCode")}</b>
                        {content.buyerRegisterCode}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <b>{t("factor.address2")}</b>
                        {content.address2}
                      </td>
                      <td>
                        <b>{t("factor.postalCode")}</b>
                        {content.buyerPostalCode}
                      </td>
                      <td>
                        <b>{t("factor.teleFax")}</b>
                        {content.buyerTele}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="row-left">
              <table>
                <tr>
                  <td>{t("factor.invoiceId")}</td>
                  <td>{content.invoiceId}</td>
                </tr>
                <tr>
                  <td>{t("factor.date")}</td>
                  <td>{content.date}</td>
                </tr>
                {/* <tr>
                  <td>{t("factor.follow")}</td>
                  <td>{content.followCode}</td>
                </tr> */}
              </table>
            </div>
          </div>

          <div className="row-items">
            <table>
              <tr>
                <th>{t("factor.rowId")}</th>
                {/* <th>{t("factor.idKala")}</th> */}
                <th className="big-th">{t("factor.descKala")}</th>
                <th className="hid"></th>
                <th className="hid"></th>
                <th className="hid"></th>
                <th className="hid"></th>
                {/* <th>{t("factor.amer")}</th> */}
                <th>{t("factor.count")}</th>
                {/* <th>{t("factor.price")}</th> */}
                <th>{t("factor.total")}</th>
                <th>{t("factor.discount")}</th>
                <th>{t("factor.totalByDiscount")}</th>
                <th>{t("factor.vat")}</th>
                <th>{t("factor.totalByAll")}</th>
              </tr>
              {content.products?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.id}</td>
                    {/* <td>{item.codeKala}</td> */}
                    <td className="big-td" colSpan={5}>
                      {item.descKala}
                    </td>
                    {/* <td>{item.amer}</td> */}
                    <td>{item.count}</td>
                    {/* <td>{item.price}</td> */}
                    <td>{item.total}</td>
                    <td>{item.discount}</td>
                    <td>{item.totalByDiscount}</td>
                    <td>{item.vat}</td>
                    <td>{item.totalByAll}</td>
                  </tr>
                );
              })}
              <tr key="sum-1">
                <td colSpan={6}>{t("factor.sum")}</td>
                <td>{content?.sum1?.count}</td>
                <td>{content?.sum1?.total}</td>
                <td>{content?.sum1?.discount}</td>
                <td>{content?.sum1?.totalByDiscount}</td>
                <td>{content?.sum1?.vat}</td>
                <td>{content?.sum1?.totalByAll}</td>
              </tr>
              <tr key="sum-2" className="sum-2">
                <td colSpan={6}></td>
                <td colSpan={5}>{t("factor.sumNext")}</td>
                <td>{content?.sumAll}</td>
              </tr>
              <tr key="sum-3" className="sum-3">
                <td colSpan={6}>{t("factor.sumVat")}</td>
                <td colSpan={6}></td>
              </tr>
            </table>
          </div>

          <div className="footer-total">
            <table>
              <tr>
                <td>{t("factor.signStore")}</td>
                <td>{t("factor.methodPayment")}</td>
                <td>{t("factor.signBuyer")}</td>
              </tr>
            </table>
          </div>
        </div>
      </Skeleton>
    </div>
  );
};

export default InvoiceView;
