import React from "react";
import Button from "@material-ui/core/Button";

// const InTest = () => {
//   console.log("x=====", Button);
//   return (
//     <>
//       <div>Hello World</div>
//       <Button>xxxxxx</Button>
//     </>
//   );
// };

class InTest extends React.Component {
  constructor() {
    super();
    // this.Button = this.Button.bind(this);
  }
  render() {
    return (
      <>
        <div>Hello World</div>
        <Button type="button" variant="contained">
          xxxxxx
        </Button>
      </>
    );
  }
}

export default InTest;
