import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useMatch, useParams, useSearchParams } from "react-router-dom";

export const useWidgetType = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const widgetPath = useMatch("/widgets/:id");
  const isNew = widgetPath?.params?.id === "new";
  const isCustom = searchParams.get("type") === "custom";
  const isSmart = searchParams.get("type") === "smart";
  const widgetId = params?.id;

  const { widgetDetail } = useSelector((state) => state.productWidget);
  const encId =
    searchParams.get("encId") || widgetDetail?.data?.encrypted_widget_id;

  return useMemo(() => {
    return { isNew, isCustom, widgetId, isSmart, encId };
  }, [isNew, isCustom, widgetId, isSmart, encId]);
};
