import { Button, Col, Form, Row, Space, Spin, message } from "antd";
import { useForm } from "antd/es/form/Form";
import confirm from "antd/lib/modal/confirm";
import { t } from "i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getPublisherInvoices,
  removePublisherInvoicesBill,
  setPublisherInvoicesBill,
} from "@/app/local/invoiceSlice";
import {
  FILE_FILE_TYPE,
  FILE_MEDIA_TYPE,
} from "@/utils/constants/apiConstants";
import { useAccount } from "@/utils/hooks/useAuth";

import InputUpload from "../input/inputUpload";

const BillUploadPublisherForm = (props) => {
  const { invoice } = props?.data || {};
  const invoiceId = invoice?.publisher_invoice_id;
  const billFileId = invoice?.legal_publisher_bill_file_id;
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [form] = useForm();
  const { isAdmin } = useAccount();

  const { payoutDetail, payoutDetailLoading } = useSelector(
    (state) => state.merchantPayout
  );

  useEffect(() => {
    if (billFileId) {
      setFiles([billFileId]);
    }
  }, [billFileId]);

  const onFinish = async (e) => {
    const [file] = form.getFieldValue("bill_file_id");
    const fileId = _.get(file, "response.file_id");

    let result;
    // if (!billFileId) {
    result = await dispatch(
      setPublisherInvoicesBill({
        invoice_id: invoiceId,
        bill_file_id: fileId,
      })
    );
    // }
    if (result?.payload?.success) {
      message.success({
        content: t("general.saveSuccessFully"),
      });
      await dispatch(getPublisherInvoices({}));
      props.close();
    } else {
      message.error({
        content: result?.payload?.Message || t("general.unHandleErrorMessage"),
      });
    }
  };

  const handleRemove = async () => {
    confirm({
      title: t("general.sureForDelete"),
      async onOk() {
        const res = await dispatch(
          removePublisherInvoicesBill({
            invoice_id: invoiceId,
          })
        );
        if (res.payload?.success) {
          message.success({
            content: t("general.successFullyDone"),
            duration: 1,
          });
          props.onFinishSearch();
        } else {
          message.error({
            content: t("general.UnexpectedErrorOccurred"),
            duration: 1,
          });
        }
      },
      onCancel() {},
    });
  };

  return (
    <Spin spinning={payoutDetailLoading}>
      <Form onFinish={onFinish} form={form} layout="vertical">
        <InputUpload
          required
          name={"bill_file_id"}
          label={t("payoutPublisher.billLegal")}
          fileType={FILE_FILE_TYPE.LegalPublisherPaymentBill}
          mediaType={FILE_MEDIA_TYPE.DOCUMENT}
          accept={"pdf"}
          form={form}
          files={files}
        />
        <br />

        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <Space>
              <Button
                disabled={props?.data?.type === "VIEW_BILL" || isAdmin}
                htmlType="submit"
                type="primary"
              >
                {t("general.save")}
              </Button>
              <Button
                onClick={handleRemove}
                disabled={props?.data?.type === "VIEW_BILL" || isAdmin}
                type="default"
              >
                {t("general.delete")}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

BillUploadPublisherForm.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func,
  onFinishSearch: PropTypes.func,
};

export default BillUploadPublisherForm;
