import MainWrap from "../components/MainWrap";
import Tickets from "./index";
import React from "react";

const TicketList = (props) => {
  return (
    <React.StrictMode>
      <MainWrap>
        <Tickets {...props} />
      </MainWrap>
    </React.StrictMode>
  );
};

export default TicketList;
