/* eslint-disable react/prop-types */
import {
  FilterOutlined,
  ReloadOutlined as ResetIcon,
  SearchOutlined,
} from "@ant-design/icons";
import { Badge, Card, Checkbox, Input, Spin } from "antd";
import _ from "lodash";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList as List } from "react-window";

import { getCategoryList } from "@/app/local/websiteSlice";

import { FilterContext } from "../widgetProductsSelector";
// import { FilterContext } from "../widget";
import "./styles.less";

const CategoryFilterV = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [context, dispatch_] = useContext(FilterContext);
  const [treeData_, setTreeData_] = useState();
  const [checkedKeys, setCheckedKeys] = useState([]);
  const { categories, categoriesLoading } = useSelector(
    (state) => state.website
  );

  const data_ = useMemo(() => {
    const rtn =
      categories?.data?.filter((item) => item.is_root && item.is_display) || [];
    setTreeData_(rtn);
    return rtn;
  }, [categories]);

  useEffect(() => {
    const [wId] = context?.websites || [];
    wId && dispatch(getCategoryList({ website_id: wId, is_pagination: false }));
    setCheckedKeys([]);
  }, [context?.websites]);

  const onCheck = (e, current) => {
    let keys = [...checkedKeys];
    if (e.target.checked) {
      keys = [...(checkedKeys || []), current];
    } else {
      keys = checkedKeys.filter((id) => id !== current);
    }
    setCheckedKeys(keys);
    setTimeout(() => dispatch_({ type: "set_category", value: keys }), 100);
  };

  const onReset = () => {
    setCheckedKeys([]);
    setTimeout(() => dispatch_({ type: "set_category", value: [] }), 100);
  };

  const onSearch = (e) => {
    if (e.target.value)
      setTreeData_((old) =>
        old.filter((item) => item.name.includes(e.target.value))
      );
    else setTreeData_(data_);
  };

  const Row = ({ index, style }) => (
    <div style={style} key={treeData_[index].Category_id}>
      <Checkbox
        value={treeData_[index].Category_id}
        checked={checkedKeys.find((id) => id === treeData_[index].Category_id)}
        onChange={(e) => onCheck(e, treeData_[index]?.Category_id)}
      >
        {treeData_[index]?.name}
      </Checkbox>
    </div>
  );

  return (
    <Card
      className="category-filter"
      title={t("product.category")}
      extra={
        <div className="filter__badge">
          {checkedKeys?.length ? (
            <>
              <a onClick={onReset}>
                <ResetIcon className="filter__badge-retry" />
              </a>
              <Badge count={checkedKeys?.length} status="default" size="small">
                <FilterOutlined className="filter__badge-icon" />
              </Badge>
            </>
          ) : (
            ""
          )}
        </div>
      }
    >
      <div>
        <Input
          prefix={<SearchOutlined />}
          placeholder={t("product.search")}
          onChange={onSearch}
        />
        {!_.isEmpty(treeData_) || categoriesLoading ? (
          <div className="filter__category">
            <br />
            <Spin spinning={categoriesLoading}>
              <List
                height={400}
                itemCount={treeData_?.length}
                itemSize={40}
                direction="rtl"
              >
                {Row}
              </List>
            </Spin>
          </div>
        ) : (
          ""
        )}
      </div>
    </Card>
  );
};

export default CategoryFilterV;
