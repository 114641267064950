import DashboardSSPAdvertiser from "@/ssp/dashboard/DashboardSSPAdvertiser";
import { USER_TYPE } from "@/utils/constants/apiConstants";
import { useAccount } from "@/utils/hooks/useAuth";
import useConfig from "@/utils/hooks/useConfig";

import DashboardAdmin from "./DashboardAdmin";
import DashboardMerchant from "./DashboardMerchant";
import DashboardPublisher from "./DashboardPublisher";
import "./styles.less";

export default function Dashboard() {
  const { isSSP } = useConfig();
  const { roleId, isAdmin } = useAccount();

  if (roleId === USER_TYPE.ADVERTISER) return <DashboardSSPAdvertiser />;
  if (roleId === USER_TYPE.PUBLISHER) return <DashboardPublisher />;
  else if (roleId === USER_TYPE.MERCHANT) return <DashboardMerchant />;
  else if (isSSP && isAdmin) return <DashboardSSPAdvertiser />;
  else return <DashboardAdmin />;
}
