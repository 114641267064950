import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import { PlaceContext } from "./AdPlaceBuilder";
import "./AdPlaceLiveViewer.less";

const devices = [
  {
    key: "DESKTOP",
    icon: <i className="adt icon-monitor-outline"></i>,
  },
  {
    key: "TABLET",
    icon: <i className="adt icon-tablet-outline"></i>,
  },
  {
    key: "MOBILE",
    icon: <i className="adt icon-mobile-outline"></i>,
  },
];
const AdPlaceLiveViewer = (props) => {
  const [current, setCurrent] = useState(devices[0].key);
  const [context, dispatchContext] = useContext(PlaceContext);

  useEffect(() => {
    dispatchContext({
      type: "set_active_live_tab",
      value: "DESKTOP",
    });
  }, []);

  const handleClick = (item) => {
    setCurrent(item.key);
    dispatchContext({
      type: "set_active_live_tab",
      value: item.key,
    });
  };

  return (
    <div className="live-viewer">
      <div className="device-selector">
        {devices.map((item) => {
          return (
            <div
              key={item.key}
              className={item.key == current ? "active" : ""}
              onClick={() => handleClick(item)}
            >
              {item.icon}
            </div>
          );
        })}
      </div>

      <div className={`device ${current}`}>
        <div className="browser">
          <div className="device-header"></div>
          <div className={`main-format format-fixed`}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};
AdPlaceLiveViewer.propTypes = {
  children: PropTypes.any,
};

export default AdPlaceLiveViewer;
