import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";

import { CONTENT_DISPLAY_TYPE } from "@/utils/constants/apiConstants";

import AdPlaceLiveViewer from "../AdPlaceLiveViewer";
import CardSkeleton from "./CardSkeleton";
import "./FixedConfig.less";
import FormMaster from "./FormMaster";
import SkeltonGenerator from "./SkeltonGenerator";

const FixedConfig = (props) => {
  const { doFunction, form } = props;

  const onFinish = (event) => {
    // Maybe need change box-widget
    doFunction(event);
  };

  return (
    <Row gutter={[24, 24]} className="main-config fixed-config">
      <Col xs={10}>
        <FormMaster
          form={form}
          onFinish={onFinish}
          config={{ content_display_type: CONTENT_DISPLAY_TYPE.ImageOnTop }}
        />
      </Col>
      <Col xs={14}>
        <AdPlaceLiveViewer>
          <div className="box-preview">
            <div className="box-m">
              <div className="box-preview__content">
                <h2 className="skeleton"></h2>
                <SkeltonGenerator count={10} />
              </div>
              <div className="box-preview__widget">
                <CardSkeleton />
              </div>
            </div>
            <SkeltonGenerator count={11} />
          </div>
          <div></div>
        </AdPlaceLiveViewer>
      </Col>
    </Row>
  );
};

FixedConfig.propTypes = {
  doFunction: PropTypes.any,
  form: PropTypes.any,
};
export default FixedConfig;
