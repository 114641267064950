import { Input } from "@material-ui/core";
import React from "react";
import TestComX from "./TestComX";

const TestCom = () => {
  return (
    <React.StrictMode>
      <div>
        <TestComX />
        <button>test</button>
      </div>
    </React.StrictMode>
  );
};

export default TestCom;
