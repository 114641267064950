import { Bar } from "@ant-design/plots";
import { Pie } from "@ant-design/plots";
import { Col, Row } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getTop10Publishers } from "@/app/local/reportSlice";
import { DashboardContext } from "@/pages/dashboard/DashboardAdmin";
import { kFormatter, numberFormat } from "@/utils/helper";

import NameTag from "../micro/NameTag";
import { ChartHolder } from "./AdminViewClickChart";
import { COLORS } from "./colors";
import { MOCK_TOP_10_PUBLISHERS } from "./mockData";

const sampleData = MOCK_TOP_10_PUBLISHERS;

const TopPublishers = (props) => {
  const { title } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [context, dispatch_] = useContext(DashboardContext);
  const [dataBarChart, setDataBarChart] = useState();
  const { adminTopPublishers, adminTopPublishersLoading } = useSelector(
    (state) => state.report
  );
  const { adminLinkConversion } = useSelector((state) => state.report);

  useEffect(() => {
    try {
      const d = (adminLinkConversion?.data || []).reduce((ac, cv) => {
        return ac.total_orders + cv.total_orders;
      }, 0);
      setTotal(d?.total_orders || d);
    } catch (e) {
      const cashing = "";
    }
  }, [adminLinkConversion?.data]);

  useEffect(() => {
    dispatch(
      getTop10Publishers({
        web_store_ids: context.webstore,
        media_ids: context?.medias,
        from: context.period.fromDate,
        to: context.period.toDate,
        publisher_ids: context?.publisher_ids,
        filter_date_by: context?.filter_date_by,
      })
    );
  }, [context]);

  useEffect(() => {
    if (adminTopPublishers) {
      let temp = [...(adminTopPublishers?.data?.data || [])];
      let dataItems = [];
      // const tempTotal = { generatedOrder: 0, finalizedOrder: 0 };

      temp = temp.sort((a, b) => {
        const h =
          b.finialized_order_commission -
          a.finialized_order_commission +
          (b.generated_order_commission - a.generated_order_commission);
        return h;
      });

      temp.map((item) => {
        const name = item.name;
        dataItems.push({
          name: name,
          value: item.finialized_order_commission,
          type: "dashboard.finalizedOrders",
        });
        // tempTotal.finalizedOrder += item.finialized_order;

        dataItems.push({
          name: name,
          value: item.generated_order_commission,
          type: "dashboard.generatedOrder",
        });
        // tempTotal.generatedOrder += item.generated_order;
      });
      dataItems = dataItems.sort((a, b) => b.value - a.value);
      // setTotal(tempTotal);
      setData(dataItems);
    }
  }, [adminTopPublishers]);
  const total_finalized_order = adminTopPublishers?.data?.total_finalized_order;
  const total_generated_order = adminTopPublishers?.data?.total_generated_order;

  const chartConfig = {
    data: data,
    isStack: true,
    padding: [20, 20, 20, 160],
    appendPadding: 8,
    xField: "value",
    yField: "name",
    renderer: "svg",
    legend: false,
    maxBarWidth: 15,
    seriesField: "type",
    theme: {
      styleSheet: {
        fontFamily: "IRANYekan",
      },
    },
    xAxis: {
      label: {
        formatter: (v) => kFormatter(v, 1),
      },
    },
    // yAxis: {
    //   position: "top",
    // },
    tooltip: {
      formatter: (datum) => {
        return {
          name: `${t(datum.type)}(${t("general.currency")})`,
          value: numberFormat(datum.value),
        };
      },
    },
    color: ({ type, year }, ...x) => {
      switch (type) {
        case "dashboard.finalizedOrders":
          return COLORS.DASHBOARD_GREEN;
        case "dashboard.generatedOrder":
          return COLORS.DASHBOARD_ORANGE;
        default:
          return COLORS.DASHBOARD_BLUE;
      }
    },
  };

  const firstPieData = [
    {
      type: "dashboard.topPublisherSubmittedOrderCount",
      value: total_generated_order,
    },
    {
      type: "dashboard.orderTotalCount",
      value: total - total_generated_order,
    },
  ];

  const firstPieConfig = {
    height: 150,
    appendPadding: 10,
    legend: false,
    data: firstPieData,
    angleField: "value",
    colorField: "type",
    radius: 1,
    tooltip: {
      formatter: (datum) => {
        return { name: t(datum.type), value: datum.value };
      },
    },
    innerRadius: 0.6,
    theme: {
      styleSheet: {
        fontFamily: "IRANYekan",
      },
    },
    label: {
      autoRotate: false,
      type: "inner",
      offset: "-50%",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    pieStyle: ({ type }) => {
      if (type === "dashboard.topPublisherSubmittedOrderCount") {
        return {
          fill: COLORS.DASHBOARD_ORANGE,
        };
      }
      if (type === "dashboard.submittedOrderCount") {
        return {
          fill: COLORS.DASHBOARD_ORANGE_LIGHTEN,
        };
      }
    },
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };

  const secondPieData = [
    {
      type: "dashboard.topPublisherFinalizedOrderCount",
      value: total_finalized_order,
    },
    {
      type: "dashboard.orderTotalCount",
      value: total - total_finalized_order,
    },
  ];

  const secondPieConfig = {
    height: 150,
    appendPadding: 10,
    legend: false,
    data: secondPieData,
    angleField: "value",
    colorField: "type",
    radius: 1,
    tooltip: {
      formatter: (datum) => {
        return { name: t(datum.type), value: datum.value };
      },
    },
    innerRadius: 0.6,
    theme: {
      styleSheet: {
        fontFamily: "IRANYekan",
      },
    },
    label: {
      autoRotate: false,
      type: "inner",
      offset: "-50%",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    pieStyle: ({ type }) => {
      if (type === "dashboard.topPublisherFinalizedOrderCount") {
        return {
          fill: COLORS.DASHBOARD_GREEN,
        };
      }
      if (type === "dashboard.orderTotalCount") {
        return {
          fill: COLORS.DASHBOARD_GREEN_LIGHTEN,
        };
      }
    },
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };

  return (
    <>
      {/* <Card
        title={<span className="title">{t(`dashboard.${title}`)}</span>}
        className="admin_commission_chart"
      > */}
      <Row>
        <Col md={24} xs={24}>
          <div className={`tags-bar align__right`}>
            <NameTag title={t("dashboard.generatedOrder")} color="color_3" />
            <NameTag title={t("dashboard.finalizedOrders")} color="color_5" />
          </div>
          <ChartHolder
            loading={adminTopPublishersLoading}
            len={data?.length}
            height="315px"
          >
            <div dir="ltr" style={{ direction: "ltr" }}>
              <Bar {...chartConfig} loading={adminTopPublishersLoading} />
            </div>
          </ChartHolder>
        </Col>
        <Col md={24} hidden>
          <Row>
            <Col md={24} xs={24} className="pie-wrapper border">
              <Row>
                <Col md={12} xs={24} dir="ltr" className="pie-wrapper_col">
                  <Pie
                    {...firstPieConfig}
                    label="x"
                    loading={adminTopPublishersLoading}
                  />
                  {t("dashboard.topGeneratedOverAll")}
                </Col>
                <Col md={12} dir="ltr" className="pie-wrapper_col">
                  <Pie
                    {...secondPieConfig}
                    label="x"
                    loading={adminTopPublishersLoading}
                  />
                  {t("dashboard.topFinalOverAll")}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* </Card> */}
    </>
  );
};

TopPublishers.propTypes = {
  title: PropTypes.string,
};
export default TopPublishers;
