import { Form, Select } from "antd";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { CONTENT_DISPLAY_TYPE } from "@/utils/constants/apiConstants";

const selectContentDisplayType = (props) => {
  const { name, label, rules, placeholder, excludedOptions } = props;
  const { t } = useTranslation("ssp");
  let devicesOptions = _.keys(CONTENT_DISPLAY_TYPE).map((k) => ({
    key: CONTENT_DISPLAY_TYPE[k],
    name: k,
  }));

  if (excludedOptions) {
    devicesOptions = devicesOptions.filter(
      (item) => !excludedOptions.find((it) => it === item.key)
    );
  }

  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      placeholder={placeholder}
    >
      <Select showArrow defaultValue={"ImageOnTop"}>
        {devicesOptions.map((item) => (
          <Select.Option key={item.key} value={item.key}>
            {t(`display_type_options.${item.name}`)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

selectContentDisplayType.propTypes = {
  name: "",
  label: "",
  rules: "",
  placeholder: "",
  excludedOptions: [],
};

export default selectContentDisplayType;
