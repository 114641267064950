import { useNavigate } from "react-router-dom";
import { TicketCreate } from "smartech-support";

import MainContainer from "@/components/container/MainContainer";
import PageTitle from "@/components/general/PageTitle";
import { HELPICAL_DEPARTMENT_ID } from "@/utils/constants/configConstants";
import { useAccount } from "@/utils/hooks/useAuth";

const Ticket = () => {
  const nav = useNavigate();
  const { userDetail } = useAccount();

  const userEmail = userDetail?.email;
  const currentDepartment = HELPICAL_DEPARTMENT_ID;

  const userProfile = {
    email: userEmail,
    firstName:
      userDetail?.first_name || userDetail?.legal_representative_first_name,
    lastName:
      userDetail?.last_name || userDetail?.legal_representative_last_name,
    id: userDetail?.mobile,
    phone: String(userDetail?.mobile),
  };

  return (
    <div>
      <PageTitle title={"TicketCreate"} />
      <MainContainer>
        <TicketCreate
          router={nav}
          options={{
            routeList: "/tickets",
            userProfile: userProfile,
            currentDepartment: currentDepartment,
          }}
        />
      </MainContainer>
    </div>
  );
};

export default Ticket;
