import { Card, Col, Row } from "antd";
import moment from "moment-jalaali";
import React, { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import { appLoadingBar } from "@/app/local/appSlice";
import { getInventory } from "@/app/local/inventorySlice";
import MainContainer from "@/components/container/MainContainer";
import FilterHeader from "@/components/dashboard/FilterHeader";
import SSPViewChart from "@/components/dashboard/SSPViewChart";
import PageTitle from "@/components/general/PageTitle";
import { DashboardContext } from "@/pages/dashboard/DashboardAdmin";
import { formatDate, serverAcceptableDate } from "@/utils/helper";

import ChartPerformanceMedia from "../components/dashboard/ChartPerformanceMedia";
import TileCount from "../components/dashboard/TileCount";
import WidgetIncome from "../components/dashboard/WidgetIncome";
import "./DashboardSSPAdvertiser.less";

const reducer = (state, action) => {
  switch (action.type) {
    case "set_date":
      return { ...state, period: action.value ? action.value : {} };
    case "set_webstore":
      return { ...state, webstore: action.value ? action.value : [] };
    case "set_all":
      return { ...state, ...action.value };
    default:
      return { ...state, [action.type]: action.value };
  }
};

const DashboardSSPAdvertiser = () => {
  const initialState = {
    period: {
      fromDate: serverAcceptableDate(moment().add(-1, "month"), true),
      toDate: serverAcceptableDate(formatDate(moment()), false, true),
    },
  };
  const { t } = useTranslation(["translation", "ssp"]);

  const dispatch = useDispatch();
  const [context, dispatch_] = useReducer(reducer, initialState);
  const pth = useMatch("/places/:id/report");
  const invId = pth?.params?.id;
  const { inventoryDetail, inventoryDetailLoading } = useSelector(
    (state) => state.inventory
  );

  useEffect(async () => {
    if (invId) {
      dispatch(appLoadingBar(true));
      await dispatch(getInventory(invId));
      dispatch(appLoadingBar(false));
    }
  }, []);

  const {
    reportClickRedirectSSP,
    reportClickSSP,
    reportViewSSP,
    reportImpSSP,
    reportClickSSPLoading: ld,
  } = useSelector((state) => state.report);

  const detail = inventoryDetail?.data;

  const ctrValue = reportClickSSP?.data?.total / reportImpSSP?.data?.total || 0;

  return (
    <DashboardContext.Provider value={[context, dispatch_]}>
      <div className={invId ? "dashboard dashboard__one_place" : "dashboard"}>
        <PageTitle
          title={
            invId ? (
              <div>
                {t("page.place_analytics") + " "}
                <b>{detail?.name || ""}</b>
              </div>
            ) : (
              <>{t("Dashboard", { ns: "ssp" }) + " "}</>
            )
          }
          print
        />
        <MainContainer wrapDisabled>
          <>
            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <Card className="filter-bar">
                  <FilterHeader hasMedia hideWebsites hideFilterType />
                </Card>
              </Col>

              <Col lg={8} md={12} xs={24} className="tile-analytic">
                <TileCount type={"view"} inputData={reportViewSSP?.data} />
                <TileCount type={"click"} inputData={reportClickSSP?.data} />
              </Col>

              <Col lg={8} md={12} xs={24} className="tile-analytic">
                <TileCount type={"imp"} inputData={reportImpSSP?.data} />
                <TileCount type={"ctr"} inputData={undefined} />
              </Col>

              <Col lg={8} className="col__left">
                <WidgetIncome />
              </Col>
            </Row>
            {/* {invId ? (
              <Row gutter={[24, 24]} style={{ marginTop: "24px" }}>
                 <Col hidden lg={8} md={12} xs={24} className="tile-analytic">
                  <TileCount
                    name={t("dashboard.ctr")}
                    count={ctrValue}
                    isCount
                    isDecimal
                    color="color_5"
                    toolTip={"CTR"}
                  />
                </Col> 
              </Row>
            ) : (
              false
            )} */}
            <Row gutter={[24, 24]} style={{ marginTop: "24px" }}>
              <Col lg={24}>
                <ChartPerformanceMedia />
              </Col>
            </Row>
            <Row hidden gutter={[24, 24]} style={{ marginTop: "24px" }}>
              <Col lg={12}>
                <SSPViewChart title="click" type="CLICK_REDIRECT" />
              </Col>

              <Col lg={12}>
                <SSPViewChart title="click_sdk" type="CLICK" />
              </Col>

              <Col lg={12}>
                <SSPViewChart title="view" type="VIEW" />
              </Col>

              <Col lg={12}>
                <SSPViewChart title="impression" type="IMPRESSION" />
              </Col>
            </Row>
          </>
        </MainContainer>
      </div>
    </DashboardContext.Provider>
  );
};

export default DashboardSSPAdvertiser;
