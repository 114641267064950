import { Button, Col, Row } from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import _ from "lodash";
import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import BulbImage from "@/assets/images/bulb.svg";
import BulbImageOn from "@/assets/images/bulbOn.svg";
import { PlaceContext, useHookPlace } from "@/ssp/AdPlaceBuilder";
import { STEPS } from "@/ssp/placeSteps/PlaceStepper";
import { INVENTORY_FORMATS } from "@/utils/constants/apiConstants";

import ButtonPrint from "../micro/ButtonPrint";
import "./PageTitle.less";

const PageTitle = (props) => {
  const { children } = props;
  const { t } = useTranslation();
  const { placeId, isNewPage, inventoryDetail } = useHookPlace();
  const [context, dispatchContext] = useContext(PlaceContext);

  const handleClick = () => {
    props.handleClick();
  };
  useEffect(() => {
    if (!isNewPage && inventoryDetail?.data && dispatchContext) {
      dispatchContext({ type: "currentStep", value: STEPS[2].key });
      const d = inventoryDetail?.data;
      dispatchContext({ type: "name", value: d?.name });
      dispatchContext({ type: "media", value: d?.media_id });

      const formatType = Object.keys(INVENTORY_FORMATS).find(
        (key) => INVENTORY_FORMATS[key] === d?.format_type
      );
      dispatchContext({ type: "format", value: formatType });
    }
  }, [inventoryDetail]);

  const hasAddon = props.showHelp || props.children || props.print;
  return (
    <div className="page__title">
      <Row className="page__title__row" gutter={[16, 16]} justify="">
        <Col
          md={hasAddon ? 22 : 24}
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
          className={props.showHelp ? "page__title__main" : ""}
        >
          {_.isString(props.title) ? (
            <Title level={4}>
              <span tour_id={props?.tour_id}>
                {props.t === false ? props.title : t("page." + props.title)}
              </span>
            </Title>
          ) : (
            <Title level={4}>{props.title}</Title>
          )}
          {props?.helpText ? (
            <Text style={{ color: "#3d3d3d" }}>
              {" | "} {props.helpText}
            </Text>
          ) : null}
        </Col>
        {props?.children ? (
          <Col md={2} className={"title__helper"}>
            {children}
          </Col>
        ) : undefined}
        {props?.print ? (
          <Col md={2} className={"title__helper"}>
            <ButtonPrint />
          </Col>
        ) : undefined}
        {props?.showHelp ? (
          <Col md={2} className={"title__helper"}>
            <Button className={"helper__button"} onClick={handleClick}>
              <img src={BulbImage} className={"image_off"} />
              <img src={BulbImageOn} className={"image_on"} />
              <dot></dot>
            </Button>
          </Col>
        ) : (
          ""
        )}
      </Row>
      {props?.subTitle && (
        <Row className="page__title__row" gutter={[16, 16]} justify="">
          <Col className="page_subtitle">{props?.subTitle}</Col>
        </Row>
      )}
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.any,
  t: PropTypes.bool,
  showHelp: PropTypes.bool,
  print: PropTypes.bool,
  helpText: PropTypes.string,
  tour_id: PropTypes.string,
  handleClick: PropTypes.func,
  children: PropTypes.any,
  subTitle: PropTypes.any,
};

export default PageTitle;
