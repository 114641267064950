import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// import { baseAPI } from "@/app/services/baseApi";
import { ENDPOINTS } from "@/utils/constants/apiConstants";
import { clearObject } from "@/utils/utils";

import { baseAPI } from "../services/fetchers";

export const getMerchantInvoicesAsync = createAsyncThunk(
  "invoice/getMerchantInvoicesAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.INVOICES_MERCHANT_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setPublisherInvoicesPayoutStatusAsync = createAsyncThunk(
  "invoice/setPublisherInvoicesPayoutStatusAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.INVOICES_PUBLISHER_PAYOUT_SET_STATUS;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setPublisherInvoicesStatusAsync = createAsyncThunk(
  "invoice/setPublisherInvoicesStatusAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.INVOICES_PUBLISHER_SET_STATUS;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getPublisherInvoicesPayoutAsync = createAsyncThunk(
  "invoice/getPublisherInvoicesPayoutAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.INVOICES_PUBLISHER_PAYOUT_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getPublisherInvoicesExportAsync = createAsyncThunk(
  "invoice/getPublisherInvoicesExportAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.INVOICES_PUBLISHER_LIST_EXPORT;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getPublisherInvoicesAsync = createAsyncThunk(
  "invoice/getPublisherInvoicesAsync",
  async (data) => {
    try {
      const defaultBody = {
        page: 1,
        page_size: 10,
        is_pagination: true,
        sort_orientation: "1",
      };
      const data_ = clearObject(data);
      const body_ = Object.assign({}, defaultBody, data_);

      let end_ = ENDPOINTS.INVOICES_PUBLISHER_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getMerchantInvoiceDetailAsync = createAsyncThunk(
  "invoice/getMerchantInvoiceDetailAsync",
  async (id) => {
    try {
      let end_ = ENDPOINTS.INVOICES_MERCHANT_DETAIL.replace("{invoiceId}", id);
      const response = await baseAPI({
        endPoint: end_,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getPublisherInvoiceDetailAsync = createAsyncThunk(
  "invoice/getPublisherInvoiceDetailAsync",
  async (id) => {
    try {
      let end_ = ENDPOINTS.INVOICES_PUBLISHER_DETAIL.replace("{invoiceId}", id);
      const response = await baseAPI({
        endPoint: end_,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setMerchantInvoiceStatusAsync = createAsyncThunk(
  "invoice/setMerchantInvoiceStatusAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.INVOICES_MERCHANT_SET_STATUS;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setMerchantInvoicePaymentStatusAsync = createAsyncThunk(
  "invoice/setMerchantInvoicePaymentStatusAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.INVOICES_MERCHANT_PAYMENT_SET_STATUS;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setPublisherBatchStatusAsync = createAsyncThunk(
  "invoice/setPublisherBatchStatusAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.INVOICES_PUBLISHER_STATUS_BATCHSET;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setPublisherBatchPaymentAsync = createAsyncThunk(
  "invoice/setPublisherBatchPaymentAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.INVOICES_PUBLISHER_PAYMENT_BATCHSET;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setPublisherInvoicesBillAsync = createAsyncThunk(
  "invoice/setPublisherInvoicesBillAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.INVOICES_PUBLISHER_SET_LEGAL_BILL.replace(
        "{invoiceId}",
        data.invoice_id
      );

      const response = await baseAPI({
        endPoint: end_,
        body: {
          file_id: data.bill_file_id,
          invoice_id: data.invoice_id,
        },
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const removePublisherInvoicesBillAsync = createAsyncThunk(
  "invoice/removePublisherInvoicesBillAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.INVOICES_PUBLISHER_REMOVE_LEGAL_BILL.replace(
        "{invoiceId}",
        data.invoice_id
      );

      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getInvoiceDocumentsAsync = createAsyncThunk(
  "invoice/getInvoiceDocumentsAsync",
  async (invId) => {
    try {
      let end_ = ENDPOINTS.INVOICES_PUBLISHER_GET_INVOICE_DOCUMENTS.replace(
        "{invoiceId}",
        invId
      );

      const response = await baseAPI({
        endPoint: end_,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const addInvoiceDocumentAsync = createAsyncThunk(
  "invoice/addInvoiceDocumentAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.INVOICES_PUBLISHER_ADD_INVOICE_DOCUMENTS.replace(
        "{invoiceId}",
        data.invoiceId
      );
      // end_ = end_.replace("{fileId}", data.fileId);

      const response = await baseAPI({
        endPoint: end_,
        body: {
          file_id: data.fileId,
          invoice_id: data.invoiceId,
        },
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const removeInvoiceDocumentAsync = createAsyncThunk(
  "invoice/removeInvoiceDocumentAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.INVOICES_PUBLISHER_REMOVE_INVOICE_DOCUMENTS.replace(
        "{invoiceId}",
        data.invoiceId
      );
      // end_ = end_.replace("{fileId}", data.fileId);

      const response = await baseAPI({
        endPoint: end_,
        body: {
          file_id: data.fileId,
          invoice_id: data.invoiceId,
        },
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const regeneratePublisherInvoiceAsync = createAsyncThunk(
  "invoice/regeneratePublisherInvoiceAsync",
  async (data) => {
    try {
      const urlParams = new URLSearchParams();
      urlParams.set("invoiceId", data.invoiceId);
      data.searchTerm &&
        urlParams.set("includedNewEvents", data.includedNewEvents);

      const end_ = ENDPOINTS.INVOICES_PUBLISHER_GENERATE_RE_INVOICE.replace(
        "{queryParams}",
        urlParams.toString()
      );

      const response = await baseAPI({
        endPoint: end_,
        controller: data?.controller,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const regenerateMerchantInvoiceAsync = createAsyncThunk(
  "invoice/regenerateMerchantInvoiceAsync",
  async (data) => {
    try {
      const urlParams = new URLSearchParams();
      urlParams.set("invoiceId", data.invoiceId);
      data.searchTerm &&
        urlParams.set("includedNewEvents", data.includedNewEvents);

      const end_ = ENDPOINTS.INVOICES_MERCHANT_GENERATE_RE_INVOICE.replace(
        "{queryParams}",
        urlParams.toString()
      );

      const response = await baseAPI({
        endPoint: end_,
        controller: data?.controller,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

const slice = createSlice({
  name: "invoice",
  initialState: {
    invoices: null,
    invoicesLoading: false,
    invoicesError: null,

    invoicesPublisher: null,
    invoicesPublisherLoading: false,
    invoicesPublisherError: null,

    invoice: null,
    invoiceLoading: false,
    invoiceError: null,

    invoiceDocuments: null,
    invoiceDocumentsLoading: false,
    invoiceDocumentsError: null,

    error: null,
  },
  reducers: {
    clearState: (state) => {
      state.invoicesLoading = false;
      state.invoicesError = null;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(getMerchantInvoicesAsync.pending, (state) => {
      state.invoicesLoading = true;
      state.invoicesError = null;
    });
    builder.addCase(getMerchantInvoicesAsync.fulfilled, (state, action) => {
      state.invoicesLoading = false;
      if (action.payload.success) {
        state.invoices = action.payload;
      } else {
        state.invoicesError = action.payload;
      }
    });
    builder.addCase(getMerchantInvoicesAsync.rejected, (state, action) => {
      state.invoicesLoading = false;
      state.invoicesError = null;
    });

    builder.addCase(getPublisherInvoicesAsync.pending, (state) => {
      state.invoicesPublisherLoading = true;
      state.invoicesPublisherError = null;
    });
    builder.addCase(getPublisherInvoicesAsync.fulfilled, (state, action) => {
      state.invoicesPublisherLoading = false;
      if (action.payload.success) {
        state.invoicesPublisher = action.payload;
      } else {
        state.invoicesPublisherError = action.payload;
      }
    });

    builder.addCase(getPublisherInvoicesAsync.rejected, (state, action) => {
      state.invoicesPublisherLoading = false;
      state.invoicesPublisherError = null;
    });

    builder.addCase(getPublisherInvoicesPayoutAsync.pending, (state) => {
      state.invoicesPublisherLoading = true;
      state.invoicesPublisherError = null;
    });
    builder.addCase(
      getPublisherInvoicesPayoutAsync.fulfilled,
      (state, action) => {
        state.invoicesPublisherLoading = false;
        if (action.payload.success) {
          state.invoicesPublisher = action.payload;
        } else {
          state.invoicesPublisherError = action.payload;
        }
      }
    );

    builder.addCase(
      getPublisherInvoicesPayoutAsync.rejected,
      (state, action) => {
        state.invoicesPublisherLoading = false;
        state.invoicesPublisherError = null;
      }
    );

    builder.addCase(getMerchantInvoiceDetailAsync.pending, (state) => {
      state.invoiceLoading = true;
      state.invoiceError = null;
    });
    builder.addCase(
      getMerchantInvoiceDetailAsync.fulfilled,
      (state, action) => {
        state.invoiceLoading = false;
        if (action.payload.success) {
          state.invoice = action.payload;
        } else {
          state.invoiceError = action.payload;
        }
      }
    );
    builder.addCase(getMerchantInvoiceDetailAsync.rejected, (state, action) => {
      state.invoiceLoading = false;
      state.invoiceError = null;
    });

    builder.addCase(getPublisherInvoiceDetailAsync.pending, (state) => {
      state.invoiceLoading = true;
      state.invoiceError = null;
    });
    builder.addCase(
      getPublisherInvoiceDetailAsync.fulfilled,
      (state, action) => {
        state.invoiceLoading = false;
        if (action.payload.success) {
          state.invoice = action.payload;
        } else {
          state.invoiceError = action.payload;
        }
      }
    );
    builder.addCase(
      getPublisherInvoiceDetailAsync.rejected,
      (state, action) => {
        state.invoiceLoading = false;
        state.invoiceError = null;
      }
    );

    builder.addCase(getInvoiceDocumentsAsync.pending, (state) => {
      state.invoiceDocumentsLoading = true;
      state.invoiceDocumentsError = null;
      state.invoiceDocuments = null;
    });
    builder.addCase(getInvoiceDocumentsAsync.fulfilled, (state, action) => {
      state.invoiceDocumentsLoading = false;
      if (action.payload.success) {
        state.invoiceDocuments = action.payload;
      } else {
        state.invoiceDocumentsError = action.payload;
      }
    });
    builder.addCase(getInvoiceDocumentsAsync.rejected, (state, action) => {
      state.invoiceDocumentsLoading = false;
      state.invoiceDocumentsError = null;
    });
  },
});

export const { clearState } = slice.actions;

export default slice.reducer;

export const getMerchantInvoices = (data) => async (dispatch) => {
  return dispatch(getMerchantInvoicesAsync(data));
};

export const getMerchantInvoiceDetail = (data) => async (dispatch) => {
  return dispatch(getMerchantInvoiceDetailAsync(data));
};

export const getPublisherInvoices = (data) => async (dispatch) => {
  return dispatch(getPublisherInvoicesAsync(data));
};

export const getPublisherInvoiceDetail = (data) => async (dispatch) => {
  return dispatch(getPublisherInvoiceDetailAsync(data));
};

export const getPublisherInvoicesPayout = (data) => async (dispatch) => {
  return dispatch(getPublisherInvoicesPayoutAsync(data));
};

export const getPublisherInvoicesExport = (data) => async (dispatch) => {
  return dispatch(getPublisherInvoicesExportAsync(data));
};

export const setMerchantInvoiceStatus = (data) => async (dispatch) => {
  return dispatch(setMerchantInvoiceStatusAsync(data));
};

export const setMerchantInvoicePaymentStatus = (data) => async (dispatch) => {
  return dispatch(setMerchantInvoicePaymentStatusAsync(data));
};

export const setPublisherInvoicesPayoutStatus = (data) => async (dispatch) => {
  return dispatch(setPublisherInvoicesPayoutStatusAsync(data));
};

export const setPublisherInvoicesStatus = (data) => async (dispatch) => {
  return dispatch(setPublisherInvoicesStatusAsync(data));
};

export const setPublisherBatchPayment = (data) => async (dispatch) => {
  return dispatch(setPublisherBatchPaymentAsync(data));
};

export const setPublisherBatchStatus = (data) => async (dispatch) => {
  return dispatch(setPublisherBatchStatusAsync(data));
};

export const setPublisherInvoicesBill = (data) => async (dispatch) => {
  return dispatch(setPublisherInvoicesBillAsync(data));
};

export const removePublisherInvoicesBill = (data) => async (dispatch) => {
  return dispatch(removePublisherInvoicesBillAsync(data));
};

export const getInvoiceDocuments = (data) => async (dispatch) => {
  return dispatch(getInvoiceDocumentsAsync(data));
};

export const addInvoiceDocument = (data) => async (dispatch) => {
  return dispatch(addInvoiceDocumentAsync(data));
};

export const removeInvoiceDocument = (data) => async (dispatch) => {
  return dispatch(removeInvoiceDocumentAsync(data));
};

export const regeneratePublisherInvoice = (data) => async (dispatch) => {
  return dispatch(regeneratePublisherInvoiceAsync(data));
};

export const regenerateMerchantInvoice = (data) => async (dispatch) => {
  return dispatch(regenerateMerchantInvoiceAsync(data));
};
