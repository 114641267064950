import { Checkbox } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useEffect } from "react";

const MultiCheckBox = (props) => {
  const { options, checkedList, setCheckedList } = props;
  // const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < options.length);
    setCheckAll(list.length === options.length);
  };
  const onCheckAllChange = (e) => {
    const vs = options.map((item) => item.value);
    setCheckedList(e.target.checked ? vs : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  useEffect(() => {
    setCheckedList(checkedList);
  }, [checkedList?.length]);

  return (
    <>
      {/* <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        {t("campaign.both")}
      </Checkbox> */}

      <Checkbox.Group
        options={options}
        value={checkedList}
        onChange={onChange}
      />
    </>
  );
};
MultiCheckBox.propTypes = {
  options: PropTypes.any,
  setCheckedList: PropTypes.func,
  checkedList: PropTypes.any,
};

export default MultiCheckBox;
